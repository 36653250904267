import React, { useState } from 'react';
import { Calendar, dateFnsLocalizer, momentLocalizer } from 'react-big-calendar';
import moment from "moment";

import { format, parse, startOfWeek, getDay } from 'date-fns';
import { es } from 'date-fns/locale';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventMap from './EventMap';
import { Button } from '@mui/material';
import { fullModalInfo } from '../../map/Objects/Layers/LayerCommons';
import "./Calendar.css"

// Localizador de fechas
// const locales = {
//   'es': es,
// };
// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
//   getDay,
//   locales,
// });
require('moment/locale/es.js');
  
moment.locale("es");
const localizer = momentLocalizer(moment);
// Eventos iniciales
// const initialEvents = [
//   {
//     address: 'Calle Mayor 1, Madrid',
//     start: new Date(2024, 8, 12),
//     end: new Date(2024, 8, 12),
//     allDay: true,
//     geometry: {
//       "type": "Point",
//       "coordinates": [440000, 4500000] // Coordenadas en EPSG:25830
//     },
//   },
//   {
//     address: 'Avenida de la Constitución, Sevilla',
//     start: new Date(2024, 8, 15),
//     end: new Date(2024, 8, 15),
//     allDay: true,
//     geometry: {
//       "type": "Point",
//       "coordinates": [750000, 4100000] // Coordenadas en EPSG:25830
//     },
//   }
// ];

  
function convertFeaturesToEvents(featuresCollectionsListStr) {
    const events = [];
    const featuresCollections = JSON.parse(featuresCollectionsListStr)
    // Iterar sobre cada colección de features

    featuresCollections.forEach(collection => {
      collection.features.forEach(feature => {
        // Extraer los datos de 'calendar' desde 'properties'
        const properties = feature.properties;
        const {id, reference, Calendario } = properties;
  
        // Convertir las fechas a objetos Date
        const startDate = new Date(Calendario);
        const endDate = new Date(Calendario);
  
        // Crear el objeto evento
        let title = reference
        if(title === null){
            title = id
        }
        const event = {
            id,
            title, // Usar 'address' como el campo para mostrar en el calendario
          start: startDate,
          end: endDate,
          allDay: true, // Asumimos que los eventos son de todo el día
          geometry: feature.geometry // Mantener la geometría para usarla en el mapa
        };
  
        // Añadir el evento a la lista
        events.push(event);
      });
    });
    console.log("Events", events)
    return events;
  }
function EventModal({ events, onClose, handleSelectEvent }) {
    const handleClickOutside = (e) => {
        if (e.target.className.includes('modal-background')) {
          onClose(); // Cerrar modal al hacer clic fuera
        }
      };
    const handleClickEvent=(e)=>{
        onClose();
        handleSelectEvent(e);
    }
    return (
        <div
        className="modal-background"
        style={{
          display: 'block',
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000, // Mayor z-index para que el modal se vea por encima de todo
        }}
        onClick={handleClickOutside}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: 20,
            margin: 'auto',
            width: '70%',
            maxHeight: '80%', // Limitar altura del modal
            borderRadius: '8px',
            position: 'relative',
            overflow: 'hidden', // Esconder el contenido que sobrepasa
            zIndex: 1001, // El modal mismo tiene un z-index mayor que su fondo
          }}
        >
          <h3>Eventos del día</h3>
          <div
            style={{
              maxHeight: '60vh', // Limitar la altura para los eventos
              overflowY: 'auto', // Habilitar scroll si hay demasiados eventos
            //   textAlign:"left",
              
            }}
          >
            <ul >
              {events.map((event, index) => (
                <li key={index} style={{
                    display:"flex",
                    backgroundColor:index % 2?"white":"rgb(var(--color-secondary))",
                    justifyContent:"space-around",
                   
                    
                    }}>
                        <label style={{paddingTop:"0.6rem",
                             color:index%2?"rgb(var(--color-secondary))":"white",
                        }}>
                  Retirada {index} - {event.address || event.title} {/* Usar el título o la dirección */}
                  {/* - {event.start.toLocaleDateString()}  */}
                  </label>
                  <Button style={{backgroundColor:"rgb(var(--color-primary))", color:"white", margin:"0.2rem"}} onClick={()=>handleClickEvent(event)}>Seleccionar</Button>
                </li>
              ))}
            </ul>
          </div>
          <button
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '5px 10px',
              cursor: 'pointer',
            }}
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  }
function MyCalendarWithMap({initialEvents, onElementClick}) {

  const [events, setEvents] = useState(convertFeaturesToEvents(initialEvents));
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showMoreEvents, setShowMoreEvents] = useState({ isOpen: false, events: [] });
  const messages = {
    allDay: 'Todo el día',
    previous: 'Anterior',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: '',
    event: 'Evento',
    noEventsInRange: 'No hay eventos en este rango',
    // showMore: total => (
    //     <span
    //       style={{ color: 'blue', cursor: 'pointer' }}
    //       onClick={() => handleShowMoreClick(events)}
    //     >
    //       + Ver más ({total})
    //     </span>
    //   )
  };
  const moveEvent = ({ event, start, end }) => {
    const updatedEvents = events.map(existingEvent => {
      if (existingEvent === event) {
        return { ...existingEvent, start, end };
      }
      return existingEvent;
    });
    setEvents(updatedEvents);
  };
  const closeModal = () => {
    setShowMoreEvents({ isOpen: false, events: [] });
  };
  const handleShowMoreClick = (events, date) => {
    // Cuando se hace clic en "Ver más", mostramos el modal con los eventos de ese día
    console.log("events", events)
    setShowMoreEvents({ isOpen: true, events });
  };
  const handleDelete = eventToDelete => {
    const updatedEvents = events.filter(event => event !== eventToDelete);
    setEvents(updatedEvents);
    if (selectedEvent === eventToDelete) {
      setSelectedEvent(null);
    }
  };

  const handleSelectEvent = event => {
    setSelectedEvent(event);
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        titleAccessor="title"
        style={{ height: 700 }}
        views={['month', "agenda"]}
        defaultView="month"
        onSelectEvent={handleSelectEvent}
        //onDoubleClickEvent={handleDelete}
        //draggableAccessor={() => true}
        //onEventDrop={moveEvent}
        messages={messages}
        onShowMore={handleShowMoreClick} 
        eventPropGetter={(event) => ({
            style: {
              color:event === selectedEvent ? 'white' : 'black',
              backgroundColor: event === selectedEvent ? "rgb(var(--color-secondary))" : '#fff', // Resalta el evento seleccionado
              border: event === selectedEvent ? '1px solid rgb(var(--button-effect-color))' : 'none', // Borde para resaltarlo más
            },
          })}
       // onShowMore={(events, date) => console.log('Eventos:', events, 'Fecha:', date)} // Simple log para verificar
      />
        {showMoreEvents.isOpen && (
        <EventModal
          events={showMoreEvents.events}
          onClose={closeModal}
          handleSelectEvent={handleSelectEvent}
        />
      )}
      {selectedEvent && (
        <div style={{ marginTop: '20px' }}>
            <h2 >Detalles del elemento seleccionado:</h2>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <h3 style={{paddingTop:"0.6rem"}}>Elemento con referencia : {selectedEvent.title}</h3>
                <Button onClick={(ev)=>{
                    ev.preventDefault();
                    const elements = JSON.parse(initialEvents)[0].features.filter(ev => ev.properties.id == selectedEvent.id)
                    console.log("elements", elements, selectedEvent)
                    if(elements.length > 0){
                        const prop = {"values_":elements[0].properties}
                        prop["values_"]["geometry"] = elements[0].geometry
                        onElementClick(fullModalInfo(prop))
                    }
                    
                }}
                style={{backgroundColor:"rgb(var(--color-secondary))", color:"white", padding:"1rem", margin:"1rem"}}
                >Editar elemento</Button>
          </div>
          <EventMap geometry={selectedEvent.geometry} />
        </div>
      )}
    </div>
  );
}

export default MyCalendarWithMap;
