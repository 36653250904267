
import LayerClass, {legendGradient, interpolateColors, tooltipBeginning} from "./LayerCommons"

const background = (feature) => {
    const colors = interpolateColors("8, 81, 79", "0, 16, 29", 2010 - 1890);
    let defaultColor = "#002a4a";
   // console.log("colors", colors);
    if (feature && "values_" in feature &&
      "features" in feature.values_ &&
      feature.values_.features.length >= 1 &&
      "beginning" in feature.values_.features[0].values_
    ) {
      let dateBeginning = new Date(
        feature.values_.features[0].values_["beginning"]
      );
      const year = dateBeginning.getFullYear();
      //const i = year + 10;
      if (year <= 1890) {
        return colors[colors.length - 1];

      } else if (year >= 2010) {
        return colors[0];

      } else {
        return colors[2010 - year];
      }
    }
    return defaultColor;
}


export const LAYER_DATE_CONSTRUCTION = new LayerClass(
    "Fecha de construcción",
    () => legendGradient("+2010", "-1890", ["#15cfca", "#002a4a"], "1950"),
    background,
    tooltipBeginning

);
