
import React, {useState,useContext, useEffect} from 'react';
import { renderToString } from 'react-dom/server'
import { Checkbox,Select, MenuItem, Paper, TextField, Button } from '@mui/material';
import {getVectorLayerFromSources, updateLayerStyle, createGeometryStyle, handleMapPointerMove, BREAKPOINT_ZOOM} from "../Styles/DefaultStyles"
import LayerPublicAmianticTextInput from "./LayerPublicAmianticTextInput"
import {InputLabel} from '@mui/material';
import styled from 'styled-components';
import AppContext from '../../../../AppContext';
import { update_building, delete_building, create_building } from '../../../services/municipio_service';
import Notiflix from 'notiflix';
const StyledSelect = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  font-family: Arial, sans-serif;
`;
function interpolateColors(color1, color2, steps) {
  var stepFactor = 1 / (steps - 1),
    interpolatedColorArray = [];

  color1 = color1.match(/\d+/g).map(Number);
  color2 = color2.match(/\d+/g).map(Number);

  for (var i = 0; i < steps; i++) {
    interpolatedColorArray.push(
      interpolateColorFactor(color1, color2, stepFactor * i)
    );
  }

  return interpolatedColorArray;
}
function interpolateColorFactor(color1, color2, factor) {
  if (arguments.length < 3) {
    factor = 0.5;
  }
  var result = color1.slice();
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}
const legendBoxes = (values) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {values.map((value) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "0.8rem",
                height: "0.8rem",
                backgroundColor: value.color,
                borderRadius: "50%",
              }}
            ></div>
            <div
              style={{
                width: "0.8rem",
              }}
            />
            <label>{value.text}</label>
          </div>
        );
      })}
    </div>
  );
};


const legendGradient = (
  topText,
  bottomText,
  gradientColors,
  centerText = ""
) => {
  const gradientStyle = {
    background: `linear-gradient(${gradientColors.join(", ")})`,
    //background: "rgb(0,0,255)",
    //background: "linear-gradient(0deg, rgba(0,0,255,1) 0%, rgba(9,9,121,1) 0%, rgba(0,0,255,1) 5%, rgba(0,255,0,1) 95%, rgba(0,255,0,1) 100%);",
    opacity: 0.9,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px", // Ajusta según la necesidad
    width: "40px", // Ajusta según la necesidad
  };
  return (
    <div className="legend">
      <div className="legend-text top-text">{topText}</div>
      <div className="legend-container">
        <div style={gradientStyle}>
          <div className="legend-text">{centerText}</div>
        </div>
      </div>
      <div className="legend-text bottom-text">{bottomText}</div>
    </div>
  );
};
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
}

const tooltipBeginning = (feature) => {
  if (
    feature &&
    "values_" in feature &&
    "features" in feature.values_ &&
    feature.values_.features.length >= 1 &&
    "beginning" in feature.values_.features[0].values_
  ) {
    return (
      "Fecha de construcción: " +
      feature.values_.features[0].values_["beginning"]
    );
  }
};
var info_field = (
  label,
  datas,
  _set_data,
  key,
  fixed_decimals = false,
  is_date=false,
  
  backgroundColor = false,
  is_checkbox = false,
) => {
 // console.log(_data);
 
  if (fixed_decimals && datas[key] !== undefined && datas[key] !== null) {
    datas[key] = datas[key].toFixed(fixed_decimals).toString();
  }
  if(is_date  && datas[key] !== undefined && datas[key] !== null){
    datas[key] = new Date(datas[key]).toISOString().slice(0, 10);
  }
  if(is_checkbox){

    return <div style={{display:"flex"}}>
    <label>{label}</label>
    <input
    type="checkbox"
    checked={datas[key]}
    onChange={(e) => _set_data({ ...datas, has_lab_measures: e.target.checked })}
  />
  </div> 
  }
  return (<LayerPublicAmianticTextInput
    style={{
      width: "90%",
      paddingLeft:"5%",
     // marginTop: "0.8rem",
      backgroundColor: backgroundColor,
    }}
   // sx={{style:{width:"100%"}}}
   // size="small"
    label={label}
    value={"" + datas[key] }
    //readonly
  />)
  // return (
  //   <TextField
  //     style={{
  //       width: "100%",
  //       marginTop: "0.8rem",
  //       backgroundColor: backgroundColor,
  //     }}
  //     size="small"
  //     label={label}
  //     value={_data}
  //     readonly
  //   />
  // );
};


export const EditFieldsGroup = ({labels, data, setIsModalOpen})=>{
  const [values, setValues] = useState(data);
  useEffect(()=>{
    console.log("DATA - ", data)

  }, [])
  const { userHeader, setUserHeader, token, setToken, roles } =
  useContext(AppContext);
  useEffect(()=>{
    setValues(data)
    console.log("En el useEfdfect", data)
  }, [labels])

  const handleChange = (key, value) => {
    setValues((prevValues) => ({ ...prevValues, [key]: value }));
    console.log("Setting", key, value, values)
  };
  const handleCreation=(values)=>{
    console.log("Creando elemento con valores", values)
    Notiflix.Confirm.show(
      "CREAR",
      "¿Está seguro que desea crear este elemento?",
      "Crear",
      "Cancelar",
      async () => {
        Notiflix.Loading.circle();
        const response = await create_building(values.id, userHeader, token, values);
        Notiflix.Loading.remove();
        if (response === false) {
          Notiflix.Notify.failure("Ha ocurrido un problema creando el elemento los datos");
        } else {
          Notiflix.Notify.success("Datos creados con éxito");
          console.log("Lanzando evento");
          var event = new CustomEvent("updateMap");
          window.dispatchEvent(event);
          // if (setIsModalOpen){
          //   setIsModalOpen(false);
          // }
          
        }
      },
      () => {
        // User cancelled the dialog, do nothing
      }
    );
  }
  const handleDelete = (values) => {
    console.log("Handle submit")
    console.log(values);
    Notiflix.Confirm.show(
      "ELIMINAR",
      "¿Está seguro que desea eliminar este elemento?",
      "Eliminar",
      "Cancelar",
      async () => {
        Notiflix.Loading.circle();
        const response = await delete_building(values.id, userHeader, token);
        Notiflix.Loading.remove();
        if (response === false) {
          Notiflix.Notify.failure("Ha ocurrido un problema eliminando los datos");
        } else {
          Notiflix.Notify.success("Datos eliminados con éxito");
          console.log("Lanzando evento");
          var event = new CustomEvent("updateMap");
          window.dispatchEvent(event);
         // setIsModalOpen(false);
        }
      },
      () => {
        // User cancelled the dialog, do nothing
      }
    );
  }
  const handleSubmit = (values) => {
    console.log("Handle submit")
    console.log(values);
    Notiflix.Loading.circle();
    const response = update_building(values.id, userHeader, token, values);
    Notiflix.Loading.remove();
    if(response == false){
      Notiflix.Notify.failure("Ha ocurrido un problema actualizando los datos");
    }else{
      Notiflix.Notify.success("Datos actualizados con éxito")
      console.log("Lanzando evento")
      var event = new CustomEvent("updateMap");

      // Dispatch/Trigger/Fire the event
      window.dispatchEvent(event);
      //setIsModalOpen(false);
    }
  };
  const is_update = Object.keys(values).includes("id") && values["id"] != null;

  return (
    <div style={{display:"flex", flexDirection:"column", gap:"1rem" }}>
      {Object.keys(values).map((key, index) => {
        if(values[key] == null || values[key] == undefined){
          values[key] = "";
        }
        if(["id", "Codigo", "geometry"].includes(key)){
          return //No se muestran en el formulario
        }
        switch (key) {
          case "currentUse":
            return (
              <StyledSelect
                key={index}
                label={labels[key]}
                value={values[key]}
                onChange={(e) => handleChange(key, e.target.value)}
              >
              <option value="Oficinas">Oficinas</option>
              <option value="Retail">Retail</option>
              <option value="Agr\u00edcola">Agrícola</option>
              <option value="Otros">Otros</option>
              <option value="Servicios P\u00fablicos">Servicios Públicos</option>
              <option value="Industrial">Industrial</option>
              <option value="Residencial">Residencial</option>
              </StyledSelect>
            );
          case "risk_level":
            return (
              <StyledSelect
                key={index}
                label={labels[key]}
                value={values[key]}
                onChange={(e) => handleChange(key, e.target.value)}
              >
                <option value="Muy Alto">Muy Alto</option>
                <option value="Alto">Alto</option>
                <option value="Medio">Medio</option>
                <option value="Leve">Leve</option>
              </StyledSelect>
            );
          case "is_amianto":
          case "has_lab_measures":
            return (<>
              <div>
              <label>{labels[key]}</label>
              <Checkbox
                key={index}
                label={labels[key]}
                type="checkbox"
                checked={values[key]}
                onChange={(e) => handleChange(key, e.target.checked)}
              />
              </div>
              </>
            );
            case "beginning":
            case "Calendario":
                return <TextField
              type="date"
              inputFormat="yyyy-MM-dd"
              value={values[key]}
              onChange={(e) => handleChange(key, e.target.value)}
              label={labels[key]}            />
          case "Link_catastro":
            return  values[key] &&  values[key].length > "00000000000000".length ?<div
            onClick={()=>{
              window.open(values[key], "_blank");
            }}
            style={{backgroundColor:"#dcdcdc", cursor:"pointer", padding:"0.8rem", textAlign:"left", width:"93%", border:"1px solid grey", borderRadius:"0.3rem"}}
          >Click para acceder a la web de catastro</div>:<></>
          default:
            if(!is_update){
              if(["Provincia", "Municipio"].includes(key)){
                return  <TextField
                  key={index}
                  label={labels[key] + "(Solo lectura)"}
                  value={values[key]}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                  aria-readonly
                  readOnly
                  style={{backgroundColor:"lightgrey"}}
                />
              }
            }
            return (
              <TextField
                key={index}
                label={labels[key]}
                value={values[key]}
                onChange={(e) => handleChange(key, e.target.value)}
              />
            );
        }
      })}
      {is_update?<>
        <Button  variant="contained" onClick={()=>{
          console.log("Pulsando")
          handleSubmit(values)}}>
          Actualizar
        </Button>
        <Button  variant="contained" style={{backgroundColor:"red"}} onClick={()=>{
          console.log("Pulsando eliminar")
          handleDelete(values)}}>
          Eliminar
        </Button></>:
        <Button  variant="contained" style={{backgroundColor:"green"}} onClick={()=>{
          console.log("Pulsando crear")
          handleCreation(values)}}>
          Crear
        </Button>
        }

     
    </div>
  );
}
export const fullModalInfo = (feature)=>{
  console.log("getModalBody feature", feature)
  if (
    feature &&
    "values_" in feature &&
    "beginning" in feature["values_"] && 
    "Provincia" in feature["values_"]
  ){
        
    const data = feature.values_;
    let data_processed = JSON.parse(JSON.stringify(data));
    // const replacements = {"Clave": "Clave",
    // "Calendario": "Calendario",
    // "Codigo": "Codigo",
    // "Link": "Link",
    // "Municipio": "Municipio",
    // "Provincia": "Provincia",
    // "a_total": "Area",
    // "antiguedad": "antiguedad",
    // "beginning": "beginning",
    // "cat_antigu": "cat_antigu",
    // "cat_area": "cat_area",
    // "centroid": "centroid",
    // "currentUse": "currentUse",
    // "end": "end",
    // "floorAbove": "floorAbove",
    // "info_sys": "info_sys",
    // "lic_new_ba": "lic_new_ba",
    // "lic_new_co": "lic_new_co",
    // "mano_cost": "mano_cost",
    // "new_basicC": "new_basicC",
    // "new_coverC": "new_coverC",
    // "priority_r": "priority_r",
    // "projection": "projection",
    // "ref_geo": "ref_geo",
    // "reference": "reference",
    // "reti_cost": "reti_cost",
    
    // "risk_norm": "risk_norm",
    // "sourceStat": "sourceStat",
    // "sum_factor": "sum_factor",
    // "val_antigu": "val_antigu",
    // "val_area": "val_area",
    // "val_use": "val_use",
    // "value_uom": "value_uom"}
    
    const replacements = {
      "Provincia":"Provincia",
      "Municipio":"Municipio",
      "reference":"Ref. Catastral",
      //"Antiguedad":"Antugüedad",
      "currentUse":"Uso actual del edificio",
      "beginning":"Fecha de construcción",
      "Calendario":"Fecha de retirada",
      "a_total": "Área",
      "Link_catastro":"Catastro online",
     // "Link":"Imagen de catastro",
      "risk_level":"Riesgo de contener amianto",
     //"Superficie de amianto":
     "is_amianto":"¿La localización contiene amianto?",
     "has_lab_measures":"¿Se han tomado muestras de laboratorio?",
     "location":"Localización del amianto"
    }

    
    for(const key of Object.keys(data)){
      if(!Object.keys(replacements).includes(key)){
        if(!["id", "geometry"].includes(key)) delete data_processed[key];
      }
    }
    // if(location.getN_REF_CATASTRAL() != null && location.getN_REF_CATASTRAL().length() >= 14) {
     const uri = "https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?rc1=";
    const rc1 = data_processed["reference"].substring(0, 7);
    const rc2 = data_processed["reference"].substring(7, 14);
    const catastroUrl = uri + rc1 + "&rc2=" + rc2;
    //   properties.put("urlIframe", catastroUrl);
    // }
    const catastroImageUrl = "https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral="+data_processed["reference"];
    data_processed["Link_catastro"] = catastroUrl;
    data_processed["Link_catastro"] = catastroUrl;
    
return <div style={{width:"100%", display:"flex", flexWrap:"wrap", gap:"1.5rem", paddingRight:"1rem"}}>
      <div style={{display:"flex", flexDirection:"column", flex:"1 1 220px"}}>
        
        <EditFieldsGroup labels={replacements} data={data_processed} />
      </div>
      {data_processed["reference"] && <div  style={{display:"flex", flex:"3 1 320px", height:"500px"}}>
       <a href={catastroImageUrl} target='blank' style={{width: "100%", height:"100%"}}> <img style={{width: "100%", borderRadius: "10px",height:"100%", minHeight:"300px",  objectFit:"cover"}}  src={catastroImageUrl}/></a>
      </div>}
    </div>
    //return r;
   // return r 
    //return renderToString(<TableKeyValue dataObject={data} replacements={replacements} />)
  }
  return false;
}

export default class LayerClass {
  constructor(label, legend, colorBgFeatureFunction, getTooltip = ()=>"", 
  getModalBody = fullModalInfo, 
  _updateLayerStyle = updateLayerStyle, 
  _getVectorLayerFromSources = getVectorLayerFromSources, 
  _createGeometryStyle = createGeometryStyle,
  _breakpoint_zoom = BREAKPOINT_ZOOM,
   _handleMapPointerMove = handleMapPointerMove) {
    this.label = label;
    this.legend = legend;
    this.colorBgFeatureFunction = colorBgFeatureFunction;
    this.getTooltip = getTooltip;
    this.getModalBody = getModalBody;
    this.updateLayerStyle = _updateLayerStyle
    this.getVectorLayerFromSources = _getVectorLayerFromSources
    this.createGeometryStyle = _createGeometryStyle
    this.breakpoint_zoom = _breakpoint_zoom // zoom entre cluster y polígonos
    this.handleMapPointerMove = _handleMapPointerMove
  }
}
export {interpolateColors,legendBoxes,  legendGradient, hexToRgb, tooltipBeginning, info_field}