// src/projections.js
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';

// Definir EPSG:25830
proj4.defs(
  "EPSG:25830",
  "+proj=utm +zone=30 +ellps=GRS80 +units=m +no_defs +type=crs"
);

// Registrar la proyección con OpenLayers
register(proj4);
