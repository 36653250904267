import React, {useContext } from "react";

import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./commons/PrivateRoute";
import jwt from "jwt-decode"; // import dependency
import AppContext from "./AppContext";
import Main from "./components/screens/Main/Main";
import WebFont from "webfontloader";
import Login from "./components/screens/Login/Login";
import ExampleMap from "./components/screens/Example/ExampleMap";
import PublicMap from "./components/screens/PublicMap/PublicMap";
import DemoGreenKW from "./components/screens/Demo/DemoGreenKW";
function App() {
  const { token } = useContext(AppContext);
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["IBM Plex Mono"],
      },
    });
  }, []);
  React.useEffect(() => {}, [token]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {token ? (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/main" element={<Main />} index />
              <Route path="/example/map" element={<ExampleMap />} />
              <Route path="/public/valladolid" element={<PublicMap uuid={"b6bf91a5-637d-4e96-b6a2-d35b0fb6d1b7"}/>} /> 
              <Route path="/public/maps/:uuid" element={<PublicMap/>} />
              <Route path="/demo/greenkw/:ine" element={<DemoGreenKW/>} />

              <Route path="*" element={<Login />} />
            </>
          ) : (
            <>
              <Route path="/public/valladolid" element={<PublicMap uuid={"b6bf91a5-637d-4e96-b6a2-d35b0fb6d1b7"}/>} /> 
              <Route path="/public/maps/:uuid" element={<PublicMap/>} />
              <Route path="/demo/greenkw/:ine" element={<DemoGreenKW/>} />
              <Route path="*" element={<Login />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
