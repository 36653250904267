import LayerClass, { legendBoxes, hexToRgb, tooltipBeginning } from "./LayerCommons";

const legend = () =>
  legendBoxes([
    { color: "#15cfca", text: "Post 2002" },
    { color: "#ff8000", text: "Pre 2002" },
  ]);

const background = (feature) => {
  const post2002Color = hexToRgb("#15cfca");
  const pre2002Color = hexToRgb("#ff8000");
  const defaultColor = hexToRgb("#002a4a");
  if (
    feature &&
    "values_" in feature &&
    "features" in feature.values_ &&
    feature.values_.features.length >= 1 &&
    "beginning" in feature.values_.features[0].values_
  ) {
    let dateBeginning = new Date(
      feature.values_.features[0].values_["beginning"]
    );
    const year = dateBeginning.getFullYear();
    //console.log("Analizando años", year, year < 2002, pre2002Color)
    if (year < 2002) {
      return pre2002Color;
    } else {
      return post2002Color;
    }
  }
  console.log("Error in feature", feature);
  return defaultColor;
};


export const LAYER_PRE_2002 = new LayerClass(
  "Pre-2002",
  legend,
  background,
  tooltipBeginning
);
