
import LayerClass, { legendGradient, interpolateColors, hexToRgb } from "./LayerCommons";
import LayerPublicAmianticTextInput from "./LayerPublicAmianticTextInput";
import { renderToString } from 'react-dom/server'
import SolarInfoTabComponent from "./SolarInfoTabComponent";
import { solarModal } from "./LayerSolarArea";

const legend = () =>legendGradient("+116 kWh/m²", "110 kWh/m²", ["#15cfca", "#002a4a"], " 113 kWh/m²");
const getBackground = (feature) => {
  const max_value = 116;
  const min_value = 110;
   const colors = interpolateColors("8, 81, 79", "0, 16, 29", max_value - min_value);
   const defaultColor = "#002a4a";
   if (feature && "values_" in feature &&
      "features" in feature.values_ &&
      feature.values_.features.length >= 1 &&
      "horizontal_irradiation_mean" in feature.values_.features[0].values_
    ) {
      const value = Math.round(feature.values_.features[0].values_["horizontal_irradiation_mean"]);
      console.log("PRocesando feature , ", value, feature)

      if(value >=max_value){
        return colors[0];
      }else if(value <=min_value){
        return colors[colors.length -1];
      }else{
        return colors[colors.length - value-1];
      }
    }
    console.log("Regresando default color", feature)
    return defaultColor;
  };


  const getTooltip = (feature)=>{
    if (
      "features" in feature.values_ &&
      feature.values_.features.length >= 1 &&
      "horizontal_irradiation_mean" in feature.values_.features[0].values_
    ) {
      const a_total = new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,

      }).format(feature.values_.features[0].values_["horizontal_irradiation_mean"]).toString();
      return "Radiación horizontal media anual : "+a_total + " kWh/m²";
    }
  };








export const LAYER_SOLAR_RADIATION = new LayerClass(
    "Radiación",
    legend,
    getBackground,
    getTooltip,
     solarModal
  );