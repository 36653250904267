import { Checkbox, Paper, Tooltip, createMuiTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import i18next from "i18next";
import Notiflix from "notiflix";
import React, { useContext, useEffect,useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../AppContext";
import OpenLayersMap from "../../../OpenLayersMap";
import GenericInfoMapModal from "../../map/GenericInfoMapModal/GenericInfoMapModal";
import { EditFieldsGroup } from "../../map/Objects/Layers/LayerCommons";
import get_geojson, {
  checkIsTokenOk,
  get_basic_geometry_from_ine,
  get_full_geometry_from_ine,
  get_graph_data,
  get_municipio_basic_info,
  get_municipio_pricing_info,
  get_offer_status,
  get_report_situacion,
  set_offer_status,
  get_location_features_autonomy,
  get_location_features_province,
  get_location_features_municipe,
} from "../../services/municipio_service";
import {
  LAYER_PRE_2002,
  LAYER_DATE_CONSTRUCTION,
  LAYER_SUSCEPTIBILITY,
  LAYER_CURRENT_USE,
} from "../../Objects/Layers/LayerClass";

import AnalysisTypeSelector from "./AnalysisTypeSelector";
import "./CatastroForm.css";
import OpenLayersBasicMap from "./Location/Catastro_Map";
import municipe_list from "./Location/municipe_list";
import en from "./i18n/en";
import es from "./i18n/es";
import { TRUE } from "ol/functions";
import { Exception } from "sass";
import { type } from "@testing-library/user-event/dist/type";
import e from "cors";
import GraphsForMunicipe from "../Graphics/Graphics";
import { useSearchParams } from "react-router-dom";
import MyCalendarWithMap from "../Calendar/Calendar";
i18next.addResourceBundle("en", "CATASTRO", en);
i18next.addResourceBundle("es", "CATASTRO", es);

const CatastroForm = () => {
  const { userHeader, setUserHeader, token, setToken, roles } =
    useContext(AppContext);
    const boxRef = useRef();
  const [queryParameters] = useSearchParams();
  const is_demo_mode = 1; //queryParameters.get("demo");
  const theme = createTheme({
    palette: {
      primary: {
        light: "#002a4a",
        main: "#002a4a",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ffffff",
        main: "#ffffff",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState(<></>);

  const { t } = useTranslation("CATASTRO");
  const navigate = useNavigate();
  //Tamaño de la pantalla
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const [geojson, setGeojson] = useState(false);
  const [isCalendarView, setIsCalendarView] = useState(false);
  // console.log("Geojson: ", geojson);
  //VARIABLE PARA ALMACENAR LOS GEOJSONES
  // const [geojsons, setGeojsons] = useState([]);

  const [longitud, setLongitud] = useState(false);
  const [latitud, setLatitud] = useState(false);

  const [municipioBasicMapGeojson, setMunicipioBasicMapGeojson] =
    useState(false);
  const [municipioFullMapGeojson, setMunicipioFullMapGeojson] = useState(false);

  const [amiantoSusceptibilityCategories, setAmiantoSusceptibilityCategories] =
    useState([]);
  const [buildingAntiqueCategories, setBuildingAntiqueCategories] = useState(
    []
  );
  const [buildingEvolutionCategories, setBuildingEvolutionCategories] =
    useState([]);
  const [currentUseCategories, setCurrentUseCategories] = useState([]);
  const [amiantoSusceptibilityValues, setAmiantoSusceptibilityValues] =
    useState([]);
  const [buildingAntiqueValues, setBuildingAntiqueValues] = useState([]);
  const [buildingEvolutionValues, setBuildingEvolutionValues] = useState([]);
  const [currentUseValues, setCurrentUseValues] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState({
    municipio: "Medina del Campo",
    provincia: "Valladolid",
    id: "47085",
    label: "Medina del Campo (Valladolid)",
  });//false);
  // console.log("Ciudad seleccionada: ", selectedLocation);
  const [municipioAbsoluteValues, setMunicipioAbsoluteValues] = useState(false);
  const [municipioBasicInfo, setMunicipioBasicInfo] = useState(false);
  let type_of_analysis_start_value = false;
  if (is_demo_mode) {
    type_of_analysis_start_value = "FULL";
  }

  const [typeOfAnalysis, setTypeOfAnalysis] = useState(
    type_of_analysis_start_value
  );
  const [offerStatus, setOfferStatus] = useState(false);
  const [loadingOfferStatus, setLoadingOfferStatus] = useState(false);

  const [typeFullIsSelected, setTypeFullIsSelected] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  // Variable global para mantener las ccaa y solo hacer una peticion al servicio
  let geometryCCAA = [];
  const [geometryProvince, setGeometryProvince] = useState([]);
  let featureClickIsDisable = false;
  // Para fuardar la geometría de la region.
  const [geometryRegionLimits, setGeometryRegionLimits] = useState([]);
  // const [geometriaProvincia, setGeometriaProvincia] = useState([]);
  const [natcode, setNatcode] = useState(0);
  const [provinceNatCode, setProvinceNatCode] = useState(0);
  //PARA PONER LA VISTA DE CCAA INICIALMENTE A TRUE
  // const [isCcaaView, setCcaaView] = useState(true);
  //PARA PONER LA VISTA DE provincia INICIALMEN TE A FALSE
  // const [isProvinceView, setProvinceView] = useState(false);

  // PARA SABER SI ES CCAA O PORVINCIA O MUNICIPIO
  let regions = ["ccaa", "province", "municipe"];

  const [typeRegion, setTypeRegion] = useState(
    // window.localStorage.getItem("typeRegion")
    regions[0]
  );

  let vueltaAtras = false;
  let n_peticiones = 0;

  // console.log("SET_CCAA INICIO ", typeRegion);

  // const setLocalStorage = (value) => {
  //   try {
  //     setTypeRegion(value);
  //     Window.localStorage.setItem("typeRegion", value);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // var type_Region = "ccaa";

  const featureClick = (ev) => {
    // console.log("Valor de vuelta atras : ", vueltaAtras);
    var feature = ev.detail.feature;
    console.log(
      "nombre region que se ha pulsado:  ",
      feature["values_"].tooltip
    );
    console.log("Tipo de region que se ha pulsado: ", typeRegion);
    const nat = feature["values_"].code;
    ev.stopPropagation();
    // console.log("Feature click", ev);
    if (featureClickIsDisable) {
      return;
    }
    // console.log("Feature click pass");
    featureClickIsDisable = true;
    try {
      document.removeEventListener("featureClick");
      setTimeout(() => {
        try {
          document.removeEventListener("featureClick");
          document.addEventListener("featureClick", featureClick);
          featureClickIsDisable = false;
        } catch (error) {
          console.warning(
            "featureClick Error en el timeout - Error deshaciendo el listener"
          );
        }
      }, 300);
    } catch (error) {
      console.error(
        "featureClick Error removiendo el event listener - feature Click"
      );
    }

    // console.log("TypeRegion: ", typeRegion);
    if (typeRegion === regions[0]) {
      var feature = ev.detail.feature;
      console.log("nombre ccaa:  ", feature["values_"].tooltip);
      const nat = feature["values_"].code;
      setNatcode(nat);
      setTypeRegion(regions[1]);
      // console.log("Tipo de region dentro del primer if", typeRegion);
      // get_full_map_region(token, userHeader);
      select_location({
        id: false,
        label: feature["values_"].tooltip,
        municipio: "",
        provincia: "",
      });
    } else if (typeRegion === regions[1]) {
      var feature = ev.detail.feature;
      console.log("nombre provincia:  ", feature["values_"].tooltip);
      const nat = feature["values_"].code;
      setNatcode(nat);
      setProvinceNatCode(nat);
      // console.log("Natcode : ", nat);
      // console.log("Aqui hay que cambiar el tipo de region a municipio");
      setTypeRegion(regions[2]);
      select_location({
        id: false,
        label: feature["values_"].tooltip,
        municipio: "",
        provincia: "",
      });
      // console.log(
      //   "Supuestamente se ha cambiado el tipo de region a municipio, tipo: ",
      //   typeRegion
      // );
    } else if (typeRegion === regions[2]) {
      var feature = ev.detail.feature;
      // console.log("Municipio: ", feature["values_"].tooltip);
      // console.log("nat code: ", feature["values_"].code);
      // Obtengo el ine codigo con los ultimos 5 digitos del natcode
      let numeroStr;
      let numero;
      if (typeof feature["values_"].code === "string") {
        console.log("Es un string.");
        console.log("features: ", feature["values_"], "      ", feature);
        numero = feature["values_"].code;
        console.log("valor de string : ", numero);
        numero = numero.substring(6);
        numeroStr = numero;
      } else if (typeof feature["values_"].code === "number") {
        console.log("variable1 es un número");
        numero = feature["values_"].code % 100000;
        numeroStr = numero.toString();
      }
      // let numero = feature["values_"].code %
      console.log("codigo de busqueda : ", numero);
      console.log("nat code: ", feature["values_"].code);

      //por si el ine code tiene 4 digitos, le metemos un 0 delante porque están guardados con
      // 5 digitos en bbdd
      if (numeroStr.length == 4) {
        numeroStr = "0" + numeroStr;
      }
      // console.log("ine code: ", numero);
      // buscamos en la lista de municipios
      const municipio = municipe_list.filter(
        (municipe) => municipe.id === numeroStr
      );
      // console.log(municipio[0]);
      // si es 0 lanzamos un error ya que no se ha encontrado el municipio.
      if (municipio.length === 0) {
        // Notiflix.Notify.warning(
        //   "ERROR. El municipio no se encuentra en nuestra bbdd."
        // );
        // Si no es 0 , seleccionamos el municipio.
        console.log("No se encuentra nombre con el municipio:  ", municipio[0]);
        let numero = feature["values_"].code;
      } else {
        console.log("municipio encontrado: ", municipio[0]);
        let municipio_seleccionado = municipio[0];
        select_location(municipio_seleccionado);
        // setSelectedLocation(municipio_seleccionado);
        console.log("Municipio seleccionado: ", selectedLocation);
        if (selectedLocation == false || selectedLocation == null) {
          console.log("No se esta estableciendo bien el municipio");
        }
      }
    }
  };
  // useEffect(() => {
  //   // console.log("Declarando evento featureCliak");
  // }, []);
  //document.addEventListener("featureClick", featureClick);
  // function featureClickGoBack() {}
  const select_location = (municipio_seleccionado) => {
    console.log(
      "select_location municipio_seleccionado",
      municipio_seleccionado
    );
    setSelectedLocation(municipio_seleccionado);
  };

  const open = Boolean(anchorEl);
  const handleDownloadReportButtonClick = (event) => {
    if (
      roles.includes(
        "ROLE_COMERCIALS_FULL_ANALYSIS_DOWNLOAD_REPORT_AGFOREST"
      ) ||
      roles.includes("ROLE_COMERCIALS_FULL_ANALYSIS_DOWNLOAD_REPORT_LABORE") ||
      roles.includes("ROLE_COMERCIALS_FULL_ANALYSIS_DOWNLOAD_REPORT_BUREAU") ||
      roles.includes("ROLE_COMERCIALS_FULL_ANALYSIS_DOWNLOAD_REPORT_BAREA")
    ) {
      setAnchorEl(event.currentTarget);
    } else {
      Notiflix.Notify.warning("No tienes permisos para realizar esta acción.");
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY;
  const mapHeightFullSize =
    " calc(90vh - calc(var(--header-height) + var(--footer-height) ))";
  // const mapHeightFullSize = "50vh";
  useEffect(() => {
    if (!checkIsTokenOk(token)) {
      Notiflix.Notify.warning("La sesión ha caducado");
      setToken("");
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      navigate("/login");
      return;
    }
  }, [token]);
  const handleDownloadPdf = async (template, provider_name = "") => {
    if (selectedLocation && "id" in selectedLocation) {
      Notiflix.Loading.circle();
      const ineCode = selectedLocation["id"];
      const name = selectedLocation["municipio"];
      const filename = provider_name + "Informe exploratorio amianto_Ayto. " + name + ".pdf";
      const signedUrl = await get_report_situacion(
        ineCode,
        userHeader,
        template,
        token
      );
      if (signedUrl) {
        try {
          const response = await fetch(signedUrl);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error('Error downloading the file:', error);
          Notiflix.Notify.failure('Error al descargar el archivo');
        }
      }
      Notiflix.Loading.remove();
    } else {
      Notiflix.Notify.warning("Seleccione un municipio antes de eso");
    }
  };
  
  // const handleDownloadPdf = async (template) => {
  //   if (selectedLocation && "id" in selectedLocation) {
  //     Notiflix.Loading.circle();
  //     const ineCode = selectedLocation["id"];
  //     const name = selectedLocation["municipio"];
  //     const filename = "Informe exploratorio amianto_Ayto. " + name +".pdf";
  //     const signedUrl = await get_report_situacion(
  //       ineCode,
  //       userHeader,
  //       template,
  //       token
  //     );
  //     if (signedUrl) {
  //       // Crear un enlace temporal para descargar el archivo
  //       const link = document.createElement("a");
  //       link.href = signedUrl;
  //       link.setAttribute("download", filename);
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //     Notiflix.Loading.remove();
  //   } else {
  //     Notiflix.Notify.warning("Seleccione un municipio antes de eso");
  //   }
  // };
  const handleOfferStatusChange = (ev) => {
    if (
      selectedLocation &&
      "id" in selectedLocation &&
      selectedLocation["id"] != false
    ) {
      setLoadingOfferStatus(true);
      let new_offer_status = !offerStatus;
      let exit = false;
      if (new_offer_status == false) {
        if (
          userHeader.endsWith("@agforest.es") ||
          userHeader.endsWith("@agforest.com")
        ) {
        } else {
          exit = true;
        }
      }
      if (!exit) {
        setOfferStatus(new_offer_status);
        set_offer_status(
          selectedLocation["id"],
          userHeader,
          token,
          new_offer_status
        )
          .then((response) => {
            // console.log("Setting offer status", response)
            if (response !== undefined && "offer_status" in response) {
              setOfferStatus(response["offer_status"]);
            }
            setLoadingOfferStatus(false);
          })
          .catch(() => {
            setLoadingOfferStatus(true);
          });
      } else {
        Notiflix.Notify.warning(
          "No tiene permisos para desactivar una oferta. Si hay un error contacte con un administrador."
        );
      }
    }
  };
  const PRICING_FIELDS = [
    { field: "price_total", label: "Precio PVP", unit: "€" },
    // {
    //   field: "cost_ref_catastral",
    //   label: "Precio por referencia catastral",
    // },

    { field: "cost_total_agforest", label: "Precio Partner", unit: "€" },
    // {
    //   field: "cost_total_agforest",
    //   label: "Total agforest",
    //   unit: "€",
    // },
    { field: "amiantic", label: "Licencia Ag4Asbestos", unit: "€" },
    // { field: "buildings_rate", label: "Porcentaje de edificios" },
    // { field: "densidad_poblacion", label: "Densidad de población" },
  ];
  const BASIC_FIELDS = [
    {
      field: "number_of_buildings_2002",
      label: "Nº de edificios pre-2002",
    },
    {
      field: "percentage_pre_ban_filter",
      label: "% edificios pre-2002",
    },
    { field: "built_area", label: "Área construida" },
    {
      field: "mean_antique",
      label: "Edad media de los inmuebles",
    },
  ];

  const get_pricing_info = async (municipio) => {
    if (!checkIsTokenOk(token)) {
      Notiflix.Notify.warning("La sesión ha caducado");
      setToken("");
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      navigate("/login");
      return;
    }
    return get_municipio_pricing_info(municipio["id"], userHeader, token).then(
      (extra_info) => {
        setMunicipioAbsoluteValues(extra_info);
        return;
      }
    );
  };
  const get_basic_info = async (municipio) => {
    if (!checkIsTokenOk(token)) {
      Notiflix.Notify.warning("La sesión ha caducado");
      setToken("");
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      navigate("/login");
      return;
    }
    return get_municipio_basic_info(municipio["id"], userHeader, token).then(
      (extra_info) => {
        setMunicipioAbsoluteValues(extra_info);
        let result = {};
        if (
          "graphics_building_evo" in extra_info &&
          extra_info["graphics_building_evo"] != undefined
        ) {
          result["graphic"] = extra_info["graphics_building_evo"];
        }
        if (
          "decade_map" in extra_info &&
          extra_info["decade_map"] != undefined
        ) {
          result["map"] = extra_info["decade_map"];
        }

        setMunicipioBasicInfo(result);
        return;
      }
    );
  };
  const get_geojson_info = async (municipio) => {
    return get_geojson(
      municipio["municipio"],
      municipio["provincia"],
      municipio["id"]
    )
      .then((response) => {
        let filtered_result = response.data.filter((element) => {
          return (
            element["osm_type"] === "relation" &&
            element["type"] === "administrative" &&
            element["place_rank"] < 20
          );
        });
        filtered_result.sort((a, b) => b["place_rank"] - a["place_rank"]);
        let result = response.data[0];
        // console.log("Checkeando filtered result",filtered_result,filtered_result.length        );
        if (filtered_result.length > 0) {
          // console.log("Asignando filtro 1");
          result = filtered_result[0];
        } else {
          let filtered_result_2 = response.data.filter((element) => {
            return (
              element["osm_type"] == "relation" &&
              element["type"] === "administrative"
            );
          });
          if (filtered_result_2.length > 0) {
            // console.log("Asignando filtro 2");
            result = filtered_result_2[0];
          } else {
            // console.log("Asignando filtro por defecto");
          }
        }
        // console.log("Resultado : ", result);

        let g = result.geojson;
        let new_lon = parseFloat(result.lon);
        let new_lat = parseFloat(result.lat);
        setLongitud(new_lon);
        setLatitud(new_lat);
        setGeojson(g);
        return;
      })
      .catch((e) => {
        Notiflix.Report.failure(
          "Fallo encontrando el municipio "+  municipio["municipio"],
          "Por favor, prueba con otro nombre",
          "Ok"
        );
        return;
      });
  };
  const load_pricing = async (municipio) => {
    Notiflix.Loading.circle();
    const funciones = [
      get_pricing_info(municipio),
      get_geojson_info(municipio),
    ];
    Promise.all(funciones)
      .then((results) => {
        Notiflix.Loading.remove();
      })
      .catch((error) => {
        Notiflix.Loading.remove();
        // Notiflix.Notify.failure("Error en la carga del municipio");
        // console.error("Falló la carga de un municipio", error);
      });
  };
  const execute_get_full_map_region = () => {
    get_full_map_region();
  };
  //ESTA BIEN?
  //LLAMADA AL SERVICIO PARA TRAER LAS CCAAS
  const get_full_map_region = async () => {
    //
    // if (
    //   selectedLocation == false ||
    //   (selectedLocation == null &&
    //     (typeRegion == regions[1] || typeRegion == regions[2]))
    // ) {
    //   setTypeRegion(regions[0]);
    // } else {
    if (typeRegion == regions[0]) {
      // console.log("Estamos en la capa de ccaa");
      // condicion para no volver a llamar al servicio
      if (geometryCCAA.length == 0) {
        // console.log("haciendo peticion, no  tenemos los datos de ccaa");
        Notiflix.Loading.circle();
        const region = await get_location_features_autonomy(userHeader, token);
        Notiflix.Loading.remove();
        // console.log("Regiones: ", region);
        if (region) {
          setGeometryRegionLimits(JSON.stringify([region]));
          geometryCCAA = JSON.stringify([region]);
        } else {
          await get_full_map_region(userHeader, token);
        }
      } else {
        //Las geometrías de ccaas las tengo guardadas en esa variable.
        setGeometryRegionLimits(geometryCCAA);
      }
      // setCcaaView(true);
      // setProvinceView(false);
    } else if (typeRegion == regions[1]) {
      // console.log(
      //   "Estamos en la capa de provincia. tipo de region: ",
      //   typeRegion
      // );
      // let region;
      // if (!vueltaAtras) {
      // console.log("no vuelta atras");
      Notiflix.Loading.circle();
      const region = await get_location_features_province(
        natcode,
        userHeader,
        token
      );
      Notiflix.Loading.remove();
      //SE USARÁ EN PROXIMAS ACTUALIZACIONES.
      setGeometryProvince(region);
      // console.log(
      //   "guardamos las geometrias de provincia para no tener que volver ha hacer una consulta",
      //   geometryProvince
      // );
      // }
      //SI LA PETICION DE REGION ES CORRECTA
      if (region) {
        // MOSTRAMOS LA REGION QUE SE HA CLICQUEADO
        // console.log("Comunidad autonoma: ", region);
        setGeometryRegionLimits(JSON.stringify([region]));
        // ---------------------------------------------------------------
        // PARA PROXIMAS ACTUALIZACIONES DONDE SE VUELVA ATRAS-
        // if (vueltaAtras) {
        //   console.log("Si vuelta atras");
        //   setGeometryRegionLimits(JSON.stringify([geometryProvince]));
        //   const v = false;
        //   vueltaAtras = v;
        //   console.log("valor de vuelta atras: ", vueltaAtras);
        // } else {

        // }
        // setCcaaView(false);
        // setProvinceView(true);
        // ----------------------------------------------------------------------
        // console.log(typeRegion);
      } else {
        // console.log("Geometria de provincia: ", geometryProvince);
        // setGeometryRegionLimits(JSON.stringify([geometryProvince]));
        // Si se rechaza la peticion se vuelve a intentar.
        await get_location_features_province(natcode, userHeader, token);
      }
    } else if (typeRegion == regions[2]) {
      // console.log(
      //   "Estamos en la capa de municipio. tipo de region: ",
      //   typeRegion
      // );
      var region;
      try {
        Notiflix.Loading.circle();
        region = await get_location_features_municipe(
          provinceNatCode,
          userHeader,
          token
        );
      } catch (err) {
        console.log("error");
      } finally {
        Notiflix.Loading.remove();
      }

      // console.log("Estamos en la capa de municipio: ", region);
      if (region) {
        setGeometryRegionLimits(JSON.stringify([region]));
      } else {
        Notiflix.Notify.failure("No disponible por el momento.");
      }
    }
    // }
  };
  useEffect(() => {
    // console.log("Accediendo a get_full_map");
    execute_get_full_map_region(token, userHeader);
  }, [typeRegion /*, selectedLocation*/]);

  const ccaa_layer = (token, userHeader) => {
    if (
      (selectedLocation == false || selectedLocation == null) &&
      (typeRegion == regions[1] || typeRegion == regions[2])
    ) {
      setTypeRegion(regions[0]);
    }
  };
  useEffect(() => {
    // console.log("Accediendo a get_full_map");
    ccaa_layer(token, userHeader);
  }, [selectedLocation]);
  // useEffect(() => {
  //   get_full_map_region(token, userHeader);
  // }, []);

  //FUNCION PARA EL BOTON
  const resetCCAA = () => {
    console.log(
      "Reseteando region para poner vista a CCAA, ahora mismos tipo de region:  ",
      typeRegion
    );
    setTypeRegion(regions[0]);
    // if (typeRegion == regions[1]) {
    //   console.log("Volviendo a la vista de ccaa.");
    //   setTypeRegion(regions[0]);
    //   console.log("nada");
    // } else if (typeRegion == regions[2] && selectedLocation === false) {
    //   console.log(
    //     "Estamos en la vista de municipio, volvemos a la de provincia."
    //   );
    //   setTypeRegion(regions[1]);
    //   // const v = true;
    //   // vueltaAtras = v;
    //   console.log("Valor de vuelta atras : ", vueltaAtras);
    // } else if (selectedLocation /*&& typeRegion == regions[2]*/) {
    //   console.log("Volviendo desde la vista del municipio: ", selectedLocation);

    // if (typeRegion === regions[2]) {
    //   setSelectedLocation(false);
    // } else if (typeRegion === regions[1]) {
    //   setSelectedLocation(false);
    //   setTypeRegion(regions[0]);
    // }
    //}
  };

  const get_full_map_info = async (municipio) => {
    const result = await get_full_geometry_from_ine(
      municipio["id"],
      userHeader,
      token
    );
    if (result == false) {
      result = await get_full_geometry_from_ine(
        municipio["id"],
        userHeader,
        token
      );
      if(result == false){
        Notiflix.Notify.failure("Error cargando límite municipal");
        setMunicipioFullMapGeojson(false);
        return;
      }
      
    }
    setMunicipioFullMapGeojson(JSON.stringify([result]));
  };
  const get_basic_map_info = async (municipio) => {
    const result = await get_basic_geometry_from_ine(
      municipio["id"],
      userHeader,
      token
    );
    if (result == false) {
      Notiflix.Notify.failure("Error cargando mapa");
      setMunicipioBasicMapGeojson(false);
      return;
    }
    setMunicipioBasicMapGeojson(JSON.stringify([result]));
  };
  function redondearNumeros(arrayNumeros) {
    // Verificar si el primer elemento del array es un número flotante
    if (!Number.isInteger(arrayNumeros[0])) {
      return arrayNumeros.map((numero) => parseFloat(numero.toFixed(2)));
    } else {
      // Si el primer número no es flotante, devuelve el array original
      return arrayNumeros;
    }
  }
  function moveToFirst(array, element) {
    const index = array.indexOf(element);
    if (index > -1) {
      array.splice(index, 1); // Elimina el elemento del array
      array.unshift(element); // Inserta el elemento al principio del array
    }
  }

  const loadOfferStatus = async () => {
    if (
      selectedLocation &&
      "municipio" in selectedLocation &&
      selectedLocation["id"]
    ) {
      get_offer_status(selectedLocation["id"], userHeader, token)
        .then((response) => {
          // console.log("Getting offer status", response)
          if (response !== undefined && "offer_status" in response) {
            setOfferStatus(response["offer_status"]);
          }
        })
        .catch(() => console.error("Problema obteniendo el offerStatus"));
    }
  };
  const info_precio_partner = (element, values) => {
    // if(!element["field"] in values){
    //   return <></>
    // }
    const label = element["label"];
    const pvp_price = values["price_total"];

    const get_price_bhterra = () => {
      const ratio_bhterra =
        pvp_price < 15000 ? 0.8 : pvp_price < 25000 ? 0.85 : 0.92;
      return pvp_price * ratio_bhterra;
    };

    const get_price_bureauveritas = () => {
      return values[element["field"]];
    };

    const get_price_zentone = () => {
      return pvp_price * 0.85;
    };
    const get_price_barea = ()=>{
      return pvp_price * (1-0.40)
    }
    const schemes = [
      {
        name: "Bhterra",
        emails: ["@bhterra.es", "@bhterra.com"],
        function: get_price_bhterra,
      },
      {
        name: "Zentonze",
        emails: ["@zentonze.com", "@zentonze.es"],
        function: get_price_zentone,
      },
      {
        name: "Bureau Veritas",
        emails: ["@bureauveritas.es", "@bureauveritas.com"],
        function: get_price_bureauveritas,
      },
      {
        name: "Grupo Barea",
        emails: ["@grupobarea.es", "@grupobarea.com"],
        function: get_price_barea,
      },
    ];
    let emailIsFound = false;
    let price = 0;
    for (let scheme of schemes) {
      for (let emailAllowed of scheme["emails"]) {
        if (userHeader.endsWith(emailAllowed)) {
          emailIsFound = true;
          price = scheme["function"]();
        }
      }
    }
    if (
      userHeader.endsWith("@agforest.es") ||
      userHeader.endsWith("@agforest.com")
    ) {
      //emailIsFound = true;
      let prices = [];
      for (let scheme of schemes) {
        console.log("Scheme", scheme["name"], scheme["function"]());
        prices.push(scheme["function"]());
      }
      return (
        <>
          {prices.map((price, index) => {
            const formattedPrice = new Intl.NumberFormat("es-ES", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
              .format(price)
              .toString();
            return info_field(
              label + " " + schemes[index]["name"],
              formattedPrice
                ? "unit" in element
                  ? formattedPrice + " " + element["unit"]
                  : formattedPrice
                : "No hay datos"
            );
          })}
        </>
      );
    }
    if (!emailIsFound) {
      return <></>;
    }

    const formattedPrice = new Intl.NumberFormat("es-ES", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
      .format(price)
      .toString();
    return info_field(
      label,
      formattedPrice
        ? "unit" in element
          ? formattedPrice + " " + element["unit"]
          : formattedPrice
        : "No hay datos"
    );
  };
  const load_full_analysis_data = (municipio) => {
    Notiflix.Loading.circle();
    console.log("Cargando full analysis data")
    const funciones = [
      get_basic_info(municipio),
      get_geojson_info(municipio),
      get_full_map_info(municipio),
    ];
    Promise.all(funciones)
      .then((results) => {
        Notiflix.Loading.remove();
      })
      .catch((error) => {
        console.error("Falló la carga del municipio", error);
      });
  };
  useEffect(() => {
    console.log("typeOfAnalysis", typeOfAnalysis);
    setMunicipioBasicInfo(false);

    setMunicipioBasicMapGeojson(false);
    setMunicipioFullMapGeojson(false);
    if (selectedLocation && selectedLocation["id"]) {
      // console.log("PRUEBA A A");
      resetCCAA();
    }
    if (
      selectedLocation &&
      "municipio" in selectedLocation &&
      selectedLocation["id"]
    ) {
      // console.log("PRUEBA A B");

      // console.log("Hemos entrado en");
      loadOfferStatus();
      setMunicipioAbsoluteValues(false);
      if (
        (typeOfAnalysis == false ||
          typeOfAnalysis == null ||
          typeOfAnalysis === "PRICING") &&
        selectedLocation["id"]
      ) {
        load_pricing(selectedLocation);
      } else if (typeOfAnalysis === "FULL") {
        const not_allowed_provinces = [
          "Navarra",
          "Guipúzcoa",
          "Vizcaya",
          "Asturias (Principado de)",
          // "Cáceres",
          "Álava",
          // "Cáceres",
        ];

        if (selectedLocation["id"] === "43016") {
          Notiflix.Notify.failure(
            "El municipio 'Arboç, L' tiene datos incompletos y no dispone de full analysis"
          );
        }
        if (not_allowed_provinces.includes(selectedLocation["provincia"])) {
          Notiflix.Notify.failure(
            "Las provincias de País Vasco y de la Comunidad Foral de Navarra, no disponen de full analysis'"
          );
        }
        load_full_analysis_data(selectedLocation);
      }
    } else {
      // console.log("PRUEBA A C");

      setGeojson(false);
    }
  }, [selectedLocation, typeOfAnalysis]);
  useEffect(()=>{
    const updateMapListener = window.addEventListener("updateMap", ()=> {
      console.log("Evento recibido)")
      setMunicipioFullMapGeojson(false);
      setIsModalOpen(false);
      load_full_analysis_data(selectedLocation)
    })
    return ()=>{
      window.removeEventListener("updateMap", updateMapListener);

    }
  }, [])

  useEffect(()=>{
    const drawGeometryEvent = window.addEventListener("drawGeometry", (ev)=> {
      console.log("Draw geometry event recived.", ev)
      if(ev.detail.geometry != null){
        const replacements = {
          "Provincia":"Provincia",
          "Municipio":"Municipio",
          "reference":"Ref. Catastral",
          //"Antiguedad":"Antugüedad",
          "currentUse":"Uso actual del edificio",
          "beginning":"Fecha de construcción",
          "a_total": "Área",
          "Link_catastro":"Catastro online",
         // "Link":"Imagen de catastro",
          "risk_level":"Riesgo de contener amianto",
         //"Superficie de amianto":
         "is_amianto":"¿La localización contiene amianto?",
         "has_lab_measures":"¿Se han tomado muestras de laboratorio?",
         "location":"Localización del amianto"
        }
        const base_data = {
          "Codigo"          : selectedLocation["id"],
          "Provincia"       : selectedLocation["provincia"],
          "Municipio"       : selectedLocation["municipio"],
          "reference"       : "",
          "currentUse"      : "",
          "beginning"       : "1900-01-01",
          "a_total"         : 0.0,
          "risk_level"      : "",
          "is_amianto"      : false,
          "has_lab_measures": false,
          "location"        : "",
          "geometry":ev.detail.geometry
      }
        Notiflix.Confirm.show(
          "CREAR EDIFICACIÓN",
          "¿Está seguro de usar esta geometría para crear un nuevo elemento?",
          "Vamos",
          "Cancelar",
          async()=>{
            setModalBody(<EditFieldsGroup labels={replacements} data={base_data} setIsModalOpen={setIsModalOpen}/>)
            setIsModalOpen(true)
          },
          ()=>{}
        )
      }
    })
      return ()=>{
        window.removeEventListener("drawGeometry", drawGeometryEvent);
  
      }
  }, [])
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  var info_field = (
    label,
    _data,
    fixed_decimals = false,
    backgroundColor = false
  ) => {
    // console.log(_data);
    if (fixed_decimals && _data !== undefined && _data !== null) {
      _data = _data.toFixed(fixed_decimals).toString();
    }
    return (
      <TextField
        style={{
          width: "100%",
          marginTop: "0.8rem",
          backgroundColor: backgroundColor,
        }}
        size="small"
        label={label}
        value={_data}
        readonly
      />
    );
  };

  const getTooltipText = (locationValue, municipioValue) => {
    let result = "";
    if (locationValue) {
      result += locationValue["label"] + "<br/>";
    }
    if (municipioValue) {
      if ("superficie" in municipioValue && municipioValue["superficie"]) {
        result += "Superficie : " + municipioValue["superficie"] + " km²<br/>";
      }
      if (
        "area_construida" in municipioValue &&
        municipioValue["area_construida"]
      ) {
        result +=
          "Área urbana (km²) : " +
          municipioValue["area_construida"] +
          " km²<br/>";
      }
      if (
        "area_industrial" in municipioValue &&
        municipioValue["area_industrial"]
      ) {
        result +=
          "Área industrial (km²) : " +
          municipioValue["area_industrial"] +
          " km²<br/>";
      }
      if (
        "area_industrial_percentage" in municipioValue &&
        municipioValue["area_industrial_percentage"]
      ) {
        result +=
          "% de suelo industrial :" +
          municipioValue["area_industrial_percentage"] +
          "%<br/>";
      }
    }

    if (result.endsWith("<br/>")) {
      result = result.substring(0, result.length - "<br/>".length);
    }
    return result;
  };
  const get_district_name_from_label = (label) => {
    try {
      const start_pos = label.search(" - ");
      if (start_pos == -1) {
        return label;
      }
      return label.substring(start_pos + 3);
    } catch (error) {
      return label;
    }
  };
  const openBuildingDetails = (body)=>{
    // console.log("openBuildingDetails", data)
    // const replacements = {
    //   "Provincia":"Provincia",
    //   "Municipio":"Municipio",
    //   "reference":"Ref. Catastral",
    //   //"Antiguedad":"Antugüedad",
    //   "currentUse":"Uso actual del edificio",
    //   "beginning":"Fecha de construcción",
    //   "a_total": "Área",
    //   "Link_catastro":"Catastro online",
    //  // "Link":"Imagen de catastro",
    //   "risk_level":"Riesgo de contener amianto",
    //  //"Superficie de amianto":
    //  "is_amianto":"¿La localización contiene amianto?",
    //  "has_lab_measures":"¿Se han tomado muestras de laboratorio?",
    //  "location":"Localización del amianto"
    // }
    setModalBody(body)
    setIsModalOpen(true)
  }
  return (
    <Box
      sx={{
        //maxWidth: "var(--max-article-width)",
        width: "100%",
        height: "100%",
      }}
      ref={boxRef}
    >
      <Paper className="catastro_form_paper_box">
        <div className="catastro_form_actions">
          <div className="catastro_form_location_autocomplete">
            {/* <label>Busca el municipio sobre el que hacer el análisis</label> */}
            <Tooltip title={t("catastro_label_location_search_box")}>
              <Autocomplete
                style={{ width: "100%", height: "100%" }}
                disablePortal
                disableClearable
                options={municipe_list}
                componentsProps={{
                  paper: {
                    sx: {
                      width: "max(100%, min(500px, 80vw))",
                    },
                  },
                }}
                value={selectedLocation ? selectedLocation : ""}
                defaultValue={selectedLocation || undefined}
                filterOptions={(options, state) => {
                  if (state.inputValue.length > 1) {
                    const m = options.filter((item) =>
                      String(item.municipio)
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLocaleLowerCase("es-ES")
                        .includes(
                          state.inputValue
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .toLocaleLowerCase("es-ES")
                        )
                    );
                    const prov = options.filter((item) =>
                      String(item.provincia)
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLocaleLowerCase("es-ES")
                        .includes(
                          state.inputValue
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .toLocaleLowerCase("es-ES")
                        )
                    );
                    return [...m, ...prov].reduce((accumulator, item) => {
                      if (!accumulator.includes(item)) {
                        accumulator.push(item);
                      }
                      return accumulator;
                    }, []);
                  }
                  return options;
                }}
                onChange={(event, newValue) => {
                  console.log("Nuevo valor del municipio: ", newValue);
      
                  /* console.log("Localizacion seleccionada: ", selectedLocation);*/
                  setSelectedLocation(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("catastro_label_location_search_box")}
                    {...params}
                    value={
                      selectedLocation != false &&
                      selectedLocation != null &&
                      selectedLocation["label"]
                        ? selectedLocation["label"]
                        : ""
                    }
                    label={t("catastro_label_location_search_box")}
                  />
                )}
              />
            </Tooltip>
          </div>
          {!is_demo_mode && (
            <div className="catastro_form_analysis_type_selector">
              <AnalysisTypeSelector
                typeOfAnalysis={typeOfAnalysis}
                setTypeOfAnalysis={setTypeOfAnalysis}
              />
            </div>
          )}
          {is_demo_mode && (
            <h3 style={{ paddingRight: "1rem", paddingLeft: "1rem" }}>
              Censo
            </h3>
          )}
          {selectedLocation &&
            selectedLocation["id"] &&
            "FULL" == typeOfAnalysis &&
            roles.includes("ROLE_COMERCIALS_FULL_ANALYSIS") && (
              <div className="catastro_form_analysis_offer_status">
                {/* <Button sx={{height:"100%", backgroundColor:"#15cfca", color:"black"}} onClick={handleDownloadPdf}>Descargar reporte</Button> */}
                <Button
                  sx={{
                    height: "100%",
                    backgroundColor: "#15cfca",
                    color: "black",
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleDownloadReportButtonClick}
                >
                  Descargar reporte
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {roles.includes(
                    "ROLE_COMERCIALS_FULL_ANALYSIS_DOWNLOAD_REPORT_AGFOREST"
                  ) && (
                    <MenuItem
                      onClick={() => {
                        handleDownloadPdf("fec77b2348541812", "Agforest_");/*25077b2385892f24*/
                        handleClose();
                      }}
                    >
                      AGFOREST
                    </MenuItem>
                  )}
                  {roles.includes(
                    "ROLE_COMERCIALS_FULL_ANALYSIS_DOWNLOAD_REPORT_LABORE"
                  ) && (
                    <MenuItem
                      onClick={() => {
                        handleDownloadPdf("73377b23858babf8", "Labore_");
                        handleClose();
                      }}
                    >
                      LABORE
                    </MenuItem>
                  )}
                  {roles.includes(
                    "ROLE_COMERCIALS_FULL_ANALYSIS_DOWNLOAD_REPORT_BAREA"
                  ) && (
                    <MenuItem
                      onClick={() => {
                        handleDownloadPdf("53077b2347d46494", "Barea_");
                        handleClose();
                      }}
                    >
                      BAREA
                    </MenuItem>
                  )}
                  {roles.includes(
                    "ROLE_COMERCIALS_FULL_ANALYSIS_DOWNLOAD_REPORT_BUREAU"
                  ) && (
                    <MenuItem
                      onClick={() => {
                        handleDownloadPdf("8aa77b23858340c6", "Bureau_");
                        handleClose();
                      }}
                    >
                      BUREAU
                    </MenuItem>
                  )}
                </Menu>
              </div>
            )}
          {!is_demo_mode && (
            <div className="catastro_form_analysis_offer_status">
              <Paper
                onClick={handleOfferStatusChange}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: loadingOfferStatus ? "lightgrey" : "",
                }}
              >
                <label>¿Oferta enviada?</label>
                <Checkbox
                  checked={offerStatus}
                  label="Oferta enviada?"
                  disabled={loadingOfferStatus}
                />
              </Paper>
            </div>
          )}
          <div className="catastro_form_analysis_offer_status">
          {isCalendarView ?
            <Button  sx={{
              height: "100%",
              backgroundColor: "lightblue",
              color: "black",
              width:"10rem"
            }}
            onClick={(e)=>{
              e.preventDefault();
              var event = new CustomEvent("updateMap");
              window.dispatchEvent(event);
              setIsCalendarView(false);
            }}>Mapa</Button>  :
            <Button  sx={{
              height: "100%",
              backgroundColor: "lightgreen",
              color: "black",
              width:"10rem"
            }}
            onClick={(e)=>{
              if(!municipioFullMapGeojson){
                return
              }
              e.preventDefault();
              setIsCalendarView(true);
            }}>Calendario</Button>
          }
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            gap: "0.5rem",
          }}
        >
          {selectedLocation && selectedLocation["id"] && (
            <div style={{ flex: "1 1 180px" }}>
              <Paper
                className="info_box"
                style={{
                  maxHeight: mapHeightFullSize,
                }}
                sx={{
                  backgroundColor: "var(--color-third)",
                }}
              >
                {info_field("Provincia", selectedLocation["provincia"])}
                {info_field("Municipio", selectedLocation["municipio"])}

                {municipioAbsoluteValues != false && (
                  <>
                    {info_field(
                      "Población",
                      municipioAbsoluteValues["poblacion"]
                    )}
                    {info_field(
                      "Superficie",
                      municipioAbsoluteValues["superficie"].toString() + " km²"
                    )}
                    {info_field(
                      "Área construida",
                      municipioAbsoluteValues["area_construida"].toString() +
                        " km²"
                    )}
                    {info_field(
                      "Área industrial",
                      municipioAbsoluteValues["area_industrial"].toString() +
                        " km²"
                    )}

                    {info_field(
                      "% de suelo industrial:",
                      municipioAbsoluteValues[
                        "area_industrial_percentage"
                      ].toString() + " %"
                    )}

                    {info_field(
                      "Densidad de población",
                      municipioAbsoluteValues["densidad_poblacion"]
                    )}
                    {info_field(
                      "Nº de referencias catastrales",
                      municipioAbsoluteValues["ref_catastrales"]
                    )}
                    {/* PRICING DATA */}
                    {typeOfAnalysis === "PRICING" && (
                      <Paper sx={{ backgroundColor: "#002a4a33" }}>
                        {PRICING_FIELDS.map((e) => {
                          if (e["field"] == "cost_total_agforest") {
                            return info_precio_partner(
                              e,
                              municipioAbsoluteValues
                            );
                          }
                          return (
                            e["field"] in municipioAbsoluteValues &&
                            info_field(
                              e["label"],
                              municipioAbsoluteValues[e["field"]]
                                ? "unit" in e
                                  ? municipioAbsoluteValues[
                                      e["field"]
                                    ].toString() +
                                    " " +
                                    e["unit"]
                                  : municipioAbsoluteValues[e["field"]]
                                : "No hay datos"
                            )
                          );
                        })}
                      </Paper>
                    )}
                    {(typeOfAnalysis === "BASIC" ||
                      typeOfAnalysis === "FULL") && (
                      <Paper sx={{ backgroundColor: "#002a4a33" }}>
                        {BASIC_FIELDS.map((e) => {
                          let isFloat = false;
                          if ("isFloat" in e && e["isFloat"]) {
                            isFloat = true;
                          }
                          return (
                            e["field"] in municipioAbsoluteValues &&
                            info_field(
                              e["label"],

                              municipioAbsoluteValues[e["field"]] !=
                                undefined &&
                                municipioAbsoluteValues[e["field"]] != null
                                ? "unit" in e
                                  ? (isFloat
                                      ? municipioAbsoluteValues[e["field"]]
                                      : municipioAbsoluteValues[e["field"]]
                                    ).toString() +
                                    " " +
                                    e["unit"]
                                  : isFloat
                                  ? municipioAbsoluteValues[e["field"]]
                                  : municipioAbsoluteValues[e["field"]]
                                : "No hay datos"
                            )
                          );
                        })}
                      </Paper>
                    )}
                  </>
                )}
              </Paper>
            </div>
          )}
          {isCalendarView?<div style={{ flex: "4 1 400px" }}><MyCalendarWithMap initialEvents={municipioFullMapGeojson} onElementClick={openBuildingDetails}/></div>:
          <div style={{ flex: "4 1 400px" }}>
            {municipioBasicMapGeojson != false ? (
              <>
                <OpenLayersMap
                  geojsons={municipioBasicMapGeojson}
                  style={{ height: mapHeightFullSize, width: "100%" }}
                  defaultSelectedLayer="dateConstruction"
                  layersSelected="full"
                />
              </>
            ) : municipioFullMapGeojson != false ? (
              <>
                <OpenLayersMap
                  geojsons={municipioFullMapGeojson}
                  style={{ height: mapHeightFullSize, width: "100%" }}
                  layersSelected="full"
                  isDrawEnabled={true}
                />
              </>
            ) : geojson ? (
              <>
                {/* <button onClick={resetCCAA}>Pulsar para volver atras</button> */}
                <OpenLayersBasicMap
                  geojson={geojson} // Omitir si no tienes un geojson
                  center={[longitud, latitud]} // Omitir para centrar el mapa en España
                  style={{
                    height: mapHeightFullSize,
                    width: "100%",
                  }} // Ajusta el tamaño a tus necesidades
                  tooltip_text={getTooltipText(
                    selectedLocation,
                    municipioAbsoluteValues
                  )}
                  isDrawEnabled={true}
                />
              </>
            ) : geometryRegionLimits != false &&
              (selectedLocation == false ||
                selectedLocation == null ||
                selectedLocation["id"] == false) ? (
              <>
                {/* <button onClick={resetCCAA}>Pulsar para volver atras</button> */}
                <OpenLayersMap
                  geojsons={geometryRegionLimits}
                  style={{
                    height: mapHeightFullSize,
                    width: "100%",
                    display: "flex",
                  }}
                  //defaultSelectedLayer="limitesCCAA"
                  center_on_new_polygons={true}
                  layersSelected="regionLimits"
                  defaultSelectedLayer="regionLimits"
                  mustShowOnClickFeature={false}
                  //isAdressSearchBarEnabled={false}
                  isGetCoordinatesOnClickEnabled={false}
                />
              </>
            ) : (
              <>
                <OpenLayersBasicMap
                  style={{
                    height: mapHeightFullSize,
                    width: "100%",
                  }}
                />
              </>
            )}
          </div>
          }
        </div>

        <div className="graph_wrapper">
          {typeOfAnalysis === "FULL" &&
            selectedLocation &&
            selectedLocation["id"] && (
              /*typeRegion != regions[0] &&
            typeRegion != regions[1] &&*/ <div
                style={{
                  display: "flex",
                  padding: "10px",
                  marginTop: "1rem",
                  boxSizing: "border-box",
                  flexDirection: "column",
                  width: "100%",
                  containerType: "inline-size",
                }}
              >
                {"id" in selectedLocation &&
                  selectedLocation["id"] &&
                  selectedLocation["id"].search("_") >= 0 && (
                    <div
                      style={{ display: "flex", width: "calc(100% - 20px)%" }}
                    >
                      <br />
                      <h2>
                        Gráficas generales de {selectedLocation["municipio"]}
                      </h2>
                    </div>
                  )}
                <GraphsForMunicipe municipe={selectedLocation} />
              </div>
            )}
          {typeOfAnalysis === "FULL" &&
            selectedLocation &&
            selectedLocation["id"] &&
            "id" in selectedLocation &&
            selectedLocation["id"].search("_") >= 0 && (
              <div
                style={{
                  display: "flex",
                  borderRadius: "1rem",
                  padding: "10px",
                  backgroundColor: "#ccccff",
                  marginTop: "1rem",
                  flexDirection: "column",
                  width: "calc(100% - 20px)",
                  containerType: "inline-size",
                  boxSizing: "border-box",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <h2>
                    Gráficas del distrito{" "}
                    {get_district_name_from_label(selectedLocation["label"])}
                  </h2>
                </div>
                <GraphsForMunicipe
                  municipe={selectedLocation}
                  split_ine={false}
                />
              </div>
            )}
        </div>
        <GenericInfoMapModal

        buttonAction={() => {}}
        ModalButton={<></>}
        InitialAction={() => {}}
        Title={"Detalles del elemento"}
        BodyComponent={modalBody}
        ConfirmText={"OK"}
        CancelText={false}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        ullScreenContainerRef={useRef}
       isFullScreen={false}
      />
      </Paper>
    </Box>
  );
};

export default CatastroForm;