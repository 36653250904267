import React, { useEffect, useRef } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM as OSMSource, Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import { get as getProjection } from 'ol/proj';
import { transform } from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';

function EventMap({ geometry }) {
  const mapRef = useRef();

  useEffect(() => {
    if (!geometry) return;

    // Definir y registrar la proyección EPSG:25830
    const EPSG_ORIGIN = 'EPSG:4326';
    const EPSG3857 = 'EPSG:3857'; // Proyección estándar para OSM

    if (!getProjection(EPSG_ORIGIN)) {
      proj4.defs(
        EPSG_ORIGIN,
        "+proj=longlat +datum=WGS84 +no_defs +type=crs"
        //'+proj=utm +zone=30 +ellps=GRS80 +units=m +no_defs +type=crs'
      );
      register(proj4);
    }

    // Crear fuente vectorial con la geometría
    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(
        {
            type: 'Feature',
            geometry: geometry, // Usamos directamente la geometría del evento
          },
        {
        dataProjection: EPSG_ORIGIN,
        featureProjection: EPSG3857,
      }),
    });

    // Obtener la extensión de la geometría para ajustar el mapa
    const extent = vectorSource.getExtent();

    // Crear capa vectorial
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    // Crear mapa
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSMSource(),
        }),
        vectorLayer,
      ],
      view: new View({
        projection: EPSG3857,
        center: transform(geometry.coordinates, EPSG_ORIGIN, EPSG3857),
        zoom: 12,
      }),
    });

    // Ajustar vista al vector si es necesario
    map.getView().fit(extent, { padding: [50, 50, 50, 50] });

    return () => {
      map.setTarget(null);
    };
  }, [geometry]);

  return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
}

export default EventMap;
