
import { none } from "ol/centerconstraint";
import LayerClass, { legendBoxes, hexToRgb, fullModalInfo } from "./LayerCommons";

const legend=() => legendBoxes([
    { color: "#d7191c", text: "Contiene amianto" },
    // { color: "#fdae61", text: "Alto" },
    // { color: "#a6d96a", text: "Medio" },
    // { color: "#1a9641", text: "Bajo" },
  ]);
const background=(feature) => {

    let defaultColor = "100, 100, 255, 0.4";
    if(is_amianto(feature)){
      return hexToRgb("#d7191c")+", 1";
    }else{
      return defaultColor
    }
   // console.log("feature color NO EXITO VALUES", feature)
    return defaultColor;
  };

const tooltip = (feature)=>{
 if(is_amianto(feature)){
   return "Localización con amianto"
 }
 else{
   return "Localización sin amianto"
 }
//    // if (
//    //   "features" in feature.values_ &&
//    //   feature.values_.features.length >= 1 &&
//    //   "beginning" in feature.values_.features[0].values_
//    // ) {
//    //   return none
//    // }
};

const is_amianto = (feature)=>{

  if (feature && "values_" in feature &&
    "features" in feature.values_ &&
    feature.values_.features.length >= 1 &&
    "is_amianto" in feature.values_.features[0].values_
  ) {
      const is_amianto = feature.values_.features[0].values_["is_amianto"]
      //console.log("feature color values_", priority)

      if(is_amianto){
        return true
      }else{
        return false
      }
    }
    console.debug("is_amianto feature", feature)
  return false;
}

const modal = (feature)=>{
 //if(is_amianto(feature)){
    console.log("Layer is amianto modal feature", feature)
    return fullModalInfo(feature)
  //}
  //return false
}


export const LAYER_IS_AMIANTO = new LayerClass(
    "Contiene amianto",
    legend,
    background,
    tooltip,
    modal
  );