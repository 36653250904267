
import LayerClass, { legendBoxes, hexToRgb } from "./LayerCommons";

const legend=() => legendBoxes([
    { color: "#d7191c", text: "Muy alto" },
    { color: "#fdae61", text: "Alto" },
    { color: "#a6d96a", text: "Medio" },
    { color: "#1a9641", text: "Bajo" },
  ]);
const background=(feature) => {
    //colors: ['#fdae61','#1a9641','#a6d96a','#d7191c' ], //['#d7191c', '#fdae61', '#a6d96a','#1a9641' ], // Colores para las categorías
    //           alta      BAJA      media  muy alta       
    const colors = {
        "Muy Alta" : hexToRgb("#d7191c"),
        "Alta"     : hexToRgb("#fdae61"), 
        "Media"    : hexToRgb("#a6d96a"), 
        "Baja"     : hexToRgb("#1a9641"),
      };
    let defaultColor = hexToRgb("#002a4a");
   // console.log("feature color ", feature)
    if (feature && "values_" in feature &&
      "features" in feature.values_ &&
      feature.values_.features.length >= 1 &&
      "priority_r" in feature.values_.features[0].values_
    ) {
        const priority = feature.values_.features[0].values_["priority_r"]
        //console.log("feature color values_", priority)

        if(Object.keys(colors).includes(priority) ){
          //console.log("feature color EXITO", colors[priority])
          return colors[priority]
        }else{
          console.log("feature color NO EXITO",priority,  colors[priority], feature.values_.features[0].values_)
          return defaultColor
        }
    }
    console.log("feature color NO EXITO VALUES", feature)
    return defaultColor;
  };

const tooltip = (feature)=>{
    if (
      "features" in feature.values_ &&
      feature.values_.features.length >= 1 &&
      "beginning" in feature.values_.features[0].values_
    ) {
      return "Probabilidad de contener amianto : "+feature.values_.features[0].values_["priority_r"]
    }
  };



export const LAYER_SUSCEPTIBILITY = new LayerClass(
    "Susceptibilidad de contener amianto",
    legend,
    background,
    tooltip
  );