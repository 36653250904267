const municipe_list = [
  // {
  //   municipio: "Bagà",
  //   provincia: "Barcelona",
  //   id: "08016",
  //   label: "Bagà (Barcelona)",
  // },
  // {
  //   municipio: "Albacete",
  //   provincia: "Albacete",
  //   id: "02003",
  //   label: "Albacete (Albacete)",
  // },
  // {
  //   municipio: "Alcaraz",
  //   provincia: "Albacete",
  //   id: "02008",
  //   label: "Alcaraz (Albacete)",
  // },
  // {
  //   municipio: "Hellín",
  //   provincia: "Albacete",
  //   id: "02037",
  //   label: "Hellín (Albacete)",
  // },
  // {
  //   municipio: "Casas de Ves",
  //   provincia: "Albacete",
  //   id: "02023",
  //   label: "Casas de Ves (Albacete)",
  // },
  // {
  //   municipio: "Nerpio",
  //   provincia: "Albacete",
  //   id: "02055",
  //   label: "Nerpio (Albacete)",
  // },
  // {
  //   municipio: "Bayarque",
  //   provincia: "Almería",
  //   id: "04021",
  //   label: "Bayarque (Almería)",
  // },
  // {
  //   municipio: "Nules",
  //   provincia: "Castellón",
  //   id: "12082",
  //   label: "Nules (Castellón)",
  // },
  // {
  //   municipio: "Arboleas",
  //   provincia: "Almería",
  //   id: "04017",
  //   label: "Arboleas (Almería)",
  // },
  // {
  //   municipio: "Moya",
  //   provincia: "Las Palmas",
  //   id: "35013",
  //   label: "Moya (Las Palmas)",
  // },
  // {
  //   municipio: "Villarrobledo",
  //   provincia: "Albacete",
  //   id: "02081",
  //   label: "Villarrobledo (Albacete)",
  // },
  // {
  //   municipio: "Yeste",
  //   provincia: "Albacete",
  //   id: "02086",
  //   label: "Yeste (Albacete)",
  // },
  // {
  //   municipio: "Alacant/Alicante",
  //   provincia: "Alicante",
  //   id: "03014",
  //   label: "Alacant/Alicante (Alicante)",
  // },
  // {
  //   municipio: "Benidorm",
  //   provincia: "Alicante",
  //   id: "03031",
  //   label: "Benidorm (Alicante)",
  // },
  // {
  //   municipio: "Altea",
  //   provincia: "Alicante",
  //   id: "03018",
  //   label: "Altea (Alicante)",
  // },
  // {
  //   municipio: "Calp",
  //   provincia: "Alicante",
  //   id: "03047",
  //   label: "Calp (Alicante)",
  // },
  // {
  //   municipio: "Poble Nou de Benitatxell, el/Benitachell",
  //   provincia: "Alicante",
  //   id: "03042",
  //   label: "Poble Nou de Benitatxell, el/Benitachell (Alicante)",
  // },
  // {
  //   municipio: "Dénia",
  //   provincia: "Alicante",
  //   id: "03063",
  //   label: "Dénia (Alicante)",
  // },
  // {
  //   municipio: "Orihuela",
  //   provincia: "Alicante",
  //   id: "03099",
  //   label: "Orihuela (Alicante)",
  // },
  // {
  //   municipio: "Torrevieja",
  //   provincia: "Alicante",
  //   id: "03133",
  //   label: "Torrevieja (Alicante)",
  // },
  // {
  //   municipio: "Santa Pola",
  //   provincia: "Alicante",
  //   id: "03121",
  //   label: "Santa Pola (Alicante)",
  // },
  // {
  //   municipio: "Teulada",
  //   provincia: "Alicante",
  //   id: "03128",
  //   label: "Teulada (Alicante)",
  // },
  // {
  //   municipio: "Adra",
  //   provincia: "Almería",
  //   id: "04003",
  //   label: "Adra (Almería)",
  // },
  // {
  //   municipio: "Abrucena",
  //   provincia: "Almería",
  //   id: "04002",
  //   label: "Abrucena (Almería)",
  // },
  // {
  //   municipio: "Alcudia de Monteagud",
  //   provincia: "Almería",
  //   id: "04009",
  //   label: "Alcudia de Monteagud (Almería)",
  // },
  // {
  //   municipio: "Almería",
  //   provincia: "Almería",
  //   id: "04013",
  //   label: "Almería (Almería)",
  // },
  // {
  //   municipio: "Carboneras",
  //   provincia: "Almería",
  //   id: "04032",
  //   label: "Carboneras (Almería)",
  // },
  // {
  //   municipio: "Cóbdar",
  //   provincia: "Almería",
  //   id: "04034",
  //   label: "Cóbdar (Almería)",
  // },
  // {
  //   municipio: "Cuevas del Almanzora",
  //   provincia: "Almería",
  //   id: "04035",
  //   label: "Cuevas del Almanzora (Almería)",
  // },
  // {
  //   municipio: "Dalías",
  //   provincia: "Almería",
  //   id: "04038",
  //   label: "Dalías (Almería)",
  // },
  // {
  //   municipio: "Lubrín",
  //   provincia: "Almería",
  //   id: "04059",
  //   label: "Lubrín (Almería)",
  // },
  // {
  //   municipio: "Lúcar",
  //   provincia: "Almería",
  //   id: "04061",
  //   label: "Lúcar (Almería)",
  // },
  // {
  //   municipio: "Níjar",
  //   provincia: "Almería",
  //   id: "04066",
  //   label: "Níjar (Almería)",
  // },
  // {
  //   municipio: "Roquetas de Mar",
  //   provincia: "Almería",
  //   id: "04079",
  //   label: "Roquetas de Mar (Almería)",
  // },
  // {
  //   municipio: "Serón",
  //   provincia: "Almería",
  //   id: "04083",
  //   label: "Serón (Almería)",
  // },
  // {
  //   municipio: "Tahal",
  //   provincia: "Almería",
  //   id: "04090",
  //   label: "Tahal (Almería)",
  // },
  // {
  //   municipio: "Tíjola",
  //   provincia: "Almería",
  //   id: "04092",
  //   label: "Tíjola (Almería)",
  // },
  // {
  //   municipio: "Vélez-Blanco",
  //   provincia: "Almería",
  //   id: "04098",
  //   label: "Vélez-Blanco (Almería)",
  // },
  // {
  //   municipio: "Ejido, El",
  //   provincia: "Almería",
  //   id: "04902",
  //   label: "Ejido, El (Almería)",
  // },
  // {
  //   municipio: "Ávila",
  //   provincia: "Ávila",
  //   id: "05019",
  //   label: "Ávila (Ávila)",
  // },
  // {
  //   municipio: "Cebreros",
  //   provincia: "Ávila",
  //   id: "05057",
  //   label: "Cebreros (Ávila)",
  // },
  // {
  //   municipio: "Hoyo de Pinares, El",
  //   provincia: "Ávila",
  //   id: "05102",
  //   label: "Hoyo de Pinares, El (Ávila)",
  // },
  // {
  //   municipio: "Tiemblo, El",
  //   provincia: "Ávila",
  //   id: "05241",
  //   label: "Tiemblo, El (Ávila)",
  // },
  // {
  //   municipio: "Alburquerque",
  //   provincia: "Badajoz",
  //   id: "06006",
  //   label: "Alburquerque (Badajoz)",
  // },
  // {
  //   municipio: "Alconchel",
  //   provincia: "Badajoz",
  //   id: "06007",
  //   label: "Alconchel (Badajoz)",
  // },
  // {
  //   municipio: "Arroyo de San Serván",
  //   provincia: "Badajoz",
  //   id: "06012",
  //   label: "Arroyo de San Serván (Badajoz)",
  // },
  // {
  //   municipio: "Badajoz",
  //   provincia: "Badajoz",
  //   id: "06015",
  //   label: "Badajoz (Badajoz)",
  // },
  // {
  //   municipio: "Don Benito",
  //   provincia: "Badajoz",
  //   id: "06044",
  //   label: "Don Benito (Badajoz)",
  // },
  // {
  //   municipio: "Guareña",
  //   provincia: "Badajoz",
  //   id: "06060",
  //   label: "Guareña (Badajoz)",
  // },
  // {
  //   municipio: "Capdepera",
  //   provincia: "Islas Baleares",
  //   id: "07014",
  //   label: "Capdepera (Islas Baleares)",
  // },
  // {
  //   municipio: "Manchita",
  //   provincia: "Badajoz",
  //   id: "06079",
  //   label: "Manchita (Badajoz)",
  // },
  // {
  //   municipio: "Jerez de los Caballeros",
  //   provincia: "Badajoz",
  //   id: "06070",
  //   label: "Jerez de los Caballeros (Badajoz)",
  // },
  // {
  //   municipio: "Mérida",
  //   provincia: "Badajoz",
  //   id: "06083",
  //   label: "Mérida (Badajoz)",
  // },
  // {
  //   municipio: "Monesterio",
  //   provincia: "Badajoz",
  //   id: "06085",
  //   label: "Monesterio (Badajoz)",
  // },
  // {
  //   municipio: "Olivenza",
  //   provincia: "Badajoz",
  //   id: "06095",
  //   label: "Olivenza (Badajoz)",
  // },
  // {
  //   municipio: "Puebla de Alcocer",
  //   provincia: "Badajoz",
  //   id: "06102",
  //   label: "Puebla de Alcocer (Badajoz)",
  // },
  // {
  //   municipio: "San Vicente de Alcántara",
  //   provincia: "Badajoz",
  //   id: "06123",
  //   label: "San Vicente de Alcántara (Badajoz)",
  // },
  // {
  //   municipio: "Villanueva del Fresno",
  //   provincia: "Badajoz",
  //   id: "06154",
  //   label: "Villanueva del Fresno (Badajoz)",
  // },
  // {
  //   municipio: "Zahínos",
  //   provincia: "Badajoz",
  //   id: "06159",
  //   label: "Zahínos (Badajoz)",
  // },
  // {
  //   municipio: "Alaior",
  //   provincia: "Islas Baleares",
  //   id: "07002",
  //   label: "Alaior (Islas Baleares)",
  // },
  // {
  //   municipio: "Alcúdia",
  //   provincia: "Islas Baleares",
  //   id: "07003",
  //   label: "Alcúdia (Islas Baleares)",
  // },
  // {
  //   municipio: "Andratx",
  //   provincia: "Islas Baleares",
  //   id: "07005",
  //   label: "Andratx (Islas Baleares)",
  // },
  // {
  //   municipio: "Artà",
  //   provincia: "Islas Baleares",
  //   id: "07006",
  //   label: "Artà (Islas Baleares)",
  // },
  // {
  //   municipio: "Calvià",
  //   provincia: "Islas Baleares",
  //   id: "07011",
  //   label: "Calvià (Islas Baleares)",
  // },
  // {
  //   municipio: "Ciutadella de Menorca",
  //   provincia: "Islas Baleares",
  //   id: "07015",
  //   label: "Ciutadella de Menorca (Islas Baleares)",
  // },
  // {
  //   municipio: "Deià",
  //   provincia: "Islas Baleares",
  //   id: "07018",
  //   label: "Deià (Islas Baleares)",
  // },
  // {
  //   municipio: "Escorca",
  //   provincia: "Islas Baleares",
  //   id: "07019",
  //   label: "Escorca (Islas Baleares)",
  // },
  // {
  //   municipio: "Felanitx",
  //   provincia: "Islas Baleares",
  //   id: "07022",
  //   label: "Felanitx (Islas Baleares)",
  // },
  // {
  //   municipio: "Ferreries",
  //   provincia: "Islas Baleares",
  //   id: "07023",
  //   label: "Ferreries (Islas Baleares)",
  // },
  // {
  //   provincia: "Islas Baleares",
  //   id: "07054",
  //   municipio: "Santa Eulària des Riu",
  //   label: "Santa Eulària des Riu (Islas Baleares)",
  // },
  // {
  //   municipio: "Formentera",
  //   provincia: "Islas Baleares",
  //   id: "07024",
  //   label: "Formentera (Islas Baleares)",
  // },
  // {
  //   municipio: "Eivissa",
  //   provincia: "Islas Baleares",
  //   id: "07026",
  //   label: "Eivissa (Islas Baleares)",
  // },
  // {
  //   municipio: "Llucmajor",
  //   provincia: "Islas Baleares",
  //   id: "07031",
  //   label: "Llucmajor (Islas Baleares)",
  // },
  // {
  //   municipio: "Maó",
  //   provincia: "Islas Baleares",
  //   id: "07032",
  //   label: "Maó (Islas Baleares)",
  // },
  // {
  //   municipio: "Manacor",
  //   provincia: "Islas Baleares",
  //   id: "07033",
  //   label: "Manacor (Islas Baleares)",
  // },
  // {
  //   municipio: "Mercadal, Es",
  //   provincia: "Islas Baleares",
  //   id: "07037",
  //   label: "Mercadal, Es (Islas Baleares)",
  // },
  // {
  //   municipio: "Palma",
  //   provincia: "Islas Baleares",
  //   id: "07040",
  //   label: "Palma (Islas Baleares)",
  // },
  // {
  //   municipio: "Pollença",
  //   provincia: "Islas Baleares",
  //   id: "07042",
  //   label: "Pollença (Islas Baleares)",
  // },
  // {
  //   municipio: "Sant Antoni de Portmany",
  //   provincia: "Islas Baleares",
  //   id: "07046",
  //   label: "Sant Antoni de Portmany (Islas Baleares)",
  // },
  // {
  //   municipio: "Sant Josep de sa Talaia",
  //   provincia: "Islas Baleares",
  //   id: "07048",
  //   label: "Sant Josep de sa Talaia (Islas Baleares)",
  // },
  // {
  //   municipio: "Sant Joan de Labritja",
  //   provincia: "Islas Baleares",
  //   id: "07050",
  //   label: "Sant Joan de Labritja (Islas Baleares)",
  // },
  // {
  //   municipio: "Sant Lluís",
  //   provincia: "Islas Baleares",
  //   id: "07052",
  //   label: "Sant Lluís (Islas Baleares)",
  // },
  // {
  //   municipio: "Santanyí",
  //   provincia: "Islas Baleares",
  //   id: "07057",
  //   label: "Santanyí (Islas Baleares)",
  // },
  // {
  //   municipio: "Salines, Ses",
  //   provincia: "Islas Baleares",
  //   id: "07059",
  //   label: "Salines, Ses (Islas Baleares)",
  // },
  // {
  //   municipio: "Sóller",
  //   provincia: "Islas Baleares",
  //   id: "07061",
  //   label: "Sóller (Islas Baleares)",
  // },
  // {
  //   municipio: "Migjorn Gran, Es",
  //   provincia: "Islas Baleares",
  //   id: "07902",
  //   label: "Migjorn Gran, Es (Islas Baleares)",
  // },
  // {
  //   municipio: "Aguilar de Segarra",
  //   provincia: "Barcelona",
  //   id: "08002",
  //   label: "Aguilar de Segarra (Barcelona)",
  // },
  // {
  //   municipio: "Argençola",
  //   provincia: "Barcelona",
  //   id: "08008",
  //   label: "Argençola (Barcelona)",
  // },
  // {
  //   municipio: "Avinyó",
  //   provincia: "Barcelona",
  //   id: "08012",
  //   label: "Avinyó (Barcelona)",
  // },
  // {
  //   municipio: "Avinyonet del Penedès",
  //   provincia: "Barcelona",
  //   id: "08013",
  //   label: "Avinyonet del Penedès (Barcelona)",
  // },
  // {
  //   municipio: "Balenyà",
  //   provincia: "Barcelona",
  //   id: "08017",
  //   label: "Balenyà (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019",
  //   label: "Barcelona (Barcelona)",
  // },
  // {
  //   municipio: "Borredà",
  //   provincia: "Barcelona",
  //   id: "08024",
  //   label: "Borredà (Barcelona)",
  // },
  // {
  //   municipio: "Bruc, El",
  //   provincia: "Barcelona",
  //   id: "08025",
  //   label: "Bruc, El (Barcelona)",
  // },
  // {
  //   municipio: "Brull, El",
  //   provincia: "Barcelona",
  //   id: "08026",
  //   label: "Brull, El (Barcelona)",
  // },
  // {
  //   municipio: "Cabrera d'Anoia",
  //   provincia: "Barcelona",
  //   id: "08028",
  //   label: "Cabrera d'Anoia (Barcelona)",
  // },
  // {
  //   municipio: "Caldes de Montbui",
  //   provincia: "Barcelona",
  //   id: "08033",
  //   label: "Caldes de Montbui (Barcelona)",
  // },
  // {
  //   municipio: "Calonge de Segarra",
  //   provincia: "Barcelona",
  //   id: "08036",
  //   label: "Calonge de Segarra (Barcelona)",
  // },
  // {
  //   municipio: "Callús",
  //   provincia: "Barcelona",
  //   id: "08038",
  //   label: "Callús (Barcelona)",
  // },
  // {
  //   municipio: "Casserres",
  //   provincia: "Barcelona",
  //   id: "08049",
  //   label: "Casserres (Barcelona)",
  // },
  // {
  //   municipio: "Cardona",
  //   provincia: "Barcelona",
  //   id: "08047",
  //   label: "Cardona (Barcelona)",
  // },
  // {
  //   municipio: "Carme",
  //   provincia: "Barcelona",
  //   id: "08048",
  //   label: "Carme (Barcelona)",
  // },
  // {
  //   municipio: "Castellar de n'Hug",
  //   provincia: "Barcelona",
  //   id: "08052",
  //   label: "Castellar de n'Hug (Barcelona)",
  // },
  // {
  //   municipio: "Castellbell i el Vilar",
  //   provincia: "Barcelona",
  //   id: "08053",
  //   label: "Castellbell i el Vilar (Barcelona)",
  // },
  // {
  //   municipio: "Castellbisbal",
  //   provincia: "Barcelona",
  //   id: "08054",
  //   label: "Castellbisbal (Barcelona)",
  // },
  // {
  //   municipio: "Castell de l'Areny",
  //   provincia: "Barcelona",
  //   id: "08057",
  //   label: "Castell de l'Areny (Barcelona)",
  // },
  // {
  //   municipio: "Castellfollit del Boix",
  //   provincia: "Barcelona",
  //   id: "08059",
  //   label: "Castellfollit del Boix (Barcelona)",
  // },
  // {
  //   municipio: "Castellnou de Bages",
  //   provincia: "Barcelona",
  //   id: "08062",
  //   label: "Castellnou de Bages (Barcelona)",
  // },
  // {
  //   municipio: "Castellolí",
  //   provincia: "Barcelona",
  //   id: "08063",
  //   label: "Castellolí (Barcelona)",
  // },
  // {
  //   municipio: "Castellví de Rosanes",
  //   provincia: "Barcelona",
  //   id: "08066",
  //   label: "Castellví de Rosanes (Barcelona)",
  // },
  // {
  //   municipio: "Cervelló",
  //   provincia: "Barcelona",
  //   id: "08068",
  //   label: "Cervelló (Barcelona)",
  // },
  // {
  //   municipio: "Collbató",
  //   provincia: "Barcelona",
  //   id: "08069",
  //   label: "Collbató (Barcelona)",
  // },
  // {
  //   municipio: "Copons",
  //   provincia: "Barcelona",
  //   id: "08071",
  //   label: "Copons (Barcelona)",
  // },
  // {
  //   municipio: "Corbera de Llobregat",
  //   provincia: "Barcelona",
  //   id: "08072",
  //   label: "Corbera de Llobregat (Barcelona)",
  // },
  // {
  //   municipio: "Esparreguera",
  //   provincia: "Barcelona",
  //   id: "08076",
  //   label: "Esparreguera (Barcelona)",
  // },
  // {
  //   municipio: "Gelida",
  //   provincia: "Barcelona",
  //   id: "08091",
  //   label: "Gelida (Barcelona)",
  // },
  // {
  //   municipio: "Gisclareny",
  //   provincia: "Barcelona",
  //   id: "08093",
  //   label: "Gisclareny (Barcelona)",
  // },
  // {
  //   municipio: "Granera",
  //   provincia: "Barcelona",
  //   id: "08095",
  //   label: "Granera (Barcelona)",
  // },
  // {
  //   municipio: "Guardiola de Berguedà",
  //   provincia: "Barcelona",
  //   id: "08099",
  //   label: "Guardiola de Berguedà (Barcelona)",
  // },
  // {
  //   municipio: "Gurb",
  //   provincia: "Barcelona",
  //   id: "08100",
  //   label: "Gurb (Barcelona)",
  // },
  // {
  //   municipio: "Igualada",
  //   provincia: "Barcelona",
  //   id: "08102",
  //   label: "Igualada (Barcelona)",
  // },
  // {
  //   municipio: "Jorba",
  //   provincia: "Barcelona",
  //   id: "08103",
  //   label: "Jorba (Barcelona)",
  // },
  // {
  //   municipio: "Lluçà",
  //   provincia: "Barcelona",
  //   id: "08109",
  //   label: "Lluçà (Barcelona)",
  // },
  // {
  //   municipio: "Masquefa",
  //   provincia: "Barcelona",
  //   id: "08119",
  //   label: "Masquefa (Barcelona)",
  // },
  // {
  //   municipio: "Monistrol de Montserrat",
  //   provincia: "Barcelona",
  //   id: "08127",
  //   label: "Monistrol de Montserrat (Barcelona)",
  // },
  // {
  //   municipio: "Muntanyola",
  //   provincia: "Barcelona",
  //   id: "08129",
  //   label: "Muntanyola (Barcelona)",
  // },
  // {
  //   municipio: "Montmajor",
  //   provincia: "Barcelona",
  //   id: "08132",
  //   label: "Montmajor (Barcelona)",
  // },
  // {
  //   municipio: "Montseny",
  //   provincia: "Barcelona",
  //   id: "08137",
  //   label: "Montseny (Barcelona)",
  // },
  // {
  //   municipio: "Quar, La",
  //   provincia: "Barcelona",
  //   id: "08177",
  //   label: "Quar, La (Barcelona)",
  // },
  // {
  //   municipio: "Sant Mateu de Bages",
  //   provincia: "Barcelona",
  //   id: "08229",
  //   label: "Sant Mateu de Bages (Barcelona)",
  // },
  // {
  //   municipio: "Vilassar de Dalt",
  //   provincia: "Barcelona",
  //   id: "08214",
  //   label: "Vilassar de Dalt (Barcelona)",
  // },
  // {
  //   municipio: "Sant Pere de Torelló",
  //   provincia: "Barcelona",
  //   id: "08233",
  //   label: "Sant Pere de Torelló (Barcelona)",
  // },
  // {
  //   municipio: "Sant Sadurní d'Osormort",
  //   provincia: "Barcelona",
  //   id: "08241",
  //   label: "Sant Sadurní d'Osormort (Barcelona)",
  // },
  // {
  //   municipio: "Marganell",
  //   provincia: "Barcelona",
  //   id: "08242",
  //   label: "Marganell (Barcelona)",
  // },
  // {
  //   municipio: "Santa Margarida de Montbui",
  //   provincia: "Barcelona",
  //   id: "08250",
  //   label: "Santa Margarida de Montbui (Barcelona)",
  // },
  // {
  //   municipio: "Santa Maria de Besora",
  //   provincia: "Barcelona",
  //   id: "08253",
  //   label: "Santa Maria de Besora (Barcelona)",
  // },
  // {
  //   municipio: "Olvan",
  //   provincia: "Barcelona",
  //   id: "08144",
  //   label: "Olvan (Barcelona)",
  // },
  // {
  //   municipio: "Mura",
  //   provincia: "Barcelona",
  //   id: "08139",
  //   label: "Mura (Barcelona)",
  // },
  // {
  //   municipio: "Navàs",
  //   provincia: "Barcelona",
  //   id: "08141",
  //   label: "Navàs (Barcelona)",
  // },
  // {
  //   municipio: "Òdena",
  //   provincia: "Barcelona",
  //   id: "08143",
  //   label: "Òdena (Barcelona)",
  // },
  // {
  //   municipio: "Piera",
  //   provincia: "Barcelona",
  //   id: "08161",
  //   label: "Piera (Barcelona)",
  // },
  // {
  //   municipio: "Hostalets de Pierola, Els",
  //   provincia: "Barcelona",
  //   id: "08162",
  //   label: "Hostalets de Pierola, Els (Barcelona)",
  // },
  // {
  //   municipio: "Pobla de Claramunt, La",
  //   provincia: "Barcelona",
  //   id: "08165",
  //   label: "Pobla de Claramunt, La (Barcelona)",
  // },
  // {
  //   municipio: "Pobla de Lillet, La",
  //   provincia: "Barcelona",
  //   id: "08166",
  //   label: "Pobla de Lillet, La (Barcelona)",
  // },
  // {
  //   municipio: "Prats de Rei, Els",
  //   provincia: "Barcelona",
  //   id: "08170",
  //   label: "Prats de Rei, Els (Barcelona)",
  // },
  // {
  //   municipio: "Puig-reig",
  //   provincia: "Barcelona",
  //   id: "08175",
  //   label: "Puig-reig (Barcelona)",
  // },
  // {
  //   municipio: "Pujalt",
  //   provincia: "Barcelona",
  //   id: "08176",
  //   label: "Pujalt (Barcelona)",
  // },
  // {
  //   municipio: "Viladrau",
  //   provincia: "Gerona",
  //   id: "17220",
  //   label: "Viladrau (Gerona)",
  // },
  // {
  //   municipio: "Sagàs",
  //   provincia: "Barcelona",
  //   id: "08188",
  //   label: "Sagàs (Barcelona)",
  // },
  // {
  //   municipio: "Rubí",
  //   provincia: "Barcelona",
  //   id: "08184",
  //   label: "Rubí (Barcelona)",
  // },
  // {
  //   municipio: "Rubió",
  //   provincia: "Barcelona",
  //   id: "08185",
  //   label: "Rubió (Barcelona)",
  // },
  // {
  //   municipio: "Sant Pere Sallavinera",
  //   provincia: "Barcelona",
  //   id: "08189",
  //   label: "Sant Pere Sallavinera (Barcelona)",
  // },
  // {
  //   municipio: "Saldes",
  //   provincia: "Barcelona",
  //   id: "08190",
  //   label: "Saldes (Barcelona)",
  // },
  // {
  //   municipio: "Sallent",
  //   provincia: "Barcelona",
  //   id: "08191",
  //   label: "Sallent (Barcelona)",
  // },
  // {
  //   municipio: "Sant Iscle de Vallalta",
  //   provincia: "Barcelona",
  //   id: "08193",
  //   label: "Sant Iscle de Vallalta (Barcelona)",
  // },
  // {
  //   municipio: "Sant Bartomeu del Grau",
  //   provincia: "Barcelona",
  //   id: "08199",
  //   label: "Sant Bartomeu del Grau (Barcelona)",
  // },
  // {
  //   municipio: "Sant Celoni",
  //   provincia: "Barcelona",
  //   id: "08202",
  //   label: "Sant Celoni (Barcelona)",
  // },
  // {
  //   municipio: "Sant Jaume de Frontanyà",
  //   provincia: "Barcelona",
  //   id: "08216",
  //   label: "Sant Jaume de Frontanyà (Barcelona)",
  // },
  // {
  //   municipio: "Sant Llorenç d'Hortons",
  //   provincia: "Barcelona",
  //   id: "08222",
  //   label: "Sant Llorenç d'Hortons (Barcelona)",
  // },
  // {
  //   municipio: "Sant Martí de Centelles",
  //   provincia: "Barcelona",
  //   id: "08224",
  //   label: "Sant Martí de Centelles (Barcelona)",
  // },
  // {
  //   municipio: "Santa Maria d'Oló",
  //   provincia: "Barcelona",
  //   id: "08258",
  //   label: "Santa Maria d'Oló (Barcelona)",
  // },
  // {
  //   municipio: "Santa Susanna",
  //   provincia: "Barcelona",
  //   id: "08261",
  //   label: "Santa Susanna (Barcelona)",
  // },
  // {
  //   municipio: "Seva",
  //   provincia: "Barcelona",
  //   id: "08269",
  //   label: "Seva (Barcelona)",
  // },
  // {
  //   municipio: "Sitges",
  //   provincia: "Barcelona",
  //   id: "08270",
  //   label: "Sitges (Barcelona)",
  // },
  // {
  //   municipio: "Esquirol, L'",
  //   provincia: "Barcelona",
  //   id: "08254",
  //   label: "Esquirol, L' (Barcelona)",
  // },
  // {
  //   municipio: "Subirats",
  //   provincia: "Barcelona",
  //   id: "08273",
  //   label: "Subirats (Barcelona)",
  // },
  // {
  //   municipio: "Tavèrnoles",
  //   provincia: "Barcelona",
  //   id: "08275",
  //   label: "Tavèrnoles (Barcelona)",
  // },
  // {
  //   municipio: "Tagamanent",
  //   provincia: "Barcelona",
  //   id: "08276",
  //   label: "Tagamanent (Barcelona)",
  // },
  // {
  //   municipio: "Taradell",
  //   provincia: "Barcelona",
  //   id: "08278",
  //   label: "Taradell (Barcelona)",
  // },
  // {
  //   municipio: "Terrassa",
  //   provincia: "Barcelona",
  //   id: "08279",
  //   label: "Terrassa (Barcelona)",
  // },
  // {
  //   municipio: "Tavertet",
  //   provincia: "Barcelona",
  //   id: "08280",
  //   label: "Tavertet (Barcelona)",
  // },
  // {
  //   municipio: "Tordera",
  //   provincia: "Barcelona",
  //   id: "08284",
  //   label: "Tordera (Barcelona)",
  // },
  // {
  //   municipio: "Vallbona d'Anoia",
  //   provincia: "Barcelona",
  //   id: "08292",
  //   label: "Vallbona d'Anoia (Barcelona)",
  // },
  // {
  //   municipio: "Vallcebre",
  //   provincia: "Barcelona",
  //   id: "08293",
  //   label: "Vallcebre (Barcelona)",
  // },
  // {
  //   municipio: "Casas de Miravete",
  //   provincia: "Cáceres",
  //   id: "10057",
  //   label: "Casas de Miravete (Cáceres)",
  // },
  // {
  //   municipio: "Galisteo",
  //   provincia: "Cáceres",
  //   id: "10076",
  //   label: "Galisteo (Cáceres)",
  // },
  // {
  //   municipio: "Jaraicejo",
  //   provincia: "Cáceres",
  //   id: "10103",
  //   label: "Jaraicejo (Cáceres)",
  // },
  // {
  //   municipio: "Malpartida de Plasencia",
  //   provincia: "Cáceres",
  //   id: "10116",
  //   label: "Malpartida de Plasencia (Cáceres)",
  // },
  // {
  //   municipio: "Montánchez",
  //   provincia: "Cáceres",
  //   id: "10126",
  //   label: "Montánchez (Cáceres)",
  // },
  // {
  //   municipio: "Moraleja",
  //   provincia: "Cáceres",
  //   id: "10128",
  //   label: "Moraleja (Cáceres)",
  // },
  // {
  //   municipio: "Serradilla",
  //   provincia: "Cáceres",
  //   id: "10175",
  //   label: "Serradilla (Cáceres)",
  // },
  // {
  //   municipio: "Talayuela",
  //   provincia: "Cáceres",
  //   id: "10180",
  //   label: "Talayuela (Cáceres)",
  // },
  // {
  //   municipio: "Trujillo",
  //   provincia: "Cáceres",
  //   id: "10195",
  //   label: "Trujillo (Cáceres)",
  // },
  // {
  //   municipio: "Valencia de Alcántara",
  //   provincia: "Cáceres",
  //   id: "10203",
  //   label: "Valencia de Alcántara (Cáceres)",
  // },
  // {
  //   municipio: "Viladecans",
  //   provincia: "Barcelona",
  //   id: "08301",
  //   label: "Viladecans (Barcelona)",
  // },
  // {
  //   municipio: "Veciana",
  //   provincia: "Barcelona",
  //   id: "08297",
  //   label: "Veciana (Barcelona)",
  // },
  // {
  //   municipio: "Vilada",
  //   provincia: "Barcelona",
  //   id: "08299",
  //   label: "Vilada (Barcelona)",
  // },
  // {
  //   municipio: "Vilanova del Camí",
  //   provincia: "Barcelona",
  //   id: "08302",
  //   label: "Vilanova del Camí (Barcelona)",
  // },
  // {
  //   municipio: "Vilanova de Sau",
  //   provincia: "Barcelona",
  //   id: "08303",
  //   label: "Vilanova de Sau (Barcelona)",
  // },
  // {
  //   municipio: "Viver i Serrateix",
  //   provincia: "Barcelona",
  //   id: "08308",
  //   label: "Viver i Serrateix (Barcelona)",
  // },
  // {
  //   municipio: "Rupit i Pruit",
  //   provincia: "Barcelona",
  //   id: "08901",
  //   label: "Rupit i Pruit (Barcelona)",
  // },
  // {
  //   municipio: "Belorado",
  //   provincia: "Burgos",
  //   id: "09048",
  //   label: "Belorado (Burgos)",
  // },
  // {
  //   municipio: "Condado de Treviño",
  //   provincia: "Burgos",
  //   id: "09109",
  //   label: "Condado de Treviño (Burgos)",
  // },
  // {
  //   municipio: "Alcántara",
  //   provincia: "Cáceres",
  //   id: "10008",
  //   label: "Alcántara (Cáceres)",
  // },
  // {
  //   municipio: "Oliva de Mérida",
  //   provincia: "Badajoz",
  //   id: "06094",
  //   label: "Oliva de Mérida (Badajoz)",
  // },
  // {
  //   municipio: "Arroyomolinos",
  //   provincia: "Cáceres",
  //   id: "10023",
  //   label: "Arroyomolinos (Cáceres)",
  // },
  // {
  //   municipio: "Cáceres",
  //   provincia: "Cáceres",
  //   id: "10037",
  //   label: "Cáceres (Cáceres)",
  // },
  // {
  //   municipio: "Valverde del Fresno",
  //   provincia: "Cáceres",
  //   id: "10205",
  //   label: "Valverde del Fresno (Cáceres)",
  // },
  // {
  //   municipio: "Rosalejo",
  //   provincia: "Cáceres",
  //   id: "10901",
  //   label: "Rosalejo (Cáceres)",
  // },
  // {
  //   municipio: "Vegaviana",
  //   provincia: "Cáceres",
  //   id: "10902",
  //   label: "Vegaviana (Cáceres)",
  // },
  // {
  //   municipio: "Alagón del Río",
  //   provincia: "Cáceres",
  //   id: "10903",
  //   label: "Alagón del Río (Cáceres)",
  // },
  // {
  //   municipio: "Alcalá de los Gazules",
  //   provincia: "Cádiz",
  //   id: "11001",
  //   label: "Alcalá de los Gazules (Cádiz)",
  // },
  // {
  //   municipio: "Algeciras",
  //   provincia: "Cádiz",
  //   id: "11004",
  //   label: "Algeciras (Cádiz)",
  // },
  // {
  //   municipio: "Arcos de la Frontera",
  //   provincia: "Cádiz",
  //   id: "11006",
  //   label: "Arcos de la Frontera (Cádiz)",
  // },
  // {
  //   municipio: "Barbate",
  //   provincia: "Cádiz",
  //   id: "11007",
  //   label: "Barbate (Cádiz)",
  // },
  // {
  //   municipio: "Barrios, Los",
  //   provincia: "Cádiz",
  //   id: "11008",
  //   label: "Barrios, Los (Cádiz)",
  // },
  // {
  //   municipio: "Castellar de la Frontera",
  //   provincia: "Cádiz",
  //   id: "11013",
  //   label: "Castellar de la Frontera (Cádiz)",
  // },
  // {
  //   municipio: "Conil de la Frontera",
  //   provincia: "Cádiz",
  //   id: "11014",
  //   label: "Conil de la Frontera (Cádiz)",
  // },
  // {
  //   municipio: "Jerez de la Frontera",
  //   provincia: "Cádiz",
  //   id: "11020",
  //   label: "Jerez de la Frontera (Cádiz)",
  // },
  // {
  //   municipio: "Jimena de la Frontera",
  //   provincia: "Cádiz",
  //   id: "11021",
  //   label: "Jimena de la Frontera (Cádiz)",
  // },
  // {
  //   municipio: "Medina Sidonia",
  //   provincia: "Cádiz",
  //   id: "11023",
  //   label: "Medina Sidonia (Cádiz)",
  // },
  // {
  //   municipio: "Puerto de Santa María, El",
  //   provincia: "Cádiz",
  //   id: "11027",
  //   label: "Puerto de Santa María, El (Cádiz)",
  // },
  // {
  //   municipio: "Puerto Real",
  //   provincia: "Cádiz",
  //   id: "11028",
  //   label: "Puerto Real (Cádiz)",
  // },
  // {
  //   municipio: "San Roque",
  //   provincia: "Cádiz",
  //   id: "11033",
  //   label: "San Roque (Cádiz)",
  // },
  // {
  //   municipio: "Tarifa",
  //   provincia: "Cádiz",
  //   id: "11035",
  //   label: "Tarifa (Cádiz)",
  // },
  // {
  //   municipio: "Vejer de la Frontera",
  //   provincia: "Cádiz",
  //   id: "11039",
  //   label: "Vejer de la Frontera (Cádiz)",
  // },
  // {
  //   municipio: "San José del Valle",
  //   provincia: "Cádiz",
  //   id: "11902",
  //   label: "San José del Valle (Cádiz)",
  // },
  // {
  //   municipio: "Alhambra",
  //   provincia: "Ciudad Real",
  //   id: "13010",
  //   label: "Alhambra (Ciudad Real)",
  // },
  // {
  //   municipio: "Abenójar",
  //   provincia: "Ciudad Real",
  //   id: "13001",
  //   label: "Abenójar (Ciudad Real)",
  // },
  // {
  //   municipio: "Almodóvar del Campo",
  //   provincia: "Ciudad Real",
  //   id: "13015",
  //   label: "Almodóvar del Campo (Ciudad Real)",
  // },
  // {
  //   municipio: "Argamasilla de Alba",
  //   provincia: "Ciudad Real",
  //   id: "13019",
  //   label: "Argamasilla de Alba (Ciudad Real)",
  // },
  // {
  //   municipio: "Borriana/Burriana",
  //   provincia: "Castellón",
  //   id: "12032",
  //   label: "Borriana/Burriana (Castellón)",
  // },
  // {
  //   municipio: "Peníscola/Peñíscola",
  //   provincia: "Castellón",
  //   id: "12089",
  //   label: "Peníscola/Peñíscola (Castellón)",
  // },
  // {
  //   municipio: "Morella",
  //   provincia: "Castellón",
  //   id: "12080",
  //   label: "Morella (Castellón)",
  // },
  // {
  //   municipio: "Vistabella del Maestrat",
  //   provincia: "Castellón",
  //   id: "12139",
  //   label: "Vistabella del Maestrat (Castellón)",
  // },
  // {
  //   municipio: "Alcázar de San Juan",
  //   provincia: "Ciudad Real",
  //   id: "13005",
  //   label: "Alcázar de San Juan (Ciudad Real)",
  // },
  // {
  //   municipio: "Ballesteros de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13022",
  //   label: "Ballesteros de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Cañada de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13029",
  //   label: "Cañada de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Ciudad Real",
  //   provincia: "Ciudad Real",
  //   id: "13034",
  //   label: "Ciudad Real (Ciudad Real)",
  // },
  // {
  //   municipio: "Herencia",
  //   provincia: "Ciudad Real",
  //   id: "13047",
  //   label: "Herencia (Ciudad Real)",
  // },
  // {
  //   municipio: "Manzanares",
  //   provincia: "Ciudad Real",
  //   id: "13053",
  //   label: "Manzanares (Ciudad Real)",
  // },
  // {
  //   municipio: "Montiel",
  //   provincia: "Ciudad Real",
  //   id: "13057",
  //   label: "Montiel (Ciudad Real)",
  // },
  // {
  //   municipio: "Puertollano",
  //   provincia: "Ciudad Real",
  //   id: "13071",
  //   label: "Puertollano (Ciudad Real)",
  // },
  // {
  //   municipio: "Socuéllamos",
  //   provincia: "Ciudad Real",
  //   id: "13078",
  //   label: "Socuéllamos (Ciudad Real)",
  // },
  // {
  //   municipio: "Torre de Juan Abad",
  //   provincia: "Ciudad Real",
  //   id: "13084",
  //   label: "Torre de Juan Abad (Ciudad Real)",
  // },
  // {
  //   municipio: "Valdepeñas",
  //   provincia: "Ciudad Real",
  //   id: "13087",
  //   label: "Valdepeñas (Ciudad Real)",
  // },
  // {
  //   municipio: "Aguilar de la Frontera",
  //   provincia: "Córdoba",
  //   id: "14002",
  //   label: "Aguilar de la Frontera (Córdoba)",
  // },
  // {
  //   municipio: "Alcaracejos",
  //   provincia: "Córdoba",
  //   id: "14003",
  //   label: "Alcaracejos (Córdoba)",
  // },
  // {
  //   municipio: "Añora",
  //   provincia: "Córdoba",
  //   id: "14006",
  //   label: "Añora (Córdoba)",
  // },
  // {
  //   municipio: "Baena",
  //   provincia: "Córdoba",
  //   id: "14007",
  //   label: "Baena (Córdoba)",
  // },
  // {
  //   municipio: "Cabra",
  //   provincia: "Córdoba",
  //   id: "14013",
  //   label: "Cabra (Córdoba)",
  // },
  // {
  //   municipio: "Cardeña",
  //   provincia: "Córdoba",
  //   id: "14016",
  //   label: "Cardeña (Córdoba)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021",
  //   label: "Córdoba (Córdoba)",
  // },
  // {
  //   municipio: "Dos Torres",
  //   provincia: "Córdoba",
  //   id: "14023",
  //   label: "Dos Torres (Córdoba)",
  // },
  // {
  //   municipio: "Espiel",
  //   provincia: "Córdoba",
  //   id: "14026",
  //   label: "Espiel (Córdoba)",
  // },
  // {
  //   municipio: "Fuente Palmera",
  //   provincia: "Córdoba",
  //   id: "14030",
  //   label: "Fuente Palmera (Córdoba)",
  // },
  // {
  //   municipio: "Hornachuelos",
  //   provincia: "Córdoba",
  //   id: "14036",
  //   label: "Hornachuelos (Córdoba)",
  // },
  // {
  //   municipio: "Iznájar",
  //   provincia: "Córdoba",
  //   id: "14037",
  //   label: "Iznájar (Córdoba)",
  // },
  // {
  //   municipio: "Lucena",
  //   provincia: "Córdoba",
  //   id: "14038",
  //   label: "Lucena (Córdoba)",
  // },
  // {
  //   municipio: "Luque",
  //   provincia: "Córdoba",
  //   id: "14039",
  //   label: "Luque (Córdoba)",
  // },
  // {
  //   municipio: "Montemayor",
  //   provincia: "Córdoba",
  //   id: "14041",
  //   label: "Montemayor (Córdoba)",
  // },
  // {
  //   municipio: "Montilla",
  //   provincia: "Córdoba",
  //   id: "14042",
  //   label: "Montilla (Córdoba)",
  // },
  // {
  //   municipio: "Montoro",
  //   provincia: "Córdoba",
  //   id: "14043",
  //   label: "Montoro (Córdoba)",
  // },
  // {
  //   municipio: "Monturque",
  //   provincia: "Córdoba",
  //   id: "14044",
  //   label: "Monturque (Córdoba)",
  // },
  // {
  //   municipio: "Nueva Carteya",
  //   provincia: "Córdoba",
  //   id: "14046",
  //   label: "Nueva Carteya (Córdoba)",
  // },
  // {
  //   municipio: "Pozoblanco",
  //   provincia: "Córdoba",
  //   id: "14054",
  //   label: "Pozoblanco (Córdoba)",
  // },
  // {
  //   municipio: "Priego de Córdoba",
  //   provincia: "Córdoba",
  //   id: "14055",
  //   label: "Priego de Córdoba (Córdoba)",
  // },
  // {
  //   municipio: "Rambla, La",
  //   provincia: "Córdoba",
  //   id: "14057",
  //   label: "Rambla, La (Córdoba)",
  // },
  // {
  //   municipio: "Rute",
  //   provincia: "Córdoba",
  //   id: "14058",
  //   label: "Rute (Córdoba)",
  // },
  // {
  //   municipio: "San Sebastián de los Ballesteros",
  //   provincia: "Córdoba",
  //   id: "14059",
  //   label: "San Sebastián de los Ballesteros (Córdoba)",
  // },
  // {
  //   municipio: "Santaella",
  //   provincia: "Córdoba",
  //   id: "14060",
  //   label: "Santaella (Córdoba)",
  // },
  // {
  //   municipio: "Victoria, La",
  //   provincia: "Córdoba",
  //   id: "14065",
  //   label: "Victoria, La (Córdoba)",
  // },
  // {
  //   municipio: "Villanueva de Córdoba",
  //   provincia: "Córdoba",
  //   id: "14069",
  //   label: "Villanueva de Córdoba (Córdoba)",
  // },
  // {
  //   municipio: "Villanueva del Duque",
  //   provincia: "Córdoba",
  //   id: "14070",
  //   label: "Villanueva del Duque (Córdoba)",
  // },
  // {
  //   municipio: "Villaviciosa de Córdoba",
  //   provincia: "Córdoba",
  //   id: "14073",
  //   label: "Villaviciosa de Córdoba (Córdoba)",
  // },
  // {
  //   municipio: "Ares",
  //   provincia: "La Coruña",
  //   id: "15004",
  //   label: "Ares (La Coruña)",
  // },
  // {
  //   municipio: "Arteixo",
  //   provincia: "La Coruña",
  //   id: "15005",
  //   label: "Arteixo (La Coruña)",
  // },
  // {
  //   municipio: "Boiro",
  //   provincia: "La Coruña",
  //   id: "15011",
  //   label: "Boiro (La Coruña)",
  // },
  // {
  //   municipio: "Cabana de Bergantiños",
  //   provincia: "La Coruña",
  //   id: "15014",
  //   label: "Cabana de Bergantiños (La Coruña)",
  // },
  // {
  //   municipio: "Camariñas",
  //   provincia: "La Coruña",
  //   id: "15016",
  //   label: "Camariñas (La Coruña)",
  // },
  // {
  //   municipio: "Carballo",
  //   provincia: "La Coruña",
  //   id: "15019",
  //   label: "Carballo (La Coruña)",
  // },
  // {
  //   municipio: "Carnota",
  //   provincia: "La Coruña",
  //   id: "15020",
  //   label: "Carnota (La Coruña)",
  // },
  // {
  //   municipio: "Cedeira",
  //   provincia: "La Coruña",
  //   id: "15022",
  //   label: "Cedeira (La Coruña)",
  // },
  // {
  //   municipio: "Cee",
  //   provincia: "La Coruña",
  //   id: "15023",
  //   label: "Cee (La Coruña)",
  // },
  // {
  //   municipio: "Corcubión",
  //   provincia: "La Coruña",
  //   id: "15028",
  //   label: "Corcubión (La Coruña)",
  // },
  // {
  //   municipio: "Coristanco",
  //   provincia: "La Coruña",
  //   id: "15029",
  //   label: "Coristanco (La Coruña)",
  // },
  // {
  //   municipio: "Coruña, A",
  //   provincia: "La Coruña",
  //   id: "15030",
  //   label: "Coruña, A (La Coruña)",
  // },
  // {
  //   municipio: "Curtis",
  //   provincia: "La Coruña",
  //   id: "15032",
  //   label: "Curtis (La Coruña)",
  // },
  // {
  //   municipio: "Ferrol",
  //   provincia: "La Coruña",
  //   id: "15036",
  //   label: "Ferrol (La Coruña)",
  // },
  // {
  //   municipio: "Fisterra",
  //   provincia: "La Coruña",
  //   id: "15037",
  //   label: "Fisterra (La Coruña)",
  // },
  // {
  //   municipio: "Laxe",
  //   provincia: "La Coruña",
  //   id: "15040",
  //   label: "Laxe (La Coruña)",
  // },
  // {
  //   municipio: "Laracha, A",
  //   provincia: "La Coruña",
  //   id: "15041",
  //   label: "Laracha, A (La Coruña)",
  // },
  // {
  //   municipio: "Malpica de Bergantiños",
  //   provincia: "La Coruña",
  //   id: "15043",
  //   label: "Malpica de Bergantiños (La Coruña)",
  // },
  // {
  //   municipio: "Mañón",
  //   provincia: "La Coruña",
  //   id: "15044",
  //   label: "Mañón (La Coruña)",
  // },
  // {
  //   municipio: "Monfero",
  //   provincia: "La Coruña",
  //   id: "15050",
  //   label: "Monfero (La Coruña)",
  // },
  // {
  //   municipio: "Muxía",
  //   provincia: "La Coruña",
  //   id: "15052",
  //   label: "Muxía (La Coruña)",
  // },
  // {
  //   municipio: "Muros",
  //   provincia: "La Coruña",
  //   id: "15053",
  //   label: "Muros (La Coruña)",
  // },
  // {
  //   municipio: "Narón",
  //   provincia: "La Coruña",
  //   id: "15054",
  //   label: "Narón (La Coruña)",
  // },
  // {
  //   municipio: "Oleiros",
  //   provincia: "La Coruña",
  //   id: "15058",
  //   label: "Oleiros (La Coruña)",
  // },
  // {
  //   municipio: "Ordes",
  //   provincia: "La Coruña",
  //   id: "15059",
  //   label: "Ordes (La Coruña)",
  // },
  // {
  //   municipio: "Ortigueira",
  //   provincia: "La Coruña",
  //   id: "15061",
  //   label: "Ortigueira (La Coruña)",
  // },
  // {
  //   municipio: "Ponteceso",
  //   provincia: "La Coruña",
  //   id: "15068",
  //   label: "Ponteceso (La Coruña)",
  // },
  // {
  //   municipio: "Pontedeume",
  //   provincia: "La Coruña",
  //   id: "15069",
  //   label: "Pontedeume (La Coruña)",
  // },
  // {
  //   municipio: "Pontes de García Rodríguez, As",
  //   provincia: "La Coruña",
  //   id: "15070",
  //   label: "Pontes de García Rodríguez, As (La Coruña)",
  // },
  // {
  //   municipio: "Porto do Son",
  //   provincia: "La Coruña",
  //   id: "15071",
  //   label: "Porto do Son (La Coruña)",
  // },
  // {
  //   municipio: "Rianxo",
  //   provincia: "La Coruña",
  //   id: "15072",
  //   label: "Rianxo (La Coruña)",
  // },
  // {
  //   municipio: "Ribeira",
  //   provincia: "La Coruña",
  //   id: "15073",
  //   label: "Ribeira (La Coruña)",
  // },
  // {
  //   municipio: "Sada",
  //   provincia: "La Coruña",
  //   id: "15075",
  //   label: "Sada (La Coruña)",
  // },
  // {
  //   municipio: "Valdoviño",
  //   provincia: "La Coruña",
  //   id: "15087",
  //   label: "Valdoviño (La Coruña)",
  // },
  // {
  //   municipio: "Vilasantar",
  //   provincia: "La Coruña",
  //   id: "15090",
  //   label: "Vilasantar (La Coruña)",
  // },
  // {
  //   municipio: "Cariño",
  //   provincia: "La Coruña",
  //   id: "15901",
  //   label: "Cariño (La Coruña)",
  // },
  // {
  //   municipio: "Barajas de Melo",
  //   provincia: "Cuenca",
  //   id: "16027",
  //   label: "Barajas de Melo (Cuenca)",
  // },
  // {
  //   municipio: "Cuenca",
  //   provincia: "Cuenca",
  //   id: "16078",
  //   label: "Cuenca (Cuenca)",
  // },
  // {
  //   municipio: "Albanyà",
  //   provincia: "Gerona",
  //   id: "17003",
  //   label: "Albanyà (Gerona)",
  // },
  // {
  //   municipio: "Amer",
  //   provincia: "Gerona",
  //   id: "17007",
  //   label: "Amer (Gerona)",
  // },
  // {
  //   municipio: "Argelaguer",
  //   provincia: "Gerona",
  //   id: "17010",
  //   label: "Argelaguer (Gerona)",
  // },
  // {
  //   municipio: "Begur",
  //   provincia: "Gerona",
  //   id: "17013",
  //   label: "Begur (Gerona)",
  // },
  // {
  //   municipio: "Banyoles",
  //   provincia: "Gerona",
  //   id: "17015",
  //   label: "Banyoles (Gerona)",
  // },
  // {
  //   municipio: "Blanes",
  //   provincia: "Gerona",
  //   id: "17023",
  //   label: "Blanes (Gerona)",
  // },
  // {
  //   municipio: "Cabanelles",
  //   provincia: "Gerona",
  //   id: "17031",
  //   label: "Cabanelles (Gerona)",
  // },
  // {
  //   municipio: "Cadaqués",
  //   provincia: "Gerona",
  //   id: "17032",
  //   label: "Cadaqués (Gerona)",
  // },
  // {
  //   municipio: "Caldes de Malavella",
  //   provincia: "Gerona",
  //   id: "17033",
  //   label: "Caldes de Malavella (Gerona)",
  // },
  // {
  //   municipio: "Calonge i Sant Antoni",
  //   provincia: "Gerona",
  //   id: "17034",
  //   label: "Calonge i Sant Antoni (Gerona)",
  // },
  // {
  //   municipio: "Campdevànol",
  //   provincia: "Gerona",
  //   id: "17036",
  //   label: "Campdevànol (Gerona)",
  // },
  // {
  //   municipio: "Camprodon",
  //   provincia: "Gerona",
  //   id: "17039",
  //   label: "Camprodon (Gerona)",
  // },
  // {
  //   municipio: "Canet d'Adri",
  //   provincia: "Gerona",
  //   id: "17040",
  //   label: "Canet d'Adri (Gerona)",
  // },
  // {
  //   municipio: "Queralbs",
  //   provincia: "Gerona",
  //   id: "17043",
  //   label: "Queralbs (Gerona)",
  // },
  // {
  //   municipio: "Cassà de la Selva",
  //   provincia: "Gerona",
  //   id: "17044",
  //   label: "Cassà de la Selva (Gerona)",
  // },
  // {
  //   municipio: "Castell-Platja d'Aro",
  //   provincia: "Gerona",
  //   id: "17048",
  //   label: "Castell-Platja d'Aro (Gerona)",
  // },
  // {
  //   municipio: "Colera",
  //   provincia: "Gerona",
  //   id: "17054",
  //   label: "Colera (Gerona)",
  // },
  // {
  //   municipio: "Cornellà del Terri",
  //   provincia: "Gerona",
  //   id: "17056",
  //   label: "Cornellà del Terri (Gerona)",
  // },
  // {
  //   municipio: "Darnius",
  //   provincia: "Gerona",
  //   id: "17060",
  //   label: "Darnius (Gerona)",
  // },
  // {
  //   municipio: "Escala, L'",
  //   provincia: "Gerona",
  //   id: "17062",
  //   label: "Escala, L' (Gerona)",
  // },
  // {
  //   municipio: "Esponellà",
  //   provincia: "Gerona",
  //   id: "17065",
  //   label: "Esponellà (Gerona)",
  // },
  // {
  //   municipio: "Fontcoberta",
  //   provincia: "Gerona",
  //   id: "17071",
  //   label: "Fontcoberta (Gerona)",
  // },
  // {
  //   municipio: "Cervià de Ter",
  //   provincia: "Gerona",
  //   id: "17050",
  //   label: "Cervià de Ter (Gerona)",
  // },
  // {
  //   municipio: "Gombrèn",
  //   provincia: "Gerona",
  //   id: "17080",
  //   label: "Gombrèn (Gerona)",
  // },
  // {
  //   municipio: "Mieres",
  //   provincia: "Gerona",
  //   id: "17105",
  //   label: "Mieres (Gerona)",
  // },
  // {
  //   municipio: "Llagostera",
  //   provincia: "Gerona",
  //   id: "17089",
  //   label: "Llagostera (Gerona)",
  // },
  // {
  //   municipio: "Llançà",
  //   provincia: "Gerona",
  //   id: "17092",
  //   label: "Llançà (Gerona)",
  // },
  // {
  //   municipio: "Lloret de Mar",
  //   provincia: "Gerona",
  //   id: "17095",
  //   label: "Lloret de Mar (Gerona)",
  // },
  // {
  //   municipio: "Llosses, Les",
  //   provincia: "Gerona",
  //   id: "17096",
  //   label: "Llosses, Les (Gerona)",
  // },
  // {
  //   municipio: "Montagut i Oix",
  //   provincia: "Gerona",
  //   id: "17109",
  //   label: "Montagut i Oix (Gerona)",
  // },
  // {
  //   municipio: "Navata",
  //   provincia: "Gerona",
  //   id: "17111",
  //   label: "Navata (Gerona)",
  // },
  // {
  //   municipio: "Ogassa",
  //   provincia: "Gerona",
  //   id: "17112",
  //   label: "Ogassa (Gerona)",
  // },
  // {
  //   municipio: "Osor",
  //   provincia: "Gerona",
  //   id: "17116",
  //   label: "Osor (Gerona)",
  // },
  // {
  //   municipio: "Palafrugell",
  //   provincia: "Gerona",
  //   id: "17117",
  //   label: "Palafrugell (Gerona)",
  // },
  // {
  //   municipio: "Palamós",
  //   provincia: "Gerona",
  //   id: "17118",
  //   label: "Palamós (Gerona)",
  // },
  // {
  //   municipio: "Pardines",
  //   provincia: "Gerona",
  //   id: "17125",
  //   label: "Pardines (Gerona)",
  // },
  // {
  //   municipio: "Planes d'Hostoles, Les",
  //   provincia: "Gerona",
  //   id: "17133",
  //   label: "Planes d'Hostoles, Les (Gerona)",
  // },
  // {
  //   municipio: "Porqueres",
  //   provincia: "Gerona",
  //   id: "17137",
  //   label: "Porqueres (Gerona)",
  // },
  // {
  //   municipio: "Portbou",
  //   provincia: "Gerona",
  //   id: "17138",
  //   label: "Portbou (Gerona)",
  // },
  // {
  //   municipio: "Port de la Selva, El",
  //   provincia: "Gerona",
  //   id: "17140",
  //   label: "Port de la Selva, El (Gerona)",
  // },
  // {
  //   municipio: "Ribes de Freser",
  //   provincia: "Gerona",
  //   id: "17145",
  //   label: "Ribes de Freser (Gerona)",
  // },
  // {
  //   municipio: "Cualedro",
  //   provincia: "Orense",
  //   id: "32028",
  //   label: "Cualedro (Orense)",
  // },
  // {
  //   municipio: "Ripoll",
  //   provincia: "Gerona",
  //   id: "17147",
  //   label: "Ripoll (Gerona)",
  // },
  // {
  //   municipio: "Roses",
  //   provincia: "Gerona",
  //   id: "17152",
  //   label: "Roses (Gerona)",
  // },
  // {
  //   municipio: "Sales de Llierca",
  //   provincia: "Gerona",
  //   id: "17154",
  //   label: "Sales de Llierca (Gerona)",
  // },
  // {
  //   municipio: "Sant Feliu de Guíxols",
  //   provincia: "Gerona",
  //   id: "17160",
  //   label: "Sant Feliu de Guíxols (Gerona)",
  // },
  // {
  //   municipio: "Sant Feliu de Pallerols",
  //   provincia: "Gerona",
  //   id: "17161",
  //   label: "Sant Feliu de Pallerols (Gerona)",
  // },
  // {
  //   municipio: "Sant Ferriol",
  //   provincia: "Gerona",
  //   id: "17162",
  //   label: "Sant Ferriol (Gerona)",
  // },
  // {
  //   municipio: "Sant Gregori",
  //   provincia: "Gerona",
  //   id: "17163",
  //   label: "Sant Gregori (Gerona)",
  // },
  // {
  //   municipio: "Sant Hilari Sacalm",
  //   provincia: "Gerona",
  //   id: "17164",
  //   label: "Sant Hilari Sacalm (Gerona)",
  // },
  // {
  //   municipio: "Sant Jaume de Llierca",
  //   provincia: "Gerona",
  //   id: "17165",
  //   label: "Sant Jaume de Llierca (Gerona)",
  // },
  // {
  //   municipio: "Sant Joan de les Abadesses",
  //   provincia: "Gerona",
  //   id: "17167",
  //   label: "Sant Joan de les Abadesses (Gerona)",
  // },
  // {
  //   municipio: "Sant Martí de Llémena",
  //   provincia: "Gerona",
  //   id: "17172",
  //   label: "Sant Martí de Llémena (Gerona)",
  // },
  // {
  //   municipio: "Sant Miquel de Campmajor",
  //   provincia: "Gerona",
  //   id: "17174",
  //   label: "Sant Miquel de Campmajor (Gerona)",
  // },
  // {
  //   municipio: "Sant Pau de Segúries",
  //   provincia: "Gerona",
  //   id: "17177",
  //   label: "Sant Pau de Segúries (Gerona)",
  // },
  // {
  //   municipio: "Santa Cristina d'Aro",
  //   provincia: "Gerona",
  //   id: "17181",
  //   label: "Santa Cristina d'Aro (Gerona)",
  // },
  // {
  //   municipio: "Sant Aniol de Finestres",
  //   provincia: "Gerona",
  //   id: "17183",
  //   label: "Sant Aniol de Finestres (Gerona)",
  // },
  // {
  //   municipio: "Santa Pau",
  //   provincia: "Gerona",
  //   id: "17184",
  //   label: "Santa Pau (Gerona)",
  // },
  // {
  //   municipio: "Sant Joan les Fonts",
  //   provincia: "Gerona",
  //   id: "17185",
  //   label: "Sant Joan les Fonts (Gerona)",
  // },
  // {
  //   municipio: "Serinyà",
  //   provincia: "Gerona",
  //   id: "17190",
  //   label: "Serinyà (Gerona)",
  // },
  // {
  //   municipio: "Susqueda",
  //   provincia: "Gerona",
  //   id: "17194",
  //   label: "Susqueda (Gerona)",
  // },
  // {
  //   municipio: "Torroella de Montgrí",
  //   provincia: "Gerona",
  //   id: "17199",
  //   label: "Torroella de Montgrí (Gerona)",
  // },
  // {
  //   municipio: "Toses",
  //   provincia: "Gerona",
  //   id: "17201",
  //   label: "Toses (Gerona)",
  // },
  // {
  //   municipio: "Tossa de Mar",
  //   provincia: "Gerona",
  //   id: "17202",
  //   label: "Tossa de Mar (Gerona)",
  // },
  // {
  //   municipio: "Vall d'en Bas, La",
  //   provincia: "Gerona",
  //   id: "17207",
  //   label: "Vall d'en Bas, La (Gerona)",
  // },
  // {
  //   municipio: "Vall de Bianya, La",
  //   provincia: "Gerona",
  //   id: "17208",
  //   label: "Vall de Bianya, La (Gerona)",
  // },
  // {
  //   municipio: "Vidrà",
  //   provincia: "Gerona",
  //   id: "17212",
  //   label: "Vidrà (Gerona)",
  // },
  // {
  //   municipio: "Vilademuls",
  //   provincia: "Gerona",
  //   id: "17218",
  //   label: "Vilademuls (Gerona)",
  // },
  // {
  //   municipio: "Vilallonga de Ter",
  //   provincia: "Gerona",
  //   id: "17224",
  //   label: "Vilallonga de Ter (Gerona)",
  // },
  // {
  //   municipio: "Alhama de Granada",
  //   provincia: "Granada",
  //   id: "18013",
  //   label: "Alhama de Granada (Granada)",
  // },
  // {
  //   municipio: "Almuñécar",
  //   provincia: "Granada",
  //   id: "18017",
  //   label: "Almuñécar (Granada)",
  // },
  // {
  //   municipio: "Baza",
  //   provincia: "Granada",
  //   id: "18023",
  //   label: "Baza (Granada)",
  // },
  // {
  //   municipio: "Granada",
  //   provincia: "Granada",
  //   id: "18087",
  //   label: "Granada (Granada)",
  // },
  // {
  //   municipio: "Guadix",
  //   provincia: "Granada",
  //   id: "18089",
  //   label: "Guadix (Granada)",
  // },
  // {
  //   municipio: "Gualchos",
  //   provincia: "Granada",
  //   id: "18093",
  //   label: "Gualchos (Granada)",
  // },
  // {
  //   municipio: "Iznalloz",
  //   provincia: "Granada",
  //   id: "18105",
  //   label: "Iznalloz (Granada)",
  // },
  // {
  //   municipio: "Loja",
  //   provincia: "Granada",
  //   id: "18122",
  //   label: "Loja (Granada)",
  // },
  // {
  //   municipio: "Motril",
  //   provincia: "Granada",
  //   id: "18140",
  //   label: "Motril (Granada)",
  // },
  // {
  //   municipio: "Pinos Puente",
  //   provincia: "Granada",
  //   id: "18158",
  //   label: "Pinos Puente (Granada)",
  // },
  // {
  //   municipio: "Purullena",
  //   provincia: "Granada",
  //   id: "18167",
  //   label: "Purullena (Granada)",
  // },
  // {
  //   municipio: "Vegas del Genil",
  //   provincia: "Granada",
  //   id: "18911",
  //   label: "Vegas del Genil (Granada)",
  // },
  // {
  //   municipio: "Valldemossa",
  //   provincia: "Islas Baleares",
  //   id: "07063",
  //   label: "Valldemossa (Islas Baleares)",
  // },
  // {
  //   municipio: "Cardoso de la Sierra, El",
  //   provincia: "Guadalajara",
  //   id: "19067",
  //   label: "Cardoso de la Sierra, El (Guadalajara)",
  // },
  // {
  //   municipio: "Guadalajara",
  //   provincia: "Guadalajara",
  //   id: "19130",
  //   label: "Guadalajara (Guadalajara)",
  // },
  // {
  //   municipio: "Zaorejas",
  //   provincia: "Guadalajara",
  //   id: "19333",
  //   label: "Zaorejas (Guadalajara)",
  // },
  // {
  //   municipio: "Almonaster la Real",
  //   provincia: "Huelva",
  //   id: "21004",
  //   label: "Almonaster la Real (Huelva)",
  // },
  // {
  //   municipio: "Almonte",
  //   provincia: "Huelva",
  //   id: "21005",
  //   label: "Almonte (Huelva)",
  // },
  // {
  //   municipio: "Aracena",
  //   provincia: "Huelva",
  //   id: "21007",
  //   label: "Aracena (Huelva)",
  // },
  // {
  //   municipio: "Aroche",
  //   provincia: "Huelva",
  //   id: "21008",
  //   label: "Aroche (Huelva)",
  // },
  // {
  //   municipio: "Calañas",
  //   provincia: "Huelva",
  //   id: "21017",
  //   label: "Calañas (Huelva)",
  // },
  // {
  //   municipio: "Escacena del Campo",
  //   provincia: "Huelva",
  //   id: "21032",
  //   label: "Escacena del Campo (Huelva)",
  // },
  // {
  //   municipio: "Hinojos",
  //   provincia: "Huelva",
  //   id: "21040",
  //   label: "Hinojos (Huelva)",
  // },
  // {
  //   municipio: "Lepe",
  //   provincia: "Huelva",
  //   id: "21044",
  //   label: "Lepe (Huelva)",
  // },
  // {
  //   municipio: "Niebla",
  //   provincia: "Huelva",
  //   id: "21053",
  //   label: "Niebla (Huelva)",
  // },
  // {
  //   municipio: "Palos de la Frontera",
  //   provincia: "Huelva",
  //   id: "21055",
  //   label: "Palos de la Frontera (Huelva)",
  // },
  // {
  //   municipio: "Paterna del Campo",
  //   provincia: "Huelva",
  //   id: "21056",
  //   label: "Paterna del Campo (Huelva)",
  // },
  // {
  //   municipio: "Puebla de Guzmán",
  //   provincia: "Huelva",
  //   id: "21058",
  //   label: "Puebla de Guzmán (Huelva)",
  // },
  // {
  //   municipio: "Rosal de la Frontera",
  //   provincia: "Huelva",
  //   id: "21062",
  //   label: "Rosal de la Frontera (Huelva)",
  // },
  // {
  //   municipio: "Valverde del Camino",
  //   provincia: "Huelva",
  //   id: "21072",
  //   label: "Valverde del Camino (Huelva)",
  // },
  // {
  //   municipio: "Ansó",
  //   provincia: "Huesca",
  //   id: "22028",
  //   label: "Ansó (Huesca)",
  // },
  // {
  //   municipio: "Benasque",
  //   provincia: "Huesca",
  //   id: "22054",
  //   label: "Benasque (Huesca)",
  // },
  // {
  //   municipio: "Sabiñánigo",
  //   provincia: "Huesca",
  //   id: "22199",
  //   label: "Sabiñánigo (Huesca)",
  // },
  // {
  //   municipio: "Alcaudete",
  //   provincia: "Jaén",
  //   id: "23003",
  //   label: "Alcaudete (Jaén)",
  // },
  // {
  //   municipio: "Andújar",
  //   provincia: "Jaén",
  //   id: "23005",
  //   label: "Andújar (Jaén)",
  // },
  // {
  //   municipio: "Cazorla",
  //   provincia: "Jaén",
  //   id: "23028",
  //   label: "Cazorla (Jaén)",
  // },
  // {
  //   municipio: "Huesa",
  //   provincia: "Jaén",
  //   id: "23045",
  //   label: "Huesa (Jaén)",
  // },
  // {
  //   municipio: "Castell, Es",
  //   provincia: "Islas Baleares",
  //   id: "07064",
  //   label: "Castell, Es (Islas Baleares)",
  // },
  // {
  //   municipio: "Marmolejo",
  //   provincia: "Jaén",
  //   id: "23059",
  //   label: "Marmolejo (Jaén)",
  // },
  // {
  //   municipio: "Navas de San Juan",
  //   provincia: "Jaén",
  //   id: "23063",
  //   label: "Navas de San Juan (Jaén)",
  // },
  // {
  //   municipio: "Quesada",
  //   provincia: "Jaén",
  //   id: "23073",
  //   label: "Quesada (Jaén)",
  // },
  // {
  //   municipio: "Segura de la Sierra",
  //   provincia: "Jaén",
  //   id: "23081",
  //   label: "Segura de la Sierra (Jaén)",
  // },
  // {
  //   municipio: "Úbeda",
  //   provincia: "Jaén",
  //   id: "23092",
  //   label: "Úbeda (Jaén)",
  // },
  // {
  //   municipio: "Vilches",
  //   provincia: "Jaén",
  //   id: "23094",
  //   label: "Vilches (Jaén)",
  // },
  // {
  //   municipio: "Villanueva del Arzobispo",
  //   provincia: "Jaén",
  //   id: "23097",
  //   label: "Villanueva del Arzobispo (Jaén)",
  // },
  // {
  //   municipio: "Santiago-Pontones",
  //   provincia: "Jaén",
  //   id: "23904",
  //   label: "Santiago-Pontones (Jaén)",
  // },
  // {
  //   municipio: "Boca de Huérgano",
  //   provincia: "León",
  //   id: "24020",
  //   label: "Boca de Huérgano (León)",
  // },
  // {
  //   municipio: "Planoles",
  //   provincia: "Gerona",
  //   id: "17134",
  //   label: "Planoles (Gerona)",
  // },
  // {
  //   municipio: "Cómpeta",
  //   provincia: "Málaga",
  //   id: "29045",
  //   label: "Cómpeta (Málaga)",
  // },
  // {
  //   municipio: "Cortes de la Frontera",
  //   provincia: "Málaga",
  //   id: "29046",
  //   label: "Cortes de la Frontera (Málaga)",
  // },
  // {
  //   municipio: "Abella de la Conca",
  //   provincia: "Lérida",
  //   id: "25001",
  //   label: "Abella de la Conca (Lérida)",
  // },
  // {
  //   municipio: "Àger",
  //   provincia: "Lérida",
  //   id: "25002",
  //   label: "Àger (Lérida)",
  // },
  // {
  //   municipio: "Albagés, L'",
  //   provincia: "Lérida",
  //   id: "25006",
  //   label: "Albagés, L' (Lérida)",
  // },
  // {
  //   municipio: "Alins",
  //   provincia: "Lérida",
  //   id: "25017",
  //   label: "Alins (Lérida)",
  // },
  // {
  //   municipio: "Naut Aran",
  //   provincia: "Lérida",
  //   id: "25025",
  //   label: "Naut Aran (Lérida)",
  // },
  // {
  //   municipio: "Arbeca",
  //   provincia: "Lérida",
  //   id: "25029",
  //   label: "Arbeca (Lérida)",
  // },
  // {
  //   municipio: "Artesa de Segre",
  //   provincia: "Lérida",
  //   id: "25034",
  //   label: "Artesa de Segre (Lérida)",
  // },
  // {
  //   municipio: "Avellanes i Santa Linya, Les",
  //   provincia: "Lérida",
  //   id: "25037",
  //   label: "Avellanes i Santa Linya, Les (Lérida)",
  // },
  // {
  //   municipio: "Baix Pallars",
  //   provincia: "Lérida",
  //   id: "25039",
  //   label: "Baix Pallars (Lérida)",
  // },
  // {
  //   municipio: "Balaguer",
  //   provincia: "Lérida",
  //   id: "25040",
  //   label: "Balaguer (Lérida)",
  // },
  // {
  //   municipio: "Baronia de Rialb, La",
  //   provincia: "Lérida",
  //   id: "25042",
  //   label: "Baronia de Rialb, La (Lérida)",
  // },
  // {
  //   municipio: "Vall de Boí, La",
  //   provincia: "Lérida",
  //   id: "25043",
  //   label: "Vall de Boí, La (Lérida)",
  // },
  // {
  //   municipio: "Belianes",
  //   provincia: "Lérida",
  //   id: "25046",
  //   label: "Belianes (Lérida)",
  // },
  // {
  //   municipio: "Bellvís",
  //   provincia: "Lérida",
  //   id: "25052",
  //   label: "Bellvís (Lérida)",
  // },
  // {
  //   municipio: "Biosca",
  //   provincia: "Lérida",
  //   id: "25055",
  //   label: "Biosca (Lérida)",
  // },
  // {
  //   municipio: "Bovera",
  //   provincia: "Lérida",
  //   id: "25056",
  //   label: "Bovera (Lérida)",
  // },
  // {
  //   municipio: "Borges Blanques, Les",
  //   provincia: "Lérida",
  //   id: "25058",
  //   label: "Borges Blanques, Les (Lérida)",
  // },
  // {
  //   municipio: "Cabó",
  //   provincia: "Lérida",
  //   id: "25061",
  //   label: "Cabó (Lérida)",
  // },
  // {
  //   municipio: "Camarasa",
  //   provincia: "Lérida",
  //   id: "25062",
  //   label: "Camarasa (Lérida)",
  // },
  // {
  //   municipio: "Castelldans",
  //   provincia: "Lérida",
  //   id: "25067",
  //   label: "Castelldans (Lérida)",
  // },
  // {
  //   municipio: "Cervera",
  //   provincia: "Lérida",
  //   id: "25072",
  //   label: "Cervera (Lérida)",
  // },
  // {
  //   municipio: "Cervià de les Garrigues",
  //   provincia: "Lérida",
  //   id: "25073",
  //   label: "Cervià de les Garrigues (Lérida)",
  // },
  // {
  //   municipio: "Aia",
  //   provincia: "Guipúzcoa",
  //   id: "20016",
  //   label: "Aia (Guipúzcoa)",
  // },
  // {
  //   municipio: "Coll de Nargó",
  //   provincia: "Lérida",
  //   id: "25077",
  //   label: "Coll de Nargó (Lérida)",
  // },
  // {
  //   municipio: "Floresta, La",
  //   provincia: "Lérida",
  //   id: "25092",
  //   label: "Floresta, La (Lérida)",
  // },
  // {
  //   municipio: "Gósol",
  //   provincia: "Lérida",
  //   id: "25100",
  //   label: "Gósol (Lérida)",
  // },
  // {
  //   municipio: "Granadella, La",
  //   provincia: "Lérida",
  //   id: "25101",
  //   label: "Granadella, La (Lérida)",
  // },
  // {
  //   municipio: "Granyena de les Garrigues",
  //   provincia: "Lérida",
  //   id: "25105",
  //   label: "Granyena de les Garrigues (Lérida)",
  // },
  // {
  //   municipio: "Guixers",
  //   provincia: "Lérida",
  //   id: "25111",
  //   label: "Guixers (Lérida)",
  // },
  // {
  //   municipio: "Ivars d'Urgell",
  //   provincia: "Lérida",
  //   id: "25113",
  //   label: "Ivars d'Urgell (Lérida)",
  // },
  // {
  //   municipio: "Isona i Conca Dellà",
  //   provincia: "Lérida",
  //   id: "25115",
  //   label: "Isona i Conca Dellà (Lérida)",
  // },
  // {
  //   municipio: "Juncosa",
  //   provincia: "Lérida",
  //   id: "25118",
  //   label: "Juncosa (Lérida)",
  // },
  // {
  //   municipio: "Juneda",
  //   provincia: "Lérida",
  //   id: "25119",
  //   label: "Juneda (Lérida)",
  // },
  // {
  //   municipio: "Lleida",
  //   provincia: "Lérida",
  //   id: "25120",
  //   label: "Lleida (Lérida)",
  // },
  // {
  //   municipio: "Lladurs",
  //   provincia: "Lérida",
  //   id: "25124",
  //   label: "Lladurs (Lérida)",
  // },
  // {
  //   municipio: "Lles de Cerdanya",
  //   provincia: "Lérida",
  //   id: "25127",
  //   label: "Lles de Cerdanya (Lérida)",
  // },
  // {
  //   municipio: "Maldà",
  //   provincia: "Lérida",
  //   id: "25130",
  //   label: "Maldà (Lérida)",
  // },
  // {
  //   municipio: "Menàrguens",
  //   provincia: "Lérida",
  //   id: "25134",
  //   label: "Menàrguens (Lérida)",
  // },
  // {
  //   municipio: "Molsosa, La",
  //   provincia: "Lérida",
  //   id: "25136",
  //   label: "Molsosa, La (Lérida)",
  // },
  // {
  //   municipio: "Montoliu de Segarra",
  //   provincia: "Lérida",
  //   id: "25141",
  //   label: "Montoliu de Segarra (Lérida)",
  // },
  // {
  //   municipio: "Navès",
  //   provincia: "Lérida",
  //   id: "25146",
  //   label: "Navès (Lérida)",
  // },
  // {
  //   municipio: "Odèn",
  //   provincia: "Lérida",
  //   id: "25148",
  //   label: "Odèn (Lérida)",
  // },
  // {
  //   municipio: "Oliana",
  //   provincia: "Lérida",
  //   id: "25149",
  //   label: "Oliana (Lérida)",
  // },
  // {
  //   municipio: "Os de Balaguer",
  //   provincia: "Lérida",
  //   id: "25156",
  //   label: "Os de Balaguer (Lérida)",
  // },
  // {
  //   municipio: "Conca de Dalt",
  //   provincia: "Lérida",
  //   id: "25161",
  //   label: "Conca de Dalt (Lérida)",
  // },
  // {
  //   municipio: "Coma i la Pedra, La",
  //   provincia: "Lérida",
  //   id: "25163",
  //   label: "Coma i la Pedra, La (Lérida)",
  // },
  // {
  //   municipio: "Pinell de Solsonès",
  //   provincia: "Lérida",
  //   id: "25166",
  //   label: "Pinell de Solsonès (Lérida)",
  // },
  // {
  //   municipio: "Pinós",
  //   provincia: "Lérida",
  //   id: "25167",
  //   label: "Pinós (Lérida)",
  // },
  // {
  //   municipio: "Pobla de Cérvoles, La",
  //   provincia: "Lérida",
  //   id: "25169",
  //   label: "Pobla de Cérvoles, La (Lérida)",
  // },
  // {
  //   municipio: "Puiggròs",
  //   provincia: "Lérida",
  //   id: "25180",
  //   label: "Puiggròs (Lérida)",
  // },
  // {
  //   municipio: "Sant Llorenç de Morunys",
  //   provincia: "Lérida",
  //   id: "25193",
  //   label: "Sant Llorenç de Morunys (Lérida)",
  // },
  // {
  //   municipio: "Tàrrega",
  //   provincia: "Lérida",
  //   id: "25217",
  //   label: "Tàrrega (Lérida)",
  // },
  // {
  //   municipio: "Torrefarrera",
  //   provincia: "Lérida",
  //   id: "25228",
  //   label: "Torrefarrera (Lérida)",
  // },
  // {
  //   municipio: "Torà",
  //   provincia: "Lérida",
  //   id: "25223",
  //   label: "Torà (Lérida)",
  // },
  // {
  //   municipio: "Torre de Cabdella, La",
  //   provincia: "Lérida",
  //   id: "25227",
  //   label: "Torre de Cabdella, La (Lérida)",
  // },
  // {
  //   municipio: "Torregrossa",
  //   provincia: "Lérida",
  //   id: "25230",
  //   label: "Torregrossa (Lérida)",
  // },
  // {
  //   municipio: "Tremp",
  //   provincia: "Lérida",
  //   id: "25234",
  //   label: "Tremp (Lérida)",
  // },
  // {
  //   municipio: "Valls de Valira, Les",
  //   provincia: "Lérida",
  //   id: "25239",
  //   label: "Valls de Valira, Les (Lérida)",
  // },
  // {
  //   municipio: "Verdú",
  //   provincia: "Lérida",
  //   id: "25242",
  //   label: "Verdú (Lérida)",
  // },
  // {
  //   municipio: "Vilosell, El",
  //   provincia: "Lérida",
  //   id: "25253",
  //   label: "Vilosell, El (Lérida)",
  // },
  // {
  //   municipio: "Vinaixa",
  //   provincia: "Lérida",
  //   id: "25255",
  //   label: "Vinaixa (Lérida)",
  // },
  // {
  //   municipio: "Sant Martí de Riucorb",
  //   provincia: "Lérida",
  //   id: "25902",
  //   label: "Sant Martí de Riucorb (Lérida)",
  // },
  // {
  //   municipio: "Ribera d'Ondara",
  //   provincia: "Lérida",
  //   id: "25905",
  //   label: "Ribera d'Ondara (Lérida)",
  // },
  // {
  //   municipio: "Torrefeta i Florejacs",
  //   provincia: "Lérida",
  //   id: "25907",
  //   label: "Torrefeta i Florejacs (Lérida)",
  // },
  // {
  //   municipio: "Fígols i Alinyà",
  //   provincia: "Lérida",
  //   id: "25908",
  //   label: "Fígols i Alinyà (Lérida)",
  // },
  // {
  //   municipio: "Josa i Tuixén",
  //   provincia: "Lérida",
  //   id: "25910",
  //   label: "Josa i Tuixén (Lérida)",
  // },
  // {
  //   municipio: "Barreiros",
  //   provincia: "Lugo",
  //   id: "27005",
  //   label: "Barreiros (Lugo)",
  // },
  // {
  //   municipio: "Cervo",
  //   provincia: "Lugo",
  //   id: "27013",
  //   label: "Cervo (Lugo)",
  // },
  // {
  //   municipio: "Foz",
  //   provincia: "Lugo",
  //   id: "27019",
  //   label: "Foz (Lugo)",
  // },
  // {
  //   municipio: "Xove",
  //   provincia: "Lugo",
  //   id: "27025",
  //   label: "Xove (Lugo)",
  // },
  // {
  //   municipio: "Muras",
  //   provincia: "Lugo",
  //   id: "27033",
  //   label: "Muras (Lugo)",
  // },
  // {
  //   municipio: "Ribadeo",
  //   provincia: "Lugo",
  //   id: "27051",
  //   label: "Ribadeo (Lugo)",
  // },
  // {
  //   municipio: "Vicedo, O",
  //   provincia: "Lugo",
  //   id: "27064",
  //   label: "Vicedo, O (Lugo)",
  // },
  // {
  //   municipio: "Vilalba",
  //   provincia: "Lugo",
  //   id: "27065",
  //   label: "Vilalba (Lugo)",
  // },
  // {
  //   municipio: "Viveiro",
  //   provincia: "Lugo",
  //   id: "27066",
  //   label: "Viveiro (Lugo)",
  // },
  // {
  //   municipio: "Alcalá de Henares",
  //   provincia: "Madrid",
  //   id: "28005",
  //   label: "Alcalá de Henares (Madrid)",
  // },
  // {
  //   municipio: "Alcobendas",
  //   provincia: "Madrid",
  //   id: "28006",
  //   label: "Alcobendas (Madrid)",
  // },
  // {
  //   municipio: "Aldea del Fresno",
  //   provincia: "Madrid",
  //   id: "28008",
  //   label: "Aldea del Fresno (Madrid)",
  // },
  // {
  //   municipio: "Algete",
  //   provincia: "Madrid",
  //   id: "28009",
  //   label: "Algete (Madrid)",
  // },
  // {
  //   municipio: "Ambite",
  //   provincia: "Madrid",
  //   id: "28011",
  //   label: "Ambite (Madrid)",
  // },
  // {
  //   municipio: "Aranjuez",
  //   provincia: "Madrid",
  //   id: "28013",
  //   label: "Aranjuez (Madrid)",
  // },
  // {
  //   municipio: "Arroyomolinos",
  //   provincia: "Madrid",
  //   id: "28015",
  //   label: "Arroyomolinos (Madrid)",
  // },
  // {
  //   municipio: "Atazar, El",
  //   provincia: "Madrid",
  //   id: "28016",
  //   label: "Atazar, El (Madrid)",
  // },
  // {
  //   municipio: "Batres",
  //   provincia: "Madrid",
  //   id: "28017",
  //   label: "Batres (Madrid)",
  // },
  // {
  //   municipio: "Buitrago del Lozoya",
  //   provincia: "Madrid",
  //   id: "28027",
  //   label: "Buitrago del Lozoya (Madrid)",
  // },
  // {
  //   municipio: "Bustarviejo",
  //   provincia: "Madrid",
  //   id: "28028",
  //   label: "Bustarviejo (Madrid)",
  // },
  // {
  //   municipio: "Ciempozuelos",
  //   provincia: "Madrid",
  //   id: "28040",
  //   label: "Ciempozuelos (Madrid)",
  // },
  // {
  //   municipio: "Colmenar del Arroyo",
  //   provincia: "Madrid",
  //   id: "28042",
  //   label: "Colmenar del Arroyo (Madrid)",
  // },
  // {
  //   municipio: "Colmenar de Oreja",
  //   provincia: "Madrid",
  //   id: "28043",
  //   label: "Colmenar de Oreja (Madrid)",
  // },
  // {
  //   municipio: "Colmenarejo",
  //   provincia: "Madrid",
  //   id: "28044",
  //   label: "Colmenarejo (Madrid)",
  // },
  // {
  //   municipio: "Colmenar Viejo",
  //   provincia: "Madrid",
  //   id: "28045",
  //   label: "Colmenar Viejo (Madrid)",
  // },
  // {
  //   municipio: "Chinchón",
  //   provincia: "Madrid",
  //   id: "28052",
  //   label: "Chinchón (Madrid)",
  // },
  // {
  //   municipio: "Escorial, El",
  //   provincia: "Madrid",
  //   id: "28054",
  //   label: "Escorial, El (Madrid)",
  // },
  // {
  //   municipio: "Estremera",
  //   provincia: "Madrid",
  //   id: "28055",
  //   label: "Estremera (Madrid)",
  // },
  // {
  //   municipio: "Fuenlabrada",
  //   provincia: "Madrid",
  //   id: "28058",
  //   label: "Fuenlabrada (Madrid)",
  // },
  // {
  //   municipio: "Galapagar",
  //   provincia: "Madrid",
  //   id: "28061",
  //   label: "Galapagar (Madrid)",
  // },
  // {
  //   municipio: "Gargantilla del Lozoya y Pinilla de Buitrago",
  //   provincia: "Madrid",
  //   id: "28063",
  //   label: "Gargantilla del Lozoya y Pinilla de Buitrago (Madrid)",
  // },
  // {
  //   municipio: "Getafe",
  //   provincia: "Madrid",
  //   id: "28065",
  //   label: "Getafe (Madrid)",
  // },
  // {
  //   municipio: "Hiruela, La",
  //   provincia: "Madrid",
  //   id: "28069",
  //   label: "Hiruela, La (Madrid)",
  // },
  // {
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   id: "28079",
  //   label: "Madrid (Madrid)",
  // },
  // {
  //   municipio: "Majadahonda",
  //   provincia: "Madrid",
  //   id: "28080",
  //   label: "Majadahonda (Madrid)",
  // },
  // {
  //   municipio: "Miraflores de la Sierra",
  //   provincia: "Madrid",
  //   id: "28085",
  //   label: "Miraflores de la Sierra (Madrid)",
  // },
  // {
  //   municipio: "Molar, El",
  //   provincia: "Madrid",
  //   id: "28086",
  //   label: "Molar, El (Madrid)",
  // },
  // {
  //   municipio: "Montejo de la Sierra",
  //   provincia: "Madrid",
  //   id: "28088",
  //   label: "Montejo de la Sierra (Madrid)",
  // },
  // {
  //   municipio: "Moraleja de Enmedio",
  //   provincia: "Madrid",
  //   id: "28089",
  //   label: "Moraleja de Enmedio (Madrid)",
  // },
  // {
  //   municipio: "Móstoles",
  //   provincia: "Madrid",
  //   id: "28092",
  //   label: "Móstoles (Madrid)",
  // },
  // {
  //   municipio: "Navalagamella",
  //   provincia: "Madrid",
  //   id: "28095",
  //   label: "Navalagamella (Madrid)",
  // },
  // {
  //   municipio: "Patones",
  //   provincia: "Madrid",
  //   id: "28107",
  //   label: "Patones (Madrid)",
  // },
  // {
  //   municipio: "Pinto",
  //   provincia: "Madrid",
  //   id: "28113",
  //   label: "Pinto (Madrid)",
  // },
  // {
  //   municipio: "Piñuécar-Gandullas",
  //   provincia: "Madrid",
  //   id: "28114",
  //   label: "Piñuécar-Gandullas (Madrid)",
  // },
  // {
  //   municipio: "Puebla de la Sierra",
  //   provincia: "Madrid",
  //   id: "28118",
  //   label: "Puebla de la Sierra (Madrid)",
  // },
  // {
  //   municipio: "Rascafría",
  //   provincia: "Madrid",
  //   id: "28120",
  //   label: "Rascafría (Madrid)",
  // },
  // {
  //   municipio: "Robledillo de la Jara",
  //   provincia: "Madrid",
  //   id: "28124",
  //   label: "Robledillo de la Jara (Madrid)",
  // },
  // {
  //   municipio: "Robledo de Chavela",
  //   provincia: "Madrid",
  //   id: "28125",
  //   label: "Robledo de Chavela (Madrid)",
  // },
  // {
  //   municipio: "Rozas de Madrid, Las",
  //   provincia: "Madrid",
  //   id: "28127",
  //   label: "Rozas de Madrid, Las (Madrid)",
  // },
  // {
  //   municipio: "San Agustín del Guadalix",
  //   provincia: "Madrid",
  //   id: "28129",
  //   label: "San Agustín del Guadalix (Madrid)",
  // },
  // {
  //   municipio: "San Martín de Valdeiglesias",
  //   provincia: "Madrid",
  //   id: "28133",
  //   label: "San Martín de Valdeiglesias (Madrid)",
  // },
  // {
  //   municipio: "San Sebastián de los Reyes",
  //   provincia: "Madrid",
  //   id: "28134",
  //   label: "San Sebastián de los Reyes (Madrid)",
  // },
  // {
  //   municipio: "Santa María de la Alameda",
  //   provincia: "Madrid",
  //   id: "28135",
  //   label: "Santa María de la Alameda (Madrid)",
  // },
  // {
  //   municipio: "Serranillos del Valle",
  //   provincia: "Madrid",
  //   id: "28140",
  //   label: "Serranillos del Valle (Madrid)",
  // },
  // {
  //   municipio: "Talamanca de Jarama",
  //   provincia: "Madrid",
  //   id: "28145",
  //   label: "Talamanca de Jarama (Madrid)",
  // },
  // {
  //   municipio: "Torremocha de Jarama",
  //   provincia: "Madrid",
  //   id: "28153",
  //   label: "Torremocha de Jarama (Madrid)",
  // },
  // {
  //   municipio: "Valdemaqueda",
  //   provincia: "Madrid",
  //   id: "28159",
  //   label: "Valdemaqueda (Madrid)",
  // },
  // {
  //   municipio: "Valdemorillo",
  //   provincia: "Madrid",
  //   id: "28160",
  //   label: "Valdemorillo (Madrid)",
  // },
  // {
  //   municipio: "Villalbilla",
  //   provincia: "Madrid",
  //   id: "28172",
  //   label: "Villalbilla (Madrid)",
  // },
  // {
  //   municipio: "Villamanta",
  //   provincia: "Madrid",
  //   id: "28174",
  //   label: "Villamanta (Madrid)",
  // },
  // {
  //   municipio: "Villanueva de la Cañada",
  //   provincia: "Madrid",
  //   id: "28176",
  //   label: "Villanueva de la Cañada (Madrid)",
  // },
  // {
  //   municipio: "Villanueva del Pardillo",
  //   provincia: "Madrid",
  //   id: "28177",
  //   label: "Villanueva del Pardillo (Madrid)",
  // },
  // {
  //   municipio: "Villanueva de Perales",
  //   provincia: "Madrid",
  //   id: "28178",
  //   label: "Villanueva de Perales (Madrid)",
  // },
  // {
  //   municipio: "Villaviciosa de Odón",
  //   provincia: "Madrid",
  //   id: "28181",
  //   label: "Villaviciosa de Odón (Madrid)",
  // },
  // {
  //   municipio: "Puentes Viejas",
  //   provincia: "Madrid",
  //   id: "28902",
  //   label: "Puentes Viejas (Madrid)",
  // },
  // {
  //   municipio: "Tres Cantos",
  //   provincia: "Madrid",
  //   id: "28903",
  //   label: "Tres Cantos (Madrid)",
  // },
  // {
  //   municipio: "Antequera",
  //   provincia: "Málaga",
  //   id: "29015",
  //   label: "Antequera (Málaga)",
  // },
  // {
  //   municipio: "Archidona",
  //   provincia: "Málaga",
  //   id: "29017",
  //   label: "Archidona (Málaga)",
  // },
  // {
  //   municipio: "Cañete la Real",
  //   provincia: "Málaga",
  //   id: "29035",
  //   label: "Cañete la Real (Málaga)",
  // },
  // {
  //   municipio: "Burgo, El",
  //   provincia: "Málaga",
  //   id: "29031",
  //   label: "Burgo, El (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067",
  //   label: "Málaga (Málaga)",
  // },
  // {
  //   municipio: "Marbella",
  //   provincia: "Málaga",
  //   id: "29069",
  //   label: "Marbella (Málaga)",
  // },
  // {
  //   municipio: "Ronda",
  //   provincia: "Málaga",
  //   id: "29084",
  //   label: "Ronda (Málaga)",
  // },
  // {
  //   municipio: "Teba",
  //   provincia: "Málaga",
  //   id: "29089",
  //   label: "Teba (Málaga)",
  // },
  // {
  //   municipio: "Vélez-Málaga",
  //   provincia: "Málaga",
  //   id: "29094",
  //   label: "Vélez-Málaga (Málaga)",
  // },
  // {
  //   municipio: "Villanueva de la Concepción",
  //   provincia: "Málaga",
  //   id: "29902",
  //   label: "Villanueva de la Concepción (Málaga)",
  // },
  // {
  //   municipio: "Abanilla",
  //   provincia: "Murcia",
  //   id: "30001",
  //   label: "Abanilla (Murcia)",
  // },
  // {
  //   municipio: "Abarán",
  //   provincia: "Murcia",
  //   id: "30002",
  //   label: "Abarán (Murcia)",
  // },
  // {
  //   municipio: "Águilas",
  //   provincia: "Murcia",
  //   id: "30003",
  //   label: "Águilas (Murcia)",
  // },
  // {
  //   municipio: "Cieza",
  //   provincia: "Murcia",
  //   id: "30019",
  //   label: "Cieza (Murcia)",
  // },
  // {
  //   municipio: "Alhama de Murcia",
  //   provincia: "Murcia",
  //   id: "30008",
  //   label: "Alhama de Murcia (Murcia)",
  // },
  // {
  //   municipio: "Blanca",
  //   provincia: "Murcia",
  //   id: "30011",
  //   label: "Blanca (Murcia)",
  // },
  // {
  //   municipio: "Bullas",
  //   provincia: "Murcia",
  //   id: "30012",
  //   label: "Bullas (Murcia)",
  // },
  // {
  //   municipio: "Caravaca de la Cruz",
  //   provincia: "Murcia",
  //   id: "30015",
  //   label: "Caravaca de la Cruz (Murcia)",
  // },
  // {
  //   municipio: "Cartagena",
  //   provincia: "Murcia",
  //   id: "30016",
  //   label: "Cartagena (Murcia)",
  // },
  // {
  //   municipio: "Cehegín",
  //   provincia: "Murcia",
  //   id: "30017",
  //   label: "Cehegín (Murcia)",
  // },
  // {
  //   municipio: "Fortuna",
  //   provincia: "Murcia",
  //   id: "30020",
  //   label: "Fortuna (Murcia)",
  // },
  // {
  //   municipio: "Fuente Álamo de Murcia",
  //   provincia: "Murcia",
  //   id: "30021",
  //   label: "Fuente Álamo de Murcia (Murcia)",
  // },
  // {
  //   municipio: "Jumilla",
  //   provincia: "Murcia",
  //   id: "30022",
  //   label: "Jumilla (Murcia)",
  // },
  // {
  //   municipio: "Librilla",
  //   provincia: "Murcia",
  //   id: "30023",
  //   label: "Librilla (Murcia)",
  // },
  // {
  //   municipio: "Lorca",
  //   provincia: "Murcia",
  //   id: "30024",
  //   label: "Lorca (Murcia)",
  // },
  // {
  //   municipio: "Mazarrón",
  //   provincia: "Murcia",
  //   id: "30026",
  //   label: "Mazarrón (Murcia)",
  // },
  // {
  //   municipio: "Molina de Segura",
  //   provincia: "Murcia",
  //   id: "30027",
  //   label: "Molina de Segura (Murcia)",
  // },
  // {
  //   municipio: "Moratalla",
  //   provincia: "Murcia",
  //   id: "30028",
  //   label: "Moratalla (Murcia)",
  // },
  // {
  //   municipio: "Mula",
  //   provincia: "Murcia",
  //   id: "30029",
  //   label: "Mula (Murcia)",
  // },
  // {
  //   municipio: "Murcia",
  //   provincia: "Murcia",
  //   id: "30030",
  //   label: "Murcia (Murcia)",
  // },
  // {
  //   municipio: "Ojós",
  //   provincia: "Murcia",
  //   id: "30031",
  //   label: "Ojós (Murcia)",
  // },
  // {
  //   municipio: "Pliego",
  //   provincia: "Murcia",
  //   id: "30032",
  //   label: "Pliego (Murcia)",
  // },
  // {
  //   municipio: "San Javier",
  //   provincia: "Murcia",
  //   id: "30035",
  //   label: "San Javier (Murcia)",
  // },
  // {
  //   municipio: "San Pedro del Pinatar",
  //   provincia: "Murcia",
  //   id: "30036",
  //   label: "San Pedro del Pinatar (Murcia)",
  // },
  // {
  //   municipio: "Torre-Pacheco",
  //   provincia: "Murcia",
  //   id: "30037",
  //   label: "Torre-Pacheco (Murcia)",
  // },
  // {
  //   municipio: "Totana",
  //   provincia: "Murcia",
  //   id: "30039",
  //   label: "Totana (Murcia)",
  // },
  // {
  //   municipio: "Ulea",
  //   provincia: "Murcia",
  //   id: "30040",
  //   label: "Ulea (Murcia)",
  // },
  // {
  //   municipio: "Unión, La",
  //   provincia: "Murcia",
  //   id: "30041",
  //   label: "Unión, La (Murcia)",
  // },
  // {
  //   municipio: "Alcázares, Los",
  //   provincia: "Murcia",
  //   id: "30902",
  //   label: "Alcázares, Los (Murcia)",
  // },
  // {
  //   municipio: "Santomera",
  //   provincia: "Murcia",
  //   id: "30901",
  //   label: "Santomera (Murcia)",
  // },
  // {
  //   municipio: "Castrillón",
  //   provincia: "Asturias (Principado de)",
  //   id: "33016",
  //   label: "Castrillón (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Castropol",
  //   provincia: "Asturias (Principado de)",
  //   id: "33017",
  //   label: "Castropol (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Coaña",
  //   provincia: "Asturias (Principado de)",
  //   id: "33018",
  //   label: "Coaña (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Cudillero",
  //   provincia: "Asturias (Principado de)",
  //   id: "33021",
  //   label: "Cudillero (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Boal",
  //   provincia: "Asturias (Principado de)",
  //   id: "33007",
  //   label: "Boal (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Gijón",
  //   provincia: "Asturias (Principado de)",
  //   id: "33024",
  //   label: "Gijón (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Gozón",
  //   provincia: "Asturias (Principado de)",
  //   id: "33025",
  //   label: "Gozón (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Franco, El",
  //   provincia: "Asturias (Principado de)",
  //   id: "33023",
  //   label: "Franco, El (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Ibias",
  //   provincia: "Asturias (Principado de)",
  //   id: "33028",
  //   label: "Ibias (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Lena",
  //   provincia: "Asturias (Principado de)",
  //   id: "33033",
  //   label: "Lena (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Valdés",
  //   provincia: "Asturias (Principado de)",
  //   id: "33034",
  //   label: "Valdés (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Llanes",
  //   provincia: "Asturias (Principado de)",
  //   id: "33036",
  //   label: "Llanes (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Navia",
  //   provincia: "Asturias (Principado de)",
  //   id: "33041",
  //   label: "Navia (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Quirós",
  //   provincia: "Asturias (Principado de)",
  //   id: "33053",
  //   label: "Quirós (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Ribadesella",
  //   provincia: "Asturias (Principado de)",
  //   id: "33056",
  //   label: "Ribadesella (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Tapia de Casariego",
  //   provincia: "Asturias (Principado de)",
  //   id: "33070",
  //   label: "Tapia de Casariego (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Villaviciosa",
  //   provincia: "Asturias (Principado de)",
  //   id: "33076",
  //   label: "Villaviciosa (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Agaete",
  //   provincia: "Las Palmas",
  //   id: "35001",
  //   label: "Agaete (Las Palmas)",
  // },
  // {
  //   municipio: "Agüimes",
  //   provincia: "Las Palmas",
  //   id: "35002",
  //   label: "Agüimes (Las Palmas)",
  // },
  // {
  //   municipio: "Antigua",
  //   provincia: "Las Palmas",
  //   id: "35003",
  //   label: "Antigua (Las Palmas)",
  // },
  // {
  //   municipio: "Arrecife",
  //   provincia: "Las Palmas",
  //   id: "35004",
  //   label: "Arrecife (Las Palmas)",
  // },
  // {
  //   municipio: "Artenara",
  //   provincia: "Las Palmas",
  //   id: "35005",
  //   label: "Artenara (Las Palmas)",
  // },
  // {
  //   municipio: "Arucas",
  //   provincia: "Las Palmas",
  //   id: "35006",
  //   label: "Arucas (Las Palmas)",
  // },
  // {
  //   municipio: "Betancuria",
  //   provincia: "Las Palmas",
  //   id: "35007",
  //   label: "Betancuria (Las Palmas)",
  // },
  // {
  //   municipio: "Firgas",
  //   provincia: "Las Palmas",
  //   id: "35008",
  //   label: "Firgas (Las Palmas)",
  // },
  // {
  //   municipio: "Gáldar",
  //   provincia: "Las Palmas",
  //   id: "35009",
  //   label: "Gáldar (Las Palmas)",
  // },
  // {
  //   municipio: "Haría",
  //   provincia: "Las Palmas",
  //   id: "35010",
  //   label: "Haría (Las Palmas)",
  // },
  // {
  //   municipio: "Ingenio",
  //   provincia: "Las Palmas",
  //   id: "35011",
  //   label: "Ingenio (Las Palmas)",
  // },
  // {
  //   municipio: "Mogán",
  //   provincia: "Las Palmas",
  //   id: "35012",
  //   label: "Mogán (Las Palmas)",
  // },
  // {
  //   municipio: "Oliva, La",
  //   provincia: "Las Palmas",
  //   id: "35014",
  //   label: "Oliva, La (Las Palmas)",
  // },
  // {
  //   municipio: "Pájara",
  //   provincia: "Las Palmas",
  //   id: "35015",
  //   label: "Pájara (Las Palmas)",
  // },
  // {
  //   municipio: "Palmas de Gran Canaria, Las",
  //   provincia: "Las Palmas",
  //   id: "35016",
  //   label: "Palmas de Gran Canaria, Las (Las Palmas)",
  // },
  // {
  //   municipio: "Puerto del Rosario",
  //   provincia: "Las Palmas",
  //   id: "35017",
  //   label: "Puerto del Rosario (Las Palmas)",
  // },
  // {
  //   municipio: "San Bartolomé de Tirajana",
  //   provincia: "Las Palmas",
  //   id: "35019",
  //   label: "San Bartolomé de Tirajana (Las Palmas)",
  // },
  // {
  //   municipio: "Aldea de San Nicolás, La",
  //   provincia: "Las Palmas",
  //   id: "35020",
  //   label: "Aldea de San Nicolás, La (Las Palmas)",
  // },
  // {
  //   municipio: "Santa Brígida",
  //   provincia: "Las Palmas",
  //   id: "35021",
  //   label: "Santa Brígida (Las Palmas)",
  // },
  // {
  //   municipio: "Santa Lucía de Tirajana",
  //   provincia: "Las Palmas",
  //   id: "35022",
  //   label: "Santa Lucía de Tirajana (Las Palmas)",
  // },
  // {
  //   municipio: "Santa María de Guía de Gran Canaria",
  //   provincia: "Las Palmas",
  //   id: "35023",
  //   label: "Santa María de Guía de Gran Canaria (Las Palmas)",
  // },
  // {
  //   municipio: "Teguise",
  //   provincia: "Las Palmas",
  //   id: "35024",
  //   label: "Teguise (Las Palmas)",
  // },
  // {
  //   municipio: "Tejeda",
  //   provincia: "Las Palmas",
  //   id: "35025",
  //   label: "Tejeda (Las Palmas)",
  // },
  // {
  //   municipio: "Telde",
  //   provincia: "Las Palmas",
  //   id: "35026",
  //   label: "Telde (Las Palmas)",
  // },
  // {
  //   municipio: "Teror",
  //   provincia: "Las Palmas",
  //   id: "35027",
  //   label: "Teror (Las Palmas)",
  // },
  // {
  //   municipio: "Tinajo",
  //   provincia: "Las Palmas",
  //   id: "35029",
  //   label: "Tinajo (Las Palmas)",
  // },
  // {
  //   municipio: "Tuineje",
  //   provincia: "Las Palmas",
  //   id: "35030",
  //   label: "Tuineje (Las Palmas)",
  // },
  // {
  //   municipio: "Valsequillo de Gran Canaria",
  //   provincia: "Las Palmas",
  //   id: "35031",
  //   label: "Valsequillo de Gran Canaria (Las Palmas)",
  // },
  // {
  //   municipio: "Valleseco",
  //   provincia: "Las Palmas",
  //   id: "35032",
  //   label: "Valleseco (Las Palmas)",
  // },
  // {
  //   municipio: "Vega de San Mateo",
  //   provincia: "Las Palmas",
  //   id: "35033",
  //   label: "Vega de San Mateo (Las Palmas)",
  // },
  // {
  //   municipio: "Yaiza",
  //   provincia: "Las Palmas",
  //   id: "35034",
  //   label: "Yaiza (Las Palmas)",
  // },
  // {
  //   municipio: "Baiona",
  //   provincia: "Pontevedra",
  //   id: "36003",
  //   label: "Baiona (Pontevedra)",
  // },
  // {
  //   municipio: "Bueu",
  //   provincia: "Pontevedra",
  //   id: "36004",
  //   label: "Bueu (Pontevedra)",
  // },
  // {
  //   municipio: "Cambados",
  //   provincia: "Pontevedra",
  //   id: "36006",
  //   label: "Cambados (Pontevedra)",
  // },
  // {
  //   municipio: "Cangas",
  //   provincia: "Pontevedra",
  //   id: "36008",
  //   label: "Cangas (Pontevedra)",
  // },
  // {
  //   municipio: "Grove, O",
  //   provincia: "Pontevedra",
  //   id: "36022",
  //   label: "Grove, O (Pontevedra)",
  // },
  // {
  //   municipio: "Lalín",
  //   provincia: "Pontevedra",
  //   id: "36024",
  //   label: "Lalín (Pontevedra)",
  // },
  // {
  //   municipio: "Nigrán",
  //   provincia: "Pontevedra",
  //   id: "36035",
  //   label: "Nigrán (Pontevedra)",
  // },
  // {
  //   municipio: "Oia",
  //   provincia: "Pontevedra",
  //   id: "36036",
  //   label: "Oia (Pontevedra)",
  // },
  // {
  //   municipio: "Poio",
  //   provincia: "Pontevedra",
  //   id: "36041",
  //   label: "Poio (Pontevedra)",
  // },
  // {
  //   municipio: "Sanxenxo",
  //   provincia: "Pontevedra",
  //   id: "36051",
  //   label: "Sanxenxo (Pontevedra)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Pontevedra",
  //   id: "36057",
  //   label: "Vigo (Pontevedra)",
  // },
  // {
  //   municipio: "Vilagarcía de Arousa",
  //   provincia: "Pontevedra",
  //   id: "36060",
  //   label: "Vilagarcía de Arousa (Pontevedra)",
  // },
  // {
  //   municipio: "Vilanova de Arousa",
  //   provincia: "Pontevedra",
  //   id: "36061",
  //   label: "Vilanova de Arousa (Pontevedra)",
  // },
  // {
  //   municipio: "Illa de Arousa, A",
  //   provincia: "Pontevedra",
  //   id: "36901",
  //   label: "Illa de Arousa, A (Pontevedra)",
  // },
  // {
  //   municipio: "Carrascal de Barregas",
  //   provincia: "Salamanca",
  //   id: "37087",
  //   label: "Carrascal de Barregas (Salamanca)",
  // },
  // {
  //   municipio: "Adeje",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38001",
  //   label: "Adeje (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Agulo",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38002",
  //   label: "Agulo (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Alajeró",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38003",
  //   label: "Alajeró (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Arico",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38005",
  //   label: "Arico (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Arona",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38006",
  //   label: "Arona (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Barlovento",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38007",
  //   label: "Barlovento (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Breña Baja",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38009",
  //   label: "Breña Baja (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Buenavista del Norte",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38010",
  //   label: "Buenavista del Norte (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Candelaria",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38011",
  //   label: "Candelaria (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Fasnia",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38012",
  //   label: "Fasnia (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Frontera",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38013",
  //   label: "Frontera (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Fuencaliente de la Palma",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38014",
  //   label: "Fuencaliente de la Palma (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Garachico",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38015",
  //   label: "Garachico (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Garafía",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38016",
  //   label: "Garafía (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Granadilla de Abona",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38017",
  //   label: "Granadilla de Abona (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Guancha, La",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38018",
  //   label: "Guancha, La (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Guía de Isora",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38019",
  //   label: "Guía de Isora (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Güímar",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38020",
  //   label: "Güímar (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Hermigua",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38021",
  //   label: "Hermigua (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Icod de los Vinos",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38022",
  //   label: "Icod de los Vinos (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "San Cristóbal de La Laguna",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38023",
  //   label: "San Cristóbal de La Laguna (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Llanos de Aridane, Los",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38024",
  //   label: "Llanos de Aridane, Los (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Matanza de Acentejo, La",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38025",
  //   label: "Matanza de Acentejo, La (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Orotava, La",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38026",
  //   label: "Orotava, La (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Puerto de la Cruz",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38028",
  //   label: "Puerto de la Cruz (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Puntagorda",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38029",
  //   label: "Puntagorda (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Puntallana",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38030",
  //   label: "Puntallana (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Realejos, Los",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38031",
  //   label: "Realejos, Los (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Rosario, El",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38032",
  //   label: "Rosario, El (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "San Juan de la Rambla",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38034",
  //   label: "San Juan de la Rambla (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "San Miguel de Abona",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38035",
  //   label: "San Miguel de Abona (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "San Sebastián de la Gomera",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38036",
  //   label: "San Sebastián de la Gomera (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Santa Cruz de Tenerife",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38038",
  //   label: "Santa Cruz de Tenerife (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Santa Úrsula",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38039",
  //   label: "Santa Úrsula (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Santiago del Teide",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38040",
  //   label: "Santiago del Teide (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Sauzal, El",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38041",
  //   label: "Sauzal, El (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Silos, Los",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38042",
  //   label: "Silos, Los (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Tacoronte",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38043",
  //   label: "Tacoronte (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Tazacorte",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38045",
  //   label: "Tazacorte (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Tijarafe",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38047",
  //   label: "Tijarafe (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Valverde",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38048",
  //   label: "Valverde (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Valle Gran Rey",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38049",
  //   label: "Valle Gran Rey (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Vallehermoso",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38050",
  //   label: "Vallehermoso (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Vilaflor de Chasna",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38052",
  //   label: "Vilaflor de Chasna (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Villa de Mazo",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38053",
  //   label: "Villa de Mazo (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Pinar de El Hierro, El",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38901",
  //   label: "Pinar de El Hierro, El (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Castro-Urdiales",
  //   provincia: "Cantabria",
  //   id: "39020",
  //   label: "Castro-Urdiales (Cantabria)",
  // },
  // {
  //   municipio: "Marina de Cudeyo",
  //   provincia: "Cantabria",
  //   id: "39040",
  //   label: "Marina de Cudeyo (Cantabria)",
  // },
  // {
  //   municipio: "Ribamontán al Mar",
  //   provincia: "Cantabria",
  //   id: "39061",
  //   label: "Ribamontán al Mar (Cantabria)",
  // },
  // {
  //   municipio: "Santander",
  //   provincia: "Cantabria",
  //   id: "39075",
  //   label: "Santander (Cantabria)",
  // },
  // {
  //   municipio: "San Vicente de la Barquera",
  //   provincia: "Cantabria",
  //   id: "39080",
  //   label: "San Vicente de la Barquera (Cantabria)",
  // },
  // {
  //   municipio: "Soba",
  //   provincia: "Cantabria",
  //   id: "39083",
  //   label: "Soba (Cantabria)",
  // },
  // {
  //   municipio: "Val de San Vicente",
  //   provincia: "Cantabria",
  //   id: "39095",
  //   label: "Val de San Vicente (Cantabria)",
  // },
  // {
  //   municipio: "Cuéllar",
  //   provincia: "Segovia",
  //   id: "40063",
  //   label: "Cuéllar (Segovia)",
  // },
  // {
  //   municipio: "Aznalcóllar",
  //   provincia: "Sevilla",
  //   id: "41013",
  //   label: "Aznalcóllar (Sevilla)",
  // },
  // {
  //   municipio: "Bollullos de la Mitación",
  //   provincia: "Sevilla",
  //   id: "41016",
  //   label: "Bollullos de la Mitación (Sevilla)",
  // },
  // {
  //   municipio: "Carmona",
  //   provincia: "Sevilla",
  //   id: "41024",
  //   label: "Carmona (Sevilla)",
  // },
  // {
  //   municipio: "Écija",
  //   provincia: "Sevilla",
  //   id: "41039",
  //   label: "Écija (Sevilla)",
  // },
  // {
  //   municipio: "Espartinas",
  //   provincia: "Sevilla",
  //   id: "41040",
  //   label: "Espartinas (Sevilla)",
  // },
  // {
  //   municipio: "Guillena",
  //   provincia: "Sevilla",
  //   id: "41049",
  //   label: "Guillena (Sevilla)",
  // },
  // {
  //   municipio: "Lora del Río",
  //   provincia: "Sevilla",
  //   id: "41055",
  //   label: "Lora del Río (Sevilla)",
  // },
  // {
  //   municipio: "Madroño, El",
  //   provincia: "Sevilla",
  //   id: "41057",
  //   label: "Madroño, El (Sevilla)",
  // },
  // {
  //   municipio: "Osuna",
  //   provincia: "Sevilla",
  //   id: "41068",
  //   label: "Osuna (Sevilla)",
  // },
  // {
  //   municipio: "Salteras",
  //   provincia: "Sevilla",
  //   id: "41085",
  //   label: "Salteras (Sevilla)",
  // },
  // {
  //   municipio: "Sanlúcar la Mayor",
  //   provincia: "Sevilla",
  //   id: "41087",
  //   label: "Sanlúcar la Mayor (Sevilla)",
  // },
  // {
  //   municipio: "Utrera",
  //   provincia: "Sevilla",
  //   id: "41095",
  //   label: "Utrera (Sevilla)",
  // },
  // {
  //   municipio: "Soria",
  //   provincia: "Soria",
  //   id: "42173",
  //   label: "Soria (Soria)",
  // },
  // {
  //   municipio: "San Esteban de Gormaz",
  //   provincia: "Soria",
  //   id: "42162",
  //   label: "San Esteban de Gormaz (Soria)",
  // },
  // {
  //   municipio: "Ametlla de Mar, L'",
  //   provincia: "Tarragona",
  //   id: "43013",
  //   label: "Ametlla de Mar, L' (Tarragona)",
  // },
  // {
  //   municipio: "Almoster",
  //   provincia: "Tarragona",
  //   id: "43011",
  //   label: "Almoster (Tarragona)",
  // },
  // {
  //   municipio: "Aiguamúrcia",
  //   provincia: "Tarragona",
  //   id: "43001",
  //   label: "Aiguamúrcia (Tarragona)",
  // },
  // {
  //   municipio: "Ascó",
  //   provincia: "Tarragona",
  //   id: "43019",
  //   label: "Ascó (Tarragona)",
  // },
  // {
  //   municipio: "Benifallet",
  //   provincia: "Tarragona",
  //   id: "43025",
  //   label: "Benifallet (Tarragona)",
  // },
  // {
  //   municipio: "Calafell",
  //   provincia: "Tarragona",
  //   id: "43037",
  //   label: "Calafell (Tarragona)",
  // },
  // {
  //   municipio: "Capçanes",
  //   provincia: "Tarragona",
  //   id: "43040",
  //   label: "Capçanes (Tarragona)",
  // },
  // {
  //   municipio: "Castellvell del Camp",
  //   provincia: "Tarragona",
  //   id: "43042",
  //   label: "Castellvell del Camp (Tarragona)",
  // },
  // {
  //   municipio: "Sénia, La",
  //   provincia: "Tarragona",
  //   id: "43044",
  //   label: "Sénia, La (Tarragona)",
  // },
  // {
  //   municipio: "Colldejou",
  //   provincia: "Tarragona",
  //   id: "43045",
  //   label: "Colldejou (Tarragona)",
  // },
  // {
  //   municipio: "Corbera d'Ebre",
  //   provincia: "Tarragona",
  //   id: "43048",
  //   label: "Corbera d'Ebre (Tarragona)",
  // },
  // {
  //   municipio: "Cornudella de Montsant",
  //   provincia: "Tarragona",
  //   id: "43049",
  //   label: "Cornudella de Montsant (Tarragona)",
  // },
  // {
  //   municipio: "Cunit",
  //   provincia: "Tarragona",
  //   id: "43051",
  //   label: "Cunit (Tarragona)",
  // },
  // {
  //   municipio: "Fatarella, La",
  //   provincia: "Tarragona",
  //   id: "43056",
  //   label: "Fatarella, La (Tarragona)",
  // },
  // {
  //   municipio: "Flix",
  //   provincia: "Tarragona",
  //   id: "43060",
  //   label: "Flix (Tarragona)",
  // },
  // {
  //   municipio: "Garcia",
  //   provincia: "Tarragona",
  //   id: "43065",
  //   label: "Garcia (Tarragona)",
  // },
  // {
  //   municipio: "Margalef",
  //   provincia: "Tarragona",
  //   id: "43075",
  //   label: "Margalef (Tarragona)",
  // },
  // {
  //   municipio: "Miravet",
  //   provincia: "Tarragona",
  //   id: "43084",
  //   label: "Miravet (Tarragona)",
  // },
  // {
  //   municipio: "Piles, Les",
  //   provincia: "Tarragona",
  //   id: "43105",
  //   label: "Piles, Les (Tarragona)",
  // },
  // {
  //   municipio: "Pinell de Brai, El",
  //   provincia: "Tarragona",
  //   id: "43106",
  //   label: "Pinell de Brai, El (Tarragona)",
  // },
  // {
  //   municipio: "Marçà",
  //   provincia: "Tarragona",
  //   id: "43076",
  //   label: "Marçà (Tarragona)",
  // },
  // {
  //   municipio: "Morera de Montsant, La",
  //   provincia: "Tarragona",
  //   id: "43096",
  //   label: "Morera de Montsant, La (Tarragona)",
  // },
  // {
  //   municipio: "Perelló, El",
  //   provincia: "Tarragona",
  //   id: "43104",
  //   label: "Perelló, El (Tarragona)",
  // },
  // {
  //   municipio: "Pla de Santa Maria, El",
  //   provincia: "Tarragona",
  //   id: "43108",
  //   label: "Pla de Santa Maria, El (Tarragona)",
  // },
  // {
  //   municipio: "Querol",
  //   provincia: "Tarragona",
  //   id: "43120",
  //   label: "Querol (Tarragona)",
  // },
  // {
  //   municipio: "Reus",
  //   provincia: "Tarragona",
  //   id: "43123",
  //   label: "Reus (Tarragona)",
  // },
  // {
  //   municipio: "Riba-roja d'Ebre",
  //   provincia: "Tarragona",
  //   id: "43125",
  //   label: "Riba-roja d'Ebre (Tarragona)",
  // },
  // {
  //   municipio: "Ràpita, La",
  //   provincia: "Tarragona",
  //   id: "43136",
  //   label: "Ràpita, La (Tarragona)",
  // },
  // {
  //   municipio: "Selva del Camp, La",
  //   provincia: "Tarragona",
  //   id: "43145",
  //   label: "Selva del Camp, La (Tarragona)",
  // },
  // {
  //   municipio: "Tarragona",
  //   provincia: "Tarragona",
  //   id: "43148",
  //   label: "Tarragona (Tarragona)",
  // },
  // {
  //   municipio: "Tivissa",
  //   provincia: "Tarragona",
  //   id: "43150",
  //   label: "Tivissa (Tarragona)",
  // },
  // {
  //   municipio: "Tortosa",
  //   provincia: "Tarragona",
  //   id: "43155",
  //   label: "Tortosa (Tarragona)",
  // },
  // {
  //   municipio: "Ulldemolins",
  //   provincia: "Tarragona",
  //   id: "43157",
  //   label: "Ulldemolins (Tarragona)",
  // },
  // {
  //   municipio: "Valls",
  //   provincia: "Tarragona",
  //   id: "43161",
  //   label: "Valls (Tarragona)",
  // },
  // {
  //   municipio: "Vandellòs i l'Hospitalet de l'Infant",
  //   provincia: "Tarragona",
  //   id: "43162",
  //   label: "Vandellòs i l'Hospitalet de l'Infant (Tarragona)",
  // },
  // {
  //   municipio: "Vilanova de Prades",
  //   provincia: "Tarragona",
  //   id: "43168",
  //   label: "Vilanova de Prades (Tarragona)",
  // },
  // {
  //   municipio: "Vinebre",
  //   provincia: "Tarragona",
  //   id: "43177",
  //   label: "Vinebre (Tarragona)",
  // },
  // {
  //   municipio: "Deltebre",
  //   provincia: "Tarragona",
  //   id: "43901",
  //   label: "Deltebre (Tarragona)",
  // },
  // {
  //   municipio: "Salou",
  //   provincia: "Tarragona",
  //   id: "43905",
  //   label: "Salou (Tarragona)",
  // },
  // {
  //   municipio: "Ampolla, L'",
  //   provincia: "Tarragona",
  //   id: "43906",
  //   label: "Ampolla, L' (Tarragona)",
  // },
  // {
  //   municipio: "Albarracín",
  //   provincia: "Teruel",
  //   id: "44009",
  //   label: "Albarracín (Teruel)",
  // },
  // {
  //   municipio: "Cantavieja",
  //   provincia: "Teruel",
  //   id: "44059",
  //   label: "Cantavieja (Teruel)",
  // },
  // {
  //   municipio: "Mosqueruela",
  //   provincia: "Teruel",
  //   id: "44160",
  //   label: "Mosqueruela (Teruel)",
  // },
  // {
  //   municipio: "Teruel",
  //   provincia: "Teruel",
  //   id: "44216",
  //   label: "Teruel (Teruel)",
  // },
  // {
  //   municipio: "Oropesa",
  //   provincia: "Toledo",
  //   id: "45125",
  //   label: "Oropesa (Toledo)",
  // },
  // {
  //   municipio: "Santa Cruz de la Zarza",
  //   provincia: "Toledo",
  //   id: "45156",
  //   label: "Santa Cruz de la Zarza (Toledo)",
  // },
  // {
  //   municipio: "Santa Cruz del Retamar",
  //   provincia: "Toledo",
  //   id: "45157",
  //   label: "Santa Cruz del Retamar (Toledo)",
  // },
  // {
  //   municipio: "Seseña",
  //   provincia: "Toledo",
  //   id: "45161",
  //   label: "Seseña (Toledo)",
  // },
  // {
  //   municipio: "Real de San Vicente, El",
  //   provincia: "Toledo",
  //   id: "45144",
  //   label: "Real de San Vicente, El (Toledo)",
  // },
  // {
  //   municipio: "Talavera de la Reina",
  //   provincia: "Toledo",
  //   id: "45165",
  //   label: "Talavera de la Reina (Toledo)",
  // },
  // {
  //   municipio: "Toledo",
  //   provincia: "Toledo",
  //   id: "45168",
  //   label: "Toledo (Toledo)",
  // },
  // {
  //   municipio: "Velada",
  //   provincia: "Toledo",
  //   id: "45181",
  //   label: "Velada (Toledo)",
  // },
  // {
  //   municipio: "Villafranca de los Caballeros",
  //   provincia: "Toledo",
  //   id: "45187",
  //   label: "Villafranca de los Caballeros (Toledo)",
  // },
  // {
  //   municipio: "San Miguel de Salinas",
  //   provincia: "Alicante",
  //   id: "03120",
  //   label: "San Miguel de Salinas (Alicante)",
  // },
  // {
  //   municipio: "Alcosser",
  //   provincia: "Alicante",
  //   id: "03007",
  //   label: "Alcosser (Alicante)",
  // },
  // {
  //   municipio: "Deba",
  //   provincia: "Guipúzcoa",
  //   id: "20029",
  //   label: "Deba (Guipúzcoa)",
  // },
  // {
  //   municipio: "Getaria",
  //   provincia: "Guipúzcoa",
  //   id: "20039",
  //   label: "Getaria (Guipúzcoa)",
  // },
  // {
  //   municipio: "Hernani",
  //   provincia: "Guipúzcoa",
  //   id: "20040",
  //   label: "Hernani (Guipúzcoa)",
  // },
  // {
  //   municipio: "Algemesí",
  //   provincia: "Valencia",
  //   id: "46029",
  //   label: "Algemesí (Valencia)",
  // },
  // {
  //   municipio: "Ayora",
  //   provincia: "Valencia",
  //   id: "46044",
  //   label: "Ayora (Valencia)",
  // },
  // {
  //   municipio: "Calles",
  //   provincia: "Valencia",
  //   id: "46079",
  //   label: "Calles (Valencia)",
  // },
  // {
  //   municipio: "Cullera",
  //   provincia: "Valencia",
  //   id: "46105",
  //   label: "Cullera (Valencia)",
  // },
  // {
  //   municipio: "Xàtiva",
  //   provincia: "Valencia",
  //   id: "46145",
  //   label: "Xàtiva (Valencia)",
  // },
  // {
  //   municipio: "Moixent/Mogente",
  //   provincia: "Valencia",
  //   id: "46170",
  //   label: "Moixent/Mogente (Valencia)",
  // },
  // {
  //   municipio: "Requena",
  //   provincia: "Valencia",
  //   id: "46213",
  //   label: "Requena (Valencia)",
  // },
  // {
  //   municipio: "Tarazona",
  //   provincia: "Zaragoza",
  //   id: "50251",
  //   label: "Tarazona (Zaragoza)",
  // },
  // {
  //   municipio: "Sástago",
  //   provincia: "Zaragoza",
  //   id: "50240",
  //   label: "Sástago (Zaragoza)",
  // },
  // {
  //   municipio: "Zaragoza",
  //   provincia: "Zaragoza",
  //   id: "50297",
  //   label: "Zaragoza (Zaragoza)",
  // },
  // {
  //   municipio: "Zuera",
  //   provincia: "Zaragoza",
  //   id: "50298",
  //   label: "Zuera (Zaragoza)",
  // },
  // {
  //   municipio: "Hondarribia",
  //   provincia: "Guipúzcoa",
  //   id: "20036",
  //   label: "Hondarribia (Guipúzcoa)",
  // },
  // {
  //   municipio: "Sollana",
  //   provincia: "Valencia",
  //   id: "46233",
  //   label: "Sollana (Valencia)",
  // },
  // {
  //   municipio: "Sueca",
  //   provincia: "Valencia",
  //   id: "46235",
  //   label: "Sueca (Valencia)",
  // },
  // {
  //   municipio: "València",
  //   provincia: "Valencia",
  //   id: "46250",
  //   label: "València (Valencia)",
  // },
  // {
  //   municipio: "Venta del Moro",
  //   provincia: "Valencia",
  //   id: "46254",
  //   label: "Venta del Moro (Valencia)",
  // },
  // {
  //   municipio: "Vilamarxant",
  //   provincia: "Valencia",
  //   id: "46256",
  //   label: "Vilamarxant (Valencia)",
  // },
  // {
  //   municipio: "Valladolid",
  //   provincia: "Valladolid",
  //   id: "47186",
  //   label: "Valladolid (Valladolid)",
  // },
  // {
  //   municipio: "Almunia de Doña Godina, La",
  //   provincia: "Zaragoza",
  //   id: "50025",
  //   label: "Almunia de Doña Godina, La (Zaragoza)",
  // },
  // {
  //   municipio: "Ceuta",
  //   provincia: "Ceuta",
  //   id: "51001",
  //   label: "Ceuta (Ceuta)",
  // },
  // {
  //   municipio: "Alpartir",
  //   provincia: "Zaragoza",
  //   id: "50026",
  //   label: "Alpartir (Zaragoza)",
  // },
  // {
  //   municipio: "Abengibre",
  //   provincia: "Albacete",
  //   id: "02001",
  //   label: "Abengibre (Albacete)",
  // },
  // {
  //   municipio: "Alatoz",
  //   provincia: "Albacete",
  //   id: "02002",
  //   label: "Alatoz (Albacete)",
  // },
  // {
  //   municipio: "Golosalvo",
  //   provincia: "Albacete",
  //   id: "02036",
  //   label: "Golosalvo (Albacete)",
  // },
  // {
  //   municipio: "Alcalá del Júcar",
  //   provincia: "Albacete",
  //   id: "02007",
  //   label: "Alcalá del Júcar (Albacete)",
  // },
  // {
  //   municipio: "Alborea",
  //   provincia: "Albacete",
  //   id: "02005",
  //   label: "Alborea (Albacete)",
  // },
  // {
  //   municipio: "Albatana",
  //   provincia: "Albacete",
  //   id: "02004",
  //   label: "Albatana (Albacete)",
  // },
  // {
  //   municipio: "Balazote",
  //   provincia: "Albacete",
  //   id: "02012",
  //   label: "Balazote (Albacete)",
  // },
  // {
  //   municipio: "Alcadozo",
  //   provincia: "Albacete",
  //   id: "02006",
  //   label: "Alcadozo (Albacete)",
  // },
  // {
  //   municipio: "Almansa",
  //   provincia: "Albacete",
  //   id: "02009",
  //   label: "Almansa (Albacete)",
  // },
  // {
  //   municipio: "Alpera",
  //   provincia: "Albacete",
  //   id: "02010",
  //   label: "Alpera (Albacete)",
  // },
  // {
  //   municipio: "Bogarra",
  //   provincia: "Albacete",
  //   id: "02017",
  //   label: "Bogarra (Albacete)",
  // },
  // {
  //   municipio: "Hoyos del Collado",
  //   provincia: "Ávila",
  //   id: "05104",
  //   label: "Hoyos del Collado (Ávila)",
  // },
  // {
  //   municipio: "Ballestero, El",
  //   provincia: "Albacete",
  //   id: "02014",
  //   label: "Ballestero, El (Albacete)",
  // },
  // {
  //   municipio: "Ayna",
  //   provincia: "Albacete",
  //   id: "02011",
  //   label: "Ayna (Albacete)",
  // },
  // {
  //   municipio: "Carcelén",
  //   provincia: "Albacete",
  //   id: "02020",
  //   label: "Carcelén (Albacete)",
  // },
  // {
  //   municipio: "Balsa de Ves",
  //   provincia: "Albacete",
  //   id: "02013",
  //   label: "Balsa de Ves (Albacete)",
  // },
  // {
  //   municipio: "Barrax",
  //   provincia: "Albacete",
  //   id: "02015",
  //   label: "Barrax (Albacete)",
  // },
  // {
  //   municipio: "Bienservida",
  //   provincia: "Albacete",
  //   id: "02016",
  //   label: "Bienservida (Albacete)",
  // },
  // {
  //   municipio: "Casas de Juan Núñez",
  //   provincia: "Albacete",
  //   id: "02021",
  //   label: "Casas de Juan Núñez (Albacete)",
  // },
  // {
  //   municipio: "Bonete",
  //   provincia: "Albacete",
  //   id: "02018",
  //   label: "Bonete (Albacete)",
  // },
  // {
  //   municipio: "Bonillo, El",
  //   provincia: "Albacete",
  //   id: "02019",
  //   label: "Bonillo, El (Albacete)",
  // },
  // {
  //   municipio: "Herrera, La",
  //   provincia: "Albacete",
  //   id: "02038",
  //   label: "Herrera, La (Albacete)",
  // },
  // {
  //   municipio: "Caudete",
  //   provincia: "Albacete",
  //   id: "02025",
  //   label: "Caudete (Albacete)",
  // },
  // {
  //   municipio: "Cotillas",
  //   provincia: "Albacete",
  //   id: "02028",
  //   label: "Cotillas (Albacete)",
  // },
  // {
  //   municipio: "Chinchilla de Monte-Aragón",
  //   provincia: "Albacete",
  //   id: "02029",
  //   label: "Chinchilla de Monte-Aragón (Albacete)",
  // },
  // {
  //   municipio: "Gineta, La",
  //   provincia: "Albacete",
  //   id: "02035",
  //   label: "Gineta, La (Albacete)",
  // },
  // {
  //   municipio: "Casas de Lázaro",
  //   provincia: "Albacete",
  //   id: "02022",
  //   label: "Casas de Lázaro (Albacete)",
  // },
  // {
  //   municipio: "Casas-Ibáñez",
  //   provincia: "Albacete",
  //   id: "02024",
  //   label: "Casas-Ibáñez (Albacete)",
  // },
  // {
  //   municipio: "Elche de la Sierra",
  //   provincia: "Albacete",
  //   id: "02030",
  //   label: "Elche de la Sierra (Albacete)",
  // },
  // {
  //   municipio: "Cenizate",
  //   provincia: "Albacete",
  //   id: "02026",
  //   label: "Cenizate (Albacete)",
  // },
  // {
  //   municipio: "Corral-Rubio",
  //   provincia: "Albacete",
  //   id: "02027",
  //   label: "Corral-Rubio (Albacete)",
  // },
  // {
  //   municipio: "Moraleja de Matacabras",
  //   provincia: "Ávila",
  //   id: "05134",
  //   label: "Moraleja de Matacabras (Ávila)",
  // },
  // {
  //   municipio: "Férez",
  //   provincia: "Albacete",
  //   id: "02031",
  //   label: "Férez (Albacete)",
  // },
  // {
  //   municipio: "Fuensanta",
  //   provincia: "Albacete",
  //   id: "02032",
  //   label: "Fuensanta (Albacete)",
  // },
  // {
  //   municipio: "Fuente-Álamo",
  //   provincia: "Albacete",
  //   id: "02033",
  //   label: "Fuente-Álamo (Albacete)",
  // },
  // {
  //   municipio: "Riba de Escalote, La",
  //   provincia: "Soria",
  //   id: "42157",
  //   label: "Riba de Escalote, La (Soria)",
  // },
  // {
  //   municipio: "Fuentealbilla",
  //   provincia: "Albacete",
  //   id: "02034",
  //   label: "Fuentealbilla (Albacete)",
  // },
  // {
  //   municipio: "Madrigueras",
  //   provincia: "Albacete",
  //   id: "02045",
  //   label: "Madrigueras (Albacete)",
  // },
  // {
  //   municipio: "Higueruela",
  //   provincia: "Albacete",
  //   id: "02039",
  //   label: "Higueruela (Albacete)",
  // },
  // {
  //   municipio: "Montealegre del Castillo",
  //   provincia: "Albacete",
  //   id: "02051",
  //   label: "Montealegre del Castillo (Albacete)",
  // },
  // {
  //   municipio: "Hoya-Gonzalo",
  //   provincia: "Albacete",
  //   id: "02040",
  //   label: "Hoya-Gonzalo (Albacete)",
  // },
  // {
  //   municipio: "Agres",
  //   provincia: "Alicante",
  //   id: "03003",
  //   label: "Agres (Alicante)",
  // },
  // {
  //   municipio: "Masegoso",
  //   provincia: "Albacete",
  //   id: "02047",
  //   label: "Masegoso (Albacete)",
  // },
  // {
  //   municipio: "Motilleja",
  //   provincia: "Albacete",
  //   id: "02052",
  //   label: "Motilleja (Albacete)",
  // },
  // {
  //   municipio: "Jorquera",
  //   provincia: "Albacete",
  //   id: "02041",
  //   label: "Jorquera (Albacete)",
  // },
  // {
  //   municipio: "Letur",
  //   provincia: "Albacete",
  //   id: "02042",
  //   label: "Letur (Albacete)",
  // },
  // {
  //   municipio: "Montalvos",
  //   provincia: "Albacete",
  //   id: "02050",
  //   label: "Montalvos (Albacete)",
  // },
  // {
  //   municipio: "Lezuza",
  //   provincia: "Albacete",
  //   id: "02043",
  //   label: "Lezuza (Albacete)",
  // },
  // {
  //   municipio: "Pozuelo",
  //   provincia: "Albacete",
  //   id: "02065",
  //   label: "Pozuelo (Albacete)",
  // },
  // {
  //   municipio: "Liétor",
  //   provincia: "Albacete",
  //   id: "02044",
  //   label: "Liétor (Albacete)",
  // },
  // {
  //   municipio: "Arevalillo",
  //   provincia: "Ávila",
  //   id: "05015",
  //   label: "Arevalillo (Ávila)",
  // },
  // {
  //   municipio: "Mahora",
  //   provincia: "Albacete",
  //   id: "02046",
  //   label: "Mahora (Albacete)",
  // },
  // {
  //   municipio: "Navas de Jorquera",
  //   provincia: "Albacete",
  //   id: "02054",
  //   label: "Navas de Jorquera (Albacete)",
  // },
  // {
  //   municipio: "Minaya",
  //   provincia: "Albacete",
  //   id: "02048",
  //   label: "Minaya (Albacete)",
  // },
  // {
  //   municipio: "Molinicos",
  //   provincia: "Albacete",
  //   id: "02049",
  //   label: "Molinicos (Albacete)",
  // },
  // {
  //   municipio: "Munera",
  //   provincia: "Albacete",
  //   id: "02053",
  //   label: "Munera (Albacete)",
  // },
  // {
  //   municipio: "Piqueras del Castillo",
  //   provincia: "Cuenca",
  //   id: "16161",
  //   label: "Piqueras del Castillo (Cuenca)",
  // },
  // {
  //   municipio: "Povedilla",
  //   provincia: "Albacete",
  //   id: "02062",
  //   label: "Povedilla (Albacete)",
  // },
  // {
  //   municipio: "Robledo",
  //   provincia: "Albacete",
  //   id: "02068",
  //   label: "Robledo (Albacete)",
  // },
  // {
  //   municipio: "Roda, La",
  //   provincia: "Albacete",
  //   id: "02069",
  //   label: "Roda, La (Albacete)",
  // },
  // {
  //   municipio: "Ontur",
  //   provincia: "Albacete",
  //   id: "02056",
  //   label: "Ontur (Albacete)",
  // },
  // {
  //   municipio: "Pozo-Lorente",
  //   provincia: "Albacete",
  //   id: "02064",
  //   label: "Pozo-Lorente (Albacete)",
  // },
  // {
  //   municipio: "Salobre",
  //   provincia: "Albacete",
  //   id: "02070",
  //   label: "Salobre (Albacete)",
  // },
  // {
  //   municipio: "San Pedro",
  //   provincia: "Albacete",
  //   id: "02071",
  //   label: "San Pedro (Albacete)",
  // },
  // {
  //   municipio: "Peñascosa",
  //   provincia: "Albacete",
  //   id: "02059",
  //   label: "Peñascosa (Albacete)",
  // },
  // {
  //   municipio: "Ossa de Montiel",
  //   provincia: "Albacete",
  //   id: "02057",
  //   label: "Ossa de Montiel (Albacete)",
  // },
  // {
  //   municipio: "Paterna del Madera",
  //   provincia: "Albacete",
  //   id: "02058",
  //   label: "Paterna del Madera (Albacete)",
  // },
  // {
  //   municipio: "Peñas de San Pedro",
  //   provincia: "Albacete",
  //   id: "02060",
  //   label: "Peñas de San Pedro (Albacete)",
  // },
  // {
  //   municipio: "Pétrola",
  //   provincia: "Albacete",
  //   id: "02061",
  //   label: "Pétrola (Albacete)",
  // },
  // {
  //   municipio: "Portalrubio de Guadamejud",
  //   provincia: "Cuenca",
  //   id: "16162",
  //   label: "Portalrubio de Guadamejud (Cuenca)",
  // },
  // {
  //   municipio: "Pozohondo",
  //   provincia: "Albacete",
  //   id: "02063",
  //   label: "Pozohondo (Albacete)",
  // },
  // {
  //   municipio: "Gatón de Campos",
  //   provincia: "Valladolid",
  //   id: "47070",
  //   label: "Gatón de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Recueja, La",
  //   provincia: "Albacete",
  //   id: "02066",
  //   label: "Recueja, La (Albacete)",
  // },
  // {
  //   municipio: "Cella",
  //   provincia: "Teruel",
  //   id: "44076",
  //   label: "Cella (Teruel)",
  // },
  // {
  //   municipio: "Socovos",
  //   provincia: "Albacete",
  //   id: "02072",
  //   label: "Socovos (Albacete)",
  // },
  // {
  //   municipio: "Benidoleig",
  //   provincia: "Alicante",
  //   id: "03030",
  //   label: "Benidoleig (Alicante)",
  // },
  // {
  //   municipio: "Riópar",
  //   provincia: "Albacete",
  //   id: "02067",
  //   label: "Riópar (Albacete)",
  // },
  // {
  //   municipio: "Tarazona de la Mancha",
  //   provincia: "Albacete",
  //   id: "02073",
  //   label: "Tarazona de la Mancha (Albacete)",
  // },
  // {
  //   municipio: "Tobarra",
  //   provincia: "Albacete",
  //   id: "02074",
  //   label: "Tobarra (Albacete)",
  // },
  // {
  //   municipio: "Atzúbia, l'",
  //   provincia: "Alicante",
  //   id: "03001",
  //   label: "Atzúbia, l' (Alicante)",
  // },
  // {
  //   municipio: "Valdeganga",
  //   provincia: "Albacete",
  //   id: "02075",
  //   label: "Valdeganga (Albacete)",
  // },
  // {
  //   municipio: "Vianos",
  //   provincia: "Albacete",
  //   id: "02076",
  //   label: "Vianos (Albacete)",
  // },
  // {
  //   municipio: "Villa de Ves",
  //   provincia: "Albacete",
  //   id: "02077",
  //   label: "Villa de Ves (Albacete)",
  // },
  // {
  //   municipio: "Beniarrés",
  //   provincia: "Alicante",
  //   id: "03028",
  //   label: "Beniarrés (Alicante)",
  // },
  // {
  //   municipio: "Villalgordo del Júcar",
  //   provincia: "Albacete",
  //   id: "02078",
  //   label: "Villalgordo del Júcar (Albacete)",
  // },
  // {
  //   municipio: "Villamalea",
  //   provincia: "Albacete",
  //   id: "02079",
  //   label: "Villamalea (Albacete)",
  // },
  // {
  //   municipio: "Benigembla",
  //   provincia: "Alicante",
  //   id: "03029",
  //   label: "Benigembla (Alicante)",
  // },
  // {
  //   municipio: "Poveda",
  //   provincia: "Ávila",
  //   id: "05188",
  //   label: "Poveda (Ávila)",
  // },
  // {
  //   municipio: "Villapalacios",
  //   provincia: "Albacete",
  //   id: "02080",
  //   label: "Villapalacios (Albacete)",
  // },
  // {
  //   municipio: "Viveros",
  //   provincia: "Albacete",
  //   id: "02085",
  //   label: "Viveros (Albacete)",
  // },
  // {
  //   municipio: "Villatoya",
  //   provincia: "Albacete",
  //   id: "02082",
  //   label: "Villatoya (Albacete)",
  // },
  // {
  //   municipio: "Villavaliente",
  //   provincia: "Albacete",
  //   id: "02083",
  //   label: "Villavaliente (Albacete)",
  // },
  // {
  //   municipio: "Avellaneda",
  //   provincia: "Ávila",
  //   id: "05018",
  //   label: "Avellaneda (Ávila)",
  // },
  // {
  //   municipio: "Villaverde de Guadalimar",
  //   provincia: "Albacete",
  //   id: "02084",
  //   label: "Villaverde de Guadalimar (Albacete)",
  // },
  // {
  //   municipio: "Benifato",
  //   provincia: "Alicante",
  //   id: "03033",
  //   label: "Benifato (Alicante)",
  // },
  // {
  //   municipio: "Pozo Cañada",
  //   provincia: "Albacete",
  //   id: "02901",
  //   label: "Pozo Cañada (Albacete)",
  // },
  // {
  //   municipio: "Benijófar",
  //   provincia: "Alicante",
  //   id: "03034",
  //   label: "Benijófar (Alicante)",
  // },
  // {
  //   municipio: "Agost",
  //   provincia: "Alicante",
  //   id: "03002",
  //   label: "Agost (Alicante)",
  // },
  // {
  //   municipio: "Benimassot",
  //   provincia: "Alicante",
  //   id: "03039",
  //   label: "Benimassot (Alicante)",
  // },
  // {
  //   municipio: "Aigües",
  //   provincia: "Alicante",
  //   id: "03004",
  //   label: "Aigües (Alicante)",
  // },
  // {
  //   municipio: "Alcalalí",
  //   provincia: "Alicante",
  //   id: "03006",
  //   label: "Alcalalí (Alicante)",
  // },
  // {
  //   municipio: "Albatera",
  //   provincia: "Alicante",
  //   id: "03005",
  //   label: "Albatera (Alicante)",
  // },
  // {
  //   municipio: "Alcoleja",
  //   provincia: "Alicante",
  //   id: "03008",
  //   label: "Alcoleja (Alicante)",
  // },
  // {
  //   municipio: "Alfafara",
  //   provincia: "Alicante",
  //   id: "03010",
  //   label: "Alfafara (Alicante)",
  // },
  // {
  //   municipio: "Algorfa",
  //   provincia: "Alicante",
  //   id: "03012",
  //   label: "Algorfa (Alicante)",
  // },
  // {
  //   municipio: "Algueña",
  //   provincia: "Alicante",
  //   id: "03013",
  //   label: "Algueña (Alicante)",
  // },
  // {
  //   municipio: "Alqueria d'Asnar, l'",
  //   provincia: "Alicante",
  //   id: "03017",
  //   label: "Alqueria d'Asnar, l' (Alicante)",
  // },
  // {
  //   municipio: "Aspe",
  //   provincia: "Alicante",
  //   id: "03019",
  //   label: "Aspe (Alicante)",
  // },
  // {
  //   municipio: "Balones",
  //   provincia: "Alicante",
  //   id: "03020",
  //   label: "Balones (Alicante)",
  // },
  // {
  //   municipio: "Almoradí",
  //   provincia: "Alicante",
  //   id: "03015",
  //   label: "Almoradí (Alicante)",
  // },
  // {
  //   municipio: "Almudaina",
  //   provincia: "Alicante",
  //   id: "03016",
  //   label: "Almudaina (Alicante)",
  // },
  // {
  //   municipio: "Benejúzar",
  //   provincia: "Alicante",
  //   id: "03024",
  //   label: "Benejúzar (Alicante)",
  // },
  // {
  //   municipio: "Beniarbeig",
  //   provincia: "Alicante",
  //   id: "03026",
  //   label: "Beniarbeig (Alicante)",
  // },
  // {
  //   municipio: "Beneixama",
  //   provincia: "Alicante",
  //   id: "03023",
  //   label: "Beneixama (Alicante)",
  // },
  // {
  //   municipio: "Benillup",
  //   provincia: "Alicante",
  //   id: "03036",
  //   label: "Benillup (Alicante)",
  // },
  // {
  //   municipio: "Benimantell",
  //   provincia: "Alicante",
  //   id: "03037",
  //   label: "Benimantell (Alicante)",
  // },
  // {
  //   municipio: "Banyeres de Mariola",
  //   provincia: "Alicante",
  //   id: "03021",
  //   label: "Banyeres de Mariola (Alicante)",
  // },
  // {
  //   municipio: "Benasau",
  //   provincia: "Alicante",
  //   id: "03022",
  //   label: "Benasau (Alicante)",
  // },
  // {
  //   municipio: "Benferri",
  //   provincia: "Alicante",
  //   id: "03025",
  //   label: "Benferri (Alicante)",
  // },
  // {
  //   municipio: "Beniardà",
  //   provincia: "Alicante",
  //   id: "03027",
  //   label: "Beniardà (Alicante)",
  // },
  // {
  //   municipio: "Benimarfull",
  //   provincia: "Alicante",
  //   id: "03038",
  //   label: "Benimarfull (Alicante)",
  // },
  // {
  //   municipio: "Benifallim",
  //   provincia: "Alicante",
  //   id: "03032",
  //   label: "Benifallim (Alicante)",
  // },
  // {
  //   municipio: "Barco de Ávila, El",
  //   provincia: "Ávila",
  //   id: "05021",
  //   label: "Barco de Ávila, El (Ávila)",
  // },
  // {
  //   municipio: "Benilloba",
  //   provincia: "Alicante",
  //   id: "03035",
  //   label: "Benilloba (Alicante)",
  // },
  // {
  //   municipio: "Biar",
  //   provincia: "Alicante",
  //   id: "03043",
  //   label: "Biar (Alicante)",
  // },
  // {
  //   municipio: "Bigastro",
  //   provincia: "Alicante",
  //   id: "03044",
  //   label: "Bigastro (Alicante)",
  // },
  // {
  //   municipio: "Portilla",
  //   provincia: "Cuenca",
  //   id: "16163",
  //   label: "Portilla (Cuenca)",
  // },
  // {
  //   municipio: "Penàguila",
  //   provincia: "Alicante",
  //   id: "03103",
  //   label: "Penàguila (Alicante)",
  // },
  // {
  //   municipio: "Petrer",
  //   provincia: "Alicante",
  //   id: "03104",
  //   label: "Petrer (Alicante)",
  // },
  // {
  //   municipio: "Benimeli",
  //   provincia: "Alicante",
  //   id: "03040",
  //   label: "Benimeli (Alicante)",
  // },
  // {
  //   municipio: "Benissa",
  //   provincia: "Alicante",
  //   id: "03041",
  //   label: "Benissa (Alicante)",
  // },
  // {
  //   municipio: "Pinós, el/Pinoso",
  //   provincia: "Alicante",
  //   id: "03105",
  //   label: "Pinós, el/Pinoso (Alicante)",
  // },
  // {
  //   municipio: "San Miguel de Corneja",
  //   provincia: "Ávila",
  //   id: "05217",
  //   label: "San Miguel de Corneja (Ávila)",
  // },
  // {
  //   municipio: "Bolulla",
  //   provincia: "Alicante",
  //   id: "03045",
  //   label: "Bolulla (Alicante)",
  // },
  // {
  //   municipio: "Famorca",
  //   provincia: "Alicante",
  //   id: "03068",
  //   label: "Famorca (Alicante)",
  // },
  // {
  //   municipio: "Callosa d'en Sarrià",
  //   provincia: "Alicante",
  //   id: "03048",
  //   label: "Callosa d'en Sarrià (Alicante)",
  // },
  // {
  //   municipio: "Callosa de Segura",
  //   provincia: "Alicante",
  //   id: "03049",
  //   label: "Callosa de Segura (Alicante)",
  // },
  // {
  //   municipio: "Busot",
  //   provincia: "Alicante",
  //   id: "03046",
  //   label: "Busot (Alicante)",
  // },
  // {
  //   municipio: "San Isidro",
  //   provincia: "Alicante",
  //   id: "03904",
  //   label: "San Isidro (Alicante)",
  // },
  // {
  //   municipio: "Albox",
  //   provincia: "Almería",
  //   id: "04006",
  //   label: "Albox (Almería)",
  // },
  // {
  //   municipio: "Camp de Mirra, el/Campo de Mirra",
  //   provincia: "Alicante",
  //   id: "03051",
  //   label: "Camp de Mirra, el/Campo de Mirra (Alicante)",
  // },
  // {
  //   municipio: "Villanueva de la Condesa",
  //   provincia: "Valladolid",
  //   id: "47219",
  //   label: "Villanueva de la Condesa (Valladolid)",
  // },
  // {
  //   municipio: "Planes",
  //   provincia: "Alicante",
  //   id: "03106",
  //   label: "Planes (Alicante)",
  // },
  // {
  //   municipio: "Cañada",
  //   provincia: "Alicante",
  //   id: "03052",
  //   label: "Cañada (Alicante)",
  // },
  // {
  //   municipio: "Quatretondeta",
  //   provincia: "Alicante",
  //   id: "03060",
  //   label: "Quatretondeta (Alicante)",
  // },
  // {
  //   municipio: "Confrides",
  //   provincia: "Alicante",
  //   id: "03057",
  //   label: "Confrides (Alicante)",
  // },
  // {
  //   municipio: "Castalla",
  //   provincia: "Alicante",
  //   id: "03053",
  //   label: "Castalla (Alicante)",
  // },
  // {
  //   municipio: "Daya Nueva",
  //   provincia: "Alicante",
  //   id: "03061",
  //   label: "Daya Nueva (Alicante)",
  // },
  // {
  //   municipio: "Castell de Castells",
  //   provincia: "Alicante",
  //   id: "03054",
  //   label: "Castell de Castells (Alicante)",
  // },
  // {
  //   municipio: "Catral",
  //   provincia: "Alicante",
  //   id: "03055",
  //   label: "Catral (Alicante)",
  // },
  // {
  //   municipio: "Daya Vieja",
  //   provincia: "Alicante",
  //   id: "03062",
  //   label: "Daya Vieja (Alicante)",
  // },
  // {
  //   municipio: "Marlín",
  //   provincia: "Ávila",
  //   id: "05120",
  //   label: "Marlín (Ávila)",
  // },
  // {
  //   municipio: "Cocentaina",
  //   provincia: "Alicante",
  //   id: "03056",
  //   label: "Cocentaina (Alicante)",
  // },
  // {
  //   municipio: "Dolores",
  //   provincia: "Alicante",
  //   id: "03064",
  //   label: "Dolores (Alicante)",
  // },
  // {
  //   municipio: "Elx/Elche",
  //   provincia: "Alicante",
  //   id: "03065",
  //   label: "Elx/Elche (Alicante)",
  // },
  // {
  //   municipio: "Villaquirán de la Puebla",
  //   provincia: "Burgos",
  //   id: "09455",
  //   label: "Villaquirán de la Puebla (Burgos)",
  // },
  // {
  //   municipio: "Crevillent",
  //   provincia: "Alicante",
  //   id: "03059",
  //   label: "Crevillent (Alicante)",
  // },
  // {
  //   municipio: "Finestrat",
  //   provincia: "Alicante",
  //   id: "03069",
  //   label: "Finestrat (Alicante)",
  // },
  // {
  //   municipio: "Cox",
  //   provincia: "Alicante",
  //   id: "03058",
  //   label: "Cox (Alicante)",
  // },
  // {
  //   municipio: "Acehúche",
  //   provincia: "Cáceres",
  //   id: "10004",
  //   label: "Acehúche (Cáceres)",
  // },
  // {
  //   municipio: "Elda",
  //   provincia: "Alicante",
  //   id: "03066",
  //   label: "Elda (Alicante)",
  // },
  // {
  //   municipio: "Fageca",
  //   provincia: "Alicante",
  //   id: "03067",
  //   label: "Fageca (Alicante)",
  // },
  // {
  //   municipio: "Gaianes",
  //   provincia: "Alicante",
  //   id: "03072",
  //   label: "Gaianes (Alicante)",
  // },
  // {
  //   municipio: "Formentera del Segura",
  //   provincia: "Alicante",
  //   id: "03070",
  //   label: "Formentera del Segura (Alicante)",
  // },
  // {
  //   municipio: "Ibi",
  //   provincia: "Alicante",
  //   id: "03079",
  //   label: "Ibi (Alicante)",
  // },
  // {
  //   municipio: "Gorga",
  //   provincia: "Alicante",
  //   id: "03073",
  //   label: "Gorga (Alicante)",
  // },
  // {
  //   municipio: "Gata de Gorgos",
  //   provincia: "Alicante",
  //   id: "03071",
  //   label: "Gata de Gorgos (Alicante)",
  // },
  // {
  //   municipio: "Granja de Rocamora",
  //   provincia: "Alicante",
  //   id: "03074",
  //   label: "Granja de Rocamora (Alicante)",
  // },
  // {
  //   municipio: "Fondó de les Neus, el/Hondón de las Nieves",
  //   provincia: "Alicante",
  //   id: "03077",
  //   label: "Fondó de les Neus, el/Hondón de las Nieves (Alicante)",
  // },
  // {
  //   municipio: "Zugarramurdi",
  //   provincia: "Navarra",
  //   id: "31264",
  //   label: "Zugarramurdi (Navarra)",
  // },
  // {
  //   municipio: "Castell de Guadalest, el",
  //   provincia: "Alicante",
  //   id: "03075",
  //   label: "Castell de Guadalest, el (Alicante)",
  // },
  // {
  //   municipio: "Guardamar del Segura",
  //   provincia: "Alicante",
  //   id: "03076",
  //   label: "Guardamar del Segura (Alicante)",
  // },
  // {
  //   municipio: "Muñomer del Peco",
  //   provincia: "Ávila",
  //   id: "05140",
  //   label: "Muñomer del Peco (Ávila)",
  // },
  // {
  //   municipio: "Hondón de los Frailes",
  //   provincia: "Alicante",
  //   id: "03078",
  //   label: "Hondón de los Frailes (Alicante)",
  // },
  // {
  //   municipio: "Sax",
  //   provincia: "Alicante",
  //   id: "03123",
  //   label: "Sax (Alicante)",
  // },
  // {
  //   municipio: "Jacarilla",
  //   provincia: "Alicante",
  //   id: "03080",
  //   label: "Jacarilla (Alicante)",
  // },
  // {
  //   municipio: "Monòver/Monóvar",
  //   provincia: "Alicante",
  //   id: "03089",
  //   label: "Monòver/Monóvar (Alicante)",
  // },
  // {
  //   municipio: "Montesinos, Los",
  //   provincia: "Alicante",
  //   id: "03903",
  //   label: "Montesinos, Los (Alicante)",
  // },
  // {
  //   municipio: "Llíber",
  //   provincia: "Alicante",
  //   id: "03085",
  //   label: "Llíber (Alicante)",
  // },
  // {
  //   municipio: "Xaló",
  //   provincia: "Alicante",
  //   id: "03081",
  //   label: "Xaló (Alicante)",
  // },
  // {
  //   municipio: "Xixona/Jijona",
  //   provincia: "Alicante",
  //   id: "03083",
  //   label: "Xixona/Jijona (Alicante)",
  // },
  // {
  //   municipio: "Orxa, l'/Lorcha",
  //   provincia: "Alicante",
  //   id: "03084",
  //   label: "Orxa, l'/Lorcha (Alicante)",
  // },
  // {
  //   municipio: "Millena",
  //   provincia: "Alicante",
  //   id: "03086",
  //   label: "Millena (Alicante)",
  // },
  // {
  //   municipio: "Monforte del Cid",
  //   provincia: "Alicante",
  //   id: "03088",
  //   label: "Monforte del Cid (Alicante)",
  // },
  // {
  //   municipio: "Muro de Alcoy",
  //   provincia: "Alicante",
  //   id: "03092",
  //   label: "Muro de Alcoy (Alicante)",
  // },
  // {
  //   municipio: "Polop",
  //   provincia: "Alicante",
  //   id: "03107",
  //   label: "Polop (Alicante)",
  // },
  // {
  //   municipio: "Mutxamel",
  //   provincia: "Alicante",
  //   id: "03090",
  //   label: "Mutxamel (Alicante)",
  // },
  // {
  //   municipio: "Murla",
  //   provincia: "Alicante",
  //   id: "03091",
  //   label: "Murla (Alicante)",
  // },
  // {
  //   municipio: "Novelda",
  //   provincia: "Alicante",
  //   id: "03093",
  //   label: "Novelda (Alicante)",
  // },
  // {
  //   municipio: "Onil",
  //   provincia: "Alicante",
  //   id: "03096",
  //   label: "Onil (Alicante)",
  // },
  // {
  //   municipio: "Nucia, la",
  //   provincia: "Alicante",
  //   id: "03094",
  //   label: "Nucia, la (Alicante)",
  // },
  // {
  //   municipio: "Ondara",
  //   provincia: "Alicante",
  //   id: "03095",
  //   label: "Ondara (Alicante)",
  // },
  // {
  //   municipio: "Ràfol d'Almúnia, el",
  //   provincia: "Alicante",
  //   id: "03110",
  //   label: "Ràfol d'Almúnia, el (Alicante)",
  // },
  // {
  //   municipio: "Quintanilla de Urz",
  //   provincia: "Zamora",
  //   id: "49170",
  //   label: "Quintanilla de Urz (Zamora)",
  // },
  // {
  //   municipio: "Orba",
  //   provincia: "Alicante",
  //   id: "03097",
  //   label: "Orba (Alicante)",
  // },
  // {
  //   municipio: "Orxeta",
  //   provincia: "Alicante",
  //   id: "03098",
  //   label: "Orxeta (Alicante)",
  // },
  // {
  //   municipio: "Parcent",
  //   provincia: "Alicante",
  //   id: "03100",
  //   label: "Parcent (Alicante)",
  // },
  // {
  //   municipio: "Pinarejos",
  //   provincia: "Segovia",
  //   id: "40159",
  //   label: "Pinarejos (Segovia)",
  // },
  // {
  //   municipio: "Redován",
  //   provincia: "Alicante",
  //   id: "03111",
  //   label: "Redován (Alicante)",
  // },
  // {
  //   municipio: "Pedreguer",
  //   provincia: "Alicante",
  //   id: "03101",
  //   label: "Pedreguer (Alicante)",
  // },
  // {
  //   municipio: "Rojales",
  //   provincia: "Alicante",
  //   id: "03113",
  //   label: "Rojales (Alicante)",
  // },
  // {
  //   municipio: "Pego",
  //   provincia: "Alicante",
  //   id: "03102",
  //   label: "Pego (Alicante)",
  // },
  // {
  //   municipio: "Romana, la",
  //   provincia: "Alicante",
  //   id: "03114",
  //   label: "Romana, la (Alicante)",
  // },
  // {
  //   municipio: "Rafal",
  //   provincia: "Alicante",
  //   id: "03109",
  //   label: "Rafal (Alicante)",
  // },
  // {
  //   municipio: "Sagra",
  //   provincia: "Alicante",
  //   id: "03115",
  //   label: "Sagra (Alicante)",
  // },
  // {
  //   municipio: "Salinas",
  //   provincia: "Alicante",
  //   id: "03116",
  //   label: "Salinas (Alicante)",
  // },
  // {
  //   municipio: "Vall d'Alcalà, la",
  //   provincia: "Alicante",
  //   id: "03134",
  //   label: "Vall d'Alcalà, la (Alicante)",
  // },
  // {
  //   municipio: "Verger, el",
  //   provincia: "Alicante",
  //   id: "03138",
  //   label: "Verger, el (Alicante)",
  // },
  // {
  //   municipio: "Relleu",
  //   provincia: "Alicante",
  //   id: "03112",
  //   label: "Relleu (Alicante)",
  // },
  // {
  //   municipio: "San Fulgencio",
  //   provincia: "Alicante",
  //   id: "03118",
  //   label: "San Fulgencio (Alicante)",
  // },
  // {
  //   municipio: "Sant Salvador de Guardiola",
  //   provincia: "Barcelona",
  //   id: "08098",
  //   label: "Sant Salvador de Guardiola (Barcelona)",
  // },
  // {
  //   municipio: "Llacuna, La",
  //   provincia: "Barcelona",
  //   id: "08104",
  //   label: "Llacuna, La (Barcelona)",
  // },
  // {
  //   municipio: "Vall d'Ebo, la",
  //   provincia: "Alicante",
  //   id: "03135",
  //   label: "Vall d'Ebo, la (Alicante)",
  // },
  // {
  //   municipio: "Sanet y Negrals",
  //   provincia: "Alicante",
  //   id: "03117",
  //   label: "Sanet y Negrals (Alicante)",
  // },
  // {
  //   municipio: "Tibi",
  //   provincia: "Alicante",
  //   id: "03129",
  //   label: "Tibi (Alicante)",
  // },
  // {
  //   municipio: "Sella",
  //   provincia: "Alicante",
  //   id: "03124",
  //   label: "Sella (Alicante)",
  // },
  // {
  //   municipio: "Senija",
  //   provincia: "Alicante",
  //   id: "03125",
  //   label: "Senija (Alicante)",
  // },
  // {
  //   municipio: "Tàrbena",
  //   provincia: "Alicante",
  //   id: "03127",
  //   label: "Tàrbena (Alicante)",
  // },
  // {
  //   municipio: "Tormos",
  //   provincia: "Alicante",
  //   id: "03131",
  //   label: "Tormos (Alicante)",
  // },
  // {
  //   municipio: "Tollos",
  //   provincia: "Alicante",
  //   id: "03130",
  //   label: "Tollos (Alicante)",
  // },
  // {
  //   municipio: "Torre de les Maçanes, la/Torremanzanas",
  //   provincia: "Alicante",
  //   id: "03132",
  //   label: "Torre de les Maçanes, la/Torremanzanas (Alicante)",
  // },
  // {
  //   municipio: "Vall de Gallinera, la",
  //   provincia: "Alicante",
  //   id: "03136",
  //   label: "Vall de Gallinera, la (Alicante)",
  // },
  // {
  //   municipio: "Vall de Laguar, la",
  //   provincia: "Alicante",
  //   id: "03137",
  //   label: "Vall de Laguar, la (Alicante)",
  // },
  // {
  //   municipio: "Pilar de la Horadada",
  //   provincia: "Alicante",
  //   id: "03902",
  //   label: "Pilar de la Horadada (Alicante)",
  // },
  // {
  //   municipio: "Villena",
  //   provincia: "Alicante",
  //   id: "03140",
  //   label: "Villena (Alicante)",
  // },
  // {
  //   municipio: "Poblets, els",
  //   provincia: "Alicante",
  //   id: "03901",
  //   label: "Poblets, els (Alicante)",
  // },
  // {
  //   municipio: "Alhabia",
  //   provincia: "Almería",
  //   id: "04010",
  //   label: "Alhabia (Almería)",
  // },
  // {
  //   municipio: "Alhama de Almería",
  //   provincia: "Almería",
  //   id: "04011",
  //   label: "Alhama de Almería (Almería)",
  // },
  // {
  //   municipio: "Abla",
  //   provincia: "Almería",
  //   id: "04001",
  //   label: "Abla (Almería)",
  // },
  // {
  //   municipio: "Albanchez",
  //   provincia: "Almería",
  //   id: "04004",
  //   label: "Albanchez (Almería)",
  // },
  // {
  //   municipio: "Alboloduy",
  //   provincia: "Almería",
  //   id: "04005",
  //   label: "Alboloduy (Almería)",
  // },
  // {
  //   municipio: "Alcolea",
  //   provincia: "Almería",
  //   id: "04007",
  //   label: "Alcolea (Almería)",
  // },
  // {
  //   municipio: "Alcóntar",
  //   provincia: "Almería",
  //   id: "04008",
  //   label: "Alcóntar (Almería)",
  // },
  // {
  //   municipio: "Benitagla",
  //   provincia: "Almería",
  //   id: "04026",
  //   label: "Benitagla (Almería)",
  // },
  // {
  //   municipio: "Alicún",
  //   provincia: "Almería",
  //   id: "04012",
  //   label: "Alicún (Almería)",
  // },
  // {
  //   municipio: "Espinosa de los Caballeros",
  //   provincia: "Ávila",
  //   id: "05072",
  //   label: "Espinosa de los Caballeros (Ávila)",
  // },
  // {
  //   municipio: "Bédar",
  //   provincia: "Almería",
  //   id: "04022",
  //   label: "Bédar (Almería)",
  // },
  // {
  //   municipio: "Almócita",
  //   provincia: "Almería",
  //   id: "04014",
  //   label: "Almócita (Almería)",
  // },
  // {
  //   municipio: "Alsodux",
  //   provincia: "Almería",
  //   id: "04015",
  //   label: "Alsodux (Almería)",
  // },
  // {
  //   municipio: "Antas",
  //   provincia: "Almería",
  //   id: "04016",
  //   label: "Antas (Almería)",
  // },
  // {
  //   municipio: "Armuña de Almanzora",
  //   provincia: "Almería",
  //   id: "04018",
  //   label: "Armuña de Almanzora (Almería)",
  // },
  // {
  //   municipio: "Bacares",
  //   provincia: "Almería",
  //   id: "04019",
  //   label: "Bacares (Almería)",
  // },
  // {
  //   municipio: "Fuente el Saúz",
  //   provincia: "Ávila",
  //   id: "05077",
  //   label: "Fuente el Saúz (Ávila)",
  // },
  // {
  //   municipio: "Bayárcal",
  //   provincia: "Almería",
  //   id: "04020",
  //   label: "Bayárcal (Almería)",
  // },
  // {
  //   municipio: "Albornos",
  //   provincia: "Ávila",
  //   id: "05005",
  //   label: "Albornos (Ávila)",
  // },
  // {
  //   municipio: "Berja",
  //   provincia: "Almería",
  //   id: "04029",
  //   label: "Berja (Almería)",
  // },
  // {
  //   municipio: "Instinción",
  //   provincia: "Almería",
  //   id: "04055",
  //   label: "Instinción (Almería)",
  // },
  // {
  //   municipio: "Beires",
  //   provincia: "Almería",
  //   id: "04023",
  //   label: "Beires (Almería)",
  // },
  // {
  //   municipio: "Benahadux",
  //   provincia: "Almería",
  //   id: "04024",
  //   label: "Benahadux (Almería)",
  // },
  // {
  //   municipio: "Benizalón",
  //   provincia: "Almería",
  //   id: "04027",
  //   label: "Benizalón (Almería)",
  // },
  // {
  //   municipio: "Aldeanueva de Santa Cruz",
  //   provincia: "Ávila",
  //   id: "05007",
  //   label: "Aldeanueva de Santa Cruz (Ávila)",
  // },
  // {
  //   municipio: "Bentarique",
  //   provincia: "Almería",
  //   id: "04028",
  //   label: "Bentarique (Almería)",
  // },
  // {
  //   municipio: "Aldeaseca",
  //   provincia: "Ávila",
  //   id: "05008",
  //   label: "Aldeaseca (Ávila)",
  // },
  // {
  //   municipio: "Canjáyar",
  //   provincia: "Almería",
  //   id: "04030",
  //   label: "Canjáyar (Almería)",
  // },
  // {
  //   municipio: "Cantoria",
  //   provincia: "Almería",
  //   id: "04031",
  //   label: "Cantoria (Almería)",
  // },
  // {
  //   municipio: "Castro de Filabres",
  //   provincia: "Almería",
  //   id: "04033",
  //   label: "Castro de Filabres (Almería)",
  // },
  // {
  //   municipio: "Chercos",
  //   provincia: "Almería",
  //   id: "04036",
  //   label: "Chercos (Almería)",
  // },
  // {
  //   municipio: "Chirivel",
  //   provincia: "Almería",
  //   id: "04037",
  //   label: "Chirivel (Almería)",
  // },
  // {
  //   municipio: "Enix",
  //   provincia: "Almería",
  //   id: "04041",
  //   label: "Enix (Almería)",
  // },
  // {
  //   municipio: "Pechina",
  //   provincia: "Almería",
  //   id: "04074",
  //   label: "Pechina (Almería)",
  // },
  // {
  //   municipio: "Felix",
  //   provincia: "Almería",
  //   id: "04043",
  //   label: "Felix (Almería)",
  // },
  // {
  //   municipio: "Íllar",
  //   provincia: "Almería",
  //   id: "04054",
  //   label: "Íllar (Almería)",
  // },
  // {
  //   municipio: "Fines",
  //   provincia: "Almería",
  //   id: "04044",
  //   label: "Fines (Almería)",
  // },
  // {
  //   municipio: "Fiñana",
  //   provincia: "Almería",
  //   id: "04045",
  //   label: "Fiñana (Almería)",
  // },
  // {
  //   municipio: "Fondón",
  //   provincia: "Almería",
  //   id: "04046",
  //   label: "Fondón (Almería)",
  // },
  // {
  //   municipio: "Gádor",
  //   provincia: "Almería",
  //   id: "04047",
  //   label: "Gádor (Almería)",
  // },
  // {
  //   municipio: "Pulpí",
  //   provincia: "Almería",
  //   id: "04075",
  //   label: "Pulpí (Almería)",
  // },
  // {
  //   municipio: "Purchena",
  //   provincia: "Almería",
  //   id: "04076",
  //   label: "Purchena (Almería)",
  // },
  // {
  //   municipio: "Gallardos, Los",
  //   provincia: "Almería",
  //   id: "04048",
  //   label: "Gallardos, Los (Almería)",
  // },
  // {
  //   municipio: "Garrucha",
  //   provincia: "Almería",
  //   id: "04049",
  //   label: "Garrucha (Almería)",
  // },
  // {
  //   municipio: "Gérgal",
  //   provincia: "Almería",
  //   id: "04050",
  //   label: "Gérgal (Almería)",
  // },
  // {
  //   municipio: "Aldehuela, La",
  //   provincia: "Ávila",
  //   id: "05010",
  //   label: "Aldehuela, La (Ávila)",
  // },
  // {
  //   municipio: "Escobar de Campos",
  //   provincia: "León",
  //   id: "24069",
  //   label: "Escobar de Campos (León)",
  // },
  // {
  //   municipio: "Huécija",
  //   provincia: "Almería",
  //   id: "04051",
  //   label: "Huécija (Almería)",
  // },
  // {
  //   municipio: "Huércal de Almería",
  //   provincia: "Almería",
  //   id: "04052",
  //   label: "Huércal de Almería (Almería)",
  // },
  // {
  //   municipio: "Fuentes de Año",
  //   provincia: "Ávila",
  //   id: "05078",
  //   label: "Fuentes de Año (Ávila)",
  // },
  // {
  //   municipio: "Huércal-Overa",
  //   provincia: "Almería",
  //   id: "04053",
  //   label: "Huércal-Overa (Almería)",
  // },
  // {
  //   municipio: "Ohanes",
  //   provincia: "Almería",
  //   id: "04067",
  //   label: "Ohanes (Almería)",
  // },
  // {
  //   municipio: "Padules",
  //   provincia: "Almería",
  //   id: "04071",
  //   label: "Padules (Almería)",
  // },
  // {
  //   municipio: "Laroya",
  //   provincia: "Almería",
  //   id: "04056",
  //   label: "Laroya (Almería)",
  // },
  // {
  //   municipio: "Láujar de Andarax",
  //   provincia: "Almería",
  //   id: "04057",
  //   label: "Láujar de Andarax (Almería)",
  // },
  // {
  //   municipio: "Líjar",
  //   provincia: "Almería",
  //   id: "04058",
  //   label: "Líjar (Almería)",
  // },
  // {
  //   municipio: "Amavida",
  //   provincia: "Ávila",
  //   id: "05012",
  //   label: "Amavida (Ávila)",
  // },
  // {
  //   municipio: "Lucainena de las Torres",
  //   provincia: "Almería",
  //   id: "04060",
  //   label: "Lucainena de las Torres (Almería)",
  // },
  // {
  //   municipio: "Arenal, El",
  //   provincia: "Ávila",
  //   id: "05013",
  //   label: "Arenal, El (Ávila)",
  // },
  // {
  //   municipio: "Macael",
  //   provincia: "Almería",
  //   id: "04062",
  //   label: "Macael (Almería)",
  // },
  // {
  //   municipio: "María",
  //   provincia: "Almería",
  //   id: "04063",
  //   label: "María (Almería)",
  // },
  // {
  //   municipio: "Mojácar",
  //   provincia: "Almería",
  //   id: "04064",
  //   label: "Mojácar (Almería)",
  // },
  // {
  //   municipio: "Arenas de San Pedro",
  //   provincia: "Ávila",
  //   id: "05014",
  //   label: "Arenas de San Pedro (Ávila)",
  // },
  // {
  //   municipio: "Nacimiento",
  //   provincia: "Almería",
  //   id: "04065",
  //   label: "Nacimiento (Almería)",
  // },
  // {
  //   municipio: "Olula de Castro",
  //   provincia: "Almería",
  //   id: "04068",
  //   label: "Olula de Castro (Almería)",
  // },
  // {
  //   municipio: "Rágol",
  //   provincia: "Almería",
  //   id: "04077",
  //   label: "Rágol (Almería)",
  // },
  // {
  //   municipio: "Olula del Río",
  //   provincia: "Almería",
  //   id: "04069",
  //   label: "Olula del Río (Almería)",
  // },
  // {
  //   municipio: "Oria",
  //   provincia: "Almería",
  //   id: "04070",
  //   label: "Oria (Almería)",
  // },
  // {
  //   municipio: "Somontín",
  //   provincia: "Almería",
  //   id: "04085",
  //   label: "Somontín (Almería)",
  // },
  // {
  //   municipio: "Sorbas",
  //   provincia: "Almería",
  //   id: "04086",
  //   label: "Sorbas (Almería)",
  // },
  // {
  //   municipio: "Paterna del Río",
  //   provincia: "Almería",
  //   id: "04073",
  //   label: "Paterna del Río (Almería)",
  // },
  // {
  //   municipio: "Rioja",
  //   provincia: "Almería",
  //   id: "04078",
  //   label: "Rioja (Almería)",
  // },
  // {
  //   municipio: "Partaloa",
  //   provincia: "Almería",
  //   id: "04072",
  //   label: "Partaloa (Almería)",
  // },
  // {
  //   municipio: "Suflí",
  //   provincia: "Almería",
  //   id: "04087",
  //   label: "Suflí (Almería)",
  // },
  // {
  //   municipio: "Turrillas",
  //   provincia: "Almería",
  //   id: "04094",
  //   label: "Turrillas (Almería)",
  // },
  // {
  //   municipio: "Santa Cruz de Marchena",
  //   provincia: "Almería",
  //   id: "04080",
  //   label: "Santa Cruz de Marchena (Almería)",
  // },
  // {
  //   municipio: "Tres Villas, Las",
  //   provincia: "Almería",
  //   id: "04901",
  //   label: "Tres Villas, Las (Almería)",
  // },
  // {
  //   municipio: "Mojonera, La",
  //   provincia: "Almería",
  //   id: "04903",
  //   label: "Mojonera, La (Almería)",
  // },
  // {
  //   municipio: "Santa Fe de Mondújar",
  //   provincia: "Almería",
  //   id: "04081",
  //   label: "Santa Fe de Mondújar (Almería)",
  // },
  // {
  //   municipio: "Bercial de Zapardiel",
  //   provincia: "Ávila",
  //   id: "05026",
  //   label: "Bercial de Zapardiel (Ávila)",
  // },
  // {
  //   municipio: "Senés",
  //   provincia: "Almería",
  //   id: "04082",
  //   label: "Senés (Almería)",
  // },
  // {
  //   municipio: "Sierro",
  //   provincia: "Almería",
  //   id: "04084",
  //   label: "Sierro (Almería)",
  // },
  // {
  //   municipio: "Tabernas",
  //   provincia: "Almería",
  //   id: "04088",
  //   label: "Tabernas (Almería)",
  // },
  // {
  //   municipio: "Taberno",
  //   provincia: "Almería",
  //   id: "04089",
  //   label: "Taberno (Almería)",
  // },
  // {
  //   municipio: "Adanero",
  //   provincia: "Ávila",
  //   id: "05001",
  //   label: "Adanero (Ávila)",
  // },
  // {
  //   municipio: "Terque",
  //   provincia: "Almería",
  //   id: "04091",
  //   label: "Terque (Almería)",
  // },
  // {
  //   municipio: "Turre",
  //   provincia: "Almería",
  //   id: "04093",
  //   label: "Turre (Almería)",
  // },
  // {
  //   municipio: "Uleila del Campo",
  //   provincia: "Almería",
  //   id: "04095",
  //   label: "Uleila del Campo (Almería)",
  // },
  // {
  //   municipio: "Quintana del Pidio",
  //   provincia: "Burgos",
  //   id: "09281",
  //   label: "Quintana del Pidio (Burgos)",
  // },
  // {
  //   municipio: "Berrocalejo de Aragona",
  //   provincia: "Ávila",
  //   id: "05030",
  //   label: "Berrocalejo de Aragona (Ávila)",
  // },
  // {
  //   municipio: "Urrácal",
  //   provincia: "Almería",
  //   id: "04096",
  //   label: "Urrácal (Almería)",
  // },
  // {
  //   municipio: "Adrada, La",
  //   provincia: "Ávila",
  //   id: "05002",
  //   label: "Adrada, La (Ávila)",
  // },
  // {
  //   municipio: "Velefique",
  //   provincia: "Almería",
  //   id: "04097",
  //   label: "Velefique (Almería)",
  // },
  // {
  //   municipio: "Vélez-Rubio",
  //   provincia: "Almería",
  //   id: "04099",
  //   label: "Vélez-Rubio (Almería)",
  // },
  // {
  //   municipio: "Vera",
  //   provincia: "Almería",
  //   id: "04100",
  //   label: "Vera (Almería)",
  // },
  // {
  //   municipio: "Viator",
  //   provincia: "Almería",
  //   id: "04101",
  //   label: "Viator (Almería)",
  // },
  // {
  //   municipio: "Vícar",
  //   provincia: "Almería",
  //   id: "04102",
  //   label: "Vícar (Almería)",
  // },
  // {
  //   municipio: "San Esteban de Nogales",
  //   provincia: "León",
  //   id: "24146",
  //   label: "San Esteban de Nogales (León)",
  // },
  // {
  //   municipio: "Zurgena",
  //   provincia: "Almería",
  //   id: "04103",
  //   label: "Zurgena (Almería)",
  // },
  // {
  //   municipio: "Blascomillán",
  //   provincia: "Ávila",
  //   id: "05033",
  //   label: "Blascomillán (Ávila)",
  // },
  // {
  //   municipio: "Acedera",
  //   provincia: "Badajoz",
  //   id: "06001",
  //   label: "Acedera (Badajoz)",
  // },
  // {
  //   municipio: "Becedillas",
  //   provincia: "Ávila",
  //   id: "05025",
  //   label: "Becedillas (Ávila)",
  // },
  // {
  //   municipio: "Arévalo",
  //   provincia: "Ávila",
  //   id: "05016",
  //   label: "Arévalo (Ávila)",
  // },
  // {
  //   municipio: "Aveinte",
  //   provincia: "Ávila",
  //   id: "05017",
  //   label: "Aveinte (Ávila)",
  // },
  // {
  //   municipio: "Barraco, El",
  //   provincia: "Ávila",
  //   id: "05022",
  //   label: "Barraco, El (Ávila)",
  // },
  // {
  //   municipio: "Barromán",
  //   provincia: "Ávila",
  //   id: "05023",
  //   label: "Barromán (Ávila)",
  // },
  // {
  //   municipio: "Becedas",
  //   provincia: "Ávila",
  //   id: "05024",
  //   label: "Becedas (Ávila)",
  // },
  // {
  //   municipio: "Berlanas, Las",
  //   provincia: "Ávila",
  //   id: "05027",
  //   label: "Berlanas, Las (Ávila)",
  // },
  // {
  //   municipio: "Junciana",
  //   provincia: "Ávila",
  //   id: "05108",
  //   label: "Junciana (Ávila)",
  // },
  // {
  //   municipio: "Manjabálago y Ortigosa de Rioalmar",
  //   provincia: "Ávila",
  //   id: "05119",
  //   label: "Manjabálago y Ortigosa de Rioalmar (Ávila)",
  // },
  // {
  //   municipio: "Bernuy-Zapardiel",
  //   provincia: "Ávila",
  //   id: "05029",
  //   label: "Bernuy-Zapardiel (Ávila)",
  // },
  // {
  //   municipio: "Blasconuño de Matacabras",
  //   provincia: "Ávila",
  //   id: "05034",
  //   label: "Blasconuño de Matacabras (Ávila)",
  // },
  // {
  //   municipio: "Blascosancho",
  //   provincia: "Ávila",
  //   id: "05035",
  //   label: "Blascosancho (Ávila)",
  // },
  // {
  //   municipio: "Bohodón, El",
  //   provincia: "Ávila",
  //   id: "05036",
  //   label: "Bohodón, El (Ávila)",
  // },
  // {
  //   municipio: "Bonilla de la Sierra",
  //   provincia: "Ávila",
  //   id: "05038",
  //   label: "Bonilla de la Sierra (Ávila)",
  // },
  // {
  //   municipio: "Bohoyo",
  //   provincia: "Ávila",
  //   id: "05037",
  //   label: "Bohoyo (Ávila)",
  // },
  // {
  //   municipio: "Cabezas del Pozo",
  //   provincia: "Ávila",
  //   id: "05043",
  //   label: "Cabezas del Pozo (Ávila)",
  // },
  // {
  //   municipio: "Brabos",
  //   provincia: "Ávila",
  //   id: "05039",
  //   label: "Brabos (Ávila)",
  // },
  // {
  //   municipio: "Cabizuela",
  //   provincia: "Ávila",
  //   id: "05045",
  //   label: "Cabizuela (Ávila)",
  // },
  // {
  //   municipio: "Langa",
  //   provincia: "Ávila",
  //   id: "05109",
  //   label: "Langa (Ávila)",
  // },
  // {
  //   municipio: "Bularros",
  //   provincia: "Ávila",
  //   id: "05040",
  //   label: "Bularros (Ávila)",
  // },
  // {
  //   municipio: "Vallarta de Bureba",
  //   provincia: "Burgos",
  //   id: "09408",
  //   label: "Vallarta de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Burgohondo",
  //   provincia: "Ávila",
  //   id: "05041",
  //   label: "Burgohondo (Ávila)",
  // },
  // {
  //   municipio: "Cabezas de Alambre",
  //   provincia: "Ávila",
  //   id: "05042",
  //   label: "Cabezas de Alambre (Ávila)",
  // },
  // {
  //   municipio: "Cabezas del Villar",
  //   provincia: "Ávila",
  //   id: "05044",
  //   label: "Cabezas del Villar (Ávila)",
  // },
  // {
  //   municipio: "Malpartida de Corneja",
  //   provincia: "Ávila",
  //   id: "05116",
  //   label: "Malpartida de Corneja (Ávila)",
  // },
  // {
  //   municipio: "Collado de Contreras",
  //   provincia: "Ávila",
  //   id: "05062",
  //   label: "Collado de Contreras (Ávila)",
  // },
  // {
  //   municipio: "Cantiveros",
  //   provincia: "Ávila",
  //   id: "05048",
  //   label: "Cantiveros (Ávila)",
  // },
  // {
  //   municipio: "Gallegos de Altamiros",
  //   provincia: "Ávila",
  //   id: "05079",
  //   label: "Gallegos de Altamiros (Ávila)",
  // },
  // {
  //   municipio: "Aceuchal",
  //   provincia: "Badajoz",
  //   id: "06002",
  //   label: "Aceuchal (Badajoz)",
  // },
  // {
  //   municipio: "Canales",
  //   provincia: "Ávila",
  //   id: "05046",
  //   label: "Canales (Ávila)",
  // },
  // {
  //   municipio: "Candeleda",
  //   provincia: "Ávila",
  //   id: "05047",
  //   label: "Candeleda (Ávila)",
  // },
  // {
  //   municipio: "Cardeñosa",
  //   provincia: "Ávila",
  //   id: "05049",
  //   label: "Cardeñosa (Ávila)",
  // },
  // {
  //   municipio: "Carrera, La",
  //   provincia: "Ávila",
  //   id: "05051",
  //   label: "Carrera, La (Ávila)",
  // },
  // {
  //   municipio: "Casas del Puerto",
  //   provincia: "Ávila",
  //   id: "05052",
  //   label: "Casas del Puerto (Ávila)",
  // },
  // {
  //   municipio: "Casasola",
  //   provincia: "Ávila",
  //   id: "05053",
  //   label: "Casasola (Ávila)",
  // },
  // {
  //   municipio: "Casavieja",
  //   provincia: "Ávila",
  //   id: "05054",
  //   label: "Casavieja (Ávila)",
  // },
  // {
  //   municipio: "Casillas",
  //   provincia: "Ávila",
  //   id: "05055",
  //   label: "Casillas (Ávila)",
  // },
  // {
  //   municipio: "Castellanos de Zapardiel",
  //   provincia: "Ávila",
  //   id: "05056",
  //   label: "Castellanos de Zapardiel (Ávila)",
  // },
  // {
  //   municipio: "Hospital de Órbigo",
  //   provincia: "León",
  //   id: "24082",
  //   label: "Hospital de Órbigo (León)",
  // },
  // {
  //   municipio: "Cepeda la Mora",
  //   provincia: "Ávila",
  //   id: "05058",
  //   label: "Cepeda la Mora (Ávila)",
  // },
  // {
  //   municipio: "Chamartín",
  //   provincia: "Ávila",
  //   id: "05067",
  //   label: "Chamartín (Ávila)",
  // },
  // {
  //   municipio: "Cillán",
  //   provincia: "Ávila",
  //   id: "05059",
  //   label: "Cillán (Ávila)",
  // },
  // {
  //   municipio: "Cisla",
  //   provincia: "Ávila",
  //   id: "05060",
  //   label: "Cisla (Ávila)",
  // },
  // {
  //   municipio: "Donjimeno",
  //   provincia: "Ávila",
  //   id: "05069",
  //   label: "Donjimeno (Ávila)",
  // },
  // {
  //   municipio: "Colilla, La",
  //   provincia: "Ávila",
  //   id: "05061",
  //   label: "Colilla, La (Ávila)",
  // },
  // {
  //   municipio: "Collado del Mirón",
  //   provincia: "Ávila",
  //   id: "05063",
  //   label: "Collado del Mirón (Ávila)",
  // },
  // {
  //   municipio: "Constanzana",
  //   provincia: "Ávila",
  //   id: "05064",
  //   label: "Constanzana (Ávila)",
  // },
  // {
  //   municipio: "Mesegar de Corneja",
  //   provincia: "Ávila",
  //   id: "05126",
  //   label: "Mesegar de Corneja (Ávila)",
  // },
  // {
  //   municipio: "Crespos",
  //   provincia: "Ávila",
  //   id: "05065",
  //   label: "Crespos (Ávila)",
  // },
  // {
  //   municipio: "Cuevas del Valle",
  //   provincia: "Ávila",
  //   id: "05066",
  //   label: "Cuevas del Valle (Ávila)",
  // },
  // {
  //   municipio: "Donvidas",
  //   provincia: "Ávila",
  //   id: "05070",
  //   label: "Donvidas (Ávila)",
  // },
  // {
  //   municipio: "Mijares",
  //   provincia: "Ávila",
  //   id: "05127",
  //   label: "Mijares (Ávila)",
  // },
  // {
  //   municipio: "Flores de Ávila",
  //   provincia: "Ávila",
  //   id: "05073",
  //   label: "Flores de Ávila (Ávila)",
  // },
  // {
  //   municipio: "Fontiveros",
  //   provincia: "Ávila",
  //   id: "05074",
  //   label: "Fontiveros (Ávila)",
  // },
  // {
  //   municipio: "Gilbuena",
  //   provincia: "Ávila",
  //   id: "05084",
  //   label: "Gilbuena (Ávila)",
  // },
  // {
  //   municipio: "Fresnedilla",
  //   provincia: "Ávila",
  //   id: "05075",
  //   label: "Fresnedilla (Ávila)",
  // },
  // {
  //   municipio: "Fresno, El",
  //   provincia: "Ávila",
  //   id: "05076",
  //   label: "Fresno, El (Ávila)",
  // },
  // {
  //   municipio: "Muñana",
  //   provincia: "Ávila",
  //   id: "05135",
  //   label: "Muñana (Ávila)",
  // },
  // {
  //   municipio: "Muñico",
  //   provincia: "Ávila",
  //   id: "05136",
  //   label: "Muñico (Ávila)",
  // },
  // {
  //   municipio: "Poyales del Hoyo",
  //   provincia: "Ávila",
  //   id: "05189",
  //   label: "Poyales del Hoyo (Ávila)",
  // },
  // {
  //   municipio: "Gallegos de Sobrinos",
  //   provincia: "Ávila",
  //   id: "05080",
  //   label: "Gallegos de Sobrinos (Ávila)",
  // },
  // {
  //   municipio: "Garganta del Villar",
  //   provincia: "Ávila",
  //   id: "05081",
  //   label: "Garganta del Villar (Ávila)",
  // },
  // {
  //   municipio: "Muñogrande",
  //   provincia: "Ávila",
  //   id: "05139",
  //   label: "Muñogrande (Ávila)",
  // },
  // {
  //   municipio: "Gavilanes",
  //   provincia: "Ávila",
  //   id: "05082",
  //   label: "Gavilanes (Ávila)",
  // },
  // {
  //   municipio: "Gemuño",
  //   provincia: "Ávila",
  //   id: "05083",
  //   label: "Gemuño (Ávila)",
  // },
  // {
  //   municipio: "Muñogalindo",
  //   provincia: "Ávila",
  //   id: "05138",
  //   label: "Muñogalindo (Ávila)",
  // },
  // {
  //   municipio: "Gil García",
  //   provincia: "Ávila",
  //   id: "05085",
  //   label: "Gil García (Ávila)",
  // },
  // {
  //   municipio: "Gimialcón",
  //   provincia: "Ávila",
  //   id: "05086",
  //   label: "Gimialcón (Ávila)",
  // },
  // {
  //   municipio: "Gotarrendura",
  //   provincia: "Ávila",
  //   id: "05087",
  //   label: "Gotarrendura (Ávila)",
  // },
  // {
  //   municipio: "Grandes y San Martín",
  //   provincia: "Ávila",
  //   id: "05088",
  //   label: "Grandes y San Martín (Ávila)",
  // },
  // {
  //   municipio: "Guisando",
  //   provincia: "Ávila",
  //   id: "05089",
  //   label: "Guisando (Ávila)",
  // },
  // {
  //   municipio: "Gutierre-Muñoz",
  //   provincia: "Ávila",
  //   id: "05090",
  //   label: "Gutierre-Muñoz (Ávila)",
  // },
  // {
  //   municipio: "Herreros de Suso",
  //   provincia: "Ávila",
  //   id: "05094",
  //   label: "Herreros de Suso (Ávila)",
  // },
  // {
  //   municipio: "Hernansancho",
  //   provincia: "Ávila",
  //   id: "05092",
  //   label: "Hernansancho (Ávila)",
  // },
  // {
  //   municipio: "Herradón de Pinares",
  //   provincia: "Ávila",
  //   id: "05093",
  //   label: "Herradón de Pinares (Ávila)",
  // },
  // {
  //   municipio: "Hoyorredondo",
  //   provincia: "Ávila",
  //   id: "05103",
  //   label: "Hoyorredondo (Ávila)",
  // },
  // {
  //   municipio: "Higuera de las Dueñas",
  //   provincia: "Ávila",
  //   id: "05095",
  //   label: "Higuera de las Dueñas (Ávila)",
  // },
  // {
  //   municipio: "Hija de Dios, La",
  //   provincia: "Ávila",
  //   id: "05096",
  //   label: "Hija de Dios, La (Ávila)",
  // },
  // {
  //   municipio: "Horcajada, La",
  //   provincia: "Ávila",
  //   id: "05097",
  //   label: "Horcajada, La (Ávila)",
  // },
  // {
  //   municipio: "Horcajo de las Torres",
  //   provincia: "Ávila",
  //   id: "05099",
  //   label: "Horcajo de las Torres (Ávila)",
  // },
  // {
  //   municipio: "Hornillo, El",
  //   provincia: "Ávila",
  //   id: "05100",
  //   label: "Hornillo, El (Ávila)",
  // },
  // {
  //   municipio: "Hoyocasero",
  //   provincia: "Ávila",
  //   id: "05101",
  //   label: "Hoyocasero (Ávila)",
  // },
  // {
  //   municipio: "Rasueros",
  //   provincia: "Ávila",
  //   id: "05193",
  //   label: "Rasueros (Ávila)",
  // },
  // {
  //   municipio: "Riocabado",
  //   provincia: "Ávila",
  //   id: "05194",
  //   label: "Riocabado (Ávila)",
  // },
  // {
  //   municipio: "Hoyos del Espino",
  //   provincia: "Ávila",
  //   id: "05105",
  //   label: "Hoyos del Espino (Ávila)",
  // },
  // {
  //   municipio: "Hoyos de Miguel Muñoz",
  //   provincia: "Ávila",
  //   id: "05106",
  //   label: "Hoyos de Miguel Muñoz (Ávila)",
  // },
  // {
  //   municipio: "Hurtumpascual",
  //   provincia: "Ávila",
  //   id: "05107",
  //   label: "Hurtumpascual (Ávila)",
  // },
  // {
  //   municipio: "Lanzahíta",
  //   provincia: "Ávila",
  //   id: "05110",
  //   label: "Lanzahíta (Ávila)",
  // },
  // {
  //   municipio: "Losar del Barco, El",
  //   provincia: "Ávila",
  //   id: "05112",
  //   label: "Losar del Barco, El (Ávila)",
  // },
  // {
  //   municipio: "Llanos de Tormes, Los",
  //   provincia: "Ávila",
  //   id: "05113",
  //   label: "Llanos de Tormes, Los (Ávila)",
  // },
  // {
  //   municipio: "Madrigal de las Altas Torres",
  //   provincia: "Ávila",
  //   id: "05114",
  //   label: "Madrigal de las Altas Torres (Ávila)",
  // },
  // {
  //   municipio: "Mamblas",
  //   provincia: "Ávila",
  //   id: "05117",
  //   label: "Mamblas (Ávila)",
  // },
  // {
  //   municipio: "Mancera de Arriba",
  //   provincia: "Ávila",
  //   id: "05118",
  //   label: "Mancera de Arriba (Ávila)",
  // },
  // {
  //   municipio: "Maello",
  //   provincia: "Ávila",
  //   id: "05115",
  //   label: "Maello (Ávila)",
  // },
  // {
  //   municipio: "Martiherrero",
  //   provincia: "Ávila",
  //   id: "05121",
  //   label: "Martiherrero (Ávila)",
  // },
  // {
  //   municipio: "Martínez",
  //   provincia: "Ávila",
  //   id: "05122",
  //   label: "Martínez (Ávila)",
  // },
  // {
  //   municipio: "Mediana de Voltoya",
  //   provincia: "Ávila",
  //   id: "05123",
  //   label: "Mediana de Voltoya (Ávila)",
  // },
  // {
  //   municipio: "Medinilla",
  //   provincia: "Ávila",
  //   id: "05124",
  //   label: "Medinilla (Ávila)",
  // },
  // {
  //   municipio: "Cirueña",
  //   provincia: "La Rioja",
  //   id: "26050",
  //   label: "Cirueña (La Rioja)",
  // },
  // {
  //   municipio: "Riofrío",
  //   provincia: "Ávila",
  //   id: "05195",
  //   label: "Riofrío (Ávila)",
  // },
  // {
  //   municipio: "Mironcillo",
  //   provincia: "Ávila",
  //   id: "05130",
  //   label: "Mironcillo (Ávila)",
  // },
  // {
  //   municipio: "Rivilla de Barajas",
  //   provincia: "Ávila",
  //   id: "05196",
  //   label: "Rivilla de Barajas (Ávila)",
  // },
  // {
  //   municipio: "Salobral",
  //   provincia: "Ávila",
  //   id: "05197",
  //   label: "Salobral (Ávila)",
  // },
  // {
  //   municipio: "Mengamuñoz",
  //   provincia: "Ávila",
  //   id: "05125",
  //   label: "Mengamuñoz (Ávila)",
  // },
  // {
  //   municipio: "Mingorría",
  //   provincia: "Ávila",
  //   id: "05128",
  //   label: "Mingorría (Ávila)",
  // },
  // {
  //   municipio: "Mirón, El",
  //   provincia: "Ávila",
  //   id: "05129",
  //   label: "Mirón, El (Ávila)",
  // },
  // {
  //   municipio: "Mirueña de los Infanzones",
  //   provincia: "Ávila",
  //   id: "05131",
  //   label: "Mirueña de los Infanzones (Ávila)",
  // },
  // {
  //   municipio: "Monsalupe",
  //   provincia: "Ávila",
  //   id: "05133",
  //   label: "Monsalupe (Ávila)",
  // },
  // {
  //   municipio: "Mombeltrán",
  //   provincia: "Ávila",
  //   id: "05132",
  //   label: "Mombeltrán (Ávila)",
  // },
  // {
  //   municipio: "Muñopepe",
  //   provincia: "Ávila",
  //   id: "05141",
  //   label: "Muñopepe (Ávila)",
  // },
  // {
  //   municipio: "Muñosancho",
  //   provincia: "Ávila",
  //   id: "05142",
  //   label: "Muñosancho (Ávila)",
  // },
  // {
  //   municipio: "Muñotello",
  //   provincia: "Ávila",
  //   id: "05143",
  //   label: "Muñotello (Ávila)",
  // },
  // {
  //   municipio: "Narrillos del Álamo",
  //   provincia: "Ávila",
  //   id: "05144",
  //   label: "Narrillos del Álamo (Ávila)",
  // },
  // {
  //   municipio: "Narrillos del Rebollar",
  //   provincia: "Ávila",
  //   id: "05145",
  //   label: "Narrillos del Rebollar (Ávila)",
  // },
  // {
  //   municipio: "Navacepedilla de Corneja",
  //   provincia: "Ávila",
  //   id: "05151",
  //   label: "Navacepedilla de Corneja (Ávila)",
  // },
  // {
  //   municipio: "Narros del Castillo",
  //   provincia: "Ávila",
  //   id: "05147",
  //   label: "Narros del Castillo (Ávila)",
  // },
  // {
  //   municipio: "Narros del Puerto",
  //   provincia: "Ávila",
  //   id: "05148",
  //   label: "Narros del Puerto (Ávila)",
  // },
  // {
  //   municipio: "Narros de Saldueña",
  //   provincia: "Ávila",
  //   id: "05149",
  //   label: "Narros de Saldueña (Ávila)",
  // },
  // {
  //   municipio: "Navalacruz",
  //   provincia: "Ávila",
  //   id: "05157",
  //   label: "Navalacruz (Ávila)",
  // },
  // {
  //   municipio: "Nava de Arévalo",
  //   provincia: "Ávila",
  //   id: "05152",
  //   label: "Nava de Arévalo (Ávila)",
  // },
  // {
  //   municipio: "Nava del Barco",
  //   provincia: "Ávila",
  //   id: "05153",
  //   label: "Nava del Barco (Ávila)",
  // },
  // {
  //   municipio: "Navaquesera",
  //   provincia: "Ávila",
  //   id: "05164",
  //   label: "Navaquesera (Ávila)",
  // },
  // {
  //   municipio: "Navadijos",
  //   provincia: "Ávila",
  //   id: "05154",
  //   label: "Navadijos (Ávila)",
  // },
  // {
  //   municipio: "Salvadiós",
  //   provincia: "Ávila",
  //   id: "05198",
  //   label: "Salvadiós (Ávila)",
  // },
  // {
  //   municipio: "Navaescurial",
  //   provincia: "Ávila",
  //   id: "05155",
  //   label: "Navaescurial (Ávila)",
  // },
  // {
  //   municipio: "Casalarreina",
  //   provincia: "La Rioja",
  //   id: "26042",
  //   label: "Casalarreina (La Rioja)",
  // },
  // {
  //   municipio: "Navahondilla",
  //   provincia: "Ávila",
  //   id: "05156",
  //   label: "Navahondilla (Ávila)",
  // },
  // {
  //   municipio: "Navalmoral",
  //   provincia: "Ávila",
  //   id: "05158",
  //   label: "Navalmoral (Ávila)",
  // },
  // {
  //   municipio: "Navalonguilla",
  //   provincia: "Ávila",
  //   id: "05159",
  //   label: "Navalonguilla (Ávila)",
  // },
  // {
  //   municipio: "Neila de San Miguel",
  //   provincia: "Ávila",
  //   id: "05171",
  //   label: "Neila de San Miguel (Ávila)",
  // },
  // {
  //   municipio: "Navalosa",
  //   provincia: "Ávila",
  //   id: "05160",
  //   label: "Navalosa (Ávila)",
  // },
  // {
  //   municipio: "Navalperal de Pinares",
  //   provincia: "Ávila",
  //   id: "05161",
  //   label: "Navalperal de Pinares (Ávila)",
  // },
  // {
  //   municipio: "Orbita",
  //   provincia: "Ávila",
  //   id: "05174",
  //   label: "Orbita (Ávila)",
  // },
  // {
  //   municipio: "Navalperal de Tormes",
  //   provincia: "Ávila",
  //   id: "05162",
  //   label: "Navalperal de Tormes (Ávila)",
  // },
  // {
  //   municipio: "Navaluenga",
  //   provincia: "Ávila",
  //   id: "05163",
  //   label: "Navaluenga (Ávila)",
  // },
  // {
  //   municipio: "Navarredonda de Gredos",
  //   provincia: "Ávila",
  //   id: "05165",
  //   label: "Navarredonda de Gredos (Ávila)",
  // },
  // {
  //   municipio: "Navarredondilla",
  //   provincia: "Ávila",
  //   id: "05166",
  //   label: "Navarredondilla (Ávila)",
  // },
  // {
  //   municipio: "Palacios de Goda",
  //   provincia: "Ávila",
  //   id: "05178",
  //   label: "Palacios de Goda (Ávila)",
  // },
  // {
  //   municipio: "Navarrevisca",
  //   provincia: "Ávila",
  //   id: "05167",
  //   label: "Navarrevisca (Ávila)",
  // },
  // {
  //   municipio: "Navas del Marqués, Las",
  //   provincia: "Ávila",
  //   id: "05168",
  //   label: "Navas del Marqués, Las (Ávila)",
  // },
  // {
  //   municipio: "Navatalgordo",
  //   provincia: "Ávila",
  //   id: "05169",
  //   label: "Navatalgordo (Ávila)",
  // },
  // {
  //   municipio: "Navatejares",
  //   provincia: "Ávila",
  //   id: "05170",
  //   label: "Navatejares (Ávila)",
  // },
  // {
  //   municipio: "Niharra",
  //   provincia: "Ávila",
  //   id: "05172",
  //   label: "Niharra (Ávila)",
  // },
  // {
  //   municipio: "Ojos-Albos",
  //   provincia: "Ávila",
  //   id: "05173",
  //   label: "Ojos-Albos (Ávila)",
  // },
  // {
  //   municipio: "Oso, El",
  //   provincia: "Ávila",
  //   id: "05175",
  //   label: "Oso, El (Ávila)",
  // },
  // {
  //   municipio: "Papatrigo",
  //   provincia: "Ávila",
  //   id: "05179",
  //   label: "Papatrigo (Ávila)",
  // },
  // {
  //   municipio: "Padiernos",
  //   provincia: "Ávila",
  //   id: "05176",
  //   label: "Padiernos (Ávila)",
  // },
  // {
  //   municipio: "Pajares de Adaja",
  //   provincia: "Ávila",
  //   id: "05177",
  //   label: "Pajares de Adaja (Ávila)",
  // },
  // {
  //   municipio: "San Bartolomé de Béjar",
  //   provincia: "Ávila",
  //   id: "05199",
  //   label: "San Bartolomé de Béjar (Ávila)",
  // },
  // {
  //   municipio: "Ahillones",
  //   provincia: "Badajoz",
  //   id: "06003",
  //   label: "Ahillones (Badajoz)",
  // },
  // {
  //   municipio: "Alange",
  //   provincia: "Badajoz",
  //   id: "06004",
  //   label: "Alange (Badajoz)",
  // },
  // {
  //   municipio: "Parral, El",
  //   provincia: "Ávila",
  //   id: "05180",
  //   label: "Parral, El (Ávila)",
  // },
  // {
  //   municipio: "Pascualcobo",
  //   provincia: "Ávila",
  //   id: "05181",
  //   label: "Pascualcobo (Ávila)",
  // },
  // {
  //   municipio: "Pedro Bernardo",
  //   provincia: "Ávila",
  //   id: "05182",
  //   label: "Pedro Bernardo (Ávila)",
  // },
  // {
  //   municipio: "Pedro-Rodríguez",
  //   provincia: "Ávila",
  //   id: "05183",
  //   label: "Pedro-Rodríguez (Ávila)",
  // },
  // {
  //   municipio: "Peguerinos",
  //   provincia: "Ávila",
  //   id: "05184",
  //   label: "Peguerinos (Ávila)",
  // },
  // {
  //   municipio: "Peñalba de Ávila",
  //   provincia: "Ávila",
  //   id: "05185",
  //   label: "Peñalba de Ávila (Ávila)",
  // },
  // {
  //   municipio: "Piedrahíta",
  //   provincia: "Ávila",
  //   id: "05186",
  //   label: "Piedrahíta (Ávila)",
  // },
  // {
  //   municipio: "Piedralaves",
  //   provincia: "Ávila",
  //   id: "05187",
  //   label: "Piedralaves (Ávila)",
  // },
  // {
  //   municipio: "Cerezal de Peñahorcada",
  //   provincia: "Salamanca",
  //   id: "37100",
  //   label: "Cerezal de Peñahorcada (Salamanca)",
  // },
  // {
  //   municipio: "Pozanco",
  //   provincia: "Ávila",
  //   id: "05190",
  //   label: "Pozanco (Ávila)",
  // },
  // {
  //   municipio: "Ibrillos",
  //   provincia: "Burgos",
  //   id: "09178",
  //   label: "Ibrillos (Burgos)",
  // },
  // {
  //   municipio: "Pradosegar",
  //   provincia: "Ávila",
  //   id: "05191",
  //   label: "Pradosegar (Ávila)",
  // },
  // {
  //   municipio: "Puerto Castilla",
  //   provincia: "Ávila",
  //   id: "05192",
  //   label: "Puerto Castilla (Ávila)",
  // },
  // {
  //   municipio: "San Bartolomé de Corneja",
  //   provincia: "Ávila",
  //   id: "05200",
  //   label: "San Bartolomé de Corneja (Ávila)",
  // },
  // {
  //   municipio: "San Bartolomé de Pinares",
  //   provincia: "Ávila",
  //   id: "05201",
  //   label: "San Bartolomé de Pinares (Ávila)",
  // },
  // {
  //   municipio: "San Esteban de los Patos",
  //   provincia: "Ávila",
  //   id: "05206",
  //   label: "San Esteban de los Patos (Ávila)",
  // },
  // {
  //   municipio: "Sanchidrián",
  //   provincia: "Ávila",
  //   id: "05204",
  //   label: "Sanchidrián (Ávila)",
  // },
  // {
  //   municipio: "Sanchorreja",
  //   provincia: "Ávila",
  //   id: "05205",
  //   label: "Sanchorreja (Ávila)",
  // },
  // {
  //   municipio: "San García de Ingelmos",
  //   provincia: "Ávila",
  //   id: "05209",
  //   label: "San García de Ingelmos (Ávila)",
  // },
  // {
  //   municipio: "San Juan de la Encinilla",
  //   provincia: "Ávila",
  //   id: "05210",
  //   label: "San Juan de la Encinilla (Ávila)",
  // },
  // {
  //   municipio: "San Esteban del Valle",
  //   provincia: "Ávila",
  //   id: "05207",
  //   label: "San Esteban del Valle (Ávila)",
  // },
  // {
  //   municipio: "San Esteban de Zapardiel",
  //   provincia: "Ávila",
  //   id: "05208",
  //   label: "San Esteban de Zapardiel (Ávila)",
  // },
  // {
  //   municipio: "San Juan de la Nava",
  //   provincia: "Ávila",
  //   id: "05211",
  //   label: "San Juan de la Nava (Ávila)",
  // },
  // {
  //   municipio: "San Juan del Molinillo",
  //   provincia: "Ávila",
  //   id: "05212",
  //   label: "San Juan del Molinillo (Ávila)",
  // },
  // {
  //   municipio: "San Juan del Olmo",
  //   provincia: "Ávila",
  //   id: "05213",
  //   label: "San Juan del Olmo (Ávila)",
  // },
  // {
  //   municipio: "Sotalbo",
  //   provincia: "Ávila",
  //   id: "05239",
  //   label: "Sotalbo (Ávila)",
  // },
  // {
  //   municipio: "San Martín de la Vega del Alberche",
  //   provincia: "Ávila",
  //   id: "05215",
  //   label: "San Martín de la Vega del Alberche (Ávila)",
  // },
  // {
  //   municipio: "Santa Cruz de Pinares",
  //   provincia: "Ávila",
  //   id: "05222",
  //   label: "Santa Cruz de Pinares (Ávila)",
  // },
  // {
  //   municipio: "Sotillo de la Adrada",
  //   provincia: "Ávila",
  //   id: "05240",
  //   label: "Sotillo de la Adrada (Ávila)",
  // },
  // {
  //   municipio: "San Lorenzo de Tormes",
  //   provincia: "Ávila",
  //   id: "05214",
  //   label: "San Lorenzo de Tormes (Ávila)",
  // },
  // {
  //   municipio: "San Martín del Pimpollar",
  //   provincia: "Ávila",
  //   id: "05216",
  //   label: "San Martín del Pimpollar (Ávila)",
  // },
  // {
  //   municipio: "San Miguel de Serrezuela",
  //   provincia: "Ávila",
  //   id: "05218",
  //   label: "San Miguel de Serrezuela (Ávila)",
  // },
  // {
  //   municipio: "San Pascual",
  //   provincia: "Ávila",
  //   id: "05219",
  //   label: "San Pascual (Ávila)",
  // },
  // {
  //   municipio: "Santa Cruz del Valle",
  //   provincia: "Ávila",
  //   id: "05221",
  //   label: "Santa Cruz del Valle (Ávila)",
  // },
  // {
  //   municipio: "San Pedro del Arroyo",
  //   provincia: "Ávila",
  //   id: "05220",
  //   label: "San Pedro del Arroyo (Ávila)",
  // },
  // {
  //   municipio: "Albuera, La",
  //   provincia: "Badajoz",
  //   id: "06005",
  //   label: "Albuera, La (Badajoz)",
  // },
  // {
  //   municipio: "Santa María de los Caballeros",
  //   provincia: "Ávila",
  //   id: "05226",
  //   label: "Santa María de los Caballeros (Ávila)",
  // },
  // {
  //   municipio: "Santa María del Arroyo",
  //   provincia: "Ávila",
  //   id: "05224",
  //   label: "Santa María del Arroyo (Ávila)",
  // },
  // {
  //   municipio: "Santa María del Berrocal",
  //   provincia: "Ávila",
  //   id: "05225",
  //   label: "Santa María del Berrocal (Ávila)",
  // },
  // {
  //   municipio: "Serrada, La",
  //   provincia: "Ávila",
  //   id: "05232",
  //   label: "Serrada, La (Ávila)",
  // },
  // {
  //   municipio: "Tiñosillos",
  //   provincia: "Ávila",
  //   id: "05242",
  //   label: "Tiñosillos (Ávila)",
  // },
  // {
  //   municipio: "Omañas, Las",
  //   provincia: "León",
  //   id: "24104",
  //   label: "Omañas, Las (León)",
  // },
  // {
  //   municipio: "San Vicente de Arévalo",
  //   provincia: "Ávila",
  //   id: "05231",
  //   label: "San Vicente de Arévalo (Ávila)",
  // },
  // {
  //   municipio: "Santa María del Tiétar",
  //   provincia: "Ávila",
  //   id: "05227",
  //   label: "Santa María del Tiétar (Ávila)",
  // },
  // {
  //   municipio: "Navares de Ayuso",
  //   provincia: "Segovia",
  //   id: "40142",
  //   label: "Navares de Ayuso (Segovia)",
  // },
  // {
  //   municipio: "Tolbaños",
  //   provincia: "Ávila",
  //   id: "05243",
  //   label: "Tolbaños (Ávila)",
  // },
  // {
  //   municipio: "Villasayas",
  //   provincia: "Soria",
  //   id: "42212",
  //   label: "Villasayas (Soria)",
  // },
  // {
  //   municipio: "Santiago del Collado",
  //   provincia: "Ávila",
  //   id: "05228",
  //   label: "Santiago del Collado (Ávila)",
  // },
  // {
  //   municipio: "Sinlabajos",
  //   provincia: "Ávila",
  //   id: "05235",
  //   label: "Sinlabajos (Ávila)",
  // },
  // {
  //   municipio: "Serranillos",
  //   provincia: "Ávila",
  //   id: "05233",
  //   label: "Serranillos (Ávila)",
  // },
  // {
  //   municipio: "Santo Domingo de las Posadas",
  //   provincia: "Ávila",
  //   id: "05229",
  //   label: "Santo Domingo de las Posadas (Ávila)",
  // },
  // {
  //   municipio: "Santo Tomé de Zabarcos",
  //   provincia: "Ávila",
  //   id: "05230",
  //   label: "Santo Tomé de Zabarcos (Ávila)",
  // },
  // {
  //   municipio: "Sigeres",
  //   provincia: "Ávila",
  //   id: "05234",
  //   label: "Sigeres (Ávila)",
  // },
  // {
  //   municipio: "Solana de Ávila",
  //   provincia: "Ávila",
  //   id: "05236",
  //   label: "Solana de Ávila (Ávila)",
  // },
  // {
  //   municipio: "Tórtoles",
  //   provincia: "Ávila",
  //   id: "05246",
  //   label: "Tórtoles (Ávila)",
  // },
  // {
  //   municipio: "Torre, La",
  //   provincia: "Ávila",
  //   id: "05247",
  //   label: "Torre, La (Ávila)",
  // },
  // {
  //   municipio: "Umbrías",
  //   provincia: "Ávila",
  //   id: "05249",
  //   label: "Umbrías (Ávila)",
  // },
  // {
  //   municipio: "Solana de Rioalmar",
  //   provincia: "Ávila",
  //   id: "05237",
  //   label: "Solana de Rioalmar (Ávila)",
  // },
  // {
  //   municipio: "Vadillo de la Sierra",
  //   provincia: "Ávila",
  //   id: "05251",
  //   label: "Vadillo de la Sierra (Ávila)",
  // },
  // {
  //   municipio: "Iglesiarrubia",
  //   provincia: "Burgos",
  //   id: "09179",
  //   label: "Iglesiarrubia (Burgos)",
  // },
  // {
  //   municipio: "Valdecasa",
  //   provincia: "Ávila",
  //   id: "05252",
  //   label: "Valdecasa (Ávila)",
  // },
  // {
  //   municipio: "Solosancho",
  //   provincia: "Ávila",
  //   id: "05238",
  //   label: "Solosancho (Ávila)",
  // },
  // {
  //   municipio: "Tormellas",
  //   provincia: "Ávila",
  //   id: "05244",
  //   label: "Tormellas (Ávila)",
  // },
  // {
  //   municipio: "Tornadizos de Ávila",
  //   provincia: "Ávila",
  //   id: "05245",
  //   label: "Tornadizos de Ávila (Ávila)",
  // },
  // {
  //   municipio: "Villanueva del Aceral",
  //   provincia: "Ávila",
  //   id: "05259",
  //   label: "Villanueva del Aceral (Ávila)",
  // },
  // {
  //   municipio: "Vega de Santa María",
  //   provincia: "Ávila",
  //   id: "05253",
  //   label: "Vega de Santa María (Ávila)",
  // },
  // {
  //   municipio: "Velayos",
  //   provincia: "Ávila",
  //   id: "05254",
  //   label: "Velayos (Ávila)",
  // },
  // {
  //   municipio: "Gormaz",
  //   provincia: "Soria",
  //   id: "42097",
  //   label: "Gormaz (Soria)",
  // },
  // {
  //   municipio: "Villaflor",
  //   provincia: "Ávila",
  //   id: "05256",
  //   label: "Villaflor (Ávila)",
  // },
  // {
  //   municipio: "Torrechiva",
  //   provincia: "Castellón",
  //   id: "12118",
  //   label: "Torrechiva (Castellón)",
  // },
  // {
  //   municipio: "Zapardiel de la Ribera",
  //   provincia: "Ávila",
  //   id: "05267",
  //   label: "Zapardiel de la Ribera (Ávila)",
  // },
  // {
  //   municipio: "Villafranca de la Sierra",
  //   provincia: "Ávila",
  //   id: "05257",
  //   label: "Villafranca de la Sierra (Ávila)",
  // },
  // {
  //   municipio: "Villanueva del Campillo",
  //   provincia: "Ávila",
  //   id: "05260",
  //   label: "Villanueva del Campillo (Ávila)",
  // },
  // {
  //   municipio: "Villar de Corneja",
  //   provincia: "Ávila",
  //   id: "05261",
  //   label: "Villar de Corneja (Ávila)",
  // },
  // {
  //   municipio: "Villanueva de Gómez",
  //   provincia: "Ávila",
  //   id: "05258",
  //   label: "Villanueva de Gómez (Ávila)",
  // },
  // {
  //   municipio: "Diego del Carpio",
  //   provincia: "Ávila",
  //   id: "05903",
  //   label: "Diego del Carpio (Ávila)",
  // },
  // {
  //   municipio: "Santiago del Tormes",
  //   provincia: "Ávila",
  //   id: "05904",
  //   label: "Santiago del Tormes (Ávila)",
  // },
  // {
  //   municipio: "Villanueva de Ávila",
  //   provincia: "Ávila",
  //   id: "05905",
  //   label: "Villanueva de Ávila (Ávila)",
  // },
  // {
  //   municipio: "Villarejo del Valle",
  //   provincia: "Ávila",
  //   id: "05262",
  //   label: "Villarejo del Valle (Ávila)",
  // },
  // {
  //   municipio: "Vita",
  //   provincia: "Ávila",
  //   id: "05265",
  //   label: "Vita (Ávila)",
  // },
  // {
  //   municipio: "Zapardiel de la Cañada",
  //   provincia: "Ávila",
  //   id: "05266",
  //   label: "Zapardiel de la Cañada (Ávila)",
  // },
  // {
  //   municipio: "Villatoro",
  //   provincia: "Ávila",
  //   id: "05263",
  //   label: "Villatoro (Ávila)",
  // },
  // {
  //   municipio: "Viñegra de Moraña",
  //   provincia: "Ávila",
  //   id: "05264",
  //   label: "Viñegra de Moraña (Ávila)",
  // },
  // {
  //   municipio: "Berlanga",
  //   provincia: "Badajoz",
  //   id: "06019",
  //   label: "Berlanga (Badajoz)",
  // },
  // {
  //   municipio: "Barrachina",
  //   provincia: "Teruel",
  //   id: "44035",
  //   label: "Barrachina (Teruel)",
  // },
  // {
  //   municipio: "San Juan de Gredos",
  //   provincia: "Ávila",
  //   id: "05901",
  //   label: "San Juan de Gredos (Ávila)",
  // },
  // {
  //   municipio: "Burguillos del Cerro",
  //   provincia: "Badajoz",
  //   id: "06022",
  //   label: "Burguillos del Cerro (Badajoz)",
  // },
  // {
  //   municipio: "Cabeza del Buey",
  //   provincia: "Badajoz",
  //   id: "06023",
  //   label: "Cabeza del Buey (Badajoz)",
  // },
  // {
  //   municipio: "Aljucén",
  //   provincia: "Badajoz",
  //   id: "06009",
  //   label: "Aljucén (Badajoz)",
  // },
  // {
  //   municipio: "Santa María del Cubillo",
  //   provincia: "Ávila",
  //   id: "05902",
  //   label: "Santa María del Cubillo (Ávila)",
  // },
  // {
  //   municipio: "Alconera",
  //   provincia: "Badajoz",
  //   id: "06008",
  //   label: "Alconera (Badajoz)",
  // },
  // {
  //   municipio: "Almendral",
  //   provincia: "Badajoz",
  //   id: "06010",
  //   label: "Almendral (Badajoz)",
  // },
  // {
  //   municipio: "Santa María de la Isla",
  //   provincia: "León",
  //   id: "24155",
  //   label: "Santa María de la Isla (León)",
  // },
  // {
  //   municipio: "Carrascalejo, El",
  //   provincia: "Badajoz",
  //   id: "06032",
  //   label: "Carrascalejo, El (Badajoz)",
  // },
  // {
  //   municipio: "Casas de Don Pedro",
  //   provincia: "Badajoz",
  //   id: "06033",
  //   label: "Casas de Don Pedro (Badajoz)",
  // },
  // {
  //   municipio: "Almendralejo",
  //   provincia: "Badajoz",
  //   id: "06011",
  //   label: "Almendralejo (Badajoz)",
  // },
  // {
  //   municipio: "Atalaya",
  //   provincia: "Badajoz",
  //   id: "06013",
  //   label: "Atalaya (Badajoz)",
  // },
  // {
  //   municipio: "Azuaga",
  //   provincia: "Badajoz",
  //   id: "06014",
  //   label: "Azuaga (Badajoz)",
  // },
  // {
  //   municipio: "Barcarrota",
  //   provincia: "Badajoz",
  //   id: "06016",
  //   label: "Barcarrota (Badajoz)",
  // },
  // {
  //   municipio: "Calamonte",
  //   provincia: "Badajoz",
  //   id: "06025",
  //   label: "Calamonte (Badajoz)",
  // },
  // {
  //   municipio: "Calera de León",
  //   provincia: "Badajoz",
  //   id: "06026",
  //   label: "Calera de León (Badajoz)",
  // },
  // {
  //   municipio: "Alcublas",
  //   provincia: "Valencia",
  //   id: "46018",
  //   label: "Alcublas (Valencia)",
  // },
  // {
  //   municipio: "Baterno",
  //   provincia: "Badajoz",
  //   id: "06017",
  //   label: "Baterno (Badajoz)",
  // },
  // {
  //   municipio: "Calzadilla de los Barros",
  //   provincia: "Badajoz",
  //   id: "06027",
  //   label: "Calzadilla de los Barros (Badajoz)",
  // },
  // {
  //   municipio: "Benquerencia de la Serena",
  //   provincia: "Badajoz",
  //   id: "06018",
  //   label: "Benquerencia de la Serena (Badajoz)",
  // },
  // {
  //   municipio: "Bienvenida",
  //   provincia: "Badajoz",
  //   id: "06020",
  //   label: "Bienvenida (Badajoz)",
  // },
  // {
  //   municipio: "Bodonal de la Sierra",
  //   provincia: "Badajoz",
  //   id: "06021",
  //   label: "Bodonal de la Sierra (Badajoz)",
  // },
  // {
  //   municipio: "Cabeza la Vaca",
  //   provincia: "Badajoz",
  //   id: "06024",
  //   label: "Cabeza la Vaca (Badajoz)",
  // },
  // {
  //   municipio: "Campanario",
  //   provincia: "Badajoz",
  //   id: "06028",
  //   label: "Campanario (Badajoz)",
  // },
  // {
  //   municipio: "Cheles",
  //   provincia: "Badajoz",
  //   id: "06042",
  //   label: "Cheles (Badajoz)",
  // },
  // {
  //   municipio: "Don Álvaro",
  //   provincia: "Badajoz",
  //   id: "06043",
  //   label: "Don Álvaro (Badajoz)",
  // },
  // {
  //   municipio: "Campillo de Llerena",
  //   provincia: "Badajoz",
  //   id: "06029",
  //   label: "Campillo de Llerena (Badajoz)",
  // },
  // {
  //   municipio: "Capilla",
  //   provincia: "Badajoz",
  //   id: "06030",
  //   label: "Capilla (Badajoz)",
  // },
  // {
  //   municipio: "Carmonita",
  //   provincia: "Badajoz",
  //   id: "06031",
  //   label: "Carmonita (Badajoz)",
  // },
  // {
  //   municipio: "Casas de Reina",
  //   provincia: "Badajoz",
  //   id: "06034",
  //   label: "Casas de Reina (Badajoz)",
  // },
  // {
  //   municipio: "Cordobilla de Lácara",
  //   provincia: "Badajoz",
  //   id: "06038",
  //   label: "Cordobilla de Lácara (Badajoz)",
  // },
  // {
  //   municipio: "Coronada, La",
  //   provincia: "Badajoz",
  //   id: "06039",
  //   label: "Coronada, La (Badajoz)",
  // },
  // {
  //   municipio: "Castilblanco",
  //   provincia: "Badajoz",
  //   id: "06035",
  //   label: "Castilblanco (Badajoz)",
  // },
  // {
  //   municipio: "Castuera",
  //   provincia: "Badajoz",
  //   id: "06036",
  //   label: "Castuera (Badajoz)",
  // },
  // {
  //   municipio: "Cristina",
  //   provincia: "Badajoz",
  //   id: "06041",
  //   label: "Cristina (Badajoz)",
  // },
  // {
  //   municipio: "Codosera, La",
  //   provincia: "Badajoz",
  //   id: "06037",
  //   label: "Codosera, La (Badajoz)",
  // },
  // {
  //   municipio: "Corte de Peleas",
  //   provincia: "Badajoz",
  //   id: "06040",
  //   label: "Corte de Peleas (Badajoz)",
  // },
  // {
  //   municipio: "Entrín Bajo",
  //   provincia: "Badajoz",
  //   id: "06045",
  //   label: "Entrín Bajo (Badajoz)",
  // },
  // {
  //   municipio: "Fresnillo de las Dueñas",
  //   provincia: "Burgos",
  //   id: "09131",
  //   label: "Fresnillo de las Dueñas (Burgos)",
  // },
  // {
  //   municipio: "Esparragalejo",
  //   provincia: "Badajoz",
  //   id: "06046",
  //   label: "Esparragalejo (Badajoz)",
  // },
  // {
  //   municipio: "Orellana de la Sierra",
  //   provincia: "Badajoz",
  //   id: "06096",
  //   label: "Orellana de la Sierra (Badajoz)",
  // },
  // {
  //   municipio: "Fresno de Río Tirón",
  //   provincia: "Burgos",
  //   id: "09132",
  //   label: "Fresno de Río Tirón (Burgos)",
  // },
  // {
  //   municipio: "Esparragosa de la Serena",
  //   provincia: "Badajoz",
  //   id: "06047",
  //   label: "Esparragosa de la Serena (Badajoz)",
  // },
  // {
  //   municipio: "Esparragosa de Lares",
  //   provincia: "Badajoz",
  //   id: "06048",
  //   label: "Esparragosa de Lares (Badajoz)",
  // },
  // {
  //   municipio: "Garbayuela",
  //   provincia: "Badajoz",
  //   id: "06056",
  //   label: "Garbayuela (Badajoz)",
  // },
  // {
  //   municipio: "Feria",
  //   provincia: "Badajoz",
  //   id: "06049",
  //   label: "Feria (Badajoz)",
  // },
  // {
  //   municipio: "Fregenal de la Sierra",
  //   provincia: "Badajoz",
  //   id: "06050",
  //   label: "Fregenal de la Sierra (Badajoz)",
  // },
  // {
  //   municipio: "Fuenlabrada de los Montes",
  //   provincia: "Badajoz",
  //   id: "06051",
  //   label: "Fuenlabrada de los Montes (Badajoz)",
  // },
  // {
  //   municipio: "Puebla de la Calzada",
  //   provincia: "Badajoz",
  //   id: "06103",
  //   label: "Puebla de la Calzada (Badajoz)",
  // },
  // {
  //   municipio: "Fuente de Cantos",
  //   provincia: "Badajoz",
  //   id: "06052",
  //   label: "Fuente de Cantos (Badajoz)",
  // },
  // {
  //   municipio: "Fuente del Arco",
  //   provincia: "Badajoz",
  //   id: "06053",
  //   label: "Fuente del Arco (Badajoz)",
  // },
  // {
  //   municipio: "Fuente del Maestre",
  //   provincia: "Badajoz",
  //   id: "06054",
  //   label: "Fuente del Maestre (Badajoz)",
  // },
  // {
  //   municipio: "Pozuelo de la Orden",
  //   provincia: "Valladolid",
  //   id: "47125",
  //   label: "Pozuelo de la Orden (Valladolid)",
  // },
  // {
  //   municipio: "Fuentes de León",
  //   provincia: "Badajoz",
  //   id: "06055",
  //   label: "Fuentes de León (Badajoz)",
  // },
  // {
  //   municipio: "Llocnou de la Corona",
  //   provincia: "Valencia",
  //   id: "46152",
  //   label: "Llocnou de la Corona (Valencia)",
  // },
  // {
  //   municipio: "Garlitos",
  //   provincia: "Badajoz",
  //   id: "06057",
  //   label: "Garlitos (Badajoz)",
  // },
  // {
  //   municipio: "Puebla de Sancho Pérez",
  //   provincia: "Badajoz",
  //   id: "06108",
  //   label: "Puebla de Sancho Pérez (Badajoz)",
  // },
  // {
  //   municipio: "Garrovilla, La",
  //   provincia: "Badajoz",
  //   id: "06058",
  //   label: "Garrovilla, La (Badajoz)",
  // },
  // {
  //   municipio: "Haba, La",
  //   provincia: "Badajoz",
  //   id: "06061",
  //   label: "Haba, La (Badajoz)",
  // },
  // {
  //   municipio: "Granja de Torrehermosa",
  //   provincia: "Badajoz",
  //   id: "06059",
  //   label: "Granja de Torrehermosa (Badajoz)",
  // },
  // {
  //   municipio: "Helechosa de los Montes",
  //   provincia: "Badajoz",
  //   id: "06062",
  //   label: "Helechosa de los Montes (Badajoz)",
  // },
  // {
  //   municipio: "Rena",
  //   provincia: "Badajoz",
  //   id: "06111",
  //   label: "Rena (Badajoz)",
  // },
  // {
  //   municipio: "Herrera del Duque",
  //   provincia: "Badajoz",
  //   id: "06063",
  //   label: "Herrera del Duque (Badajoz)",
  // },
  // {
  //   municipio: "Higuera de la Serena",
  //   provincia: "Badajoz",
  //   id: "06064",
  //   label: "Higuera de la Serena (Badajoz)",
  // },
  // {
  //   municipio: "Vilafranca de Bonany",
  //   provincia: "Islas Baleares",
  //   id: "07065",
  //   label: "Vilafranca de Bonany (Islas Baleares)",
  // },
  // {
  //   municipio: "Higuera de Llerena",
  //   provincia: "Badajoz",
  //   id: "06065",
  //   label: "Higuera de Llerena (Badajoz)",
  // },
  // {
  //   municipio: "Higuera de Vargas",
  //   provincia: "Badajoz",
  //   id: "06066",
  //   label: "Higuera de Vargas (Badajoz)",
  // },
  // {
  //   municipio: "Higuera la Real",
  //   provincia: "Badajoz",
  //   id: "06067",
  //   label: "Higuera la Real (Badajoz)",
  // },
  // {
  //   municipio: "Hinojosa del Valle",
  //   provincia: "Badajoz",
  //   id: "06068",
  //   label: "Hinojosa del Valle (Badajoz)",
  // },
  // {
  //   municipio: "Hornachos",
  //   provincia: "Badajoz",
  //   id: "06069",
  //   label: "Hornachos (Badajoz)",
  // },
  // {
  //   municipio: "Lapa, La",
  //   provincia: "Badajoz",
  //   id: "06071",
  //   label: "Lapa, La (Badajoz)",
  // },
  // {
  //   municipio: "Lobón",
  //   provincia: "Badajoz",
  //   id: "06072",
  //   label: "Lobón (Badajoz)",
  // },
  // {
  //   municipio: "Castillejo de Iniesta",
  //   provincia: "Cuenca",
  //   id: "16068",
  //   label: "Castillejo de Iniesta (Cuenca)",
  // },
  // {
  //   municipio: "Llera",
  //   provincia: "Badajoz",
  //   id: "06073",
  //   label: "Llera (Badajoz)",
  // },
  // {
  //   municipio: "Llerena",
  //   provincia: "Badajoz",
  //   id: "06074",
  //   label: "Llerena (Badajoz)",
  // },
  // {
  //   municipio: "Magacela",
  //   provincia: "Badajoz",
  //   id: "06075",
  //   label: "Magacela (Badajoz)",
  // },
  // {
  //   municipio: "Malcocinado",
  //   provincia: "Badajoz",
  //   id: "06077",
  //   label: "Malcocinado (Badajoz)",
  // },
  // {
  //   municipio: "Maguilla",
  //   provincia: "Badajoz",
  //   id: "06076",
  //   label: "Maguilla (Badajoz)",
  // },
  // {
  //   municipio: "Malpartida de la Serena",
  //   provincia: "Badajoz",
  //   id: "06078",
  //   label: "Malpartida de la Serena (Badajoz)",
  // },
  // {
  //   municipio: "Medellín",
  //   provincia: "Badajoz",
  //   id: "06080",
  //   label: "Medellín (Badajoz)",
  // },
  // {
  //   municipio: "Medina de las Torres",
  //   provincia: "Badajoz",
  //   id: "06081",
  //   label: "Medina de las Torres (Badajoz)",
  // },
  // {
  //   municipio: "Mengabril",
  //   provincia: "Badajoz",
  //   id: "06082",
  //   label: "Mengabril (Badajoz)",
  // },
  // {
  //   municipio: "Mirandilla",
  //   provincia: "Badajoz",
  //   id: "06084",
  //   label: "Mirandilla (Badajoz)",
  // },
  // {
  //   municipio: "Montemolín",
  //   provincia: "Badajoz",
  //   id: "06086",
  //   label: "Montemolín (Badajoz)",
  // },
  // {
  //   municipio: "Monterrubio de la Serena",
  //   provincia: "Badajoz",
  //   id: "06087",
  //   label: "Monterrubio de la Serena (Badajoz)",
  // },
  // {
  //   municipio: "Montijo",
  //   provincia: "Badajoz",
  //   id: "06088",
  //   label: "Montijo (Badajoz)",
  // },
  // {
  //   municipio: "Morera, La",
  //   provincia: "Badajoz",
  //   id: "06089",
  //   label: "Morera, La (Badajoz)",
  // },
  // {
  //   municipio: "Nava de Santiago, La",
  //   provincia: "Badajoz",
  //   id: "06090",
  //   label: "Nava de Santiago, La (Badajoz)",
  // },
  // {
  //   municipio: "Navalvillar de Pela",
  //   provincia: "Badajoz",
  //   id: "06091",
  //   label: "Navalvillar de Pela (Badajoz)",
  // },
  // {
  //   municipio: "Nogales",
  //   provincia: "Badajoz",
  //   id: "06092",
  //   label: "Nogales (Badajoz)",
  // },
  // {
  //   municipio: "Oliva de la Frontera",
  //   provincia: "Badajoz",
  //   id: "06093",
  //   label: "Oliva de la Frontera (Badajoz)",
  // },
  // {
  //   municipio: "Orellana la Vieja",
  //   provincia: "Badajoz",
  //   id: "06097",
  //   label: "Orellana la Vieja (Badajoz)",
  // },
  // {
  //   municipio: "Palomas",
  //   provincia: "Badajoz",
  //   id: "06098",
  //   label: "Palomas (Badajoz)",
  // },
  // {
  //   municipio: "Parra, La",
  //   provincia: "Badajoz",
  //   id: "06099",
  //   label: "Parra, La (Badajoz)",
  // },
  // {
  //   municipio: "Peñalsordo",
  //   provincia: "Badajoz",
  //   id: "06100",
  //   label: "Peñalsordo (Badajoz)",
  // },
  // {
  //   municipio: "Campanet",
  //   provincia: "Islas Baleares",
  //   id: "07012",
  //   label: "Campanet (Islas Baleares)",
  // },
  // {
  //   municipio: "Campos",
  //   provincia: "Islas Baleares",
  //   id: "07013",
  //   label: "Campos (Islas Baleares)",
  // },
  // {
  //   municipio: "Peraleda del Zaucejo",
  //   provincia: "Badajoz",
  //   id: "06101",
  //   label: "Peraleda del Zaucejo (Badajoz)",
  // },
  // {
  //   municipio: "Puebla de la Reina",
  //   provincia: "Badajoz",
  //   id: "06104",
  //   label: "Puebla de la Reina (Badajoz)",
  // },
  // {
  //   municipio: "Puebla del Maestre",
  //   provincia: "Badajoz",
  //   id: "06105",
  //   label: "Puebla del Maestre (Badajoz)",
  // },
  // {
  //   municipio: "Puebla del Prior",
  //   provincia: "Badajoz",
  //   id: "06106",
  //   label: "Puebla del Prior (Badajoz)",
  // },
  // {
  //   municipio: "Puebla de Obando",
  //   provincia: "Badajoz",
  //   id: "06107",
  //   label: "Puebla de Obando (Badajoz)",
  // },
  // {
  //   municipio: "Puras",
  //   provincia: "Valladolid",
  //   id: "47126",
  //   label: "Puras (Valladolid)",
  // },
  // {
  //   municipio: "Quintana de la Serena",
  //   provincia: "Badajoz",
  //   id: "06109",
  //   label: "Quintana de la Serena (Badajoz)",
  // },
  // {
  //   municipio: "Reina",
  //   provincia: "Badajoz",
  //   id: "06110",
  //   label: "Reina (Badajoz)",
  // },
  // {
  //   municipio: "Retamal de Llerena",
  //   provincia: "Badajoz",
  //   id: "06112",
  //   label: "Retamal de Llerena (Badajoz)",
  // },
  // {
  //   municipio: "Ribera del Fresno",
  //   provincia: "Badajoz",
  //   id: "06113",
  //   label: "Ribera del Fresno (Badajoz)",
  // },
  // {
  //   municipio: "Risco",
  //   provincia: "Badajoz",
  //   id: "06114",
  //   label: "Risco (Badajoz)",
  // },
  // {
  //   municipio: "Roca de la Sierra, La",
  //   provincia: "Badajoz",
  //   id: "06115",
  //   label: "Roca de la Sierra, La (Badajoz)",
  // },
  // {
  //   municipio: "Consell",
  //   provincia: "Islas Baleares",
  //   id: "07016",
  //   label: "Consell (Islas Baleares)",
  // },
  // {
  //   municipio: "Salvaleón",
  //   provincia: "Badajoz",
  //   id: "06116",
  //   label: "Salvaleón (Badajoz)",
  // },
  // {
  //   municipio: "Costitx",
  //   provincia: "Islas Baleares",
  //   id: "07017",
  //   label: "Costitx (Islas Baleares)",
  // },
  // {
  //   municipio: "Salvatierra de los Barros",
  //   provincia: "Badajoz",
  //   id: "06117",
  //   label: "Salvatierra de los Barros (Badajoz)",
  // },
  // {
  //   municipio: "Bordalba",
  //   provincia: "Zaragoza",
  //   id: "50054",
  //   label: "Bordalba (Zaragoza)",
  // },
  // {
  //   municipio: "Sancti-Spíritus",
  //   provincia: "Badajoz",
  //   id: "06118",
  //   label: "Sancti-Spíritus (Badajoz)",
  // },
  // {
  //   municipio: "San Pedro de Mérida",
  //   provincia: "Badajoz",
  //   id: "06119",
  //   label: "San Pedro de Mérida (Badajoz)",
  // },
  // {
  //   municipio: "Santa Cecília de Voltregà",
  //   provincia: "Barcelona",
  //   id: "08243",
  //   label: "Santa Cecília de Voltregà (Barcelona)",
  // },
  // {
  //   municipio: "Santa Amalia",
  //   provincia: "Badajoz",
  //   id: "06120",
  //   label: "Santa Amalia (Badajoz)",
  // },
  // {
  //   municipio: "Novetlè/Novelé",
  //   provincia: "Valencia",
  //   id: "46180",
  //   label: "Novetlè/Novelé (Valencia)",
  // },
  // {
  //   municipio: "Santa Marta",
  //   provincia: "Badajoz",
  //   id: "06121",
  //   label: "Santa Marta (Badajoz)",
  // },
  // {
  //   municipio: "Lloseta",
  //   provincia: "Islas Baleares",
  //   id: "07029",
  //   label: "Lloseta (Islas Baleares)",
  // },
  // {
  //   municipio: "Oliva",
  //   provincia: "Valencia",
  //   id: "46181",
  //   label: "Oliva (Valencia)",
  // },
  // {
  //   municipio: "Santos de Maimona, Los",
  //   provincia: "Badajoz",
  //   id: "06122",
  //   label: "Santos de Maimona, Los (Badajoz)",
  // },
  // {
  //   municipio: "Llubí",
  //   provincia: "Islas Baleares",
  //   id: "07030",
  //   label: "Llubí (Islas Baleares)",
  // },
  // {
  //   municipio: "Borja",
  //   provincia: "Zaragoza",
  //   id: "50055",
  //   label: "Borja (Zaragoza)",
  // },
  // {
  //   municipio: "Segura de León",
  //   provincia: "Badajoz",
  //   id: "06124",
  //   label: "Segura de León (Badajoz)",
  // },
  // {
  //   municipio: "Siruela",
  //   provincia: "Badajoz",
  //   id: "06125",
  //   label: "Siruela (Badajoz)",
  // },
  // {
  //   municipio: "Leiva",
  //   provincia: "La Rioja",
  //   id: "26087",
  //   label: "Leiva (La Rioja)",
  // },
  // {
  //   municipio: "Solana de los Barros",
  //   provincia: "Badajoz",
  //   id: "06126",
  //   label: "Solana de los Barros (Badajoz)",
  // },
  // {
  //   municipio: "Mancor de la Vall",
  //   provincia: "Islas Baleares",
  //   id: "07034",
  //   label: "Mancor de la Vall (Islas Baleares)",
  // },
  // {
  //   municipio: "Talarrubias",
  //   provincia: "Badajoz",
  //   id: "06127",
  //   label: "Talarrubias (Badajoz)",
  // },
  // {
  //   municipio: "Talavera la Real",
  //   provincia: "Badajoz",
  //   id: "06128",
  //   label: "Talavera la Real (Badajoz)",
  // },
  // {
  //   municipio: "Táliga",
  //   provincia: "Badajoz",
  //   id: "06129",
  //   label: "Táliga (Badajoz)",
  // },
  // {
  //   municipio: "Tamurejo",
  //   provincia: "Badajoz",
  //   id: "06130",
  //   label: "Tamurejo (Badajoz)",
  // },
  // {
  //   municipio: "Valdecaballeros",
  //   provincia: "Badajoz",
  //   id: "06137",
  //   label: "Valdecaballeros (Badajoz)",
  // },
  // {
  //   municipio: "Torre de Miguel Sesmero",
  //   provincia: "Badajoz",
  //   id: "06131",
  //   label: "Torre de Miguel Sesmero (Badajoz)",
  // },
  // {
  //   municipio: "Torremayor",
  //   provincia: "Badajoz",
  //   id: "06132",
  //   label: "Torremayor (Badajoz)",
  // },
  // {
  //   municipio: "Torremejía",
  //   provincia: "Badajoz",
  //   id: "06133",
  //   label: "Torremejía (Badajoz)",
  // },
  // {
  //   municipio: "Valle de Matamoros",
  //   provincia: "Badajoz",
  //   id: "06147",
  //   label: "Valle de Matamoros (Badajoz)",
  // },
  // {
  //   municipio: "Trasierra",
  //   provincia: "Badajoz",
  //   id: "06134",
  //   label: "Trasierra (Badajoz)",
  // },
  // {
  //   municipio: "Trujillanos",
  //   provincia: "Badajoz",
  //   id: "06135",
  //   label: "Trujillanos (Badajoz)",
  // },
  // {
  //   municipio: "Usagre",
  //   provincia: "Badajoz",
  //   id: "06136",
  //   label: "Usagre (Badajoz)",
  // },
  // {
  //   municipio: "Valdetorres",
  //   provincia: "Badajoz",
  //   id: "06138",
  //   label: "Valdetorres (Badajoz)",
  // },
  // {
  //   municipio: "Valencia de las Torres",
  //   provincia: "Badajoz",
  //   id: "06139",
  //   label: "Valencia de las Torres (Badajoz)",
  // },
  // {
  //   municipio: "Valencia del Mombuey",
  //   provincia: "Badajoz",
  //   id: "06140",
  //   label: "Valencia del Mombuey (Badajoz)",
  // },
  // {
  //   municipio: "Valencia del Ventoso",
  //   provincia: "Badajoz",
  //   id: "06141",
  //   label: "Valencia del Ventoso (Badajoz)",
  // },
  // {
  //   municipio: "Valle de Santa Ana",
  //   provincia: "Badajoz",
  //   id: "06148",
  //   label: "Valle de Santa Ana (Badajoz)",
  // },
  // {
  //   municipio: "Valverde de Burguillos",
  //   provincia: "Badajoz",
  //   id: "06142",
  //   label: "Valverde de Burguillos (Badajoz)",
  // },
  // {
  //   municipio: "Arauzo de Miel",
  //   provincia: "Burgos",
  //   id: "09020",
  //   label: "Arauzo de Miel (Burgos)",
  // },
  // {
  //   municipio: "Valverde de Leganés",
  //   provincia: "Badajoz",
  //   id: "06143",
  //   label: "Valverde de Leganés (Badajoz)",
  // },
  // {
  //   municipio: "Maria de la Salut",
  //   provincia: "Islas Baleares",
  //   id: "07035",
  //   label: "Maria de la Salut (Islas Baleares)",
  // },
  // {
  //   municipio: "Valverde de Llerena",
  //   provincia: "Badajoz",
  //   id: "06144",
  //   label: "Valverde de Llerena (Badajoz)",
  // },
  // {
  //   municipio: "Valverde de Mérida",
  //   provincia: "Badajoz",
  //   id: "06145",
  //   label: "Valverde de Mérida (Badajoz)",
  // },
  // {
  //   municipio: "Valle de la Serena",
  //   provincia: "Badajoz",
  //   id: "06146",
  //   label: "Valle de la Serena (Badajoz)",
  // },
  // {
  //   municipio: "Villafranca de los Barros",
  //   provincia: "Badajoz",
  //   id: "06149",
  //   label: "Villafranca de los Barros (Badajoz)",
  // },
  // {
  //   municipio: "Villagarcía de la Torre",
  //   provincia: "Badajoz",
  //   id: "06150",
  //   label: "Villagarcía de la Torre (Badajoz)",
  // },
  // {
  //   municipio: "Villagonzalo",
  //   provincia: "Badajoz",
  //   id: "06151",
  //   label: "Villagonzalo (Badajoz)",
  // },
  // {
  //   municipio: "Lliçà de Vall",
  //   provincia: "Barcelona",
  //   id: "08108",
  //   label: "Lliçà de Vall (Barcelona)",
  // },
  // {
  //   municipio: "Villalba de los Barros",
  //   provincia: "Badajoz",
  //   id: "06152",
  //   label: "Villalba de los Barros (Badajoz)",
  // },
  // {
  //   municipio: "Villanueva de la Serena",
  //   provincia: "Badajoz",
  //   id: "06153",
  //   label: "Villanueva de la Serena (Badajoz)",
  // },
  // {
  //   municipio: "Matadepera",
  //   provincia: "Barcelona",
  //   id: "08120",
  //   label: "Matadepera (Barcelona)",
  // },
  // {
  //   municipio: "Mataró",
  //   provincia: "Barcelona",
  //   id: "08121",
  //   label: "Mataró (Barcelona)",
  // },
  // {
  //   municipio: "Mediona",
  //   provincia: "Barcelona",
  //   id: "08122",
  //   label: "Mediona (Barcelona)",
  // },
  // {
  //   municipio: "Villar del Rey",
  //   provincia: "Badajoz",
  //   id: "06155",
  //   label: "Villar del Rey (Badajoz)",
  // },
  // {
  //   municipio: "Molins de Rei",
  //   provincia: "Barcelona",
  //   id: "08123",
  //   label: "Molins de Rei (Barcelona)",
  // },
  // {
  //   municipio: "Geria",
  //   provincia: "Valladolid",
  //   id: "47071",
  //   label: "Geria (Valladolid)",
  // },
  // {
  //   municipio: "Villar de Rena",
  //   provincia: "Badajoz",
  //   id: "06156",
  //   label: "Villar de Rena (Badajoz)",
  // },
  // {
  //   municipio: "Villarta de los Montes",
  //   provincia: "Badajoz",
  //   id: "06157",
  //   label: "Villarta de los Montes (Badajoz)",
  // },
  // {
  //   municipio: "Olèrdola",
  //   provincia: "Barcelona",
  //   id: "08145",
  //   label: "Olèrdola (Barcelona)",
  // },
  // {
  //   municipio: "Zafra",
  //   provincia: "Badajoz",
  //   id: "06158",
  //   label: "Zafra (Badajoz)",
  // },
  // {
  //   municipio: "Zalamea de la Serena",
  //   provincia: "Badajoz",
  //   id: "06160",
  //   label: "Zalamea de la Serena (Badajoz)",
  // },
  // {
  //   municipio: "Begues",
  //   provincia: "Barcelona",
  //   id: "08020",
  //   label: "Begues (Barcelona)",
  // },
  // {
  //   municipio: "Zarza-Capilla",
  //   provincia: "Badajoz",
  //   id: "06161",
  //   label: "Zarza-Capilla (Badajoz)",
  // },
  // {
  //   municipio: "Pacs del Penedès",
  //   provincia: "Barcelona",
  //   id: "08154",
  //   label: "Pacs del Penedès (Barcelona)",
  // },
  // {
  //   municipio: "Palafolls",
  //   provincia: "Barcelona",
  //   id: "08155",
  //   label: "Palafolls (Barcelona)",
  // },
  // {
  //   municipio: "Palau-solità i Plegamans",
  //   provincia: "Barcelona",
  //   id: "08156",
  //   label: "Palau-solità i Plegamans (Barcelona)",
  // },
  // {
  //   municipio: "Pallejà",
  //   provincia: "Barcelona",
  //   id: "08157",
  //   label: "Pallejà (Barcelona)",
  // },
  // {
  //   municipio: "Zarza, La",
  //   provincia: "Badajoz",
  //   id: "06162",
  //   label: "Zarza, La (Badajoz)",
  // },
  // {
  //   municipio: "Prats de Lluçanès",
  //   provincia: "Barcelona",
  //   id: "08171",
  //   label: "Prats de Lluçanès (Barcelona)",
  // },
  // {
  //   municipio: "Valdelacalzada",
  //   provincia: "Badajoz",
  //   id: "06901",
  //   label: "Valdelacalzada (Badajoz)",
  // },
  // {
  //   municipio: "Pueblonuevo del Guadiana",
  //   provincia: "Badajoz",
  //   id: "06902",
  //   label: "Pueblonuevo del Guadiana (Badajoz)",
  // },
  // {
  //   municipio: "Algaida",
  //   provincia: "Islas Baleares",
  //   id: "07004",
  //   label: "Algaida (Islas Baleares)",
  // },
  // {
  //   municipio: "Alaró",
  //   provincia: "Islas Baleares",
  //   id: "07001",
  //   label: "Alaró (Islas Baleares)",
  // },
  // {
  //   municipio: "Banyalbufar",
  //   provincia: "Islas Baleares",
  //   id: "07007",
  //   label: "Banyalbufar (Islas Baleares)",
  // },
  // {
  //   municipio: "Premià de Mar",
  //   provincia: "Barcelona",
  //   id: "08172",
  //   label: "Premià de Mar (Barcelona)",
  // },
  // {
  //   municipio: "Binissalem",
  //   provincia: "Islas Baleares",
  //   id: "07008",
  //   label: "Binissalem (Islas Baleares)",
  // },
  // {
  //   municipio: "Búger",
  //   provincia: "Islas Baleares",
  //   id: "07009",
  //   label: "Búger (Islas Baleares)",
  // },
  // {
  //   municipio: "Santa Eufemia del Arroyo",
  //   provincia: "Valladolid",
  //   id: "47152",
  //   label: "Santa Eufemia del Arroyo (Valladolid)",
  // },
  // {
  //   municipio: "Bunyola",
  //   provincia: "Islas Baleares",
  //   id: "07010",
  //   label: "Bunyola (Islas Baleares)",
  // },
  // {
  //   municipio: "Arauzo de Salce",
  //   provincia: "Burgos",
  //   id: "09021",
  //   label: "Arauzo de Salce (Burgos)",
  // },
  // {
  //   municipio: "Esporles",
  //   provincia: "Islas Baleares",
  //   id: "07020",
  //   label: "Esporles (Islas Baleares)",
  // },
  // {
  //   municipio: "Estellencs",
  //   provincia: "Islas Baleares",
  //   id: "07021",
  //   label: "Estellencs (Islas Baleares)",
  // },
  // {
  //   municipio: "Marratxí",
  //   provincia: "Islas Baleares",
  //   id: "07036",
  //   label: "Marratxí (Islas Baleares)",
  // },
  // {
  //   municipio: "Fornalutx",
  //   provincia: "Islas Baleares",
  //   id: "07025",
  //   label: "Fornalutx (Islas Baleares)",
  // },
  // {
  //   municipio: "Cabanyes, Les",
  //   provincia: "Barcelona",
  //   id: "08027",
  //   label: "Cabanyes, Les (Barcelona)",
  // },
  // {
  //   municipio: "Inca",
  //   provincia: "Islas Baleares",
  //   id: "07027",
  //   label: "Inca (Islas Baleares)",
  // },
  // {
  //   municipio: "Lloret de Vistalegre",
  //   provincia: "Islas Baleares",
  //   id: "07028",
  //   label: "Lloret de Vistalegre (Islas Baleares)",
  // },
  // {
  //   municipio: "Montuïri",
  //   provincia: "Islas Baleares",
  //   id: "07038",
  //   label: "Montuïri (Islas Baleares)",
  // },
  // {
  //   municipio: "Muro",
  //   provincia: "Islas Baleares",
  //   id: "07039",
  //   label: "Muro (Islas Baleares)",
  // },
  // {
  //   municipio: "Caldes d'Estrac",
  //   provincia: "Barcelona",
  //   id: "08032",
  //   label: "Caldes d'Estrac (Barcelona)",
  // },
  // {
  //   municipio: "Petra",
  //   provincia: "Islas Baleares",
  //   id: "07041",
  //   label: "Petra (Islas Baleares)",
  // },
  // {
  //   municipio: "Canet de Mar",
  //   provincia: "Barcelona",
  //   id: "08040",
  //   label: "Canet de Mar (Barcelona)",
  // },
  // {
  //   municipio: "Porreres",
  //   provincia: "Islas Baleares",
  //   id: "07043",
  //   label: "Porreres (Islas Baleares)",
  // },
  // {
  //   municipio: "Puigpunyent",
  //   provincia: "Islas Baleares",
  //   id: "07045",
  //   label: "Puigpunyent (Islas Baleares)",
  // },
  // {
  //   municipio: "Pobla, Sa",
  //   provincia: "Islas Baleares",
  //   id: "07044",
  //   label: "Pobla, Sa (Islas Baleares)",
  // },
  // {
  //   municipio: "Santa Margalida",
  //   provincia: "Islas Baleares",
  //   id: "07055",
  //   label: "Santa Margalida (Islas Baleares)",
  // },
  // {
  //   municipio: "Arauzo de Torre",
  //   provincia: "Burgos",
  //   id: "09022",
  //   label: "Arauzo de Torre (Burgos)",
  // },
  // {
  //   municipio: "Sencelles",
  //   provincia: "Islas Baleares",
  //   id: "07047",
  //   label: "Sencelles (Islas Baleares)",
  // },
  // {
  //   municipio: "Santa María del Camí",
  //   provincia: "Islas Baleares",
  //   id: "07056",
  //   label: "Santa María del Camí (Islas Baleares)",
  // },
  // {
  //   municipio: "Sant Joan",
  //   provincia: "Islas Baleares",
  //   id: "07049",
  //   label: "Sant Joan (Islas Baleares)",
  // },
  // {
  //   municipio: "Sant Llorenç des Cardassar",
  //   provincia: "Islas Baleares",
  //   id: "07051",
  //   label: "Sant Llorenç des Cardassar (Islas Baleares)",
  // },
  // {
  //   municipio: "Santa Eugènia",
  //   provincia: "Islas Baleares",
  //   id: "07053",
  //   label: "Santa Eugènia (Islas Baleares)",
  // },
  // {
  //   municipio: "Selva",
  //   provincia: "Islas Baleares",
  //   id: "07058",
  //   label: "Selva (Islas Baleares)",
  // },
  // {
  //   municipio: "Sineu",
  //   provincia: "Islas Baleares",
  //   id: "07060",
  //   label: "Sineu (Islas Baleares)",
  // },
  // {
  //   municipio: "Son Servera",
  //   provincia: "Islas Baleares",
  //   id: "07062",
  //   label: "Son Servera (Islas Baleares)",
  // },
  // {
  //   municipio: "Ariany",
  //   provincia: "Islas Baleares",
  //   id: "07901",
  //   label: "Ariany (Islas Baleares)",
  // },
  // {
  //   municipio: "Abrera",
  //   provincia: "Barcelona",
  //   id: "08001",
  //   label: "Abrera (Barcelona)",
  // },
  // {
  //   municipio: "Ametlla del Vallès, L'",
  //   provincia: "Barcelona",
  //   id: "08005",
  //   label: "Ametlla del Vallès, L' (Barcelona)",
  // },
  // {
  //   municipio: "Alella",
  //   provincia: "Barcelona",
  //   id: "08003",
  //   label: "Alella (Barcelona)",
  // },
  // {
  //   municipio: "Alpens",
  //   provincia: "Barcelona",
  //   id: "08004",
  //   label: "Alpens (Barcelona)",
  // },
  // {
  //   municipio: "Arenys de Mar",
  //   provincia: "Barcelona",
  //   id: "08006",
  //   label: "Arenys de Mar (Barcelona)",
  // },
  // {
  //   municipio: "Arenys de Munt",
  //   provincia: "Barcelona",
  //   id: "08007",
  //   label: "Arenys de Munt (Barcelona)",
  // },
  // {
  //   municipio: "Argentona",
  //   provincia: "Barcelona",
  //   id: "08009",
  //   label: "Argentona (Barcelona)",
  // },
  // {
  //   municipio: "Artés",
  //   provincia: "Barcelona",
  //   id: "08010",
  //   label: "Artés (Barcelona)",
  // },
  // {
  //   municipio: "Avià",
  //   provincia: "Barcelona",
  //   id: "08011",
  //   label: "Avià (Barcelona)",
  // },
  // {
  //   municipio: "Aiguafreda",
  //   provincia: "Barcelona",
  //   id: "08014",
  //   label: "Aiguafreda (Barcelona)",
  // },
  // {
  //   municipio: "Badalona",
  //   provincia: "Barcelona",
  //   id: "08015",
  //   label: "Badalona (Barcelona)",
  // },
  // {
  //   municipio: "Balsareny",
  //   provincia: "Barcelona",
  //   id: "08018",
  //   label: "Balsareny (Barcelona)",
  // },
  // {
  //   municipio: "Balbases, Los",
  //   provincia: "Burgos",
  //   id: "09034",
  //   label: "Balbases, Los (Burgos)",
  // },
  // {
  //   municipio: "Bellprat",
  //   provincia: "Barcelona",
  //   id: "08021",
  //   label: "Bellprat (Barcelona)",
  // },
  // {
  //   municipio: "Berga",
  //   provincia: "Barcelona",
  //   id: "08022",
  //   label: "Berga (Barcelona)",
  // },
  // {
  //   municipio: "Bigues i Riells del Fai",
  //   provincia: "Barcelona",
  //   id: "08023",
  //   label: "Bigues i Riells del Fai (Barcelona)",
  // },
  // {
  //   municipio: "Herrín de Campos",
  //   provincia: "Valladolid",
  //   id: "47073",
  //   label: "Herrín de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Cabrera de Mar",
  //   provincia: "Barcelona",
  //   id: "08029",
  //   label: "Cabrera de Mar (Barcelona)",
  // },
  // {
  //   municipio: "Cabrils",
  //   provincia: "Barcelona",
  //   id: "08030",
  //   label: "Cabrils (Barcelona)",
  // },
  // {
  //   municipio: "Calaf",
  //   provincia: "Barcelona",
  //   id: "08031",
  //   label: "Calaf (Barcelona)",
  // },
  // {
  //   municipio: "Calders",
  //   provincia: "Barcelona",
  //   id: "08034",
  //   label: "Calders (Barcelona)",
  // },
  // {
  //   municipio: "Calella",
  //   provincia: "Barcelona",
  //   id: "08035",
  //   label: "Calella (Barcelona)",
  // },
  // {
  //   municipio: "Santa Coloma de Gramenet",
  //   provincia: "Barcelona",
  //   id: "08245",
  //   label: "Santa Coloma de Gramenet (Barcelona)",
  // },
  // {
  //   municipio: "Calldetenes",
  //   provincia: "Barcelona",
  //   id: "08037",
  //   label: "Calldetenes (Barcelona)",
  // },
  // {
  //   municipio: "Campins",
  //   provincia: "Barcelona",
  //   id: "08039",
  //   label: "Campins (Barcelona)",
  // },
  // {
  //   municipio: "Canovelles",
  //   provincia: "Barcelona",
  //   id: "08041",
  //   label: "Canovelles (Barcelona)",
  // },
  // {
  //   municipio: "Cànoves i Samalús",
  //   provincia: "Barcelona",
  //   id: "08042",
  //   label: "Cànoves i Samalús (Barcelona)",
  // },
  // {
  //   municipio: "Santa Eugènia de Berga",
  //   provincia: "Barcelona",
  //   id: "08246",
  //   label: "Santa Eugènia de Berga (Barcelona)",
  // },
  // {
  //   municipio: "Santa Eulàlia de Riuprimer",
  //   provincia: "Barcelona",
  //   id: "08247",
  //   label: "Santa Eulàlia de Riuprimer (Barcelona)",
  // },
  // {
  //   municipio: "Santa Eulàlia de Ronçana",
  //   provincia: "Barcelona",
  //   id: "08248",
  //   label: "Santa Eulàlia de Ronçana (Barcelona)",
  // },
  // {
  //   municipio: "Canyelles",
  //   provincia: "Barcelona",
  //   id: "08043",
  //   label: "Canyelles (Barcelona)",
  // },
  // {
  //   municipio: "Cardedeu",
  //   provincia: "Barcelona",
  //   id: "08046",
  //   label: "Cardedeu (Barcelona)",
  // },
  // {
  //   municipio: "Capellades",
  //   provincia: "Barcelona",
  //   id: "08044",
  //   label: "Capellades (Barcelona)",
  // },
  // {
  //   municipio: "Capolat",
  //   provincia: "Barcelona",
  //   id: "08045",
  //   label: "Capolat (Barcelona)",
  // },
  // {
  //   municipio: "Baños de Valdearados",
  //   provincia: "Burgos",
  //   id: "09035",
  //   label: "Baños de Valdearados (Burgos)",
  // },
  // {
  //   municipio: "Castellar del Riu",
  //   provincia: "Barcelona",
  //   id: "08050",
  //   label: "Castellar del Riu (Barcelona)",
  // },
  // {
  //   municipio: "Castellar del Vallès",
  //   provincia: "Barcelona",
  //   id: "08051",
  //   label: "Castellar del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Bañuelos de Bureba",
  //   provincia: "Burgos",
  //   id: "09036",
  //   label: "Bañuelos de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Castellcir",
  //   provincia: "Barcelona",
  //   id: "08055",
  //   label: "Castellcir (Barcelona)",
  // },
  // {
  //   municipio: "Barbadillo del Pez",
  //   provincia: "Burgos",
  //   id: "09039",
  //   label: "Barbadillo del Pez (Burgos)",
  // },
  // {
  //   municipio: "Castelldefels",
  //   provincia: "Barcelona",
  //   id: "08056",
  //   label: "Castelldefels (Barcelona)",
  // },
  // {
  //   municipio: "Castellet i la Gornal",
  //   provincia: "Barcelona",
  //   id: "08058",
  //   label: "Castellet i la Gornal (Barcelona)",
  // },
  // {
  //   municipio: "Castellfollit de Riubregós",
  //   provincia: "Barcelona",
  //   id: "08060",
  //   label: "Castellfollit de Riubregós (Barcelona)",
  // },
  // {
  //   municipio: "Sant Vicenç de Montalt",
  //   provincia: "Barcelona",
  //   id: "08264",
  //   label: "Sant Vicenç de Montalt (Barcelona)",
  // },
  // {
  //   municipio: "Castellgalí",
  //   provincia: "Barcelona",
  //   id: "08061",
  //   label: "Castellgalí (Barcelona)",
  // },
  // {
  //   municipio: "Castellterçol",
  //   provincia: "Barcelona",
  //   id: "08064",
  //   label: "Castellterçol (Barcelona)",
  // },
  // {
  //   municipio: "Sobremunt",
  //   provincia: "Barcelona",
  //   id: "08271",
  //   label: "Sobremunt (Barcelona)",
  // },
  // {
  //   municipio: "Castellví de la Marca",
  //   provincia: "Barcelona",
  //   id: "08065",
  //   label: "Castellví de la Marca (Barcelona)",
  // },
  // {
  //   municipio: "Centelles",
  //   provincia: "Barcelona",
  //   id: "08067",
  //   label: "Centelles (Barcelona)",
  // },
  // {
  //   municipio: "Collsuspina",
  //   provincia: "Barcelona",
  //   id: "08070",
  //   label: "Collsuspina (Barcelona)",
  // },
  // {
  //   municipio: "Esplugues de Llobregat",
  //   provincia: "Barcelona",
  //   id: "08077",
  //   label: "Esplugues de Llobregat (Barcelona)",
  // },
  // {
  //   municipio: "Vilanova i la Geltrú",
  //   provincia: "Barcelona",
  //   id: "08307",
  //   label: "Vilanova i la Geltrú (Barcelona)",
  // },
  // {
  //   municipio: "Cornellà de Llobregat",
  //   provincia: "Barcelona",
  //   id: "08073",
  //   label: "Cornellà de Llobregat (Barcelona)",
  // },
  // {
  //   municipio: "Fogars de Montclús",
  //   provincia: "Barcelona",
  //   id: "08081",
  //   label: "Fogars de Montclús (Barcelona)",
  // },
  // {
  //   municipio: "Fogars de la Selva",
  //   provincia: "Barcelona",
  //   id: "08082",
  //   label: "Fogars de la Selva (Barcelona)",
  // },
  // {
  //   municipio: "Vilanova del Vallès",
  //   provincia: "Barcelona",
  //   id: "08902",
  //   label: "Vilanova del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Cubelles",
  //   provincia: "Barcelona",
  //   id: "08074",
  //   label: "Cubelles (Barcelona)",
  // },
  // {
  //   municipio: "Sant Julià de Cerdanyola",
  //   provincia: "Barcelona",
  //   id: "08903",
  //   label: "Sant Julià de Cerdanyola (Barcelona)",
  // },
  // {
  //   municipio: "Barrio de Muñó",
  //   provincia: "Burgos",
  //   id: "09041",
  //   label: "Barrio de Muñó (Burgos)",
  // },
  // {
  //   municipio: "Dosrius",
  //   provincia: "Barcelona",
  //   id: "08075",
  //   label: "Dosrius (Barcelona)",
  // },
  // {
  //   municipio: "Val de San Martín",
  //   provincia: "Zaragoza",
  //   id: "50274",
  //   label: "Val de San Martín (Zaragoza)",
  // },
  // {
  //   municipio: "Espunyola, L'",
  //   provincia: "Barcelona",
  //   id: "08078",
  //   label: "Espunyola, L' (Barcelona)",
  // },
  // {
  //   municipio: "Estany, L'",
  //   provincia: "Barcelona",
  //   id: "08079",
  //   label: "Estany, L' (Barcelona)",
  // },
  // {
  //   municipio: "Fígols",
  //   provincia: "Barcelona",
  //   id: "08080",
  //   label: "Fígols (Barcelona)",
  // },
  // {
  //   municipio: "Folgueroles",
  //   provincia: "Barcelona",
  //   id: "08083",
  //   label: "Folgueroles (Barcelona)",
  // },
  // {
  //   municipio: "Belbimbre",
  //   provincia: "Burgos",
  //   id: "09047",
  //   label: "Belbimbre (Burgos)",
  // },
  // {
  //   municipio: "Fonollosa",
  //   provincia: "Barcelona",
  //   id: "08084",
  //   label: "Fonollosa (Barcelona)",
  // },
  // {
  //   municipio: "Font-rubí",
  //   provincia: "Barcelona",
  //   id: "08085",
  //   label: "Font-rubí (Barcelona)",
  // },
  // {
  //   municipio: "Granollers",
  //   provincia: "Barcelona",
  //   id: "08096",
  //   label: "Granollers (Barcelona)",
  // },
  // {
  //   municipio: "Franqueses del Vallès, Les",
  //   provincia: "Barcelona",
  //   id: "08086",
  //   label: "Franqueses del Vallès, Les (Barcelona)",
  // },
  // {
  //   municipio: "Gallifa",
  //   provincia: "Barcelona",
  //   id: "08087",
  //   label: "Gallifa (Barcelona)",
  // },
  // {
  //   municipio: "Llagosta, La",
  //   provincia: "Barcelona",
  //   id: "08105",
  //   label: "Llagosta, La (Barcelona)",
  // },
  // {
  //   municipio: "Llinars del Vallès",
  //   provincia: "Barcelona",
  //   id: "08106",
  //   label: "Llinars del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Gualba",
  //   provincia: "Barcelona",
  //   id: "08097",
  //   label: "Gualba (Barcelona)",
  // },
  // {
  //   municipio: "Garriga, La",
  //   provincia: "Barcelona",
  //   id: "08088",
  //   label: "Garriga, La (Barcelona)",
  // },
  // {
  //   municipio: "Gavà",
  //   provincia: "Barcelona",
  //   id: "08089",
  //   label: "Gavà (Barcelona)",
  // },
  // {
  //   municipio: "Gaià",
  //   provincia: "Barcelona",
  //   id: "08090",
  //   label: "Gaià (Barcelona)",
  // },
  // {
  //   municipio: "Gironella",
  //   provincia: "Barcelona",
  //   id: "08092",
  //   label: "Gironella (Barcelona)",
  // },
  // {
  //   municipio: "Vilaboa",
  //   provincia: "Pontevedra",
  //   id: "36058",
  //   label: "Vilaboa (Pontevedra)",
  // },
  // {
  //   municipio: "Vila de Cruces",
  //   provincia: "Pontevedra",
  //   id: "36059",
  //   label: "Vila de Cruces (Pontevedra)",
  // },
  // {
  //   municipio: "Granada, La",
  //   provincia: "Barcelona",
  //   id: "08094",
  //   label: "Granada, La (Barcelona)",
  // },
  // {
  //   municipio: "Lliçà d'Amunt",
  //   provincia: "Barcelona",
  //   id: "08107",
  //   label: "Lliçà d'Amunt (Barcelona)",
  // },
  // {
  //   municipio: "Malgrat de Mar",
  //   provincia: "Barcelona",
  //   id: "08110",
  //   label: "Malgrat de Mar (Barcelona)",
  // },
  // {
  //   municipio: "Malla",
  //   provincia: "Barcelona",
  //   id: "08111",
  //   label: "Malla (Barcelona)",
  // },
  // {
  //   municipio: "Manlleu",
  //   provincia: "Barcelona",
  //   id: "08112",
  //   label: "Manlleu (Barcelona)",
  // },
  // {
  //   municipio: "Manresa",
  //   provincia: "Barcelona",
  //   id: "08113",
  //   label: "Manresa (Barcelona)",
  // },
  // {
  //   municipio: "Martorell",
  //   provincia: "Barcelona",
  //   id: "08114",
  //   label: "Martorell (Barcelona)",
  // },
  // {
  //   municipio: "Martorelles",
  //   provincia: "Barcelona",
  //   id: "08115",
  //   label: "Martorelles (Barcelona)",
  // },
  // {
  //   municipio: "Masies de Roda, Les",
  //   provincia: "Barcelona",
  //   id: "08116",
  //   label: "Masies de Roda, Les (Barcelona)",
  // },
  // {
  //   municipio: "Masies de Voltregà, Les",
  //   provincia: "Barcelona",
  //   id: "08117",
  //   label: "Masies de Voltregà, Les (Barcelona)",
  // },
  // {
  //   municipio: "Masnou, El",
  //   provincia: "Barcelona",
  //   id: "08118",
  //   label: "Masnou, El (Barcelona)",
  // },
  // {
  //   municipio: "Mollet del Vallès",
  //   provincia: "Barcelona",
  //   id: "08124",
  //   label: "Mollet del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Montcada i Reixac",
  //   provincia: "Barcelona",
  //   id: "08125",
  //   label: "Montcada i Reixac (Barcelona)",
  // },
  // {
  //   municipio: "Montgat",
  //   provincia: "Barcelona",
  //   id: "08126",
  //   label: "Montgat (Barcelona)",
  // },
  // {
  //   municipio: "Monistrol de Calders",
  //   provincia: "Barcelona",
  //   id: "08128",
  //   label: "Monistrol de Calders (Barcelona)",
  // },
  // {
  //   municipio: "Montclar",
  //   provincia: "Barcelona",
  //   id: "08130",
  //   label: "Montclar (Barcelona)",
  // },
  // {
  //   municipio: "Berzosa de Bureba",
  //   provincia: "Burgos",
  //   id: "09052",
  //   label: "Berzosa de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Montesquiu",
  //   provincia: "Barcelona",
  //   id: "08131",
  //   label: "Montesquiu (Barcelona)",
  // },
  // {
  //   municipio: "Montmaneu",
  //   provincia: "Barcelona",
  //   id: "08133",
  //   label: "Montmaneu (Barcelona)",
  // },
  // {
  //   municipio: "Carrias",
  //   provincia: "Burgos",
  //   id: "09076",
  //   label: "Carrias (Burgos)",
  // },
  // {
  //   municipio: "Figaró-Montmany",
  //   provincia: "Barcelona",
  //   id: "08134",
  //   label: "Figaró-Montmany (Barcelona)",
  // },
  // {
  //   municipio: "Montmeló",
  //   provincia: "Barcelona",
  //   id: "08135",
  //   label: "Montmeló (Barcelona)",
  // },
  // {
  //   municipio: "Montornès del Vallès",
  //   provincia: "Barcelona",
  //   id: "08136",
  //   label: "Montornès del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Moià",
  //   provincia: "Barcelona",
  //   id: "08138",
  //   label: "Moià (Barcelona)",
  // },
  // {
  //   municipio: "Navarcles",
  //   provincia: "Barcelona",
  //   id: "08140",
  //   label: "Navarcles (Barcelona)",
  // },
  // {
  //   municipio: "Nou de Berguedà, La",
  //   provincia: "Barcelona",
  //   id: "08142",
  //   label: "Nou de Berguedà, La (Barcelona)",
  // },
  // {
  //   municipio: "Olesa de Bonesvalls",
  //   provincia: "Barcelona",
  //   id: "08146",
  //   label: "Olesa de Bonesvalls (Barcelona)",
  // },
  // {
  //   municipio: "Olesa de Montserrat",
  //   provincia: "Barcelona",
  //   id: "08147",
  //   label: "Olesa de Montserrat (Barcelona)",
  // },
  // {
  //   municipio: "Olivella",
  //   provincia: "Barcelona",
  //   id: "08148",
  //   label: "Olivella (Barcelona)",
  // },
  // {
  //   municipio: "Sant Adrià de Besòs",
  //   provincia: "Barcelona",
  //   id: "08194",
  //   label: "Sant Adrià de Besòs (Barcelona)",
  // },
  // {
  //   municipio: "Olost",
  //   provincia: "Barcelona",
  //   id: "08149",
  //   label: "Olost (Barcelona)",
  // },
  // {
  //   municipio: "Orís",
  //   provincia: "Barcelona",
  //   id: "08150",
  //   label: "Orís (Barcelona)",
  // },
  // {
  //   municipio: "Oristà",
  //   provincia: "Barcelona",
  //   id: "08151",
  //   label: "Oristà (Barcelona)",
  // },
  // {
  //   municipio: "Orpí",
  //   provincia: "Barcelona",
  //   id: "08152",
  //   label: "Orpí (Barcelona)",
  // },
  // {
  //   municipio: "Òrrius",
  //   provincia: "Barcelona",
  //   id: "08153",
  //   label: "Òrrius (Barcelona)",
  // },
  // {
  //   municipio: "Papiol, El",
  //   provincia: "Barcelona",
  //   id: "08158",
  //   label: "Papiol, El (Barcelona)",
  // },
  // {
  //   municipio: "Sant Antoni de Vilamajor",
  //   provincia: "Barcelona",
  //   id: "08198",
  //   label: "Sant Antoni de Vilamajor (Barcelona)",
  // },
  // {
  //   municipio: "Castildelgado",
  //   provincia: "Burgos",
  //   id: "09082",
  //   label: "Castildelgado (Burgos)",
  // },
  // {
  //   municipio: "Parets del Vallès",
  //   provincia: "Barcelona",
  //   id: "08159",
  //   label: "Parets del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Perafita",
  //   provincia: "Barcelona",
  //   id: "08160",
  //   label: "Perafita (Barcelona)",
  // },
  // {
  //   municipio: "Pineda de Mar",
  //   provincia: "Barcelona",
  //   id: "08163",
  //   label: "Pineda de Mar (Barcelona)",
  // },
  // {
  //   municipio: "Pla del Penedès, El",
  //   provincia: "Barcelona",
  //   id: "08164",
  //   label: "Pla del Penedès, El (Barcelona)",
  // },
  // {
  //   municipio: "Polinyà",
  //   provincia: "Barcelona",
  //   id: "08167",
  //   label: "Polinyà (Barcelona)",
  // },
  // {
  //   municipio: "Pontons",
  //   provincia: "Barcelona",
  //   id: "08168",
  //   label: "Pontons (Barcelona)",
  // },
  // {
  //   municipio: "Prat de Llobregat, El",
  //   provincia: "Barcelona",
  //   id: "08169",
  //   label: "Prat de Llobregat, El (Barcelona)",
  // },
  // {
  //   municipio: "Puigdàlber",
  //   provincia: "Barcelona",
  //   id: "08174",
  //   label: "Puigdàlber (Barcelona)",
  // },
  // {
  //   municipio: "Rajadell",
  //   provincia: "Barcelona",
  //   id: "08178",
  //   label: "Rajadell (Barcelona)",
  // },
  // {
  //   municipio: "Rellinars",
  //   provincia: "Barcelona",
  //   id: "08179",
  //   label: "Rellinars (Barcelona)",
  // },
  // {
  //   municipio: "Ripollet",
  //   provincia: "Barcelona",
  //   id: "08180",
  //   label: "Ripollet (Barcelona)",
  // },
  // {
  //   municipio: "Cueva de Roa, La",
  //   provincia: "Burgos",
  //   id: "09117",
  //   label: "Cueva de Roa, La (Burgos)",
  // },
  // {
  //   municipio: "Roca del Vallès, La",
  //   provincia: "Barcelona",
  //   id: "08181",
  //   label: "Roca del Vallès, La (Barcelona)",
  // },
  // {
  //   municipio: "Pont de Vilomara i Rocafort, El",
  //   provincia: "Barcelona",
  //   id: "08182",
  //   label: "Pont de Vilomara i Rocafort, El (Barcelona)",
  // },
  // {
  //   municipio: "Roda de Ter",
  //   provincia: "Barcelona",
  //   id: "08183",
  //   label: "Roda de Ter (Barcelona)",
  // },
  // {
  //   municipio: "Sabadell",
  //   provincia: "Barcelona",
  //   id: "08187",
  //   label: "Sabadell (Barcelona)",
  // },
  // {
  //   municipio: "Fuente Encalada",
  //   provincia: "Zamora",
  //   id: "49079",
  //   label: "Fuente Encalada (Zamora)",
  // },
  // {
  //   municipio: "Santpedor",
  //   provincia: "Barcelona",
  //   id: "08192",
  //   label: "Santpedor (Barcelona)",
  // },
  // {
  //   municipio: "Sesué",
  //   provincia: "Huesca",
  //   id: "22221",
  //   label: "Sesué (Huesca)",
  // },
  // {
  //   municipio: "Sant Agustí de Lluçanès",
  //   provincia: "Barcelona",
  //   id: "08195",
  //   label: "Sant Agustí de Lluçanès (Barcelona)",
  // },
  // {
  //   municipio: "Buniel",
  //   provincia: "Burgos",
  //   id: "09058",
  //   label: "Buniel (Burgos)",
  // },
  // {
  //   municipio: "Sant Andreu de la Barca",
  //   provincia: "Barcelona",
  //   id: "08196",
  //   label: "Sant Andreu de la Barca (Barcelona)",
  // },
  // {
  //   municipio: "Sant Andreu de Llavaneres",
  //   provincia: "Barcelona",
  //   id: "08197",
  //   label: "Sant Andreu de Llavaneres (Barcelona)",
  // },
  // {
  //   municipio: "Sant Boi de Llobregat",
  //   provincia: "Barcelona",
  //   id: "08200",
  //   label: "Sant Boi de Llobregat (Barcelona)",
  // },
  // {
  //   municipio: "Peñacaballera",
  //   provincia: "Salamanca",
  //   id: "37244",
  //   label: "Peñacaballera (Salamanca)",
  // },
  // {
  //   municipio: "Sant Boi de Lluçanès",
  //   provincia: "Barcelona",
  //   id: "08201",
  //   label: "Sant Boi de Lluçanès (Barcelona)",
  // },
  // {
  //   municipio: "Sant Cebrià de Vallalta",
  //   provincia: "Barcelona",
  //   id: "08203",
  //   label: "Sant Cebrià de Vallalta (Barcelona)",
  // },
  // {
  //   municipio: "Sant Climent de Llobregat",
  //   provincia: "Barcelona",
  //   id: "08204",
  //   label: "Sant Climent de Llobregat (Barcelona)",
  // },
  // {
  //   municipio: "Sant Cugat del Vallès",
  //   provincia: "Barcelona",
  //   id: "08205",
  //   label: "Sant Cugat del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Sant Cugat Sesgarrigues",
  //   provincia: "Barcelona",
  //   id: "08206",
  //   label: "Sant Cugat Sesgarrigues (Barcelona)",
  // },
  // {
  //   municipio: "Cardeñajimeno",
  //   provincia: "Burgos",
  //   id: "09074",
  //   label: "Cardeñajimeno (Burgos)",
  // },
  // {
  //   municipio: "Sant Esteve de Palautordera",
  //   provincia: "Barcelona",
  //   id: "08207",
  //   label: "Sant Esteve de Palautordera (Barcelona)",
  // },
  // {
  //   municipio: "Sant Esteve Sesrovires",
  //   provincia: "Barcelona",
  //   id: "08208",
  //   label: "Sant Esteve Sesrovires (Barcelona)",
  // },
  // {
  //   municipio: "Sant Fost de Campsentelles",
  //   provincia: "Barcelona",
  //   id: "08209",
  //   label: "Sant Fost de Campsentelles (Barcelona)",
  // },
  // {
  //   municipio: "Busto de Bureba",
  //   provincia: "Burgos",
  //   id: "09060",
  //   label: "Busto de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Sant Feliu de Codines",
  //   provincia: "Barcelona",
  //   id: "08210",
  //   label: "Sant Feliu de Codines (Barcelona)",
  // },
  // {
  //   municipio: "Sant Feliu de Llobregat",
  //   provincia: "Barcelona",
  //   id: "08211",
  //   label: "Sant Feliu de Llobregat (Barcelona)",
  // },
  // {
  //   municipio: "Sant Feliu Sasserra",
  //   provincia: "Barcelona",
  //   id: "08212",
  //   label: "Sant Feliu Sasserra (Barcelona)",
  // },
  // {
  //   municipio: "Cabañes de Esgueva",
  //   provincia: "Burgos",
  //   id: "09061",
  //   label: "Cabañes de Esgueva (Burgos)",
  // },
  // {
  //   municipio: "Sant Fruitós de Bages",
  //   provincia: "Barcelona",
  //   id: "08213",
  //   label: "Sant Fruitós de Bages (Barcelona)",
  // },
  // {
  //   municipio: "Sant Hipòlit de Voltregà",
  //   provincia: "Barcelona",
  //   id: "08215",
  //   label: "Sant Hipòlit de Voltregà (Barcelona)",
  // },
  // {
  //   municipio: "Fresno de Rodilla",
  //   provincia: "Burgos",
  //   id: "09133",
  //   label: "Fresno de Rodilla (Burgos)",
  // },
  // {
  //   municipio: "Sant Joan Despí",
  //   provincia: "Barcelona",
  //   id: "08217",
  //   label: "Sant Joan Despí (Barcelona)",
  // },
  // {
  //   municipio: "Badia del Vallès",
  //   provincia: "Barcelona",
  //   id: "08904",
  //   label: "Badia del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Sant Joan de Vilatorrada",
  //   provincia: "Barcelona",
  //   id: "08218",
  //   label: "Sant Joan de Vilatorrada (Barcelona)",
  // },
  // {
  //   municipio: "Vilassar de Mar",
  //   provincia: "Barcelona",
  //   id: "08219",
  //   label: "Vilassar de Mar (Barcelona)",
  // },
  // {
  //   municipio: "Sant Julià de Vilatorta",
  //   provincia: "Barcelona",
  //   id: "08220",
  //   label: "Sant Julià de Vilatorta (Barcelona)",
  // },
  // {
  //   municipio: "Sant Just Desvern",
  //   provincia: "Barcelona",
  //   id: "08221",
  //   label: "Sant Just Desvern (Barcelona)",
  // },
  // {
  //   municipio: "Sant Llorenç Savall",
  //   provincia: "Barcelona",
  //   id: "08223",
  //   label: "Sant Llorenç Savall (Barcelona)",
  // },
  // {
  //   municipio: "Palma de Cervelló, La",
  //   provincia: "Barcelona",
  //   id: "08905",
  //   label: "Palma de Cervelló, La (Barcelona)",
  // },
  // {
  //   municipio: "Abajas",
  //   provincia: "Burgos",
  //   id: "09001",
  //   label: "Abajas (Burgos)",
  // },
  // {
  //   municipio: "Cabezón de la Sierra",
  //   provincia: "Burgos",
  //   id: "09062",
  //   label: "Cabezón de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Sant Martí d'Albars",
  //   provincia: "Barcelona",
  //   id: "08225",
  //   label: "Sant Martí d'Albars (Barcelona)",
  // },
  // {
  //   municipio: "Sant Martí de Tous",
  //   provincia: "Barcelona",
  //   id: "08226",
  //   label: "Sant Martí de Tous (Barcelona)",
  // },
  // {
  //   municipio: "Sant Martí Sarroca",
  //   provincia: "Barcelona",
  //   id: "08227",
  //   label: "Sant Martí Sarroca (Barcelona)",
  // },
  // {
  //   municipio: "Sant Martí Sesgueioles",
  //   provincia: "Barcelona",
  //   id: "08228",
  //   label: "Sant Martí Sesgueioles (Barcelona)",
  // },
  // {
  //   municipio: "Sant Pere de Ribes",
  //   provincia: "Barcelona",
  //   id: "08231",
  //   label: "Sant Pere de Ribes (Barcelona)",
  // },
  // {
  //   municipio: "Premià de Dalt",
  //   provincia: "Barcelona",
  //   id: "08230",
  //   label: "Premià de Dalt (Barcelona)",
  // },
  // {
  //   municipio: "Sant Quirze de Besora",
  //   provincia: "Barcelona",
  //   id: "08237",
  //   label: "Sant Quirze de Besora (Barcelona)",
  // },
  // {
  //   municipio: "Sant Pere de Riudebitlles",
  //   provincia: "Barcelona",
  //   id: "08232",
  //   label: "Sant Pere de Riudebitlles (Barcelona)",
  // },
  // {
  //   municipio: "Sant Quirze Safaja",
  //   provincia: "Barcelona",
  //   id: "08239",
  //   label: "Sant Quirze Safaja (Barcelona)",
  // },
  // {
  //   municipio: "Sant Sadurní d'Anoia",
  //   provincia: "Barcelona",
  //   id: "08240",
  //   label: "Sant Sadurní d'Anoia (Barcelona)",
  // },
  // {
  //   municipio: "Sant Pere de Vilamajor",
  //   provincia: "Barcelona",
  //   id: "08234",
  //   label: "Sant Pere de Vilamajor (Barcelona)",
  // },
  // {
  //   municipio: "Sant Pol de Mar",
  //   provincia: "Barcelona",
  //   id: "08235",
  //   label: "Sant Pol de Mar (Barcelona)",
  // },
  // {
  //   municipio: "Sant Quintí de Mediona",
  //   provincia: "Barcelona",
  //   id: "08236",
  //   label: "Sant Quintí de Mediona (Barcelona)",
  // },
  // {
  //   municipio: "Sant Quirze del Vallès",
  //   provincia: "Barcelona",
  //   id: "08238",
  //   label: "Sant Quirze del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Santa Coloma de Cervelló",
  //   provincia: "Barcelona",
  //   id: "08244",
  //   label: "Santa Coloma de Cervelló (Barcelona)",
  // },
  // {
  //   municipio: "Adrada de Haza",
  //   provincia: "Burgos",
  //   id: "09003",
  //   label: "Adrada de Haza (Burgos)",
  // },
  // {
  //   municipio: "Santa Fe del Penedès",
  //   provincia: "Barcelona",
  //   id: "08249",
  //   label: "Santa Fe del Penedès (Barcelona)",
  // },
  // {
  //   municipio: "Santa Margarida i els Monjos",
  //   provincia: "Barcelona",
  //   id: "08251",
  //   label: "Santa Margarida i els Monjos (Barcelona)",
  // },
  // {
  //   municipio: "Barberà del Vallès",
  //   provincia: "Barcelona",
  //   id: "08252",
  //   label: "Barberà del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Santa Maria de Merlès",
  //   provincia: "Barcelona",
  //   id: "08255",
  //   label: "Santa Maria de Merlès (Barcelona)",
  // },
  // {
  //   municipio: "Santa Maria de Martorelles",
  //   provincia: "Barcelona",
  //   id: "08256",
  //   label: "Santa Maria de Martorelles (Barcelona)",
  // },
  // {
  //   municipio: "Cavia",
  //   provincia: "Burgos",
  //   id: "09063",
  //   label: "Cavia (Burgos)",
  // },
  // {
  //   municipio: "Santa Maria de Miralles",
  //   provincia: "Barcelona",
  //   id: "08257",
  //   label: "Santa Maria de Miralles (Barcelona)",
  // },
  // {
  //   municipio: "Santa Maria de Palautordera",
  //   provincia: "Barcelona",
  //   id: "08259",
  //   label: "Santa Maria de Palautordera (Barcelona)",
  // },
  // {
  //   municipio: "Aguas Cándidas",
  //   provincia: "Burgos",
  //   id: "09006",
  //   label: "Aguas Cándidas (Burgos)",
  // },
  // {
  //   municipio: "Santa Perpètua de Mogoda",
  //   provincia: "Barcelona",
  //   id: "08260",
  //   label: "Santa Perpètua de Mogoda (Barcelona)",
  // },
  // {
  //   municipio: "Sant Vicenç de Castellet",
  //   provincia: "Barcelona",
  //   id: "08262",
  //   label: "Sant Vicenç de Castellet (Barcelona)",
  // },
  // {
  //   municipio: "Sant Vicenç dels Horts",
  //   provincia: "Barcelona",
  //   id: "08263",
  //   label: "Sant Vicenç dels Horts (Barcelona)",
  // },
  // {
  //   municipio: "Sant Vicenç de Torelló",
  //   provincia: "Barcelona",
  //   id: "08265",
  //   label: "Sant Vicenç de Torelló (Barcelona)",
  // },
  // {
  //   municipio: "Cerdanyola del Vallès",
  //   provincia: "Barcelona",
  //   id: "08266",
  //   label: "Cerdanyola del Vallès (Barcelona)",
  // },
  // {
  //   municipio: "Sentmenat",
  //   provincia: "Barcelona",
  //   id: "08267",
  //   label: "Sentmenat (Barcelona)",
  // },
  // {
  //   municipio: "Cercs",
  //   provincia: "Barcelona",
  //   id: "08268",
  //   label: "Cercs (Barcelona)",
  // },
  // {
  //   municipio: "Gallega, La",
  //   provincia: "Burgos",
  //   id: "09144",
  //   label: "Gallega, La (Burgos)",
  // },
  // {
  //   municipio: "Sora",
  //   provincia: "Barcelona",
  //   id: "08272",
  //   label: "Sora (Barcelona)",
  // },
  // {
  //   municipio: "Súria",
  //   provincia: "Barcelona",
  //   id: "08274",
  //   label: "Súria (Barcelona)",
  // },
  // {
  //   municipio: "Talamanca",
  //   provincia: "Barcelona",
  //   id: "08277",
  //   label: "Talamanca (Barcelona)",
  // },
  // {
  //   municipio: "Teià",
  //   provincia: "Barcelona",
  //   id: "08281",
  //   label: "Teià (Barcelona)",
  // },
  // {
  //   municipio: "Tiana",
  //   provincia: "Barcelona",
  //   id: "08282",
  //   label: "Tiana (Barcelona)",
  // },
  // {
  //   municipio: "Tona",
  //   provincia: "Barcelona",
  //   id: "08283",
  //   label: "Tona (Barcelona)",
  // },
  // {
  //   municipio: "Aguilar de Bureba",
  //   provincia: "Burgos",
  //   id: "09007",
  //   label: "Aguilar de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Carrascosa de Abajo",
  //   provincia: "Soria",
  //   id: "42053",
  //   label: "Carrascosa de Abajo (Soria)",
  // },
  // {
  //   municipio: "Torelló",
  //   provincia: "Barcelona",
  //   id: "08285",
  //   label: "Torelló (Barcelona)",
  // },
  // {
  //   municipio: "Torre de Claramunt, La",
  //   provincia: "Barcelona",
  //   id: "08286",
  //   label: "Torre de Claramunt, La (Barcelona)",
  // },
  // {
  //   municipio: "Torrelavit",
  //   provincia: "Barcelona",
  //   id: "08287",
  //   label: "Torrelavit (Barcelona)",
  // },
  // {
  //   municipio: "Torrelles de Foix",
  //   provincia: "Barcelona",
  //   id: "08288",
  //   label: "Torrelles de Foix (Barcelona)",
  // },
  // {
  //   municipio: "Torrelles de Llobregat",
  //   provincia: "Barcelona",
  //   id: "08289",
  //   label: "Torrelles de Llobregat (Barcelona)",
  // },
  // {
  //   municipio: "Albillos",
  //   provincia: "Burgos",
  //   id: "09009",
  //   label: "Albillos (Burgos)",
  // },
  // {
  //   municipio: "Ullastrell",
  //   provincia: "Barcelona",
  //   id: "08290",
  //   label: "Ullastrell (Barcelona)",
  // },
  // {
  //   municipio: "Vacarisses",
  //   provincia: "Barcelona",
  //   id: "08291",
  //   label: "Vacarisses (Barcelona)",
  // },
  // {
  //   municipio: "Vallgorguina",
  //   provincia: "Barcelona",
  //   id: "08294",
  //   label: "Vallgorguina (Barcelona)",
  // },
  // {
  //   municipio: "Vallirana",
  //   provincia: "Barcelona",
  //   id: "08295",
  //   label: "Vallirana (Barcelona)",
  // },
  // {
  //   municipio: "Vallromanes",
  //   provincia: "Barcelona",
  //   id: "08296",
  //   label: "Vallromanes (Barcelona)",
  // },
  // {
  //   municipio: "Vic",
  //   provincia: "Barcelona",
  //   id: "08298",
  //   label: "Vic (Barcelona)",
  // },
  // {
  //   municipio: "Alcocero de Mola",
  //   provincia: "Burgos",
  //   id: "09010",
  //   label: "Alcocero de Mola (Burgos)",
  // },
  // {
  //   municipio: "Alfoz de Bricia",
  //   provincia: "Burgos",
  //   id: "09011",
  //   label: "Alfoz de Bricia (Burgos)",
  // },
  // {
  //   municipio: "Viladecavalls",
  //   provincia: "Barcelona",
  //   id: "08300",
  //   label: "Viladecavalls (Barcelona)",
  // },
  // {
  //   municipio: "Alfoz de Santa Gadea",
  //   provincia: "Burgos",
  //   id: "09012",
  //   label: "Alfoz de Santa Gadea (Burgos)",
  // },
  // {
  //   municipio: "Altable",
  //   provincia: "Burgos",
  //   id: "09013",
  //   label: "Altable (Burgos)",
  // },
  // {
  //   municipio: "Vilobí del Penedès",
  //   provincia: "Barcelona",
  //   id: "08304",
  //   label: "Vilobí del Penedès (Barcelona)",
  // },
  // {
  //   municipio: "Ameyugo",
  //   provincia: "Burgos",
  //   id: "09016",
  //   label: "Ameyugo (Burgos)",
  // },
  // {
  //   municipio: "Anguix",
  //   provincia: "Burgos",
  //   id: "09017",
  //   label: "Anguix (Burgos)",
  // },
  // {
  //   municipio: "Vilafranca del Penedès",
  //   provincia: "Barcelona",
  //   id: "08305",
  //   label: "Vilafranca del Penedès (Barcelona)",
  // },
  // {
  //   municipio: "Arandilla",
  //   provincia: "Burgos",
  //   id: "09019",
  //   label: "Arandilla (Burgos)",
  // },
  // {
  //   municipio: "Cuerlas, Las",
  //   provincia: "Zaragoza",
  //   id: "50091",
  //   label: "Cuerlas, Las (Zaragoza)",
  // },
  // {
  //   municipio: "Vilalba Sasserra",
  //   provincia: "Barcelona",
  //   id: "08306",
  //   label: "Vilalba Sasserra (Barcelona)",
  // },
  // {
  //   municipio: "Altos, Los",
  //   provincia: "Burgos",
  //   id: "09014",
  //   label: "Altos, Los (Burgos)",
  // },
  // {
  //   municipio: "Aranda de Duero",
  //   provincia: "Burgos",
  //   id: "09018",
  //   label: "Aranda de Duero (Burgos)",
  // },
  // {
  //   municipio: "Arcos",
  //   provincia: "Burgos",
  //   id: "09023",
  //   label: "Arcos (Burgos)",
  // },
  // {
  //   municipio: "Arenillas de Riopisuerga",
  //   provincia: "Burgos",
  //   id: "09024",
  //   label: "Arenillas de Riopisuerga (Burgos)",
  // },
  // {
  //   municipio: "Arija",
  //   provincia: "Burgos",
  //   id: "09025",
  //   label: "Arija (Burgos)",
  // },
  // {
  //   municipio: "Arlanzón",
  //   provincia: "Burgos",
  //   id: "09026",
  //   label: "Arlanzón (Burgos)",
  // },
  // {
  //   municipio: "Arraya de Oca",
  //   provincia: "Burgos",
  //   id: "09027",
  //   label: "Arraya de Oca (Burgos)",
  // },
  // {
  //   municipio: "Atapuerca",
  //   provincia: "Burgos",
  //   id: "09029",
  //   label: "Atapuerca (Burgos)",
  // },
  // {
  //   municipio: "Avellanosa de Muñó",
  //   provincia: "Burgos",
  //   id: "09032",
  //   label: "Avellanosa de Muñó (Burgos)",
  // },
  // {
  //   municipio: "Ausines, Los",
  //   provincia: "Burgos",
  //   id: "09030",
  //   label: "Ausines, Los (Burgos)",
  // },
  // {
  //   municipio: "Bahabón de Esgueva",
  //   provincia: "Burgos",
  //   id: "09033",
  //   label: "Bahabón de Esgueva (Burgos)",
  // },
  // {
  //   municipio: "Barbadillo de Herreros",
  //   provincia: "Burgos",
  //   id: "09037",
  //   label: "Barbadillo de Herreros (Burgos)",
  // },
  // {
  //   municipio: "Barbadillo del Mercado",
  //   provincia: "Burgos",
  //   id: "09038",
  //   label: "Barbadillo del Mercado (Burgos)",
  // },
  // {
  //   municipio: "Barrios de Bureba, Los",
  //   provincia: "Burgos",
  //   id: "09043",
  //   label: "Barrios de Bureba, Los (Burgos)",
  // },
  // {
  //   municipio: "Barrios de Colina",
  //   provincia: "Burgos",
  //   id: "09044",
  //   label: "Barrios de Colina (Burgos)",
  // },
  // {
  //   municipio: "Basconcillos del Tozo",
  //   provincia: "Burgos",
  //   id: "09045",
  //   label: "Basconcillos del Tozo (Burgos)",
  // },
  // {
  //   municipio: "Bascuñana",
  //   provincia: "Burgos",
  //   id: "09046",
  //   label: "Bascuñana (Burgos)",
  // },
  // {
  //   municipio: "Berberana",
  //   provincia: "Burgos",
  //   id: "09050",
  //   label: "Berberana (Burgos)",
  // },
  // {
  //   municipio: "Berlangas de Roa",
  //   provincia: "Burgos",
  //   id: "09051",
  //   label: "Berlangas de Roa (Burgos)",
  // },
  // {
  //   municipio: "Bozoó",
  //   provincia: "Burgos",
  //   id: "09054",
  //   label: "Bozoó (Burgos)",
  // },
  // {
  //   municipio: "Brazacorta",
  //   provincia: "Burgos",
  //   id: "09055",
  //   label: "Brazacorta (Burgos)",
  // },
  // {
  //   municipio: "Briviesca",
  //   provincia: "Burgos",
  //   id: "09056",
  //   label: "Briviesca (Burgos)",
  // },
  // {
  //   municipio: "Bugedo",
  //   provincia: "Burgos",
  //   id: "09057",
  //   label: "Bugedo (Burgos)",
  // },
  // {
  //   municipio: "Burgos",
  //   provincia: "Burgos",
  //   id: "09059",
  //   label: "Burgos (Burgos)",
  // },
  // {
  //   municipio: "Canicosa de la Sierra",
  //   provincia: "Burgos",
  //   id: "09067",
  //   label: "Canicosa de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Caleruega",
  //   provincia: "Burgos",
  //   id: "09064",
  //   label: "Caleruega (Burgos)",
  // },
  // {
  //   municipio: "Campillo de Aranda",
  //   provincia: "Burgos",
  //   id: "09065",
  //   label: "Campillo de Aranda (Burgos)",
  // },
  // {
  //   municipio: "Campolara",
  //   provincia: "Burgos",
  //   id: "09066",
  //   label: "Campolara (Burgos)",
  // },
  // {
  //   municipio: "Anaya",
  //   provincia: "Segovia",
  //   id: "40017",
  //   label: "Anaya (Segovia)",
  // },
  // {
  //   municipio: "Ciruelos de Cervera",
  //   provincia: "Burgos",
  //   id: "09105",
  //   label: "Ciruelos de Cervera (Burgos)",
  // },
  // {
  //   municipio: "Cantabrana",
  //   provincia: "Burgos",
  //   id: "09068",
  //   label: "Cantabrana (Burgos)",
  // },
  // {
  //   municipio: "Carazo",
  //   provincia: "Burgos",
  //   id: "09070",
  //   label: "Carazo (Burgos)",
  // },
  // {
  //   municipio: "Cardeñuela Riopico",
  //   provincia: "Burgos",
  //   id: "09075",
  //   label: "Cardeñuela Riopico (Burgos)",
  // },
  // {
  //   municipio: "Carcedo de Bureba",
  //   provincia: "Burgos",
  //   id: "09071",
  //   label: "Carcedo de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Carcedo de Burgos",
  //   provincia: "Burgos",
  //   id: "09072",
  //   label: "Carcedo de Burgos (Burgos)",
  // },
  // {
  //   municipio: "Cardeñadijo",
  //   provincia: "Burgos",
  //   id: "09073",
  //   label: "Cardeñadijo (Burgos)",
  // },
  // {
  //   municipio: "Castrillo de la Vega",
  //   provincia: "Burgos",
  //   id: "09085",
  //   label: "Castrillo de la Vega (Burgos)",
  // },
  // {
  //   municipio: "Castrillo del Val",
  //   provincia: "Burgos",
  //   id: "09086",
  //   label: "Castrillo del Val (Burgos)",
  // },
  // {
  //   municipio: "Celada del Camino",
  //   provincia: "Burgos",
  //   id: "09095",
  //   label: "Celada del Camino (Burgos)",
  // },
  // {
  //   municipio: "Castrillo de Riopisuerga",
  //   provincia: "Burgos",
  //   id: "09088",
  //   label: "Castrillo de Riopisuerga (Burgos)",
  // },
  // {
  //   municipio: "Cascajares de Bureba",
  //   provincia: "Burgos",
  //   id: "09077",
  //   label: "Cascajares de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Cascajares de la Sierra",
  //   provincia: "Burgos",
  //   id: "09078",
  //   label: "Cascajares de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Castellanos de Castro",
  //   provincia: "Burgos",
  //   id: "09079",
  //   label: "Castellanos de Castro (Burgos)",
  // },
  // {
  //   municipio: "Castil de Peones",
  //   provincia: "Burgos",
  //   id: "09083",
  //   label: "Castil de Peones (Burgos)",
  // },
  // {
  //   municipio: "Castrillo de la Reina",
  //   provincia: "Burgos",
  //   id: "09084",
  //   label: "Castrillo de la Reina (Burgos)",
  // },
  // {
  //   municipio: "Castrillo Mota de Judíos",
  //   provincia: "Burgos",
  //   id: "09090",
  //   label: "Castrillo Mota de Judíos (Burgos)",
  // },
  // {
  //   municipio: "Ciadoncha",
  //   provincia: "Burgos",
  //   id: "09101",
  //   label: "Ciadoncha (Burgos)",
  // },
  // {
  //   municipio: "Castrojeriz",
  //   provincia: "Burgos",
  //   id: "09091",
  //   label: "Castrojeriz (Burgos)",
  // },
  // {
  //   municipio: "Cayuela",
  //   provincia: "Burgos",
  //   id: "09093",
  //   label: "Cayuela (Burgos)",
  // },
  // {
  //   municipio: "Cebrecos",
  //   provincia: "Burgos",
  //   id: "09094",
  //   label: "Cebrecos (Burgos)",
  // },
  // {
  //   municipio: "Pedrosa del Páramo",
  //   provincia: "Burgos",
  //   id: "09257",
  //   label: "Pedrosa del Páramo (Burgos)",
  // },
  // {
  //   municipio: "Cerezo de Río Tirón",
  //   provincia: "Burgos",
  //   id: "09098",
  //   label: "Cerezo de Río Tirón (Burgos)",
  // },
  // {
  //   municipio: "Cerratón de Juarros",
  //   provincia: "Burgos",
  //   id: "09100",
  //   label: "Cerratón de Juarros (Burgos)",
  // },
  // {
  //   municipio: "Cillaperlata",
  //   provincia: "Burgos",
  //   id: "09102",
  //   label: "Cillaperlata (Burgos)",
  // },
  // {
  //   municipio: "Cilleruelo de Abajo",
  //   provincia: "Burgos",
  //   id: "09103",
  //   label: "Cilleruelo de Abajo (Burgos)",
  // },
  // {
  //   municipio: "Cilleruelo de Arriba",
  //   provincia: "Burgos",
  //   id: "09104",
  //   label: "Cilleruelo de Arriba (Burgos)",
  // },
  // {
  //   municipio: "Cogollos",
  //   provincia: "Burgos",
  //   id: "09108",
  //   label: "Cogollos (Burgos)",
  // },
  // {
  //   municipio: "Contreras",
  //   provincia: "Burgos",
  //   id: "09110",
  //   label: "Contreras (Burgos)",
  // },
  // {
  //   municipio: "Cuevas de San Clemente",
  //   provincia: "Burgos",
  //   id: "09119",
  //   label: "Cuevas de San Clemente (Burgos)",
  // },
  // {
  //   municipio: "Coruña del Conde",
  //   provincia: "Burgos",
  //   id: "09112",
  //   label: "Coruña del Conde (Burgos)",
  // },
  // {
  //   municipio: "Cubillo del Campo",
  //   provincia: "Burgos",
  //   id: "09114",
  //   label: "Cubillo del Campo (Burgos)",
  // },
  // {
  //   municipio: "Añe",
  //   provincia: "Segovia",
  //   id: "40018",
  //   label: "Añe (Segovia)",
  // },
  // {
  //   municipio: "Covarrubias",
  //   provincia: "Burgos",
  //   id: "09113",
  //   label: "Covarrubias (Burgos)",
  // },
  // {
  //   municipio: "Cubo de Bureba",
  //   provincia: "Burgos",
  //   id: "09115",
  //   label: "Cubo de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Encío",
  //   provincia: "Burgos",
  //   id: "09120",
  //   label: "Encío (Burgos)",
  // },
  // {
  //   municipio: "Espinosa de Cervera",
  //   provincia: "Burgos",
  //   id: "09122",
  //   label: "Espinosa de Cervera (Burgos)",
  // },
  // {
  //   municipio: "Espinosa del Camino",
  //   provincia: "Burgos",
  //   id: "09123",
  //   label: "Espinosa del Camino (Burgos)",
  // },
  // {
  //   municipio: "Espinosa de los Monteros",
  //   provincia: "Burgos",
  //   id: "09124",
  //   label: "Espinosa de los Monteros (Burgos)",
  // },
  // {
  //   municipio: "Frandovínez",
  //   provincia: "Burgos",
  //   id: "09128",
  //   label: "Frandovínez (Burgos)",
  // },
  // {
  //   municipio: "Estépar",
  //   provincia: "Burgos",
  //   id: "09125",
  //   label: "Estépar (Burgos)",
  // },
  // {
  //   municipio: "Fontioso",
  //   provincia: "Burgos",
  //   id: "09127",
  //   label: "Fontioso (Burgos)",
  // },
  // {
  //   municipio: "Fresneda de la Sierra Tirón",
  //   provincia: "Burgos",
  //   id: "09129",
  //   label: "Fresneda de la Sierra Tirón (Burgos)",
  // },
  // {
  //   municipio: "Fresneña",
  //   provincia: "Burgos",
  //   id: "09130",
  //   label: "Fresneña (Burgos)",
  // },
  // {
  //   municipio: "Pinilla de los Barruecos",
  //   provincia: "Burgos",
  //   id: "09268",
  //   label: "Pinilla de los Barruecos (Burgos)",
  // },
  // {
  //   municipio: "Galbarros",
  //   provincia: "Burgos",
  //   id: "09143",
  //   label: "Galbarros (Burgos)",
  // },
  // {
  //   municipio: "Frías",
  //   provincia: "Burgos",
  //   id: "09134",
  //   label: "Frías (Burgos)",
  // },
  // {
  //   municipio: "Fuentebureba",
  //   provincia: "Burgos",
  //   id: "09135",
  //   label: "Fuentebureba (Burgos)",
  // },
  // {
  //   municipio: "Fuentecén",
  //   provincia: "Burgos",
  //   id: "09136",
  //   label: "Fuentecén (Burgos)",
  // },
  // {
  //   municipio: "Fuentelcésped",
  //   provincia: "Burgos",
  //   id: "09137",
  //   label: "Fuentelcésped (Burgos)",
  // },
  // {
  //   municipio: "Fuentelisendo",
  //   provincia: "Burgos",
  //   id: "09138",
  //   label: "Fuentelisendo (Burgos)",
  // },
  // {
  //   municipio: "Fuentemolinos",
  //   provincia: "Burgos",
  //   id: "09139",
  //   label: "Fuentemolinos (Burgos)",
  // },
  // {
  //   municipio: "Fuentenebro",
  //   provincia: "Burgos",
  //   id: "09140",
  //   label: "Fuentenebro (Burgos)",
  // },
  // {
  //   municipio: "Fuentespina",
  //   provincia: "Burgos",
  //   id: "09141",
  //   label: "Fuentespina (Burgos)",
  // },
  // {
  //   municipio: "Carrascosa de la Sierra",
  //   provincia: "Soria",
  //   id: "42054",
  //   label: "Carrascosa de la Sierra (Soria)",
  // },
  // {
  //   municipio: "Grijalba",
  //   provincia: "Burgos",
  //   id: "09148",
  //   label: "Grijalba (Burgos)",
  // },
  // {
  //   municipio: "Grisaleña",
  //   provincia: "Burgos",
  //   id: "09149",
  //   label: "Grisaleña (Burgos)",
  // },
  // {
  //   municipio: "Gumiel de Izán",
  //   provincia: "Burgos",
  //   id: "09151",
  //   label: "Gumiel de Izán (Burgos)",
  // },
  // {
  //   municipio: "Gumiel de Mercado",
  //   provincia: "Burgos",
  //   id: "09152",
  //   label: "Gumiel de Mercado (Burgos)",
  // },
  // {
  //   municipio: "Hacinas",
  //   provincia: "Burgos",
  //   id: "09154",
  //   label: "Hacinas (Burgos)",
  // },
  // {
  //   municipio: "Haza",
  //   provincia: "Burgos",
  //   id: "09155",
  //   label: "Haza (Burgos)",
  // },
  // {
  //   municipio: "Cueva de Ágreda",
  //   provincia: "Soria",
  //   id: "42073",
  //   label: "Cueva de Ágreda (Soria)",
  // },
  // {
  //   municipio: "Nebreda",
  //   provincia: "Burgos",
  //   id: "09231",
  //   label: "Nebreda (Burgos)",
  // },
  // {
  //   municipio: "Hontanas",
  //   provincia: "Burgos",
  //   id: "09159",
  //   label: "Hontanas (Burgos)",
  // },
  // {
  //   municipio: "Hontangas",
  //   provincia: "Burgos",
  //   id: "09160",
  //   label: "Hontangas (Burgos)",
  // },
  // {
  //   municipio: "Horra, La",
  //   provincia: "Burgos",
  //   id: "09168",
  //   label: "Horra, La (Burgos)",
  // },
  // {
  //   municipio: "Hontoria de la Cantera",
  //   provincia: "Burgos",
  //   id: "09162",
  //   label: "Hontoria de la Cantera (Burgos)",
  // },
  // {
  //   municipio: "Hontoria del Pinar",
  //   provincia: "Burgos",
  //   id: "09163",
  //   label: "Hontoria del Pinar (Burgos)",
  // },
  // {
  //   municipio: "Hontoria de Valdearados",
  //   provincia: "Burgos",
  //   id: "09164",
  //   label: "Hontoria de Valdearados (Burgos)",
  // },
  // {
  //   municipio: "Hormazas, Las",
  //   provincia: "Burgos",
  //   id: "09166",
  //   label: "Hormazas, Las (Burgos)",
  // },
  // {
  //   municipio: "Hornillos del Camino",
  //   provincia: "Burgos",
  //   id: "09167",
  //   label: "Hornillos del Camino (Burgos)",
  // },
  // {
  //   municipio: "Hortigüela",
  //   provincia: "Burgos",
  //   id: "09169",
  //   label: "Hortigüela (Burgos)",
  // },
  // {
  //   municipio: "Hoyales de Roa",
  //   provincia: "Burgos",
  //   id: "09170",
  //   label: "Hoyales de Roa (Burgos)",
  // },
  // {
  //   municipio: "Huérmeces",
  //   provincia: "Burgos",
  //   id: "09172",
  //   label: "Huérmeces (Burgos)",
  // },
  // {
  //   municipio: "Huerta de Arriba",
  //   provincia: "Burgos",
  //   id: "09173",
  //   label: "Huerta de Arriba (Burgos)",
  // },
  // {
  //   municipio: "Huerta de Rey",
  //   provincia: "Burgos",
  //   id: "09174",
  //   label: "Huerta de Rey (Burgos)",
  // },
  // {
  //   municipio: "Santa Croya de Tera",
  //   provincia: "Zamora",
  //   id: "49201",
  //   label: "Santa Croya de Tera (Zamora)",
  // },
  // {
  //   municipio: "Humada",
  //   provincia: "Burgos",
  //   id: "09175",
  //   label: "Humada (Burgos)",
  // },
  // {
  //   municipio: "Hurones",
  //   provincia: "Burgos",
  //   id: "09176",
  //   label: "Hurones (Burgos)",
  // },
  // {
  //   municipio: "Ibeas de Juarros",
  //   provincia: "Burgos",
  //   id: "09177",
  //   label: "Ibeas de Juarros (Burgos)",
  // },
  // {
  //   municipio: "Arahuetes",
  //   provincia: "Segovia",
  //   id: "40019",
  //   label: "Arahuetes (Segovia)",
  // },
  // {
  //   municipio: "Iglesias",
  //   provincia: "Burgos",
  //   id: "09180",
  //   label: "Iglesias (Burgos)",
  // },
  // {
  //   municipio: "Isar",
  //   provincia: "Burgos",
  //   id: "09181",
  //   label: "Isar (Burgos)",
  // },
  // {
  //   municipio: "Itero del Castillo",
  //   provincia: "Burgos",
  //   id: "09182",
  //   label: "Itero del Castillo (Burgos)",
  // },
  // {
  //   municipio: "Jaramillo de la Fuente",
  //   provincia: "Burgos",
  //   id: "09183",
  //   label: "Jaramillo de la Fuente (Burgos)",
  // },
  // {
  //   municipio: "Jaramillo Quemado",
  //   provincia: "Burgos",
  //   id: "09184",
  //   label: "Jaramillo Quemado (Burgos)",
  // },
  // {
  //   municipio: "Nava de Roa",
  //   provincia: "Burgos",
  //   id: "09229",
  //   label: "Nava de Roa (Burgos)",
  // },
  // {
  //   municipio: "Junta de Traslaloma",
  //   provincia: "Burgos",
  //   id: "09189",
  //   label: "Junta de Traslaloma (Burgos)",
  // },
  // {
  //   municipio: "Navas de Bureba",
  //   provincia: "Burgos",
  //   id: "09230",
  //   label: "Navas de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Junta de Villalba de Losa",
  //   provincia: "Burgos",
  //   id: "09190",
  //   label: "Junta de Villalba de Losa (Burgos)",
  // },
  // {
  //   municipio: "Jurisdicción de Lara",
  //   provincia: "Burgos",
  //   id: "09191",
  //   label: "Jurisdicción de Lara (Burgos)",
  // },
  // {
  //   municipio: "Jurisdicción de San Zadornil",
  //   provincia: "Burgos",
  //   id: "09192",
  //   label: "Jurisdicción de San Zadornil (Burgos)",
  // },
  // {
  //   municipio: "Lerma",
  //   provincia: "Burgos",
  //   id: "09194",
  //   label: "Lerma (Burgos)",
  // },
  // {
  //   municipio: "Llano de Bureba",
  //   provincia: "Burgos",
  //   id: "09195",
  //   label: "Llano de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Madrigal del Monte",
  //   provincia: "Burgos",
  //   id: "09196",
  //   label: "Madrigal del Monte (Burgos)",
  // },
  // {
  //   municipio: "Lazagurría",
  //   provincia: "Navarra",
  //   id: "31145",
  //   label: "Lazagurría (Navarra)",
  // },
  // {
  //   municipio: "Madrigalejo del Monte",
  //   provincia: "Burgos",
  //   id: "09197",
  //   label: "Madrigalejo del Monte (Burgos)",
  // },
  // {
  //   municipio: "Mahamud",
  //   provincia: "Burgos",
  //   id: "09198",
  //   label: "Mahamud (Burgos)",
  // },
  // {
  //   municipio: "Mambrilla de Castrejón",
  //   provincia: "Burgos",
  //   id: "09199",
  //   label: "Mambrilla de Castrejón (Burgos)",
  // },
  // {
  //   municipio: "Mambrillas de Lara",
  //   provincia: "Burgos",
  //   id: "09200",
  //   label: "Mambrillas de Lara (Burgos)",
  // },
  // {
  //   municipio: "Mamolar",
  //   provincia: "Burgos",
  //   id: "09201",
  //   label: "Mamolar (Burgos)",
  // },
  // {
  //   municipio: "Miraveche",
  //   provincia: "Burgos",
  //   id: "09220",
  //   label: "Miraveche (Burgos)",
  // },
  // {
  //   municipio: "Villalonso",
  //   provincia: "Zamora",
  //   id: "49249",
  //   label: "Villalonso (Zamora)",
  // },
  // {
  //   municipio: "Daroca",
  //   provincia: "Zaragoza",
  //   id: "50094",
  //   label: "Daroca (Zaragoza)",
  // },
  // {
  //   municipio: "Manciles",
  //   provincia: "Burgos",
  //   id: "09202",
  //   label: "Manciles (Burgos)",
  // },
  // {
  //   municipio: "Mazuela",
  //   provincia: "Burgos",
  //   id: "09206",
  //   label: "Mazuela (Burgos)",
  // },
  // {
  //   municipio: "Mecerreyes",
  //   provincia: "Burgos",
  //   id: "09208",
  //   label: "Mecerreyes (Burgos)",
  // },
  // {
  //   municipio: "Medina de Pomar",
  //   provincia: "Burgos",
  //   id: "09209",
  //   label: "Medina de Pomar (Burgos)",
  // },
  // {
  //   municipio: "Modúbar de la Emparedada",
  //   provincia: "Burgos",
  //   id: "09221",
  //   label: "Modúbar de la Emparedada (Burgos)",
  // },
  // {
  //   municipio: "Monasterio de la Sierra",
  //   provincia: "Burgos",
  //   id: "09223",
  //   label: "Monasterio de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Melgar de Fernamental",
  //   provincia: "Burgos",
  //   id: "09211",
  //   label: "Melgar de Fernamental (Burgos)",
  // },
  // {
  //   municipio: "Merindad de Cuesta-Urria",
  //   provincia: "Burgos",
  //   id: "09213",
  //   label: "Merindad de Cuesta-Urria (Burgos)",
  // },
  // {
  //   municipio: "Oquillas",
  //   provincia: "Burgos",
  //   id: "09239",
  //   label: "Oquillas (Burgos)",
  // },
  // {
  //   municipio: "Merindad de Montija",
  //   provincia: "Burgos",
  //   id: "09214",
  //   label: "Merindad de Montija (Burgos)",
  // },
  // {
  //   municipio: "Monasterio de Rodilla",
  //   provincia: "Burgos",
  //   id: "09224",
  //   label: "Monasterio de Rodilla (Burgos)",
  // },
  // {
  //   municipio: "Merindad de Sotoscueva",
  //   provincia: "Burgos",
  //   id: "09215",
  //   label: "Merindad de Sotoscueva (Burgos)",
  // },
  // {
  //   municipio: "Moncalvillo",
  //   provincia: "Burgos",
  //   id: "09225",
  //   label: "Moncalvillo (Burgos)",
  // },
  // {
  //   municipio: "Orbaneja Riopico",
  //   provincia: "Burgos",
  //   id: "09241",
  //   label: "Orbaneja Riopico (Burgos)",
  // },
  // {
  //   municipio: "Merindad de Valdeporres",
  //   provincia: "Burgos",
  //   id: "09216",
  //   label: "Merindad de Valdeporres (Burgos)",
  // },
  // {
  //   municipio: "Padilla de Arriba",
  //   provincia: "Burgos",
  //   id: "09243",
  //   label: "Padilla de Arriba (Burgos)",
  // },
  // {
  //   municipio: "Merindad de Valdivielso",
  //   provincia: "Burgos",
  //   id: "09217",
  //   label: "Merindad de Valdivielso (Burgos)",
  // },
  // {
  //   municipio: "Milagros",
  //   provincia: "Burgos",
  //   id: "09218",
  //   label: "Milagros (Burgos)",
  // },
  // {
  //   municipio: "Miranda de Ebro",
  //   provincia: "Burgos",
  //   id: "09219",
  //   label: "Miranda de Ebro (Burgos)",
  // },
  // {
  //   municipio: "Monterrubio de la Demanda",
  //   provincia: "Burgos",
  //   id: "09226",
  //   label: "Monterrubio de la Demanda (Burgos)",
  // },
  // {
  //   municipio: "Montorio",
  //   provincia: "Burgos",
  //   id: "09227",
  //   label: "Montorio (Burgos)",
  // },
  // {
  //   municipio: "Moradillo de Roa",
  //   provincia: "Burgos",
  //   id: "09228",
  //   label: "Moradillo de Roa (Burgos)",
  // },
  // {
  //   municipio: "Padrones de Bureba",
  //   provincia: "Burgos",
  //   id: "09244",
  //   label: "Padrones de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Neila",
  //   provincia: "Burgos",
  //   id: "09232",
  //   label: "Neila (Burgos)",
  // },
  // {
  //   municipio: "Olmedillo de Roa",
  //   provincia: "Burgos",
  //   id: "09235",
  //   label: "Olmedillo de Roa (Burgos)",
  // },
  // {
  //   municipio: "Olmillos de Muñó",
  //   provincia: "Burgos",
  //   id: "09236",
  //   label: "Olmillos de Muñó (Burgos)",
  // },
  // {
  //   municipio: "Oña",
  //   provincia: "Burgos",
  //   id: "09238",
  //   label: "Oña (Burgos)",
  // },
  // {
  //   municipio: "Padilla de Abajo",
  //   provincia: "Burgos",
  //   id: "09242",
  //   label: "Padilla de Abajo (Burgos)",
  // },
  // {
  //   municipio: "Rabanera del Pinar",
  //   provincia: "Burgos",
  //   id: "09302",
  //   label: "Rabanera del Pinar (Burgos)",
  // },
  // {
  //   municipio: "Palazuelos de la Sierra",
  //   provincia: "Burgos",
  //   id: "09248",
  //   label: "Palazuelos de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Rábanos",
  //   provincia: "Burgos",
  //   id: "09303",
  //   label: "Rábanos (Burgos)",
  // },
  // {
  //   municipio: "Villagalijo",
  //   provincia: "Burgos",
  //   id: "09433",
  //   label: "Villagalijo (Burgos)",
  // },
  // {
  //   municipio: "Palacios de la Sierra",
  //   provincia: "Burgos",
  //   id: "09246",
  //   label: "Palacios de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Palacios de Riopisuerga",
  //   provincia: "Burgos",
  //   id: "09247",
  //   label: "Palacios de Riopisuerga (Burgos)",
  // },
  // {
  //   municipio: "Palazuelos de Muñó",
  //   provincia: "Burgos",
  //   id: "09249",
  //   label: "Palazuelos de Muñó (Burgos)",
  // },
  // {
  //   municipio: "Pampliega",
  //   provincia: "Burgos",
  //   id: "09250",
  //   label: "Pampliega (Burgos)",
  // },
  // {
  //   municipio: "Rollamienta",
  //   provincia: "Soria",
  //   id: "42159",
  //   label: "Rollamienta (Soria)",
  // },
  // {
  //   municipio: "Pancorbo",
  //   provincia: "Burgos",
  //   id: "09251",
  //   label: "Pancorbo (Burgos)",
  // },
  // {
  //   municipio: "Pardilla",
  //   provincia: "Burgos",
  //   id: "09253",
  //   label: "Pardilla (Burgos)",
  // },
  // {
  //   municipio: "Partido de la Sierra en Tobalina",
  //   provincia: "Burgos",
  //   id: "09255",
  //   label: "Partido de la Sierra en Tobalina (Burgos)",
  // },
  // {
  //   municipio: "Santa Cruz del Valle Urbión",
  //   provincia: "Burgos",
  //   id: "09346",
  //   label: "Santa Cruz del Valle Urbión (Burgos)",
  // },
  // {
  //   municipio: "Pedrosa de Duero",
  //   provincia: "Burgos",
  //   id: "09256",
  //   label: "Pedrosa de Duero (Burgos)",
  // },
  // {
  //   municipio: "Santa Cruz de la Salceda",
  //   provincia: "Burgos",
  //   id: "09345",
  //   label: "Santa Cruz de la Salceda (Burgos)",
  // },
  // {
  //   municipio: "Pedrosa del Príncipe",
  //   provincia: "Burgos",
  //   id: "09258",
  //   label: "Pedrosa del Príncipe (Burgos)",
  // },
  // {
  //   municipio: "Pedrosa de Río Úrbel",
  //   provincia: "Burgos",
  //   id: "09259",
  //   label: "Pedrosa de Río Úrbel (Burgos)",
  // },
  // {
  //   municipio: "Peñaranda de Duero",
  //   provincia: "Burgos",
  //   id: "09261",
  //   label: "Peñaranda de Duero (Burgos)",
  // },
  // {
  //   municipio: "Peral de Arlanza",
  //   provincia: "Burgos",
  //   id: "09262",
  //   label: "Peral de Arlanza (Burgos)",
  // },
  // {
  //   municipio: "Piérnigas",
  //   provincia: "Burgos",
  //   id: "09265",
  //   label: "Piérnigas (Burgos)",
  // },
  // {
  //   municipio: "Santa Inés",
  //   provincia: "Burgos",
  //   id: "09348",
  //   label: "Santa Inés (Burgos)",
  // },
  // {
  //   municipio: "Pineda de la Sierra",
  //   provincia: "Burgos",
  //   id: "09266",
  //   label: "Pineda de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Pineda Trasmonte",
  //   provincia: "Burgos",
  //   id: "09267",
  //   label: "Pineda Trasmonte (Burgos)",
  // },
  // {
  //   municipio: "Santa Gadea del Cid",
  //   provincia: "Burgos",
  //   id: "09347",
  //   label: "Santa Gadea del Cid (Burgos)",
  // },
  // {
  //   municipio: "Pinilla de los Moros",
  //   provincia: "Burgos",
  //   id: "09269",
  //   label: "Pinilla de los Moros (Burgos)",
  // },
  // {
  //   municipio: "Santa María del Campo",
  //   provincia: "Burgos",
  //   id: "09350",
  //   label: "Santa María del Campo (Burgos)",
  // },
  // {
  //   municipio: "Santa María del Invierno",
  //   provincia: "Burgos",
  //   id: "09351",
  //   label: "Santa María del Invierno (Burgos)",
  // },
  // {
  //   municipio: "Salduero",
  //   provincia: "Soria",
  //   id: "42161",
  //   label: "Salduero (Soria)",
  // },
  // {
  //   municipio: "Pinilla Trasmonte",
  //   provincia: "Burgos",
  //   id: "09270",
  //   label: "Pinilla Trasmonte (Burgos)",
  // },
  // {
  //   municipio: "Poza de la Sal",
  //   provincia: "Burgos",
  //   id: "09272",
  //   label: "Poza de la Sal (Burgos)",
  // },
  // {
  //   municipio: "Prádanos de Bureba",
  //   provincia: "Burgos",
  //   id: "09273",
  //   label: "Prádanos de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Pradoluengo",
  //   provincia: "Burgos",
  //   id: "09274",
  //   label: "Pradoluengo (Burgos)",
  // },
  // {
  //   municipio: "Presencio",
  //   provincia: "Burgos",
  //   id: "09275",
  //   label: "Presencio (Burgos)",
  // },
  // {
  //   municipio: "Puebla de Arganzón, La",
  //   provincia: "Burgos",
  //   id: "09276",
  //   label: "Puebla de Arganzón, La (Burgos)",
  // },
  // {
  //   municipio: "Puentedura",
  //   provincia: "Burgos",
  //   id: "09277",
  //   label: "Puentedura (Burgos)",
  // },
  // {
  //   municipio: "Quemada",
  //   provincia: "Burgos",
  //   id: "09279",
  //   label: "Quemada (Burgos)",
  // },
  // {
  //   municipio: "Quintanabureba",
  //   provincia: "Burgos",
  //   id: "09280",
  //   label: "Quintanabureba (Burgos)",
  // },
  // {
  //   municipio: "Santa María del Mercadillo",
  //   provincia: "Burgos",
  //   id: "09352",
  //   label: "Santa María del Mercadillo (Burgos)",
  // },
  // {
  //   municipio: "Santa María Rivarredonda",
  //   provincia: "Burgos",
  //   id: "09353",
  //   label: "Santa María Rivarredonda (Burgos)",
  // },
  // {
  //   municipio: "Villagonzalo Pedernales",
  //   provincia: "Burgos",
  //   id: "09434",
  //   label: "Villagonzalo Pedernales (Burgos)",
  // },
  // {
  //   municipio: "Quintanaélez",
  //   provincia: "Burgos",
  //   id: "09283",
  //   label: "Quintanaélez (Burgos)",
  // },
  // {
  //   municipio: "Quintanaortuño",
  //   provincia: "Burgos",
  //   id: "09287",
  //   label: "Quintanaortuño (Burgos)",
  // },
  // {
  //   municipio: "Quintanapalla",
  //   provincia: "Burgos",
  //   id: "09288",
  //   label: "Quintanapalla (Burgos)",
  // },
  // {
  //   municipio: "Quintanillas, Las",
  //   provincia: "Burgos",
  //   id: "09297",
  //   label: "Quintanillas, Las (Burgos)",
  // },
  // {
  //   municipio: "Quintanar de la Sierra",
  //   provincia: "Burgos",
  //   id: "09289",
  //   label: "Quintanar de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Quintanavides",
  //   provincia: "Burgos",
  //   id: "09292",
  //   label: "Quintanavides (Burgos)",
  // },
  // {
  //   municipio: "Redecilla del Campo",
  //   provincia: "Burgos",
  //   id: "09308",
  //   label: "Redecilla del Campo (Burgos)",
  // },
  // {
  //   municipio: "Quintanilla de la Mata",
  //   provincia: "Burgos",
  //   id: "09294",
  //   label: "Quintanilla de la Mata (Burgos)",
  // },
  // {
  //   municipio: "Quintanilla del Coco",
  //   provincia: "Burgos",
  //   id: "09295",
  //   label: "Quintanilla del Coco (Burgos)",
  // },
  // {
  //   municipio: "Quintanilla San García",
  //   provincia: "Burgos",
  //   id: "09298",
  //   label: "Quintanilla San García (Burgos)",
  // },
  // {
  //   municipio: "Quintanilla Vivar",
  //   provincia: "Burgos",
  //   id: "09301",
  //   label: "Quintanilla Vivar (Burgos)",
  // },
  // {
  //   municipio: "Rabé de las Calzadas",
  //   provincia: "Burgos",
  //   id: "09304",
  //   label: "Rabé de las Calzadas (Burgos)",
  // },
  // {
  //   municipio: "Almedíjar",
  //   provincia: "Castellón",
  //   id: "12010",
  //   label: "Almedíjar (Castellón)",
  // },
  // {
  //   municipio: "Rebolledo de la Torre",
  //   provincia: "Burgos",
  //   id: "09306",
  //   label: "Rebolledo de la Torre (Burgos)",
  // },
  // {
  //   municipio: "Redecilla del Camino",
  //   provincia: "Burgos",
  //   id: "09307",
  //   label: "Redecilla del Camino (Burgos)",
  // },
  // {
  //   municipio: "Regumiel de la Sierra",
  //   provincia: "Burgos",
  //   id: "09309",
  //   label: "Regumiel de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Sordillos",
  //   provincia: "Burgos",
  //   id: "09368",
  //   label: "Sordillos (Burgos)",
  // },
  // {
  //   municipio: "Sotillo de la Ribera",
  //   provincia: "Burgos",
  //   id: "09369",
  //   label: "Sotillo de la Ribera (Burgos)",
  // },
  // {
  //   municipio: "Villahoz",
  //   provincia: "Burgos",
  //   id: "09437",
  //   label: "Villahoz (Burgos)",
  // },
  // {
  //   municipio: "Reinoso",
  //   provincia: "Burgos",
  //   id: "09310",
  //   label: "Reinoso (Burgos)",
  // },
  // {
  //   municipio: "Retuerta",
  //   provincia: "Burgos",
  //   id: "09311",
  //   label: "Retuerta (Burgos)",
  // },
  // {
  //   municipio: "Revilla y Ahedo, La",
  //   provincia: "Burgos",
  //   id: "09312",
  //   label: "Revilla y Ahedo, La (Burgos)",
  // },
  // {
  //   municipio: "Revilla del Campo",
  //   provincia: "Burgos",
  //   id: "09314",
  //   label: "Revilla del Campo (Burgos)",
  // },
  // {
  //   municipio: "Revillarruz",
  //   provincia: "Burgos",
  //   id: "09315",
  //   label: "Revillarruz (Burgos)",
  // },
  // {
  //   municipio: "San Martín de Rubiales",
  //   provincia: "Burgos",
  //   id: "09339",
  //   label: "San Martín de Rubiales (Burgos)",
  // },
  // {
  //   municipio: "Revilla Vallejera",
  //   provincia: "Burgos",
  //   id: "09316",
  //   label: "Revilla Vallejera (Burgos)",
  // },
  // {
  //   municipio: "Rezmondo",
  //   provincia: "Burgos",
  //   id: "09317",
  //   label: "Rezmondo (Burgos)",
  // },
  // {
  //   municipio: "Royuela de Río Franco",
  //   provincia: "Burgos",
  //   id: "09325",
  //   label: "Royuela de Río Franco (Burgos)",
  // },
  // {
  //   municipio: "Riocavado de la Sierra",
  //   provincia: "Burgos",
  //   id: "09318",
  //   label: "Riocavado de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Rubena",
  //   provincia: "Burgos",
  //   id: "09326",
  //   label: "Rubena (Burgos)",
  // },
  // {
  //   municipio: "Rublacedo de Abajo",
  //   provincia: "Burgos",
  //   id: "09327",
  //   label: "Rublacedo de Abajo (Burgos)",
  // },
  // {
  //   municipio: "Roa",
  //   provincia: "Burgos",
  //   id: "09321",
  //   label: "Roa (Burgos)",
  // },
  // {
  //   municipio: "Rojas",
  //   provincia: "Burgos",
  //   id: "09323",
  //   label: "Rojas (Burgos)",
  // },
  // {
  //   municipio: "Rucandio",
  //   provincia: "Burgos",
  //   id: "09328",
  //   label: "Rucandio (Burgos)",
  // },
  // {
  //   municipio: "Salas de Bureba",
  //   provincia: "Burgos",
  //   id: "09329",
  //   label: "Salas de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Salinillas de Bureba",
  //   provincia: "Burgos",
  //   id: "09334",
  //   label: "Salinillas de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Santibáñez de Esgueva",
  //   provincia: "Burgos",
  //   id: "09355",
  //   label: "Santibáñez de Esgueva (Burgos)",
  // },
  // {
  //   municipio: "Salas de los Infantes",
  //   provincia: "Burgos",
  //   id: "09330",
  //   label: "Salas de los Infantes (Burgos)",
  // },
  // {
  //   municipio: "Saldaña de Burgos",
  //   provincia: "Burgos",
  //   id: "09332",
  //   label: "Saldaña de Burgos (Burgos)",
  // },
  // {
  //   municipio: "San Adrián de Juarros",
  //   provincia: "Burgos",
  //   id: "09335",
  //   label: "San Adrián de Juarros (Burgos)",
  // },
  // {
  //   municipio: "San Juan del Monte",
  //   provincia: "Burgos",
  //   id: "09337",
  //   label: "San Juan del Monte (Burgos)",
  // },
  // {
  //   municipio: "San Mamés de Burgos",
  //   provincia: "Burgos",
  //   id: "09338",
  //   label: "San Mamés de Burgos (Burgos)",
  // },
  // {
  //   municipio: "San Millán de Lara",
  //   provincia: "Burgos",
  //   id: "09340",
  //   label: "San Millán de Lara (Burgos)",
  // },
  // {
  //   municipio: "Santa Cecilia",
  //   provincia: "Burgos",
  //   id: "09343",
  //   label: "Santa Cecilia (Burgos)",
  // },
  // {
  //   municipio: "Santa Olalla de Bureba",
  //   provincia: "Burgos",
  //   id: "09354",
  //   label: "Santa Olalla de Bureba (Burgos)",
  // },
  // {
  //   municipio: "Santibáñez del Val",
  //   provincia: "Burgos",
  //   id: "09356",
  //   label: "Santibáñez del Val (Burgos)",
  // },
  // {
  //   municipio: "Santo Domingo de Silos",
  //   provincia: "Burgos",
  //   id: "09358",
  //   label: "Santo Domingo de Silos (Burgos)",
  // },
  // {
  //   municipio: "San Vicente del Valle",
  //   provincia: "Burgos",
  //   id: "09360",
  //   label: "San Vicente del Valle (Burgos)",
  // },
  // {
  //   municipio: "Sargentes de la Lora",
  //   provincia: "Burgos",
  //   id: "09361",
  //   label: "Sargentes de la Lora (Burgos)",
  // },
  // {
  //   municipio: "Sarracín",
  //   provincia: "Burgos",
  //   id: "09362",
  //   label: "Sarracín (Burgos)",
  // },
  // {
  //   municipio: "Sasamón",
  //   provincia: "Burgos",
  //   id: "09363",
  //   label: "Sasamón (Burgos)",
  // },
  // {
  //   municipio: "Sequera de Haza, La",
  //   provincia: "Burgos",
  //   id: "09365",
  //   label: "Sequera de Haza, La (Burgos)",
  // },
  // {
  //   municipio: "Susinos del Páramo",
  //   provincia: "Burgos",
  //   id: "09374",
  //   label: "Susinos del Páramo (Burgos)",
  // },
  // {
  //   municipio: "Solarana",
  //   provincia: "Burgos",
  //   id: "09366",
  //   label: "Solarana (Burgos)",
  // },
  // {
  //   municipio: "Sotragero",
  //   provincia: "Burgos",
  //   id: "09372",
  //   label: "Sotragero (Burgos)",
  // },
  // {
  //   municipio: "Tamarón",
  //   provincia: "Burgos",
  //   id: "09375",
  //   label: "Tamarón (Burgos)",
  // },
  // {
  //   municipio: "Tardajos",
  //   provincia: "Burgos",
  //   id: "09377",
  //   label: "Tardajos (Burgos)",
  // },
  // {
  //   municipio: "Sotresgudo",
  //   provincia: "Burgos",
  //   id: "09373",
  //   label: "Sotresgudo (Burgos)",
  // },
  // {
  //   municipio: "Tejada",
  //   provincia: "Burgos",
  //   id: "09378",
  //   label: "Tejada (Burgos)",
  // },
  // {
  //   municipio: "Terradillos de Esgueva",
  //   provincia: "Burgos",
  //   id: "09380",
  //   label: "Terradillos de Esgueva (Burgos)",
  // },
  // {
  //   municipio: "Tobar",
  //   provincia: "Burgos",
  //   id: "09382",
  //   label: "Tobar (Burgos)",
  // },
  // {
  //   municipio: "Tinieblas de la Sierra",
  //   provincia: "Burgos",
  //   id: "09381",
  //   label: "Tinieblas de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Valle de Valdelucio",
  //   provincia: "Burgos",
  //   id: "09415",
  //   label: "Valle de Valdelucio (Burgos)",
  // },
  // {
  //   municipio: "Valle de Zamanzas",
  //   provincia: "Burgos",
  //   id: "09416",
  //   label: "Valle de Zamanzas (Burgos)",
  // },
  // {
  //   municipio: "Tordómar",
  //   provincia: "Burgos",
  //   id: "09384",
  //   label: "Tordómar (Burgos)",
  // },
  // {
  //   municipio: "Torrecilla del Monte",
  //   provincia: "Burgos",
  //   id: "09386",
  //   label: "Torrecilla del Monte (Burgos)",
  // },
  // {
  //   municipio: "Valle de Losa",
  //   provincia: "Burgos",
  //   id: "09908",
  //   label: "Valle de Losa (Burgos)",
  // },
  // {
  //   municipio: "Torregalindo",
  //   provincia: "Burgos",
  //   id: "09387",
  //   label: "Torregalindo (Burgos)",
  // },
  // {
  //   municipio: "Torrelara",
  //   provincia: "Burgos",
  //   id: "09388",
  //   label: "Torrelara (Burgos)",
  // },
  // {
  //   municipio: "Torrepadre",
  //   provincia: "Burgos",
  //   id: "09389",
  //   label: "Torrepadre (Burgos)",
  // },
  // {
  //   municipio: "Torresandino",
  //   provincia: "Burgos",
  //   id: "09390",
  //   label: "Torresandino (Burgos)",
  // },
  // {
  //   municipio: "Tórtoles de Esgueva",
  //   provincia: "Burgos",
  //   id: "09391",
  //   label: "Tórtoles de Esgueva (Burgos)",
  // },
  // {
  //   municipio: "Tosantos",
  //   provincia: "Burgos",
  //   id: "09392",
  //   label: "Tosantos (Burgos)",
  // },
  // {
  //   municipio: "Trespaderne",
  //   provincia: "Burgos",
  //   id: "09394",
  //   label: "Trespaderne (Burgos)",
  // },
  // {
  //   municipio: "Tubilla del Agua",
  //   provincia: "Burgos",
  //   id: "09395",
  //   label: "Tubilla del Agua (Burgos)",
  // },
  // {
  //   municipio: "Tubilla del Lago",
  //   provincia: "Burgos",
  //   id: "09396",
  //   label: "Tubilla del Lago (Burgos)",
  // },
  // {
  //   municipio: "Valmala",
  //   provincia: "Burgos",
  //   id: "09407",
  //   label: "Valmala (Burgos)",
  // },
  // {
  //   municipio: "Úrbel del Castillo",
  //   provincia: "Burgos",
  //   id: "09398",
  //   label: "Úrbel del Castillo (Burgos)",
  // },
  // {
  //   municipio: "Vadocondes",
  //   provincia: "Burgos",
  //   id: "09400",
  //   label: "Vadocondes (Burgos)",
  // },
  // {
  //   municipio: "Valdeande",
  //   provincia: "Burgos",
  //   id: "09403",
  //   label: "Valdeande (Burgos)",
  // },
  // {
  //   municipio: "Villafranca Montes de Oca",
  //   provincia: "Burgos",
  //   id: "09431",
  //   label: "Villafranca Montes de Oca (Burgos)",
  // },
  // {
  //   municipio: "Villafruela",
  //   provincia: "Burgos",
  //   id: "09432",
  //   label: "Villafruela (Burgos)",
  // },
  // {
  //   municipio: "Valdezate",
  //   provincia: "Burgos",
  //   id: "09405",
  //   label: "Valdezate (Burgos)",
  // },
  // {
  //   municipio: "Valdorros",
  //   provincia: "Burgos",
  //   id: "09406",
  //   label: "Valdorros (Burgos)",
  // },
  // {
  //   municipio: "Valle de Manzanedo",
  //   provincia: "Burgos",
  //   id: "09409",
  //   label: "Valle de Manzanedo (Burgos)",
  // },
  // {
  //   municipio: "Valle de Mena",
  //   provincia: "Burgos",
  //   id: "09410",
  //   label: "Valle de Mena (Burgos)",
  // },
  // {
  //   municipio: "Valluércanes",
  //   provincia: "Burgos",
  //   id: "09419",
  //   label: "Valluércanes (Burgos)",
  // },
  // {
  //   municipio: "Valle de Oca",
  //   provincia: "Burgos",
  //   id: "09411",
  //   label: "Valle de Oca (Burgos)",
  // },
  // {
  //   municipio: "Valle de Tobalina",
  //   provincia: "Burgos",
  //   id: "09412",
  //   label: "Valle de Tobalina (Burgos)",
  // },
  // {
  //   municipio: "Valles de Palenzuela",
  //   provincia: "Burgos",
  //   id: "09418",
  //   label: "Valles de Palenzuela (Burgos)",
  // },
  // {
  //   municipio: "Valle de Valdebezana",
  //   provincia: "Burgos",
  //   id: "09413",
  //   label: "Valle de Valdebezana (Burgos)",
  // },
  // {
  //   municipio: "Rafelcofer",
  //   provincia: "Valencia",
  //   id: "46208",
  //   label: "Rafelcofer (Valencia)",
  // },
  // {
  //   municipio: "Valle de Valdelaguna",
  //   provincia: "Burgos",
  //   id: "09414",
  //   label: "Valle de Valdelaguna (Burgos)",
  // },
  // {
  //   municipio: "Vallejera",
  //   provincia: "Burgos",
  //   id: "09417",
  //   label: "Vallejera (Burgos)",
  // },
  // {
  //   municipio: "Vid y Barrios, La",
  //   provincia: "Burgos",
  //   id: "09421",
  //   label: "Vid y Barrios, La (Burgos)",
  // },
  // {
  //   municipio: "Vid de Bureba, La",
  //   provincia: "Burgos",
  //   id: "09422",
  //   label: "Vid de Bureba, La (Burgos)",
  // },
  // {
  //   municipio: "Vileña",
  //   provincia: "Burgos",
  //   id: "09423",
  //   label: "Vileña (Burgos)",
  // },
  // {
  //   municipio: "Viloria de Rioja",
  //   provincia: "Burgos",
  //   id: "09424",
  //   label: "Viloria de Rioja (Burgos)",
  // },
  // {
  //   municipio: "Vilviestre del Pinar",
  //   provincia: "Burgos",
  //   id: "09425",
  //   label: "Vilviestre del Pinar (Burgos)",
  // },
  // {
  //   municipio: "Villaescusa de Roa",
  //   provincia: "Burgos",
  //   id: "09428",
  //   label: "Villaescusa de Roa (Burgos)",
  // },
  // {
  //   municipio: "Villalbilla de Burgos",
  //   provincia: "Burgos",
  //   id: "09439",
  //   label: "Villalbilla de Burgos (Burgos)",
  // },
  // {
  //   municipio: "Villadiego",
  //   provincia: "Burgos",
  //   id: "09427",
  //   label: "Villadiego (Burgos)",
  // },
  // {
  //   municipio: "Villaescusa la Sombría",
  //   provincia: "Burgos",
  //   id: "09429",
  //   label: "Villaescusa la Sombría (Burgos)",
  // },
  // {
  //   municipio: "Villaespasa",
  //   provincia: "Burgos",
  //   id: "09430",
  //   label: "Villaespasa (Burgos)",
  // },
  // {
  //   municipio: "Villalba de Duero",
  //   provincia: "Burgos",
  //   id: "09438",
  //   label: "Villalba de Duero (Burgos)",
  // },
  // {
  //   municipio: "Villalbilla de Gumiel",
  //   provincia: "Burgos",
  //   id: "09440",
  //   label: "Villalbilla de Gumiel (Burgos)",
  // },
  // {
  //   municipio: "Villaldemiro",
  //   provincia: "Burgos",
  //   id: "09441",
  //   label: "Villaldemiro (Burgos)",
  // },
  // {
  //   municipio: "Villalmanzo",
  //   provincia: "Burgos",
  //   id: "09442",
  //   label: "Villalmanzo (Burgos)",
  // },
  // {
  //   municipio: "Villamayor de los Montes",
  //   provincia: "Burgos",
  //   id: "09443",
  //   label: "Villamayor de los Montes (Burgos)",
  // },
  // {
  //   municipio: "Villamayor de Treviño",
  //   provincia: "Burgos",
  //   id: "09444",
  //   label: "Villamayor de Treviño (Burgos)",
  // },
  // {
  //   municipio: "Villambistia",
  //   provincia: "Burgos",
  //   id: "09445",
  //   label: "Villambistia (Burgos)",
  // },
  // {
  //   municipio: "Villamedianilla",
  //   provincia: "Burgos",
  //   id: "09446",
  //   label: "Villamedianilla (Burgos)",
  // },
  // {
  //   municipio: "Villamiel de la Sierra",
  //   provincia: "Burgos",
  //   id: "09447",
  //   label: "Villamiel de la Sierra (Burgos)",
  // },
  // {
  //   municipio: "Villangómez",
  //   provincia: "Burgos",
  //   id: "09448",
  //   label: "Villangómez (Burgos)",
  // },
  // {
  //   municipio: "Abadía",
  //   provincia: "Cáceres",
  //   id: "10001",
  //   label: "Abadía (Cáceres)",
  // },
  // {
  //   municipio: "Villanueva de Gumiel",
  //   provincia: "Burgos",
  //   id: "09451",
  //   label: "Villanueva de Gumiel (Burgos)",
  // },
  // {
  //   municipio: "Abertura",
  //   provincia: "Cáceres",
  //   id: "10002",
  //   label: "Abertura (Cáceres)",
  // },
  // {
  //   municipio: "Villanueva de Teba",
  //   provincia: "Burgos",
  //   id: "09454",
  //   label: "Villanueva de Teba (Burgos)",
  // },
  // {
  //   municipio: "Acebo",
  //   provincia: "Cáceres",
  //   id: "10003",
  //   label: "Acebo (Cáceres)",
  // },
  // {
  //   municipio: "Villanueva de Argaño",
  //   provincia: "Burgos",
  //   id: "09449",
  //   label: "Villanueva de Argaño (Burgos)",
  // },
  // {
  //   municipio: "Villanueva de Carazo",
  //   provincia: "Burgos",
  //   id: "09450",
  //   label: "Villanueva de Carazo (Burgos)",
  // },
  // {
  //   municipio: "Aceituna",
  //   provincia: "Cáceres",
  //   id: "10005",
  //   label: "Aceituna (Cáceres)",
  // },
  // {
  //   municipio: "Villariezo",
  //   provincia: "Burgos",
  //   id: "09458",
  //   label: "Villariezo (Burgos)",
  // },
  // {
  //   municipio: "Villazopeque",
  //   provincia: "Burgos",
  //   id: "09472",
  //   label: "Villazopeque (Burgos)",
  // },
  // {
  //   municipio: "Ahigal",
  //   provincia: "Cáceres",
  //   id: "10006",
  //   label: "Ahigal (Cáceres)",
  // },
  // {
  //   municipio: "Villaquirán de los Infantes",
  //   provincia: "Burgos",
  //   id: "09456",
  //   label: "Villaquirán de los Infantes (Burgos)",
  // },
  // {
  //   municipio: "Villasandino",
  //   provincia: "Burgos",
  //   id: "09460",
  //   label: "Villasandino (Burgos)",
  // },
  // {
  //   municipio: "Villatuelda",
  //   provincia: "Burgos",
  //   id: "09464",
  //   label: "Villatuelda (Burgos)",
  // },
  // {
  //   municipio: "Villegas",
  //   provincia: "Burgos",
  //   id: "09473",
  //   label: "Villegas (Burgos)",
  // },
  // {
  //   municipio: "Villasur de Herreros",
  //   provincia: "Burgos",
  //   id: "09463",
  //   label: "Villasur de Herreros (Burgos)",
  // },
  // {
  //   municipio: "Villaverde del Monte",
  //   provincia: "Burgos",
  //   id: "09466",
  //   label: "Villaverde del Monte (Burgos)",
  // },
  // {
  //   municipio: "Villaverde-Mogina",
  //   provincia: "Burgos",
  //   id: "09467",
  //   label: "Villaverde-Mogina (Burgos)",
  // },
  // {
  //   municipio: "Villayerno Morquillas",
  //   provincia: "Burgos",
  //   id: "09471",
  //   label: "Villayerno Morquillas (Burgos)",
  // },
  // {
  //   municipio: "Villoruebo",
  //   provincia: "Burgos",
  //   id: "09476",
  //   label: "Villoruebo (Burgos)",
  // },
  // {
  //   municipio: "Vizcaínos",
  //   provincia: "Burgos",
  //   id: "09478",
  //   label: "Vizcaínos (Burgos)",
  // },
  // {
  //   municipio: "Zael",
  //   provincia: "Burgos",
  //   id: "09480",
  //   label: "Zael (Burgos)",
  // },
  // {
  //   municipio: "Albalá",
  //   provincia: "Cáceres",
  //   id: "10007",
  //   label: "Albalá (Cáceres)",
  // },
  // {
  //   municipio: "Zarzosa de Río Pisuerga",
  //   provincia: "Burgos",
  //   id: "09482",
  //   label: "Zarzosa de Río Pisuerga (Burgos)",
  // },
  // {
  //   municipio: "Zazuar",
  //   provincia: "Burgos",
  //   id: "09483",
  //   label: "Zazuar (Burgos)",
  // },
  // {
  //   municipio: "Zuñeda",
  //   provincia: "Burgos",
  //   id: "09485",
  //   label: "Zuñeda (Burgos)",
  // },
  // {
  //   municipio: "Quintanilla del Agua y Tordueles",
  //   provincia: "Burgos",
  //   id: "09901",
  //   label: "Quintanilla del Agua y Tordueles (Burgos)",
  // },
  // {
  //   municipio: "Valle de Santibáñez",
  //   provincia: "Burgos",
  //   id: "09902",
  //   label: "Valle de Santibáñez (Burgos)",
  // },
  // {
  //   municipio: "Villarcayo de Merindad de Castilla la Vieja",
  //   provincia: "Burgos",
  //   id: "09903",
  //   label: "Villarcayo de Merindad de Castilla la Vieja (Burgos)",
  // },
  // {
  //   municipio: "Valle de las Navas",
  //   provincia: "Burgos",
  //   id: "09904",
  //   label: "Valle de las Navas (Burgos)",
  // },
  // {
  //   municipio: "Valle de Sedano",
  //   provincia: "Burgos",
  //   id: "09905",
  //   label: "Valle de Sedano (Burgos)",
  // },
  // {
  //   municipio: "Alfoz de Quintanadueñas",
  //   provincia: "Burgos",
  //   id: "09907",
  //   label: "Alfoz de Quintanadueñas (Burgos)",
  // },
  // {
  //   municipio: "Merindad de Río Ubierna",
  //   provincia: "Burgos",
  //   id: "09906",
  //   label: "Merindad de Río Ubierna (Burgos)",
  // },
  // {
  //   municipio: "Alcollarín",
  //   provincia: "Cáceres",
  //   id: "10009",
  //   label: "Alcollarín (Cáceres)",
  // },
  // {
  //   municipio: "Alcuéscar",
  //   provincia: "Cáceres",
  //   id: "10010",
  //   label: "Alcuéscar (Cáceres)",
  // },
  // {
  //   municipio: "Aldeacentenera",
  //   provincia: "Cáceres",
  //   id: "10011",
  //   label: "Aldeacentenera (Cáceres)",
  // },
  // {
  //   municipio: "Cadalso",
  //   provincia: "Cáceres",
  //   id: "10039",
  //   label: "Cadalso (Cáceres)",
  // },
  // {
  //   municipio: "Aldea del Cano",
  //   provincia: "Cáceres",
  //   id: "10012",
  //   label: "Aldea del Cano (Cáceres)",
  // },
  // {
  //   municipio: "Aldeanueva de la Vera",
  //   provincia: "Cáceres",
  //   id: "10014",
  //   label: "Aldeanueva de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Aldea del Obispo, La",
  //   provincia: "Cáceres",
  //   id: "10013",
  //   label: "Aldea del Obispo, La (Cáceres)",
  // },
  // {
  //   municipio: "Benquerencia",
  //   provincia: "Cáceres",
  //   id: "10027",
  //   label: "Benquerencia (Cáceres)",
  // },
  // {
  //   municipio: "Aldeanueva del Camino",
  //   provincia: "Cáceres",
  //   id: "10015",
  //   label: "Aldeanueva del Camino (Cáceres)",
  // },
  // {
  //   municipio: "Aldehuela de Jerte",
  //   provincia: "Cáceres",
  //   id: "10016",
  //   label: "Aldehuela de Jerte (Cáceres)",
  // },
  // {
  //   municipio: "Alía",
  //   provincia: "Cáceres",
  //   id: "10017",
  //   label: "Alía (Cáceres)",
  // },
  // {
  //   municipio: "Cabañas del Castillo",
  //   provincia: "Cáceres",
  //   id: "10033",
  //   label: "Cabañas del Castillo (Cáceres)",
  // },
  // {
  //   municipio: "Aliseda",
  //   provincia: "Cáceres",
  //   id: "10018",
  //   label: "Aliseda (Cáceres)",
  // },
  // {
  //   municipio: "Almaraz",
  //   provincia: "Cáceres",
  //   id: "10019",
  //   label: "Almaraz (Cáceres)",
  // },
  // {
  //   municipio: "Almoharín",
  //   provincia: "Cáceres",
  //   id: "10020",
  //   label: "Almoharín (Cáceres)",
  // },
  // {
  //   municipio: "Arroyo de la Luz",
  //   provincia: "Cáceres",
  //   id: "10021",
  //   label: "Arroyo de la Luz (Cáceres)",
  // },
  // {
  //   municipio: "Carcaboso",
  //   provincia: "Cáceres",
  //   id: "10047",
  //   label: "Carcaboso (Cáceres)",
  // },
  // {
  //   municipio: "Arroyomolinos de la Vera",
  //   provincia: "Cáceres",
  //   id: "10022",
  //   label: "Arroyomolinos de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Baños de Montemayor",
  //   provincia: "Cáceres",
  //   id: "10024",
  //   label: "Baños de Montemayor (Cáceres)",
  // },
  // {
  //   municipio: "Barrado",
  //   provincia: "Cáceres",
  //   id: "10025",
  //   label: "Barrado (Cáceres)",
  // },
  // {
  //   municipio: "Belvís de Monroy",
  //   provincia: "Cáceres",
  //   id: "10026",
  //   label: "Belvís de Monroy (Cáceres)",
  // },
  // {
  //   municipio: "Berrocalejo",
  //   provincia: "Cáceres",
  //   id: "10028",
  //   label: "Berrocalejo (Cáceres)",
  // },
  // {
  //   municipio: "Berzocana",
  //   provincia: "Cáceres",
  //   id: "10029",
  //   label: "Berzocana (Cáceres)",
  // },
  // {
  //   municipio: "Bohonal de Ibor",
  //   provincia: "Cáceres",
  //   id: "10030",
  //   label: "Bohonal de Ibor (Cáceres)",
  // },
  // {
  //   municipio: "Botija",
  //   provincia: "Cáceres",
  //   id: "10031",
  //   label: "Botija (Cáceres)",
  // },
  // {
  //   municipio: "Brozas",
  //   provincia: "Cáceres",
  //   id: "10032",
  //   label: "Brozas (Cáceres)",
  // },
  // {
  //   municipio: "Cabezabellosa",
  //   provincia: "Cáceres",
  //   id: "10034",
  //   label: "Cabezabellosa (Cáceres)",
  // },
  // {
  //   municipio: "Cachorrilla",
  //   provincia: "Cáceres",
  //   id: "10038",
  //   label: "Cachorrilla (Cáceres)",
  // },
  // {
  //   municipio: "Cabezuela del Valle",
  //   provincia: "Cáceres",
  //   id: "10035",
  //   label: "Cabezuela del Valle (Cáceres)",
  // },
  // {
  //   municipio: "Cabrero",
  //   provincia: "Cáceres",
  //   id: "10036",
  //   label: "Cabrero (Cáceres)",
  // },
  // {
  //   municipio: "Calzadilla",
  //   provincia: "Cáceres",
  //   id: "10040",
  //   label: "Calzadilla (Cáceres)",
  // },
  // {
  //   municipio: "Caminomorisco",
  //   provincia: "Cáceres",
  //   id: "10041",
  //   label: "Caminomorisco (Cáceres)",
  // },
  // {
  //   municipio: "Casares de las Hurdes",
  //   provincia: "Cáceres",
  //   id: "10051",
  //   label: "Casares de las Hurdes (Cáceres)",
  // },
  // {
  //   municipio: "Campillo de Deleitosa",
  //   provincia: "Cáceres",
  //   id: "10042",
  //   label: "Campillo de Deleitosa (Cáceres)",
  // },
  // {
  //   municipio: "Cumbre, La",
  //   provincia: "Cáceres",
  //   id: "10069",
  //   label: "Cumbre, La (Cáceres)",
  // },
  // {
  //   municipio: "Deleitosa",
  //   provincia: "Cáceres",
  //   id: "10070",
  //   label: "Deleitosa (Cáceres)",
  // },
  // {
  //   municipio: "Vall de Almonacid",
  //   provincia: "Castellón",
  //   id: "12125",
  //   label: "Vall de Almonacid (Castellón)",
  // },
  // {
  //   municipio: "Campo Lugar",
  //   provincia: "Cáceres",
  //   id: "10043",
  //   label: "Campo Lugar (Cáceres)",
  // },
  // {
  //   municipio: "Carrascalejo",
  //   provincia: "Cáceres",
  //   id: "10048",
  //   label: "Carrascalejo (Cáceres)",
  // },
  // {
  //   municipio: "Casar de Cáceres",
  //   provincia: "Cáceres",
  //   id: "10049",
  //   label: "Casar de Cáceres (Cáceres)",
  // },
  // {
  //   municipio: "Cañamero",
  //   provincia: "Cáceres",
  //   id: "10044",
  //   label: "Cañamero (Cáceres)",
  // },
  // {
  //   municipio: "Cañaveral",
  //   provincia: "Cáceres",
  //   id: "10045",
  //   label: "Cañaveral (Cáceres)",
  // },
  // {
  //   municipio: "Carbajo",
  //   provincia: "Cáceres",
  //   id: "10046",
  //   label: "Carbajo (Cáceres)",
  // },
  // {
  //   municipio: "Casar de Palomero",
  //   provincia: "Cáceres",
  //   id: "10050",
  //   label: "Casar de Palomero (Cáceres)",
  // },
  // {
  //   municipio: "Casas de Don Antonio",
  //   provincia: "Cáceres",
  //   id: "10052",
  //   label: "Casas de Don Antonio (Cáceres)",
  // },
  // {
  //   municipio: "Casas de Don Gómez",
  //   provincia: "Cáceres",
  //   id: "10053",
  //   label: "Casas de Don Gómez (Cáceres)",
  // },
  // {
  //   municipio: "Casas del Castañar",
  //   provincia: "Cáceres",
  //   id: "10054",
  //   label: "Casas del Castañar (Cáceres)",
  // },
  // {
  //   municipio: "Casas del Monte",
  //   provincia: "Cáceres",
  //   id: "10055",
  //   label: "Casas del Monte (Cáceres)",
  // },
  // {
  //   municipio: "Casas de Millán",
  //   provincia: "Cáceres",
  //   id: "10056",
  //   label: "Casas de Millán (Cáceres)",
  // },
  // {
  //   municipio: "Casatejada",
  //   provincia: "Cáceres",
  //   id: "10058",
  //   label: "Casatejada (Cáceres)",
  // },
  // {
  //   municipio: "Tribaldos",
  //   provincia: "Cuenca",
  //   id: "16217",
  //   label: "Tribaldos (Cuenca)",
  // },
  // {
  //   municipio: "Casillas de Coria",
  //   provincia: "Cáceres",
  //   id: "10059",
  //   label: "Casillas de Coria (Cáceres)",
  // },
  // {
  //   municipio: "Castañar de Ibor",
  //   provincia: "Cáceres",
  //   id: "10060",
  //   label: "Castañar de Ibor (Cáceres)",
  // },
  // {
  //   municipio: "Carrizosa",
  //   provincia: "Ciudad Real",
  //   id: "13032",
  //   label: "Carrizosa (Ciudad Real)",
  // },
  // {
  //   municipio: "Ceclavín",
  //   provincia: "Cáceres",
  //   id: "10061",
  //   label: "Ceclavín (Cáceres)",
  // },
  // {
  //   municipio: "Daimiel",
  //   provincia: "Ciudad Real",
  //   id: "13039",
  //   label: "Daimiel (Ciudad Real)",
  // },
  // {
  //   municipio: "Cedillo",
  //   provincia: "Cáceres",
  //   id: "10062",
  //   label: "Cedillo (Cáceres)",
  // },
  // {
  //   municipio: "Cerezo",
  //   provincia: "Cáceres",
  //   id: "10063",
  //   label: "Cerezo (Cáceres)",
  // },
  // {
  //   municipio: "Cilleros",
  //   provincia: "Cáceres",
  //   id: "10064",
  //   label: "Cilleros (Cáceres)",
  // },
  // {
  //   municipio: "Descargamaría",
  //   provincia: "Cáceres",
  //   id: "10071",
  //   label: "Descargamaría (Cáceres)",
  // },
  // {
  //   municipio: "Collado de la Vera",
  //   provincia: "Cáceres",
  //   id: "10065",
  //   label: "Collado de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Garganta, La",
  //   provincia: "Cáceres",
  //   id: "10078",
  //   label: "Garganta, La (Cáceres)",
  // },
  // {
  //   municipio: "Conquista de la Sierra",
  //   provincia: "Cáceres",
  //   id: "10066",
  //   label: "Conquista de la Sierra (Cáceres)",
  // },
  // {
  //   municipio: "Coria",
  //   provincia: "Cáceres",
  //   id: "10067",
  //   label: "Coria (Cáceres)",
  // },
  // {
  //   municipio: "Cuacos de Yuste",
  //   provincia: "Cáceres",
  //   id: "10068",
  //   label: "Cuacos de Yuste (Cáceres)",
  // },
  // {
  //   municipio: "Eljas",
  //   provincia: "Cáceres",
  //   id: "10072",
  //   label: "Eljas (Cáceres)",
  // },
  // {
  //   municipio: "Garvín",
  //   provincia: "Cáceres",
  //   id: "10083",
  //   label: "Garvín (Cáceres)",
  // },
  // {
  //   municipio: "Escurial",
  //   provincia: "Cáceres",
  //   id: "10073",
  //   label: "Escurial (Cáceres)",
  // },
  // {
  //   municipio: "Fresnedoso de Ibor",
  //   provincia: "Cáceres",
  //   id: "10075",
  //   label: "Fresnedoso de Ibor (Cáceres)",
  // },
  // {
  //   municipio: "Garciaz",
  //   provincia: "Cáceres",
  //   id: "10077",
  //   label: "Garciaz (Cáceres)",
  // },
  // {
  //   municipio: "Granja, La",
  //   provincia: "Cáceres",
  //   id: "10086",
  //   label: "Granja, La (Cáceres)",
  // },
  // {
  //   municipio: "Garganta la Olla",
  //   provincia: "Cáceres",
  //   id: "10079",
  //   label: "Garganta la Olla (Cáceres)",
  // },
  // {
  //   municipio: "Gargantilla",
  //   provincia: "Cáceres",
  //   id: "10080",
  //   label: "Gargantilla (Cáceres)",
  // },
  // {
  //   municipio: "Gargüera",
  //   provincia: "Cáceres",
  //   id: "10081",
  //   label: "Gargüera (Cáceres)",
  // },
  // {
  //   municipio: "Garrovillas de Alconétar",
  //   provincia: "Cáceres",
  //   id: "10082",
  //   label: "Garrovillas de Alconétar (Cáceres)",
  // },
  // {
  //   municipio: "Gata",
  //   provincia: "Cáceres",
  //   id: "10084",
  //   label: "Gata (Cáceres)",
  // },
  // {
  //   municipio: "Gordo, El",
  //   provincia: "Cáceres",
  //   id: "10085",
  //   label: "Gordo, El (Cáceres)",
  // },
  // {
  //   municipio: "Guadalupe",
  //   provincia: "Cáceres",
  //   id: "10087",
  //   label: "Guadalupe (Cáceres)",
  // },
  // {
  //   municipio: "Huélaga",
  //   provincia: "Cáceres",
  //   id: "10101",
  //   label: "Huélaga (Cáceres)",
  // },
  // {
  //   municipio: "Guijo de Coria",
  //   provincia: "Cáceres",
  //   id: "10088",
  //   label: "Guijo de Coria (Cáceres)",
  // },
  // {
  //   municipio: "Jarilla",
  //   provincia: "Cáceres",
  //   id: "10106",
  //   label: "Jarilla (Cáceres)",
  // },
  // {
  //   municipio: "Guijo de Galisteo",
  //   provincia: "Cáceres",
  //   id: "10089",
  //   label: "Guijo de Galisteo (Cáceres)",
  // },
  // {
  //   municipio: "Guijo de Granadilla",
  //   provincia: "Cáceres",
  //   id: "10090",
  //   label: "Guijo de Granadilla (Cáceres)",
  // },
  // {
  //   municipio: "Guijo de Santa Bárbara",
  //   provincia: "Cáceres",
  //   id: "10091",
  //   label: "Guijo de Santa Bárbara (Cáceres)",
  // },
  // {
  //   municipio: "Hernán-Pérez",
  //   provincia: "Cáceres",
  //   id: "10093",
  //   label: "Hernán-Pérez (Cáceres)",
  // },
  // {
  //   municipio: "Herguijuela",
  //   provincia: "Cáceres",
  //   id: "10092",
  //   label: "Herguijuela (Cáceres)",
  // },
  // {
  //   municipio: "Herrera de Alcántara",
  //   provincia: "Cáceres",
  //   id: "10094",
  //   label: "Herrera de Alcántara (Cáceres)",
  // },
  // {
  //   municipio: "Montalbanejo",
  //   provincia: "Cuenca",
  //   id: "16129",
  //   label: "Montalbanejo (Cuenca)",
  // },
  // {
  //   municipio: "Herreruela",
  //   provincia: "Cáceres",
  //   id: "10095",
  //   label: "Herreruela (Cáceres)",
  // },
  // {
  //   municipio: "Hervás",
  //   provincia: "Cáceres",
  //   id: "10096",
  //   label: "Hervás (Cáceres)",
  // },
  // {
  //   municipio: "Holguera",
  //   provincia: "Cáceres",
  //   id: "10099",
  //   label: "Holguera (Cáceres)",
  // },
  // {
  //   municipio: "Higuera de Albalat",
  //   provincia: "Cáceres",
  //   id: "10097",
  //   label: "Higuera de Albalat (Cáceres)",
  // },
  // {
  //   municipio: "Hoyos",
  //   provincia: "Cáceres",
  //   id: "10100",
  //   label: "Hoyos (Cáceres)",
  // },
  // {
  //   municipio: "Hinojal",
  //   provincia: "Cáceres",
  //   id: "10098",
  //   label: "Hinojal (Cáceres)",
  // },
  // {
  //   municipio: "Millanes",
  //   provincia: "Cáceres",
  //   id: "10122",
  //   label: "Millanes (Cáceres)",
  // },
  // {
  //   municipio: "Ibahernando",
  //   provincia: "Cáceres",
  //   id: "10102",
  //   label: "Ibahernando (Cáceres)",
  // },
  // {
  //   municipio: "Vilavella, la",
  //   provincia: "Castellón",
  //   id: "12136",
  //   label: "Vilavella, la (Castellón)",
  // },
  // {
  //   municipio: "Jaraíz de la Vera",
  //   provincia: "Cáceres",
  //   id: "10104",
  //   label: "Jaraíz de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Jarandilla de la Vera",
  //   provincia: "Cáceres",
  //   id: "10105",
  //   label: "Jarandilla de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Jerte",
  //   provincia: "Cáceres",
  //   id: "10107",
  //   label: "Jerte (Cáceres)",
  // },
  // {
  //   municipio: "Ladrillar",
  //   provincia: "Cáceres",
  //   id: "10108",
  //   label: "Ladrillar (Cáceres)",
  // },
  // {
  //   municipio: "Logrosán",
  //   provincia: "Cáceres",
  //   id: "10109",
  //   label: "Logrosán (Cáceres)",
  // },
  // {
  //   municipio: "Losar de la Vera",
  //   provincia: "Cáceres",
  //   id: "10110",
  //   label: "Losar de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Madrigal de la Vera",
  //   provincia: "Cáceres",
  //   id: "10111",
  //   label: "Madrigal de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Madrigalejo",
  //   provincia: "Cáceres",
  //   id: "10112",
  //   label: "Madrigalejo (Cáceres)",
  // },
  // {
  //   municipio: "Madroñera",
  //   provincia: "Cáceres",
  //   id: "10113",
  //   label: "Madroñera (Cáceres)",
  // },
  // {
  //   municipio: "Majadas",
  //   provincia: "Cáceres",
  //   id: "10114",
  //   label: "Majadas (Cáceres)",
  // },
  // {
  //   municipio: "Morcillo",
  //   provincia: "Cáceres",
  //   id: "10129",
  //   label: "Morcillo (Cáceres)",
  // },
  // {
  //   municipio: "Malpartida de Cáceres",
  //   provincia: "Cáceres",
  //   id: "10115",
  //   label: "Malpartida de Cáceres (Cáceres)",
  // },
  // {
  //   municipio: "Marchagaz",
  //   provincia: "Cáceres",
  //   id: "10117",
  //   label: "Marchagaz (Cáceres)",
  // },
  // {
  //   municipio: "Mata de Alcántara",
  //   provincia: "Cáceres",
  //   id: "10118",
  //   label: "Mata de Alcántara (Cáceres)",
  // },
  // {
  //   municipio: "Membrío",
  //   provincia: "Cáceres",
  //   id: "10119",
  //   label: "Membrío (Cáceres)",
  // },
  // {
  //   municipio: "Mesas de Ibor",
  //   provincia: "Cáceres",
  //   id: "10120",
  //   label: "Mesas de Ibor (Cáceres)",
  // },
  // {
  //   municipio: "Miajadas",
  //   provincia: "Cáceres",
  //   id: "10121",
  //   label: "Miajadas (Cáceres)",
  // },
  // {
  //   municipio: "Mirabel",
  //   provincia: "Cáceres",
  //   id: "10123",
  //   label: "Mirabel (Cáceres)",
  // },
  // {
  //   municipio: "Villores",
  //   provincia: "Castellón",
  //   id: "12137",
  //   label: "Villores (Castellón)",
  // },
  // {
  //   municipio: "Vinaròs",
  //   provincia: "Castellón",
  //   id: "12138",
  //   label: "Vinaròs (Castellón)",
  // },
  // {
  //   municipio: "Mohedas de Granadilla",
  //   provincia: "Cáceres",
  //   id: "10124",
  //   label: "Mohedas de Granadilla (Cáceres)",
  // },
  // {
  //   municipio: "Monroy",
  //   provincia: "Cáceres",
  //   id: "10125",
  //   label: "Monroy (Cáceres)",
  // },
  // {
  //   municipio: "Montehermoso",
  //   provincia: "Cáceres",
  //   id: "10127",
  //   label: "Montehermoso (Cáceres)",
  // },
  // {
  //   municipio: "Navaconcejo",
  //   provincia: "Cáceres",
  //   id: "10130",
  //   label: "Navaconcejo (Cáceres)",
  // },
  // {
  //   municipio: "Navalmoral de la Mata",
  //   provincia: "Cáceres",
  //   id: "10131",
  //   label: "Navalmoral de la Mata (Cáceres)",
  // },
  // {
  //   municipio: "Palomero",
  //   provincia: "Cáceres",
  //   id: "10137",
  //   label: "Palomero (Cáceres)",
  // },
  // {
  //   municipio: "Navalvillar de Ibor",
  //   provincia: "Cáceres",
  //   id: "10132",
  //   label: "Navalvillar de Ibor (Cáceres)",
  // },
  // {
  //   municipio: "Robledillo de la Vera",
  //   provincia: "Cáceres",
  //   id: "10157",
  //   label: "Robledillo de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Navas del Madroño",
  //   provincia: "Cáceres",
  //   id: "10133",
  //   label: "Navas del Madroño (Cáceres)",
  // },
  // {
  //   municipio: "Pesga, La",
  //   provincia: "Cáceres",
  //   id: "10144",
  //   label: "Pesga, La (Cáceres)",
  // },
  // {
  //   municipio: "Navezuelas",
  //   provincia: "Cáceres",
  //   id: "10134",
  //   label: "Navezuelas (Cáceres)",
  // },
  // {
  //   municipio: "Piedras Albas",
  //   provincia: "Cáceres",
  //   id: "10145",
  //   label: "Piedras Albas (Cáceres)",
  // },
  // {
  //   municipio: "Torre d'en Doménec, la",
  //   provincia: "Castellón",
  //   id: "12120",
  //   label: "Torre d'en Doménec, la (Castellón)",
  // },
  // {
  //   municipio: "Nuñomoral",
  //   provincia: "Cáceres",
  //   id: "10135",
  //   label: "Nuñomoral (Cáceres)",
  // },
  // {
  //   municipio: "San Martín de Trevejo",
  //   provincia: "Cáceres",
  //   id: "10164",
  //   label: "San Martín de Trevejo (Cáceres)",
  // },
  // {
  //   municipio: "Oliva de Plasencia",
  //   provincia: "Cáceres",
  //   id: "10136",
  //   label: "Oliva de Plasencia (Cáceres)",
  // },
  // {
  //   municipio: "Vallat",
  //   provincia: "Castellón",
  //   id: "12123",
  //   label: "Vallat (Castellón)",
  // },
  // {
  //   municipio: "Pasarón de la Vera",
  //   provincia: "Cáceres",
  //   id: "10138",
  //   label: "Pasarón de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Huétor Vega",
  //   provincia: "Granada",
  //   id: "18101",
  //   label: "Huétor Vega (Granada)",
  // },
  // {
  //   municipio: "Pedroso de Acim",
  //   provincia: "Cáceres",
  //   id: "10139",
  //   label: "Pedroso de Acim (Cáceres)",
  // },
  // {
  //   municipio: "Peraleda de la Mata",
  //   provincia: "Cáceres",
  //   id: "10140",
  //   label: "Peraleda de la Mata (Cáceres)",
  // },
  // {
  //   municipio: "Segura de Toro",
  //   provincia: "Cáceres",
  //   id: "10174",
  //   label: "Segura de Toro (Cáceres)",
  // },
  // {
  //   municipio: "Peraleda de San Román",
  //   provincia: "Cáceres",
  //   id: "10141",
  //   label: "Peraleda de San Román (Cáceres)",
  // },
  // {
  //   municipio: "Torre de Don Miguel",
  //   provincia: "Cáceres",
  //   id: "10187",
  //   label: "Torre de Don Miguel (Cáceres)",
  // },
  // {
  //   municipio: "Íllora",
  //   provincia: "Granada",
  //   id: "18102",
  //   label: "Íllora (Granada)",
  // },
  // {
  //   municipio: "Perales del Puerto",
  //   provincia: "Cáceres",
  //   id: "10142",
  //   label: "Perales del Puerto (Cáceres)",
  // },
  // {
  //   municipio: "Pozuelo de Zarzón",
  //   provincia: "Cáceres",
  //   id: "10152",
  //   label: "Pozuelo de Zarzón (Cáceres)",
  // },
  // {
  //   municipio: "Pescueza",
  //   provincia: "Cáceres",
  //   id: "10143",
  //   label: "Pescueza (Cáceres)",
  // },
  // {
  //   municipio: "Robledillo de Gata",
  //   provincia: "Cáceres",
  //   id: "10156",
  //   label: "Robledillo de Gata (Cáceres)",
  // },
  // {
  //   municipio: "Pinofranqueado",
  //   provincia: "Cáceres",
  //   id: "10146",
  //   label: "Pinofranqueado (Cáceres)",
  // },
  // {
  //   municipio: "Medranda",
  //   provincia: "Guadalajara",
  //   id: "19177",
  //   label: "Medranda (Guadalajara)",
  // },
  // {
  //   municipio: "Piornal",
  //   provincia: "Cáceres",
  //   id: "10147",
  //   label: "Piornal (Cáceres)",
  // },
  // {
  //   municipio: "Plasencia",
  //   provincia: "Cáceres",
  //   id: "10148",
  //   label: "Plasencia (Cáceres)",
  // },
  // {
  //   municipio: "Eslida",
  //   provincia: "Castellón",
  //   id: "12057",
  //   label: "Eslida (Castellón)",
  // },
  // {
  //   municipio: "Plasenzuela",
  //   provincia: "Cáceres",
  //   id: "10149",
  //   label: "Plasenzuela (Cáceres)",
  // },
  // {
  //   municipio: "Espadilla",
  //   provincia: "Castellón",
  //   id: "12058",
  //   label: "Espadilla (Castellón)",
  // },
  // {
  //   municipio: "Portaje",
  //   provincia: "Cáceres",
  //   id: "10150",
  //   label: "Portaje (Cáceres)",
  // },
  // {
  //   municipio: "Portezuelo",
  //   provincia: "Cáceres",
  //   id: "10151",
  //   label: "Portezuelo (Cáceres)",
  // },
  // {
  //   municipio: "Puerto de Santa Cruz",
  //   provincia: "Cáceres",
  //   id: "10153",
  //   label: "Puerto de Santa Cruz (Cáceres)",
  // },
  // {
  //   municipio: "Rebollar",
  //   provincia: "Cáceres",
  //   id: "10154",
  //   label: "Rebollar (Cáceres)",
  // },
  // {
  //   municipio: "Riolobos",
  //   provincia: "Cáceres",
  //   id: "10155",
  //   label: "Riolobos (Cáceres)",
  // },
  // {
  //   municipio: "Fuentes de Ayódar",
  //   provincia: "Castellón",
  //   id: "12064",
  //   label: "Fuentes de Ayódar (Castellón)",
  // },
  // {
  //   municipio: "Robledillo de Trujillo",
  //   provincia: "Cáceres",
  //   id: "10158",
  //   label: "Robledillo de Trujillo (Cáceres)",
  // },
  // {
  //   municipio: "Romangordo",
  //   provincia: "Cáceres",
  //   id: "10160",
  //   label: "Romangordo (Cáceres)",
  // },
  // {
  //   municipio: "Robledollano",
  //   provincia: "Cáceres",
  //   id: "10159",
  //   label: "Robledollano (Cáceres)",
  // },
  // {
  //   municipio: "Ruanes",
  //   provincia: "Cáceres",
  //   id: "10161",
  //   label: "Ruanes (Cáceres)",
  // },
  // {
  //   municipio: "Salorino",
  //   provincia: "Cáceres",
  //   id: "10162",
  //   label: "Salorino (Cáceres)",
  // },
  // {
  //   municipio: "Salvatierra de Santiago",
  //   provincia: "Cáceres",
  //   id: "10163",
  //   label: "Salvatierra de Santiago (Cáceres)",
  // },
  // {
  //   municipio: "Santa Ana",
  //   provincia: "Cáceres",
  //   id: "10165",
  //   label: "Santa Ana (Cáceres)",
  // },
  // {
  //   municipio: "Almenara",
  //   provincia: "Castellón",
  //   id: "12011",
  //   label: "Almenara (Castellón)",
  // },
  // {
  //   municipio: "Santa Cruz de la Sierra",
  //   provincia: "Cáceres",
  //   id: "10166",
  //   label: "Santa Cruz de la Sierra (Cáceres)",
  // },
  // {
  //   municipio: "Fuenllana",
  //   provincia: "Ciudad Real",
  //   id: "13043",
  //   label: "Fuenllana (Ciudad Real)",
  // },
  // {
  //   municipio: "Santa Cruz de Paniagua",
  //   provincia: "Cáceres",
  //   id: "10167",
  //   label: "Santa Cruz de Paniagua (Cáceres)",
  // },
  // {
  //   municipio: "Pedro Muñoz",
  //   provincia: "Ciudad Real",
  //   id: "13061",
  //   label: "Pedro Muñoz (Ciudad Real)",
  // },
  // {
  //   municipio: "Santa Marta de Magasca",
  //   provincia: "Cáceres",
  //   id: "10168",
  //   label: "Santa Marta de Magasca (Cáceres)",
  // },
  // {
  //   municipio: "Santiago de Alcántara",
  //   provincia: "Cáceres",
  //   id: "10169",
  //   label: "Santiago de Alcántara (Cáceres)",
  // },
  // {
  //   municipio: "Santiago del Campo",
  //   provincia: "Cáceres",
  //   id: "10170",
  //   label: "Santiago del Campo (Cáceres)",
  // },
  // {
  //   municipio: "Santibáñez el Alto",
  //   provincia: "Cáceres",
  //   id: "10171",
  //   label: "Santibáñez el Alto (Cáceres)",
  // },
  // {
  //   municipio: "Piedrabuena",
  //   provincia: "Ciudad Real",
  //   id: "13063",
  //   label: "Piedrabuena (Ciudad Real)",
  // },
  // {
  //   municipio: "Poblete",
  //   provincia: "Ciudad Real",
  //   id: "13064",
  //   label: "Poblete (Ciudad Real)",
  // },
  // {
  //   municipio: "Santibáñez el Bajo",
  //   provincia: "Cáceres",
  //   id: "10172",
  //   label: "Santibáñez el Bajo (Cáceres)",
  // },
  // {
  //   municipio: "Torre de Santa María",
  //   provincia: "Cáceres",
  //   id: "10188",
  //   label: "Torre de Santa María (Cáceres)",
  // },
  // {
  //   municipio: "Saucedilla",
  //   provincia: "Cáceres",
  //   id: "10173",
  //   label: "Saucedilla (Cáceres)",
  // },
  // {
  //   municipio: "Serrejón",
  //   provincia: "Cáceres",
  //   id: "10176",
  //   label: "Serrejón (Cáceres)",
  // },
  // {
  //   municipio: "Sierra de Fuentes",
  //   provincia: "Cáceres",
  //   id: "10177",
  //   label: "Sierra de Fuentes (Cáceres)",
  // },
  // {
  //   municipio: "Talaván",
  //   provincia: "Cáceres",
  //   id: "10178",
  //   label: "Talaván (Cáceres)",
  // },
  // {
  //   municipio: "Talaveruela de la Vera",
  //   provincia: "Cáceres",
  //   id: "10179",
  //   label: "Talaveruela de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Tejeda de Tiétar",
  //   provincia: "Cáceres",
  //   id: "10181",
  //   label: "Tejeda de Tiétar (Cáceres)",
  // },
  // {
  //   municipio: "Toril",
  //   provincia: "Cáceres",
  //   id: "10182",
  //   label: "Toril (Cáceres)",
  // },
  // {
  //   municipio: "Tornavacas",
  //   provincia: "Cáceres",
  //   id: "10183",
  //   label: "Tornavacas (Cáceres)",
  // },
  // {
  //   municipio: "Torremenga",
  //   provincia: "Cáceres",
  //   id: "10191",
  //   label: "Torremenga (Cáceres)",
  // },
  // {
  //   municipio: "Torno, El",
  //   provincia: "Cáceres",
  //   id: "10184",
  //   label: "Torno, El (Cáceres)",
  // },
  // {
  //   municipio: "Torrecilla de los Ángeles",
  //   provincia: "Cáceres",
  //   id: "10185",
  //   label: "Torrecilla de los Ángeles (Cáceres)",
  // },
  // {
  //   municipio: "Torrecillas de la Tiesa",
  //   provincia: "Cáceres",
  //   id: "10186",
  //   label: "Torrecillas de la Tiesa (Cáceres)",
  // },
  // {
  //   municipio: "Torrejoncillo",
  //   provincia: "Cáceres",
  //   id: "10189",
  //   label: "Torrejoncillo (Cáceres)",
  // },
  // {
  //   municipio: "Torrejón el Rubio",
  //   provincia: "Cáceres",
  //   id: "10190",
  //   label: "Torrejón el Rubio (Cáceres)",
  // },
  // {
  //   municipio: "Torremocha",
  //   provincia: "Cáceres",
  //   id: "10192",
  //   label: "Torremocha (Cáceres)",
  // },
  // {
  //   municipio: "Valdeobispo",
  //   provincia: "Cáceres",
  //   id: "10202",
  //   label: "Valdeobispo (Cáceres)",
  // },
  // {
  //   municipio: "Torreorgaz",
  //   provincia: "Cáceres",
  //   id: "10193",
  //   label: "Torreorgaz (Cáceres)",
  // },
  // {
  //   municipio: "Azuébar",
  //   provincia: "Castellón",
  //   id: "12018",
  //   label: "Azuébar (Castellón)",
  // },
  // {
  //   municipio: "Torrequemada",
  //   provincia: "Cáceres",
  //   id: "10194",
  //   label: "Torrequemada (Cáceres)",
  // },
  // {
  //   municipio: "Valdastillas",
  //   provincia: "Cáceres",
  //   id: "10196",
  //   label: "Valdastillas (Cáceres)",
  // },
  // {
  //   municipio: "Valdecañas de Tajo",
  //   provincia: "Cáceres",
  //   id: "10197",
  //   label: "Valdecañas de Tajo (Cáceres)",
  // },
  // {
  //   municipio: "Valverde de la Vera",
  //   provincia: "Cáceres",
  //   id: "10204",
  //   label: "Valverde de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Valdefuentes",
  //   provincia: "Cáceres",
  //   id: "10198",
  //   label: "Valdefuentes (Cáceres)",
  // },
  // {
  //   municipio: "Valdehúncar",
  //   provincia: "Cáceres",
  //   id: "10199",
  //   label: "Valdehúncar (Cáceres)",
  // },
  // {
  //   municipio: "Valdelacasa de Tajo",
  //   provincia: "Cáceres",
  //   id: "10200",
  //   label: "Valdelacasa de Tajo (Cáceres)",
  // },
  // {
  //   municipio: "Valdemorales",
  //   provincia: "Cáceres",
  //   id: "10201",
  //   label: "Valdemorales (Cáceres)",
  // },
  // {
  //   municipio: "Barracas",
  //   provincia: "Castellón",
  //   id: "12020",
  //   label: "Barracas (Castellón)",
  // },
  // {
  //   municipio: "Viandar de la Vera",
  //   provincia: "Cáceres",
  //   id: "10206",
  //   label: "Viandar de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Villar de Plasencia",
  //   provincia: "Cáceres",
  //   id: "10214",
  //   label: "Villar de Plasencia (Cáceres)",
  // },
  // {
  //   municipio: "Villa del Campo",
  //   provincia: "Cáceres",
  //   id: "10207",
  //   label: "Villa del Campo (Cáceres)",
  // },
  // {
  //   municipio: "Línea de la Concepción, La",
  //   provincia: "Cádiz",
  //   id: "11022",
  //   label: "Línea de la Concepción, La (Cádiz)",
  // },
  // {
  //   municipio: "Olvera",
  //   provincia: "Cádiz",
  //   id: "11024",
  //   label: "Olvera (Cádiz)",
  // },
  // {
  //   municipio: "Paterna de Rivera",
  //   provincia: "Cádiz",
  //   id: "11025",
  //   label: "Paterna de Rivera (Cádiz)",
  // },
  // {
  //   municipio: "Villa del Rey",
  //   provincia: "Cáceres",
  //   id: "10208",
  //   label: "Villa del Rey (Cáceres)",
  // },
  // {
  //   municipio: "Villamesías",
  //   provincia: "Cáceres",
  //   id: "10209",
  //   label: "Villamesías (Cáceres)",
  // },
  // {
  //   municipio: "Prado del Rey",
  //   provincia: "Cádiz",
  //   id: "11026",
  //   label: "Prado del Rey (Cádiz)",
  // },
  // {
  //   municipio: "Villamiel",
  //   provincia: "Cáceres",
  //   id: "10210",
  //   label: "Villamiel (Cáceres)",
  // },
  // {
  //   municipio: "San Fernando",
  //   provincia: "Cádiz",
  //   id: "11031",
  //   label: "San Fernando (Cádiz)",
  // },
  // {
  //   municipio: "Betxí",
  //   provincia: "Castellón",
  //   id: "12021",
  //   label: "Betxí (Castellón)",
  // },
  // {
  //   municipio: "Villanueva de la Sierra",
  //   provincia: "Cáceres",
  //   id: "10211",
  //   label: "Villanueva de la Sierra (Cáceres)",
  // },
  // {
  //   municipio: "Torre Alháquime",
  //   provincia: "Cádiz",
  //   id: "11036",
  //   label: "Torre Alháquime (Cádiz)",
  // },
  // {
  //   municipio: "Villanueva de la Vera",
  //   provincia: "Cáceres",
  //   id: "10212",
  //   label: "Villanueva de la Vera (Cáceres)",
  // },
  // {
  //   municipio: "Villar del Pedroso",
  //   provincia: "Cáceres",
  //   id: "10213",
  //   label: "Villar del Pedroso (Cáceres)",
  // },
  // {
  //   municipio: "Trebujena",
  //   provincia: "Cádiz",
  //   id: "11037",
  //   label: "Trebujena (Cádiz)",
  // },
  // {
  //   municipio: "Villasbuenas de Gata",
  //   provincia: "Cáceres",
  //   id: "10215",
  //   label: "Villasbuenas de Gata (Cáceres)",
  // },
  // {
  //   municipio: "Zarza de Granadilla",
  //   provincia: "Cáceres",
  //   id: "10216",
  //   label: "Zarza de Granadilla (Cáceres)",
  // },
  // {
  //   municipio: "Argelita",
  //   provincia: "Castellón",
  //   id: "12015",
  //   label: "Argelita (Castellón)",
  // },
  // {
  //   municipio: "Zarza de Montánchez",
  //   provincia: "Cáceres",
  //   id: "10217",
  //   label: "Zarza de Montánchez (Cáceres)",
  // },
  // {
  //   municipio: "Zarza la Mayor",
  //   provincia: "Cáceres",
  //   id: "10218",
  //   label: "Zarza la Mayor (Cáceres)",
  // },
  // {
  //   municipio: "Zorita",
  //   provincia: "Cáceres",
  //   id: "10219",
  //   label: "Zorita (Cáceres)",
  // },
  // {
  //   municipio: "Artana",
  //   provincia: "Castellón",
  //   id: "12016",
  //   label: "Artana (Castellón)",
  // },
  // {
  //   municipio: "Alcalá del Valle",
  //   provincia: "Cádiz",
  //   id: "11002",
  //   label: "Alcalá del Valle (Cádiz)",
  // },
  // {
  //   municipio: "Algar",
  //   provincia: "Cádiz",
  //   id: "11003",
  //   label: "Algar (Cádiz)",
  // },
  // {
  //   municipio: "Algodonales",
  //   provincia: "Cádiz",
  //   id: "11005",
  //   label: "Algodonales (Cádiz)",
  // },
  // {
  //   municipio: "Benaocaz",
  //   provincia: "Cádiz",
  //   id: "11009",
  //   label: "Benaocaz (Cádiz)",
  // },
  // {
  //   municipio: "Bornos",
  //   provincia: "Cádiz",
  //   id: "11010",
  //   label: "Bornos (Cádiz)",
  // },
  // {
  //   municipio: "Aín",
  //   provincia: "Castellón",
  //   id: "12002",
  //   label: "Aín (Castellón)",
  // },
  // {
  //   municipio: "Bosque, El",
  //   provincia: "Cádiz",
  //   id: "11011",
  //   label: "Bosque, El (Cádiz)",
  // },
  // {
  //   municipio: "Cádiz",
  //   provincia: "Cádiz",
  //   id: "11012",
  //   label: "Cádiz (Cádiz)",
  // },
  // {
  //   municipio: "Alcudia de Veo",
  //   provincia: "Castellón",
  //   id: "12006",
  //   label: "Alcudia de Veo (Castellón)",
  // },
  // {
  //   municipio: "Chiclana de la Frontera",
  //   provincia: "Cádiz",
  //   id: "11015",
  //   label: "Chiclana de la Frontera (Cádiz)",
  // },
  // {
  //   municipio: "Chipiona",
  //   provincia: "Cádiz",
  //   id: "11016",
  //   label: "Chipiona (Cádiz)",
  // },
  // {
  //   municipio: "Espera",
  //   provincia: "Cádiz",
  //   id: "11017",
  //   label: "Espera (Cádiz)",
  // },
  // {
  //   municipio: "Santa Cruz de los Cáñamos",
  //   provincia: "Ciudad Real",
  //   id: "13076",
  //   label: "Santa Cruz de los Cáñamos (Ciudad Real)",
  // },
  // {
  //   municipio: "Santa Cruz de Mudela",
  //   provincia: "Ciudad Real",
  //   id: "13077",
  //   label: "Santa Cruz de Mudela (Ciudad Real)",
  // },
  // {
  //   municipio: "Gastor, El",
  //   provincia: "Cádiz",
  //   id: "11018",
  //   label: "Gastor, El (Cádiz)",
  // },
  // {
  //   municipio: "Alfondeguilla",
  //   provincia: "Castellón",
  //   id: "12007",
  //   label: "Alfondeguilla (Castellón)",
  // },
  // {
  //   municipio: "Grazalema",
  //   provincia: "Cádiz",
  //   id: "11019",
  //   label: "Grazalema (Cádiz)",
  // },
  // {
  //   municipio: "Puerto Serrano",
  //   provincia: "Cádiz",
  //   id: "11029",
  //   label: "Puerto Serrano (Cádiz)",
  // },
  // {
  //   municipio: "Rota",
  //   provincia: "Cádiz",
  //   id: "11030",
  //   label: "Rota (Cádiz)",
  // },
  // {
  //   municipio: "Sanlúcar de Barrameda",
  //   provincia: "Cádiz",
  //   id: "11032",
  //   label: "Sanlúcar de Barrameda (Cádiz)",
  // },
  // {
  //   municipio: "Solana, La",
  //   provincia: "Ciudad Real",
  //   id: "13079",
  //   label: "Solana, La (Ciudad Real)",
  // },
  // {
  //   municipio: "Solana del Pino",
  //   provincia: "Ciudad Real",
  //   id: "13080",
  //   label: "Solana del Pino (Ciudad Real)",
  // },
  // {
  //   municipio: "Setenil de las Bodegas",
  //   provincia: "Cádiz",
  //   id: "11034",
  //   label: "Setenil de las Bodegas (Cádiz)",
  // },
  // {
  //   municipio: "Ubrique",
  //   provincia: "Cádiz",
  //   id: "11038",
  //   label: "Ubrique (Cádiz)",
  // },
  // {
  //   municipio: "Villaluenga del Rosario",
  //   provincia: "Cádiz",
  //   id: "11040",
  //   label: "Villaluenga del Rosario (Cádiz)",
  // },
  // {
  //   municipio: "Villamartín",
  //   provincia: "Cádiz",
  //   id: "11041",
  //   label: "Villamartín (Cádiz)",
  // },
  // {
  //   municipio: "Zahara",
  //   provincia: "Cádiz",
  //   id: "11042",
  //   label: "Zahara (Cádiz)",
  // },
  // {
  //   municipio: "Benalup-Casas Viejas",
  //   provincia: "Cádiz",
  //   id: "11901",
  //   label: "Benalup-Casas Viejas (Cádiz)",
  // },
  // {
  //   municipio: "Atzeneta del Maestrat",
  //   provincia: "Castellón",
  //   id: "12001",
  //   label: "Atzeneta del Maestrat (Castellón)",
  // },
  // {
  //   municipio: "Rebollosa de Jadraque",
  //   provincia: "Guadalajara",
  //   id: "19231",
  //   label: "Rebollosa de Jadraque (Guadalajara)",
  // },
  // {
  //   municipio: "Albocàsser",
  //   provincia: "Castellón",
  //   id: "12003",
  //   label: "Albocàsser (Castellón)",
  // },
  // {
  //   municipio: "Alcalà de Xivert",
  //   provincia: "Castellón",
  //   id: "12004",
  //   label: "Alcalà de Xivert (Castellón)",
  // },
  // {
  //   municipio: "Alcora, l'",
  //   provincia: "Castellón",
  //   id: "12005",
  //   label: "Alcora, l' (Castellón)",
  // },
  // {
  //   municipio: "Algimia de Almonacid",
  //   provincia: "Castellón",
  //   id: "12008",
  //   label: "Algimia de Almonacid (Castellón)",
  // },
  // {
  //   municipio: "Altura",
  //   provincia: "Castellón",
  //   id: "12012",
  //   label: "Altura (Castellón)",
  // },
  // {
  //   municipio: "Arañuel",
  //   provincia: "Castellón",
  //   id: "12013",
  //   label: "Arañuel (Castellón)",
  // },
  // {
  //   municipio: "Ares del Maestrat",
  //   provincia: "Castellón",
  //   id: "12014",
  //   label: "Ares del Maestrat (Castellón)",
  // },
  // {
  //   municipio: "Ayódar",
  //   provincia: "Castellón",
  //   id: "12017",
  //   label: "Ayódar (Castellón)",
  // },
  // {
  //   municipio: "Bejís",
  //   provincia: "Castellón",
  //   id: "12022",
  //   label: "Bejís (Castellón)",
  // },
  // {
  //   municipio: "Benafer",
  //   provincia: "Castellón",
  //   id: "12024",
  //   label: "Benafer (Castellón)",
  // },
  // {
  //   municipio: "Benafigos",
  //   provincia: "Castellón",
  //   id: "12025",
  //   label: "Benafigos (Castellón)",
  // },
  // {
  //   municipio: "Benassal",
  //   provincia: "Castellón",
  //   id: "12026",
  //   label: "Benassal (Castellón)",
  // },
  // {
  //   municipio: "Benicarló",
  //   provincia: "Castellón",
  //   id: "12027",
  //   label: "Benicarló (Castellón)",
  // },
  // {
  //   municipio: "Benicàssim/Benicasim",
  //   provincia: "Castellón",
  //   id: "12028",
  //   label: "Benicàssim/Benicasim (Castellón)",
  // },
  // {
  //   municipio: "Benlloc",
  //   provincia: "Castellón",
  //   id: "12029",
  //   label: "Benlloc (Castellón)",
  // },
  // {
  //   municipio: "Borriol",
  //   provincia: "Castellón",
  //   id: "12031",
  //   label: "Borriol (Castellón)",
  // },
  // {
  //   municipio: "Cabanes",
  //   provincia: "Castellón",
  //   id: "12033",
  //   label: "Cabanes (Castellón)",
  // },
  // {
  //   municipio: "Cueva del Hierro",
  //   provincia: "Cuenca",
  //   id: "16079",
  //   label: "Cueva del Hierro (Cuenca)",
  // },
  // {
  //   municipio: "Càlig",
  //   provincia: "Castellón",
  //   id: "12034",
  //   label: "Càlig (Castellón)",
  // },
  // {
  //   municipio: "Canet lo Roig",
  //   provincia: "Castellón",
  //   id: "12036",
  //   label: "Canet lo Roig (Castellón)",
  // },
  // {
  //   municipio: "Saelices de la Sal",
  //   provincia: "Guadalajara",
  //   id: "19246",
  //   label: "Saelices de la Sal (Guadalajara)",
  // },
  // {
  //   municipio: "Castell de Cabres",
  //   provincia: "Castellón",
  //   id: "12037",
  //   label: "Castell de Cabres (Castellón)",
  // },
  // {
  //   municipio: "Castellnovo",
  //   provincia: "Castellón",
  //   id: "12039",
  //   label: "Castellnovo (Castellón)",
  // },
  // {
  //   municipio: "Castellfort",
  //   provincia: "Castellón",
  //   id: "12038",
  //   label: "Castellfort (Castellón)",
  // },
  // {
  //   municipio: "Castillo de Villamalefa",
  //   provincia: "Castellón",
  //   id: "12041",
  //   label: "Castillo de Villamalefa (Castellón)",
  // },
  // {
  //   municipio: "Catí",
  //   provincia: "Castellón",
  //   id: "12042",
  //   label: "Catí (Castellón)",
  // },
  // {
  //   municipio: "Caudiel",
  //   provincia: "Castellón",
  //   id: "12043",
  //   label: "Caudiel (Castellón)",
  // },
  // {
  //   municipio: "Cirat",
  //   provincia: "Castellón",
  //   id: "12046",
  //   label: "Cirat (Castellón)",
  // },
  // {
  //   municipio: "Cervera del Maestre",
  //   provincia: "Castellón",
  //   id: "12044",
  //   label: "Cervera del Maestre (Castellón)",
  // },
  // {
  //   municipio: "Cinctorres",
  //   provincia: "Castellón",
  //   id: "12045",
  //   label: "Cinctorres (Castellón)",
  // },
  // {
  //   municipio: "Cortes de Arenoso",
  //   provincia: "Castellón",
  //   id: "12048",
  //   label: "Cortes de Arenoso (Castellón)",
  // },
  // {
  //   municipio: "Xodos/Chodos",
  //   provincia: "Castellón",
  //   id: "12055",
  //   label: "Xodos/Chodos (Castellón)",
  // },
  // {
  //   municipio: "Costur",
  //   provincia: "Castellón",
  //   id: "12049",
  //   label: "Costur (Castellón)",
  // },
  // {
  //   municipio: "Chóvar",
  //   provincia: "Castellón",
  //   id: "12056",
  //   label: "Chóvar (Castellón)",
  // },
  // {
  //   municipio: "Coves de Vinromà, les",
  //   provincia: "Castellón",
  //   id: "12050",
  //   label: "Coves de Vinromà, les (Castellón)",
  // },
  // {
  //   municipio: "Culla",
  //   provincia: "Castellón",
  //   id: "12051",
  //   label: "Culla (Castellón)",
  // },
  // {
  //   municipio: "Santiuste",
  //   provincia: "Guadalajara",
  //   id: "19250",
  //   label: "Santiuste (Guadalajara)",
  // },
  // {
  //   municipio: "Xert",
  //   provincia: "Castellón",
  //   id: "12052",
  //   label: "Xert (Castellón)",
  // },
  // {
  //   municipio: "Chilches/Xilxes",
  //   provincia: "Castellón",
  //   id: "12053",
  //   label: "Chilches/Xilxes (Castellón)",
  // },
  // {
  //   municipio: "Fanzara",
  //   provincia: "Castellón",
  //   id: "12059",
  //   label: "Fanzara (Castellón)",
  // },
  // {
  //   municipio: "Figueroles",
  //   provincia: "Castellón",
  //   id: "12060",
  //   label: "Figueroles (Castellón)",
  // },
  // {
  //   municipio: "Ludiente",
  //   provincia: "Castellón",
  //   id: "12073",
  //   label: "Ludiente (Castellón)",
  // },
  // {
  //   municipio: "Forcall",
  //   provincia: "Castellón",
  //   id: "12061",
  //   label: "Forcall (Castellón)",
  // },
  // {
  //   municipio: "Montanejos",
  //   provincia: "Castellón",
  //   id: "12079",
  //   label: "Montanejos (Castellón)",
  // },
  // {
  //   municipio: "Fuente la Reina",
  //   provincia: "Castellón",
  //   id: "12063",
  //   label: "Fuente la Reina (Castellón)",
  // },
  // {
  //   municipio: "Pina de Montalgrao",
  //   provincia: "Castellón",
  //   id: "12090",
  //   label: "Pina de Montalgrao (Castellón)",
  // },
  // {
  //   municipio: "Portell de Morella",
  //   provincia: "Castellón",
  //   id: "12091",
  //   label: "Portell de Morella (Castellón)",
  // },
  // {
  //   municipio: "Gaibiel",
  //   provincia: "Castellón",
  //   id: "12065",
  //   label: "Gaibiel (Castellón)",
  // },
  // {
  //   municipio: "Geldo",
  //   provincia: "Castellón",
  //   id: "12067",
  //   label: "Geldo (Castellón)",
  // },
  // {
  //   municipio: "Navajas",
  //   provincia: "Castellón",
  //   id: "12081",
  //   label: "Navajas (Castellón)",
  // },
  // {
  //   municipio: "Herbers",
  //   provincia: "Castellón",
  //   id: "12068",
  //   label: "Herbers (Castellón)",
  // },
  // {
  //   municipio: "Higueras",
  //   provincia: "Castellón",
  //   id: "12069",
  //   label: "Higueras (Castellón)",
  // },
  // {
  //   municipio: "Jana, la",
  //   provincia: "Castellón",
  //   id: "12070",
  //   label: "Jana, la (Castellón)",
  // },
  // {
  //   municipio: "Jérica",
  //   provincia: "Castellón",
  //   id: "12071",
  //   label: "Jérica (Castellón)",
  // },
  // {
  //   municipio: "Llucena/Lucena del Cid",
  //   provincia: "Castellón",
  //   id: "12072",
  //   label: "Llucena/Lucena del Cid (Castellón)",
  // },
  // {
  //   municipio: "Puebla de Arenoso",
  //   provincia: "Castellón",
  //   id: "12092",
  //   label: "Puebla de Arenoso (Castellón)",
  // },
  // {
  //   municipio: "Llosa, la",
  //   provincia: "Castellón",
  //   id: "12074",
  //   label: "Llosa, la (Castellón)",
  // },
  // {
  //   municipio: "Pobla Tornesa, la",
  //   provincia: "Castellón",
  //   id: "12094",
  //   label: "Pobla Tornesa, la (Castellón)",
  // },
  // {
  //   municipio: "Mata de Morella, la",
  //   provincia: "Castellón",
  //   id: "12075",
  //   label: "Mata de Morella, la (Castellón)",
  // },
  // {
  //   municipio: "Matet",
  //   provincia: "Castellón",
  //   id: "12076",
  //   label: "Matet (Castellón)",
  // },
  // {
  //   municipio: "Moncofa",
  //   provincia: "Castellón",
  //   id: "12077",
  //   label: "Moncofa (Castellón)",
  // },
  // {
  //   municipio: "Montán",
  //   provincia: "Castellón",
  //   id: "12078",
  //   label: "Montán (Castellón)",
  // },
  // {
  //   municipio: "Yélamos de Abajo",
  //   provincia: "Guadalajara",
  //   id: "19329",
  //   label: "Yélamos de Abajo (Guadalajara)",
  // },
  // {
  //   municipio: "Olocau del Rey",
  //   provincia: "Castellón",
  //   id: "12083",
  //   label: "Olocau del Rey (Castellón)",
  // },
  // {
  //   municipio: "Onda",
  //   provincia: "Castellón",
  //   id: "12084",
  //   label: "Onda (Castellón)",
  // },
  // {
  //   municipio: "Orpesa/Oropesa del Mar",
  //   provincia: "Castellón",
  //   id: "12085",
  //   label: "Orpesa/Oropesa del Mar (Castellón)",
  // },
  // {
  //   municipio: "Ribesalbes",
  //   provincia: "Castellón",
  //   id: "12095",
  //   label: "Ribesalbes (Castellón)",
  // },
  // {
  //   municipio: "Rossell",
  //   provincia: "Castellón",
  //   id: "12096",
  //   label: "Rossell (Castellón)",
  // },
  // {
  //   municipio: "Palanques",
  //   provincia: "Castellón",
  //   id: "12087",
  //   label: "Palanques (Castellón)",
  // },
  // {
  //   municipio: "Pavías",
  //   provincia: "Castellón",
  //   id: "12088",
  //   label: "Pavías (Castellón)",
  // },
  // {
  //   municipio: "Mestanza",
  //   provincia: "Ciudad Real",
  //   id: "13055",
  //   label: "Mestanza (Ciudad Real)",
  // },
  // {
  //   municipio: "Valdecolmenas, Los",
  //   provincia: "Cuenca",
  //   id: "16906",
  //   label: "Valdecolmenas, Los (Cuenca)",
  // },
  // {
  //   municipio: "Pobla de Benifassà, la",
  //   provincia: "Castellón",
  //   id: "12093",
  //   label: "Pobla de Benifassà, la (Castellón)",
  // },
  // {
  //   municipio: "Sacañet",
  //   provincia: "Castellón",
  //   id: "12097",
  //   label: "Sacañet (Castellón)",
  // },
  // {
  //   municipio: "Sant Mateu",
  //   provincia: "Castellón",
  //   id: "12100",
  //   label: "Sant Mateu (Castellón)",
  // },
  // {
  //   municipio: "Salzadella, la",
  //   provincia: "Castellón",
  //   id: "12098",
  //   label: "Salzadella, la (Castellón)",
  // },
  // {
  //   municipio: "Sant Jordi/San Jorge",
  //   provincia: "Castellón",
  //   id: "12099",
  //   label: "Sant Jordi/San Jorge (Castellón)",
  // },
  // {
  //   municipio: "San Rafael del Río",
  //   provincia: "Castellón",
  //   id: "12101",
  //   label: "San Rafael del Río (Castellón)",
  // },
  // {
  //   municipio: "Santa Magdalena de Pulpis",
  //   provincia: "Castellón",
  //   id: "12102",
  //   label: "Santa Magdalena de Pulpis (Castellón)",
  // },
  // {
  //   municipio: "Serratella, la",
  //   provincia: "Castellón",
  //   id: "12103",
  //   label: "Serratella, la (Castellón)",
  // },
  // {
  //   municipio: "Segorbe",
  //   provincia: "Castellón",
  //   id: "12104",
  //   label: "Segorbe (Castellón)",
  // },
  // {
  //   municipio: "Soneja",
  //   provincia: "Castellón",
  //   id: "12106",
  //   label: "Soneja (Castellón)",
  // },
  // {
  //   municipio: "Traíd",
  //   provincia: "Guadalajara",
  //   id: "19289",
  //   label: "Traíd (Guadalajara)",
  // },
  // {
  //   municipio: "Sierra Engarcerán",
  //   provincia: "Castellón",
  //   id: "12105",
  //   label: "Sierra Engarcerán (Castellón)",
  // },
  // {
  //   municipio: "Uceda",
  //   provincia: "Guadalajara",
  //   id: "19293",
  //   label: "Uceda (Guadalajara)",
  // },
  // {
  //   municipio: "Sot de Ferrer",
  //   provincia: "Castellón",
  //   id: "12107",
  //   label: "Sot de Ferrer (Castellón)",
  // },
  // {
  //   municipio: "Suera/Sueras",
  //   provincia: "Castellón",
  //   id: "12108",
  //   label: "Suera/Sueras (Castellón)",
  // },
  // {
  //   municipio: "Tales",
  //   provincia: "Castellón",
  //   id: "12109",
  //   label: "Tales (Castellón)",
  // },
  // {
  //   municipio: "Teresa",
  //   provincia: "Castellón",
  //   id: "12110",
  //   label: "Teresa (Castellón)",
  // },
  // {
  //   municipio: "Todolella",
  //   provincia: "Castellón",
  //   id: "12112",
  //   label: "Todolella (Castellón)",
  // },
  // {
  //   municipio: "Tírig",
  //   provincia: "Castellón",
  //   id: "12111",
  //   label: "Tírig (Castellón)",
  // },
  // {
  //   municipio: "Toga",
  //   provincia: "Castellón",
  //   id: "12113",
  //   label: "Toga (Castellón)",
  // },
  // {
  //   municipio: "Torás",
  //   provincia: "Castellón",
  //   id: "12114",
  //   label: "Torás (Castellón)",
  // },
  // {
  //   municipio: "Torralba del Pinar",
  //   provincia: "Castellón",
  //   id: "12116",
  //   label: "Torralba del Pinar (Castellón)",
  // },
  // {
  //   municipio: "Toro, El",
  //   provincia: "Castellón",
  //   id: "12115",
  //   label: "Toro, El (Castellón)",
  // },
  // {
  //   municipio: "Torreblanca",
  //   provincia: "Castellón",
  //   id: "12117",
  //   label: "Torreblanca (Castellón)",
  // },
  // {
  //   municipio: "Vila-real",
  //   provincia: "Castellón",
  //   id: "12135",
  //   label: "Vila-real (Castellón)",
  // },
  // {
  //   municipio: "Traiguera",
  //   provincia: "Castellón",
  //   id: "12121",
  //   label: "Traiguera (Castellón)",
  // },
  // {
  //   municipio: "Vall d'Alba",
  //   provincia: "Castellón",
  //   id: "12124",
  //   label: "Vall d'Alba (Castellón)",
  // },
  // {
  //   municipio: "Useres, les/Useras",
  //   provincia: "Castellón",
  //   id: "12122",
  //   label: "Useres, les/Useras (Castellón)",
  // },
  // {
  //   municipio: "Vallibona",
  //   provincia: "Castellón",
  //   id: "12127",
  //   label: "Vallibona (Castellón)",
  // },
  // {
  //   municipio: "Vilafamés",
  //   provincia: "Castellón",
  //   id: "12128",
  //   label: "Vilafamés (Castellón)",
  // },
  // {
  //   municipio: "Vilafranca/Villafranca del Cid",
  //   provincia: "Castellón",
  //   id: "12129",
  //   label: "Vilafranca/Villafranca del Cid (Castellón)",
  // },
  // {
  //   municipio: "Villamalur",
  //   provincia: "Castellón",
  //   id: "12131",
  //   label: "Villamalur (Castellón)",
  // },
  // {
  //   municipio: "Villahermosa del Río",
  //   provincia: "Castellón",
  //   id: "12130",
  //   label: "Villahermosa del Río (Castellón)",
  // },
  // {
  //   municipio: "Ujados",
  //   provincia: "Guadalajara",
  //   id: "19294",
  //   label: "Ujados (Guadalajara)",
  // },
  // {
  //   municipio: "Vilanova d'Alcolea",
  //   provincia: "Castellón",
  //   id: "12132",
  //   label: "Vilanova d'Alcolea (Castellón)",
  // },
  // {
  //   municipio: "Adobes",
  //   provincia: "Guadalajara",
  //   id: "19003",
  //   label: "Adobes (Guadalajara)",
  // },
  // {
  //   municipio: "Villanueva de Viver",
  //   provincia: "Castellón",
  //   id: "12133",
  //   label: "Villanueva de Viver (Castellón)",
  // },
  // {
  //   municipio: "Vilar de Canes",
  //   provincia: "Castellón",
  //   id: "12134",
  //   label: "Vilar de Canes (Castellón)",
  // },
  // {
  //   municipio: "Villamandos",
  //   provincia: "León",
  //   id: "24211",
  //   label: "Villamandos (León)",
  // },
  // {
  //   municipio: "Viver",
  //   provincia: "Castellón",
  //   id: "12140",
  //   label: "Viver (Castellón)",
  // },
  // {
  //   municipio: "Alamillo",
  //   provincia: "Ciudad Real",
  //   id: "13003",
  //   label: "Alamillo (Ciudad Real)",
  // },
  // {
  //   municipio: "Zorita del Maestrazgo",
  //   provincia: "Castellón",
  //   id: "12141",
  //   label: "Zorita del Maestrazgo (Castellón)",
  // },
  // {
  //   municipio: "Motilla del Palancar",
  //   provincia: "Cuenca",
  //   id: "16134",
  //   label: "Motilla del Palancar (Cuenca)",
  // },
  // {
  //   municipio: "Zucaina",
  //   provincia: "Castellón",
  //   id: "12142",
  //   label: "Zucaina (Castellón)",
  // },
  // {
  //   municipio: "Albaladejo",
  //   provincia: "Ciudad Real",
  //   id: "13004",
  //   label: "Albaladejo (Ciudad Real)",
  // },
  // {
  //   municipio: "Alqueries, les/Alquerías del Niño Perdido",
  //   provincia: "Castellón",
  //   id: "12901",
  //   label: "Alqueries, les/Alquerías del Niño Perdido (Castellón)",
  // },
  // {
  //   municipio: "Alcubillas",
  //   provincia: "Ciudad Real",
  //   id: "13008",
  //   label: "Alcubillas (Ciudad Real)",
  // },
  // {
  //   municipio: "Sant Joan de Moró",
  //   provincia: "Castellón",
  //   id: "12902",
  //   label: "Sant Joan de Moró (Castellón)",
  // },
  // {
  //   municipio: "Agudo",
  //   provincia: "Ciudad Real",
  //   id: "13002",
  //   label: "Agudo (Ciudad Real)",
  // },
  // {
  //   municipio: "Alcoba",
  //   provincia: "Ciudad Real",
  //   id: "13006",
  //   label: "Alcoba (Ciudad Real)",
  // },
  // {
  //   municipio: "Valdegeña",
  //   provincia: "Soria",
  //   id: "42192",
  //   label: "Valdegeña (Soria)",
  // },
  // {
  //   municipio: "Alcolea de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13007",
  //   label: "Alcolea de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Aldea del Rey",
  //   provincia: "Ciudad Real",
  //   id: "13009",
  //   label: "Aldea del Rey (Ciudad Real)",
  // },
  // {
  //   municipio: "Almadén",
  //   provincia: "Ciudad Real",
  //   id: "13011",
  //   label: "Almadén (Ciudad Real)",
  // },
  // {
  //   municipio: "Casa de Uceda",
  //   provincia: "Guadalajara",
  //   id: "19070",
  //   label: "Casa de Uceda (Guadalajara)",
  // },
  // {
  //   municipio: "Almadenejos",
  //   provincia: "Ciudad Real",
  //   id: "13012",
  //   label: "Almadenejos (Ciudad Real)",
  // },
  // {
  //   municipio: "Almagro",
  //   provincia: "Ciudad Real",
  //   id: "13013",
  //   label: "Almagro (Ciudad Real)",
  // },
  // {
  //   municipio: "Almedina",
  //   provincia: "Ciudad Real",
  //   id: "13014",
  //   label: "Almedina (Ciudad Real)",
  // },
  // {
  //   municipio: "Almuradiel",
  //   provincia: "Ciudad Real",
  //   id: "13016",
  //   label: "Almuradiel (Ciudad Real)",
  // },
  // {
  //   municipio: "Anchuras",
  //   provincia: "Ciudad Real",
  //   id: "13017",
  //   label: "Anchuras (Ciudad Real)",
  // },
  // {
  //   municipio: "Arenas de San Juan",
  //   provincia: "Ciudad Real",
  //   id: "13018",
  //   label: "Arenas de San Juan (Ciudad Real)",
  // },
  // {
  //   municipio: "Argamasilla de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13020",
  //   label: "Argamasilla de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Arroba de los Montes",
  //   provincia: "Ciudad Real",
  //   id: "13021",
  //   label: "Arroba de los Montes (Ciudad Real)",
  // },
  // {
  //   municipio: "Caspueñas",
  //   provincia: "Guadalajara",
  //   id: "19074",
  //   label: "Caspueñas (Guadalajara)",
  // },
  // {
  //   municipio: "Bolaños de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13023",
  //   label: "Bolaños de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Brazatortas",
  //   provincia: "Ciudad Real",
  //   id: "13024",
  //   label: "Brazatortas (Ciudad Real)",
  // },
  // {
  //   municipio: "Caracuel de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13030",
  //   label: "Caracuel de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Carrión de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13031",
  //   label: "Carrión de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Cabezarados",
  //   provincia: "Ciudad Real",
  //   id: "13025",
  //   label: "Cabezarados (Ciudad Real)",
  // },
  // {
  //   municipio: "Cabezarrubias del Puerto",
  //   provincia: "Ciudad Real",
  //   id: "13026",
  //   label: "Cabezarrubias del Puerto (Ciudad Real)",
  // },
  // {
  //   municipio: "Calzada de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13027",
  //   label: "Calzada de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Campo de Criptana",
  //   provincia: "Ciudad Real",
  //   id: "13028",
  //   label: "Campo de Criptana (Ciudad Real)",
  // },
  // {
  //   municipio: "Castellar de Santiago",
  //   provincia: "Ciudad Real",
  //   id: "13033",
  //   label: "Castellar de Santiago (Ciudad Real)",
  // },
  // {
  //   municipio: "Corral de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13035",
  //   label: "Corral de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Valenzuela de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13088",
  //   label: "Valenzuela de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Cortijos, Los",
  //   provincia: "Ciudad Real",
  //   id: "13036",
  //   label: "Cortijos, Los (Ciudad Real)",
  // },
  // {
  //   municipio: "Villar del Pozo",
  //   provincia: "Ciudad Real",
  //   id: "13095",
  //   label: "Villar del Pozo (Ciudad Real)",
  // },
  // {
  //   municipio: "Cózar",
  //   provincia: "Ciudad Real",
  //   id: "13037",
  //   label: "Cózar (Ciudad Real)",
  // },
  // {
  //   municipio: "Villarrubia de los Ojos",
  //   provincia: "Ciudad Real",
  //   id: "13096",
  //   label: "Villarrubia de los Ojos (Ciudad Real)",
  // },
  // {
  //   municipio: "Alcázar del Rey",
  //   provincia: "Cuenca",
  //   id: "16010",
  //   label: "Alcázar del Rey (Cuenca)",
  // },
  // {
  //   municipio: "Chillón",
  //   provincia: "Ciudad Real",
  //   id: "13038",
  //   label: "Chillón (Ciudad Real)",
  // },
  // {
  //   municipio: "Fernán Caballero",
  //   provincia: "Ciudad Real",
  //   id: "13040",
  //   label: "Fernán Caballero (Ciudad Real)",
  // },
  // {
  //   municipio: "Fontanarejo",
  //   provincia: "Ciudad Real",
  //   id: "13041",
  //   label: "Fontanarejo (Ciudad Real)",
  // },
  // {
  //   municipio: "Fuencaliente",
  //   provincia: "Ciudad Real",
  //   id: "13042",
  //   label: "Fuencaliente (Ciudad Real)",
  // },
  // {
  //   municipio: "Fuente el Fresno",
  //   provincia: "Ciudad Real",
  //   id: "13044",
  //   label: "Fuente el Fresno (Ciudad Real)",
  // },
  // {
  //   municipio: "Alcohujate",
  //   provincia: "Cuenca",
  //   id: "16011",
  //   label: "Alcohujate (Cuenca)",
  // },
  // {
  //   municipio: "Granátula de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13045",
  //   label: "Granátula de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Guadalmez",
  //   provincia: "Ciudad Real",
  //   id: "13046",
  //   label: "Guadalmez (Ciudad Real)",
  // },
  // {
  //   municipio: "Hinojosas de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13048",
  //   label: "Hinojosas de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Horcajo de los Montes",
  //   provincia: "Ciudad Real",
  //   id: "13049",
  //   label: "Horcajo de los Montes (Ciudad Real)",
  // },
  // {
  //   municipio: "Llanos del Caudillo",
  //   provincia: "Ciudad Real",
  //   id: "13904",
  //   label: "Llanos del Caudillo (Ciudad Real)",
  // },
  // {
  //   municipio: "Labores, Las",
  //   provincia: "Ciudad Real",
  //   id: "13050",
  //   label: "Labores, Las (Ciudad Real)",
  // },
  // {
  //   municipio: "Adamuz",
  //   provincia: "Córdoba",
  //   id: "14001",
  //   label: "Adamuz (Córdoba)",
  // },
  // {
  //   municipio: "Almedinilla",
  //   provincia: "Córdoba",
  //   id: "14004",
  //   label: "Almedinilla (Córdoba)",
  // },
  // {
  //   municipio: "Almodóvar del Río",
  //   provincia: "Córdoba",
  //   id: "14005",
  //   label: "Almodóvar del Río (Córdoba)",
  // },
  // {
  //   municipio: "Belalcázar",
  //   provincia: "Córdoba",
  //   id: "14008",
  //   label: "Belalcázar (Córdoba)",
  // },
  // {
  //   municipio: "Gascueña de Bornova",
  //   provincia: "Guadalajara",
  //   id: "19129",
  //   label: "Gascueña de Bornova (Guadalajara)",
  // },
  // {
  //   municipio: "Luciana",
  //   provincia: "Ciudad Real",
  //   id: "13051",
  //   label: "Luciana (Ciudad Real)",
  // },
  // {
  //   municipio: "Malagón",
  //   provincia: "Ciudad Real",
  //   id: "13052",
  //   label: "Malagón (Ciudad Real)",
  // },
  // {
  //   municipio: "Membrilla",
  //   provincia: "Ciudad Real",
  //   id: "13054",
  //   label: "Membrilla (Ciudad Real)",
  // },
  // {
  //   municipio: "Miguelturra",
  //   provincia: "Ciudad Real",
  //   id: "13056",
  //   label: "Miguelturra (Ciudad Real)",
  // },
  // {
  //   municipio: "Moral de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13058",
  //   label: "Moral de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Navalpino",
  //   provincia: "Ciudad Real",
  //   id: "13059",
  //   label: "Navalpino (Ciudad Real)",
  // },
  // {
  //   municipio: "Campo",
  //   provincia: "Huesca",
  //   id: "22074",
  //   label: "Campo (Huesca)",
  // },
  // {
  //   municipio: "Navas de Estena",
  //   provincia: "Ciudad Real",
  //   id: "13060",
  //   label: "Navas de Estena (Ciudad Real)",
  // },
  // {
  //   municipio: "Picón",
  //   provincia: "Ciudad Real",
  //   id: "13062",
  //   label: "Picón (Ciudad Real)",
  // },
  // {
  //   municipio: "Porzuna",
  //   provincia: "Ciudad Real",
  //   id: "13065",
  //   label: "Porzuna (Ciudad Real)",
  // },
  // {
  //   municipio: "Pozuelo de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13066",
  //   label: "Pozuelo de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Pozuelos de Calatrava, Los",
  //   provincia: "Ciudad Real",
  //   id: "13067",
  //   label: "Pozuelos de Calatrava, Los (Ciudad Real)",
  // },
  // {
  //   municipio: "Puebla de Don Rodrigo",
  //   provincia: "Ciudad Real",
  //   id: "13068",
  //   label: "Puebla de Don Rodrigo (Ciudad Real)",
  // },
  // {
  //   municipio: "Puebla del Príncipe",
  //   provincia: "Ciudad Real",
  //   id: "13069",
  //   label: "Puebla del Príncipe (Ciudad Real)",
  // },
  // {
  //   municipio: "Puerto Lápice",
  //   provincia: "Ciudad Real",
  //   id: "13070",
  //   label: "Puerto Lápice (Ciudad Real)",
  // },
  // {
  //   municipio: "Retuerta del Bullaque",
  //   provincia: "Ciudad Real",
  //   id: "13072",
  //   label: "Retuerta del Bullaque (Ciudad Real)",
  // },
  // {
  //   municipio: "Alconchel de la Estrella",
  //   provincia: "Cuenca",
  //   id: "16012",
  //   label: "Alconchel de la Estrella (Cuenca)",
  // },
  // {
  //   municipio: "Saceruela",
  //   provincia: "Ciudad Real",
  //   id: "13073",
  //   label: "Saceruela (Ciudad Real)",
  // },
  // {
  //   municipio: "San Carlos del Valle",
  //   provincia: "Ciudad Real",
  //   id: "13074",
  //   label: "San Carlos del Valle (Ciudad Real)",
  // },
  // {
  //   municipio: "San Lorenzo de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13075",
  //   label: "San Lorenzo de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Terrinches",
  //   provincia: "Ciudad Real",
  //   id: "13081",
  //   label: "Terrinches (Ciudad Real)",
  // },
  // {
  //   municipio: "Tomelloso",
  //   provincia: "Ciudad Real",
  //   id: "13082",
  //   label: "Tomelloso (Ciudad Real)",
  // },
  // {
  //   municipio: "Doña Mencía",
  //   provincia: "Córdoba",
  //   id: "14022",
  //   label: "Doña Mencía (Córdoba)",
  // },
  // {
  //   municipio: "Torralba de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13083",
  //   label: "Torralba de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Torrenueva",
  //   provincia: "Ciudad Real",
  //   id: "13085",
  //   label: "Torrenueva (Ciudad Real)",
  // },
  // {
  //   municipio: "Encinas Reales",
  //   provincia: "Córdoba",
  //   id: "14024",
  //   label: "Encinas Reales (Córdoba)",
  // },
  // {
  //   municipio: "Espejo",
  //   provincia: "Córdoba",
  //   id: "14025",
  //   label: "Espejo (Córdoba)",
  // },
  // {
  //   municipio: "Fernán-Núñez",
  //   provincia: "Córdoba",
  //   id: "14027",
  //   label: "Fernán-Núñez (Córdoba)",
  // },
  // {
  //   municipio: "Brahojos de Medina",
  //   provincia: "Valladolid",
  //   id: "47025",
  //   label: "Brahojos de Medina (Valladolid)",
  // },
  // {
  //   municipio: "Valdemanco del Esteras",
  //   provincia: "Ciudad Real",
  //   id: "13086",
  //   label: "Valdemanco del Esteras (Ciudad Real)",
  // },
  // {
  //   municipio: "Villahermosa",
  //   provincia: "Ciudad Real",
  //   id: "13089",
  //   label: "Villahermosa (Ciudad Real)",
  // },
  // {
  //   municipio: "Villamanrique",
  //   provincia: "Ciudad Real",
  //   id: "13090",
  //   label: "Villamanrique (Ciudad Real)",
  // },
  // {
  //   municipio: "Fuente la Lancha",
  //   provincia: "Córdoba",
  //   id: "14028",
  //   label: "Fuente la Lancha (Córdoba)",
  // },
  // {
  //   municipio: "Villamayor de Calatrava",
  //   provincia: "Ciudad Real",
  //   id: "13091",
  //   label: "Villamayor de Calatrava (Ciudad Real)",
  // },
  // {
  //   municipio: "Fuente Obejuna",
  //   provincia: "Córdoba",
  //   id: "14029",
  //   label: "Fuente Obejuna (Córdoba)",
  // },
  // {
  //   municipio: "Villanueva de la Fuente",
  //   provincia: "Ciudad Real",
  //   id: "13092",
  //   label: "Villanueva de la Fuente (Ciudad Real)",
  // },
  // {
  //   municipio: "Algarra",
  //   provincia: "Cuenca",
  //   id: "16013",
  //   label: "Algarra (Cuenca)",
  // },
  // {
  //   municipio: "Villanueva de los Infantes",
  //   provincia: "Ciudad Real",
  //   id: "13093",
  //   label: "Villanueva de los Infantes (Ciudad Real)",
  // },
  // {
  //   municipio: "Gascueña",
  //   provincia: "Cuenca",
  //   id: "16094",
  //   label: "Gascueña (Cuenca)",
  // },
  // {
  //   municipio: "Villanueva de San Carlos",
  //   provincia: "Ciudad Real",
  //   id: "13094",
  //   label: "Villanueva de San Carlos (Ciudad Real)",
  // },
  // {
  //   municipio: "Chumillas",
  //   provincia: "Cuenca",
  //   id: "16081",
  //   label: "Chumillas (Cuenca)",
  // },
  // {
  //   municipio: "Enguídanos",
  //   provincia: "Cuenca",
  //   id: "16082",
  //   label: "Enguídanos (Cuenca)",
  // },
  // {
  //   municipio: "Villarta de San Juan",
  //   provincia: "Ciudad Real",
  //   id: "13097",
  //   label: "Villarta de San Juan (Ciudad Real)",
  // },
  // {
  //   municipio: "Viso del Marqués",
  //   provincia: "Ciudad Real",
  //   id: "13098",
  //   label: "Viso del Marqués (Ciudad Real)",
  // },
  // {
  //   municipio: "Aliaguilla",
  //   provincia: "Cuenca",
  //   id: "16014",
  //   label: "Aliaguilla (Cuenca)",
  // },
  // {
  //   municipio: "Robledo, El",
  //   provincia: "Ciudad Real",
  //   id: "13901",
  //   label: "Robledo, El (Ciudad Real)",
  // },
  // {
  //   municipio: "Ruidera",
  //   provincia: "Ciudad Real",
  //   id: "13902",
  //   label: "Ruidera (Ciudad Real)",
  // },
  // {
  //   municipio: "Frontera, La",
  //   provincia: "Cuenca",
  //   id: "16085",
  //   label: "Frontera, La (Cuenca)",
  // },
  // {
  //   municipio: "Arenales de San Gregorio",
  //   provincia: "Ciudad Real",
  //   id: "13903",
  //   label: "Arenales de San Gregorio (Ciudad Real)",
  // },
  // {
  //   municipio: "Belmez",
  //   provincia: "Córdoba",
  //   id: "14009",
  //   label: "Belmez (Córdoba)",
  // },
  // {
  //   municipio: "Fuente de Pedro Naharro",
  //   provincia: "Cuenca",
  //   id: "16086",
  //   label: "Fuente de Pedro Naharro (Cuenca)",
  // },
  // {
  //   municipio: "Benamejí",
  //   provincia: "Córdoba",
  //   id: "14010",
  //   label: "Benamejí (Córdoba)",
  // },
  // {
  //   municipio: "Almonacid del Marquesado",
  //   provincia: "Cuenca",
  //   id: "16018",
  //   label: "Almonacid del Marquesado (Cuenca)",
  // },
  // {
  //   municipio: "Blázquez, Los",
  //   provincia: "Córdoba",
  //   id: "14011",
  //   label: "Blázquez, Los (Córdoba)",
  // },
  // {
  //   municipio: "Cumbres de Enmedio",
  //   provincia: "Huelva",
  //   id: "21027",
  //   label: "Cumbres de Enmedio (Huelva)",
  // },
  // {
  //   municipio: "Bujalance",
  //   provincia: "Córdoba",
  //   id: "14012",
  //   label: "Bujalance (Córdoba)",
  // },
  // {
  //   municipio: "Cumbres de San Bartolomé",
  //   provincia: "Huelva",
  //   id: "21028",
  //   label: "Cumbres de San Bartolomé (Huelva)",
  // },
  // {
  //   municipio: "Cumbres Mayores",
  //   provincia: "Huelva",
  //   id: "21029",
  //   label: "Cumbres Mayores (Huelva)",
  // },
  // {
  //   municipio: "Cañete de las Torres",
  //   provincia: "Córdoba",
  //   id: "14014",
  //   label: "Cañete de las Torres (Córdoba)",
  // },
  // {
  //   municipio: "Chucena",
  //   provincia: "Huelva",
  //   id: "21030",
  //   label: "Chucena (Huelva)",
  // },
  // {
  //   municipio: "Encinasola",
  //   provincia: "Huelva",
  //   id: "21031",
  //   label: "Encinasola (Huelva)",
  // },
  // {
  //   municipio: "Carcabuey",
  //   provincia: "Córdoba",
  //   id: "14015",
  //   label: "Carcabuey (Córdoba)",
  // },
  // {
  //   municipio: "Carlota, La",
  //   provincia: "Córdoba",
  //   id: "14017",
  //   label: "Carlota, La (Córdoba)",
  // },
  // {
  //   municipio: "Fuentelespino de Haro",
  //   provincia: "Cuenca",
  //   id: "16087",
  //   label: "Fuentelespino de Haro (Cuenca)",
  // },
  // {
  //   municipio: "Carpio, El",
  //   provincia: "Córdoba",
  //   id: "14018",
  //   label: "Carpio, El (Córdoba)",
  // },
  // {
  //   municipio: "Castro del Río",
  //   provincia: "Córdoba",
  //   id: "14019",
  //   label: "Castro del Río (Córdoba)",
  // },
  // {
  //   municipio: "Palenciana",
  //   provincia: "Córdoba",
  //   id: "14048",
  //   label: "Palenciana (Córdoba)",
  // },
  // {
  //   municipio: "Palma del Río",
  //   provincia: "Córdoba",
  //   id: "14049",
  //   label: "Palma del Río (Córdoba)",
  // },
  // {
  //   municipio: "Conquista",
  //   provincia: "Córdoba",
  //   id: "14020",
  //   label: "Conquista (Córdoba)",
  // },
  // {
  //   municipio: "Pedro Abad",
  //   provincia: "Córdoba",
  //   id: "14050",
  //   label: "Pedro Abad (Córdoba)",
  // },
  // {
  //   municipio: "Pedroche",
  //   provincia: "Córdoba",
  //   id: "14051",
  //   label: "Pedroche (Córdoba)",
  // },
  // {
  //   municipio: "Fuente-Tójar",
  //   provincia: "Córdoba",
  //   id: "14031",
  //   label: "Fuente-Tójar (Córdoba)",
  // },
  // {
  //   municipio: "Granjuela, La",
  //   provincia: "Córdoba",
  //   id: "14032",
  //   label: "Granjuela, La (Córdoba)",
  // },
  // {
  //   municipio: "Guadalcázar",
  //   provincia: "Córdoba",
  //   id: "14033",
  //   label: "Guadalcázar (Córdoba)",
  // },
  // {
  //   municipio: "Marines, Los",
  //   provincia: "Huelva",
  //   id: "21048",
  //   label: "Marines, Los (Huelva)",
  // },
  // {
  //   municipio: "Guijo, El",
  //   provincia: "Córdoba",
  //   id: "14034",
  //   label: "Guijo, El (Córdoba)",
  // },
  // {
  //   municipio: "Hinojosa del Duque",
  //   provincia: "Córdoba",
  //   id: "14035",
  //   label: "Hinojosa del Duque (Córdoba)",
  // },
  // {
  //   municipio: "Montalbán de Córdoba",
  //   provincia: "Córdoba",
  //   id: "14040",
  //   label: "Montalbán de Córdoba (Córdoba)",
  // },
  // {
  //   municipio: "Moriles",
  //   provincia: "Córdoba",
  //   id: "14045",
  //   label: "Moriles (Córdoba)",
  // },
  // {
  //   municipio: "Obejo",
  //   provincia: "Córdoba",
  //   id: "14047",
  //   label: "Obejo (Córdoba)",
  // },
  // {
  //   municipio: "Peñarroya-Pueblonuevo",
  //   provincia: "Córdoba",
  //   id: "14052",
  //   label: "Peñarroya-Pueblonuevo (Córdoba)",
  // },
  // {
  //   municipio: "Atalaya del Cañavate",
  //   provincia: "Cuenca",
  //   id: "16026",
  //   label: "Atalaya del Cañavate (Cuenca)",
  // },
  // {
  //   municipio: "Posadas",
  //   provincia: "Córdoba",
  //   id: "14053",
  //   label: "Posadas (Córdoba)",
  // },
  // {
  //   municipio: "Puente Genil",
  //   provincia: "Córdoba",
  //   id: "14056",
  //   label: "Puente Genil (Córdoba)",
  // },
  // {
  //   municipio: "Santa Eufemia",
  //   provincia: "Córdoba",
  //   id: "14061",
  //   label: "Santa Eufemia (Córdoba)",
  // },
  // {
  //   municipio: "Torrecampo",
  //   provincia: "Córdoba",
  //   id: "14062",
  //   label: "Torrecampo (Córdoba)",
  // },
  // {
  //   municipio: "Valenzuela",
  //   provincia: "Córdoba",
  //   id: "14063",
  //   label: "Valenzuela (Córdoba)",
  // },
  // {
  //   municipio: "Beamud",
  //   provincia: "Cuenca",
  //   id: "16031",
  //   label: "Beamud (Cuenca)",
  // },
  // {
  //   municipio: "Valsequillo",
  //   provincia: "Córdoba",
  //   id: "14064",
  //   label: "Valsequillo (Córdoba)",
  // },
  // {
  //   municipio: "Campillo de Altobuey",
  //   provincia: "Cuenca",
  //   id: "16042",
  //   label: "Campillo de Altobuey (Cuenca)",
  // },
  // {
  //   municipio: "Villa del Río",
  //   provincia: "Córdoba",
  //   id: "14066",
  //   label: "Villa del Río (Córdoba)",
  // },
  // {
  //   municipio: "Capela, A",
  //   provincia: "La Coruña",
  //   id: "15018",
  //   label: "Capela, A (La Coruña)",
  // },
  // {
  //   municipio: "Villafranca de Córdoba",
  //   provincia: "Córdoba",
  //   id: "14067",
  //   label: "Villafranca de Córdoba (Córdoba)",
  // },
  // {
  //   municipio: "Villaharta",
  //   provincia: "Córdoba",
  //   id: "14068",
  //   label: "Villaharta (Córdoba)",
  // },
  // {
  //   municipio: "Villanueva del Rey",
  //   provincia: "Córdoba",
  //   id: "14071",
  //   label: "Villanueva del Rey (Córdoba)",
  // },
  // {
  //   municipio: "Carral",
  //   provincia: "La Coruña",
  //   id: "15021",
  //   label: "Carral (La Coruña)",
  // },
  // {
  //   municipio: "Villaralto",
  //   provincia: "Córdoba",
  //   id: "14072",
  //   label: "Villaralto (Córdoba)",
  // },
  // {
  //   municipio: "Viso, El",
  //   provincia: "Córdoba",
  //   id: "14074",
  //   label: "Viso, El (Córdoba)",
  // },
  // {
  //   municipio: "Santiso",
  //   provincia: "La Coruña",
  //   id: "15079",
  //   label: "Santiso (La Coruña)",
  // },
  // {
  //   municipio: "Sobrado",
  //   provincia: "La Coruña",
  //   id: "15080",
  //   label: "Sobrado (La Coruña)",
  // },
  // {
  //   municipio: "Somozas, As",
  //   provincia: "La Coruña",
  //   id: "15081",
  //   label: "Somozas, As (La Coruña)",
  // },
  // {
  //   municipio: "Teo",
  //   provincia: "La Coruña",
  //   id: "15082",
  //   label: "Teo (La Coruña)",
  // },
  // {
  //   municipio: "Suellacabras",
  //   provincia: "Soria",
  //   id: "42175",
  //   label: "Suellacabras (Soria)",
  // },
  // {
  //   municipio: "Zuheros",
  //   provincia: "Córdoba",
  //   id: "14075",
  //   label: "Zuheros (Córdoba)",
  // },
  // {
  //   municipio: "Abegondo",
  //   provincia: "La Coruña",
  //   id: "15001",
  //   label: "Abegondo (La Coruña)",
  // },
  // {
  //   municipio: "Ames",
  //   provincia: "La Coruña",
  //   id: "15002",
  //   label: "Ames (La Coruña)",
  // },
  // {
  //   municipio: "Aranga",
  //   provincia: "La Coruña",
  //   id: "15003",
  //   label: "Aranga (La Coruña)",
  // },
  // {
  //   municipio: "Arzúa",
  //   provincia: "La Coruña",
  //   id: "15006",
  //   label: "Arzúa (La Coruña)",
  // },
  // {
  //   municipio: "Baña, A",
  //   provincia: "La Coruña",
  //   id: "15007",
  //   label: "Baña, A (La Coruña)",
  // },
  // {
  //   municipio: "Bergondo",
  //   provincia: "La Coruña",
  //   id: "15008",
  //   label: "Bergondo (La Coruña)",
  // },
  // {
  //   municipio: "Bustillo de Chaves",
  //   provincia: "Valladolid",
  //   id: "47026",
  //   label: "Bustillo de Chaves (Valladolid)",
  // },
  // {
  //   municipio: "Betanzos",
  //   provincia: "La Coruña",
  //   id: "15009",
  //   label: "Betanzos (La Coruña)",
  // },
  // {
  //   municipio: "Boimorto",
  //   provincia: "La Coruña",
  //   id: "15010",
  //   label: "Boimorto (La Coruña)",
  // },
  // {
  //   municipio: "Boqueixón",
  //   provincia: "La Coruña",
  //   id: "15012",
  //   label: "Boqueixón (La Coruña)",
  // },
  // {
  //   municipio: "Brión",
  //   provincia: "La Coruña",
  //   id: "15013",
  //   label: "Brión (La Coruña)",
  // },
  // {
  //   municipio: "Cabanas",
  //   provincia: "La Coruña",
  //   id: "15015",
  //   label: "Cabanas (La Coruña)",
  // },
  // {
  //   municipio: "Cambre",
  //   provincia: "La Coruña",
  //   id: "15017",
  //   label: "Cambre (La Coruña)",
  // },
  // {
  //   municipio: "Toques",
  //   provincia: "La Coruña",
  //   id: "15083",
  //   label: "Toques (La Coruña)",
  // },
  // {
  //   municipio: "Cerceda",
  //   provincia: "La Coruña",
  //   id: "15024",
  //   label: "Cerceda (La Coruña)",
  // },
  // {
  //   municipio: "Tordoia",
  //   provincia: "La Coruña",
  //   id: "15084",
  //   label: "Tordoia (La Coruña)",
  // },
  // {
  //   municipio: "Cerdido",
  //   provincia: "La Coruña",
  //   id: "15025",
  //   label: "Cerdido (La Coruña)",
  // },
  // {
  //   municipio: "Coirós",
  //   provincia: "La Coruña",
  //   id: "15027",
  //   label: "Coirós (La Coruña)",
  // },
  // {
  //   municipio: "Touro",
  //   provincia: "La Coruña",
  //   id: "15085",
  //   label: "Touro (La Coruña)",
  // },
  // {
  //   municipio: "Trazo",
  //   provincia: "La Coruña",
  //   id: "15086",
  //   label: "Trazo (La Coruña)",
  // },
  // {
  //   municipio: "Culleredo",
  //   provincia: "La Coruña",
  //   id: "15031",
  //   label: "Culleredo (La Coruña)",
  // },
  // {
  //   municipio: "Hormilleja",
  //   provincia: "La Rioja",
  //   id: "26076",
  //   label: "Hormilleja (La Rioja)",
  // },
  // {
  //   municipio: "Dodro",
  //   provincia: "La Coruña",
  //   id: "15033",
  //   label: "Dodro (La Coruña)",
  // },
  // {
  //   municipio: "Dumbría",
  //   provincia: "La Coruña",
  //   id: "15034",
  //   label: "Dumbría (La Coruña)",
  // },
  // {
  //   municipio: "Fene",
  //   provincia: "La Coruña",
  //   id: "15035",
  //   label: "Fene (La Coruña)",
  // },
  // {
  //   municipio: "Frades",
  //   provincia: "La Coruña",
  //   id: "15038",
  //   label: "Frades (La Coruña)",
  // },
  // {
  //   municipio: "Irixoa",
  //   provincia: "La Coruña",
  //   id: "15039",
  //   label: "Irixoa (La Coruña)",
  // },
  // {
  //   municipio: "Alberuela de Tubo",
  //   provincia: "Huesca",
  //   id: "22013",
  //   label: "Alberuela de Tubo (Huesca)",
  // },
  // {
  //   municipio: "Lousame",
  //   provincia: "La Coruña",
  //   id: "15042",
  //   label: "Lousame (La Coruña)",
  // },
  // {
  //   municipio: "Mazaricos",
  //   provincia: "La Coruña",
  //   id: "15045",
  //   label: "Mazaricos (La Coruña)",
  // },
  // {
  //   municipio: "Melide",
  //   provincia: "La Coruña",
  //   id: "15046",
  //   label: "Melide (La Coruña)",
  // },
  // {
  //   municipio: "Mesía",
  //   provincia: "La Coruña",
  //   id: "15047",
  //   label: "Mesía (La Coruña)",
  // },
  // {
  //   municipio: "Miño",
  //   provincia: "La Coruña",
  //   id: "15048",
  //   label: "Miño (La Coruña)",
  // },
  // {
  //   municipio: "Alfántega",
  //   provincia: "Huesca",
  //   id: "22020",
  //   label: "Alfántega (Huesca)",
  // },
  // {
  //   municipio: "Moeche",
  //   provincia: "La Coruña",
  //   id: "15049",
  //   label: "Moeche (La Coruña)",
  // },
  // {
  //   municipio: "Mugardos",
  //   provincia: "La Coruña",
  //   id: "15051",
  //   label: "Mugardos (La Coruña)",
  // },
  // {
  //   municipio: "Neda",
  //   provincia: "La Coruña",
  //   id: "15055",
  //   label: "Neda (La Coruña)",
  // },
  // {
  //   municipio: "Negreira",
  //   provincia: "La Coruña",
  //   id: "15056",
  //   label: "Negreira (La Coruña)",
  // },
  // {
  //   municipio: "Noia",
  //   provincia: "La Coruña",
  //   id: "15057",
  //   label: "Noia (La Coruña)",
  // },
  // {
  //   municipio: "Oroso",
  //   provincia: "La Coruña",
  //   id: "15060",
  //   label: "Oroso (La Coruña)",
  // },
  // {
  //   municipio: "Outes",
  //   provincia: "La Coruña",
  //   id: "15062",
  //   label: "Outes (La Coruña)",
  // },
  // {
  //   municipio: "Paderne",
  //   provincia: "La Coruña",
  //   id: "15064",
  //   label: "Paderne (La Coruña)",
  // },
  // {
  //   municipio: "Padrón",
  //   provincia: "La Coruña",
  //   id: "15065",
  //   label: "Padrón (La Coruña)",
  // },
  // {
  //   municipio: "Pino, O",
  //   provincia: "La Coruña",
  //   id: "15066",
  //   label: "Pino, O (La Coruña)",
  // },
  // {
  //   municipio: "Pobra do Caramiñal, A",
  //   provincia: "La Coruña",
  //   id: "15067",
  //   label: "Pobra do Caramiñal, A (La Coruña)",
  // },
  // {
  //   municipio: "Rois",
  //   provincia: "La Coruña",
  //   id: "15074",
  //   label: "Rois (La Coruña)",
  // },
  // {
  //   municipio: "San Sadurniño",
  //   provincia: "La Coruña",
  //   id: "15076",
  //   label: "San Sadurniño (La Coruña)",
  // },
  // {
  //   municipio: "Santa Comba",
  //   provincia: "La Coruña",
  //   id: "15077",
  //   label: "Santa Comba (La Coruña)",
  // },
  // {
  //   municipio: "Santiago de Compostela",
  //   provincia: "La Coruña",
  //   id: "15078",
  //   label: "Santiago de Compostela (La Coruña)",
  // },
  // {
  //   municipio: "Val do Dubra",
  //   provincia: "La Coruña",
  //   id: "15088",
  //   label: "Val do Dubra (La Coruña)",
  // },
  // {
  //   municipio: "Vedra",
  //   provincia: "La Coruña",
  //   id: "15089",
  //   label: "Vedra (La Coruña)",
  // },
  // {
  //   municipio: "Vilarmaior",
  //   provincia: "La Coruña",
  //   id: "15091",
  //   label: "Vilarmaior (La Coruña)",
  // },
  // {
  //   municipio: "Vimianzo",
  //   provincia: "La Coruña",
  //   id: "15092",
  //   label: "Vimianzo (La Coruña)",
  // },
  // {
  //   municipio: "Zas",
  //   provincia: "La Coruña",
  //   id: "15093",
  //   label: "Zas (La Coruña)",
  // },
  // {
  //   municipio: "Abia de la Obispalía",
  //   provincia: "Cuenca",
  //   id: "16001",
  //   label: "Abia de la Obispalía (Cuenca)",
  // },
  // {
  //   municipio: "Acebrón, El",
  //   provincia: "Cuenca",
  //   id: "16002",
  //   label: "Acebrón, El (Cuenca)",
  // },
  // {
  //   municipio: "Alarcón",
  //   provincia: "Cuenca",
  //   id: "16003",
  //   label: "Alarcón (Cuenca)",
  // },
  // {
  //   municipio: "Albaladejo del Cuende",
  //   provincia: "Cuenca",
  //   id: "16004",
  //   label: "Albaladejo del Cuende (Cuenca)",
  // },
  // {
  //   municipio: "Albalate de las Nogueras",
  //   provincia: "Cuenca",
  //   id: "16005",
  //   label: "Albalate de las Nogueras (Cuenca)",
  // },
  // {
  //   municipio: "Cañavate, El",
  //   provincia: "Cuenca",
  //   id: "16049",
  //   label: "Cañavate, El (Cuenca)",
  // },
  // {
  //   municipio: "Albendea",
  //   provincia: "Cuenca",
  //   id: "16006",
  //   label: "Albendea (Cuenca)",
  // },
  // {
  //   municipio: "Alberca de Záncara, La",
  //   provincia: "Cuenca",
  //   id: "16007",
  //   label: "Alberca de Záncara, La (Cuenca)",
  // },
  // {
  //   municipio: "Barriopedro",
  //   provincia: "Guadalajara",
  //   id: "19050",
  //   label: "Barriopedro (Guadalajara)",
  // },
  // {
  //   municipio: "Alcalá de la Vega",
  //   provincia: "Cuenca",
  //   id: "16008",
  //   label: "Alcalá de la Vega (Cuenca)",
  // },
  // {
  //   municipio: "Alcantud",
  //   provincia: "Cuenca",
  //   id: "16009",
  //   label: "Alcantud (Cuenca)",
  // },
  // {
  //   municipio: "Almarcha, La",
  //   provincia: "Cuenca",
  //   id: "16015",
  //   label: "Almarcha, La (Cuenca)",
  // },
  // {
  //   municipio: "Almendros",
  //   provincia: "Cuenca",
  //   id: "16016",
  //   label: "Almendros (Cuenca)",
  // },
  // {
  //   municipio: "Almodóvar del Pinar",
  //   provincia: "Cuenca",
  //   id: "16017",
  //   label: "Almodóvar del Pinar (Cuenca)",
  // },
  // {
  //   municipio: "Cañaveras",
  //   provincia: "Cuenca",
  //   id: "16050",
  //   label: "Cañaveras (Cuenca)",
  // },
  // {
  //   municipio: "Altarejos",
  //   provincia: "Cuenca",
  //   id: "16019",
  //   label: "Altarejos (Cuenca)",
  // },
  // {
  //   municipio: "Arandilla del Arroyo",
  //   provincia: "Cuenca",
  //   id: "16020",
  //   label: "Arandilla del Arroyo (Cuenca)",
  // },
  // {
  //   municipio: "Arcos de la Sierra",
  //   provincia: "Cuenca",
  //   id: "16022",
  //   label: "Arcos de la Sierra (Cuenca)",
  // },
  // {
  //   municipio: "Chillarón de Cuenca",
  //   provincia: "Cuenca",
  //   id: "16023",
  //   label: "Chillarón de Cuenca (Cuenca)",
  // },
  // {
  //   municipio: "Arguisuelas",
  //   provincia: "Cuenca",
  //   id: "16024",
  //   label: "Arguisuelas (Cuenca)",
  // },
  // {
  //   municipio: "Arrancacepas",
  //   provincia: "Cuenca",
  //   id: "16025",
  //   label: "Arrancacepas (Cuenca)",
  // },
  // {
  //   municipio: "Barchín del Hoyo",
  //   provincia: "Cuenca",
  //   id: "16029",
  //   label: "Barchín del Hoyo (Cuenca)",
  // },
  // {
  //   municipio: "Bascuñana de San Pedro",
  //   provincia: "Cuenca",
  //   id: "16030",
  //   label: "Bascuñana de San Pedro (Cuenca)",
  // },
  // {
  //   municipio: "Juviles",
  //   provincia: "Granada",
  //   id: "18112",
  //   label: "Juviles (Granada)",
  // },
  // {
  //   municipio: "Belinchón",
  //   provincia: "Cuenca",
  //   id: "16032",
  //   label: "Belinchón (Cuenca)",
  // },
  // {
  //   municipio: "Belmonte",
  //   provincia: "Cuenca",
  //   id: "16033",
  //   label: "Belmonte (Cuenca)",
  // },
  // {
  //   municipio: "Belmontejo",
  //   provincia: "Cuenca",
  //   id: "16034",
  //   label: "Belmontejo (Cuenca)",
  // },
  // {
  //   municipio: "Beteta",
  //   provincia: "Cuenca",
  //   id: "16035",
  //   label: "Beteta (Cuenca)",
  // },
  // {
  //   municipio: "Boniches",
  //   provincia: "Cuenca",
  //   id: "16036",
  //   label: "Boniches (Cuenca)",
  // },
  // {
  //   municipio: "Buciegas",
  //   provincia: "Cuenca",
  //   id: "16038",
  //   label: "Buciegas (Cuenca)",
  // },
  // {
  //   municipio: "Buenache de Alarcón",
  //   provincia: "Cuenca",
  //   id: "16039",
  //   label: "Buenache de Alarcón (Cuenca)",
  // },
  // {
  //   municipio: "Buenache de la Sierra",
  //   provincia: "Cuenca",
  //   id: "16040",
  //   label: "Buenache de la Sierra (Cuenca)",
  // },
  // {
  //   municipio: "Graja de Campalbo",
  //   provincia: "Cuenca",
  //   id: "16095",
  //   label: "Graja de Campalbo (Cuenca)",
  // },
  // {
  //   municipio: "Buendía",
  //   provincia: "Cuenca",
  //   id: "16041",
  //   label: "Buendía (Cuenca)",
  // },
  // {
  //   municipio: "Graja de Iniesta",
  //   provincia: "Cuenca",
  //   id: "16096",
  //   label: "Graja de Iniesta (Cuenca)",
  // },
  // {
  //   municipio: "Campillos-Paravientos",
  //   provincia: "Cuenca",
  //   id: "16043",
  //   label: "Campillos-Paravientos (Cuenca)",
  // },
  // {
  //   municipio: "Cañaveruelas",
  //   provincia: "Cuenca",
  //   id: "16051",
  //   label: "Cañaveruelas (Cuenca)",
  // },
  // {
  //   municipio: "Campillos-Sierra",
  //   provincia: "Cuenca",
  //   id: "16044",
  //   label: "Campillos-Sierra (Cuenca)",
  // },
  // {
  //   municipio: "Canalejas del Arroyo",
  //   provincia: "Cuenca",
  //   id: "16045",
  //   label: "Canalejas del Arroyo (Cuenca)",
  // },
  // {
  //   municipio: "Casas de Guijarro",
  //   provincia: "Cuenca",
  //   id: "16063",
  //   label: "Casas de Guijarro (Cuenca)",
  // },
  // {
  //   municipio: "Cañada del Hoyo",
  //   provincia: "Cuenca",
  //   id: "16046",
  //   label: "Cañada del Hoyo (Cuenca)",
  // },
  // {
  //   municipio: "Cañada Juncosa",
  //   provincia: "Cuenca",
  //   id: "16047",
  //   label: "Cañada Juncosa (Cuenca)",
  // },
  // {
  //   municipio: "Cañamares",
  //   provincia: "Cuenca",
  //   id: "16048",
  //   label: "Cañamares (Cuenca)",
  // },
  // {
  //   municipio: "Cañete",
  //   provincia: "Cuenca",
  //   id: "16052",
  //   label: "Cañete (Cuenca)",
  // },
  // {
  //   municipio: "Casas de Haro",
  //   provincia: "Cuenca",
  //   id: "16064",
  //   label: "Casas de Haro (Cuenca)",
  // },
  // {
  //   municipio: "Cañizares",
  //   provincia: "Cuenca",
  //   id: "16053",
  //   label: "Cañizares (Cuenca)",
  // },
  // {
  //   municipio: "Carboneras de Guadazaón",
  //   provincia: "Cuenca",
  //   id: "16055",
  //   label: "Carboneras de Guadazaón (Cuenca)",
  // },
  // {
  //   municipio: "Hinojosa, La",
  //   provincia: "Cuenca",
  //   id: "16099",
  //   label: "Hinojosa, La (Cuenca)",
  // },
  // {
  //   municipio: "Cardenete",
  //   provincia: "Cuenca",
  //   id: "16056",
  //   label: "Cardenete (Cuenca)",
  // },
  // {
  //   municipio: "Castejón",
  //   provincia: "Cuenca",
  //   id: "16067",
  //   label: "Castejón (Cuenca)",
  // },
  // {
  //   municipio: "Carrascosa",
  //   provincia: "Cuenca",
  //   id: "16057",
  //   label: "Carrascosa (Cuenca)",
  // },
  // {
  //   municipio: "Carrascosa de Haro",
  //   provincia: "Cuenca",
  //   id: "16058",
  //   label: "Carrascosa de Haro (Cuenca)",
  // },
  // {
  //   municipio: "Casas de Benítez",
  //   provincia: "Cuenca",
  //   id: "16060",
  //   label: "Casas de Benítez (Cuenca)",
  // },
  // {
  //   municipio: "Casas de Fernando Alonso",
  //   provincia: "Cuenca",
  //   id: "16061",
  //   label: "Casas de Fernando Alonso (Cuenca)",
  // },
  // {
  //   municipio: "Casas de Garcimolina",
  //   provincia: "Cuenca",
  //   id: "16062",
  //   label: "Casas de Garcimolina (Cuenca)",
  // },
  // {
  //   municipio: "Casas de los Pinos",
  //   provincia: "Cuenca",
  //   id: "16065",
  //   label: "Casas de los Pinos (Cuenca)",
  // },
  // {
  //   municipio: "Casasimarro",
  //   provincia: "Cuenca",
  //   id: "16066",
  //   label: "Casasimarro (Cuenca)",
  // },
  // {
  //   municipio: "Castillejo-Sierra",
  //   provincia: "Cuenca",
  //   id: "16070",
  //   label: "Castillejo-Sierra (Cuenca)",
  // },
  // {
  //   municipio: "Castillo-Albaráñez",
  //   provincia: "Cuenca",
  //   id: "16071",
  //   label: "Castillo-Albaráñez (Cuenca)",
  // },
  // {
  //   municipio: "Castillo de Garcimuñoz",
  //   provincia: "Cuenca",
  //   id: "16072",
  //   label: "Castillo de Garcimuñoz (Cuenca)",
  // },
  // {
  //   municipio: "Cervera del Llano",
  //   provincia: "Cuenca",
  //   id: "16073",
  //   label: "Cervera del Llano (Cuenca)",
  // },
  // {
  //   municipio: "Gabaldón",
  //   provincia: "Cuenca",
  //   id: "16092",
  //   label: "Gabaldón (Cuenca)",
  // },
  // {
  //   municipio: "Cierva, La",
  //   provincia: "Cuenca",
  //   id: "16074",
  //   label: "Cierva, La (Cuenca)",
  // },
  // {
  //   municipio: "Campillo de Dueñas",
  //   provincia: "Guadalajara",
  //   id: "19059",
  //   label: "Campillo de Dueñas (Guadalajara)",
  // },
  // {
  //   municipio: "Garaballa",
  //   provincia: "Cuenca",
  //   id: "16093",
  //   label: "Garaballa (Cuenca)",
  // },
  // {
  //   municipio: "Moya",
  //   provincia: "Cuenca",
  //   id: "16135",
  //   label: "Moya (Cuenca)",
  // },
  // {
  //   municipio: "Fresneda de Altarejos",
  //   provincia: "Cuenca",
  //   id: "16083",
  //   label: "Fresneda de Altarejos (Cuenca)",
  // },
  // {
  //   municipio: "Fresneda de la Sierra",
  //   provincia: "Cuenca",
  //   id: "16084",
  //   label: "Fresneda de la Sierra (Cuenca)",
  // },
  // {
  //   municipio: "Fuentelespino de Moya",
  //   provincia: "Cuenca",
  //   id: "16088",
  //   label: "Fuentelespino de Moya (Cuenca)",
  // },
  // {
  //   municipio: "Herrumblar, El",
  //   provincia: "Cuenca",
  //   id: "16098",
  //   label: "Herrumblar, El (Cuenca)",
  // },
  // {
  //   municipio: "Campillo de Ranas",
  //   provincia: "Guadalajara",
  //   id: "19060",
  //   label: "Campillo de Ranas (Guadalajara)",
  // },
  // {
  //   municipio: "Fuentes",
  //   provincia: "Cuenca",
  //   id: "16089",
  //   label: "Fuentes (Cuenca)",
  // },
  // {
  //   municipio: "Fuertescusa",
  //   provincia: "Cuenca",
  //   id: "16091",
  //   label: "Fuertescusa (Cuenca)",
  // },
  // {
  //   municipio: "Vega del Codorno",
  //   provincia: "Cuenca",
  //   id: "16239",
  //   label: "Vega del Codorno (Cuenca)",
  // },
  // {
  //   municipio: "Henarejos",
  //   provincia: "Cuenca",
  //   id: "16097",
  //   label: "Henarejos (Cuenca)",
  // },
  // {
  //   municipio: "Hinojosos, Los",
  //   provincia: "Cuenca",
  //   id: "16100",
  //   label: "Hinojosos, Los (Cuenca)",
  // },
  // {
  //   municipio: "Hito, El",
  //   provincia: "Cuenca",
  //   id: "16101",
  //   label: "Hito, El (Cuenca)",
  // },
  // {
  //   municipio: "Huerta de la Obispalía",
  //   provincia: "Cuenca",
  //   id: "16110",
  //   label: "Huerta de la Obispalía (Cuenca)",
  // },
  // {
  //   municipio: "Honrubia",
  //   provincia: "Cuenca",
  //   id: "16102",
  //   label: "Honrubia (Cuenca)",
  // },
  // {
  //   municipio: "Olmeda de la Cuesta",
  //   provincia: "Cuenca",
  //   id: "16140",
  //   label: "Olmeda de la Cuesta (Cuenca)",
  // },
  // {
  //   municipio: "Hontanaya",
  //   provincia: "Cuenca",
  //   id: "16103",
  //   label: "Hontanaya (Cuenca)",
  // },
  // {
  //   municipio: "Hontecillas",
  //   provincia: "Cuenca",
  //   id: "16104",
  //   label: "Hontecillas (Cuenca)",
  // },
  // {
  //   municipio: "Ledaña",
  //   provincia: "Cuenca",
  //   id: "16118",
  //   label: "Ledaña (Cuenca)",
  // },
  // {
  //   municipio: "Horcajo de Santiago",
  //   provincia: "Cuenca",
  //   id: "16106",
  //   label: "Horcajo de Santiago (Cuenca)",
  // },
  // {
  //   municipio: "Huélamo",
  //   provincia: "Cuenca",
  //   id: "16107",
  //   label: "Huélamo (Cuenca)",
  // },
  // {
  //   municipio: "Huelves",
  //   provincia: "Cuenca",
  //   id: "16108",
  //   label: "Huelves (Cuenca)",
  // },
  // {
  //   municipio: "Huérguina",
  //   provincia: "Cuenca",
  //   id: "16109",
  //   label: "Huérguina (Cuenca)",
  // },
  // {
  //   municipio: "Huerta del Marquesado",
  //   provincia: "Cuenca",
  //   id: "16111",
  //   label: "Huerta del Marquesado (Cuenca)",
  // },
  // {
  //   municipio: "Huete",
  //   provincia: "Cuenca",
  //   id: "16112",
  //   label: "Huete (Cuenca)",
  // },
  // {
  //   municipio: "Iniesta",
  //   provincia: "Cuenca",
  //   id: "16113",
  //   label: "Iniesta (Cuenca)",
  // },
  // {
  //   municipio: "Laguna del Marquesado",
  //   provincia: "Cuenca",
  //   id: "16115",
  //   label: "Laguna del Marquesado (Cuenca)",
  // },
  // {
  //   municipio: "Lagunaseca",
  //   provincia: "Cuenca",
  //   id: "16116",
  //   label: "Lagunaseca (Cuenca)",
  // },
  // {
  //   municipio: "Landete",
  //   provincia: "Cuenca",
  //   id: "16117",
  //   label: "Landete (Cuenca)",
  // },
  // {
  //   municipio: "Leganiel",
  //   provincia: "Cuenca",
  //   id: "16119",
  //   label: "Leganiel (Cuenca)",
  // },
  // {
  //   municipio: "Majadas, Las",
  //   provincia: "Cuenca",
  //   id: "16121",
  //   label: "Majadas, Las (Cuenca)",
  // },
  // {
  //   municipio: "Mariana",
  //   provincia: "Cuenca",
  //   id: "16122",
  //   label: "Mariana (Cuenca)",
  // },
  // {
  //   municipio: "Masegosa",
  //   provincia: "Cuenca",
  //   id: "16123",
  //   label: "Masegosa (Cuenca)",
  // },
  // {
  //   municipio: "Mesas, Las",
  //   provincia: "Cuenca",
  //   id: "16124",
  //   label: "Mesas, Las (Cuenca)",
  // },
  // {
  //   municipio: "Minglanilla",
  //   provincia: "Cuenca",
  //   id: "16125",
  //   label: "Minglanilla (Cuenca)",
  // },
  // {
  //   municipio: "Monreal del Llano",
  //   provincia: "Cuenca",
  //   id: "16128",
  //   label: "Monreal del Llano (Cuenca)",
  // },
  // {
  //   municipio: "Mira",
  //   provincia: "Cuenca",
  //   id: "16126",
  //   label: "Mira (Cuenca)",
  // },
  // {
  //   municipio: "Montalbo",
  //   provincia: "Cuenca",
  //   id: "16130",
  //   label: "Montalbo (Cuenca)",
  // },
  // {
  //   municipio: "Monteagudo de las Salinas",
  //   provincia: "Cuenca",
  //   id: "16131",
  //   label: "Monteagudo de las Salinas (Cuenca)",
  // },
  // {
  //   municipio: "Mota de Altarejos",
  //   provincia: "Cuenca",
  //   id: "16132",
  //   label: "Mota de Altarejos (Cuenca)",
  // },
  // {
  //   municipio: "Mota del Cuervo",
  //   provincia: "Cuenca",
  //   id: "16133",
  //   label: "Mota del Cuervo (Cuenca)",
  // },
  // {
  //   municipio: "Narboneta",
  //   provincia: "Cuenca",
  //   id: "16137",
  //   label: "Narboneta (Cuenca)",
  // },
  // {
  //   municipio: "Olivares de Júcar",
  //   provincia: "Cuenca",
  //   id: "16139",
  //   label: "Olivares de Júcar (Cuenca)",
  // },
  // {
  //   municipio: "Olmeda del Rey",
  //   provincia: "Cuenca",
  //   id: "16141",
  //   label: "Olmeda del Rey (Cuenca)",
  // },
  // {
  //   municipio: "Palomares del Campo",
  //   provincia: "Cuenca",
  //   id: "16148",
  //   label: "Palomares del Campo (Cuenca)",
  // },
  // {
  //   municipio: "Olmedilla de Alarcón",
  //   provincia: "Cuenca",
  //   id: "16142",
  //   label: "Olmedilla de Alarcón (Cuenca)",
  // },
  // {
  //   municipio: "Olmedilla de Eliz",
  //   provincia: "Cuenca",
  //   id: "16143",
  //   label: "Olmedilla de Eliz (Cuenca)",
  // },
  // {
  //   municipio: "Osa de la Vega",
  //   provincia: "Cuenca",
  //   id: "16145",
  //   label: "Osa de la Vega (Cuenca)",
  // },
  // {
  //   municipio: "Pajarón",
  //   provincia: "Cuenca",
  //   id: "16146",
  //   label: "Pajarón (Cuenca)",
  // },
  // {
  //   municipio: "Pajaroncillo",
  //   provincia: "Cuenca",
  //   id: "16147",
  //   label: "Pajaroncillo (Cuenca)",
  // },
  // {
  //   municipio: "Peral, El",
  //   provincia: "Cuenca",
  //   id: "16155",
  //   label: "Peral, El (Cuenca)",
  // },
  // {
  //   municipio: "Palomera",
  //   provincia: "Cuenca",
  //   id: "16149",
  //   label: "Palomera (Cuenca)",
  // },
  // {
  //   municipio: "Peraleja, La",
  //   provincia: "Cuenca",
  //   id: "16156",
  //   label: "Peraleja, La (Cuenca)",
  // },
  // {
  //   municipio: "Rada de Haro",
  //   provincia: "Cuenca",
  //   id: "16176",
  //   label: "Rada de Haro (Cuenca)",
  // },
  // {
  //   municipio: "Paracuellos",
  //   provincia: "Cuenca",
  //   id: "16150",
  //   label: "Paracuellos (Cuenca)",
  // },
  // {
  //   municipio: "Paredes",
  //   provincia: "Cuenca",
  //   id: "16151",
  //   label: "Paredes (Cuenca)",
  // },
  // {
  //   municipio: "Parra de las Vegas, La",
  //   provincia: "Cuenca",
  //   id: "16152",
  //   label: "Parra de las Vegas, La (Cuenca)",
  // },
  // {
  //   municipio: "Pedernoso, El",
  //   provincia: "Cuenca",
  //   id: "16153",
  //   label: "Pedernoso, El (Cuenca)",
  // },
  // {
  //   municipio: "Pedroñeras, Las",
  //   provincia: "Cuenca",
  //   id: "16154",
  //   label: "Pedroñeras, Las (Cuenca)",
  // },
  // {
  //   municipio: "Pesquera, La",
  //   provincia: "Cuenca",
  //   id: "16157",
  //   label: "Pesquera, La (Cuenca)",
  // },
  // {
  //   municipio: "Pineda de Gigüela",
  //   provincia: "Cuenca",
  //   id: "16160",
  //   label: "Pineda de Gigüela (Cuenca)",
  // },
  // {
  //   municipio: "Picazo, El",
  //   provincia: "Cuenca",
  //   id: "16158",
  //   label: "Picazo, El (Cuenca)",
  // },
  // {
  //   municipio: "Pinarejo",
  //   provincia: "Cuenca",
  //   id: "16159",
  //   label: "Pinarejo (Cuenca)",
  // },
  // {
  //   municipio: "Poyatos",
  //   provincia: "Cuenca",
  //   id: "16165",
  //   label: "Poyatos (Cuenca)",
  // },
  // {
  //   municipio: "Pozoamargo",
  //   provincia: "Cuenca",
  //   id: "16166",
  //   label: "Pozoamargo (Cuenca)",
  // },
  // {
  //   municipio: "Pozorrubio de Santiago",
  //   provincia: "Cuenca",
  //   id: "16167",
  //   label: "Pozorrubio de Santiago (Cuenca)",
  // },
  // {
  //   municipio: "Pozuelo, El",
  //   provincia: "Cuenca",
  //   id: "16169",
  //   label: "Pozuelo, El (Cuenca)",
  // },
  // {
  //   municipio: "Priego",
  //   provincia: "Cuenca",
  //   id: "16170",
  //   label: "Priego (Cuenca)",
  // },
  // {
  //   municipio: "Quintanar del Rey",
  //   provincia: "Cuenca",
  //   id: "16175",
  //   label: "Quintanar del Rey (Cuenca)",
  // },
  // {
  //   municipio: "Provencio, El",
  //   provincia: "Cuenca",
  //   id: "16171",
  //   label: "Provencio, El (Cuenca)",
  // },
  // {
  //   municipio: "Puebla de Almenara",
  //   provincia: "Cuenca",
  //   id: "16172",
  //   label: "Puebla de Almenara (Cuenca)",
  // },
  // {
  //   municipio: "Valle de Altomira, El",
  //   provincia: "Cuenca",
  //   id: "16173",
  //   label: "Valle de Altomira, El (Cuenca)",
  // },
  // {
  //   municipio: "Puebla del Salvador",
  //   provincia: "Cuenca",
  //   id: "16174",
  //   label: "Puebla del Salvador (Cuenca)",
  // },
  // {
  //   municipio: "Reíllo",
  //   provincia: "Cuenca",
  //   id: "16177",
  //   label: "Reíllo (Cuenca)",
  // },
  // {
  //   municipio: "Rozalén del Monte",
  //   provincia: "Cuenca",
  //   id: "16181",
  //   label: "Rozalén del Monte (Cuenca)",
  // },
  // {
  //   municipio: "Camporrélls",
  //   provincia: "Huesca",
  //   id: "22075",
  //   label: "Camporrélls (Huesca)",
  // },
  // {
  //   municipio: "Saceda-Trasierra",
  //   provincia: "Cuenca",
  //   id: "16185",
  //   label: "Saceda-Trasierra (Cuenca)",
  // },
  // {
  //   municipio: "Saelices",
  //   provincia: "Cuenca",
  //   id: "16186",
  //   label: "Saelices (Cuenca)",
  // },
  // {
  //   municipio: "Salinas del Manzano",
  //   provincia: "Cuenca",
  //   id: "16187",
  //   label: "Salinas del Manzano (Cuenca)",
  // },
  // {
  //   municipio: "Salmeroncillos",
  //   provincia: "Cuenca",
  //   id: "16188",
  //   label: "Salmeroncillos (Cuenca)",
  // },
  // {
  //   municipio: "Valdemeca",
  //   provincia: "Cuenca",
  //   id: "16224",
  //   label: "Valdemeca (Cuenca)",
  // },
  // {
  //   municipio: "Salvacañete",
  //   provincia: "Cuenca",
  //   id: "16189",
  //   label: "Salvacañete (Cuenca)",
  // },
  // {
  //   municipio: "San Clemente",
  //   provincia: "Cuenca",
  //   id: "16190",
  //   label: "San Clemente (Cuenca)",
  // },
  // {
  //   municipio: "San Martín de Boniches",
  //   provincia: "Cuenca",
  //   id: "16192",
  //   label: "San Martín de Boniches (Cuenca)",
  // },
  // {
  //   municipio: "San Lorenzo de la Parrilla",
  //   provincia: "Cuenca",
  //   id: "16191",
  //   label: "San Lorenzo de la Parrilla (Cuenca)",
  // },
  // {
  //   municipio: "San Pedro Palmiches",
  //   provincia: "Cuenca",
  //   id: "16193",
  //   label: "San Pedro Palmiches (Cuenca)",
  // },
  // {
  //   municipio: "Santa Cruz de Moya",
  //   provincia: "Cuenca",
  //   id: "16194",
  //   label: "Santa Cruz de Moya (Cuenca)",
  // },
  // {
  //   municipio: "Villanueva de Guadamejud",
  //   provincia: "Cuenca",
  //   id: "16250",
  //   label: "Villanueva de Guadamejud (Cuenca)",
  // },
  // {
  //   municipio: "Santa María del Campo Rus",
  //   provincia: "Cuenca",
  //   id: "16195",
  //   label: "Santa María del Campo Rus (Cuenca)",
  // },
  // {
  //   municipio: "Solera de Gabaldón",
  //   provincia: "Cuenca",
  //   id: "16199",
  //   label: "Solera de Gabaldón (Cuenca)",
  // },
  // {
  //   municipio: "Santa María de los Llanos",
  //   provincia: "Cuenca",
  //   id: "16196",
  //   label: "Santa María de los Llanos (Cuenca)",
  // },
  // {
  //   municipio: "Santa María del Val",
  //   provincia: "Cuenca",
  //   id: "16197",
  //   label: "Santa María del Val (Cuenca)",
  // },
  // {
  //   municipio: "Tinajas",
  //   provincia: "Cuenca",
  //   id: "16206",
  //   label: "Tinajas (Cuenca)",
  // },
  // {
  //   municipio: "Sisante",
  //   provincia: "Cuenca",
  //   id: "16198",
  //   label: "Sisante (Cuenca)",
  // },
  // {
  //   municipio: "Velascálvaro",
  //   provincia: "Valladolid",
  //   id: "47189",
  //   label: "Velascálvaro (Valladolid)",
  // },
  // {
  //   municipio: "Talayuelas",
  //   provincia: "Cuenca",
  //   id: "16202",
  //   label: "Talayuelas (Cuenca)",
  // },
  // {
  //   municipio: "Tarancón",
  //   provincia: "Cuenca",
  //   id: "16203",
  //   label: "Tarancón (Cuenca)",
  // },
  // {
  //   municipio: "Tébar",
  //   provincia: "Cuenca",
  //   id: "16204",
  //   label: "Tébar (Cuenca)",
  // },
  // {
  //   municipio: "Tejadillos",
  //   provincia: "Cuenca",
  //   id: "16205",
  //   label: "Tejadillos (Cuenca)",
  // },
  // {
  //   municipio: "Valverde de Júcar",
  //   provincia: "Cuenca",
  //   id: "16236",
  //   label: "Valverde de Júcar (Cuenca)",
  // },
  // {
  //   municipio: "Torralba",
  //   provincia: "Cuenca",
  //   id: "16209",
  //   label: "Torralba (Cuenca)",
  // },
  // {
  //   municipio: "Aiguaviva",
  //   provincia: "Gerona",
  //   id: "17002",
  //   label: "Aiguaviva (Gerona)",
  // },
  // {
  //   municipio: "Torrejoncillo del Rey",
  //   provincia: "Cuenca",
  //   id: "16211",
  //   label: "Torrejoncillo del Rey (Cuenca)",
  // },
  // {
  //   municipio: "Arquillinos",
  //   provincia: "Zamora",
  //   id: "49014",
  //   label: "Arquillinos (Zamora)",
  // },
  // {
  //   municipio: "Torrubia del Campo",
  //   provincia: "Cuenca",
  //   id: "16212",
  //   label: "Torrubia del Campo (Cuenca)",
  // },
  // {
  //   municipio: "Torrubia del Castillo",
  //   provincia: "Cuenca",
  //   id: "16213",
  //   label: "Torrubia del Castillo (Cuenca)",
  // },
  // {
  //   municipio: "Tragacete",
  //   provincia: "Cuenca",
  //   id: "16215",
  //   label: "Tragacete (Cuenca)",
  // },
  // {
  //   municipio: "Tresjuncos",
  //   provincia: "Cuenca",
  //   id: "16216",
  //   label: "Tresjuncos (Cuenca)",
  // },
  // {
  //   municipio: "Arbúcies",
  //   provincia: "Gerona",
  //   id: "17009",
  //   label: "Arbúcies (Gerona)",
  // },
  // {
  //   municipio: "Uclés",
  //   provincia: "Cuenca",
  //   id: "16218",
  //   label: "Uclés (Cuenca)",
  // },
  // {
  //   municipio: "Uña",
  //   provincia: "Cuenca",
  //   id: "16219",
  //   label: "Uña (Cuenca)",
  // },
  // {
  //   municipio: "Armentera, L'",
  //   provincia: "Gerona",
  //   id: "17011",
  //   label: "Armentera, L' (Gerona)",
  // },
  // {
  //   municipio: "Beuda",
  //   provincia: "Gerona",
  //   id: "17021",
  //   label: "Beuda (Gerona)",
  // },
  // {
  //   municipio: "Valdemorillo de la Sierra",
  //   provincia: "Cuenca",
  //   id: "16225",
  //   label: "Valdemorillo de la Sierra (Cuenca)",
  // },
  // {
  //   municipio: "Valdemoro-Sierra",
  //   provincia: "Cuenca",
  //   id: "16227",
  //   label: "Valdemoro-Sierra (Cuenca)",
  // },
  // {
  //   municipio: "Valdeolivas",
  //   provincia: "Cuenca",
  //   id: "16228",
  //   label: "Valdeolivas (Cuenca)",
  // },
  // {
  //   municipio: "Valhermoso de la Fuente",
  //   provincia: "Cuenca",
  //   id: "16231",
  //   label: "Valhermoso de la Fuente (Cuenca)",
  // },
  // {
  //   municipio: "Valsalobre",
  //   provincia: "Cuenca",
  //   id: "16234",
  //   label: "Valsalobre (Cuenca)",
  // },
  // {
  //   municipio: "Valverdejo",
  //   provincia: "Cuenca",
  //   id: "16237",
  //   label: "Valverdejo (Cuenca)",
  // },
  // {
  //   municipio: "Vara de Rey",
  //   provincia: "Cuenca",
  //   id: "16238",
  //   label: "Vara de Rey (Cuenca)",
  // },
  // {
  //   municipio: "Vellisca",
  //   provincia: "Cuenca",
  //   id: "16240",
  //   label: "Vellisca (Cuenca)",
  // },
  // {
  //   municipio: "Villaconejos de Trabaque",
  //   provincia: "Cuenca",
  //   id: "16242",
  //   label: "Villaconejos de Trabaque (Cuenca)",
  // },
  // {
  //   municipio: "Villagarcía del Llano",
  //   provincia: "Cuenca",
  //   id: "16244",
  //   label: "Villagarcía del Llano (Cuenca)",
  // },
  // {
  //   municipio: "Villaescusa de Haro",
  //   provincia: "Cuenca",
  //   id: "16243",
  //   label: "Villaescusa de Haro (Cuenca)",
  // },
  // {
  //   municipio: "Villarrubio",
  //   provincia: "Cuenca",
  //   id: "16270",
  //   label: "Villarrubio (Cuenca)",
  // },
  // {
  //   municipio: "Villalba de la Sierra",
  //   provincia: "Cuenca",
  //   id: "16245",
  //   label: "Villalba de la Sierra (Cuenca)",
  // },
  // {
  //   municipio: "Villaverde y Pasaconsol",
  //   provincia: "Cuenca",
  //   id: "16273",
  //   label: "Villaverde y Pasaconsol (Cuenca)",
  // },
  // {
  //   municipio: "Villarejo-Periesteban",
  //   provincia: "Cuenca",
  //   id: "16266",
  //   label: "Villarejo-Periesteban (Cuenca)",
  // },
  // {
  //   municipio: "Villalba del Rey",
  //   provincia: "Cuenca",
  //   id: "16246",
  //   label: "Villalba del Rey (Cuenca)",
  // },
  // {
  //   municipio: "Villalgordo del Marquesado",
  //   provincia: "Cuenca",
  //   id: "16247",
  //   label: "Villalgordo del Marquesado (Cuenca)",
  // },
  // {
  //   municipio: "Valdetórtola",
  //   provincia: "Cuenca",
  //   id: "16902",
  //   label: "Valdetórtola (Cuenca)",
  // },
  // {
  //   municipio: "Arrabalde",
  //   provincia: "Zamora",
  //   id: "49015",
  //   label: "Arrabalde (Zamora)",
  // },
  // {
  //   municipio: "Villalpardo",
  //   provincia: "Cuenca",
  //   id: "16248",
  //   label: "Villalpardo (Cuenca)",
  // },
  // {
  //   municipio: "Castejón de Henares",
  //   provincia: "Guadalajara",
  //   id: "19075",
  //   label: "Castejón de Henares (Guadalajara)",
  // },
  // {
  //   municipio: "Villamayor de Santiago",
  //   provincia: "Cuenca",
  //   id: "16249",
  //   label: "Villamayor de Santiago (Cuenca)",
  // },
  // {
  //   municipio: "Villanueva de la Jara",
  //   provincia: "Cuenca",
  //   id: "16251",
  //   label: "Villanueva de la Jara (Cuenca)",
  // },
  // {
  //   municipio: "Villar de Cañas",
  //   provincia: "Cuenca",
  //   id: "16253",
  //   label: "Villar de Cañas (Cuenca)",
  // },
  // {
  //   municipio: "Villar de Domingo García",
  //   provincia: "Cuenca",
  //   id: "16254",
  //   label: "Villar de Domingo García (Cuenca)",
  // },
  // {
  //   municipio: "Villar de la Encina",
  //   provincia: "Cuenca",
  //   id: "16255",
  //   label: "Villar de la Encina (Cuenca)",
  // },
  // {
  //   municipio: "Gualta",
  //   provincia: "Gerona",
  //   id: "17081",
  //   label: "Gualta (Gerona)",
  // },
  // {
  //   municipio: "Villar del Humo",
  //   provincia: "Cuenca",
  //   id: "16258",
  //   label: "Villar del Humo (Cuenca)",
  // },
  // {
  //   municipio: "Villar del Infantado",
  //   provincia: "Cuenca",
  //   id: "16259",
  //   label: "Villar del Infantado (Cuenca)",
  // },
  // {
  //   municipio: "Villar de Olalla",
  //   provincia: "Cuenca",
  //   id: "16263",
  //   label: "Villar de Olalla (Cuenca)",
  // },
  // {
  //   municipio: "Castellar de la Muela",
  //   provincia: "Guadalajara",
  //   id: "19076",
  //   label: "Castellar de la Muela (Guadalajara)",
  // },
  // {
  //   municipio: "Villarejo de Fuentes",
  //   provincia: "Cuenca",
  //   id: "16264",
  //   label: "Villarejo de Fuentes (Cuenca)",
  // },
  // {
  //   municipio: "Villarejo de la Peñuela",
  //   provincia: "Cuenca",
  //   id: "16265",
  //   label: "Villarejo de la Peñuela (Cuenca)",
  // },
  // {
  //   municipio: "Zagra",
  //   provincia: "Granada",
  //   id: "18913",
  //   label: "Zagra (Granada)",
  // },
  // {
  //   municipio: "Villares del Saz",
  //   provincia: "Cuenca",
  //   id: "16269",
  //   label: "Villares del Saz (Cuenca)",
  // },
  // {
  //   municipio: "Villarta",
  //   provincia: "Cuenca",
  //   id: "16271",
  //   label: "Villarta (Cuenca)",
  // },
  // {
  //   municipio: "Villas de la Ventosa",
  //   provincia: "Cuenca",
  //   id: "16272",
  //   label: "Villas de la Ventosa (Cuenca)",
  // },
  // {
  //   municipio: "Vindel",
  //   provincia: "Cuenca",
  //   id: "16275",
  //   label: "Vindel (Cuenca)",
  // },
  // {
  //   municipio: "Víllora",
  //   provincia: "Cuenca",
  //   id: "16274",
  //   label: "Víllora (Cuenca)",
  // },
  // {
  //   municipio: "San Martín de Valderaduey",
  //   provincia: "Zamora",
  //   id: "49190",
  //   label: "San Martín de Valderaduey (Zamora)",
  // },
  // {
  //   municipio: "Yémeda",
  //   provincia: "Cuenca",
  //   id: "16276",
  //   label: "Yémeda (Cuenca)",
  // },
  // {
  //   municipio: "Cincovillas",
  //   provincia: "Guadalajara",
  //   id: "19087",
  //   label: "Cincovillas (Guadalajara)",
  // },
  // {
  //   municipio: "Zarzuela",
  //   provincia: "Cuenca",
  //   id: "16280",
  //   label: "Zarzuela (Cuenca)",
  // },
  // {
  //   municipio: "Zafra de Záncara",
  //   provincia: "Cuenca",
  //   id: "16277",
  //   label: "Zafra de Záncara (Cuenca)",
  // },
  // {
  //   municipio: "Zafrilla",
  //   provincia: "Cuenca",
  //   id: "16278",
  //   label: "Zafrilla (Cuenca)",
  // },
  // {
  //   municipio: "Zarza de Tajo",
  //   provincia: "Cuenca",
  //   id: "16279",
  //   label: "Zarza de Tajo (Cuenca)",
  // },
  // {
  //   municipio: "Valeras, Las",
  //   provincia: "Cuenca",
  //   id: "16903",
  //   label: "Valeras, Las (Cuenca)",
  // },
  // {
  //   municipio: "Agullana",
  //   provincia: "Gerona",
  //   id: "17001",
  //   label: "Agullana (Gerona)",
  // },
  // {
  //   municipio: "Campos del Paraíso",
  //   provincia: "Cuenca",
  //   id: "16901",
  //   label: "Campos del Paraíso (Cuenca)",
  // },
  // {
  //   municipio: "Fuentenava de Jábaga",
  //   provincia: "Cuenca",
  //   id: "16904",
  //   label: "Fuentenava de Jábaga (Cuenca)",
  // },
  // {
  //   municipio: "Arcas",
  //   provincia: "Cuenca",
  //   id: "16905",
  //   label: "Arcas (Cuenca)",
  // },
  // {
  //   municipio: "Magaz de Cepeda",
  //   provincia: "León",
  //   id: "24093",
  //   label: "Magaz de Cepeda (León)",
  // },
  // {
  //   municipio: "Pozorrubielos de la Mancha",
  //   provincia: "Cuenca",
  //   id: "16908",
  //   label: "Pozorrubielos de la Mancha (Cuenca)",
  // },
  // {
  //   municipio: "Avinyonet de Puigventós",
  //   provincia: "Gerona",
  //   id: "17012",
  //   label: "Avinyonet de Puigventós (Gerona)",
  // },
  // {
  //   municipio: "Sotorribas",
  //   provincia: "Cuenca",
  //   id: "16909",
  //   label: "Sotorribas (Cuenca)",
  // },
  // {
  //   municipio: "Villar y Velasco",
  //   provincia: "Cuenca",
  //   id: "16910",
  //   label: "Villar y Velasco (Cuenca)",
  // },
  // {
  //   municipio: "Vajol, La",
  //   provincia: "Gerona",
  //   id: "17014",
  //   label: "Vajol, La (Gerona)",
  // },
  // {
  //   municipio: "Albons",
  //   provincia: "Gerona",
  //   id: "17004",
  //   label: "Albons (Gerona)",
  // },
  // {
  //   municipio: "Bàscara",
  //   provincia: "Gerona",
  //   id: "17016",
  //   label: "Bàscara (Gerona)",
  // },
  // {
  //   municipio: "Fortià",
  //   provincia: "Gerona",
  //   id: "17074",
  //   label: "Fortià (Gerona)",
  // },
  // {
  //   municipio: "Far d'Empordà, El",
  //   provincia: "Gerona",
  //   id: "17005",
  //   label: "Far d'Empordà, El (Gerona)",
  // },
  // {
  //   municipio: "Alp",
  //   provincia: "Gerona",
  //   id: "17006",
  //   label: "Alp (Gerona)",
  // },
  // {
  //   municipio: "Tajahuerce",
  //   provincia: "Soria",
  //   id: "42176",
  //   label: "Tajahuerce (Soria)",
  // },
  // {
  //   municipio: "Anglès",
  //   provincia: "Gerona",
  //   id: "17008",
  //   label: "Anglès (Gerona)",
  // },
  // {
  //   municipio: "Bellcaire d'Empordà",
  //   provincia: "Gerona",
  //   id: "17018",
  //   label: "Bellcaire d'Empordà (Gerona)",
  // },
  // {
  //   municipio: "Bolvir",
  //   provincia: "Gerona",
  //   id: "17024",
  //   label: "Bolvir (Gerona)",
  // },
  // {
  //   municipio: "Maià de Montcal",
  //   provincia: "Gerona",
  //   id: "17098",
  //   label: "Maià de Montcal (Gerona)",
  // },
  // {
  //   municipio: "Alaminos",
  //   provincia: "Guadalajara",
  //   id: "19004",
  //   label: "Alaminos (Guadalajara)",
  // },
  // {
  //   municipio: "Besalú",
  //   provincia: "Gerona",
  //   id: "17019",
  //   label: "Besalú (Gerona)",
  // },
  // {
  //   municipio: "Bescanó",
  //   provincia: "Gerona",
  //   id: "17020",
  //   label: "Bescanó (Gerona)",
  // },
  // {
  //   municipio: "Bisbal d'Empordà, La",
  //   provincia: "Gerona",
  //   id: "17022",
  //   label: "Bisbal d'Empordà, La (Gerona)",
  // },
  // {
  //   municipio: "Campelles",
  //   provincia: "Gerona",
  //   id: "17037",
  //   label: "Campelles (Gerona)",
  // },
  // {
  //   municipio: "Campllong",
  //   provincia: "Gerona",
  //   id: "17038",
  //   label: "Campllong (Gerona)",
  // },
  // {
  //   municipio: "Bordils",
  //   provincia: "Gerona",
  //   id: "17025",
  //   label: "Bordils (Gerona)",
  // },
  // {
  //   municipio: "Borrassà",
  //   provincia: "Gerona",
  //   id: "17026",
  //   label: "Borrassà (Gerona)",
  // },
  // {
  //   municipio: "Breda",
  //   provincia: "Gerona",
  //   id: "17027",
  //   label: "Breda (Gerona)",
  // },
  // {
  //   municipio: "Pardos",
  //   provincia: "Guadalajara",
  //   id: "19209",
  //   label: "Pardos (Guadalajara)",
  // },
  // {
  //   municipio: "Brunyola i Sant Martí Sapresa",
  //   provincia: "Gerona",
  //   id: "17028",
  //   label: "Brunyola i Sant Martí Sapresa (Gerona)",
  // },
  // {
  //   municipio: "Montejo de Arévalo",
  //   provincia: "Segovia",
  //   id: "40129",
  //   label: "Montejo de Arévalo (Segovia)",
  // },
  // {
  //   municipio: "Boadella i les Escaules",
  //   provincia: "Gerona",
  //   id: "17029",
  //   label: "Boadella i les Escaules (Gerona)",
  // },
  // {
  //   municipio: "Ítrabo",
  //   provincia: "Granada",
  //   id: "18103",
  //   label: "Ítrabo (Granada)",
  // },
  // {
  //   municipio: "Cabanes",
  //   provincia: "Gerona",
  //   id: "17030",
  //   label: "Cabanes (Gerona)",
  // },
  // {
  //   municipio: "Camós",
  //   provincia: "Gerona",
  //   id: "17035",
  //   label: "Camós (Gerona)",
  // },
  // {
  //   municipio: "Cantallops",
  //   provincia: "Gerona",
  //   id: "17041",
  //   label: "Cantallops (Gerona)",
  // },
  // {
  //   municipio: "Capmany",
  //   provincia: "Gerona",
  //   id: "17042",
  //   label: "Capmany (Gerona)",
  // },
  // {
  //   municipio: "Castellfollit de la Roca",
  //   provincia: "Gerona",
  //   id: "17046",
  //   label: "Castellfollit de la Roca (Gerona)",
  // },
  // {
  //   municipio: "Castelló d'Empúries",
  //   provincia: "Gerona",
  //   id: "17047",
  //   label: "Castelló d'Empúries (Gerona)",
  // },
  // {
  //   municipio: "Siurana",
  //   provincia: "Gerona",
  //   id: "17052",
  //   label: "Siurana (Gerona)",
  // },
  // {
  //   municipio: "Celrà",
  //   provincia: "Gerona",
  //   id: "17049",
  //   label: "Celrà (Gerona)",
  // },
  // {
  //   municipio: "Valdevacas de Montejo",
  //   provincia: "Segovia",
  //   id: "40212",
  //   label: "Valdevacas de Montejo (Segovia)",
  // },
  // {
  //   municipio: "Cistella",
  //   provincia: "Gerona",
  //   id: "17051",
  //   label: "Cistella (Gerona)",
  // },
  // {
  //   municipio: "Ollauri",
  //   provincia: "La Rioja",
  //   id: "26111",
  //   label: "Ollauri (La Rioja)",
  // },
  // {
  //   municipio: "Colomers",
  //   provincia: "Gerona",
  //   id: "17055",
  //   label: "Colomers (Gerona)",
  // },
  // {
  //   municipio: "Corçà",
  //   provincia: "Gerona",
  //   id: "17057",
  //   label: "Corçà (Gerona)",
  // },
  // {
  //   municipio: "Crespià",
  //   provincia: "Gerona",
  //   id: "17058",
  //   label: "Crespià (Gerona)",
  // },
  // {
  //   municipio: "Das",
  //   provincia: "Gerona",
  //   id: "17061",
  //   label: "Das (Gerona)",
  // },
  // {
  //   municipio: "Espinelves",
  //   provincia: "Gerona",
  //   id: "17063",
  //   label: "Espinelves (Gerona)",
  // },
  // {
  //   municipio: "Espolla",
  //   provincia: "Gerona",
  //   id: "17064",
  //   label: "Espolla (Gerona)",
  // },
  // {
  //   municipio: "Figueres",
  //   provincia: "Gerona",
  //   id: "17066",
  //   label: "Figueres (Gerona)",
  // },
  // {
  //   municipio: "Fornells de la Selva",
  //   provincia: "Gerona",
  //   id: "17073",
  //   label: "Fornells de la Selva (Gerona)",
  // },
  // {
  //   municipio: "Flaçà",
  //   provincia: "Gerona",
  //   id: "17067",
  //   label: "Flaçà (Gerona)",
  // },
  // {
  //   municipio: "Jirueque",
  //   provincia: "Guadalajara",
  //   id: "19157",
  //   label: "Jirueque (Guadalajara)",
  // },
  // {
  //   municipio: "Foixà",
  //   provincia: "Gerona",
  //   id: "17068",
  //   label: "Foixà (Gerona)",
  // },
  // {
  //   municipio: "Fontanals de Cerdanya",
  //   provincia: "Gerona",
  //   id: "17069",
  //   label: "Fontanals de Cerdanya (Gerona)",
  // },
  // {
  //   municipio: "Fontanilles",
  //   provincia: "Gerona",
  //   id: "17070",
  //   label: "Fontanilles (Gerona)",
  // },
  // {
  //   municipio: "Garrigàs",
  //   provincia: "Gerona",
  //   id: "17075",
  //   label: "Garrigàs (Gerona)",
  // },
  // {
  //   municipio: "Garrigoles",
  //   provincia: "Gerona",
  //   id: "17076",
  //   label: "Garrigoles (Gerona)",
  // },
  // {
  //   municipio: "Garriguella",
  //   provincia: "Gerona",
  //   id: "17077",
  //   label: "Garriguella (Gerona)",
  // },
  // {
  //   municipio: "Ger",
  //   provincia: "Gerona",
  //   id: "17078",
  //   label: "Ger (Gerona)",
  // },
  // {
  //   municipio: "Girona",
  //   provincia: "Gerona",
  //   id: "17079",
  //   label: "Girona (Gerona)",
  // },
  // {
  //   municipio: "Tajueco",
  //   provincia: "Soria",
  //   id: "42177",
  //   label: "Tajueco (Soria)",
  // },
  // {
  //   municipio: "Guils de Cerdanya",
  //   provincia: "Gerona",
  //   id: "17082",
  //   label: "Guils de Cerdanya (Gerona)",
  // },
  // {
  //   municipio: "Hostalric",
  //   provincia: "Gerona",
  //   id: "17083",
  //   label: "Hostalric (Gerona)",
  // },
  // {
  //   municipio: "Isòvol",
  //   provincia: "Gerona",
  //   id: "17084",
  //   label: "Isòvol (Gerona)",
  // },
  // {
  //   municipio: "Jafre",
  //   provincia: "Gerona",
  //   id: "17085",
  //   label: "Jafre (Gerona)",
  // },
  // {
  //   municipio: "Jonquera, La",
  //   provincia: "Gerona",
  //   id: "17086",
  //   label: "Jonquera, La (Gerona)",
  // },
  // {
  //   municipio: "Juià",
  //   provincia: "Gerona",
  //   id: "17087",
  //   label: "Juià (Gerona)",
  // },
  // {
  //   municipio: "Almadrones",
  //   provincia: "Guadalajara",
  //   id: "19020",
  //   label: "Almadrones (Guadalajara)",
  // },
  // {
  //   municipio: "Lladó",
  //   provincia: "Gerona",
  //   id: "17088",
  //   label: "Lladó (Gerona)",
  // },
  // {
  //   municipio: "Llambilles",
  //   provincia: "Gerona",
  //   id: "17090",
  //   label: "Llambilles (Gerona)",
  // },
  // {
  //   municipio: "Llanars",
  //   provincia: "Gerona",
  //   id: "17091",
  //   label: "Llanars (Gerona)",
  // },
  // {
  //   municipio: "Llers",
  //   provincia: "Gerona",
  //   id: "17093",
  //   label: "Llers (Gerona)",
  // },
  // {
  //   municipio: "Almoguera",
  //   provincia: "Guadalajara",
  //   id: "19021",
  //   label: "Almoguera (Guadalajara)",
  // },
  // {
  //   municipio: "Llívia",
  //   provincia: "Gerona",
  //   id: "17094",
  //   label: "Llívia (Gerona)",
  // },
  // {
  //   municipio: "Madremanya",
  //   provincia: "Gerona",
  //   id: "17097",
  //   label: "Madremanya (Gerona)",
  // },
  // {
  //   municipio: "Meranges",
  //   provincia: "Gerona",
  //   id: "17099",
  //   label: "Meranges (Gerona)",
  // },
  // {
  //   municipio: "Massanes",
  //   provincia: "Gerona",
  //   id: "17101",
  //   label: "Massanes (Gerona)",
  // },
  // {
  //   municipio: "Maçanet de Cabrenys",
  //   provincia: "Gerona",
  //   id: "17102",
  //   label: "Maçanet de Cabrenys (Gerona)",
  // },
  // {
  //   municipio: "Masarac",
  //   provincia: "Gerona",
  //   id: "17100",
  //   label: "Masarac (Gerona)",
  // },
  // {
  //   municipio: "Molló",
  //   provincia: "Gerona",
  //   id: "17107",
  //   label: "Molló (Gerona)",
  // },
  // {
  //   municipio: "Maçanet de la Selva",
  //   provincia: "Gerona",
  //   id: "17103",
  //   label: "Maçanet de la Selva (Gerona)",
  // },
  // {
  //   municipio: "Mollet de Peralada",
  //   provincia: "Gerona",
  //   id: "17106",
  //   label: "Mollet de Peralada (Gerona)",
  // },
  // {
  //   municipio: "Mont-ras",
  //   provincia: "Gerona",
  //   id: "17110",
  //   label: "Mont-ras (Gerona)",
  // },
  // {
  //   municipio: "Olot",
  //   provincia: "Gerona",
  //   id: "17114",
  //   label: "Olot (Gerona)",
  // },
  // {
  //   municipio: "Ordis",
  //   provincia: "Gerona",
  //   id: "17115",
  //   label: "Ordis (Gerona)",
  // },
  // {
  //   municipio: "Palau de Santa Eulàlia",
  //   provincia: "Gerona",
  //   id: "17119",
  //   label: "Palau de Santa Eulàlia (Gerona)",
  // },
  // {
  //   municipio: "Palau-saverdera",
  //   provincia: "Gerona",
  //   id: "17120",
  //   label: "Palau-saverdera (Gerona)",
  // },
  // {
  //   municipio: "Palau-sator",
  //   provincia: "Gerona",
  //   id: "17121",
  //   label: "Palau-sator (Gerona)",
  // },
  // {
  //   municipio: "Palol de Revardit",
  //   provincia: "Gerona",
  //   id: "17123",
  //   label: "Palol de Revardit (Gerona)",
  // },
  // {
  //   municipio: "Pau",
  //   provincia: "Gerona",
  //   id: "17128",
  //   label: "Pau (Gerona)",
  // },
  // {
  //   municipio: "Pals",
  //   provincia: "Gerona",
  //   id: "17124",
  //   label: "Pals (Gerona)",
  // },
  // {
  //   municipio: "Parlavà",
  //   provincia: "Gerona",
  //   id: "17126",
  //   label: "Parlavà (Gerona)",
  // },
  // {
  //   municipio: "Pedret i Marzà",
  //   provincia: "Gerona",
  //   id: "17129",
  //   label: "Pedret i Marzà (Gerona)",
  // },
  // {
  //   municipio: "Pera, La",
  //   provincia: "Gerona",
  //   id: "17130",
  //   label: "Pera, La (Gerona)",
  // },
  // {
  //   municipio: "Peralada",
  //   provincia: "Gerona",
  //   id: "17132",
  //   label: "Peralada (Gerona)",
  // },
  // {
  //   municipio: "Pont de Molins",
  //   provincia: "Gerona",
  //   id: "17135",
  //   label: "Pont de Molins (Gerona)",
  // },
  // {
  //   municipio: "Sant Joan de Mollet",
  //   provincia: "Gerona",
  //   id: "17168",
  //   label: "Sant Joan de Mollet (Gerona)",
  // },
  // {
  //   municipio: "Pontós",
  //   provincia: "Gerona",
  //   id: "17136",
  //   label: "Pontós (Gerona)",
  // },
  // {
  //   municipio: "Preses, Les",
  //   provincia: "Gerona",
  //   id: "17139",
  //   label: "Preses, Les (Gerona)",
  // },
  // {
  //   municipio: "Puigcerdà",
  //   provincia: "Gerona",
  //   id: "17141",
  //   label: "Puigcerdà (Gerona)",
  // },
  // {
  //   municipio: "Sant Julià de Ramis",
  //   provincia: "Gerona",
  //   id: "17169",
  //   label: "Sant Julià de Ramis (Gerona)",
  // },
  // {
  //   municipio: "Vallfogona de Ripollès",
  //   provincia: "Gerona",
  //   id: "17170",
  //   label: "Vallfogona de Ripollès (Gerona)",
  // },
  // {
  //   municipio: "Sant Llorenç de la Muga",
  //   provincia: "Gerona",
  //   id: "17171",
  //   label: "Sant Llorenç de la Muga (Gerona)",
  // },
  // {
  //   municipio: "Sant Martí Vell",
  //   provincia: "Gerona",
  //   id: "17173",
  //   label: "Sant Martí Vell (Gerona)",
  // },
  // {
  //   municipio: "Quart",
  //   provincia: "Gerona",
  //   id: "17142",
  //   label: "Quart (Gerona)",
  // },
  // {
  //   municipio: "Sant Miquel de Fluvià",
  //   provincia: "Gerona",
  //   id: "17175",
  //   label: "Sant Miquel de Fluvià (Gerona)",
  // },
  // {
  //   municipio: "Rabós",
  //   provincia: "Gerona",
  //   id: "17143",
  //   label: "Rabós (Gerona)",
  // },
  // {
  //   municipio: "Alustante",
  //   provincia: "Guadalajara",
  //   id: "19027",
  //   label: "Alustante (Guadalajara)",
  // },
  // {
  //   municipio: "Regencós",
  //   provincia: "Gerona",
  //   id: "17144",
  //   label: "Regencós (Gerona)",
  // },
  // {
  //   municipio: "Riells i Viabrea",
  //   provincia: "Gerona",
  //   id: "17146",
  //   label: "Riells i Viabrea (Gerona)",
  // },
  // {
  //   municipio: "Riudarenes",
  //   provincia: "Gerona",
  //   id: "17148",
  //   label: "Riudarenes (Gerona)",
  // },
  // {
  //   municipio: "Riudaura",
  //   provincia: "Gerona",
  //   id: "17149",
  //   label: "Riudaura (Gerona)",
  // },
  // {
  //   municipio: "Sant Mori",
  //   provincia: "Gerona",
  //   id: "17176",
  //   label: "Sant Mori (Gerona)",
  // },
  // {
  //   municipio: "Riudellots de la Selva",
  //   provincia: "Gerona",
  //   id: "17150",
  //   label: "Riudellots de la Selva (Gerona)",
  // },
  // {
  //   municipio: "Riumors",
  //   provincia: "Gerona",
  //   id: "17151",
  //   label: "Riumors (Gerona)",
  // },
  // {
  //   municipio: "Sant Pere Pescador",
  //   provincia: "Gerona",
  //   id: "17178",
  //   label: "Sant Pere Pescador (Gerona)",
  // },
  // {
  //   municipio: "Rupià",
  //   provincia: "Gerona",
  //   id: "17153",
  //   label: "Rupià (Gerona)",
  // },
  // {
  //   municipio: "Santa Coloma de Farners",
  //   provincia: "Gerona",
  //   id: "17180",
  //   label: "Santa Coloma de Farners (Gerona)",
  // },
  // {
  //   municipio: "Santa Llogaia d'Àlguema",
  //   provincia: "Gerona",
  //   id: "17182",
  //   label: "Santa Llogaia d'Àlguema (Gerona)",
  // },
  // {
  //   municipio: "Salt",
  //   provincia: "Gerona",
  //   id: "17155",
  //   label: "Salt (Gerona)",
  // },
  // {
  //   municipio: "Selva de Mar, La",
  //   provincia: "Gerona",
  //   id: "17188",
  //   label: "Selva de Mar, La (Gerona)",
  // },
  // {
  //   municipio: "Cellera de Ter, La",
  //   provincia: "Gerona",
  //   id: "17189",
  //   label: "Cellera de Ter, La (Gerona)",
  // },
  // {
  //   municipio: "Sant Andreu Salou",
  //   provincia: "Gerona",
  //   id: "17157",
  //   label: "Sant Andreu Salou (Gerona)",
  // },
  // {
  //   municipio: "Torrent",
  //   provincia: "Gerona",
  //   id: "17197",
  //   label: "Torrent (Gerona)",
  // },
  // {
  //   municipio: "Sant Climent Sescebes",
  //   provincia: "Gerona",
  //   id: "17158",
  //   label: "Sant Climent Sescebes (Gerona)",
  // },
  // {
  //   municipio: "Torroella de Fluvià",
  //   provincia: "Gerona",
  //   id: "17198",
  //   label: "Torroella de Fluvià (Gerona)",
  // },
  // {
  //   municipio: "Sant Feliu de Buixalleu",
  //   provincia: "Gerona",
  //   id: "17159",
  //   label: "Sant Feliu de Buixalleu (Gerona)",
  // },
  // {
  //   municipio: "Tortellà",
  //   provincia: "Gerona",
  //   id: "17200",
  //   label: "Tortellà (Gerona)",
  // },
  // {
  //   municipio: "Ultramort",
  //   provincia: "Gerona",
  //   id: "17203",
  //   label: "Ultramort (Gerona)",
  // },
  // {
  //   municipio: "Sant Jordi Desvalls",
  //   provincia: "Gerona",
  //   id: "17166",
  //   label: "Sant Jordi Desvalls (Gerona)",
  // },
  // {
  //   municipio: "Ullà",
  //   provincia: "Gerona",
  //   id: "17204",
  //   label: "Ullà (Gerona)",
  // },
  // {
  //   municipio: "Sarrià de Ter",
  //   provincia: "Gerona",
  //   id: "17186",
  //   label: "Sarrià de Ter (Gerona)",
  // },
  // {
  //   municipio: "Saus, Camallera i Llampaies",
  //   provincia: "Gerona",
  //   id: "17187",
  //   label: "Saus, Camallera i Llampaies (Gerona)",
  // },
  // {
  //   municipio: "Serra de Daró",
  //   provincia: "Gerona",
  //   id: "17191",
  //   label: "Serra de Daró (Gerona)",
  // },
  // {
  //   municipio: "Setcases",
  //   provincia: "Gerona",
  //   id: "17192",
  //   label: "Setcases (Gerona)",
  // },
  // {
  //   municipio: "Sils",
  //   provincia: "Gerona",
  //   id: "17193",
  //   label: "Sils (Gerona)",
  // },
  // {
  //   municipio: "Vilajuïga",
  //   provincia: "Gerona",
  //   id: "17223",
  //   label: "Vilajuïga (Gerona)",
  // },
  // {
  //   municipio: "Tallada d'Empordà, La",
  //   provincia: "Gerona",
  //   id: "17195",
  //   label: "Tallada d'Empordà, La (Gerona)",
  // },
  // {
  //   municipio: "Albuñán",
  //   provincia: "Granada",
  //   id: "18005",
  //   label: "Albuñán (Granada)",
  // },
  // {
  //   municipio: "Albuñol",
  //   provincia: "Granada",
  //   id: "18006",
  //   label: "Albuñol (Granada)",
  // },
  // {
  //   municipio: "Terrades",
  //   provincia: "Gerona",
  //   id: "17196",
  //   label: "Terrades (Gerona)",
  // },
  // {
  //   municipio: "Ullastret",
  //   provincia: "Gerona",
  //   id: "17205",
  //   label: "Ullastret (Gerona)",
  // },
  // {
  //   municipio: "Urús",
  //   provincia: "Gerona",
  //   id: "17206",
  //   label: "Urús (Gerona)",
  // },
  // {
  //   municipio: "Vilamacolum",
  //   provincia: "Gerona",
  //   id: "17225",
  //   label: "Vilamacolum (Gerona)",
  // },
  // {
  //   municipio: "Albondón",
  //   provincia: "Granada",
  //   id: "18004",
  //   label: "Albondón (Granada)",
  // },
  // {
  //   municipio: "Masegoso de Tajuña",
  //   provincia: "Guadalajara",
  //   id: "19172",
  //   label: "Masegoso de Tajuña (Guadalajara)",
  // },
  // {
  //   municipio: "Vall-llobrega",
  //   provincia: "Gerona",
  //   id: "17209",
  //   label: "Vall-llobrega (Gerona)",
  // },
  // {
  //   municipio: "Ventalló",
  //   provincia: "Gerona",
  //   id: "17210",
  //   label: "Ventalló (Gerona)",
  // },
  // {
  //   municipio: "Vilabertran",
  //   provincia: "Gerona",
  //   id: "17214",
  //   label: "Vilabertran (Gerona)",
  // },
  // {
  //   municipio: "Verges",
  //   provincia: "Gerona",
  //   id: "17211",
  //   label: "Verges (Gerona)",
  // },
  // {
  //   municipio: "Vidreres",
  //   provincia: "Gerona",
  //   id: "17213",
  //   label: "Vidreres (Gerona)",
  // },
  // {
  //   municipio: "Viladamat",
  //   provincia: "Gerona",
  //   id: "17217",
  //   label: "Viladamat (Gerona)",
  // },
  // {
  //   municipio: "Vilablareix",
  //   provincia: "Gerona",
  //   id: "17215",
  //   label: "Vilablareix (Gerona)",
  // },
  // {
  //   municipio: "Viladasens",
  //   provincia: "Gerona",
  //   id: "17216",
  //   label: "Viladasens (Gerona)",
  // },
  // {
  //   municipio: "Vilafant",
  //   provincia: "Gerona",
  //   id: "17221",
  //   label: "Vilafant (Gerona)",
  // },
  // {
  //   municipio: "Vilaür",
  //   provincia: "Gerona",
  //   id: "17222",
  //   label: "Vilaür (Gerona)",
  // },
  // {
  //   municipio: "Albuñuelas",
  //   provincia: "Granada",
  //   id: "18007",
  //   label: "Albuñuelas (Granada)",
  // },
  // {
  //   municipio: "Aldeire",
  //   provincia: "Granada",
  //   id: "18010",
  //   label: "Aldeire (Granada)",
  // },
  // {
  //   municipio: "Vilamalla",
  //   provincia: "Gerona",
  //   id: "17226",
  //   label: "Vilamalla (Gerona)",
  // },
  // {
  //   municipio: "Vilamaniscle",
  //   provincia: "Gerona",
  //   id: "17227",
  //   label: "Vilamaniscle (Gerona)",
  // },
  // {
  //   municipio: "Vila-sacra",
  //   provincia: "Gerona",
  //   id: "17230",
  //   label: "Vila-sacra (Gerona)",
  // },
  // {
  //   municipio: "Vilopriu",
  //   provincia: "Gerona",
  //   id: "17232",
  //   label: "Vilopriu (Gerona)",
  // },
  // {
  //   municipio: "Vilobí d'Onyar",
  //   provincia: "Gerona",
  //   id: "17233",
  //   label: "Vilobí d'Onyar (Gerona)",
  // },
  // {
  //   municipio: "Vilanant",
  //   provincia: "Gerona",
  //   id: "17228",
  //   label: "Vilanant (Gerona)",
  // },
  // {
  //   municipio: "Palo",
  //   provincia: "Huesca",
  //   id: "22168",
  //   label: "Palo (Huesca)",
  // },
  // {
  //   municipio: "Biure",
  //   provincia: "Gerona",
  //   id: "17234",
  //   label: "Biure (Gerona)",
  // },
  // {
  //   municipio: "Cruïlles, Monells i Sant Sadurní de l'Heura",
  //   provincia: "Gerona",
  //   id: "17901",
  //   label: "Cruïlles, Monells i Sant Sadurní de l'Heura (Gerona)",
  // },
  // {
  //   municipio: "Forallac",
  //   provincia: "Gerona",
  //   id: "17902",
  //   label: "Forallac (Gerona)",
  // },
  // {
  //   municipio: "Panticosa",
  //   provincia: "Huesca",
  //   id: "22170",
  //   label: "Panticosa (Huesca)",
  // },
  // {
  //   municipio: "Sant Julià del Llor i Bonmatí",
  //   provincia: "Gerona",
  //   id: "17903",
  //   label: "Sant Julià del Llor i Bonmatí (Gerona)",
  // },
  // {
  //   municipio: "Agrón",
  //   provincia: "Granada",
  //   id: "18001",
  //   label: "Agrón (Granada)",
  // },
  // {
  //   municipio: "Alfacar",
  //   provincia: "Granada",
  //   id: "18011",
  //   label: "Alfacar (Granada)",
  // },
  // {
  //   municipio: "Algarinejo",
  //   provincia: "Granada",
  //   id: "18012",
  //   label: "Algarinejo (Granada)",
  // },
  // {
  //   municipio: "Alhendín",
  //   provincia: "Granada",
  //   id: "18014",
  //   label: "Alhendín (Granada)",
  // },
  // {
  //   municipio: "Alamedilla",
  //   provincia: "Granada",
  //   id: "18002",
  //   label: "Alamedilla (Granada)",
  // },
  // {
  //   municipio: "Albolote",
  //   provincia: "Granada",
  //   id: "18003",
  //   label: "Albolote (Granada)",
  // },
  // {
  //   municipio: "Alicún de Ortega",
  //   provincia: "Granada",
  //   id: "18015",
  //   label: "Alicún de Ortega (Granada)",
  // },
  // {
  //   municipio: "Benalúa de las Villas",
  //   provincia: "Granada",
  //   id: "18028",
  //   label: "Benalúa de las Villas (Granada)",
  // },
  // {
  //   municipio: "Benamaurel",
  //   provincia: "Granada",
  //   id: "18029",
  //   label: "Benamaurel (Granada)",
  // },
  // {
  //   municipio: "Almegíjar",
  //   provincia: "Granada",
  //   id: "18016",
  //   label: "Almegíjar (Granada)",
  // },
  // {
  //   municipio: "Cájar",
  //   provincia: "Granada",
  //   id: "18036",
  //   label: "Cájar (Granada)",
  // },
  // {
  //   municipio: "Alquife",
  //   provincia: "Granada",
  //   id: "18018",
  //   label: "Alquife (Granada)",
  // },
  // {
  //   municipio: "Arenas del Rey",
  //   provincia: "Granada",
  //   id: "18020",
  //   label: "Arenas del Rey (Granada)",
  // },
  // {
  //   municipio: "Armilla",
  //   provincia: "Granada",
  //   id: "18021",
  //   label: "Armilla (Granada)",
  // },
  // {
  //   municipio: "Atarfe",
  //   provincia: "Granada",
  //   id: "18022",
  //   label: "Atarfe (Granada)",
  // },
  // {
  //   municipio: "Beas de Granada",
  //   provincia: "Granada",
  //   id: "18024",
  //   label: "Beas de Granada (Granada)",
  // },
  // {
  //   municipio: "Fuentelsaz",
  //   provincia: "Guadalajara",
  //   id: "19122",
  //   label: "Fuentelsaz (Guadalajara)",
  // },
  // {
  //   municipio: "Beas de Guadix",
  //   provincia: "Granada",
  //   id: "18025",
  //   label: "Beas de Guadix (Granada)",
  // },
  // {
  //   municipio: "Calicasas",
  //   provincia: "Granada",
  //   id: "18037",
  //   label: "Calicasas (Granada)",
  // },
  // {
  //   municipio: "Campotéjar",
  //   provincia: "Granada",
  //   id: "18038",
  //   label: "Campotéjar (Granada)",
  // },
  // {
  //   municipio: "Caniles",
  //   provincia: "Granada",
  //   id: "18039",
  //   label: "Caniles (Granada)",
  // },
  // {
  //   municipio: "Benalúa",
  //   provincia: "Granada",
  //   id: "18027",
  //   label: "Benalúa (Granada)",
  // },
  // {
  //   municipio: "Bérchules",
  //   provincia: "Granada",
  //   id: "18030",
  //   label: "Bérchules (Granada)",
  // },
  // {
  //   municipio: "Bubión",
  //   provincia: "Granada",
  //   id: "18032",
  //   label: "Bubión (Granada)",
  // },
  // {
  //   municipio: "Cijuela",
  //   provincia: "Granada",
  //   id: "18048",
  //   label: "Cijuela (Granada)",
  // },
  // {
  //   municipio: "Busquístar",
  //   provincia: "Granada",
  //   id: "18033",
  //   label: "Busquístar (Granada)",
  // },
  // {
  //   municipio: "Carataunas",
  //   provincia: "Granada",
  //   id: "18043",
  //   label: "Carataunas (Granada)",
  // },
  // {
  //   municipio: "Cacín",
  //   provincia: "Granada",
  //   id: "18034",
  //   label: "Cacín (Granada)",
  // },
  // {
  //   municipio: "Cenes de la Vega",
  //   provincia: "Granada",
  //   id: "18047",
  //   label: "Cenes de la Vega (Granada)",
  // },
  // {
  //   municipio: "Cádiar",
  //   provincia: "Granada",
  //   id: "18035",
  //   label: "Cádiar (Granada)",
  // },
  // {
  //   municipio: "Cáñar",
  //   provincia: "Granada",
  //   id: "18040",
  //   label: "Cáñar (Granada)",
  // },
  // {
  //   municipio: "Capileira",
  //   provincia: "Granada",
  //   id: "18042",
  //   label: "Capileira (Granada)",
  // },
  // {
  //   municipio: "Churriana de la Vega",
  //   provincia: "Granada",
  //   id: "18062",
  //   label: "Churriana de la Vega (Granada)",
  // },
  // {
  //   municipio: "Darro",
  //   provincia: "Granada",
  //   id: "18063",
  //   label: "Darro (Granada)",
  // },
  // {
  //   municipio: "Cástaras",
  //   provincia: "Granada",
  //   id: "18044",
  //   label: "Cástaras (Granada)",
  // },
  // {
  //   municipio: "Dehesas de Guadix",
  //   provincia: "Granada",
  //   id: "18064",
  //   label: "Dehesas de Guadix (Granada)",
  // },
  // {
  //   municipio: "Castilléjar",
  //   provincia: "Granada",
  //   id: "18045",
  //   label: "Castilléjar (Granada)",
  // },
  // {
  //   municipio: "Castril",
  //   provincia: "Granada",
  //   id: "18046",
  //   label: "Castril (Granada)",
  // },
  // {
  //   municipio: "Cogollos de Guadix",
  //   provincia: "Granada",
  //   id: "18049",
  //   label: "Cogollos de Guadix (Granada)",
  // },
  // {
  //   municipio: "Dúdar",
  //   provincia: "Granada",
  //   id: "18070",
  //   label: "Dúdar (Granada)",
  // },
  // {
  //   municipio: "Dúrcal",
  //   provincia: "Granada",
  //   id: "18071",
  //   label: "Dúrcal (Granada)",
  // },
  // {
  //   municipio: "Cogollos de la Vega",
  //   provincia: "Granada",
  //   id: "18050",
  //   label: "Cogollos de la Vega (Granada)",
  // },
  // {
  //   municipio: "Colomera",
  //   provincia: "Granada",
  //   id: "18051",
  //   label: "Colomera (Granada)",
  // },
  // {
  //   municipio: "Cortes de Baza",
  //   provincia: "Granada",
  //   id: "18053",
  //   label: "Cortes de Baza (Granada)",
  // },
  // {
  //   municipio: "Cortes y Graena",
  //   provincia: "Granada",
  //   id: "18054",
  //   label: "Cortes y Graena (Granada)",
  // },
  // {
  //   municipio: "Cúllar",
  //   provincia: "Granada",
  //   id: "18056",
  //   label: "Cúllar (Granada)",
  // },
  // {
  //   municipio: "Peraltilla",
  //   provincia: "Huesca",
  //   id: "22176",
  //   label: "Peraltilla (Huesca)",
  // },
  // {
  //   municipio: "Cúllar Vega",
  //   provincia: "Granada",
  //   id: "18057",
  //   label: "Cúllar Vega (Granada)",
  // },
  // {
  //   municipio: "Chauchina",
  //   provincia: "Granada",
  //   id: "18059",
  //   label: "Chauchina (Granada)",
  // },
  // {
  //   municipio: "Gobernador",
  //   provincia: "Granada",
  //   id: "18083",
  //   label: "Gobernador (Granada)",
  // },
  // {
  //   municipio: "Chimeneas",
  //   provincia: "Granada",
  //   id: "18061",
  //   label: "Chimeneas (Granada)",
  // },
  // {
  //   municipio: "Deifontes",
  //   provincia: "Granada",
  //   id: "18066",
  //   label: "Deifontes (Granada)",
  // },
  // {
  //   municipio: "Pueyo de Santa Cruz",
  //   provincia: "Huesca",
  //   id: "22193",
  //   label: "Pueyo de Santa Cruz (Huesca)",
  // },
  // {
  //   municipio: "Diezma",
  //   provincia: "Granada",
  //   id: "18067",
  //   label: "Diezma (Granada)",
  // },
  // {
  //   municipio: "Dílar",
  //   provincia: "Granada",
  //   id: "18068",
  //   label: "Dílar (Granada)",
  // },
  // {
  //   municipio: "Dólar",
  //   provincia: "Granada",
  //   id: "18069",
  //   label: "Dólar (Granada)",
  // },
  // {
  //   municipio: "Escúzar",
  //   provincia: "Granada",
  //   id: "18072",
  //   label: "Escúzar (Granada)",
  // },
  // {
  //   municipio: "Jete",
  //   provincia: "Granada",
  //   id: "18109",
  //   label: "Jete (Granada)",
  // },
  // {
  //   municipio: "Ferreira",
  //   provincia: "Granada",
  //   id: "18074",
  //   label: "Ferreira (Granada)",
  // },
  // {
  //   municipio: "Fonelas",
  //   provincia: "Granada",
  //   id: "18076",
  //   label: "Fonelas (Granada)",
  // },
  // {
  //   municipio: "Jun",
  //   provincia: "Granada",
  //   id: "18111",
  //   label: "Jun (Granada)",
  // },
  // {
  //   municipio: "Freila",
  //   provincia: "Granada",
  //   id: "18078",
  //   label: "Freila (Granada)",
  // },
  // {
  //   municipio: "Fuente Vaqueros",
  //   provincia: "Granada",
  //   id: "18079",
  //   label: "Fuente Vaqueros (Granada)",
  // },
  // {
  //   municipio: "Galera",
  //   provincia: "Granada",
  //   id: "18082",
  //   label: "Galera (Granada)",
  // },
  // {
  //   municipio: "Gójar",
  //   provincia: "Granada",
  //   id: "18084",
  //   label: "Gójar (Granada)",
  // },
  // {
  //   municipio: "Gor",
  //   provincia: "Granada",
  //   id: "18085",
  //   label: "Gor (Granada)",
  // },
  // {
  //   municipio: "Gorafe",
  //   provincia: "Granada",
  //   id: "18086",
  //   label: "Gorafe (Granada)",
  // },
  // {
  //   municipio: "Güevéjar",
  //   provincia: "Granada",
  //   id: "18095",
  //   label: "Güevéjar (Granada)",
  // },
  // {
  //   municipio: "Guadahortuna",
  //   provincia: "Granada",
  //   id: "18088",
  //   label: "Guadahortuna (Granada)",
  // },
  // {
  //   municipio: "Güéjar Sierra",
  //   provincia: "Granada",
  //   id: "18094",
  //   label: "Güéjar Sierra (Granada)",
  // },
  // {
  //   municipio: "Huétor de Santillán",
  //   provincia: "Granada",
  //   id: "18099",
  //   label: "Huétor de Santillán (Granada)",
  // },
  // {
  //   municipio: "Villanova",
  //   provincia: "Huesca",
  //   id: "22249",
  //   label: "Villanova (Huesca)",
  // },
  // {
  //   municipio: "Huélago",
  //   provincia: "Granada",
  //   id: "18096",
  //   label: "Huélago (Granada)",
  // },
  // {
  //   municipio: "Huéneja",
  //   provincia: "Granada",
  //   id: "18097",
  //   label: "Huéneja (Granada)",
  // },
  // {
  //   municipio: "Huéscar",
  //   provincia: "Granada",
  //   id: "18098",
  //   label: "Huéscar (Granada)",
  // },
  // {
  //   municipio: "Huétor Tájar",
  //   provincia: "Granada",
  //   id: "18100",
  //   label: "Huétor Tájar (Granada)",
  // },
  // {
  //   municipio: "Jayena",
  //   provincia: "Granada",
  //   id: "18107",
  //   label: "Jayena (Granada)",
  // },
  // {
  //   municipio: "Jérez del Marquesado",
  //   provincia: "Granada",
  //   id: "18108",
  //   label: "Jérez del Marquesado (Granada)",
  // },
  // {
  //   municipio: "Sotodosos",
  //   provincia: "Guadalajara",
  //   id: "19261",
  //   label: "Sotodosos (Guadalajara)",
  // },
  // {
  //   municipio: "Calahorra, La",
  //   provincia: "Granada",
  //   id: "18114",
  //   label: "Calahorra, La (Granada)",
  // },
  // {
  //   municipio: "Láchar",
  //   provincia: "Granada",
  //   id: "18115",
  //   label: "Láchar (Granada)",
  // },
  // {
  //   municipio: "Lecrín",
  //   provincia: "Granada",
  //   id: "18119",
  //   label: "Lecrín (Granada)",
  // },
  // {
  //   municipio: "Lanjarón",
  //   provincia: "Granada",
  //   id: "18116",
  //   label: "Lanjarón (Granada)",
  // },
  // {
  //   municipio: "Lanteira",
  //   provincia: "Granada",
  //   id: "18117",
  //   label: "Lanteira (Granada)",
  // },
  // {
  //   municipio: "Lentegí",
  //   provincia: "Granada",
  //   id: "18120",
  //   label: "Lentegí (Granada)",
  // },
  // {
  //   municipio: "Fuentelviejo",
  //   provincia: "Guadalajara",
  //   id: "19123",
  //   label: "Fuentelviejo (Guadalajara)",
  // },
  // {
  //   municipio: "Lobras",
  //   provincia: "Granada",
  //   id: "18121",
  //   label: "Lobras (Granada)",
  // },
  // {
  //   municipio: "Lugros",
  //   provincia: "Granada",
  //   id: "18123",
  //   label: "Lugros (Granada)",
  // },
  // {
  //   municipio: "Molvízar",
  //   provincia: "Granada",
  //   id: "18133",
  //   label: "Molvízar (Granada)",
  // },
  // {
  //   municipio: "Lújar",
  //   provincia: "Granada",
  //   id: "18124",
  //   label: "Lújar (Granada)",
  // },
  // {
  //   municipio: "Monachil",
  //   provincia: "Granada",
  //   id: "18134",
  //   label: "Monachil (Granada)",
  // },
  // {
  //   municipio: "Malahá, La",
  //   provincia: "Granada",
  //   id: "18126",
  //   label: "Malahá, La (Granada)",
  // },
  // {
  //   municipio: "Maracena",
  //   provincia: "Granada",
  //   id: "18127",
  //   label: "Maracena (Granada)",
  // },
  // {
  //   municipio: "Montefrío",
  //   provincia: "Granada",
  //   id: "18135",
  //   label: "Montefrío (Granada)",
  // },
  // {
  //   municipio: "Marchal",
  //   provincia: "Granada",
  //   id: "18128",
  //   label: "Marchal (Granada)",
  // },
  // {
  //   municipio: "Moclín",
  //   provincia: "Granada",
  //   id: "18132",
  //   label: "Moclín (Granada)",
  // },
  // {
  //   municipio: "Montejícar",
  //   provincia: "Granada",
  //   id: "18136",
  //   label: "Montejícar (Granada)",
  // },
  // {
  //   municipio: "Montillana",
  //   provincia: "Granada",
  //   id: "18137",
  //   label: "Montillana (Granada)",
  // },
  // {
  //   municipio: "Moraleda de Zafayona",
  //   provincia: "Granada",
  //   id: "18138",
  //   label: "Moraleda de Zafayona (Granada)",
  // },
  // {
  //   municipio: "Murtas",
  //   provincia: "Granada",
  //   id: "18141",
  //   label: "Murtas (Granada)",
  // },
  // {
  //   municipio: "Nigüelas",
  //   provincia: "Granada",
  //   id: "18143",
  //   label: "Nigüelas (Granada)",
  // },
  // {
  //   municipio: "Nívar",
  //   provincia: "Granada",
  //   id: "18144",
  //   label: "Nívar (Granada)",
  // },
  // {
  //   municipio: "Ogíjares",
  //   provincia: "Granada",
  //   id: "18145",
  //   label: "Ogíjares (Granada)",
  // },
  // {
  //   municipio: "Villa de Otura",
  //   provincia: "Granada",
  //   id: "18149",
  //   label: "Villa de Otura (Granada)",
  // },
  // {
  //   municipio: "Orce",
  //   provincia: "Granada",
  //   id: "18146",
  //   label: "Orce (Granada)",
  // },
  // {
  //   municipio: "Órgiva",
  //   provincia: "Granada",
  //   id: "18147",
  //   label: "Órgiva (Granada)",
  // },
  // {
  //   municipio: "Otívar",
  //   provincia: "Granada",
  //   id: "18148",
  //   label: "Otívar (Granada)",
  // },
  // {
  //   municipio: "Padul",
  //   provincia: "Granada",
  //   id: "18150",
  //   label: "Padul (Granada)",
  // },
  // {
  //   municipio: "Anquela del Pedregal",
  //   provincia: "Guadalajara",
  //   id: "19034",
  //   label: "Anquela del Pedregal (Guadalajara)",
  // },
  // {
  //   municipio: "Pampaneira",
  //   provincia: "Granada",
  //   id: "18151",
  //   label: "Pampaneira (Granada)",
  // },
  // {
  //   municipio: "Pedro Martínez",
  //   provincia: "Granada",
  //   id: "18152",
  //   label: "Pedro Martínez (Granada)",
  // },
  // {
  //   municipio: "Fuentesaúco",
  //   provincia: "Zamora",
  //   id: "49081",
  //   label: "Fuentesaúco (Zamora)",
  // },
  // {
  //   municipio: "Peligros",
  //   provincia: "Granada",
  //   id: "18153",
  //   label: "Peligros (Granada)",
  // },
  // {
  //   municipio: "Peza, La",
  //   provincia: "Granada",
  //   id: "18154",
  //   label: "Peza, La (Granada)",
  // },
  // {
  //   municipio: "Santa Cruz del Comercio",
  //   provincia: "Granada",
  //   id: "18174",
  //   label: "Santa Cruz del Comercio (Granada)",
  // },
  // {
  //   municipio: "Pinos Genil",
  //   provincia: "Granada",
  //   id: "18157",
  //   label: "Pinos Genil (Granada)",
  // },
  // {
  //   municipio: "Píñar",
  //   provincia: "Granada",
  //   id: "18159",
  //   label: "Píñar (Granada)",
  // },
  // {
  //   municipio: "Polícar",
  //   provincia: "Granada",
  //   id: "18161",
  //   label: "Polícar (Granada)",
  // },
  // {
  //   municipio: "Polopos",
  //   provincia: "Granada",
  //   id: "18162",
  //   label: "Polopos (Granada)",
  // },
  // {
  //   municipio: "Pórtugos",
  //   provincia: "Granada",
  //   id: "18163",
  //   label: "Pórtugos (Granada)",
  // },
  // {
  //   municipio: "Puebla de Don Fadrique",
  //   provincia: "Granada",
  //   id: "18164",
  //   label: "Puebla de Don Fadrique (Granada)",
  // },
  // {
  //   municipio: "Pulianas",
  //   provincia: "Granada",
  //   id: "18165",
  //   label: "Pulianas (Granada)",
  // },
  // {
  //   municipio: "Quéntar",
  //   provincia: "Granada",
  //   id: "18168",
  //   label: "Quéntar (Granada)",
  // },
  // {
  //   municipio: "Rubite",
  //   provincia: "Granada",
  //   id: "18170",
  //   label: "Rubite (Granada)",
  // },
  // {
  //   municipio: "Salar",
  //   provincia: "Granada",
  //   id: "18171",
  //   label: "Salar (Granada)",
  // },
  // {
  //   municipio: "Salobreña",
  //   provincia: "Granada",
  //   id: "18173",
  //   label: "Salobreña (Granada)",
  // },
  // {
  //   municipio: "Hombrados",
  //   provincia: "Guadalajara",
  //   id: "19139",
  //   label: "Hombrados (Guadalajara)",
  // },
  // {
  //   municipio: "Santa Fe",
  //   provincia: "Granada",
  //   id: "18175",
  //   label: "Santa Fe (Granada)",
  // },
  // {
  //   municipio: "Soportújar",
  //   provincia: "Granada",
  //   id: "18176",
  //   label: "Soportújar (Granada)",
  // },
  // {
  //   municipio: "Sorvilán",
  //   provincia: "Granada",
  //   id: "18177",
  //   label: "Sorvilán (Granada)",
  // },
  // {
  //   municipio: "Torre-Cardela",
  //   provincia: "Granada",
  //   id: "18178",
  //   label: "Torre-Cardela (Granada)",
  // },
  // {
  //   municipio: "Torvizcón",
  //   provincia: "Granada",
  //   id: "18179",
  //   label: "Torvizcón (Granada)",
  // },
  // {
  //   municipio: "Trevélez",
  //   provincia: "Granada",
  //   id: "18180",
  //   label: "Trevélez (Granada)",
  // },
  // {
  //   municipio: "Turón",
  //   provincia: "Granada",
  //   id: "18181",
  //   label: "Turón (Granada)",
  // },
  // {
  //   municipio: "Ugíjar",
  //   provincia: "Granada",
  //   id: "18182",
  //   label: "Ugíjar (Granada)",
  // },
  // {
  //   municipio: "Válor",
  //   provincia: "Granada",
  //   id: "18183",
  //   label: "Válor (Granada)",
  // },
  // {
  //   municipio: "Víznar",
  //   provincia: "Granada",
  //   id: "18189",
  //   label: "Víznar (Granada)",
  // },
  // {
  //   municipio: "Vélez de Benaudalla",
  //   provincia: "Granada",
  //   id: "18184",
  //   label: "Vélez de Benaudalla (Granada)",
  // },
  // {
  //   municipio: "Taragudo",
  //   provincia: "Guadalajara",
  //   id: "19263",
  //   label: "Taragudo (Guadalajara)",
  // },
  // {
  //   municipio: "Ventas de Huelma",
  //   provincia: "Granada",
  //   id: "18185",
  //   label: "Ventas de Huelma (Granada)",
  // },
  // {
  //   municipio: "Villanueva de las Torres",
  //   provincia: "Granada",
  //   id: "18187",
  //   label: "Villanueva de las Torres (Granada)",
  // },
  // {
  //   municipio: "Villanueva Mesía",
  //   provincia: "Granada",
  //   id: "18188",
  //   label: "Villanueva Mesía (Granada)",
  // },
  // {
  //   municipio: "Zafarraya",
  //   provincia: "Granada",
  //   id: "18192",
  //   label: "Zafarraya (Granada)",
  // },
  // {
  //   municipio: "Zubia, La",
  //   provincia: "Granada",
  //   id: "18193",
  //   label: "Zubia, La (Granada)",
  // },
  // {
  //   municipio: "Zújar",
  //   provincia: "Granada",
  //   id: "18194",
  //   label: "Zújar (Granada)",
  // },
  // {
  //   municipio: "Taha, La",
  //   provincia: "Granada",
  //   id: "18901",
  //   label: "Taha, La (Granada)",
  // },
  // {
  //   municipio: "Alarilla",
  //   provincia: "Guadalajara",
  //   id: "19005",
  //   label: "Alarilla (Guadalajara)",
  // },
  // {
  //   municipio: "Valle, El",
  //   provincia: "Granada",
  //   id: "18902",
  //   label: "Valle, El (Granada)",
  // },
  // {
  //   municipio: "Villamena",
  //   provincia: "Granada",
  //   id: "18908",
  //   label: "Villamena (Granada)",
  // },
  // {
  //   municipio: "Nevada",
  //   provincia: "Granada",
  //   id: "18903",
  //   label: "Nevada (Granada)",
  // },
  // {
  //   municipio: "Alpujarra de la Sierra",
  //   provincia: "Granada",
  //   id: "18904",
  //   label: "Alpujarra de la Sierra (Granada)",
  // },
  // {
  //   municipio: "Abánades",
  //   provincia: "Guadalajara",
  //   id: "19001",
  //   label: "Abánades (Guadalajara)",
  // },
  // {
  //   municipio: "Gabias, Las",
  //   provincia: "Granada",
  //   id: "18905",
  //   label: "Gabias, Las (Granada)",
  // },
  // {
  //   municipio: "Alcoroches",
  //   provincia: "Guadalajara",
  //   id: "19013",
  //   label: "Alcoroches (Guadalajara)",
  // },
  // {
  //   municipio: "Guájares, Los",
  //   provincia: "Granada",
  //   id: "18906",
  //   label: "Guájares, Los (Granada)",
  // },
  // {
  //   municipio: "Valle del Zalabí",
  //   provincia: "Granada",
  //   id: "18907",
  //   label: "Valle del Zalabí (Granada)",
  // },
  // {
  //   municipio: "Morelábor",
  //   provincia: "Granada",
  //   id: "18909",
  //   label: "Morelábor (Granada)",
  // },
  // {
  //   municipio: "Ablanque",
  //   provincia: "Guadalajara",
  //   id: "19002",
  //   label: "Ablanque (Guadalajara)",
  // },
  // {
  //   municipio: "Pinar, El",
  //   provincia: "Granada",
  //   id: "18910",
  //   label: "Pinar, El (Granada)",
  // },
  // {
  //   municipio: "Fuenteheridos",
  //   provincia: "Huelva",
  //   id: "21033",
  //   label: "Fuenteheridos (Huelva)",
  // },
  // {
  //   municipio: "Cuevas del Campo",
  //   provincia: "Granada",
  //   id: "18912",
  //   label: "Cuevas del Campo (Granada)",
  // },
  // {
  //   municipio: "Albalate de Zorita",
  //   provincia: "Guadalajara",
  //   id: "19006",
  //   label: "Albalate de Zorita (Guadalajara)",
  // },
  // {
  //   municipio: "Albares",
  //   provincia: "Guadalajara",
  //   id: "19007",
  //   label: "Albares (Guadalajara)",
  // },
  // {
  //   municipio: "Albendiego",
  //   provincia: "Guadalajara",
  //   id: "19008",
  //   label: "Albendiego (Guadalajara)",
  // },
  // {
  //   municipio: "Alcocer",
  //   provincia: "Guadalajara",
  //   id: "19009",
  //   label: "Alcocer (Guadalajara)",
  // },
  // {
  //   municipio: "Alcolea de las Peñas",
  //   provincia: "Guadalajara",
  //   id: "19010",
  //   label: "Alcolea de las Peñas (Guadalajara)",
  // },
  // {
  //   municipio: "Alcolea del Pinar",
  //   provincia: "Guadalajara",
  //   id: "19011",
  //   label: "Alcolea del Pinar (Guadalajara)",
  // },
  // {
  //   municipio: "Tordelrábano",
  //   provincia: "Guadalajara",
  //   id: "19270",
  //   label: "Tordelrábano (Guadalajara)",
  // },
  // {
  //   municipio: "Aldeanueva de Guadalajara",
  //   provincia: "Guadalajara",
  //   id: "19015",
  //   label: "Aldeanueva de Guadalajara (Guadalajara)",
  // },
  // {
  //   municipio: "Casar, El",
  //   provincia: "Guadalajara",
  //   id: "19071",
  //   label: "Casar, El (Guadalajara)",
  // },
  // {
  //   municipio: "Algar de Mesa",
  //   provincia: "Guadalajara",
  //   id: "19016",
  //   label: "Algar de Mesa (Guadalajara)",
  // },
  // {
  //   municipio: "Alique",
  //   provincia: "Guadalajara",
  //   id: "19019",
  //   label: "Alique (Guadalajara)",
  // },
  // {
  //   municipio: "Algora",
  //   provincia: "Guadalajara",
  //   id: "19017",
  //   label: "Algora (Guadalajara)",
  // },
  // {
  //   municipio: "Alhóndiga",
  //   provincia: "Guadalajara",
  //   id: "19018",
  //   label: "Alhóndiga (Guadalajara)",
  // },
  // {
  //   municipio: "Saelices de Mayorga",
  //   provincia: "Valladolid",
  //   id: "47140",
  //   label: "Saelices de Mayorga (Valladolid)",
  // },
  // {
  //   municipio: "Almonacid de Zorita",
  //   provincia: "Guadalajara",
  //   id: "19022",
  //   label: "Almonacid de Zorita (Guadalajara)",
  // },
  // {
  //   municipio: "Armallones",
  //   provincia: "Guadalajara",
  //   id: "19040",
  //   label: "Armallones (Guadalajara)",
  // },
  // {
  //   municipio: "Alocén",
  //   provincia: "Guadalajara",
  //   id: "19023",
  //   label: "Alocén (Guadalajara)",
  // },
  // {
  //   municipio: "Alovera",
  //   provincia: "Guadalajara",
  //   id: "19024",
  //   label: "Alovera (Guadalajara)",
  // },
  // {
  //   municipio: "Angón",
  //   provincia: "Guadalajara",
  //   id: "19031",
  //   label: "Angón (Guadalajara)",
  // },
  // {
  //   municipio: "Casas de San Galindo",
  //   provincia: "Guadalajara",
  //   id: "19073",
  //   label: "Casas de San Galindo (Guadalajara)",
  // },
  // {
  //   municipio: "Anguita",
  //   provincia: "Guadalajara",
  //   id: "19032",
  //   label: "Anguita (Guadalajara)",
  // },
  // {
  //   municipio: "Anquela del Ducado",
  //   provincia: "Guadalajara",
  //   id: "19033",
  //   label: "Anquela del Ducado (Guadalajara)",
  // },
  // {
  //   municipio: "Aranzueque",
  //   provincia: "Guadalajara",
  //   id: "19036",
  //   label: "Aranzueque (Guadalajara)",
  // },
  // {
  //   municipio: "Arbancón",
  //   provincia: "Guadalajara",
  //   id: "19037",
  //   label: "Arbancón (Guadalajara)",
  // },
  // {
  //   municipio: "Almohaja",
  //   provincia: "Teruel",
  //   id: "44018",
  //   label: "Almohaja (Teruel)",
  // },
  // {
  //   municipio: "Arbeteta",
  //   provincia: "Guadalajara",
  //   id: "19038",
  //   label: "Arbeteta (Guadalajara)",
  // },
  // {
  //   municipio: "Argecilla",
  //   provincia: "Guadalajara",
  //   id: "19039",
  //   label: "Argecilla (Guadalajara)",
  // },
  // {
  //   municipio: "Armuña de Tajuña",
  //   provincia: "Guadalajara",
  //   id: "19041",
  //   label: "Armuña de Tajuña (Guadalajara)",
  // },
  // {
  //   municipio: "Arroyo de las Fraguas",
  //   provincia: "Guadalajara",
  //   id: "19042",
  //   label: "Arroyo de las Fraguas (Guadalajara)",
  // },
  // {
  //   municipio: "Vallecillo",
  //   provincia: "León",
  //   id: "24191",
  //   label: "Vallecillo (León)",
  // },
  // {
  //   municipio: "Atanzón",
  //   provincia: "Guadalajara",
  //   id: "19043",
  //   label: "Atanzón (Guadalajara)",
  // },
  // {
  //   municipio: "Auñón",
  //   provincia: "Guadalajara",
  //   id: "19045",
  //   label: "Auñón (Guadalajara)",
  // },
  // {
  //   municipio: "Atienza",
  //   provincia: "Guadalajara",
  //   id: "19044",
  //   label: "Atienza (Guadalajara)",
  // },
  // {
  //   municipio: "Fuencemillán",
  //   provincia: "Guadalajara",
  //   id: "19119",
  //   label: "Fuencemillán (Guadalajara)",
  // },
  // {
  //   municipio: "Azuqueca de Henares",
  //   provincia: "Guadalajara",
  //   id: "19046",
  //   label: "Azuqueca de Henares (Guadalajara)",
  // },
  // {
  //   municipio: "Bañuelos",
  //   provincia: "Guadalajara",
  //   id: "19049",
  //   label: "Bañuelos (Guadalajara)",
  // },
  // {
  //   municipio: "Baides",
  //   provincia: "Guadalajara",
  //   id: "19047",
  //   label: "Baides (Guadalajara)",
  // },
  // {
  //   municipio: "Baños de Tajo",
  //   provincia: "Guadalajara",
  //   id: "19048",
  //   label: "Baños de Tajo (Guadalajara)",
  // },
  // {
  //   municipio: "Candilichera",
  //   provincia: "Soria",
  //   id: "42049",
  //   label: "Candilichera (Soria)",
  // },
  // {
  //   municipio: "Fontanar",
  //   provincia: "Guadalajara",
  //   id: "19117",
  //   label: "Fontanar (Guadalajara)",
  // },
  // {
  //   municipio: "Fuembellida",
  //   provincia: "Guadalajara",
  //   id: "19118",
  //   label: "Fuembellida (Guadalajara)",
  // },
  // {
  //   municipio: "Cañamaque",
  //   provincia: "Soria",
  //   id: "42050",
  //   label: "Cañamaque (Soria)",
  // },
  // {
  //   municipio: "Berninches",
  //   provincia: "Guadalajara",
  //   id: "19051",
  //   label: "Berninches (Guadalajara)",
  // },
  // {
  //   municipio: "Bodera, La",
  //   provincia: "Guadalajara",
  //   id: "19052",
  //   label: "Bodera, La (Guadalajara)",
  // },
  // {
  //   municipio: "Brihuega",
  //   provincia: "Guadalajara",
  //   id: "19053",
  //   label: "Brihuega (Guadalajara)",
  // },
  // {
  //   municipio: "Torre la Ribera",
  //   provincia: "Huesca",
  //   id: "22233",
  //   label: "Torre la Ribera (Huesca)",
  // },
  // {
  //   municipio: "Budia",
  //   provincia: "Guadalajara",
  //   id: "19054",
  //   label: "Budia (Guadalajara)",
  // },
  // {
  //   municipio: "Bujalaro",
  //   provincia: "Guadalajara",
  //   id: "19055",
  //   label: "Bujalaro (Guadalajara)",
  // },
  // {
  //   municipio: "Bustares",
  //   provincia: "Guadalajara",
  //   id: "19057",
  //   label: "Bustares (Guadalajara)",
  // },
  // {
  //   municipio: "Campisábalos",
  //   provincia: "Guadalajara",
  //   id: "19061",
  //   label: "Campisábalos (Guadalajara)",
  // },
  // {
  //   municipio: "Ocentejo",
  //   provincia: "Guadalajara",
  //   id: "19199",
  //   label: "Ocentejo (Guadalajara)",
  // },
  // {
  //   municipio: "Cabanillas del Campo",
  //   provincia: "Guadalajara",
  //   id: "19058",
  //   label: "Cabanillas del Campo (Guadalajara)",
  // },
  // {
  //   municipio: "Canredondo",
  //   provincia: "Guadalajara",
  //   id: "19064",
  //   label: "Canredondo (Guadalajara)",
  // },
  // {
  //   municipio: "Cantalojas",
  //   provincia: "Guadalajara",
  //   id: "19065",
  //   label: "Cantalojas (Guadalajara)",
  // },
  // {
  //   municipio: "Cañizar",
  //   provincia: "Guadalajara",
  //   id: "19066",
  //   label: "Cañizar (Guadalajara)",
  // },
  // {
  //   municipio: "Castilforte",
  //   provincia: "Guadalajara",
  //   id: "19078",
  //   label: "Castilforte (Guadalajara)",
  // },
  // {
  //   municipio: "Castilnuevo",
  //   provincia: "Guadalajara",
  //   id: "19079",
  //   label: "Castilnuevo (Guadalajara)",
  // },
  // {
  //   municipio: "Carabantes",
  //   provincia: "Soria",
  //   id: "42051",
  //   label: "Carabantes (Soria)",
  // },
  // {
  //   municipio: "Cendejas de Enmedio",
  //   provincia: "Guadalajara",
  //   id: "19080",
  //   label: "Cendejas de Enmedio (Guadalajara)",
  // },
  // {
  //   municipio: "Cendejas de la Torre",
  //   provincia: "Guadalajara",
  //   id: "19081",
  //   label: "Cendejas de la Torre (Guadalajara)",
  // },
  // {
  //   municipio: "Centenera",
  //   provincia: "Guadalajara",
  //   id: "19082",
  //   label: "Centenera (Guadalajara)",
  // },
  // {
  //   municipio: "Cifuentes",
  //   provincia: "Guadalajara",
  //   id: "19086",
  //   label: "Cifuentes (Guadalajara)",
  // },
  // {
  //   municipio: "Ciruelas",
  //   provincia: "Guadalajara",
  //   id: "19088",
  //   label: "Ciruelas (Guadalajara)",
  // },
  // {
  //   municipio: "Cobeta",
  //   provincia: "Guadalajara",
  //   id: "19090",
  //   label: "Cobeta (Guadalajara)",
  // },
  // {
  //   municipio: "Cogollor",
  //   provincia: "Guadalajara",
  //   id: "19091",
  //   label: "Cogollor (Guadalajara)",
  // },
  // {
  //   municipio: "Fuentelahiguera de Albatages",
  //   provincia: "Guadalajara",
  //   id: "19120",
  //   label: "Fuentelahiguera de Albatages (Guadalajara)",
  // },
  // {
  //   municipio: "Cogolludo",
  //   provincia: "Guadalajara",
  //   id: "19092",
  //   label: "Cogolludo (Guadalajara)",
  // },
  // {
  //   municipio: "Fuentelencina",
  //   provincia: "Guadalajara",
  //   id: "19121",
  //   label: "Fuentelencina (Guadalajara)",
  // },
  // {
  //   municipio: "Ciruelos del Pinar",
  //   provincia: "Guadalajara",
  //   id: "19089",
  //   label: "Ciruelos del Pinar (Guadalajara)",
  // },
  // {
  //   municipio: "Condemios de Abajo",
  //   provincia: "Guadalajara",
  //   id: "19095",
  //   label: "Condemios de Abajo (Guadalajara)",
  // },
  // {
  //   municipio: "Condemios de Arriba",
  //   provincia: "Guadalajara",
  //   id: "19096",
  //   label: "Condemios de Arriba (Guadalajara)",
  // },
  // {
  //   municipio: "Congostrina",
  //   provincia: "Guadalajara",
  //   id: "19097",
  //   label: "Congostrina (Guadalajara)",
  // },
  // {
  //   municipio: "Copernal",
  //   provincia: "Guadalajara",
  //   id: "19098",
  //   label: "Copernal (Guadalajara)",
  // },
  // {
  //   municipio: "Corduente",
  //   provincia: "Guadalajara",
  //   id: "19099",
  //   label: "Corduente (Guadalajara)",
  // },
  // {
  //   municipio: "Cubillo de Uceda, El",
  //   provincia: "Guadalajara",
  //   id: "19102",
  //   label: "Cubillo de Uceda, El (Guadalajara)",
  // },
  // {
  //   municipio: "Checa",
  //   provincia: "Guadalajara",
  //   id: "19103",
  //   label: "Checa (Guadalajara)",
  // },
  // {
  //   municipio: "Chequilla",
  //   provincia: "Guadalajara",
  //   id: "19104",
  //   label: "Chequilla (Guadalajara)",
  // },
  // {
  //   municipio: "Chiloeches",
  //   provincia: "Guadalajara",
  //   id: "19105",
  //   label: "Chiloeches (Guadalajara)",
  // },
  // {
  //   municipio: "Escopete",
  //   provincia: "Guadalajara",
  //   id: "19112",
  //   label: "Escopete (Guadalajara)",
  // },
  // {
  //   municipio: "Chillarón del Rey",
  //   provincia: "Guadalajara",
  //   id: "19106",
  //   label: "Chillarón del Rey (Guadalajara)",
  // },
  // {
  //   municipio: "Driebes",
  //   provincia: "Guadalajara",
  //   id: "19107",
  //   label: "Driebes (Guadalajara)",
  // },
  // {
  //   municipio: "Arenzana de Abajo",
  //   provincia: "La Rioja",
  //   id: "26015",
  //   label: "Arenzana de Abajo (La Rioja)",
  // },
  // {
  //   municipio: "Durón",
  //   provincia: "Guadalajara",
  //   id: "19108",
  //   label: "Durón (Guadalajara)",
  // },
  // {
  //   municipio: "Embid",
  //   provincia: "Guadalajara",
  //   id: "19109",
  //   label: "Embid (Guadalajara)",
  // },
  // {
  //   municipio: "Esplegares",
  //   provincia: "Guadalajara",
  //   id: "19114",
  //   label: "Esplegares (Guadalajara)",
  // },
  // {
  //   municipio: "Escamilla",
  //   provincia: "Guadalajara",
  //   id: "19110",
  //   label: "Escamilla (Guadalajara)",
  // },
  // {
  //   municipio: "Escariche",
  //   provincia: "Guadalajara",
  //   id: "19111",
  //   label: "Escariche (Guadalajara)",
  // },
  // {
  //   municipio: "Espinosa de Henares",
  //   provincia: "Guadalajara",
  //   id: "19113",
  //   label: "Espinosa de Henares (Guadalajara)",
  // },
  // {
  //   municipio: "Establés",
  //   provincia: "Guadalajara",
  //   id: "19115",
  //   label: "Establés (Guadalajara)",
  // },
  // {
  //   municipio: "Estriégana",
  //   provincia: "Guadalajara",
  //   id: "19116",
  //   label: "Estriégana (Guadalajara)",
  // },
  // {
  //   municipio: "Fuentenovilla",
  //   provincia: "Guadalajara",
  //   id: "19124",
  //   label: "Fuentenovilla (Guadalajara)",
  // },
  // {
  //   municipio: "Gajanejos",
  //   provincia: "Guadalajara",
  //   id: "19125",
  //   label: "Gajanejos (Guadalajara)",
  // },
  // {
  //   municipio: "Galápagos",
  //   provincia: "Guadalajara",
  //   id: "19126",
  //   label: "Galápagos (Guadalajara)",
  // },
  // {
  //   municipio: "Galve de Sorbe",
  //   provincia: "Guadalajara",
  //   id: "19127",
  //   label: "Galve de Sorbe (Guadalajara)",
  // },
  // {
  //   municipio: "Henche",
  //   provincia: "Guadalajara",
  //   id: "19132",
  //   label: "Henche (Guadalajara)",
  // },
  // {
  //   municipio: "Heras de Ayuso",
  //   provincia: "Guadalajara",
  //   id: "19133",
  //   label: "Heras de Ayuso (Guadalajara)",
  // },
  // {
  //   municipio: "Herrería",
  //   provincia: "Guadalajara",
  //   id: "19134",
  //   label: "Herrería (Guadalajara)",
  // },
  // {
  //   municipio: "Hiendelaencina",
  //   provincia: "Guadalajara",
  //   id: "19135",
  //   label: "Hiendelaencina (Guadalajara)",
  // },
  // {
  //   municipio: "Hijes",
  //   provincia: "Guadalajara",
  //   id: "19136",
  //   label: "Hijes (Guadalajara)",
  // },
  // {
  //   municipio: "Inviernas, Las",
  //   provincia: "Guadalajara",
  //   id: "19154",
  //   label: "Inviernas, Las (Guadalajara)",
  // },
  // {
  //   municipio: "Hita",
  //   provincia: "Guadalajara",
  //   id: "19138",
  //   label: "Hita (Guadalajara)",
  // },
  // {
  //   municipio: "Hontoba",
  //   provincia: "Guadalajara",
  //   id: "19142",
  //   label: "Hontoba (Guadalajara)",
  // },
  // {
  //   municipio: "Horche",
  //   provincia: "Guadalajara",
  //   id: "19143",
  //   label: "Horche (Guadalajara)",
  // },
  // {
  //   municipio: "Pobladura de Pelayo García",
  //   provincia: "León",
  //   id: "24113",
  //   label: "Pobladura de Pelayo García (León)",
  // },
  // {
  //   municipio: "Loranca de Tajuña",
  //   provincia: "Guadalajara",
  //   id: "19160",
  //   label: "Loranca de Tajuña (Guadalajara)",
  // },
  // {
  //   municipio: "Hortezuela de Océn",
  //   provincia: "Guadalajara",
  //   id: "19145",
  //   label: "Hortezuela de Océn (Guadalajara)",
  // },
  // {
  //   municipio: "Huerce, La",
  //   provincia: "Guadalajara",
  //   id: "19146",
  //   label: "Huerce, La (Guadalajara)",
  // },
  // {
  //   municipio: "Huérmeces del Cerro",
  //   provincia: "Guadalajara",
  //   id: "19147",
  //   label: "Huérmeces del Cerro (Guadalajara)",
  // },
  // {
  //   municipio: "Huertahernando",
  //   provincia: "Guadalajara",
  //   id: "19148",
  //   label: "Huertahernando (Guadalajara)",
  // },
  // {
  //   municipio: "Hueva",
  //   provincia: "Guadalajara",
  //   id: "19150",
  //   label: "Hueva (Guadalajara)",
  // },
  // {
  //   municipio: "Humanes",
  //   provincia: "Guadalajara",
  //   id: "19151",
  //   label: "Humanes (Guadalajara)",
  // },
  // {
  //   municipio: "Irueste",
  //   provincia: "Guadalajara",
  //   id: "19155",
  //   label: "Irueste (Guadalajara)",
  // },
  // {
  //   municipio: "Matarrubia",
  //   provincia: "Guadalajara",
  //   id: "19173",
  //   label: "Matarrubia (Guadalajara)",
  // },
  // {
  //   municipio: "Illana",
  //   provincia: "Guadalajara",
  //   id: "19152",
  //   label: "Illana (Guadalajara)",
  // },
  // {
  //   municipio: "Iniéstola",
  //   provincia: "Guadalajara",
  //   id: "19153",
  //   label: "Iniéstola (Guadalajara)",
  // },
  // {
  //   municipio: "Jadraque",
  //   provincia: "Guadalajara",
  //   id: "19156",
  //   label: "Jadraque (Guadalajara)",
  // },
  // {
  //   municipio: "Ledanca",
  //   provincia: "Guadalajara",
  //   id: "19159",
  //   label: "Ledanca (Guadalajara)",
  // },
  // {
  //   municipio: "Lupiana",
  //   provincia: "Guadalajara",
  //   id: "19161",
  //   label: "Lupiana (Guadalajara)",
  // },
  // {
  //   municipio: "Luzaga",
  //   provincia: "Guadalajara",
  //   id: "19162",
  //   label: "Luzaga (Guadalajara)",
  // },
  // {
  //   municipio: "Luzón",
  //   provincia: "Guadalajara",
  //   id: "19163",
  //   label: "Luzón (Guadalajara)",
  // },
  // {
  //   municipio: "Matillas",
  //   provincia: "Guadalajara",
  //   id: "19174",
  //   label: "Matillas (Guadalajara)",
  // },
  // {
  //   municipio: "Majaelrayo",
  //   provincia: "Guadalajara",
  //   id: "19165",
  //   label: "Majaelrayo (Guadalajara)",
  // },
  // {
  //   municipio: "Castañares de Rioja",
  //   provincia: "La Rioja",
  //   id: "26043",
  //   label: "Castañares de Rioja (La Rioja)",
  // },
  // {
  //   municipio: "Málaga del Fresno",
  //   provincia: "Guadalajara",
  //   id: "19166",
  //   label: "Málaga del Fresno (Guadalajara)",
  // },
  // {
  //   municipio: "Malaguilla",
  //   provincia: "Guadalajara",
  //   id: "19167",
  //   label: "Malaguilla (Guadalajara)",
  // },
  // {
  //   municipio: "Mandayona",
  //   provincia: "Guadalajara",
  //   id: "19168",
  //   label: "Mandayona (Guadalajara)",
  // },
  // {
  //   municipio: "Mantiel",
  //   provincia: "Guadalajara",
  //   id: "19169",
  //   label: "Mantiel (Guadalajara)",
  // },
  // {
  //   municipio: "Mazarete",
  //   provincia: "Guadalajara",
  //   id: "19175",
  //   label: "Mazarete (Guadalajara)",
  // },
  // {
  //   municipio: "Maranchón",
  //   provincia: "Guadalajara",
  //   id: "19170",
  //   label: "Maranchón (Guadalajara)",
  // },
  // {
  //   municipio: "Mazuecos",
  //   provincia: "Guadalajara",
  //   id: "19176",
  //   label: "Mazuecos (Guadalajara)",
  // },
  // {
  //   municipio: "Marchamalo",
  //   provincia: "Guadalajara",
  //   id: "19171",
  //   label: "Marchamalo (Guadalajara)",
  // },
  // {
  //   municipio: "Megina",
  //   provincia: "Guadalajara",
  //   id: "19178",
  //   label: "Megina (Guadalajara)",
  // },
  // {
  //   municipio: "Membrillera",
  //   provincia: "Guadalajara",
  //   id: "19179",
  //   label: "Membrillera (Guadalajara)",
  // },
  // {
  //   municipio: "Olivar, El",
  //   provincia: "Guadalajara",
  //   id: "19200",
  //   label: "Olivar, El (Guadalajara)",
  // },
  // {
  //   municipio: "Miedes de Atienza",
  //   provincia: "Guadalajara",
  //   id: "19181",
  //   label: "Miedes de Atienza (Guadalajara)",
  // },
  // {
  //   municipio: "Mierla, La",
  //   provincia: "Guadalajara",
  //   id: "19182",
  //   label: "Mierla, La (Guadalajara)",
  // },
  // {
  //   municipio: "Milmarcos",
  //   provincia: "Guadalajara",
  //   id: "19183",
  //   label: "Milmarcos (Guadalajara)",
  // },
  // {
  //   municipio: "Millana",
  //   provincia: "Guadalajara",
  //   id: "19184",
  //   label: "Millana (Guadalajara)",
  // },
  // {
  //   municipio: "Miñosa, La",
  //   provincia: "Guadalajara",
  //   id: "19185",
  //   label: "Miñosa, La (Guadalajara)",
  // },
  // {
  //   municipio: "Mirabueno",
  //   provincia: "Guadalajara",
  //   id: "19186",
  //   label: "Mirabueno (Guadalajara)",
  // },
  // {
  //   municipio: "Miralrío",
  //   provincia: "Guadalajara",
  //   id: "19187",
  //   label: "Miralrío (Guadalajara)",
  // },
  // {
  //   municipio: "Mochales",
  //   provincia: "Guadalajara",
  //   id: "19188",
  //   label: "Mochales (Guadalajara)",
  // },
  // {
  //   municipio: "Hornillos de Cameros",
  //   provincia: "La Rioja",
  //   id: "26077",
  //   label: "Hornillos de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Mohernando",
  //   provincia: "Guadalajara",
  //   id: "19189",
  //   label: "Mohernando (Guadalajara)",
  // },
  // {
  //   municipio: "Montarrón",
  //   provincia: "Guadalajara",
  //   id: "19193",
  //   label: "Montarrón (Guadalajara)",
  // },
  // {
  //   municipio: "Molina de Aragón",
  //   provincia: "Guadalajara",
  //   id: "19190",
  //   label: "Molina de Aragón (Guadalajara)",
  // },
  // {
  //   municipio: "Monasterio",
  //   provincia: "Guadalajara",
  //   id: "19191",
  //   label: "Monasterio (Guadalajara)",
  // },
  // {
  //   municipio: "Mondéjar",
  //   provincia: "Guadalajara",
  //   id: "19192",
  //   label: "Mondéjar (Guadalajara)",
  // },
  // {
  //   municipio: "Moratilla de los Meleros",
  //   provincia: "Guadalajara",
  //   id: "19194",
  //   label: "Moratilla de los Meleros (Guadalajara)",
  // },
  // {
  //   municipio: "Morenilla",
  //   provincia: "Guadalajara",
  //   id: "19195",
  //   label: "Morenilla (Guadalajara)",
  // },
  // {
  //   municipio: "Ortigosa de Cameros",
  //   provincia: "La Rioja",
  //   id: "26112",
  //   label: "Ortigosa de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Muduex",
  //   provincia: "Guadalajara",
  //   id: "19196",
  //   label: "Muduex (Guadalajara)",
  // },
  // {
  //   municipio: "Navas de Jadraque, Las",
  //   provincia: "Guadalajara",
  //   id: "19197",
  //   label: "Navas de Jadraque, Las (Guadalajara)",
  // },
  // {
  //   municipio: "Negredo",
  //   provincia: "Guadalajara",
  //   id: "19198",
  //   label: "Negredo (Guadalajara)",
  // },
  // {
  //   municipio: "Olmeda de Cobeta",
  //   provincia: "Guadalajara",
  //   id: "19201",
  //   label: "Olmeda de Cobeta (Guadalajara)",
  // },
  // {
  //   municipio: "Olmeda de Jadraque, La",
  //   provincia: "Guadalajara",
  //   id: "19202",
  //   label: "Olmeda de Jadraque, La (Guadalajara)",
  // },
  // {
  //   municipio: "Pálmaces de Jadraque",
  //   provincia: "Guadalajara",
  //   id: "19208",
  //   label: "Pálmaces de Jadraque (Guadalajara)",
  // },
  // {
  //   municipio: "Ordial, El",
  //   provincia: "Guadalajara",
  //   id: "19203",
  //   label: "Ordial, El (Guadalajara)",
  // },
  // {
  //   municipio: "Orea",
  //   provincia: "Guadalajara",
  //   id: "19204",
  //   label: "Orea (Guadalajara)",
  // },
  // {
  //   municipio: "Paredes de Sigüenza",
  //   provincia: "Guadalajara",
  //   id: "19210",
  //   label: "Paredes de Sigüenza (Guadalajara)",
  // },
  // {
  //   municipio: "Prados Redondos",
  //   provincia: "Guadalajara",
  //   id: "19227",
  //   label: "Prados Redondos (Guadalajara)",
  // },
  // {
  //   municipio: "Pedregal, El",
  //   provincia: "Guadalajara",
  //   id: "19213",
  //   label: "Pedregal, El (Guadalajara)",
  // },
  // {
  //   municipio: "Pareja",
  //   provincia: "Guadalajara",
  //   id: "19211",
  //   label: "Pareja (Guadalajara)",
  // },
  // {
  //   municipio: "Pastrana",
  //   provincia: "Guadalajara",
  //   id: "19212",
  //   label: "Pastrana (Guadalajara)",
  // },
  // {
  //   municipio: "Peñalén",
  //   provincia: "Guadalajara",
  //   id: "19214",
  //   label: "Peñalén (Guadalajara)",
  // },
  // {
  //   municipio: "Peñalver",
  //   provincia: "Guadalajara",
  //   id: "19215",
  //   label: "Peñalver (Guadalajara)",
  // },
  // {
  //   municipio: "Peralejos de las Truchas",
  //   provincia: "Guadalajara",
  //   id: "19216",
  //   label: "Peralejos de las Truchas (Guadalajara)",
  // },
  // {
  //   municipio: "Peralveche",
  //   provincia: "Guadalajara",
  //   id: "19217",
  //   label: "Peralveche (Guadalajara)",
  // },
  // {
  //   municipio: "Pinilla de Jadraque",
  //   provincia: "Guadalajara",
  //   id: "19218",
  //   label: "Pinilla de Jadraque (Guadalajara)",
  // },
  // {
  //   municipio: "Pinilla de Molina",
  //   provincia: "Guadalajara",
  //   id: "19219",
  //   label: "Pinilla de Molina (Guadalajara)",
  // },
  // {
  //   municipio: "Pioz",
  //   provincia: "Guadalajara",
  //   id: "19220",
  //   label: "Pioz (Guadalajara)",
  // },
  // {
  //   municipio: "Piqueras",
  //   provincia: "Guadalajara",
  //   id: "19221",
  //   label: "Piqueras (Guadalajara)",
  // },
  // {
  //   municipio: "Pobo de Dueñas, El",
  //   provincia: "Guadalajara",
  //   id: "19222",
  //   label: "Pobo de Dueñas, El (Guadalajara)",
  // },
  // {
  //   municipio: "Poveda de la Sierra",
  //   provincia: "Guadalajara",
  //   id: "19223",
  //   label: "Poveda de la Sierra (Guadalajara)",
  // },
  // {
  //   municipio: "Pozo de Almoguera",
  //   provincia: "Guadalajara",
  //   id: "19224",
  //   label: "Pozo de Almoguera (Guadalajara)",
  // },
  // {
  //   municipio: "Pozo de Guadalajara",
  //   provincia: "Guadalajara",
  //   id: "19225",
  //   label: "Pozo de Guadalajara (Guadalajara)",
  // },
  // {
  //   municipio: "Prádena de Atienza",
  //   provincia: "Guadalajara",
  //   id: "19226",
  //   label: "Prádena de Atienza (Guadalajara)",
  // },
  // {
  //   municipio: "Puebla de Beleña",
  //   provincia: "Guadalajara",
  //   id: "19228",
  //   label: "Puebla de Beleña (Guadalajara)",
  // },
  // {
  //   municipio: "Puebla de Valles",
  //   provincia: "Guadalajara",
  //   id: "19229",
  //   label: "Puebla de Valles (Guadalajara)",
  // },
  // {
  //   municipio: "Quer",
  //   provincia: "Guadalajara",
  //   id: "19230",
  //   label: "Quer (Guadalajara)",
  // },
  // {
  //   municipio: "Recuenco, El",
  //   provincia: "Guadalajara",
  //   id: "19232",
  //   label: "Recuenco, El (Guadalajara)",
  // },
  // {
  //   municipio: "Renera",
  //   provincia: "Guadalajara",
  //   id: "19233",
  //   label: "Renera (Guadalajara)",
  // },
  // {
  //   municipio: "Retiendas",
  //   provincia: "Guadalajara",
  //   id: "19234",
  //   label: "Retiendas (Guadalajara)",
  // },
  // {
  //   municipio: "Riba de Saelices",
  //   provincia: "Guadalajara",
  //   id: "19235",
  //   label: "Riba de Saelices (Guadalajara)",
  // },
  // {
  //   municipio: "Rillo de Gallo",
  //   provincia: "Guadalajara",
  //   id: "19237",
  //   label: "Rillo de Gallo (Guadalajara)",
  // },
  // {
  //   municipio: "Riofrío del Llano",
  //   provincia: "Guadalajara",
  //   id: "19238",
  //   label: "Riofrío del Llano (Guadalajara)",
  // },
  // {
  //   municipio: "Robledillo de Mohernando",
  //   provincia: "Guadalajara",
  //   id: "19239",
  //   label: "Robledillo de Mohernando (Guadalajara)",
  // },
  // {
  //   municipio: "Robledo de Corpes",
  //   provincia: "Guadalajara",
  //   id: "19240",
  //   label: "Robledo de Corpes (Guadalajara)",
  // },
  // {
  //   municipio: "Setiles",
  //   provincia: "Guadalajara",
  //   id: "19255",
  //   label: "Setiles (Guadalajara)",
  // },
  // {
  //   municipio: "Sienes",
  //   provincia: "Guadalajara",
  //   id: "19256",
  //   label: "Sienes (Guadalajara)",
  // },
  // {
  //   municipio: "Romanillos de Atienza",
  //   provincia: "Guadalajara",
  //   id: "19241",
  //   label: "Romanillos de Atienza (Guadalajara)",
  // },
  // {
  //   municipio: "Romanones",
  //   provincia: "Guadalajara",
  //   id: "19242",
  //   label: "Romanones (Guadalajara)",
  // },
  // {
  //   municipio: "Rueda de la Sierra",
  //   provincia: "Guadalajara",
  //   id: "19243",
  //   label: "Rueda de la Sierra (Guadalajara)",
  // },
  // {
  //   municipio: "Sacecorbo",
  //   provincia: "Guadalajara",
  //   id: "19244",
  //   label: "Sacecorbo (Guadalajara)",
  // },
  // {
  //   municipio: "Sacedón",
  //   provincia: "Guadalajara",
  //   id: "19245",
  //   label: "Sacedón (Guadalajara)",
  // },
  // {
  //   municipio: "Salmerón",
  //   provincia: "Guadalajara",
  //   id: "19247",
  //   label: "Salmerón (Guadalajara)",
  // },
  // {
  //   municipio: "San Andrés del Congosto",
  //   provincia: "Guadalajara",
  //   id: "19248",
  //   label: "San Andrés del Congosto (Guadalajara)",
  // },
  // {
  //   municipio: "San Andrés del Rey",
  //   provincia: "Guadalajara",
  //   id: "19249",
  //   label: "San Andrés del Rey (Guadalajara)",
  // },
  // {
  //   municipio: "Saúca",
  //   provincia: "Guadalajara",
  //   id: "19251",
  //   label: "Saúca (Guadalajara)",
  // },
  // {
  //   municipio: "Sotillo, El",
  //   provincia: "Guadalajara",
  //   id: "19260",
  //   label: "Sotillo, El (Guadalajara)",
  // },
  // {
  //   municipio: "Sayatón",
  //   provincia: "Guadalajara",
  //   id: "19252",
  //   label: "Sayatón (Guadalajara)",
  // },
  // {
  //   municipio: "Cantagallo",
  //   provincia: "Salamanca",
  //   id: "37080",
  //   label: "Cantagallo (Salamanca)",
  // },
  // {
  //   municipio: "Selas",
  //   provincia: "Guadalajara",
  //   id: "19254",
  //   label: "Selas (Guadalajara)",
  // },
  // {
  //   municipio: "Sigüenza",
  //   provincia: "Guadalajara",
  //   id: "19257",
  //   label: "Sigüenza (Guadalajara)",
  // },
  // {
  //   municipio: "Solanillos del Extremo",
  //   provincia: "Guadalajara",
  //   id: "19258",
  //   label: "Solanillos del Extremo (Guadalajara)",
  // },
  // {
  //   municipio: "Somolinos",
  //   provincia: "Guadalajara",
  //   id: "19259",
  //   label: "Somolinos (Guadalajara)",
  // },
  // {
  //   municipio: "Tamajón",
  //   provincia: "Guadalajara",
  //   id: "19262",
  //   label: "Tamajón (Guadalajara)",
  // },
  // {
  //   municipio: "Taravilla",
  //   provincia: "Guadalajara",
  //   id: "19264",
  //   label: "Taravilla (Guadalajara)",
  // },
  // {
  //   municipio: "Tordellego",
  //   provincia: "Guadalajara",
  //   id: "19271",
  //   label: "Tordellego (Guadalajara)",
  // },
  // {
  //   municipio: "Tartanedo",
  //   provincia: "Guadalajara",
  //   id: "19265",
  //   label: "Tartanedo (Guadalajara)",
  // },
  // {
  //   municipio: "Tendilla",
  //   provincia: "Guadalajara",
  //   id: "19266",
  //   label: "Tendilla (Guadalajara)",
  // },
  // {
  //   municipio: "Torrecuadrada de Molina",
  //   provincia: "Guadalajara",
  //   id: "19277",
  //   label: "Torrecuadrada de Molina (Guadalajara)",
  // },
  // {
  //   municipio: "Terzaga",
  //   provincia: "Guadalajara",
  //   id: "19267",
  //   label: "Terzaga (Guadalajara)",
  // },
  // {
  //   municipio: "Tierzo",
  //   provincia: "Guadalajara",
  //   id: "19268",
  //   label: "Tierzo (Guadalajara)",
  // },
  // {
  //   municipio: "Torremocha de Jadraque",
  //   provincia: "Guadalajara",
  //   id: "19281",
  //   label: "Torremocha de Jadraque (Guadalajara)",
  // },
  // {
  //   municipio: "Toba, La",
  //   provincia: "Guadalajara",
  //   id: "19269",
  //   label: "Toba, La (Guadalajara)",
  // },
  // {
  //   municipio: "Valdeavellano",
  //   provincia: "Guadalajara",
  //   id: "19299",
  //   label: "Valdeavellano (Guadalajara)",
  // },
  // {
  //   municipio: "Valdeaveruelo",
  //   provincia: "Guadalajara",
  //   id: "19300",
  //   label: "Valdeaveruelo (Guadalajara)",
  // },
  // {
  //   municipio: "Valdeconcha",
  //   provincia: "Guadalajara",
  //   id: "19301",
  //   label: "Valdeconcha (Guadalajara)",
  // },
  // {
  //   municipio: "Tordesilos",
  //   provincia: "Guadalajara",
  //   id: "19272",
  //   label: "Tordesilos (Guadalajara)",
  // },
  // {
  //   municipio: "Torija",
  //   provincia: "Guadalajara",
  //   id: "19274",
  //   label: "Torija (Guadalajara)",
  // },
  // {
  //   municipio: "Torrecuadradilla",
  //   provincia: "Guadalajara",
  //   id: "19278",
  //   label: "Torrecuadradilla (Guadalajara)",
  // },
  // {
  //   municipio: "Torre del Burgo",
  //   provincia: "Guadalajara",
  //   id: "19279",
  //   label: "Torre del Burgo (Guadalajara)",
  // },
  // {
  //   municipio: "Torremochuela",
  //   provincia: "Guadalajara",
  //   id: "19284",
  //   label: "Torremochuela (Guadalajara)",
  // },
  // {
  //   municipio: "Torrejón del Rey",
  //   provincia: "Guadalajara",
  //   id: "19280",
  //   label: "Torrejón del Rey (Guadalajara)",
  // },
  // {
  //   municipio: "Torremocha del Campo",
  //   provincia: "Guadalajara",
  //   id: "19282",
  //   label: "Torremocha del Campo (Guadalajara)",
  // },
  // {
  //   municipio: "Torremocha del Pinar",
  //   provincia: "Guadalajara",
  //   id: "19283",
  //   label: "Torremocha del Pinar (Guadalajara)",
  // },
  // {
  //   municipio: "Torrubia",
  //   provincia: "Guadalajara",
  //   id: "19285",
  //   label: "Torrubia (Guadalajara)",
  // },
  // {
  //   municipio: "Tórtola de Henares",
  //   provincia: "Guadalajara",
  //   id: "19286",
  //   label: "Tórtola de Henares (Guadalajara)",
  // },
  // {
  //   municipio: "Yunquera de Henares",
  //   provincia: "Guadalajara",
  //   id: "19331",
  //   label: "Yunquera de Henares (Guadalajara)",
  // },
  // {
  //   municipio: "Yunta, La",
  //   provincia: "Guadalajara",
  //   id: "19332",
  //   label: "Yunta, La (Guadalajara)",
  // },
  // {
  //   municipio: "Tortuera",
  //   provincia: "Guadalajara",
  //   id: "19287",
  //   label: "Tortuera (Guadalajara)",
  // },
  // {
  //   municipio: "Tortuero",
  //   provincia: "Guadalajara",
  //   id: "19288",
  //   label: "Tortuero (Guadalajara)",
  // },
  // {
  //   municipio: "Trijueque",
  //   provincia: "Guadalajara",
  //   id: "19290",
  //   label: "Trijueque (Guadalajara)",
  // },
  // {
  //   municipio: "Trillo",
  //   provincia: "Guadalajara",
  //   id: "19291",
  //   label: "Trillo (Guadalajara)",
  // },
  // {
  //   municipio: "Utande",
  //   provincia: "Guadalajara",
  //   id: "19296",
  //   label: "Utande (Guadalajara)",
  // },
  // {
  //   municipio: "Valdarachas",
  //   provincia: "Guadalajara",
  //   id: "19297",
  //   label: "Valdarachas (Guadalajara)",
  // },
  // {
  //   municipio: "Valdearenas",
  //   provincia: "Guadalajara",
  //   id: "19298",
  //   label: "Valdearenas (Guadalajara)",
  // },
  // {
  //   municipio: "Valdegrudas",
  //   provincia: "Guadalajara",
  //   id: "19302",
  //   label: "Valdegrudas (Guadalajara)",
  // },
  // {
  //   municipio: "Valdelcubo",
  //   provincia: "Guadalajara",
  //   id: "19303",
  //   label: "Valdelcubo (Guadalajara)",
  // },
  // {
  //   municipio: "Manzanillo",
  //   provincia: "Valladolid",
  //   id: "47080",
  //   label: "Manzanillo (Valladolid)",
  // },
  // {
  //   municipio: "Valdenuño Fernández",
  //   provincia: "Guadalajara",
  //   id: "19304",
  //   label: "Valdenuño Fernández (Guadalajara)",
  // },
  // {
  //   municipio: "Valdepeñas de la Sierra",
  //   provincia: "Guadalajara",
  //   id: "19305",
  //   label: "Valdepeñas de la Sierra (Guadalajara)",
  // },
  // {
  //   municipio: "Valderrebollo",
  //   provincia: "Guadalajara",
  //   id: "19306",
  //   label: "Valderrebollo (Guadalajara)",
  // },
  // {
  //   municipio: "Muriel",
  //   provincia: "Valladolid",
  //   id: "47100",
  //   label: "Muriel (Valladolid)",
  // },
  // {
  //   municipio: "Valdesotos",
  //   provincia: "Guadalajara",
  //   id: "19307",
  //   label: "Valdesotos (Guadalajara)",
  // },
  // {
  //   municipio: "Valfermoso de Tajuña",
  //   provincia: "Guadalajara",
  //   id: "19308",
  //   label: "Valfermoso de Tajuña (Guadalajara)",
  // },
  // {
  //   municipio: "Valhermoso",
  //   provincia: "Guadalajara",
  //   id: "19309",
  //   label: "Valhermoso (Guadalajara)",
  // },
  // {
  //   municipio: "Valtablado del Río",
  //   provincia: "Guadalajara",
  //   id: "19310",
  //   label: "Valtablado del Río (Guadalajara)",
  // },
  // {
  //   municipio: "Valverde de los Arroyos",
  //   provincia: "Guadalajara",
  //   id: "19311",
  //   label: "Valverde de los Arroyos (Guadalajara)",
  // },
  // {
  //   municipio: "Viana de Jadraque",
  //   provincia: "Guadalajara",
  //   id: "19314",
  //   label: "Viana de Jadraque (Guadalajara)",
  // },
  // {
  //   municipio: "Villares de Jadraque",
  //   provincia: "Guadalajara",
  //   id: "19321",
  //   label: "Villares de Jadraque (Guadalajara)",
  // },
  // {
  //   municipio: "Villanueva de Alcorón",
  //   provincia: "Guadalajara",
  //   id: "19317",
  //   label: "Villanueva de Alcorón (Guadalajara)",
  // },
  // {
  //   municipio: "Villanueva de Argecilla",
  //   provincia: "Guadalajara",
  //   id: "19318",
  //   label: "Villanueva de Argecilla (Guadalajara)",
  // },
  // {
  //   municipio: "Villanueva de la Torre",
  //   provincia: "Guadalajara",
  //   id: "19319",
  //   label: "Villanueva de la Torre (Guadalajara)",
  // },
  // {
  //   municipio: "Villaseca de Henares",
  //   provincia: "Guadalajara",
  //   id: "19322",
  //   label: "Villaseca de Henares (Guadalajara)",
  // },
  // {
  //   municipio: "Villaseca de Uceda",
  //   provincia: "Guadalajara",
  //   id: "19323",
  //   label: "Villaseca de Uceda (Guadalajara)",
  // },
  // {
  //   municipio: "Villel de Mesa",
  //   provincia: "Guadalajara",
  //   id: "19324",
  //   label: "Villel de Mesa (Guadalajara)",
  // },
  // {
  //   municipio: "Viñuelas",
  //   provincia: "Guadalajara",
  //   id: "19325",
  //   label: "Viñuelas (Guadalajara)",
  // },
  // {
  //   municipio: "Yebes",
  //   provincia: "Guadalajara",
  //   id: "19326",
  //   label: "Yebes (Guadalajara)",
  // },
  // {
  //   municipio: "Yebra",
  //   provincia: "Guadalajara",
  //   id: "19327",
  //   label: "Yebra (Guadalajara)",
  // },
  // {
  //   municipio: "Yélamos de Arriba",
  //   provincia: "Guadalajara",
  //   id: "19330",
  //   label: "Yélamos de Arriba (Guadalajara)",
  // },
  // {
  //   municipio: "Zarzuela de Jadraque",
  //   provincia: "Guadalajara",
  //   id: "19334",
  //   label: "Zarzuela de Jadraque (Guadalajara)",
  // },
  // {
  //   municipio: "Zorita de los Canes",
  //   provincia: "Guadalajara",
  //   id: "19335",
  //   label: "Zorita de los Canes (Guadalajara)",
  // },
  // {
  //   municipio: "Semillas",
  //   provincia: "Guadalajara",
  //   id: "19901",
  //   label: "Semillas (Guadalajara)",
  // },
  // {
  //   municipio: "Alájar",
  //   provincia: "Huelva",
  //   id: "21001",
  //   label: "Alájar (Huelva)",
  // },
  // {
  //   municipio: "Aljaraque",
  //   provincia: "Huelva",
  //   id: "21002",
  //   label: "Aljaraque (Huelva)",
  // },
  // {
  //   municipio: "Almendro, El",
  //   provincia: "Huelva",
  //   id: "21003",
  //   label: "Almendro, El (Huelva)",
  // },
  // {
  //   municipio: "Alosno",
  //   provincia: "Huelva",
  //   id: "21006",
  //   label: "Alosno (Huelva)",
  // },
  // {
  //   municipio: "Campofrío",
  //   provincia: "Huelva",
  //   id: "21019",
  //   label: "Campofrío (Huelva)",
  // },
  // {
  //   municipio: "Arroyomolinos de León",
  //   provincia: "Huelva",
  //   id: "21009",
  //   label: "Arroyomolinos de León (Huelva)",
  // },
  // {
  //   municipio: "Ayamonte",
  //   provincia: "Huelva",
  //   id: "21010",
  //   label: "Ayamonte (Huelva)",
  // },
  // {
  //   municipio: "Beas",
  //   provincia: "Huelva",
  //   id: "21011",
  //   label: "Beas (Huelva)",
  // },
  // {
  //   municipio: "Berrocal",
  //   provincia: "Huelva",
  //   id: "21012",
  //   label: "Berrocal (Huelva)",
  // },
  // {
  //   municipio: "Bollullos Par del Condado",
  //   provincia: "Huelva",
  //   id: "21013",
  //   label: "Bollullos Par del Condado (Huelva)",
  // },
  // {
  //   municipio: "Bonares",
  //   provincia: "Huelva",
  //   id: "21014",
  //   label: "Bonares (Huelva)",
  // },
  // {
  //   municipio: "Castaño del Robledo",
  //   provincia: "Huelva",
  //   id: "21022",
  //   label: "Castaño del Robledo (Huelva)",
  // },
  // {
  //   municipio: "Cerro de Andévalo, El",
  //   provincia: "Huelva",
  //   id: "21023",
  //   label: "Cerro de Andévalo, El (Huelva)",
  // },
  // {
  //   municipio: "Cabezas Rubias",
  //   provincia: "Huelva",
  //   id: "21015",
  //   label: "Cabezas Rubias (Huelva)",
  // },
  // {
  //   municipio: "Cortegana",
  //   provincia: "Huelva",
  //   id: "21025",
  //   label: "Cortegana (Huelva)",
  // },
  // {
  //   municipio: "Reznos",
  //   provincia: "Soria",
  //   id: "42156",
  //   label: "Reznos (Soria)",
  // },
  // {
  //   municipio: "Cala",
  //   provincia: "Huelva",
  //   id: "21016",
  //   label: "Cala (Huelva)",
  // },
  // {
  //   municipio: "Campillo, El",
  //   provincia: "Huelva",
  //   id: "21018",
  //   label: "Campillo, El (Huelva)",
  // },
  // {
  //   municipio: "Cañaveral de León",
  //   provincia: "Huelva",
  //   id: "21020",
  //   label: "Cañaveral de León (Huelva)",
  // },
  // {
  //   municipio: "Cartaya",
  //   provincia: "Huelva",
  //   id: "21021",
  //   label: "Cartaya (Huelva)",
  // },
  // {
  //   municipio: "Cortelazor",
  //   provincia: "Huelva",
  //   id: "21026",
  //   label: "Cortelazor (Huelva)",
  // },
  // {
  //   municipio: "Corteconcepción",
  //   provincia: "Huelva",
  //   id: "21024",
  //   label: "Corteconcepción (Huelva)",
  // },
  // {
  //   municipio: "Galaroza",
  //   provincia: "Huelva",
  //   id: "21034",
  //   label: "Galaroza (Huelva)",
  // },
  // {
  //   municipio: "Gibraleón",
  //   provincia: "Huelva",
  //   id: "21035",
  //   label: "Gibraleón (Huelva)",
  // },
  // {
  //   municipio: "Valfarta",
  //   provincia: "Huesca",
  //   id: "22242",
  //   label: "Valfarta (Huesca)",
  // },
  // {
  //   municipio: "Granada de Río-Tinto, La",
  //   provincia: "Huelva",
  //   id: "21036",
  //   label: "Granada de Río-Tinto, La (Huelva)",
  // },
  // {
  //   municipio: "Granado, El",
  //   provincia: "Huelva",
  //   id: "21037",
  //   label: "Granado, El (Huelva)",
  // },
  // {
  //   municipio: "Capdesaso",
  //   provincia: "Huesca",
  //   id: "22079",
  //   label: "Capdesaso (Huesca)",
  // },
  // {
  //   municipio: "Higuera de la Sierra",
  //   provincia: "Huelva",
  //   id: "21038",
  //   label: "Higuera de la Sierra (Huelva)",
  // },
  // {
  //   municipio: "Hinojales",
  //   provincia: "Huelva",
  //   id: "21039",
  //   label: "Hinojales (Huelva)",
  // },
  // {
  //   municipio: "Huelva",
  //   provincia: "Huelva",
  //   id: "21041",
  //   label: "Huelva (Huelva)",
  // },
  // {
  //   municipio: "Isla Cristina",
  //   provincia: "Huelva",
  //   id: "21042",
  //   label: "Isla Cristina (Huelva)",
  // },
  // {
  //   municipio: "Jabugo",
  //   provincia: "Huelva",
  //   id: "21043",
  //   label: "Jabugo (Huelva)",
  // },
  // {
  //   municipio: "Linares de la Sierra",
  //   provincia: "Huelva",
  //   id: "21045",
  //   label: "Linares de la Sierra (Huelva)",
  // },
  // {
  //   municipio: "Lucena del Puerto",
  //   provincia: "Huelva",
  //   id: "21046",
  //   label: "Lucena del Puerto (Huelva)",
  // },
  // {
  //   municipio: "Manzanilla",
  //   provincia: "Huelva",
  //   id: "21047",
  //   label: "Manzanilla (Huelva)",
  // },
  // {
  //   municipio: "Minas de Riotinto",
  //   provincia: "Huelva",
  //   id: "21049",
  //   label: "Minas de Riotinto (Huelva)",
  // },
  // {
  //   municipio: "Moguer",
  //   provincia: "Huelva",
  //   id: "21050",
  //   label: "Moguer (Huelva)",
  // },
  // {
  //   municipio: "Portillo de Soria",
  //   provincia: "Soria",
  //   id: "42140",
  //   label: "Portillo de Soria (Soria)",
  // },
  // {
  //   municipio: "Nava, La",
  //   provincia: "Huelva",
  //   id: "21051",
  //   label: "Nava, La (Huelva)",
  // },
  // {
  //   municipio: "Nerva",
  //   provincia: "Huelva",
  //   id: "21052",
  //   label: "Nerva (Huelva)",
  // },
  // {
  //   municipio: "Palma del Condado, La",
  //   provincia: "Huelva",
  //   id: "21054",
  //   label: "Palma del Condado, La (Huelva)",
  // },
  // {
  //   municipio: "Paymogo",
  //   provincia: "Huelva",
  //   id: "21057",
  //   label: "Paymogo (Huelva)",
  // },
  // {
  //   municipio: "Santa Eulalia Bajera",
  //   provincia: "La Rioja",
  //   id: "26136",
  //   label: "Santa Eulalia Bajera (La Rioja)",
  // },
  // {
  //   municipio: "Puerto Moral",
  //   provincia: "Huelva",
  //   id: "21059",
  //   label: "Puerto Moral (Huelva)",
  // },
  // {
  //   municipio: "Punta Umbría",
  //   provincia: "Huelva",
  //   id: "21060",
  //   label: "Punta Umbría (Huelva)",
  // },
  // {
  //   municipio: "San Bartolomé de la Torre",
  //   provincia: "Huelva",
  //   id: "21063",
  //   label: "San Bartolomé de la Torre (Huelva)",
  // },
  // {
  //   municipio: "San Juan del Puerto",
  //   provincia: "Huelva",
  //   id: "21064",
  //   label: "San Juan del Puerto (Huelva)",
  // },
  // {
  //   municipio: "Sanlúcar de Guadiana",
  //   provincia: "Huelva",
  //   id: "21065",
  //   label: "Sanlúcar de Guadiana (Huelva)",
  // },
  // {
  //   municipio: "Rociana del Condado",
  //   provincia: "Huelva",
  //   id: "21061",
  //   label: "Rociana del Condado (Huelva)",
  // },
  // {
  //   municipio: "Trigueros",
  //   provincia: "Huelva",
  //   id: "21070",
  //   label: "Trigueros (Huelva)",
  // },
  // {
  //   municipio: "San Silvestre de Guzmán",
  //   provincia: "Huelva",
  //   id: "21066",
  //   label: "San Silvestre de Guzmán (Huelva)",
  // },
  // {
  //   municipio: "Santa Ana la Real",
  //   provincia: "Huelva",
  //   id: "21067",
  //   label: "Santa Ana la Real (Huelva)",
  // },
  // {
  //   municipio: "Santa Bárbara de Casa",
  //   provincia: "Huelva",
  //   id: "21068",
  //   label: "Santa Bárbara de Casa (Huelva)",
  // },
  // {
  //   municipio: "Santa Olalla del Cala",
  //   provincia: "Huelva",
  //   id: "21069",
  //   label: "Santa Olalla del Cala (Huelva)",
  // },
  // {
  //   municipio: "Valdelarco",
  //   provincia: "Huelva",
  //   id: "21071",
  //   label: "Valdelarco (Huelva)",
  // },
  // {
  //   municipio: "Villablanca",
  //   provincia: "Huelva",
  //   id: "21073",
  //   label: "Villablanca (Huelva)",
  // },
  // {
  //   municipio: "Zufre",
  //   provincia: "Huelva",
  //   id: "21079",
  //   label: "Zufre (Huelva)",
  // },
  // {
  //   municipio: "Santo Domingo de la Calzada",
  //   provincia: "La Rioja",
  //   id: "26138",
  //   label: "Santo Domingo de la Calzada (La Rioja)",
  // },
  // {
  //   municipio: "Villalba del Alcor",
  //   provincia: "Huelva",
  //   id: "21074",
  //   label: "Villalba del Alcor (Huelva)",
  // },
  // {
  //   municipio: "Villanueva de las Cruces",
  //   provincia: "Huelva",
  //   id: "21075",
  //   label: "Villanueva de las Cruces (Huelva)",
  // },
  // {
  //   municipio: "Villanueva de los Castillejos",
  //   provincia: "Huelva",
  //   id: "21076",
  //   label: "Villanueva de los Castillejos (Huelva)",
  // },
  // {
  //   municipio: "Villarrasa",
  //   provincia: "Huelva",
  //   id: "21077",
  //   label: "Villarrasa (Huelva)",
  // },
  // {
  //   municipio: "Zalamea la Real",
  //   provincia: "Huelva",
  //   id: "21078",
  //   label: "Zalamea la Real (Huelva)",
  // },
  // {
  //   municipio: "Abiego",
  //   provincia: "Huesca",
  //   id: "22001",
  //   label: "Abiego (Huesca)",
  // },
  // {
  //   municipio: "Albalatillo",
  //   provincia: "Huesca",
  //   id: "22008",
  //   label: "Albalatillo (Huesca)",
  // },
  // {
  //   municipio: "Abizanda",
  //   provincia: "Huesca",
  //   id: "22002",
  //   label: "Abizanda (Huesca)",
  // },
  // {
  //   municipio: "Albelda",
  //   provincia: "Huesca",
  //   id: "22009",
  //   label: "Albelda (Huesca)",
  // },
  // {
  //   municipio: "Albero Alto",
  //   provincia: "Huesca",
  //   id: "22011",
  //   label: "Albero Alto (Huesca)",
  // },
  // {
  //   municipio: "Adahuesca",
  //   provincia: "Huesca",
  //   id: "22003",
  //   label: "Adahuesca (Huesca)",
  // },
  // {
  //   municipio: "Albero Bajo",
  //   provincia: "Huesca",
  //   id: "22012",
  //   label: "Albero Bajo (Huesca)",
  // },
  // {
  //   municipio: "Agüero",
  //   provincia: "Huesca",
  //   id: "22004",
  //   label: "Agüero (Huesca)",
  // },
  // {
  //   municipio: "Aisa",
  //   provincia: "Huesca",
  //   id: "22006",
  //   label: "Aisa (Huesca)",
  // },
  // {
  //   municipio: "Albalate de Cinca",
  //   provincia: "Huesca",
  //   id: "22007",
  //   label: "Albalate de Cinca (Huesca)",
  // },
  // {
  //   municipio: "Alcalá de Gurrea",
  //   provincia: "Huesca",
  //   id: "22014",
  //   label: "Alcalá de Gurrea (Huesca)",
  // },
  // {
  //   municipio: "Alcalá del Obispo",
  //   provincia: "Huesca",
  //   id: "22015",
  //   label: "Alcalá del Obispo (Huesca)",
  // },
  // {
  //   municipio: "Alcampell",
  //   provincia: "Huesca",
  //   id: "22016",
  //   label: "Alcampell (Huesca)",
  // },
  // {
  //   municipio: "Alcolea de Cinca",
  //   provincia: "Huesca",
  //   id: "22017",
  //   label: "Alcolea de Cinca (Huesca)",
  // },
  // {
  //   municipio: "Almunia de San Juan",
  //   provincia: "Huesca",
  //   id: "22022",
  //   label: "Almunia de San Juan (Huesca)",
  // },
  // {
  //   municipio: "Alcubierre",
  //   provincia: "Huesca",
  //   id: "22018",
  //   label: "Alcubierre (Huesca)",
  // },
  // {
  //   municipio: "Alerre",
  //   provincia: "Huesca",
  //   id: "22019",
  //   label: "Alerre (Huesca)",
  // },
  // {
  //   municipio: "Almudévar",
  //   provincia: "Huesca",
  //   id: "22021",
  //   label: "Almudévar (Huesca)",
  // },
  // {
  //   municipio: "Almuniente",
  //   provincia: "Huesca",
  //   id: "22023",
  //   label: "Almuniente (Huesca)",
  // },
  // {
  //   municipio: "Alquézar",
  //   provincia: "Huesca",
  //   id: "22024",
  //   label: "Alquézar (Huesca)",
  // },
  // {
  //   municipio: "Altorricón",
  //   provincia: "Huesca",
  //   id: "22025",
  //   label: "Altorricón (Huesca)",
  // },
  // {
  //   municipio: "Angüés",
  //   provincia: "Huesca",
  //   id: "22027",
  //   label: "Angüés (Huesca)",
  // },
  // {
  //   municipio: "Antillón",
  //   provincia: "Huesca",
  //   id: "22029",
  //   label: "Antillón (Huesca)",
  // },
  // {
  //   municipio: "Aragüés del Puerto",
  //   provincia: "Huesca",
  //   id: "22032",
  //   label: "Aragüés del Puerto (Huesca)",
  // },
  // {
  //   municipio: "Arén",
  //   provincia: "Huesca",
  //   id: "22035",
  //   label: "Arén (Huesca)",
  // },
  // {
  //   municipio: "Argavieso",
  //   provincia: "Huesca",
  //   id: "22036",
  //   label: "Argavieso (Huesca)",
  // },
  // {
  //   municipio: "Azlor",
  //   provincia: "Huesca",
  //   id: "22042",
  //   label: "Azlor (Huesca)",
  // },
  // {
  //   municipio: "Arguis",
  //   provincia: "Huesca",
  //   id: "22037",
  //   label: "Arguis (Huesca)",
  // },
  // {
  //   municipio: "Baldellou",
  //   provincia: "Huesca",
  //   id: "22045",
  //   label: "Baldellou (Huesca)",
  // },
  // {
  //   municipio: "Ayerbe",
  //   provincia: "Huesca",
  //   id: "22039",
  //   label: "Ayerbe (Huesca)",
  // },
  // {
  //   municipio: "Barbués",
  //   provincia: "Huesca",
  //   id: "22049",
  //   label: "Barbués (Huesca)",
  // },
  // {
  //   municipio: "Azanuy-Alins",
  //   provincia: "Huesca",
  //   id: "22040",
  //   label: "Azanuy-Alins (Huesca)",
  // },
  // {
  //   municipio: "Azara",
  //   provincia: "Huesca",
  //   id: "22041",
  //   label: "Azara (Huesca)",
  // },
  // {
  //   municipio: "Baélls",
  //   provincia: "Huesca",
  //   id: "22043",
  //   label: "Baélls (Huesca)",
  // },
  // {
  //   municipio: "Barbuñales",
  //   provincia: "Huesca",
  //   id: "22050",
  //   label: "Barbuñales (Huesca)",
  // },
  // {
  //   municipio: "Bárcabo",
  //   provincia: "Huesca",
  //   id: "22051",
  //   label: "Bárcabo (Huesca)",
  // },
  // {
  //   municipio: "Bailo",
  //   provincia: "Huesca",
  //   id: "22044",
  //   label: "Bailo (Huesca)",
  // },
  // {
  //   municipio: "Ballobar",
  //   provincia: "Huesca",
  //   id: "22046",
  //   label: "Ballobar (Huesca)",
  // },
  // {
  //   municipio: "Bonansa",
  //   provincia: "Huesca",
  //   id: "22067",
  //   label: "Bonansa (Huesca)",
  // },
  // {
  //   municipio: "Borau",
  //   provincia: "Huesca",
  //   id: "22068",
  //   label: "Borau (Huesca)",
  // },
  // {
  //   municipio: "Belver de Cinca",
  //   provincia: "Huesca",
  //   id: "22052",
  //   label: "Belver de Cinca (Huesca)",
  // },
  // {
  //   municipio: "Broto",
  //   provincia: "Huesca",
  //   id: "22069",
  //   label: "Broto (Huesca)",
  // },
  // {
  //   municipio: "Caldearenas",
  //   provincia: "Huesca",
  //   id: "22072",
  //   label: "Caldearenas (Huesca)",
  // },
  // {
  //   municipio: "Banastás",
  //   provincia: "Huesca",
  //   id: "22047",
  //   label: "Banastás (Huesca)",
  // },
  // {
  //   municipio: "Barbastro",
  //   provincia: "Huesca",
  //   id: "22048",
  //   label: "Barbastro (Huesca)",
  // },
  // {
  //   municipio: "Ibieca",
  //   provincia: "Huesca",
  //   id: "22126",
  //   label: "Ibieca (Huesca)",
  // },
  // {
  //   municipio: "Benabarre",
  //   provincia: "Huesca",
  //   id: "22053",
  //   label: "Benabarre (Huesca)",
  // },
  // {
  //   municipio: "Berbegal",
  //   provincia: "Huesca",
  //   id: "22055",
  //   label: "Berbegal (Huesca)",
  // },
  // {
  //   municipio: "Bielsa",
  //   provincia: "Huesca",
  //   id: "22057",
  //   label: "Bielsa (Huesca)",
  // },
  // {
  //   municipio: "Bierge",
  //   provincia: "Huesca",
  //   id: "22058",
  //   label: "Bierge (Huesca)",
  // },
  // {
  //   municipio: "Biescas",
  //   provincia: "Huesca",
  //   id: "22059",
  //   label: "Biescas (Huesca)",
  // },
  // {
  //   municipio: "Blecua y Torres",
  //   provincia: "Huesca",
  //   id: "22064",
  //   label: "Blecua y Torres (Huesca)",
  // },
  // {
  //   municipio: "Binaced",
  //   provincia: "Huesca",
  //   id: "22060",
  //   label: "Binaced (Huesca)",
  // },
  // {
  //   municipio: "Binéfar",
  //   provincia: "Huesca",
  //   id: "22061",
  //   label: "Binéfar (Huesca)",
  // },
  // {
  //   municipio: "Bisaurri",
  //   provincia: "Huesca",
  //   id: "22062",
  //   label: "Bisaurri (Huesca)",
  // },
  // {
  //   municipio: "Biscarrués",
  //   provincia: "Huesca",
  //   id: "22063",
  //   label: "Biscarrués (Huesca)",
  // },
  // {
  //   municipio: "Boltaña",
  //   provincia: "Huesca",
  //   id: "22066",
  //   label: "Boltaña (Huesca)",
  // },
  // {
  //   municipio: "Canal de Berdún",
  //   provincia: "Huesca",
  //   id: "22076",
  //   label: "Canal de Berdún (Huesca)",
  // },
  // {
  //   municipio: "Candasnos",
  //   provincia: "Huesca",
  //   id: "22077",
  //   label: "Candasnos (Huesca)",
  // },
  // {
  //   municipio: "Castiello de Jaca",
  //   provincia: "Huesca",
  //   id: "22086",
  //   label: "Castiello de Jaca (Huesca)",
  // },
  // {
  //   municipio: "Canfranc",
  //   provincia: "Huesca",
  //   id: "22078",
  //   label: "Canfranc (Huesca)",
  // },
  // {
  //   municipio: "Capella",
  //   provincia: "Huesca",
  //   id: "22080",
  //   label: "Capella (Huesca)",
  // },
  // {
  //   municipio: "Casbas de Huesca",
  //   provincia: "Huesca",
  //   id: "22081",
  //   label: "Casbas de Huesca (Huesca)",
  // },
  // {
  //   municipio: "Castejón del Puente",
  //   provincia: "Huesca",
  //   id: "22082",
  //   label: "Castejón del Puente (Huesca)",
  // },
  // {
  //   municipio: "Castejón de Monegros",
  //   provincia: "Huesca",
  //   id: "22083",
  //   label: "Castejón de Monegros (Huesca)",
  // },
  // {
  //   municipio: "Castigaleu",
  //   provincia: "Huesca",
  //   id: "22087",
  //   label: "Castigaleu (Huesca)",
  // },
  // {
  //   municipio: "Castejón de Sos",
  //   provincia: "Huesca",
  //   id: "22084",
  //   label: "Castejón de Sos (Huesca)",
  // },
  // {
  //   municipio: "Castelflorite",
  //   provincia: "Huesca",
  //   id: "22085",
  //   label: "Castelflorite (Huesca)",
  // },
  // {
  //   municipio: "Labuerda",
  //   provincia: "Huesca",
  //   id: "22133",
  //   label: "Labuerda (Huesca)",
  // },
  // {
  //   municipio: "Grado, El",
  //   provincia: "Huesca",
  //   id: "22115",
  //   label: "Grado, El (Huesca)",
  // },
  // {
  //   municipio: "Castillonroy",
  //   provincia: "Huesca",
  //   id: "22089",
  //   label: "Castillonroy (Huesca)",
  // },
  // {
  //   municipio: "Grañén",
  //   provincia: "Huesca",
  //   id: "22116",
  //   label: "Grañén (Huesca)",
  // },
  // {
  //   municipio: "Graus",
  //   provincia: "Huesca",
  //   id: "22117",
  //   label: "Graus (Huesca)",
  // },
  // {
  //   municipio: "Castillazuelo",
  //   provincia: "Huesca",
  //   id: "22088",
  //   label: "Castillazuelo (Huesca)",
  // },
  // {
  //   municipio: "Colungo",
  //   provincia: "Huesca",
  //   id: "22090",
  //   label: "Colungo (Huesca)",
  // },
  // {
  //   municipio: "Chalamera",
  //   provincia: "Huesca",
  //   id: "22094",
  //   label: "Chalamera (Huesca)",
  // },
  // {
  //   municipio: "Chía",
  //   provincia: "Huesca",
  //   id: "22095",
  //   label: "Chía (Huesca)",
  // },
  // {
  //   municipio: "Chimillas",
  //   provincia: "Huesca",
  //   id: "22096",
  //   label: "Chimillas (Huesca)",
  // },
  // {
  //   municipio: "Fago",
  //   provincia: "Huesca",
  //   id: "22106",
  //   label: "Fago (Huesca)",
  // },
  // {
  //   municipio: "Esplús",
  //   provincia: "Huesca",
  //   id: "22099",
  //   label: "Esplús (Huesca)",
  // },
  // {
  //   municipio: "Estada",
  //   provincia: "Huesca",
  //   id: "22102",
  //   label: "Estada (Huesca)",
  // },
  // {
  //   municipio: "Fanlo",
  //   provincia: "Huesca",
  //   id: "22107",
  //   label: "Fanlo (Huesca)",
  // },
  // {
  //   municipio: "Estadilla",
  //   provincia: "Huesca",
  //   id: "22103",
  //   label: "Estadilla (Huesca)",
  // },
  // {
  //   municipio: "Fonz",
  //   provincia: "Huesca",
  //   id: "22110",
  //   label: "Fonz (Huesca)",
  // },
  // {
  //   municipio: "Estopiñán del Castillo",
  //   provincia: "Huesca",
  //   id: "22105",
  //   label: "Estopiñán del Castillo (Huesca)",
  // },
  // {
  //   municipio: "Gistaín",
  //   provincia: "Huesca",
  //   id: "22114",
  //   label: "Gistaín (Huesca)",
  // },
  // {
  //   municipio: "Fiscal",
  //   provincia: "Huesca",
  //   id: "22109",
  //   label: "Fiscal (Huesca)",
  // },
  // {
  //   municipio: "Villacarralón",
  //   provincia: "Valladolid",
  //   id: "47198",
  //   label: "Villacarralón (Valladolid)",
  // },
  // {
  //   municipio: "Foradada del Toscar",
  //   provincia: "Huesca",
  //   id: "22111",
  //   label: "Foradada del Toscar (Huesca)",
  // },
  // {
  //   municipio: "Fraga",
  //   provincia: "Huesca",
  //   id: "22112",
  //   label: "Fraga (Huesca)",
  // },
  // {
  //   municipio: "Hoz de Jaca",
  //   provincia: "Huesca",
  //   id: "22122",
  //   label: "Hoz de Jaca (Huesca)",
  // },
  // {
  //   municipio: "Fueva, La",
  //   provincia: "Huesca",
  //   id: "22113",
  //   label: "Fueva, La (Huesca)",
  // },
  // {
  //   municipio: "Gurrea de Gállego",
  //   provincia: "Huesca",
  //   id: "22119",
  //   label: "Gurrea de Gállego (Huesca)",
  // },
  // {
  //   municipio: "Navafría",
  //   provincia: "Segovia",
  //   id: "40139",
  //   label: "Navafría (Segovia)",
  // },
  // {
  //   municipio: "Huerto",
  //   provincia: "Huesca",
  //   id: "22124",
  //   label: "Huerto (Huesca)",
  // },
  // {
  //   municipio: "Huesca",
  //   provincia: "Huesca",
  //   id: "22125",
  //   label: "Huesca (Huesca)",
  // },
  // {
  //   municipio: "San Torcuato",
  //   provincia: "La Rioja",
  //   id: "26139",
  //   label: "San Torcuato (La Rioja)",
  // },
  // {
  //   municipio: "Igriés",
  //   provincia: "Huesca",
  //   id: "22127",
  //   label: "Igriés (Huesca)",
  // },
  // {
  //   municipio: "Ilche",
  //   provincia: "Huesca",
  //   id: "22128",
  //   label: "Ilche (Huesca)",
  // },
  // {
  //   municipio: "Isábena",
  //   provincia: "Huesca",
  //   id: "22129",
  //   label: "Isábena (Huesca)",
  // },
  // {
  //   municipio: "Jaca",
  //   provincia: "Huesca",
  //   id: "22130",
  //   label: "Jaca (Huesca)",
  // },
  // {
  //   municipio: "Jasa",
  //   provincia: "Huesca",
  //   id: "22131",
  //   label: "Jasa (Huesca)",
  // },
  // {
  //   municipio: "Laluenga",
  //   provincia: "Huesca",
  //   id: "22135",
  //   label: "Laluenga (Huesca)",
  // },
  // {
  //   municipio: "Laperdiguera",
  //   provincia: "Huesca",
  //   id: "22139",
  //   label: "Laperdiguera (Huesca)",
  // },
  // {
  //   municipio: "Osso de Cinca",
  //   provincia: "Huesca",
  //   id: "22167",
  //   label: "Osso de Cinca (Huesca)",
  // },
  // {
  //   municipio: "Lalueza",
  //   provincia: "Huesca",
  //   id: "22136",
  //   label: "Lalueza (Huesca)",
  // },
  // {
  //   municipio: "Santurde de Rioja",
  //   provincia: "La Rioja",
  //   id: "26140",
  //   label: "Santurde de Rioja (La Rioja)",
  // },
  // {
  //   municipio: "Lanaja",
  //   provincia: "Huesca",
  //   id: "22137",
  //   label: "Lanaja (Huesca)",
  // },
  // {
  //   municipio: "Lascellas-Ponzano",
  //   provincia: "Huesca",
  //   id: "22141",
  //   label: "Lascellas-Ponzano (Huesca)",
  // },
  // {
  //   municipio: "Lascuarre",
  //   provincia: "Huesca",
  //   id: "22142",
  //   label: "Lascuarre (Huesca)",
  // },
  // {
  //   municipio: "Melque de Cercos",
  //   provincia: "Segovia",
  //   id: "40126",
  //   label: "Melque de Cercos (Segovia)",
  // },
  // {
  //   municipio: "Laspaúles",
  //   provincia: "Huesca",
  //   id: "22143",
  //   label: "Laspaúles (Huesca)",
  // },
  // {
  //   municipio: "Monflorite-Lascasas",
  //   provincia: "Huesca",
  //   id: "22156",
  //   label: "Monflorite-Lascasas (Huesca)",
  // },
  // {
  //   municipio: "Laspuña",
  //   provincia: "Huesca",
  //   id: "22144",
  //   label: "Laspuña (Huesca)",
  // },
  // {
  //   municipio: "Loarre",
  //   provincia: "Huesca",
  //   id: "22149",
  //   label: "Loarre (Huesca)",
  // },
  // {
  //   municipio: "Loporzano",
  //   provincia: "Huesca",
  //   id: "22150",
  //   label: "Loporzano (Huesca)",
  // },
  // {
  //   municipio: "Montanuy",
  //   provincia: "Huesca",
  //   id: "22157",
  //   label: "Montanuy (Huesca)",
  // },
  // {
  //   municipio: "Loscorrales",
  //   provincia: "Huesca",
  //   id: "22151",
  //   label: "Loscorrales (Huesca)",
  // },
  // {
  //   municipio: "Monesma y Cajigar",
  //   provincia: "Huesca",
  //   id: "22155",
  //   label: "Monesma y Cajigar (Huesca)",
  // },
  // {
  //   municipio: "Monzón",
  //   provincia: "Huesca",
  //   id: "22158",
  //   label: "Monzón (Huesca)",
  // },
  // {
  //   municipio: "Naval",
  //   provincia: "Huesca",
  //   id: "22160",
  //   label: "Naval (Huesca)",
  // },
  // {
  //   municipio: "Novales",
  //   provincia: "Huesca",
  //   id: "22162",
  //   label: "Novales (Huesca)",
  // },
  // {
  //   municipio: "Nueno",
  //   provincia: "Huesca",
  //   id: "22163",
  //   label: "Nueno (Huesca)",
  // },
  // {
  //   municipio: "Olvena",
  //   provincia: "Huesca",
  //   id: "22164",
  //   label: "Olvena (Huesca)",
  // },
  // {
  //   municipio: "Membibre de la Hoz",
  //   provincia: "Segovia",
  //   id: "40127",
  //   label: "Membibre de la Hoz (Segovia)",
  // },
  // {
  //   municipio: "Ontiñena",
  //   provincia: "Huesca",
  //   id: "22165",
  //   label: "Ontiñena (Huesca)",
  // },
  // {
  //   municipio: "Peñalba",
  //   provincia: "Huesca",
  //   id: "22172",
  //   label: "Peñalba (Huesca)",
  // },
  // {
  //   municipio: "Peñas de Riglos, Las",
  //   provincia: "Huesca",
  //   id: "22173",
  //   label: "Peñas de Riglos, Las (Huesca)",
  // },
  // {
  //   municipio: "Quicena",
  //   provincia: "Huesca",
  //   id: "22195",
  //   label: "Quicena (Huesca)",
  // },
  // {
  //   municipio: "Peralta de Alcofea",
  //   provincia: "Huesca",
  //   id: "22174",
  //   label: "Peralta de Alcofea (Huesca)",
  // },
  // {
  //   municipio: "Peralta de Calasanz",
  //   provincia: "Huesca",
  //   id: "22175",
  //   label: "Peralta de Calasanz (Huesca)",
  // },
  // {
  //   municipio: "Perarrúa",
  //   provincia: "Huesca",
  //   id: "22177",
  //   label: "Perarrúa (Huesca)",
  // },
  // {
  //   municipio: "Pertusa",
  //   provincia: "Huesca",
  //   id: "22178",
  //   label: "Pertusa (Huesca)",
  // },
  // {
  //   municipio: "Piracés",
  //   provincia: "Huesca",
  //   id: "22181",
  //   label: "Piracés (Huesca)",
  // },
  // {
  //   municipio: "Plan",
  //   provincia: "Huesca",
  //   id: "22182",
  //   label: "Plan (Huesca)",
  // },
  // {
  //   municipio: "Poleñino",
  //   provincia: "Huesca",
  //   id: "22184",
  //   label: "Poleñino (Huesca)",
  // },
  // {
  //   municipio: "Pozán de Vero",
  //   provincia: "Huesca",
  //   id: "22186",
  //   label: "Pozán de Vero (Huesca)",
  // },
  // {
  //   municipio: "Puebla de Castro, La",
  //   provincia: "Huesca",
  //   id: "22187",
  //   label: "Puebla de Castro, La (Huesca)",
  // },
  // {
  //   municipio: "Puente de Montañana",
  //   provincia: "Huesca",
  //   id: "22188",
  //   label: "Puente de Montañana (Huesca)",
  // },
  // {
  //   municipio: "Santurdejo",
  //   provincia: "La Rioja",
  //   id: "26141",
  //   label: "Santurdejo (La Rioja)",
  // },
  // {
  //   municipio: "Puértolas",
  //   provincia: "Huesca",
  //   id: "22189",
  //   label: "Puértolas (Huesca)",
  // },
  // {
  //   municipio: "Pueyo de Araguás, El",
  //   provincia: "Huesca",
  //   id: "22190",
  //   label: "Pueyo de Araguás, El (Huesca)",
  // },
  // {
  //   municipio: "Sena",
  //   provincia: "Huesca",
  //   id: "22217",
  //   label: "Sena (Huesca)",
  // },
  // {
  //   municipio: "Robres",
  //   provincia: "Huesca",
  //   id: "22197",
  //   label: "Robres (Huesca)",
  // },
  // {
  //   municipio: "Salas Bajas",
  //   provincia: "Huesca",
  //   id: "22202",
  //   label: "Salas Bajas (Huesca)",
  // },
  // {
  //   municipio: "Sahún",
  //   provincia: "Huesca",
  //   id: "22200",
  //   label: "Sahún (Huesca)",
  // },
  // {
  //   municipio: "Salas Altas",
  //   provincia: "Huesca",
  //   id: "22201",
  //   label: "Salas Altas (Huesca)",
  // },
  // {
  //   municipio: "Salillas",
  //   provincia: "Huesca",
  //   id: "22203",
  //   label: "Salillas (Huesca)",
  // },
  // {
  //   municipio: "Sallent de Gállego",
  //   provincia: "Huesca",
  //   id: "22204",
  //   label: "Sallent de Gállego (Huesca)",
  // },
  // {
  //   municipio: "Sangarrén",
  //   provincia: "Huesca",
  //   id: "22206",
  //   label: "Sangarrén (Huesca)",
  // },
  // {
  //   municipio: "Tirgo",
  //   provincia: "La Rioja",
  //   id: "26148",
  //   label: "Tirgo (La Rioja)",
  // },
  // {
  //   municipio: "San Esteban de Litera",
  //   provincia: "Huesca",
  //   id: "22205",
  //   label: "San Esteban de Litera (Huesca)",
  // },
  // {
  //   municipio: "Villacid de Campos",
  //   provincia: "Valladolid",
  //   id: "47199",
  //   label: "Villacid de Campos (Valladolid)",
  // },
  // {
  //   municipio: "San Juan de Plan",
  //   provincia: "Huesca",
  //   id: "22207",
  //   label: "San Juan de Plan (Huesca)",
  // },
  // {
  //   municipio: "Santaliestra y San Quílez",
  //   provincia: "Huesca",
  //   id: "22212",
  //   label: "Santaliestra y San Quílez (Huesca)",
  // },
  // {
  //   municipio: "Sariñena",
  //   provincia: "Huesca",
  //   id: "22213",
  //   label: "Sariñena (Huesca)",
  // },
  // {
  //   municipio: "Santa Cilia",
  //   provincia: "Huesca",
  //   id: "22208",
  //   label: "Santa Cilia (Huesca)",
  // },
  // {
  //   municipio: "Santa Cruz de la Serós",
  //   provincia: "Huesca",
  //   id: "22209",
  //   label: "Santa Cruz de la Serós (Huesca)",
  // },
  // {
  //   municipio: "Secastilla",
  //   provincia: "Huesca",
  //   id: "22214",
  //   label: "Secastilla (Huesca)",
  // },
  // {
  //   municipio: "Senés de Alcubierre",
  //   provincia: "Huesca",
  //   id: "22218",
  //   label: "Senés de Alcubierre (Huesca)",
  // },
  // {
  //   municipio: "Sesa",
  //   provincia: "Huesca",
  //   id: "22220",
  //   label: "Sesa (Huesca)",
  // },
  // {
  //   municipio: "Seira",
  //   provincia: "Huesca",
  //   id: "22215",
  //   label: "Seira (Huesca)",
  // },
  // {
  //   municipio: "Siétamo",
  //   provincia: "Huesca",
  //   id: "22222",
  //   label: "Siétamo (Huesca)",
  // },
  // {
  //   municipio: "Sopeira",
  //   provincia: "Huesca",
  //   id: "22223",
  //   label: "Sopeira (Huesca)",
  // },
  // {
  //   municipio: "Tamarite de Litera",
  //   provincia: "Huesca",
  //   id: "22225",
  //   label: "Tamarite de Litera (Huesca)",
  // },
  // {
  //   municipio: "Tardienta",
  //   provincia: "Huesca",
  //   id: "22226",
  //   label: "Tardienta (Huesca)",
  // },
  // {
  //   municipio: "Tella-Sin",
  //   provincia: "Huesca",
  //   id: "22227",
  //   label: "Tella-Sin (Huesca)",
  // },
  // {
  //   municipio: "Tierz",
  //   provincia: "Huesca",
  //   id: "22228",
  //   label: "Tierz (Huesca)",
  // },
  // {
  //   municipio: "Torrente de Cinca",
  //   provincia: "Huesca",
  //   id: "22234",
  //   label: "Torrente de Cinca (Huesca)",
  // },
  // {
  //   municipio: "Tolva",
  //   provincia: "Huesca",
  //   id: "22229",
  //   label: "Tolva (Huesca)",
  // },
  // {
  //   municipio: "Torla-Ordesa",
  //   provincia: "Huesca",
  //   id: "22230",
  //   label: "Torla-Ordesa (Huesca)",
  // },
  // {
  //   municipio: "Torralba de Aragón",
  //   provincia: "Huesca",
  //   id: "22232",
  //   label: "Torralba de Aragón (Huesca)",
  // },
  // {
  //   municipio: "Torres de Alcanadre",
  //   provincia: "Huesca",
  //   id: "22235",
  //   label: "Torres de Alcanadre (Huesca)",
  // },
  // {
  //   municipio: "Torres de Barbués",
  //   provincia: "Huesca",
  //   id: "22236",
  //   label: "Torres de Barbués (Huesca)",
  // },
  // {
  //   municipio: "Tramaced",
  //   provincia: "Huesca",
  //   id: "22239",
  //   label: "Tramaced (Huesca)",
  // },
  // {
  //   municipio: "Valle de Bardají",
  //   provincia: "Huesca",
  //   id: "22243",
  //   label: "Valle de Bardají (Huesca)",
  // },
  // {
  //   municipio: "Valle de Lierp",
  //   provincia: "Huesca",
  //   id: "22244",
  //   label: "Valle de Lierp (Huesca)",
  // },
  // {
  //   municipio: "Vicién",
  //   provincia: "Huesca",
  //   id: "22248",
  //   label: "Vicién (Huesca)",
  // },
  // {
  //   municipio: "Fuentes de Carbajal",
  //   provincia: "León",
  //   id: "24074",
  //   label: "Fuentes de Carbajal (León)",
  // },
  // {
  //   municipio: "Velilla de Cinca",
  //   provincia: "Huesca",
  //   id: "22245",
  //   label: "Velilla de Cinca (Huesca)",
  // },
  // {
  //   municipio: "Beranuy",
  //   provincia: "Huesca",
  //   id: "22246",
  //   label: "Beranuy (Huesca)",
  // },
  // {
  //   municipio: "Viacamp y Litera",
  //   provincia: "Huesca",
  //   id: "22247",
  //   label: "Viacamp y Litera (Huesca)",
  // },
  // {
  //   municipio: "Villanúa",
  //   provincia: "Huesca",
  //   id: "22250",
  //   label: "Villanúa (Huesca)",
  // },
  // {
  //   municipio: "Villanueva de Sigena",
  //   provincia: "Huesca",
  //   id: "22251",
  //   label: "Villanueva de Sigena (Huesca)",
  // },
  // {
  //   municipio: "Santa María de Dulcis",
  //   provincia: "Huesca",
  //   id: "22906",
  //   label: "Santa María de Dulcis (Huesca)",
  // },
  // {
  //   municipio: "Aínsa-Sobrarbe",
  //   provincia: "Huesca",
  //   id: "22907",
  //   label: "Aínsa-Sobrarbe (Huesca)",
  // },
  // {
  //   municipio: "Yebra de Basa",
  //   provincia: "Huesca",
  //   id: "22252",
  //   label: "Yebra de Basa (Huesca)",
  // },
  // {
  //   municipio: "Cambil",
  //   provincia: "Jaén",
  //   id: "23018",
  //   label: "Cambil (Jaén)",
  // },
  // {
  //   municipio: "Campillo de Arenas",
  //   provincia: "Jaén",
  //   id: "23019",
  //   label: "Campillo de Arenas (Jaén)",
  // },
  // {
  //   municipio: "Orejana",
  //   provincia: "Segovia",
  //   id: "40150",
  //   label: "Orejana (Segovia)",
  // },
  // {
  //   municipio: "Yésero",
  //   provincia: "Huesca",
  //   id: "22253",
  //   label: "Yésero (Huesca)",
  // },
  // {
  //   municipio: "Zaidín",
  //   provincia: "Huesca",
  //   id: "22254",
  //   label: "Zaidín (Huesca)",
  // },
  // {
  //   municipio: "Valle de Hecho",
  //   provincia: "Huesca",
  //   id: "22901",
  //   label: "Valle de Hecho (Huesca)",
  // },
  // {
  //   municipio: "Puente la Reina de Jaca",
  //   provincia: "Huesca",
  //   id: "22902",
  //   label: "Puente la Reina de Jaca (Huesca)",
  // },
  // {
  //   municipio: "San Miguel del Cinca",
  //   provincia: "Huesca",
  //   id: "22903",
  //   label: "San Miguel del Cinca (Huesca)",
  // },
  // {
  //   municipio: "Sotonera, La",
  //   provincia: "Huesca",
  //   id: "22904",
  //   label: "Sotonera, La (Huesca)",
  // },
  // {
  //   municipio: "Lupiñén-Ortilla",
  //   provincia: "Huesca",
  //   id: "22905",
  //   label: "Lupiñén-Ortilla (Huesca)",
  // },
  // {
  //   municipio: "Hoz y Costean",
  //   provincia: "Huesca",
  //   id: "22908",
  //   label: "Hoz y Costean (Huesca)",
  // },
  // {
  //   municipio: "Vencillón",
  //   provincia: "Huesca",
  //   id: "22909",
  //   label: "Vencillón (Huesca)",
  // },
  // {
  //   municipio: "Albanchez de Mágina",
  //   provincia: "Jaén",
  //   id: "23001",
  //   label: "Albanchez de Mágina (Jaén)",
  // },
  // {
  //   municipio: "Alcalá la Real",
  //   provincia: "Jaén",
  //   id: "23002",
  //   label: "Alcalá la Real (Jaén)",
  // },
  // {
  //   municipio: "Alija del Infantado",
  //   provincia: "León",
  //   id: "24003",
  //   label: "Alija del Infantado (León)",
  // },
  // {
  //   municipio: "Aldeaquemada",
  //   provincia: "Jaén",
  //   id: "23004",
  //   label: "Aldeaquemada (Jaén)",
  // },
  // {
  //   municipio: "Arjona",
  //   provincia: "Jaén",
  //   id: "23006",
  //   label: "Arjona (Jaén)",
  // },
  // {
  //   municipio: "Arjonilla",
  //   provincia: "Jaén",
  //   id: "23007",
  //   label: "Arjonilla (Jaén)",
  // },
  // {
  //   municipio: "Arquillos",
  //   provincia: "Jaén",
  //   id: "23008",
  //   label: "Arquillos (Jaén)",
  // },
  // {
  //   municipio: "Baeza",
  //   provincia: "Jaén",
  //   id: "23009",
  //   label: "Baeza (Jaén)",
  // },
  // {
  //   municipio: "Bailén",
  //   provincia: "Jaén",
  //   id: "23010",
  //   label: "Bailén (Jaén)",
  // },
  // {
  //   municipio: "Baños de la Encina",
  //   provincia: "Jaén",
  //   id: "23011",
  //   label: "Baños de la Encina (Jaén)",
  // },
  // {
  //   municipio: "Beas de Segura",
  //   provincia: "Jaén",
  //   id: "23012",
  //   label: "Beas de Segura (Jaén)",
  // },
  // {
  //   municipio: "Torremontalbo",
  //   provincia: "La Rioja",
  //   id: "26154",
  //   label: "Torremontalbo (La Rioja)",
  // },
  // {
  //   municipio: "Begíjar",
  //   provincia: "Jaén",
  //   id: "23014",
  //   label: "Begíjar (Jaén)",
  // },
  // {
  //   municipio: "Bañeza, La",
  //   provincia: "León",
  //   id: "24010",
  //   label: "Bañeza, La (León)",
  // },
  // {
  //   municipio: "Bélmez de la Moraleda",
  //   provincia: "Jaén",
  //   id: "23015",
  //   label: "Bélmez de la Moraleda (Jaén)",
  // },
  // {
  //   municipio: "Carboneros",
  //   provincia: "Jaén",
  //   id: "23021",
  //   label: "Carboneros (Jaén)",
  // },
  // {
  //   municipio: "Benatae",
  //   provincia: "Jaén",
  //   id: "23016",
  //   label: "Benatae (Jaén)",
  // },
  // {
  //   municipio: "Carolina, La",
  //   provincia: "Jaén",
  //   id: "23024",
  //   label: "Carolina, La (Jaén)",
  // },
  // {
  //   municipio: "Castellar",
  //   provincia: "Jaén",
  //   id: "23025",
  //   label: "Castellar (Jaén)",
  // },
  // {
  //   municipio: "Cabra del Santo Cristo",
  //   provincia: "Jaén",
  //   id: "23017",
  //   label: "Cabra del Santo Cristo (Jaén)",
  // },
  // {
  //   municipio: "Canena",
  //   provincia: "Jaén",
  //   id: "23020",
  //   label: "Canena (Jaén)",
  // },
  // {
  //   municipio: "Castillo de Locubín",
  //   provincia: "Jaén",
  //   id: "23026",
  //   label: "Castillo de Locubín (Jaén)",
  // },
  // {
  //   municipio: "Cazalilla",
  //   provincia: "Jaén",
  //   id: "23027",
  //   label: "Cazalilla (Jaén)",
  // },
  // {
  //   municipio: "Chiclana de Segura",
  //   provincia: "Jaén",
  //   id: "23029",
  //   label: "Chiclana de Segura (Jaén)",
  // },
  // {
  //   municipio: "Escañuela",
  //   provincia: "Jaén",
  //   id: "23031",
  //   label: "Escañuela (Jaén)",
  // },
  // {
  //   municipio: "Chilluévar",
  //   provincia: "Jaén",
  //   id: "23030",
  //   label: "Chilluévar (Jaén)",
  // },
  // {
  //   municipio: "Iruela, La",
  //   provincia: "Jaén",
  //   id: "23047",
  //   label: "Iruela, La (Jaén)",
  // },
  // {
  //   municipio: "Iznatoraf",
  //   provincia: "Jaén",
  //   id: "23048",
  //   label: "Iznatoraf (Jaén)",
  // },
  // {
  //   municipio: "Espeluy",
  //   provincia: "Jaén",
  //   id: "23032",
  //   label: "Espeluy (Jaén)",
  // },
  // {
  //   municipio: "Larva",
  //   provincia: "Jaén",
  //   id: "23054",
  //   label: "Larva (Jaén)",
  // },
  // {
  //   municipio: "Linares",
  //   provincia: "Jaén",
  //   id: "23055",
  //   label: "Linares (Jaén)",
  // },
  // {
  //   municipio: "Frailes",
  //   provincia: "Jaén",
  //   id: "23033",
  //   label: "Frailes (Jaén)",
  // },
  // {
  //   municipio: "Bercianos del Real Camino",
  //   provincia: "León",
  //   id: "24018",
  //   label: "Bercianos del Real Camino (León)",
  // },
  // {
  //   municipio: "Fuensanta de Martos",
  //   provincia: "Jaén",
  //   id: "23034",
  //   label: "Fuensanta de Martos (Jaén)",
  // },
  // {
  //   municipio: "Berlanga del Bierzo",
  //   provincia: "León",
  //   id: "24019",
  //   label: "Berlanga del Bierzo (León)",
  // },
  // {
  //   municipio: "Fuerte del Rey",
  //   provincia: "Jaén",
  //   id: "23035",
  //   label: "Fuerte del Rey (Jaén)",
  // },
  // {
  //   municipio: "Lupión",
  //   provincia: "Jaén",
  //   id: "23057",
  //   label: "Lupión (Jaén)",
  // },
  // {
  //   municipio: "Génave",
  //   provincia: "Jaén",
  //   id: "23037",
  //   label: "Génave (Jaén)",
  // },
  // {
  //   municipio: "Guardia de Jaén, La",
  //   provincia: "Jaén",
  //   id: "23038",
  //   label: "Guardia de Jaén, La (Jaén)",
  // },
  // {
  //   municipio: "Mancha Real",
  //   provincia: "Jaén",
  //   id: "23058",
  //   label: "Mancha Real (Jaén)",
  // },
  // {
  //   municipio: "Martos",
  //   provincia: "Jaén",
  //   id: "23060",
  //   label: "Martos (Jaén)",
  // },
  // {
  //   municipio: "Guarromán",
  //   provincia: "Jaén",
  //   id: "23039",
  //   label: "Guarromán (Jaén)",
  // },
  // {
  //   municipio: "Lahiguera",
  //   provincia: "Jaén",
  //   id: "23040",
  //   label: "Lahiguera (Jaén)",
  // },
  // {
  //   municipio: "Brazuelo",
  //   provincia: "León",
  //   id: "24023",
  //   label: "Brazuelo (León)",
  // },
  // {
  //   municipio: "Higuera de Calatrava",
  //   provincia: "Jaén",
  //   id: "23041",
  //   label: "Higuera de Calatrava (Jaén)",
  // },
  // {
  //   municipio: "Astorga",
  //   provincia: "León",
  //   id: "24008",
  //   label: "Astorga (León)",
  // },
  // {
  //   municipio: "Hinojares",
  //   provincia: "Jaén",
  //   id: "23042",
  //   label: "Hinojares (Jaén)",
  // },
  // {
  //   municipio: "Hornos",
  //   provincia: "Jaén",
  //   id: "23043",
  //   label: "Hornos (Jaén)",
  // },
  // {
  //   municipio: "Balboa",
  //   provincia: "León",
  //   id: "24009",
  //   label: "Balboa (León)",
  // },
  // {
  //   municipio: "Huelma",
  //   provincia: "Jaén",
  //   id: "23044",
  //   label: "Huelma (Jaén)",
  // },
  // {
  //   municipio: "Ibros",
  //   provincia: "Jaén",
  //   id: "23046",
  //   label: "Ibros (Jaén)",
  // },
  // {
  //   municipio: "Jabalquinto",
  //   provincia: "Jaén",
  //   id: "23049",
  //   label: "Jabalquinto (Jaén)",
  // },
  // {
  //   municipio: "Jaén",
  //   provincia: "Jaén",
  //   id: "23050",
  //   label: "Jaén (Jaén)",
  // },
  // {
  //   municipio: "Jamilena",
  //   provincia: "Jaén",
  //   id: "23051",
  //   label: "Jamilena (Jaén)",
  // },
  // {
  //   municipio: "Jimena",
  //   provincia: "Jaén",
  //   id: "23052",
  //   label: "Jimena (Jaén)",
  // },
  // {
  //   municipio: "Carrocera",
  //   provincia: "León",
  //   id: "24040",
  //   label: "Carrocera (León)",
  // },
  // {
  //   municipio: "Jódar",
  //   provincia: "Jaén",
  //   id: "23053",
  //   label: "Jódar (Jaén)",
  // },
  // {
  //   municipio: "Lopera",
  //   provincia: "Jaén",
  //   id: "23056",
  //   label: "Lopera (Jaén)",
  // },
  // {
  //   municipio: "Cacabelos",
  //   provincia: "León",
  //   id: "24030",
  //   label: "Cacabelos (León)",
  // },
  // {
  //   municipio: "Mengíbar",
  //   provincia: "Jaén",
  //   id: "23061",
  //   label: "Mengíbar (Jaén)",
  // },
  // {
  //   municipio: "Montizón",
  //   provincia: "Jaén",
  //   id: "23062",
  //   label: "Montizón (Jaén)",
  // },
  // {
  //   municipio: "Noalejo",
  //   provincia: "Jaén",
  //   id: "23064",
  //   label: "Noalejo (Jaén)",
  // },
  // {
  //   municipio: "Orcera",
  //   provincia: "Jaén",
  //   id: "23065",
  //   label: "Orcera (Jaén)",
  // },
  // {
  //   municipio: "Peal de Becerro",
  //   provincia: "Jaén",
  //   id: "23066",
  //   label: "Peal de Becerro (Jaén)",
  // },
  // {
  //   municipio: "Castrillo de la Valduerna",
  //   provincia: "León",
  //   id: "24044",
  //   label: "Castrillo de la Valduerna (León)",
  // },
  // {
  //   municipio: "Pegalajar",
  //   provincia: "Jaén",
  //   id: "23067",
  //   label: "Pegalajar (Jaén)",
  // },
  // {
  //   municipio: "Puente de Génave",
  //   provincia: "Jaén",
  //   id: "23071",
  //   label: "Puente de Génave (Jaén)",
  // },
  // {
  //   municipio: "Porcuna",
  //   provincia: "Jaén",
  //   id: "23069",
  //   label: "Porcuna (Jaén)",
  // },
  // {
  //   municipio: "Pozo Alcón",
  //   provincia: "Jaén",
  //   id: "23070",
  //   label: "Pozo Alcón (Jaén)",
  // },
  // {
  //   municipio: "Santo Tomé",
  //   provincia: "Jaén",
  //   id: "23080",
  //   label: "Santo Tomé (Jaén)",
  // },
  // {
  //   municipio: "Puerta de Segura, La",
  //   provincia: "Jaén",
  //   id: "23072",
  //   label: "Puerta de Segura, La (Jaén)",
  // },
  // {
  //   municipio: "Rus",
  //   provincia: "Jaén",
  //   id: "23074",
  //   label: "Rus (Jaén)",
  // },
  // {
  //   municipio: "Sabiote",
  //   provincia: "Jaén",
  //   id: "23075",
  //   label: "Sabiote (Jaén)",
  // },
  // {
  //   municipio: "Siles",
  //   provincia: "Jaén",
  //   id: "23082",
  //   label: "Siles (Jaén)",
  // },
  // {
  //   municipio: "Sorihuela del Guadalimar",
  //   provincia: "Jaén",
  //   id: "23084",
  //   label: "Sorihuela del Guadalimar (Jaén)",
  // },
  // {
  //   municipio: "Santa Elena",
  //   provincia: "Jaén",
  //   id: "23076",
  //   label: "Santa Elena (Jaén)",
  // },
  // {
  //   municipio: "Santiago de Calatrava",
  //   provincia: "Jaén",
  //   id: "23077",
  //   label: "Santiago de Calatrava (Jaén)",
  // },
  // {
  //   municipio: "Santisteban del Puerto",
  //   provincia: "Jaén",
  //   id: "23079",
  //   label: "Santisteban del Puerto (Jaén)",
  // },
  // {
  //   municipio: "Torreblascopedro",
  //   provincia: "Jaén",
  //   id: "23085",
  //   label: "Torreblascopedro (Jaén)",
  // },
  // {
  //   municipio: "Torredelcampo",
  //   provincia: "Jaén",
  //   id: "23086",
  //   label: "Torredelcampo (Jaén)",
  // },
  // {
  //   municipio: "Torredonjimeno",
  //   provincia: "Jaén",
  //   id: "23087",
  //   label: "Torredonjimeno (Jaén)",
  // },
  // {
  //   municipio: "Tricio",
  //   provincia: "La Rioja",
  //   id: "26157",
  //   label: "Tricio (La Rioja)",
  // },
  // {
  //   municipio: "Torreperogil",
  //   provincia: "Jaén",
  //   id: "23088",
  //   label: "Torreperogil (Jaén)",
  // },
  // {
  //   municipio: "Ortigosa de Pestaño",
  //   provincia: "Segovia",
  //   id: "40151",
  //   label: "Ortigosa de Pestaño (Segovia)",
  // },
  // {
  //   municipio: "Torres",
  //   provincia: "Jaén",
  //   id: "23090",
  //   label: "Torres (Jaén)",
  // },
  // {
  //   municipio: "Villares, Los",
  //   provincia: "Jaén",
  //   id: "23099",
  //   label: "Villares, Los (Jaén)",
  // },
  // {
  //   municipio: "Torres de Albánchez",
  //   provincia: "Jaén",
  //   id: "23091",
  //   label: "Torres de Albánchez (Jaén)",
  // },
  // {
  //   municipio: "Valdepeñas de Jaén",
  //   provincia: "Jaén",
  //   id: "23093",
  //   label: "Valdepeñas de Jaén (Jaén)",
  // },
  // {
  //   municipio: "Villacarrillo",
  //   provincia: "Jaén",
  //   id: "23095",
  //   label: "Villacarrillo (Jaén)",
  // },
  // {
  //   municipio: "Villanueva de la Reina",
  //   provincia: "Jaén",
  //   id: "23096",
  //   label: "Villanueva de la Reina (Jaén)",
  // },
  // {
  //   municipio: "Villardompardo",
  //   provincia: "Jaén",
  //   id: "23098",
  //   label: "Villardompardo (Jaén)",
  // },
  // {
  //   municipio: "Villarrodrigo",
  //   provincia: "Jaén",
  //   id: "23101",
  //   label: "Villarrodrigo (Jaén)",
  // },
  // {
  //   municipio: "Cárcheles",
  //   provincia: "Jaén",
  //   id: "23901",
  //   label: "Cárcheles (Jaén)",
  // },
  // {
  //   municipio: "Bedmar y Garcíez",
  //   provincia: "Jaén",
  //   id: "23902",
  //   label: "Bedmar y Garcíez (Jaén)",
  // },
  // {
  //   municipio: "Villanueva del Rebollar",
  //   provincia: "Palencia",
  //   id: "34227",
  //   label: "Villanueva del Rebollar (Palencia)",
  // },
  // {
  //   municipio: "Villatorres",
  //   provincia: "Jaén",
  //   id: "23903",
  //   label: "Villatorres (Jaén)",
  // },
  // {
  //   municipio: "Arroyo del Ojanco",
  //   provincia: "Jaén",
  //   id: "23905",
  //   label: "Arroyo del Ojanco (Jaén)",
  // },
  // {
  //   municipio: "Acebedo",
  //   provincia: "León",
  //   id: "24001",
  //   label: "Acebedo (León)",
  // },
  // {
  //   municipio: "Algadefe",
  //   provincia: "León",
  //   id: "24002",
  //   label: "Algadefe (León)",
  // },
  // {
  //   municipio: "Almanza",
  //   provincia: "León",
  //   id: "24004",
  //   label: "Almanza (León)",
  // },
  // {
  //   municipio: "Antigua, La",
  //   provincia: "León",
  //   id: "24005",
  //   label: "Antigua, La (León)",
  // },
  // {
  //   municipio: "Ardón",
  //   provincia: "León",
  //   id: "24006",
  //   label: "Ardón (León)",
  // },
  // {
  //   municipio: "Borrenes",
  //   provincia: "León",
  //   id: "24022",
  //   label: "Borrenes (León)",
  // },
  // {
  //   municipio: "Burgo Ranero, El",
  //   provincia: "León",
  //   id: "24024",
  //   label: "Burgo Ranero, El (León)",
  // },
  // {
  //   municipio: "Burón",
  //   provincia: "León",
  //   id: "24025",
  //   label: "Burón (León)",
  // },
  // {
  //   municipio: "Arganza",
  //   provincia: "León",
  //   id: "24007",
  //   label: "Arganza (León)",
  // },
  // {
  //   municipio: "Santa Cecilia del Alcor",
  //   provincia: "Palencia",
  //   id: "34167",
  //   label: "Santa Cecilia del Alcor (Palencia)",
  // },
  // {
  //   municipio: "Barjas",
  //   provincia: "León",
  //   id: "24011",
  //   label: "Barjas (León)",
  // },
  // {
  //   municipio: "Pajarejos",
  //   provincia: "Segovia",
  //   id: "40154",
  //   label: "Pajarejos (Segovia)",
  // },
  // {
  //   municipio: "Barrios de Luna, Los",
  //   provincia: "León",
  //   id: "24012",
  //   label: "Barrios de Luna, Los (León)",
  // },
  // {
  //   municipio: "Bembibre",
  //   provincia: "León",
  //   id: "24014",
  //   label: "Bembibre (León)",
  // },
  // {
  //   municipio: "Benavides",
  //   provincia: "León",
  //   id: "24015",
  //   label: "Benavides (León)",
  // },
  // {
  //   municipio: "Cabreros del Río",
  //   provincia: "León",
  //   id: "24028",
  //   label: "Cabreros del Río (León)",
  // },
  // {
  //   municipio: "Cabrillanes",
  //   provincia: "León",
  //   id: "24029",
  //   label: "Cabrillanes (León)",
  // },
  // {
  //   municipio: "Benuza",
  //   provincia: "León",
  //   id: "24016",
  //   label: "Benuza (León)",
  // },
  // {
  //   municipio: "Bercianos del Páramo",
  //   provincia: "León",
  //   id: "24017",
  //   label: "Bercianos del Páramo (León)",
  // },
  // {
  //   municipio: "Boñar",
  //   provincia: "León",
  //   id: "24021",
  //   label: "Boñar (León)",
  // },
  // {
  //   municipio: "Bustillo del Páramo",
  //   provincia: "León",
  //   id: "24026",
  //   label: "Bustillo del Páramo (León)",
  // },
  // {
  //   municipio: "Cabañas Raras",
  //   provincia: "León",
  //   id: "24027",
  //   label: "Cabañas Raras (León)",
  // },
  // {
  //   municipio: "Calzada del Coto",
  //   provincia: "León",
  //   id: "24031",
  //   label: "Calzada del Coto (León)",
  // },
  // {
  //   municipio: "Campazas",
  //   provincia: "León",
  //   id: "24032",
  //   label: "Campazas (León)",
  // },
  // {
  //   municipio: "Campo de Villavidel",
  //   provincia: "León",
  //   id: "24033",
  //   label: "Campo de Villavidel (León)",
  // },
  // {
  //   municipio: "Camponaraya",
  //   provincia: "León",
  //   id: "24034",
  //   label: "Camponaraya (León)",
  // },
  // {
  //   municipio: "Candín",
  //   provincia: "León",
  //   id: "24036",
  //   label: "Candín (León)",
  // },
  // {
  //   municipio: "Cármenes",
  //   provincia: "León",
  //   id: "24037",
  //   label: "Cármenes (León)",
  // },
  // {
  //   municipio: "Carracedelo",
  //   provincia: "León",
  //   id: "24038",
  //   label: "Carracedelo (León)",
  // },
  // {
  //   municipio: "Carrizo",
  //   provincia: "León",
  //   id: "24039",
  //   label: "Carrizo (León)",
  // },
  // {
  //   municipio: "Castillejo de Mesleón",
  //   provincia: "Segovia",
  //   id: "40046",
  //   label: "Castillejo de Mesleón (Segovia)",
  // },
  // {
  //   municipio: "Carucedo",
  //   provincia: "León",
  //   id: "24041",
  //   label: "Carucedo (León)",
  // },
  // {
  //   municipio: "Castilfalé",
  //   provincia: "León",
  //   id: "24042",
  //   label: "Castilfalé (León)",
  // },
  // {
  //   municipio: "Escobosa de Almazán",
  //   provincia: "Soria",
  //   id: "42079",
  //   label: "Escobosa de Almazán (Soria)",
  // },
  // {
  //   municipio: "Castrillo de Cabrera",
  //   provincia: "León",
  //   id: "24043",
  //   label: "Castrillo de Cabrera (León)",
  // },
  // {
  //   municipio: "Narros",
  //   provincia: "Soria",
  //   id: "42128",
  //   label: "Narros (Soria)",
  // },
  // {
  //   municipio: "Castrocalbón",
  //   provincia: "León",
  //   id: "24046",
  //   label: "Castrocalbón (León)",
  // },
  // {
  //   municipio: "Castrocontrigo",
  //   provincia: "León",
  //   id: "24047",
  //   label: "Castrocontrigo (León)",
  // },
  // {
  //   municipio: "Cimanes de la Vega",
  //   provincia: "León",
  //   id: "24054",
  //   label: "Cimanes de la Vega (León)",
  // },
  // {
  //   municipio: "Castropodame",
  //   provincia: "León",
  //   id: "24049",
  //   label: "Castropodame (León)",
  // },
  // {
  //   municipio: "Castrotierra de Valmadrigal",
  //   provincia: "León",
  //   id: "24050",
  //   label: "Castrotierra de Valmadrigal (León)",
  // },
  // {
  //   municipio: "Cea",
  //   provincia: "León",
  //   id: "24051",
  //   label: "Cea (León)",
  // },
  // {
  //   municipio: "Cebanico",
  //   provincia: "León",
  //   id: "24052",
  //   label: "Cebanico (León)",
  // },
  // {
  //   municipio: "Cebrones del Río",
  //   provincia: "León",
  //   id: "24053",
  //   label: "Cebrones del Río (León)",
  // },
  // {
  //   municipio: "Cimanes del Tejar",
  //   provincia: "León",
  //   id: "24055",
  //   label: "Cimanes del Tejar (León)",
  // },
  // {
  //   municipio: "Corbillos de los Oteros",
  //   provincia: "León",
  //   id: "24058",
  //   label: "Corbillos de los Oteros (León)",
  // },
  // {
  //   municipio: "Corullón",
  //   provincia: "León",
  //   id: "24059",
  //   label: "Corullón (León)",
  // },
  // {
  //   municipio: "Crémenes",
  //   provincia: "León",
  //   id: "24060",
  //   label: "Crémenes (León)",
  // },
  // {
  //   municipio: "Cistierna",
  //   provincia: "León",
  //   id: "24056",
  //   label: "Cistierna (León)",
  // },
  // {
  //   municipio: "Congosto",
  //   provincia: "León",
  //   id: "24057",
  //   label: "Congosto (León)",
  // },
  // {
  //   municipio: "Cuadros",
  //   provincia: "León",
  //   id: "24061",
  //   label: "Cuadros (León)",
  // },
  // {
  //   municipio: "Cubillas de los Oteros",
  //   provincia: "León",
  //   id: "24062",
  //   label: "Cubillas de los Oteros (León)",
  // },
  // {
  //   municipio: "Fresno de la Vega",
  //   provincia: "León",
  //   id: "24073",
  //   label: "Fresno de la Vega (León)",
  // },
  // {
  //   municipio: "Cubillas de Rueda",
  //   provincia: "León",
  //   id: "24063",
  //   label: "Cubillas de Rueda (León)",
  // },
  // {
  //   municipio: "Cubillos del Sil",
  //   provincia: "León",
  //   id: "24064",
  //   label: "Cubillos del Sil (León)",
  // },
  // {
  //   municipio: "Chozas de Abajo",
  //   provincia: "León",
  //   id: "24065",
  //   label: "Chozas de Abajo (León)",
  // },
  // {
  //   municipio: "Gordaliza del Pino",
  //   provincia: "León",
  //   id: "24077",
  //   label: "Gordaliza del Pino (León)",
  // },
  // {
  //   municipio: "Destriana",
  //   provincia: "León",
  //   id: "24066",
  //   label: "Destriana (León)",
  // },
  // {
  //   municipio: "Ercina, La",
  //   provincia: "León",
  //   id: "24068",
  //   label: "Ercina, La (León)",
  // },
  // {
  //   municipio: "Encinedo",
  //   provincia: "León",
  //   id: "24067",
  //   label: "Encinedo (León)",
  // },
  // {
  //   municipio: "Fabero",
  //   provincia: "León",
  //   id: "24070",
  //   label: "Fabero (León)",
  // },
  // {
  //   municipio: "Folgoso de la Ribera",
  //   provincia: "León",
  //   id: "24071",
  //   label: "Folgoso de la Ribera (León)",
  // },
  // {
  //   municipio: "Garrafe de Torío",
  //   provincia: "León",
  //   id: "24076",
  //   label: "Garrafe de Torío (León)",
  // },
  // {
  //   municipio: "Gordoncillo",
  //   provincia: "León",
  //   id: "24078",
  //   label: "Gordoncillo (León)",
  // },
  // {
  //   municipio: "Gusendos de los Oteros",
  //   provincia: "León",
  //   id: "24081",
  //   label: "Gusendos de los Oteros (León)",
  // },
  // {
  //   municipio: "León",
  //   provincia: "León",
  //   id: "24089",
  //   label: "León (León)",
  // },
  // {
  //   municipio: "Laguna de Negrillos",
  //   provincia: "León",
  //   id: "24088",
  //   label: "Laguna de Negrillos (León)",
  // },
  // {
  //   municipio: "Lucillo",
  //   provincia: "León",
  //   id: "24090",
  //   label: "Lucillo (León)",
  // },
  // {
  //   municipio: "Luyego",
  //   provincia: "León",
  //   id: "24091",
  //   label: "Luyego (León)",
  // },
  // {
  //   municipio: "Gradefes",
  //   provincia: "León",
  //   id: "24079",
  //   label: "Gradefes (León)",
  // },
  // {
  //   municipio: "Grajal de Campos",
  //   provincia: "León",
  //   id: "24080",
  //   label: "Grajal de Campos (León)",
  // },
  // {
  //   municipio: "Izagre",
  //   provincia: "León",
  //   id: "24084",
  //   label: "Izagre (León)",
  // },
  // {
  //   municipio: "Joarilla de las Matas",
  //   provincia: "León",
  //   id: "24086",
  //   label: "Joarilla de las Matas (León)",
  // },
  // {
  //   municipio: "Igüeña",
  //   provincia: "León",
  //   id: "24083",
  //   label: "Igüeña (León)",
  // },
  // {
  //   municipio: "Villerías de Campos",
  //   provincia: "Palencia",
  //   id: "34240",
  //   label: "Villerías de Campos (Palencia)",
  // },
  // {
  //   municipio: "Laguna Dalga",
  //   provincia: "León",
  //   id: "24087",
  //   label: "Laguna Dalga (León)",
  // },
  // {
  //   municipio: "Villodre",
  //   provincia: "Palencia",
  //   id: "34241",
  //   label: "Villodre (Palencia)",
  // },
  // {
  //   municipio: "Llamas de la Ribera",
  //   provincia: "León",
  //   id: "24092",
  //   label: "Llamas de la Ribera (León)",
  // },
  // {
  //   municipio: "Mansilla de las Mulas",
  //   provincia: "León",
  //   id: "24094",
  //   label: "Mansilla de las Mulas (León)",
  // },
  // {
  //   municipio: "Mansilla Mayor",
  //   provincia: "León",
  //   id: "24095",
  //   label: "Mansilla Mayor (León)",
  // },
  // {
  //   municipio: "Prado de la Guzpeña",
  //   provincia: "León",
  //   id: "24118",
  //   label: "Prado de la Guzpeña (León)",
  // },
  // {
  //   municipio: "Maraña",
  //   provincia: "León",
  //   id: "24096",
  //   label: "Maraña (León)",
  // },
  // {
  //   municipio: "Reyero",
  //   provincia: "León",
  //   id: "24129",
  //   label: "Reyero (León)",
  // },
  // {
  //   municipio: "Noceda del Bierzo",
  //   provincia: "León",
  //   id: "24102",
  //   label: "Noceda del Bierzo (León)",
  // },
  // {
  //   municipio: "Matadeón de los Oteros",
  //   provincia: "León",
  //   id: "24097",
  //   label: "Matadeón de los Oteros (León)",
  // },
  // {
  //   municipio: "Matallana de Torío",
  //   provincia: "León",
  //   id: "24098",
  //   label: "Matallana de Torío (León)",
  // },
  // {
  //   municipio: "Oencia",
  //   provincia: "León",
  //   id: "24103",
  //   label: "Oencia (León)",
  // },
  // {
  //   municipio: "Matanza",
  //   provincia: "León",
  //   id: "24099",
  //   label: "Matanza (León)",
  // },
  // {
  //   municipio: "Molinaseca",
  //   provincia: "León",
  //   id: "24100",
  //   label: "Molinaseca (León)",
  // },
  // {
  //   municipio: "Murias de Paredes",
  //   provincia: "León",
  //   id: "24101",
  //   label: "Murias de Paredes (León)",
  // },
  // {
  //   municipio: "Palacios de la Valduerna",
  //   provincia: "León",
  //   id: "24108",
  //   label: "Palacios de la Valduerna (León)",
  // },
  // {
  //   municipio: "Onzonilla",
  //   provincia: "León",
  //   id: "24105",
  //   label: "Onzonilla (León)",
  // },
  // {
  //   municipio: "Pajares de los Oteros",
  //   provincia: "León",
  //   id: "24107",
  //   label: "Pajares de los Oteros (León)",
  // },
  // {
  //   municipio: "Peranzanes",
  //   provincia: "León",
  //   id: "24112",
  //   label: "Peranzanes (León)",
  // },
  // {
  //   municipio: "Oseja de Sajambre",
  //   provincia: "León",
  //   id: "24106",
  //   label: "Oseja de Sajambre (León)",
  // },
  // {
  //   municipio: "Pozuelo del Páramo",
  //   provincia: "León",
  //   id: "24117",
  //   label: "Pozuelo del Páramo (León)",
  // },
  // {
  //   municipio: "Palacios del Sil",
  //   provincia: "León",
  //   id: "24109",
  //   label: "Palacios del Sil (León)",
  // },
  // {
  //   municipio: "Páramo del Sil",
  //   provincia: "León",
  //   id: "24110",
  //   label: "Páramo del Sil (León)",
  // },
  // {
  //   municipio: "Pola de Gordón, La",
  //   provincia: "León",
  //   id: "24114",
  //   label: "Pola de Gordón, La (León)",
  // },
  // {
  //   municipio: "Priaranza del Bierzo",
  //   provincia: "León",
  //   id: "24119",
  //   label: "Priaranza del Bierzo (León)",
  // },
  // {
  //   municipio: "Ponferrada",
  //   provincia: "León",
  //   id: "24115",
  //   label: "Ponferrada (León)",
  // },
  // {
  //   municipio: "Posada de Valdeón",
  //   provincia: "León",
  //   id: "24116",
  //   label: "Posada de Valdeón (León)",
  // },
  // {
  //   municipio: "Prioro",
  //   provincia: "León",
  //   id: "24120",
  //   label: "Prioro (León)",
  // },
  // {
  //   municipio: "Puente de Domingo Flórez",
  //   provincia: "León",
  //   id: "24122",
  //   label: "Puente de Domingo Flórez (León)",
  // },
  // {
  //   municipio: "Puebla de Lillo",
  //   provincia: "León",
  //   id: "24121",
  //   label: "Puebla de Lillo (León)",
  // },
  // {
  //   municipio: "Villodrigo",
  //   provincia: "Palencia",
  //   id: "34242",
  //   label: "Villodrigo (Palencia)",
  // },
  // {
  //   municipio: "Quintana del Castillo",
  //   provincia: "León",
  //   id: "24123",
  //   label: "Quintana del Castillo (León)",
  // },
  // {
  //   municipio: "Quintana del Marco",
  //   provincia: "León",
  //   id: "24124",
  //   label: "Quintana del Marco (León)",
  // },
  // {
  //   municipio: "Quintana y Congosto",
  //   provincia: "León",
  //   id: "24125",
  //   label: "Quintana y Congosto (León)",
  // },
  // {
  //   municipio: "Regueras de Arriba",
  //   provincia: "León",
  //   id: "24127",
  //   label: "Regueras de Arriba (León)",
  // },
  // {
  //   municipio: "Riaño",
  //   provincia: "León",
  //   id: "24130",
  //   label: "Riaño (León)",
  // },
  // {
  //   municipio: "Riego de la Vega",
  //   provincia: "León",
  //   id: "24131",
  //   label: "Riego de la Vega (León)",
  // },
  // {
  //   municipio: "Rioseco de Tapia",
  //   provincia: "León",
  //   id: "24133",
  //   label: "Rioseco de Tapia (León)",
  // },
  // {
  //   municipio: "Villaobispo de Otero",
  //   provincia: "León",
  //   id: "24219",
  //   label: "Villaobispo de Otero (León)",
  // },
  // {
  //   municipio: "Riello",
  //   provincia: "León",
  //   id: "24132",
  //   label: "Riello (León)",
  // },
  // {
  //   municipio: "Mogarraz",
  //   provincia: "Salamanca",
  //   id: "37194",
  //   label: "Mogarraz (Salamanca)",
  // },
  // {
  //   municipio: "Robla, La",
  //   provincia: "León",
  //   id: "24134",
  //   label: "Robla, La (León)",
  // },
  // {
  //   municipio: "Roperuelos del Páramo",
  //   provincia: "León",
  //   id: "24136",
  //   label: "Roperuelos del Páramo (León)",
  // },
  // {
  //   municipio: "Arenzana de Arriba",
  //   provincia: "La Rioja",
  //   id: "26016",
  //   label: "Arenzana de Arriba (La Rioja)",
  // },
  // {
  //   municipio: "Sabero",
  //   provincia: "León",
  //   id: "24137",
  //   label: "Sabero (León)",
  // },
  // {
  //   municipio: "San Andrés del Rabanedo",
  //   provincia: "León",
  //   id: "24142",
  //   label: "San Andrés del Rabanedo (León)",
  // },
  // {
  //   municipio: "Sancedo",
  //   provincia: "León",
  //   id: "24143",
  //   label: "Sancedo (León)",
  // },
  // {
  //   municipio: "Sahagún",
  //   provincia: "León",
  //   id: "24139",
  //   label: "Sahagún (León)",
  // },
  // {
  //   municipio: "San Adrián del Valle",
  //   provincia: "León",
  //   id: "24141",
  //   label: "San Adrián del Valle (León)",
  // },
  // {
  //   municipio: "San Cristóbal de la Polantera",
  //   provincia: "León",
  //   id: "24144",
  //   label: "San Cristóbal de la Polantera (León)",
  // },
  // {
  //   municipio: "San Emiliano",
  //   provincia: "León",
  //   id: "24145",
  //   label: "San Emiliano (León)",
  // },
  // {
  //   municipio: "Santas Martas",
  //   provincia: "León",
  //   id: "24160",
  //   label: "Santas Martas (León)",
  // },
  // {
  //   municipio: "San Justo de la Vega",
  //   provincia: "León",
  //   id: "24148",
  //   label: "San Justo de la Vega (León)",
  // },
  // {
  //   municipio: "San Millán de los Caballeros",
  //   provincia: "León",
  //   id: "24149",
  //   label: "San Millán de los Caballeros (León)",
  // },
  // {
  //   municipio: "San Pedro Bercianos",
  //   provincia: "León",
  //   id: "24150",
  //   label: "San Pedro Bercianos (León)",
  // },
  // {
  //   municipio: "Santa Colomba de Curueño",
  //   provincia: "León",
  //   id: "24151",
  //   label: "Santa Colomba de Curueño (León)",
  // },
  // {
  //   municipio: "Santa María del Monte de Cea",
  //   provincia: "León",
  //   id: "24156",
  //   label: "Santa María del Monte de Cea (León)",
  // },
  // {
  //   municipio: "Santa Colomba de Somoza",
  //   provincia: "León",
  //   id: "24152",
  //   label: "Santa Colomba de Somoza (León)",
  // },
  // {
  //   municipio: "Santa Cristina de Valmadrigal",
  //   provincia: "León",
  //   id: "24153",
  //   label: "Santa Cristina de Valmadrigal (León)",
  // },
  // {
  //   municipio: "Santa Elena de Jamuz",
  //   provincia: "León",
  //   id: "24154",
  //   label: "Santa Elena de Jamuz (León)",
  // },
  // {
  //   municipio: "Santa María del Páramo",
  //   provincia: "León",
  //   id: "24157",
  //   label: "Santa María del Páramo (León)",
  // },
  // {
  //   municipio: "Santa María de Ordás",
  //   provincia: "León",
  //   id: "24158",
  //   label: "Santa María de Ordás (León)",
  // },
  // {
  //   municipio: "Sariegos",
  //   provincia: "León",
  //   id: "24163",
  //   label: "Sariegos (León)",
  // },
  // {
  //   municipio: "Santa Marina del Rey",
  //   provincia: "León",
  //   id: "24159",
  //   label: "Santa Marina del Rey (León)",
  // },
  // {
  //   municipio: "Santiago Millas",
  //   provincia: "León",
  //   id: "24161",
  //   label: "Santiago Millas (León)",
  // },
  // {
  //   municipio: "Santovenia de la Valdoncina",
  //   provincia: "León",
  //   id: "24162",
  //   label: "Santovenia de la Valdoncina (León)",
  // },
  // {
  //   municipio: "Sena de Luna",
  //   provincia: "León",
  //   id: "24164",
  //   label: "Sena de Luna (León)",
  // },
  // {
  //   municipio: "Sobrado",
  //   provincia: "León",
  //   id: "24165",
  //   label: "Sobrado (León)",
  // },
  // {
  //   municipio: "Soto de la Vega",
  //   provincia: "León",
  //   id: "24166",
  //   label: "Soto de la Vega (León)",
  // },
  // {
  //   municipio: "Soto y Amío",
  //   provincia: "León",
  //   id: "24167",
  //   label: "Soto y Amío (León)",
  // },
  // {
  //   municipio: "Toral de los Guzmanes",
  //   provincia: "León",
  //   id: "24168",
  //   label: "Toral de los Guzmanes (León)",
  // },
  // {
  //   municipio: "Hornos de Moncalvillo",
  //   provincia: "La Rioja",
  //   id: "26078",
  //   label: "Hornos de Moncalvillo (La Rioja)",
  // },
  // {
  //   municipio: "Toreno",
  //   provincia: "León",
  //   id: "24169",
  //   label: "Toreno (León)",
  // },
  // {
  //   municipio: "Turcia",
  //   provincia: "León",
  //   id: "24173",
  //   label: "Turcia (León)",
  // },
  // {
  //   municipio: "Torre del Bierzo",
  //   provincia: "León",
  //   id: "24170",
  //   label: "Torre del Bierzo (León)",
  // },
  // {
  //   municipio: "Jalón de Cameros",
  //   provincia: "La Rioja",
  //   id: "26081",
  //   label: "Jalón de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Trabadelo",
  //   provincia: "León",
  //   id: "24171",
  //   label: "Trabadelo (León)",
  // },
  // {
  //   municipio: "Truchas",
  //   provincia: "León",
  //   id: "24172",
  //   label: "Truchas (León)",
  // },
  // {
  //   municipio: "Urdiales del Páramo",
  //   provincia: "León",
  //   id: "24174",
  //   label: "Urdiales del Páramo (León)",
  // },
  // {
  //   municipio: "Valderas",
  //   provincia: "León",
  //   id: "24181",
  //   label: "Valderas (León)",
  // },
  // {
  //   municipio: "Valdefresno",
  //   provincia: "León",
  //   id: "24175",
  //   label: "Valdefresno (León)",
  // },
  // {
  //   municipio: "Valdefuentes del Páramo",
  //   provincia: "León",
  //   id: "24176",
  //   label: "Valdefuentes del Páramo (León)",
  // },
  // {
  //   municipio: "Hinojosa del Campo",
  //   provincia: "Soria",
  //   id: "42100",
  //   label: "Hinojosa del Campo (Soria)",
  // },
  // {
  //   municipio: "Valdelugueros",
  //   provincia: "León",
  //   id: "24177",
  //   label: "Valdelugueros (León)",
  // },
  // {
  //   municipio: "Valdemora",
  //   provincia: "León",
  //   id: "24178",
  //   label: "Valdemora (León)",
  // },
  // {
  //   municipio: "Valdepiélago",
  //   provincia: "León",
  //   id: "24179",
  //   label: "Valdepiélago (León)",
  // },
  // {
  //   municipio: "Valdesamario",
  //   provincia: "León",
  //   id: "24184",
  //   label: "Valdesamario (León)",
  // },
  // {
  //   municipio: "Valdepolo",
  //   provincia: "León",
  //   id: "24180",
  //   label: "Valdepolo (León)",
  // },
  // {
  //   municipio: "Villamediana de Iregua",
  //   provincia: "La Rioja",
  //   id: "26168",
  //   label: "Villamediana de Iregua (La Rioja)",
  // },
  // {
  //   municipio: "Valderrey",
  //   provincia: "León",
  //   id: "24182",
  //   label: "Valderrey (León)",
  // },
  // {
  //   municipio: "Val de San Lorenzo",
  //   provincia: "León",
  //   id: "24185",
  //   label: "Val de San Lorenzo (León)",
  // },
  // {
  //   municipio: "Valderrueda",
  //   provincia: "León",
  //   id: "24183",
  //   label: "Valderrueda (León)",
  // },
  // {
  //   municipio: "Valdevimbre",
  //   provincia: "León",
  //   id: "24187",
  //   label: "Valdevimbre (León)",
  // },
  // {
  //   municipio: "Valverde-Enrique",
  //   provincia: "León",
  //   id: "24190",
  //   label: "Valverde-Enrique (León)",
  // },
  // {
  //   municipio: "Valencia de Don Juan",
  //   provincia: "León",
  //   id: "24188",
  //   label: "Valencia de Don Juan (León)",
  // },
  // {
  //   municipio: "Valverde de la Virgen",
  //   provincia: "León",
  //   id: "24189",
  //   label: "Valverde de la Virgen (León)",
  // },
  // {
  //   municipio: "Vecilla, La",
  //   provincia: "León",
  //   id: "24193",
  //   label: "Vecilla, La (León)",
  // },
  // {
  //   municipio: "Vegacervera",
  //   provincia: "León",
  //   id: "24194",
  //   label: "Vegacervera (León)",
  // },
  // {
  //   municipio: "Vega de Espinareda",
  //   provincia: "León",
  //   id: "24196",
  //   label: "Vega de Espinareda (León)",
  // },
  // {
  //   municipio: "Villamejil",
  //   provincia: "León",
  //   id: "24214",
  //   label: "Villamejil (León)",
  // },
  // {
  //   municipio: "Vega de Infanzones",
  //   provincia: "León",
  //   id: "24197",
  //   label: "Vega de Infanzones (León)",
  // },
  // {
  //   municipio: "Vega de Valcarce",
  //   provincia: "León",
  //   id: "24198",
  //   label: "Vega de Valcarce (León)",
  // },
  // {
  //   municipio: "Vegaquemada",
  //   provincia: "León",
  //   id: "24199",
  //   label: "Vegaquemada (León)",
  // },
  // {
  //   municipio: "Villabraz",
  //   provincia: "León",
  //   id: "24203",
  //   label: "Villabraz (León)",
  // },
  // {
  //   municipio: "Vegas del Condado",
  //   provincia: "León",
  //   id: "24201",
  //   label: "Vegas del Condado (León)",
  // },
  // {
  //   municipio: "Villablino",
  //   provincia: "León",
  //   id: "24202",
  //   label: "Villablino (León)",
  // },
  // {
  //   municipio: "Villademor de la Vega",
  //   provincia: "León",
  //   id: "24207",
  //   label: "Villademor de la Vega (León)",
  // },
  // {
  //   municipio: "Villadangos del Páramo",
  //   provincia: "León",
  //   id: "24205",
  //   label: "Villadangos del Páramo (León)",
  // },
  // {
  //   municipio: "Villagatón",
  //   provincia: "León",
  //   id: "24210",
  //   label: "Villagatón (León)",
  // },
  // {
  //   municipio: "Toral de los Vados",
  //   provincia: "León",
  //   id: "24206",
  //   label: "Toral de los Vados (León)",
  // },
  // {
  //   municipio: "Villafranca del Bierzo",
  //   provincia: "León",
  //   id: "24209",
  //   label: "Villafranca del Bierzo (León)",
  // },
  // {
  //   municipio: "Villamañán",
  //   provincia: "León",
  //   id: "24212",
  //   label: "Villamañán (León)",
  // },
  // {
  //   municipio: "Villamartín de Don Sancho",
  //   provincia: "León",
  //   id: "24213",
  //   label: "Villamartín de Don Sancho (León)",
  // },
  // {
  //   municipio: "Villamol",
  //   provincia: "León",
  //   id: "24215",
  //   label: "Villamol (León)",
  // },
  // {
  //   municipio: "Villamontán de la Valduerna",
  //   provincia: "León",
  //   id: "24216",
  //   label: "Villamontán de la Valduerna (León)",
  // },
  // {
  //   municipio: "Villamoratiel de las Matas",
  //   provincia: "León",
  //   id: "24217",
  //   label: "Villamoratiel de las Matas (León)",
  // },
  // {
  //   municipio: "Villanueva de las Manzanas",
  //   provincia: "León",
  //   id: "24218",
  //   label: "Villanueva de las Manzanas (León)",
  // },
  // {
  //   municipio: "Villaquejida",
  //   provincia: "León",
  //   id: "24221",
  //   label: "Villaquejida (León)",
  // },
  // {
  //   municipio: "Villaquilambre",
  //   provincia: "León",
  //   id: "24222",
  //   label: "Villaquilambre (León)",
  // },
  // {
  //   municipio: "Villarejo de Órbigo",
  //   provincia: "León",
  //   id: "24223",
  //   label: "Villarejo de Órbigo (León)",
  // },
  // {
  //   municipio: "Villares de Órbigo",
  //   provincia: "León",
  //   id: "24224",
  //   label: "Villares de Órbigo (León)",
  // },
  // {
  //   municipio: "Villaornate y Castro",
  //   provincia: "León",
  //   id: "24902",
  //   label: "Villaornate y Castro (León)",
  // },
  // {
  //   municipio: "Agramunt",
  //   provincia: "Lérida",
  //   id: "25003",
  //   label: "Agramunt (Lérida)",
  // },
  // {
  //   municipio: "Villasabariego",
  //   provincia: "León",
  //   id: "24225",
  //   label: "Villasabariego (León)",
  // },
  // {
  //   municipio: "Alamús, Els",
  //   provincia: "Lérida",
  //   id: "25004",
  //   label: "Alamús, Els (Lérida)",
  // },
  // {
  //   municipio: "Esterri d'Àneu",
  //   provincia: "Lérida",
  //   id: "25086",
  //   label: "Esterri d'Àneu (Lérida)",
  // },
  // {
  //   municipio: "Alàs i Cerc",
  //   provincia: "Lérida",
  //   id: "25005",
  //   label: "Alàs i Cerc (Lérida)",
  // },
  // {
  //   municipio: "Villaselán",
  //   provincia: "León",
  //   id: "24226",
  //   label: "Villaselán (León)",
  // },
  // {
  //   municipio: "Esterri de Cardós",
  //   provincia: "Lérida",
  //   id: "25087",
  //   label: "Esterri de Cardós (Lérida)",
  // },
  // {
  //   municipio: "Villaturiel",
  //   provincia: "León",
  //   id: "24227",
  //   label: "Villaturiel (León)",
  // },
  // {
  //   municipio: "Villanueva de Cameros",
  //   provincia: "La Rioja",
  //   id: "26169",
  //   label: "Villanueva de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Villazala",
  //   provincia: "León",
  //   id: "24228",
  //   label: "Villazala (León)",
  // },
  // {
  //   municipio: "Gimenells i el Pla de la Font",
  //   provincia: "Lérida",
  //   id: "25912",
  //   label: "Gimenells i el Pla de la Font (Lérida)",
  // },
  // {
  //   municipio: "Villazanzo de Valderaduey",
  //   provincia: "León",
  //   id: "24229",
  //   label: "Villazanzo de Valderaduey (León)",
  // },
  // {
  //   municipio: "Zotes del Páramo",
  //   provincia: "León",
  //   id: "24230",
  //   label: "Zotes del Páramo (León)",
  // },
  // {
  //   municipio: "Villamanín",
  //   provincia: "León",
  //   id: "24901",
  //   label: "Villamanín (León)",
  // },
  // {
  //   municipio: "Riu de Cerdanya",
  //   provincia: "Lérida",
  //   id: "25913",
  //   label: "Riu de Cerdanya (Lérida)",
  // },
  // {
  //   municipio: "Albatàrrec",
  //   provincia: "Lérida",
  //   id: "25007",
  //   label: "Albatàrrec (Lérida)",
  // },
  // {
  //   municipio: "Albesa",
  //   provincia: "Lérida",
  //   id: "25008",
  //   label: "Albesa (Lérida)",
  // },
  // {
  //   municipio: "Albi, L'",
  //   provincia: "Lérida",
  //   id: "25009",
  //   label: "Albi, L' (Lérida)",
  // },
  // {
  //   municipio: "Ábalos",
  //   provincia: "La Rioja",
  //   id: "26001",
  //   label: "Ábalos (La Rioja)",
  // },
  // {
  //   municipio: "Alcanó",
  //   provincia: "Lérida",
  //   id: "25010",
  //   label: "Alcanó (Lérida)",
  // },
  // {
  //   municipio: "Alfarràs",
  //   provincia: "Lérida",
  //   id: "25013",
  //   label: "Alfarràs (Lérida)",
  // },
  // {
  //   municipio: "Alcarràs",
  //   provincia: "Lérida",
  //   id: "25011",
  //   label: "Alcarràs (Lérida)",
  // },
  // {
  //   municipio: "Artesa de Lleida",
  //   provincia: "Lérida",
  //   id: "25033",
  //   label: "Artesa de Lleida (Lérida)",
  // },
  // {
  //   municipio: "Sentiu de Sió, La",
  //   provincia: "Lérida",
  //   id: "25035",
  //   label: "Sentiu de Sió, La (Lérida)",
  // },
  // {
  //   municipio: "Aspa",
  //   provincia: "Lérida",
  //   id: "25036",
  //   label: "Aspa (Lérida)",
  // },
  // {
  //   municipio: "Alcoletge",
  //   provincia: "Lérida",
  //   id: "25012",
  //   label: "Alcoletge (Lérida)",
  // },
  // {
  //   municipio: "Aitona",
  //   provincia: "Lérida",
  //   id: "25038",
  //   label: "Aitona (Lérida)",
  // },
  // {
  //   municipio: "Alfés",
  //   provincia: "Lérida",
  //   id: "25014",
  //   label: "Alfés (Lérida)",
  // },
  // {
  //   municipio: "Algerri",
  //   provincia: "Lérida",
  //   id: "25015",
  //   label: "Algerri (Lérida)",
  // },
  // {
  //   municipio: "Castellnou de Seana",
  //   provincia: "Lérida",
  //   id: "25068",
  //   label: "Castellnou de Seana (Lérida)",
  // },
  // {
  //   municipio: "Alguaire",
  //   provincia: "Lérida",
  //   id: "25016",
  //   label: "Alguaire (Lérida)",
  // },
  // {
  //   municipio: "Almacelles",
  //   provincia: "Lérida",
  //   id: "25019",
  //   label: "Almacelles (Lérida)",
  // },
  // {
  //   municipio: "Almatret",
  //   provincia: "Lérida",
  //   id: "25020",
  //   label: "Almatret (Lérida)",
  // },
  // {
  //   municipio: "Almenar",
  //   provincia: "Lérida",
  //   id: "25021",
  //   label: "Almenar (Lérida)",
  // },
  // {
  //   municipio: "Alòs de Balaguer",
  //   provincia: "Lérida",
  //   id: "25022",
  //   label: "Alòs de Balaguer (Lérida)",
  // },
  // {
  //   municipio: "Alpicat",
  //   provincia: "Lérida",
  //   id: "25023",
  //   label: "Alpicat (Lérida)",
  // },
  // {
  //   municipio: "Alt Àneu",
  //   provincia: "Lérida",
  //   id: "25024",
  //   label: "Alt Àneu (Lérida)",
  // },
  // {
  //   municipio: "Anglesola",
  //   provincia: "Lérida",
  //   id: "25027",
  //   label: "Anglesola (Lérida)",
  // },
  // {
  //   municipio: "Pont de Bar, El",
  //   provincia: "Lérida",
  //   id: "25030",
  //   label: "Pont de Bar, El (Lérida)",
  // },
  // {
  //   municipio: "Arres",
  //   provincia: "Lérida",
  //   id: "25031",
  //   label: "Arres (Lérida)",
  // },
  // {
  //   municipio: "Bellmunt d'Urgell",
  //   provincia: "Lérida",
  //   id: "25049",
  //   label: "Bellmunt d'Urgell (Lérida)",
  // },
  // {
  //   municipio: "Bellpuig",
  //   provincia: "Lérida",
  //   id: "25050",
  //   label: "Bellpuig (Lérida)",
  // },
  // {
  //   municipio: "Arsèguel",
  //   provincia: "Lérida",
  //   id: "25032",
  //   label: "Arsèguel (Lérida)",
  // },
  // {
  //   municipio: "Barbens",
  //   provincia: "Lérida",
  //   id: "25041",
  //   label: "Barbens (Lérida)",
  // },
  // {
  //   municipio: "Bassella",
  //   provincia: "Lérida",
  //   id: "25044",
  //   label: "Bassella (Lérida)",
  // },
  // {
  //   municipio: "Liceras",
  //   provincia: "Soria",
  //   id: "42105",
  //   label: "Liceras (Soria)",
  // },
  // {
  //   municipio: "Bausen",
  //   provincia: "Lérida",
  //   id: "25045",
  //   label: "Bausen (Lérida)",
  // },
  // {
  //   municipio: "Bellcaire d'Urgell",
  //   provincia: "Lérida",
  //   id: "25047",
  //   label: "Bellcaire d'Urgell (Lérida)",
  // },
  // {
  //   municipio: "Bell-lloc d'Urgell",
  //   provincia: "Lérida",
  //   id: "25048",
  //   label: "Bell-lloc d'Urgell (Lérida)",
  // },
  // {
  //   municipio: "Bellver de Cerdanya",
  //   provincia: "Lérida",
  //   id: "25051",
  //   label: "Bellver de Cerdanya (Lérida)",
  // },
  // {
  //   municipio: "Benavent de Segrià",
  //   provincia: "Lérida",
  //   id: "25053",
  //   label: "Benavent de Segrià (Lérida)",
  // },
  // {
  //   municipio: "Castellserà",
  //   provincia: "Lérida",
  //   id: "25070",
  //   label: "Castellserà (Lérida)",
  // },
  // {
  //   municipio: "Cava",
  //   provincia: "Lérida",
  //   id: "25071",
  //   label: "Cava (Lérida)",
  // },
  // {
  //   municipio: "Ciutadilla",
  //   provincia: "Lérida",
  //   id: "25074",
  //   label: "Ciutadilla (Lérida)",
  // },
  // {
  //   municipio: "Clariana de Cardener",
  //   provincia: "Lérida",
  //   id: "25075",
  //   label: "Clariana de Cardener (Lérida)",
  // },
  // {
  //   municipio: "Bòrdes, Es",
  //   provincia: "Lérida",
  //   id: "25057",
  //   label: "Bòrdes, Es (Lérida)",
  // },
  // {
  //   municipio: "Bossòst",
  //   provincia: "Lérida",
  //   id: "25059",
  //   label: "Bossòst (Lérida)",
  // },
  // {
  //   municipio: "Cabanabona",
  //   provincia: "Lérida",
  //   id: "25060",
  //   label: "Cabanabona (Lérida)",
  // },
  // {
  //   municipio: "Cogul, El",
  //   provincia: "Lérida",
  //   id: "25076",
  //   label: "Cogul, El (Lérida)",
  // },
  // {
  //   municipio: "Corbins",
  //   provincia: "Lérida",
  //   id: "25078",
  //   label: "Corbins (Lérida)",
  // },
  // {
  //   municipio: "Cubells",
  //   provincia: "Lérida",
  //   id: "25079",
  //   label: "Cubells (Lérida)",
  // },
  // {
  //   municipio: "Espluga Calba, L'",
  //   provincia: "Lérida",
  //   id: "25081",
  //   label: "Espluga Calba, L' (Lérida)",
  // },
  // {
  //   municipio: "Canejan",
  //   provincia: "Lérida",
  //   id: "25063",
  //   label: "Canejan (Lérida)",
  // },
  // {
  //   municipio: "Castellar de la Ribera",
  //   provincia: "Lérida",
  //   id: "25064",
  //   label: "Castellar de la Ribera (Lérida)",
  // },
  // {
  //   municipio: "Castelló de Farfanya",
  //   provincia: "Lérida",
  //   id: "25069",
  //   label: "Castelló de Farfanya (Lérida)",
  // },
  // {
  //   municipio: "Espot",
  //   provincia: "Lérida",
  //   id: "25082",
  //   label: "Espot (Lérida)",
  // },
  // {
  //   municipio: "Estaràs",
  //   provincia: "Lérida",
  //   id: "25085",
  //   label: "Estaràs (Lérida)",
  // },
  // {
  //   municipio: "Estamariu",
  //   provincia: "Lérida",
  //   id: "25088",
  //   label: "Estamariu (Lérida)",
  // },
  // {
  //   municipio: "Ivorra",
  //   provincia: "Lérida",
  //   id: "25114",
  //   label: "Ivorra (Lérida)",
  // },
  // {
  //   municipio: "Farrera",
  //   provincia: "Lérida",
  //   id: "25089",
  //   label: "Farrera (Lérida)",
  // },
  // {
  //   municipio: "Fondarella",
  //   provincia: "Lérida",
  //   id: "25093",
  //   label: "Fondarella (Lérida)",
  // },
  // {
  //   municipio: "Foradada",
  //   provincia: "Lérida",
  //   id: "25094",
  //   label: "Foradada (Lérida)",
  // },
  // {
  //   municipio: "Les",
  //   provincia: "Lérida",
  //   id: "25121",
  //   label: "Les (Lérida)",
  // },
  // {
  //   municipio: "Linyola",
  //   provincia: "Lérida",
  //   id: "25122",
  //   label: "Linyola (Lérida)",
  // },
  // {
  //   municipio: "Fuliola, La",
  //   provincia: "Lérida",
  //   id: "25096",
  //   label: "Fuliola, La (Lérida)",
  // },
  // {
  //   municipio: "Fulleda",
  //   provincia: "Lérida",
  //   id: "25097",
  //   label: "Fulleda (Lérida)",
  // },
  // {
  //   municipio: "Gavet de la Conca",
  //   provincia: "Lérida",
  //   id: "25098",
  //   label: "Gavet de la Conca (Lérida)",
  // },
  // {
  //   municipio: "Lladorre",
  //   provincia: "Lérida",
  //   id: "25123",
  //   label: "Lladorre (Lérida)",
  // },
  // {
  //   municipio: "Golmés",
  //   provincia: "Lérida",
  //   id: "25099",
  //   label: "Golmés (Lérida)",
  // },
  // {
  //   municipio: "Granja d'Escarp, La",
  //   provincia: "Lérida",
  //   id: "25102",
  //   label: "Granja d'Escarp, La (Lérida)",
  // },
  // {
  //   municipio: "Granyanella",
  //   provincia: "Lérida",
  //   id: "25103",
  //   label: "Granyanella (Lérida)",
  // },
  // {
  //   municipio: "Granyena de Segarra",
  //   provincia: "Lérida",
  //   id: "25104",
  //   label: "Granyena de Segarra (Lérida)",
  // },
  // {
  //   municipio: "Guimerà",
  //   provincia: "Lérida",
  //   id: "25109",
  //   label: "Guimerà (Lérida)",
  // },
  // {
  //   municipio: "Llavorsí",
  //   provincia: "Lérida",
  //   id: "25126",
  //   label: "Llavorsí (Lérida)",
  // },
  // {
  //   municipio: "Alberite",
  //   provincia: "La Rioja",
  //   id: "26006",
  //   label: "Alberite (La Rioja)",
  // },
  // {
  //   municipio: "Guissona",
  //   provincia: "Lérida",
  //   id: "25110",
  //   label: "Guissona (Lérida)",
  // },
  // {
  //   municipio: "Montellà i Martinet",
  //   provincia: "Lérida",
  //   id: "25139",
  //   label: "Montellà i Martinet (Lérida)",
  // },
  // {
  //   municipio: "Ivars de Noguera",
  //   provincia: "Lérida",
  //   id: "25112",
  //   label: "Ivars de Noguera (Lérida)",
  // },
  // {
  //   municipio: "Nalec",
  //   provincia: "Lérida",
  //   id: "25145",
  //   label: "Nalec (Lérida)",
  // },
  // {
  //   municipio: "Oliola",
  //   provincia: "Lérida",
  //   id: "25150",
  //   label: "Oliola (Lérida)",
  // },
  // {
  //   municipio: "Olius",
  //   provincia: "Lérida",
  //   id: "25151",
  //   label: "Olius (Lérida)",
  // },
  // {
  //   municipio: "Meruelo",
  //   provincia: "Cantabria",
  //   id: "39043",
  //   label: "Meruelo (Cantabria)",
  // },
  // {
  //   municipio: "Llardecans",
  //   provincia: "Lérida",
  //   id: "25125",
  //   label: "Llardecans (Lérida)",
  // },
  // {
  //   municipio: "Llimiana",
  //   provincia: "Lérida",
  //   id: "25128",
  //   label: "Llimiana (Lérida)",
  // },
  // {
  //   municipio: "Miengo",
  //   provincia: "Cantabria",
  //   id: "39044",
  //   label: "Miengo (Cantabria)",
  // },
  // {
  //   municipio: "Miera",
  //   provincia: "Cantabria",
  //   id: "39045",
  //   label: "Miera (Cantabria)",
  // },
  // {
  //   municipio: "Llobera",
  //   provincia: "Lérida",
  //   id: "25129",
  //   label: "Llobera (Lérida)",
  // },
  // {
  //   municipio: "Massalcoreig",
  //   provincia: "Lérida",
  //   id: "25131",
  //   label: "Massalcoreig (Lérida)",
  // },
  // {
  //   municipio: "Massoteres",
  //   provincia: "Lérida",
  //   id: "25132",
  //   label: "Massoteres (Lérida)",
  // },
  // {
  //   municipio: "Maials",
  //   provincia: "Lérida",
  //   id: "25133",
  //   label: "Maials (Lérida)",
  // },
  // {
  //   municipio: "Miralcamp",
  //   provincia: "Lérida",
  //   id: "25135",
  //   label: "Miralcamp (Lérida)",
  // },
  // {
  //   municipio: "Mollerussa",
  //   provincia: "Lérida",
  //   id: "25137",
  //   label: "Mollerussa (Lérida)",
  // },
  // {
  //   municipio: "Montgai",
  //   provincia: "Lérida",
  //   id: "25138",
  //   label: "Montgai (Lérida)",
  // },
  // {
  //   municipio: "Montferrer i Castellbò",
  //   provincia: "Lérida",
  //   id: "25140",
  //   label: "Montferrer i Castellbò (Lérida)",
  // },
  // {
  //   municipio: "Montoliu de Lleida",
  //   provincia: "Lérida",
  //   id: "25142",
  //   label: "Montoliu de Lleida (Lérida)",
  // },
  // {
  //   municipio: "Portella, La",
  //   provincia: "Lérida",
  //   id: "25174",
  //   label: "Portella, La (Lérida)",
  // },
  // {
  //   municipio: "Montornès de Segarra",
  //   provincia: "Lérida",
  //   id: "25143",
  //   label: "Montornès de Segarra (Lérida)",
  // },
  // {
  //   municipio: "Oluges, Les",
  //   provincia: "Lérida",
  //   id: "25152",
  //   label: "Oluges, Les (Lérida)",
  // },
  // {
  //   municipio: "Omellons, Els",
  //   provincia: "Lérida",
  //   id: "25153",
  //   label: "Omellons, Els (Lérida)",
  // },
  // {
  //   municipio: "Omells de na Gaia, Els",
  //   provincia: "Lérida",
  //   id: "25154",
  //   label: "Omells de na Gaia, Els (Lérida)",
  // },
  // {
  //   municipio: "Organyà",
  //   provincia: "Lérida",
  //   id: "25155",
  //   label: "Organyà (Lérida)",
  // },
  // {
  //   municipio: "Ossó de Sió",
  //   provincia: "Lérida",
  //   id: "25157",
  //   label: "Ossó de Sió (Lérida)",
  // },
  // {
  //   municipio: "Palau d'Anglesola, El",
  //   provincia: "Lérida",
  //   id: "25158",
  //   label: "Palau d'Anglesola, El (Lérida)",
  // },
  // {
  //   municipio: "Penelles",
  //   provincia: "Lérida",
  //   id: "25164",
  //   label: "Penelles (Lérida)",
  // },
  // {
  //   municipio: "Peramola",
  //   provincia: "Lérida",
  //   id: "25165",
  //   label: "Peramola (Lérida)",
  // },
  // {
  //   municipio: "Poal, El",
  //   provincia: "Lérida",
  //   id: "25168",
  //   label: "Poal, El (Lérida)",
  // },
  // {
  //   municipio: "Bellaguarda",
  //   provincia: "Lérida",
  //   id: "25170",
  //   label: "Bellaguarda (Lérida)",
  // },
  // {
  //   municipio: "Pobla de Segur, La",
  //   provincia: "Lérida",
  //   id: "25171",
  //   label: "Pobla de Segur, La (Lérida)",
  // },
  // {
  //   municipio: "Prats i Sansor",
  //   provincia: "Lérida",
  //   id: "25175",
  //   label: "Prats i Sansor (Lérida)",
  // },
  // {
  //   municipio: "Preixana",
  //   provincia: "Lérida",
  //   id: "25176",
  //   label: "Preixana (Lérida)",
  // },
  // {
  //   municipio: "Juarros de Riomoros",
  //   provincia: "Segovia",
  //   id: "40105",
  //   label: "Juarros de Riomoros (Segovia)",
  // },
  // {
  //   municipio: "Ponts",
  //   provincia: "Lérida",
  //   id: "25172",
  //   label: "Ponts (Lérida)",
  // },
  // {
  //   municipio: "Preixens",
  //   provincia: "Lérida",
  //   id: "25177",
  //   label: "Preixens (Lérida)",
  // },
  // {
  //   municipio: "Prullans",
  //   provincia: "Lérida",
  //   id: "25179",
  //   label: "Prullans (Lérida)",
  // },
  // {
  //   municipio: "Pont de Suert, El",
  //   provincia: "Lérida",
  //   id: "25173",
  //   label: "Pont de Suert, El (Lérida)",
  // },
  // {
  //   municipio: "Puigverd d'Agramunt",
  //   provincia: "Lérida",
  //   id: "25181",
  //   label: "Puigverd d'Agramunt (Lérida)",
  // },
  // {
  //   municipio: "Puigverd de Lleida",
  //   provincia: "Lérida",
  //   id: "25182",
  //   label: "Puigverd de Lleida (Lérida)",
  // },
  // {
  //   municipio: "Rialp",
  //   provincia: "Lérida",
  //   id: "25183",
  //   label: "Rialp (Lérida)",
  // },
  // {
  //   municipio: "Sant Ramon",
  //   provincia: "Lérida",
  //   id: "25194",
  //   label: "Sant Ramon (Lérida)",
  // },
  // {
  //   municipio: "Ribera d'Urgellet",
  //   provincia: "Lérida",
  //   id: "25185",
  //   label: "Ribera d'Urgellet (Lérida)",
  // },
  // {
  //   municipio: "Riner",
  //   provincia: "Lérida",
  //   id: "25186",
  //   label: "Riner (Lérida)",
  // },
  // {
  //   municipio: "Soses",
  //   provincia: "Lérida",
  //   id: "25210",
  //   label: "Soses (Lérida)",
  // },
  // {
  //   municipio: "Rosselló",
  //   provincia: "Lérida",
  //   id: "25189",
  //   label: "Rosselló (Lérida)",
  // },
  // {
  //   municipio: "Salàs de Pallars",
  //   provincia: "Lérida",
  //   id: "25190",
  //   label: "Salàs de Pallars (Lérida)",
  // },
  // {
  //   municipio: "Sanaüja",
  //   provincia: "Lérida",
  //   id: "25191",
  //   label: "Sanaüja (Lérida)",
  // },
  // {
  //   municipio: "Sant Guim de Freixenet",
  //   provincia: "Lérida",
  //   id: "25192",
  //   label: "Sant Guim de Freixenet (Lérida)",
  // },
  // {
  //   municipio: "Tornabous",
  //   provincia: "Lérida",
  //   id: "25225",
  //   label: "Tornabous (Lérida)",
  // },
  // {
  //   municipio: "Torrebesses",
  //   provincia: "Lérida",
  //   id: "25226",
  //   label: "Torrebesses (Lérida)",
  // },
  // {
  //   municipio: "Sant Esteve de la Sarga",
  //   provincia: "Lérida",
  //   id: "25196",
  //   label: "Sant Esteve de la Sarga (Lérida)",
  // },
  // {
  //   municipio: "Sant Guim de la Plana",
  //   provincia: "Lérida",
  //   id: "25197",
  //   label: "Sant Guim de la Plana (Lérida)",
  // },
  // {
  //   municipio: "Sarroca de Lleida",
  //   provincia: "Lérida",
  //   id: "25200",
  //   label: "Sarroca de Lleida (Lérida)",
  // },
  // {
  //   municipio: "Sarroca de Bellera",
  //   provincia: "Lérida",
  //   id: "25201",
  //   label: "Sarroca de Bellera (Lérida)",
  // },
  // {
  //   municipio: "Senterada",
  //   provincia: "Lérida",
  //   id: "25202",
  //   label: "Senterada (Lérida)",
  // },
  // {
  //   municipio: "Seu d'Urgell, La",
  //   provincia: "Lérida",
  //   id: "25203",
  //   label: "Seu d'Urgell, La (Lérida)",
  // },
  // {
  //   municipio: "Seròs",
  //   provincia: "Lérida",
  //   id: "25204",
  //   label: "Seròs (Lérida)",
  // },
  // {
  //   municipio: "Sidamon",
  //   provincia: "Lérida",
  //   id: "25205",
  //   label: "Sidamon (Lérida)",
  // },
  // {
  //   municipio: "Soleràs, El",
  //   provincia: "Lérida",
  //   id: "25206",
  //   label: "Soleràs, El (Lérida)",
  // },
  // {
  //   municipio: "Solsona",
  //   provincia: "Lérida",
  //   id: "25207",
  //   label: "Solsona (Lérida)",
  // },
  // {
  //   municipio: "Soriguera",
  //   provincia: "Lérida",
  //   id: "25208",
  //   label: "Soriguera (Lérida)",
  // },
  // {
  //   municipio: "Villar de Arnedo, El",
  //   provincia: "La Rioja",
  //   id: "26170",
  //   label: "Villar de Arnedo, El (La Rioja)",
  // },
  // {
  //   municipio: "Sort",
  //   provincia: "Lérida",
  //   id: "25209",
  //   label: "Sort (Lérida)",
  // },
  // {
  //   municipio: "Sudanell",
  //   provincia: "Lérida",
  //   id: "25211",
  //   label: "Sudanell (Lérida)",
  // },
  // {
  //   municipio: "Sunyer",
  //   provincia: "Lérida",
  //   id: "25212",
  //   label: "Sunyer (Lérida)",
  // },
  // {
  //   municipio: "Talarn",
  //   provincia: "Lérida",
  //   id: "25215",
  //   label: "Talarn (Lérida)",
  // },
  // {
  //   municipio: "Talavera",
  //   provincia: "Lérida",
  //   id: "25216",
  //   label: "Talavera (Lérida)",
  // },
  // {
  //   municipio: "Tarrés",
  //   provincia: "Lérida",
  //   id: "25218",
  //   label: "Tarrés (Lérida)",
  // },
  // {
  //   municipio: "Torrelameu",
  //   provincia: "Lérida",
  //   id: "25231",
  //   label: "Torrelameu (Lérida)",
  // },
  // {
  //   municipio: "Tarroja de Segarra",
  //   provincia: "Lérida",
  //   id: "25219",
  //   label: "Tarroja de Segarra (Lérida)",
  // },
  // {
  //   municipio: "Térmens",
  //   provincia: "Lérida",
  //   id: "25220",
  //   label: "Térmens (Lérida)",
  // },
  // {
  //   municipio: "Tírvia",
  //   provincia: "Lérida",
  //   id: "25221",
  //   label: "Tírvia (Lérida)",
  // },
  // {
  //   municipio: "Tiurana",
  //   provincia: "Lérida",
  //   id: "25222",
  //   label: "Tiurana (Lérida)",
  // },
  // {
  //   municipio: "Torms, Els",
  //   provincia: "Lérida",
  //   id: "25224",
  //   label: "Torms, Els (Lérida)",
  // },
  // {
  //   municipio: "Torres de Segre",
  //   provincia: "Lérida",
  //   id: "25232",
  //   label: "Torres de Segre (Lérida)",
  // },
  // {
  //   municipio: "Vilanova de Segrià",
  //   provincia: "Lérida",
  //   id: "25251",
  //   label: "Vilanova de Segrià (Lérida)",
  // },
  // {
  //   municipio: "Torre-serona",
  //   provincia: "Lérida",
  //   id: "25233",
  //   label: "Torre-serona (Lérida)",
  // },
  // {
  //   municipio: "Vallbona de les Monges",
  //   provincia: "Lérida",
  //   id: "25238",
  //   label: "Vallbona de les Monges (Lérida)",
  // },
  // {
  //   municipio: "Vallfogona de Balaguer",
  //   provincia: "Lérida",
  //   id: "25240",
  //   label: "Vallfogona de Balaguer (Lérida)",
  // },
  // {
  //   municipio: "Vielha e Mijaran",
  //   provincia: "Lérida",
  //   id: "25243",
  //   label: "Vielha e Mijaran (Lérida)",
  // },
  // {
  //   municipio: "Vilagrassa",
  //   provincia: "Lérida",
  //   id: "25244",
  //   label: "Vilagrassa (Lérida)",
  // },
  // {
  //   municipio: "Vilaller",
  //   provincia: "Lérida",
  //   id: "25245",
  //   label: "Vilaller (Lérida)",
  // },
  // {
  //   municipio: "Vilamòs",
  //   provincia: "Lérida",
  //   id: "25247",
  //   label: "Vilamòs (Lérida)",
  // },
  // {
  //   municipio: "Vilanova de Bellpuig",
  //   provincia: "Lérida",
  //   id: "25248",
  //   label: "Vilanova de Bellpuig (Lérida)",
  // },
  // {
  //   municipio: "Vilanova de l'Aguda",
  //   provincia: "Lérida",
  //   id: "25249",
  //   label: "Vilanova de l'Aguda (Lérida)",
  // },
  // {
  //   municipio: "Vila-sana",
  //   provincia: "Lérida",
  //   id: "25252",
  //   label: "Vila-sana (Lérida)",
  // },
  // {
  //   municipio: "Alesanco",
  //   provincia: "La Rioja",
  //   id: "26009",
  //   label: "Alesanco (La Rioja)",
  // },
  // {
  //   municipio: "Vilanova de Meià",
  //   provincia: "Lérida",
  //   id: "25250",
  //   label: "Vilanova de Meià (Lérida)",
  // },
  // {
  //   municipio: "Vilanova de la Barca",
  //   provincia: "Lérida",
  //   id: "25254",
  //   label: "Vilanova de la Barca (Lérida)",
  // },
  // {
  //   municipio: "Vall de Cardós",
  //   provincia: "Lérida",
  //   id: "25901",
  //   label: "Vall de Cardós (Lérida)",
  // },
  // {
  //   municipio: "Guingueta d'Àneu, La",
  //   provincia: "Lérida",
  //   id: "25903",
  //   label: "Guingueta d'Àneu, La (Lérida)",
  // },
  // {
  //   municipio: "Castell de Mur",
  //   provincia: "Lérida",
  //   id: "25904",
  //   label: "Castell de Mur (Lérida)",
  // },
  // {
  //   municipio: "Alesón",
  //   provincia: "La Rioja",
  //   id: "26010",
  //   label: "Alesón (La Rioja)",
  // },
  // {
  //   municipio: "Valls d'Aguilar, Les",
  //   provincia: "Lérida",
  //   id: "25906",
  //   label: "Valls d'Aguilar, Les (Lérida)",
  // },
  // {
  //   municipio: "Vansa i Fórnols, La",
  //   provincia: "Lérida",
  //   id: "25909",
  //   label: "Vansa i Fórnols, La (Lérida)",
  // },
  // {
  //   municipio: "Plans de Sió, Els",
  //   provincia: "Lérida",
  //   id: "25911",
  //   label: "Plans de Sió, Els (Lérida)",
  // },
  // {
  //   municipio: "Agoncillo",
  //   provincia: "La Rioja",
  //   id: "26002",
  //   label: "Agoncillo (La Rioja)",
  // },
  // {
  //   municipio: "Almarza de Cameros",
  //   provincia: "La Rioja",
  //   id: "26012",
  //   label: "Almarza de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Aguilar del Río Alhama",
  //   provincia: "La Rioja",
  //   id: "26003",
  //   label: "Aguilar del Río Alhama (La Rioja)",
  // },
  // {
  //   municipio: "Anguciana",
  //   provincia: "La Rioja",
  //   id: "26013",
  //   label: "Anguciana (La Rioja)",
  // },
  // {
  //   municipio: "Pradejón",
  //   provincia: "La Rioja",
  //   id: "26117",
  //   label: "Pradejón (La Rioja)",
  // },
  // {
  //   municipio: "Ajamil de Cameros",
  //   provincia: "La Rioja",
  //   id: "26004",
  //   label: "Ajamil de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Albelda de Iregua",
  //   provincia: "La Rioja",
  //   id: "26005",
  //   label: "Albelda de Iregua (La Rioja)",
  // },
  // {
  //   municipio: "Alcanadre",
  //   provincia: "La Rioja",
  //   id: "26007",
  //   label: "Alcanadre (La Rioja)",
  // },
  // {
  //   municipio: "Aldeanueva de Ebro",
  //   provincia: "La Rioja",
  //   id: "26008",
  //   label: "Aldeanueva de Ebro (La Rioja)",
  // },
  // {
  //   municipio: "Alfaro",
  //   provincia: "La Rioja",
  //   id: "26011",
  //   label: "Alfaro (La Rioja)",
  // },
  // {
  //   municipio: "Anguiano",
  //   provincia: "La Rioja",
  //   id: "26014",
  //   label: "Anguiano (La Rioja)",
  // },
  // {
  //   municipio: "Arnedillo",
  //   provincia: "La Rioja",
  //   id: "26017",
  //   label: "Arnedillo (La Rioja)",
  // },
  // {
  //   municipio: "Ausejo",
  //   provincia: "La Rioja",
  //   id: "26020",
  //   label: "Ausejo (La Rioja)",
  // },
  // {
  //   municipio: "Arnedo",
  //   provincia: "La Rioja",
  //   id: "26018",
  //   label: "Arnedo (La Rioja)",
  // },
  // {
  //   municipio: "Arrúbal",
  //   provincia: "La Rioja",
  //   id: "26019",
  //   label: "Arrúbal (La Rioja)",
  // },
  // {
  //   municipio: "Autol",
  //   provincia: "La Rioja",
  //   id: "26021",
  //   label: "Autol (La Rioja)",
  // },
  // {
  //   municipio: "Azofra",
  //   provincia: "La Rioja",
  //   id: "26022",
  //   label: "Azofra (La Rioja)",
  // },
  // {
  //   municipio: "Baños de Río Tobía",
  //   provincia: "La Rioja",
  //   id: "26026",
  //   label: "Baños de Río Tobía (La Rioja)",
  // },
  // {
  //   municipio: "Badarán",
  //   provincia: "La Rioja",
  //   id: "26023",
  //   label: "Badarán (La Rioja)",
  // },
  // {
  //   municipio: "Bañares",
  //   provincia: "La Rioja",
  //   id: "26024",
  //   label: "Bañares (La Rioja)",
  // },
  // {
  //   municipio: "Baños de Rioja",
  //   provincia: "La Rioja",
  //   id: "26025",
  //   label: "Baños de Rioja (La Rioja)",
  // },
  // {
  //   municipio: "Berceo",
  //   provincia: "La Rioja",
  //   id: "26027",
  //   label: "Berceo (La Rioja)",
  // },
  // {
  //   municipio: "Bobadilla",
  //   provincia: "La Rioja",
  //   id: "26031",
  //   label: "Bobadilla (La Rioja)",
  // },
  // {
  //   municipio: "Pradillo",
  //   provincia: "La Rioja",
  //   id: "26118",
  //   label: "Pradillo (La Rioja)",
  // },
  // {
  //   municipio: "Bergasa",
  //   provincia: "La Rioja",
  //   id: "26028",
  //   label: "Bergasa (La Rioja)",
  // },
  // {
  //   municipio: "Bergasillas Bajera",
  //   provincia: "La Rioja",
  //   id: "26029",
  //   label: "Bergasillas Bajera (La Rioja)",
  // },
  // {
  //   municipio: "Bezares",
  //   provincia: "La Rioja",
  //   id: "26030",
  //   label: "Bezares (La Rioja)",
  // },
  // {
  //   municipio: "Brieva de Cameros",
  //   provincia: "La Rioja",
  //   id: "26032",
  //   label: "Brieva de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Hormilla",
  //   provincia: "La Rioja",
  //   id: "26075",
  //   label: "Hormilla (La Rioja)",
  // },
  // {
  //   municipio: "Zarratón",
  //   provincia: "La Rioja",
  //   id: "26180",
  //   label: "Zarratón (La Rioja)",
  // },
  // {
  //   municipio: "Briñas",
  //   provincia: "La Rioja",
  //   id: "26033",
  //   label: "Briñas (La Rioja)",
  // },
  // {
  //   municipio: "Camprovín",
  //   provincia: "La Rioja",
  //   id: "26037",
  //   label: "Camprovín (La Rioja)",
  // },
  // {
  //   municipio: "Briones",
  //   provincia: "La Rioja",
  //   id: "26034",
  //   label: "Briones (La Rioja)",
  // },
  // {
  //   municipio: "Cabezón de Cameros",
  //   provincia: "La Rioja",
  //   id: "26035",
  //   label: "Cabezón de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Calahorra",
  //   provincia: "La Rioja",
  //   id: "26036",
  //   label: "Calahorra (La Rioja)",
  // },
  // {
  //   municipio: "Cárdenas",
  //   provincia: "La Rioja",
  //   id: "26041",
  //   label: "Cárdenas (La Rioja)",
  // },
  // {
  //   municipio: "Canales de la Sierra",
  //   provincia: "La Rioja",
  //   id: "26038",
  //   label: "Canales de la Sierra (La Rioja)",
  // },
  // {
  //   municipio: "Canillas de Río Tuerto",
  //   provincia: "La Rioja",
  //   id: "26039",
  //   label: "Canillas de Río Tuerto (La Rioja)",
  // },
  // {
  //   municipio: "Cañas",
  //   provincia: "La Rioja",
  //   id: "26040",
  //   label: "Cañas (La Rioja)",
  // },
  // {
  //   municipio: "Castroviejo",
  //   provincia: "La Rioja",
  //   id: "26044",
  //   label: "Castroviejo (La Rioja)",
  // },
  // {
  //   municipio: "Cellorigo",
  //   provincia: "La Rioja",
  //   id: "26045",
  //   label: "Cellorigo (La Rioja)",
  // },
  // {
  //   municipio: "Cidamón",
  //   provincia: "La Rioja",
  //   id: "26048",
  //   label: "Cidamón (La Rioja)",
  // },
  // {
  //   municipio: "Cenicero",
  //   provincia: "La Rioja",
  //   id: "26046",
  //   label: "Cenicero (La Rioja)",
  // },
  // {
  //   municipio: "Cihuri",
  //   provincia: "La Rioja",
  //   id: "26049",
  //   label: "Cihuri (La Rioja)",
  // },
  // {
  //   municipio: "Penagos",
  //   provincia: "Cantabria",
  //   id: "39048",
  //   label: "Penagos (Cantabria)",
  // },
  // {
  //   municipio: "Cervera del Río Alhama",
  //   provincia: "La Rioja",
  //   id: "26047",
  //   label: "Cervera del Río Alhama (La Rioja)",
  // },
  // {
  //   municipio: "Peñarrubia",
  //   provincia: "Cantabria",
  //   id: "39049",
  //   label: "Peñarrubia (Cantabria)",
  // },
  // {
  //   municipio: "Clavijo",
  //   provincia: "La Rioja",
  //   id: "26051",
  //   label: "Clavijo (La Rioja)",
  // },
  // {
  //   municipio: "Cordovín",
  //   provincia: "La Rioja",
  //   id: "26052",
  //   label: "Cordovín (La Rioja)",
  // },
  // {
  //   municipio: "Corera",
  //   provincia: "La Rioja",
  //   id: "26053",
  //   label: "Corera (La Rioja)",
  // },
  // {
  //   municipio: "Cornago",
  //   provincia: "La Rioja",
  //   id: "26054",
  //   label: "Cornago (La Rioja)",
  // },
  // {
  //   municipio: "Corporales",
  //   provincia: "La Rioja",
  //   id: "26055",
  //   label: "Corporales (La Rioja)",
  // },
  // {
  //   municipio: "Cuzcurrita de Río Tirón",
  //   provincia: "La Rioja",
  //   id: "26056",
  //   label: "Cuzcurrita de Río Tirón (La Rioja)",
  // },
  // {
  //   municipio: "Daroca de Rioja",
  //   provincia: "La Rioja",
  //   id: "26057",
  //   label: "Daroca de Rioja (La Rioja)",
  // },
  // {
  //   municipio: "Enciso",
  //   provincia: "La Rioja",
  //   id: "26058",
  //   label: "Enciso (La Rioja)",
  // },
  // {
  //   municipio: "Entrena",
  //   provincia: "La Rioja",
  //   id: "26059",
  //   label: "Entrena (La Rioja)",
  // },
  // {
  //   municipio: "Estollo",
  //   provincia: "La Rioja",
  //   id: "26060",
  //   label: "Estollo (La Rioja)",
  // },
  // {
  //   municipio: "Foncea",
  //   provincia: "La Rioja",
  //   id: "26062",
  //   label: "Foncea (La Rioja)",
  // },
  // {
  //   municipio: "Fonzaleche",
  //   provincia: "La Rioja",
  //   id: "26063",
  //   label: "Fonzaleche (La Rioja)",
  // },
  // {
  //   municipio: "Ezcaray",
  //   provincia: "La Rioja",
  //   id: "26061",
  //   label: "Ezcaray (La Rioja)",
  // },
  // {
  //   municipio: "Galbárruli",
  //   provincia: "La Rioja",
  //   id: "26065",
  //   label: "Galbárruli (La Rioja)",
  // },
  // {
  //   municipio: "Fuenmayor",
  //   provincia: "La Rioja",
  //   id: "26064",
  //   label: "Fuenmayor (La Rioja)",
  // },
  // {
  //   municipio: "Hervías",
  //   provincia: "La Rioja",
  //   id: "26074",
  //   label: "Hervías (La Rioja)",
  // },
  // {
  //   municipio: "Galilea",
  //   provincia: "La Rioja",
  //   id: "26066",
  //   label: "Galilea (La Rioja)",
  // },
  // {
  //   municipio: "Gallinero de Cameros",
  //   provincia: "La Rioja",
  //   id: "26067",
  //   label: "Gallinero de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Gimileo",
  //   provincia: "La Rioja",
  //   id: "26068",
  //   label: "Gimileo (La Rioja)",
  // },
  // {
  //   municipio: "Herramélluri",
  //   provincia: "La Rioja",
  //   id: "26073",
  //   label: "Herramélluri (La Rioja)",
  // },
  // {
  //   municipio: "Grañón",
  //   provincia: "La Rioja",
  //   id: "26069",
  //   label: "Grañón (La Rioja)",
  // },
  // {
  //   municipio: "Grávalos",
  //   provincia: "La Rioja",
  //   id: "26070",
  //   label: "Grávalos (La Rioja)",
  // },
  // {
  //   municipio: "Haro",
  //   provincia: "La Rioja",
  //   id: "26071",
  //   label: "Haro (La Rioja)",
  // },
  // {
  //   municipio: "Herce",
  //   provincia: "La Rioja",
  //   id: "26072",
  //   label: "Herce (La Rioja)",
  // },
  // {
  //   municipio: "Huércanos",
  //   provincia: "La Rioja",
  //   id: "26079",
  //   label: "Huércanos (La Rioja)",
  // },
  // {
  //   municipio: "Igea",
  //   provincia: "La Rioja",
  //   id: "26080",
  //   label: "Igea (La Rioja)",
  // },
  // {
  //   municipio: "Laguna de Cameros",
  //   provincia: "La Rioja",
  //   id: "26082",
  //   label: "Laguna de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Lagunilla del Jubera",
  //   provincia: "La Rioja",
  //   id: "26083",
  //   label: "Lagunilla del Jubera (La Rioja)",
  // },
  // {
  //   municipio: "Lardero",
  //   provincia: "La Rioja",
  //   id: "26084",
  //   label: "Lardero (La Rioja)",
  // },
  // {
  //   municipio: "Ledesma de la Cogolla",
  //   provincia: "La Rioja",
  //   id: "26086",
  //   label: "Ledesma de la Cogolla (La Rioja)",
  // },
  // {
  //   municipio: "Muro de Aguas",
  //   provincia: "La Rioja",
  //   id: "26100",
  //   label: "Muro de Aguas (La Rioja)",
  // },
  // {
  //   municipio: "Munilla",
  //   provincia: "La Rioja",
  //   id: "26098",
  //   label: "Munilla (La Rioja)",
  // },
  // {
  //   municipio: "Murillo de Río Leza",
  //   provincia: "La Rioja",
  //   id: "26099",
  //   label: "Murillo de Río Leza (La Rioja)",
  // },
  // {
  //   municipio: "Leza de Río Leza",
  //   provincia: "La Rioja",
  //   id: "26088",
  //   label: "Leza de Río Leza (La Rioja)",
  // },
  // {
  //   municipio: "Lumbreras de Cameros",
  //   provincia: "La Rioja",
  //   id: "26091",
  //   label: "Lumbreras de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Manjarrés",
  //   provincia: "La Rioja",
  //   id: "26092",
  //   label: "Manjarrés (La Rioja)",
  // },
  // {
  //   municipio: "Logroño",
  //   provincia: "La Rioja",
  //   id: "26089",
  //   label: "Logroño (La Rioja)",
  // },
  // {
  //   municipio: "Mansilla de la Sierra",
  //   provincia: "La Rioja",
  //   id: "26093",
  //   label: "Mansilla de la Sierra (La Rioja)",
  // },
  // {
  //   municipio: "Manzanares de Rioja",
  //   provincia: "La Rioja",
  //   id: "26094",
  //   label: "Manzanares de Rioja (La Rioja)",
  // },
  // {
  //   municipio: "Matute",
  //   provincia: "La Rioja",
  //   id: "26095",
  //   label: "Matute (La Rioja)",
  // },
  // {
  //   municipio: "Medrano",
  //   provincia: "La Rioja",
  //   id: "26096",
  //   label: "Medrano (La Rioja)",
  // },
  // {
  //   municipio: "Muro en Cameros",
  //   provincia: "La Rioja",
  //   id: "26101",
  //   label: "Muro en Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Nájera",
  //   provincia: "La Rioja",
  //   id: "26102",
  //   label: "Nájera (La Rioja)",
  // },
  // {
  //   municipio: "Rabanera",
  //   provincia: "La Rioja",
  //   id: "26121",
  //   label: "Rabanera (La Rioja)",
  // },
  // {
  //   municipio: "Nalda",
  //   provincia: "La Rioja",
  //   id: "26103",
  //   label: "Nalda (La Rioja)",
  // },
  // {
  //   municipio: "Navajún",
  //   provincia: "La Rioja",
  //   id: "26104",
  //   label: "Navajún (La Rioja)",
  // },
  // {
  //   municipio: "Rasillo de Cameros, El",
  //   provincia: "La Rioja",
  //   id: "26122",
  //   label: "Rasillo de Cameros, El (La Rioja)",
  // },
  // {
  //   municipio: "Navarrete",
  //   provincia: "La Rioja",
  //   id: "26105",
  //   label: "Navarrete (La Rioja)",
  // },
  // {
  //   municipio: "Nestares",
  //   provincia: "La Rioja",
  //   id: "26106",
  //   label: "Nestares (La Rioja)",
  // },
  // {
  //   municipio: "Redal, El",
  //   provincia: "La Rioja",
  //   id: "26123",
  //   label: "Redal, El (La Rioja)",
  // },
  // {
  //   municipio: "Nieva de Cameros",
  //   provincia: "La Rioja",
  //   id: "26107",
  //   label: "Nieva de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Ojacastro",
  //   provincia: "La Rioja",
  //   id: "26110",
  //   label: "Ojacastro (La Rioja)",
  // },
  // {
  //   municipio: "Ocón",
  //   provincia: "La Rioja",
  //   id: "26108",
  //   label: "Ocón (La Rioja)",
  // },
  // {
  //   municipio: "Ochánduri",
  //   provincia: "La Rioja",
  //   id: "26109",
  //   label: "Ochánduri (La Rioja)",
  // },
  // {
  //   municipio: "Pazuengos",
  //   provincia: "La Rioja",
  //   id: "26113",
  //   label: "Pazuengos (La Rioja)",
  // },
  // {
  //   municipio: "Pedroso",
  //   provincia: "La Rioja",
  //   id: "26114",
  //   label: "Pedroso (La Rioja)",
  // },
  // {
  //   municipio: "Pinillos",
  //   provincia: "La Rioja",
  //   id: "26115",
  //   label: "Pinillos (La Rioja)",
  // },
  // {
  //   municipio: "Préjano",
  //   provincia: "La Rioja",
  //   id: "26119",
  //   label: "Préjano (La Rioja)",
  // },
  // {
  //   municipio: "Quel",
  //   provincia: "La Rioja",
  //   id: "26120",
  //   label: "Quel (La Rioja)",
  // },
  // {
  //   municipio: "Castro de Fuentidueña",
  //   provincia: "Segovia",
  //   id: "40047",
  //   label: "Castro de Fuentidueña (Segovia)",
  // },
  // {
  //   municipio: "Ribafrecha",
  //   provincia: "La Rioja",
  //   id: "26124",
  //   label: "Ribafrecha (La Rioja)",
  // },
  // {
  //   municipio: "Rincón de Soto",
  //   provincia: "La Rioja",
  //   id: "26125",
  //   label: "Rincón de Soto (La Rioja)",
  // },
  // {
  //   municipio: "Villar de Torre",
  //   provincia: "La Rioja",
  //   id: "26171",
  //   label: "Villar de Torre (La Rioja)",
  // },
  // {
  //   municipio: "Robres del Castillo",
  //   provincia: "La Rioja",
  //   id: "26126",
  //   label: "Robres del Castillo (La Rioja)",
  // },
  // {
  //   municipio: "Rodezno",
  //   provincia: "La Rioja",
  //   id: "26127",
  //   label: "Rodezno (La Rioja)",
  // },
  // {
  //   municipio: "Sajazarra",
  //   provincia: "La Rioja",
  //   id: "26128",
  //   label: "Sajazarra (La Rioja)",
  // },
  // {
  //   municipio: "San Asensio",
  //   provincia: "La Rioja",
  //   id: "26129",
  //   label: "San Asensio (La Rioja)",
  // },
  // {
  //   municipio: "Sotés",
  //   provincia: "La Rioja",
  //   id: "26145",
  //   label: "Sotés (La Rioja)",
  // },
  // {
  //   municipio: "San Millán de la Cogolla",
  //   provincia: "La Rioja",
  //   id: "26130",
  //   label: "San Millán de la Cogolla (La Rioja)",
  // },
  // {
  //   municipio: "San Millán de Yécora",
  //   provincia: "La Rioja",
  //   id: "26131",
  //   label: "San Millán de Yécora (La Rioja)",
  // },
  // {
  //   municipio: "Soto en Cameros",
  //   provincia: "La Rioja",
  //   id: "26146",
  //   label: "Soto en Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Zarzosa",
  //   provincia: "La Rioja",
  //   id: "26181",
  //   label: "Zarzosa (La Rioja)",
  // },
  // {
  //   municipio: "Fonsagrada, A",
  //   provincia: "Lugo",
  //   id: "27018",
  //   label: "Fonsagrada, A (Lugo)",
  // },
  // {
  //   municipio: "Villarejo",
  //   provincia: "La Rioja",
  //   id: "26172",
  //   label: "Villarejo (La Rioja)",
  // },
  // {
  //   municipio: "San Román de Cameros",
  //   provincia: "La Rioja",
  //   id: "26132",
  //   label: "San Román de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Santa Coloma",
  //   provincia: "La Rioja",
  //   id: "26134",
  //   label: "Santa Coloma (La Rioja)",
  // },
  // {
  //   municipio: "Santa Engracia del Jubera",
  //   provincia: "La Rioja",
  //   id: "26135",
  //   label: "Santa Engracia del Jubera (La Rioja)",
  // },
  // {
  //   municipio: "Losilla, La",
  //   provincia: "Soria",
  //   id: "42106",
  //   label: "Losilla, La (Soria)",
  // },
  // {
  //   municipio: "San Vicente de la Sonsierra",
  //   provincia: "La Rioja",
  //   id: "26142",
  //   label: "San Vicente de la Sonsierra (La Rioja)",
  // },
  // {
  //   municipio: "Villavelayo",
  //   provincia: "La Rioja",
  //   id: "26175",
  //   label: "Villavelayo (La Rioja)",
  // },
  // {
  //   municipio: "Sojuela",
  //   provincia: "La Rioja",
  //   id: "26143",
  //   label: "Sojuela (La Rioja)",
  // },
  // {
  //   municipio: "Sorzano",
  //   provincia: "La Rioja",
  //   id: "26144",
  //   label: "Sorzano (La Rioja)",
  // },
  // {
  //   municipio: "Terroba",
  //   provincia: "La Rioja",
  //   id: "26147",
  //   label: "Terroba (La Rioja)",
  // },
  // {
  //   municipio: "Tobía",
  //   provincia: "La Rioja",
  //   id: "26149",
  //   label: "Tobía (La Rioja)",
  // },
  // {
  //   municipio: "Tormantos",
  //   provincia: "La Rioja",
  //   id: "26150",
  //   label: "Tormantos (La Rioja)",
  // },
  // {
  //   municipio: "Villarroya",
  //   provincia: "La Rioja",
  //   id: "26173",
  //   label: "Villarroya (La Rioja)",
  // },
  // {
  //   municipio: "Torrecilla en Cameros",
  //   provincia: "La Rioja",
  //   id: "26151",
  //   label: "Torrecilla en Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Torrecilla sobre Alesanco",
  //   provincia: "La Rioja",
  //   id: "26152",
  //   label: "Torrecilla sobre Alesanco (La Rioja)",
  // },
  // {
  //   municipio: "Torre en Cameros",
  //   provincia: "La Rioja",
  //   id: "26153",
  //   label: "Torre en Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Treviana",
  //   provincia: "La Rioja",
  //   id: "26155",
  //   label: "Treviana (La Rioja)",
  // },
  // {
  //   municipio: "Viniegra de Arriba",
  //   provincia: "La Rioja",
  //   id: "26179",
  //   label: "Viniegra de Arriba (La Rioja)",
  // },
  // {
  //   municipio: "Tudelilla",
  //   provincia: "La Rioja",
  //   id: "26158",
  //   label: "Tudelilla (La Rioja)",
  // },
  // {
  //   municipio: "Uruñuela",
  //   provincia: "La Rioja",
  //   id: "26160",
  //   label: "Uruñuela (La Rioja)",
  // },
  // {
  //   municipio: "Valdemadera",
  //   provincia: "La Rioja",
  //   id: "26161",
  //   label: "Valdemadera (La Rioja)",
  // },
  // {
  //   municipio: "Valgañón",
  //   provincia: "La Rioja",
  //   id: "26162",
  //   label: "Valgañón (La Rioja)",
  // },
  // {
  //   municipio: "Ventosa",
  //   provincia: "La Rioja",
  //   id: "26163",
  //   label: "Ventosa (La Rioja)",
  // },
  // {
  //   municipio: "Villarta-Quintana",
  //   provincia: "La Rioja",
  //   id: "26174",
  //   label: "Villarta-Quintana (La Rioja)",
  // },
  // {
  //   municipio: "Ventrosa",
  //   provincia: "La Rioja",
  //   id: "26164",
  //   label: "Ventrosa (La Rioja)",
  // },
  // {
  //   municipio: "Villaverde de Rioja",
  //   provincia: "La Rioja",
  //   id: "26176",
  //   label: "Villaverde de Rioja (La Rioja)",
  // },
  // {
  //   municipio: "Viguera",
  //   provincia: "La Rioja",
  //   id: "26165",
  //   label: "Viguera (La Rioja)",
  // },
  // {
  //   municipio: "Villalba de Rioja",
  //   provincia: "La Rioja",
  //   id: "26166",
  //   label: "Villalba de Rioja (La Rioja)",
  // },
  // {
  //   municipio: "Villalobar de Rioja",
  //   provincia: "La Rioja",
  //   id: "26167",
  //   label: "Villalobar de Rioja (La Rioja)",
  // },
  // {
  //   municipio: "Zorraquín",
  //   provincia: "La Rioja",
  //   id: "26183",
  //   label: "Zorraquín (La Rioja)",
  // },
  // {
  //   municipio: "Abadín",
  //   provincia: "Lugo",
  //   id: "27001",
  //   label: "Abadín (Lugo)",
  // },
  // {
  //   municipio: "Villoslada de Cameros",
  //   provincia: "La Rioja",
  //   id: "26177",
  //   label: "Villoslada de Cameros (La Rioja)",
  // },
  // {
  //   municipio: "Valdemanco",
  //   provincia: "Madrid",
  //   id: "28158",
  //   label: "Valdemanco (Madrid)",
  // },
  // {
  //   municipio: "Valdemoro",
  //   provincia: "Madrid",
  //   id: "28161",
  //   label: "Valdemoro (Madrid)",
  // },
  // {
  //   municipio: "Valdeolmos-Alalpardo",
  //   provincia: "Madrid",
  //   id: "28162",
  //   label: "Valdeolmos-Alalpardo (Madrid)",
  // },
  // {
  //   municipio: "Viniegra de Abajo",
  //   provincia: "La Rioja",
  //   id: "26178",
  //   label: "Viniegra de Abajo (La Rioja)",
  // },
  // {
  //   municipio: "Alfoz",
  //   provincia: "Lugo",
  //   id: "27002",
  //   label: "Alfoz (Lugo)",
  // },
  // {
  //   municipio: "Valdilecha",
  //   provincia: "Madrid",
  //   id: "28165",
  //   label: "Valdilecha (Madrid)",
  // },
  // {
  //   municipio: "Antas de Ulla",
  //   provincia: "Lugo",
  //   id: "27003",
  //   label: "Antas de Ulla (Lugo)",
  // },
  // {
  //   municipio: "Baleira",
  //   provincia: "Lugo",
  //   id: "27004",
  //   label: "Baleira (Lugo)",
  // },
  // {
  //   municipio: "Álamo, El",
  //   provincia: "Madrid",
  //   id: "28004",
  //   label: "Álamo, El (Madrid)",
  // },
  // {
  //   municipio: "Becerreá",
  //   provincia: "Lugo",
  //   id: "27006",
  //   label: "Becerreá (Lugo)",
  // },
  // {
  //   municipio: "Bóveda",
  //   provincia: "Lugo",
  //   id: "27008",
  //   label: "Bóveda (Lugo)",
  // },
  // {
  //   municipio: "Begonte",
  //   provincia: "Lugo",
  //   id: "27007",
  //   label: "Begonte (Lugo)",
  // },
  // {
  //   municipio: "Carballedo",
  //   provincia: "Lugo",
  //   id: "27009",
  //   label: "Carballedo (Lugo)",
  // },
  // {
  //   municipio: "Castro de Rei",
  //   provincia: "Lugo",
  //   id: "27010",
  //   label: "Castro de Rei (Lugo)",
  // },
  // {
  //   municipio: "Alcorcón",
  //   provincia: "Madrid",
  //   id: "28007",
  //   label: "Alcorcón (Madrid)",
  // },
  // {
  //   municipio: "Alpedrete",
  //   provincia: "Madrid",
  //   id: "28010",
  //   label: "Alpedrete (Madrid)",
  // },
  // {
  //   municipio: "Castroverde",
  //   provincia: "Lugo",
  //   id: "27011",
  //   label: "Castroverde (Lugo)",
  // },
  // {
  //   municipio: "Folgoso do Courel",
  //   provincia: "Lugo",
  //   id: "27017",
  //   label: "Folgoso do Courel (Lugo)",
  // },
  // {
  //   municipio: "Anchuelo",
  //   provincia: "Madrid",
  //   id: "28012",
  //   label: "Anchuelo (Madrid)",
  // },
  // {
  //   municipio: "Cervantes",
  //   provincia: "Lugo",
  //   id: "27012",
  //   label: "Cervantes (Lugo)",
  // },
  // {
  //   municipio: "Corgo, O",
  //   provincia: "Lugo",
  //   id: "27014",
  //   label: "Corgo, O (Lugo)",
  // },
  // {
  //   municipio: "Cospeito",
  //   provincia: "Lugo",
  //   id: "27015",
  //   label: "Cospeito (Lugo)",
  // },
  // {
  //   municipio: "Chantada",
  //   provincia: "Lugo",
  //   id: "27016",
  //   label: "Chantada (Lugo)",
  // },
  // {
  //   municipio: "Friol",
  //   provincia: "Lugo",
  //   id: "27020",
  //   label: "Friol (Lugo)",
  // },
  // {
  //   municipio: "Xermade",
  //   provincia: "Lugo",
  //   id: "27021",
  //   label: "Xermade (Lugo)",
  // },
  // {
  //   municipio: "Guitiriz",
  //   provincia: "Lugo",
  //   id: "27022",
  //   label: "Guitiriz (Lugo)",
  // },
  // {
  //   municipio: "Pantón",
  //   provincia: "Lugo",
  //   id: "27041",
  //   label: "Pantón (Lugo)",
  // },
  // {
  //   municipio: "Belmonte de Tajo",
  //   provincia: "Madrid",
  //   id: "28019",
  //   label: "Belmonte de Tajo (Madrid)",
  // },
  // {
  //   municipio: "Berzosa del Lozoya",
  //   provincia: "Madrid",
  //   id: "28020",
  //   label: "Berzosa del Lozoya (Madrid)",
  // },
  // {
  //   municipio: "Berrueco, El",
  //   provincia: "Madrid",
  //   id: "28021",
  //   label: "Berrueco, El (Madrid)",
  // },
  // {
  //   municipio: "Guntín",
  //   provincia: "Lugo",
  //   id: "27023",
  //   label: "Guntín (Lugo)",
  // },
  // {
  //   municipio: "Pontenova, A",
  //   provincia: "Lugo",
  //   id: "27048",
  //   label: "Pontenova, A (Lugo)",
  // },
  // {
  //   municipio: "Boalo, El",
  //   provincia: "Madrid",
  //   id: "28023",
  //   label: "Boalo, El (Madrid)",
  // },
  // {
  //   municipio: "Incio, O",
  //   provincia: "Lugo",
  //   id: "27024",
  //   label: "Incio, O (Lugo)",
  // },
  // {
  //   municipio: "Bola, A",
  //   provincia: "Orense",
  //   id: "32014",
  //   label: "Bola, A (Orense)",
  // },
  // {
  //   municipio: "Láncara",
  //   provincia: "Lugo",
  //   id: "27026",
  //   label: "Láncara (Lugo)",
  // },
  // {
  //   municipio: "Triacastela",
  //   provincia: "Lugo",
  //   id: "27062",
  //   label: "Triacastela (Lugo)",
  // },
  // {
  //   municipio: "Lourenzá",
  //   provincia: "Lugo",
  //   id: "27027",
  //   label: "Lourenzá (Lugo)",
  // },
  // {
  //   municipio: "Lugo",
  //   provincia: "Lugo",
  //   id: "27028",
  //   label: "Lugo (Lugo)",
  // },
  // {
  //   municipio: "Sarreaus",
  //   provincia: "Orense",
  //   id: "32078",
  //   label: "Sarreaus (Orense)",
  // },
  // {
  //   municipio: "Meira",
  //   provincia: "Lugo",
  //   id: "27029",
  //   label: "Meira (Lugo)",
  // },
  // {
  //   municipio: "Mondoñedo",
  //   provincia: "Lugo",
  //   id: "27030",
  //   label: "Mondoñedo (Lugo)",
  // },
  // {
  //   municipio: "Monforte de Lemos",
  //   provincia: "Lugo",
  //   id: "27031",
  //   label: "Monforte de Lemos (Lugo)",
  // },
  // {
  //   municipio: "Monterroso",
  //   provincia: "Lugo",
  //   id: "27032",
  //   label: "Monterroso (Lugo)",
  // },
  // {
  //   municipio: "Navia de Suarna",
  //   provincia: "Lugo",
  //   id: "27034",
  //   label: "Navia de Suarna (Lugo)",
  // },
  // {
  //   municipio: "Taboadela",
  //   provincia: "Orense",
  //   id: "32079",
  //   label: "Taboadela (Orense)",
  // },
  // {
  //   municipio: "Negueira de Muñiz",
  //   provincia: "Lugo",
  //   id: "27035",
  //   label: "Negueira de Muñiz (Lugo)",
  // },
  // {
  //   municipio: "Nogais, As",
  //   provincia: "Lugo",
  //   id: "27037",
  //   label: "Nogais, As (Lugo)",
  // },
  // {
  //   municipio: "Ourol",
  //   provincia: "Lugo",
  //   id: "27038",
  //   label: "Ourol (Lugo)",
  // },
  // {
  //   municipio: "Dehesa de Romanos",
  //   provincia: "Palencia",
  //   id: "34068",
  //   label: "Dehesa de Romanos (Palencia)",
  // },
  // {
  //   municipio: "Outeiro de Rei",
  //   provincia: "Lugo",
  //   id: "27039",
  //   label: "Outeiro de Rei (Lugo)",
  // },
  // {
  //   municipio: "Boadilla de Rioseco",
  //   provincia: "Palencia",
  //   id: "34035",
  //   label: "Boadilla de Rioseco (Palencia)",
  // },
  // {
  //   municipio: "Palas de Rei",
  //   provincia: "Lugo",
  //   id: "27040",
  //   label: "Palas de Rei (Lugo)",
  // },
  // {
  //   municipio: "Paradela",
  //   provincia: "Lugo",
  //   id: "27042",
  //   label: "Paradela (Lugo)",
  // },
  // {
  //   municipio: "Santibáñez de Ecla",
  //   provincia: "Palencia",
  //   id: "34170",
  //   label: "Santibáñez de Ecla (Palencia)",
  // },
  // {
  //   municipio: "Páramo, O",
  //   provincia: "Lugo",
  //   id: "27043",
  //   label: "Páramo, O (Lugo)",
  // },
  // {
  //   municipio: "Pastoriza, A",
  //   provincia: "Lugo",
  //   id: "27044",
  //   label: "Pastoriza, A (Lugo)",
  // },
  // {
  //   municipio: "Serna, La",
  //   provincia: "Palencia",
  //   id: "34175",
  //   label: "Serna, La (Palencia)",
  // },
  // {
  //   municipio: "Pedrafita do Cebreiro",
  //   provincia: "Lugo",
  //   id: "27045",
  //   label: "Pedrafita do Cebreiro (Lugo)",
  // },
  // {
  //   municipio: "Pol",
  //   provincia: "Lugo",
  //   id: "27046",
  //   label: "Pol (Lugo)",
  // },
  // {
  //   municipio: "Baralla",
  //   provincia: "Lugo",
  //   id: "27901",
  //   label: "Baralla (Lugo)",
  // },
  // {
  //   municipio: "Burela",
  //   provincia: "Lugo",
  //   id: "27902",
  //   label: "Burela (Lugo)",
  // },
  // {
  //   municipio: "Pobra do Brollón, A",
  //   provincia: "Lugo",
  //   id: "27047",
  //   label: "Pobra do Brollón, A (Lugo)",
  // },
  // {
  //   municipio: "Portomarín",
  //   provincia: "Lugo",
  //   id: "27049",
  //   label: "Portomarín (Lugo)",
  // },
  // {
  //   municipio: "Quiroga",
  //   provincia: "Lugo",
  //   id: "27050",
  //   label: "Quiroga (Lugo)",
  // },
  // {
  //   municipio: "Acebeda, La",
  //   provincia: "Madrid",
  //   id: "28001",
  //   label: "Acebeda, La (Madrid)",
  // },
  // {
  //   municipio: "Ajalvir",
  //   provincia: "Madrid",
  //   id: "28002",
  //   label: "Ajalvir (Madrid)",
  // },
  // {
  //   municipio: "Alameda del Valle",
  //   provincia: "Madrid",
  //   id: "28003",
  //   label: "Alameda del Valle (Madrid)",
  // },
  // {
  //   municipio: "Ribas de Sil",
  //   provincia: "Lugo",
  //   id: "27052",
  //   label: "Ribas de Sil (Lugo)",
  // },
  // {
  //   municipio: "Calahorra de Boedo",
  //   provincia: "Palencia",
  //   id: "34041",
  //   label: "Calahorra de Boedo (Palencia)",
  // },
  // {
  //   municipio: "Ribeira de Piquín",
  //   provincia: "Lugo",
  //   id: "27053",
  //   label: "Ribeira de Piquín (Lugo)",
  // },
  // {
  //   municipio: "Riotorto",
  //   provincia: "Lugo",
  //   id: "27054",
  //   label: "Riotorto (Lugo)",
  // },
  // {
  //   municipio: "Samos",
  //   provincia: "Lugo",
  //   id: "27055",
  //   label: "Samos (Lugo)",
  // },
  // {
  //   municipio: "Rábade",
  //   provincia: "Lugo",
  //   id: "27056",
  //   label: "Rábade (Lugo)",
  // },
  // {
  //   municipio: "Sarria",
  //   provincia: "Lugo",
  //   id: "27057",
  //   label: "Sarria (Lugo)",
  // },
  // {
  //   municipio: "Saviñao, O",
  //   provincia: "Lugo",
  //   id: "27058",
  //   label: "Saviñao, O (Lugo)",
  // },
  // {
  //   municipio: "Támara de Campos",
  //   provincia: "Palencia",
  //   id: "34180",
  //   label: "Támara de Campos (Palencia)",
  // },
  // {
  //   municipio: "Sober",
  //   provincia: "Lugo",
  //   id: "27059",
  //   label: "Sober (Lugo)",
  // },
  // {
  //   municipio: "Taboada",
  //   provincia: "Lugo",
  //   id: "27060",
  //   label: "Taboada (Lugo)",
  // },
  // {
  //   municipio: "Trabada",
  //   provincia: "Lugo",
  //   id: "27061",
  //   label: "Trabada (Lugo)",
  // },
  // {
  //   municipio: "Valadouro, O",
  //   provincia: "Lugo",
  //   id: "27063",
  //   label: "Valadouro, O (Lugo)",
  // },
  // {
  //   municipio: "Arganda del Rey",
  //   provincia: "Madrid",
  //   id: "28014",
  //   label: "Arganda del Rey (Madrid)",
  // },
  // {
  //   municipio: "Valverde de Alcalá",
  //   provincia: "Madrid",
  //   id: "28166",
  //   label: "Valverde de Alcalá (Madrid)",
  // },
  // {
  //   municipio: "Becerril de la Sierra",
  //   provincia: "Madrid",
  //   id: "28018",
  //   label: "Becerril de la Sierra (Madrid)",
  // },
  // {
  //   municipio: "Boadilla del Monte",
  //   provincia: "Madrid",
  //   id: "28022",
  //   label: "Boadilla del Monte (Madrid)",
  // },
  // {
  //   municipio: "Braojos",
  //   provincia: "Madrid",
  //   id: "28024",
  //   label: "Braojos (Madrid)",
  // },
  // {
  //   municipio: "Casarrubuelos",
  //   provincia: "Madrid",
  //   id: "28036",
  //   label: "Casarrubuelos (Madrid)",
  // },
  // {
  //   municipio: "Brea de Tajo",
  //   provincia: "Madrid",
  //   id: "28025",
  //   label: "Brea de Tajo (Madrid)",
  // },
  // {
  //   municipio: "Brunete",
  //   provincia: "Madrid",
  //   id: "28026",
  //   label: "Brunete (Madrid)",
  // },
  // {
  //   municipio: "Cenicientos",
  //   provincia: "Madrid",
  //   id: "28037",
  //   label: "Cenicientos (Madrid)",
  // },
  // {
  //   municipio: "Calzada de los Molinos",
  //   provincia: "Palencia",
  //   id: "34042",
  //   label: "Calzada de los Molinos (Palencia)",
  // },
  // {
  //   municipio: "Cabanillas de la Sierra",
  //   provincia: "Madrid",
  //   id: "28029",
  //   label: "Cabanillas de la Sierra (Madrid)",
  // },
  // {
  //   municipio: "Cabrera, La",
  //   provincia: "Madrid",
  //   id: "28030",
  //   label: "Cabrera, La (Madrid)",
  // },
  // {
  //   municipio: "Cadalso de los Vidrios",
  //   provincia: "Madrid",
  //   id: "28031",
  //   label: "Cadalso de los Vidrios (Madrid)",
  // },
  // {
  //   municipio: "Camarma de Esteruelas",
  //   provincia: "Madrid",
  //   id: "28032",
  //   label: "Camarma de Esteruelas (Madrid)",
  // },
  // {
  //   municipio: "Corpa",
  //   provincia: "Madrid",
  //   id: "28048",
  //   label: "Corpa (Madrid)",
  // },
  // {
  //   municipio: "Campo Real",
  //   provincia: "Madrid",
  //   id: "28033",
  //   label: "Campo Real (Madrid)",
  // },
  // {
  //   municipio: "Canencia",
  //   provincia: "Madrid",
  //   id: "28034",
  //   label: "Canencia (Madrid)",
  // },
  // {
  //   municipio: "Carabaña",
  //   provincia: "Madrid",
  //   id: "28035",
  //   label: "Carabaña (Madrid)",
  // },
  // {
  //   municipio: "Cercedilla",
  //   provincia: "Madrid",
  //   id: "28038",
  //   label: "Cercedilla (Madrid)",
  // },
  // {
  //   municipio: "Cervera de Buitrago",
  //   provincia: "Madrid",
  //   id: "28039",
  //   label: "Cervera de Buitrago (Madrid)",
  // },
  // {
  //   municipio: "Cobeña",
  //   provincia: "Madrid",
  //   id: "28041",
  //   label: "Cobeña (Madrid)",
  // },
  // {
  //   municipio: "Orusco de Tajuña",
  //   provincia: "Madrid",
  //   id: "28102",
  //   label: "Orusco de Tajuña (Madrid)",
  // },
  // {
  //   municipio: "Paracuellos de Jarama",
  //   provincia: "Madrid",
  //   id: "28104",
  //   label: "Paracuellos de Jarama (Madrid)",
  // },
  // {
  //   municipio: "Parla",
  //   provincia: "Madrid",
  //   id: "28106",
  //   label: "Parla (Madrid)",
  // },
  // {
  //   municipio: "Pedrezuela",
  //   provincia: "Madrid",
  //   id: "28108",
  //   label: "Pedrezuela (Madrid)",
  // },
  // {
  //   municipio: "Collado Mediano",
  //   provincia: "Madrid",
  //   id: "28046",
  //   label: "Collado Mediano (Madrid)",
  // },
  // {
  //   municipio: "Collado Villalba",
  //   provincia: "Madrid",
  //   id: "28047",
  //   label: "Collado Villalba (Madrid)",
  // },
  // {
  //   municipio: "Pelayos de la Presa",
  //   provincia: "Madrid",
  //   id: "28109",
  //   label: "Pelayos de la Presa (Madrid)",
  // },
  // {
  //   municipio: "Coslada",
  //   provincia: "Madrid",
  //   id: "28049",
  //   label: "Coslada (Madrid)",
  // },
  // {
  //   municipio: "San Fernando de Henares",
  //   provincia: "Madrid",
  //   id: "28130",
  //   label: "San Fernando de Henares (Madrid)",
  // },
  // {
  //   municipio: "San Lorenzo de El Escorial",
  //   provincia: "Madrid",
  //   id: "28131",
  //   label: "San Lorenzo de El Escorial (Madrid)",
  // },
  // {
  //   municipio: "San Martín de la Vega",
  //   provincia: "Madrid",
  //   id: "28132",
  //   label: "San Martín de la Vega (Madrid)",
  // },
  // {
  //   municipio: "Cubas de la Sagra",
  //   provincia: "Madrid",
  //   id: "28050",
  //   label: "Cubas de la Sagra (Madrid)",
  // },
  // {
  //   municipio: "Chapinería",
  //   provincia: "Madrid",
  //   id: "28051",
  //   label: "Chapinería (Madrid)",
  // },
  // {
  //   municipio: "Daganzo de Arriba",
  //   provincia: "Madrid",
  //   id: "28053",
  //   label: "Daganzo de Arriba (Madrid)",
  // },
  // {
  //   municipio: "Fresnedillas de la Oliva",
  //   provincia: "Madrid",
  //   id: "28056",
  //   label: "Fresnedillas de la Oliva (Madrid)",
  // },
  // {
  //   municipio: "Fresno de Torote",
  //   provincia: "Madrid",
  //   id: "28057",
  //   label: "Fresno de Torote (Madrid)",
  // },
  // {
  //   municipio: "Fuente el Saz de Jarama",
  //   provincia: "Madrid",
  //   id: "28059",
  //   label: "Fuente el Saz de Jarama (Madrid)",
  // },
  // {
  //   municipio: "Fuentidueña de Tajo",
  //   provincia: "Madrid",
  //   id: "28060",
  //   label: "Fuentidueña de Tajo (Madrid)",
  // },
  // {
  //   municipio: "Garganta de los Montes",
  //   provincia: "Madrid",
  //   id: "28062",
  //   label: "Garganta de los Montes (Madrid)",
  // },
  // {
  //   municipio: "Gascones",
  //   provincia: "Madrid",
  //   id: "28064",
  //   label: "Gascones (Madrid)",
  // },
  // {
  //   municipio: "Griñón",
  //   provincia: "Madrid",
  //   id: "28066",
  //   label: "Griñón (Madrid)",
  // },
  // {
  //   municipio: "Guadalix de la Sierra",
  //   provincia: "Madrid",
  //   id: "28067",
  //   label: "Guadalix de la Sierra (Madrid)",
  // },
  // {
  //   municipio: "Guadarrama",
  //   provincia: "Madrid",
  //   id: "28068",
  //   label: "Guadarrama (Madrid)",
  // },
  // {
  //   municipio: "Horcajo de la Sierra-Aoslos",
  //   provincia: "Madrid",
  //   id: "28070",
  //   label: "Horcajo de la Sierra-Aoslos (Madrid)",
  // },
  // {
  //   municipio: "Horcajuelo de la Sierra",
  //   provincia: "Madrid",
  //   id: "28071",
  //   label: "Horcajuelo de la Sierra (Madrid)",
  // },
  // {
  //   municipio: "Villalaco",
  //   provincia: "Palencia",
  //   id: "34213",
  //   label: "Villalaco (Palencia)",
  // },
  // {
  //   municipio: "Hoyo de Manzanares",
  //   provincia: "Madrid",
  //   id: "28072",
  //   label: "Hoyo de Manzanares (Madrid)",
  // },
  // {
  //   municipio: "Venturada",
  //   provincia: "Madrid",
  //   id: "28169",
  //   label: "Venturada (Madrid)",
  // },
  // {
  //   municipio: "Humanes de Madrid",
  //   provincia: "Madrid",
  //   id: "28073",
  //   label: "Humanes de Madrid (Madrid)",
  // },
  // {
  //   municipio: "Arriate",
  //   provincia: "Málaga",
  //   id: "29020",
  //   label: "Arriate (Málaga)",
  // },
  // {
  //   municipio: "Leganés",
  //   provincia: "Madrid",
  //   id: "28074",
  //   label: "Leganés (Madrid)",
  // },
  // {
  //   municipio: "Loeches",
  //   provincia: "Madrid",
  //   id: "28075",
  //   label: "Loeches (Madrid)",
  // },
  // {
  //   municipio: "Lozoya",
  //   provincia: "Madrid",
  //   id: "28076",
  //   label: "Lozoya (Madrid)",
  // },
  // {
  //   municipio: "Madarcos",
  //   provincia: "Madrid",
  //   id: "28078",
  //   label: "Madarcos (Madrid)",
  // },
  // {
  //   municipio: "Manzanares el Real",
  //   provincia: "Madrid",
  //   id: "28082",
  //   label: "Manzanares el Real (Madrid)",
  // },
  // {
  //   municipio: "Meco",
  //   provincia: "Madrid",
  //   id: "28083",
  //   label: "Meco (Madrid)",
  // },
  // {
  //   municipio: "Valbuena de Pisuerga",
  //   provincia: "Palencia",
  //   id: "34186",
  //   label: "Valbuena de Pisuerga (Palencia)",
  // },
  // {
  //   municipio: "Mejorada del Campo",
  //   provincia: "Madrid",
  //   id: "28084",
  //   label: "Mejorada del Campo (Madrid)",
  // },
  // {
  //   municipio: "Molinos, Los",
  //   provincia: "Madrid",
  //   id: "28087",
  //   label: "Molinos, Los (Madrid)",
  // },
  // {
  //   municipio: "Moralzarzal",
  //   provincia: "Madrid",
  //   id: "28090",
  //   label: "Moralzarzal (Madrid)",
  // },
  // {
  //   municipio: "Morata de Tajuña",
  //   provincia: "Madrid",
  //   id: "28091",
  //   label: "Morata de Tajuña (Madrid)",
  // },
  // {
  //   municipio: "Santorcaz",
  //   provincia: "Madrid",
  //   id: "28136",
  //   label: "Santorcaz (Madrid)",
  // },
  // {
  //   municipio: "Navacerrada",
  //   provincia: "Madrid",
  //   id: "28093",
  //   label: "Navacerrada (Madrid)",
  // },
  // {
  //   municipio: "Santos de la Humosa, Los",
  //   provincia: "Madrid",
  //   id: "28137",
  //   label: "Santos de la Humosa, Los (Madrid)",
  // },
  // {
  //   municipio: "Serna del Monte, La",
  //   provincia: "Madrid",
  //   id: "28138",
  //   label: "Serna del Monte, La (Madrid)",
  // },
  // {
  //   municipio: "Navalafuente",
  //   provincia: "Madrid",
  //   id: "28094",
  //   label: "Navalafuente (Madrid)",
  // },
  // {
  //   municipio: "Navalcarnero",
  //   provincia: "Madrid",
  //   id: "28096",
  //   label: "Navalcarnero (Madrid)",
  // },
  // {
  //   municipio: "Itero de la Vega",
  //   provincia: "Palencia",
  //   id: "34089",
  //   label: "Itero de la Vega (Palencia)",
  // },
  // {
  //   municipio: "Navarredonda y San Mamés",
  //   provincia: "Madrid",
  //   id: "28097",
  //   label: "Navarredonda y San Mamés (Madrid)",
  // },
  // {
  //   municipio: "Navas del Rey",
  //   provincia: "Madrid",
  //   id: "28099",
  //   label: "Navas del Rey (Madrid)",
  // },
  // {
  //   municipio: "Piña de Campos",
  //   provincia: "Palencia",
  //   id: "34130",
  //   label: "Piña de Campos (Palencia)",
  // },
  // {
  //   municipio: "Nuevo Baztán",
  //   provincia: "Madrid",
  //   id: "28100",
  //   label: "Nuevo Baztán (Madrid)",
  // },
  // {
  //   municipio: "Marcilla de Campos",
  //   provincia: "Palencia",
  //   id: "34101",
  //   label: "Marcilla de Campos (Palencia)",
  // },
  // {
  //   municipio: "Olmeda de las Fuentes",
  //   provincia: "Madrid",
  //   id: "28101",
  //   label: "Olmeda de las Fuentes (Madrid)",
  // },
  // {
  //   municipio: "Perales de Tajuña",
  //   provincia: "Madrid",
  //   id: "28110",
  //   label: "Perales de Tajuña (Madrid)",
  // },
  // {
  //   municipio: "Sauquillo de Cabezas",
  //   provincia: "Segovia",
  //   id: "40192",
  //   label: "Sauquillo de Cabezas (Segovia)",
  // },
  // {
  //   municipio: "Pezuela de las Torres",
  //   provincia: "Madrid",
  //   id: "28111",
  //   label: "Pezuela de las Torres (Madrid)",
  // },
  // {
  //   municipio: "Mazariegos",
  //   provincia: "Palencia",
  //   id: "34102",
  //   label: "Mazariegos (Palencia)",
  // },
  // {
  //   municipio: "Pinilla del Valle",
  //   provincia: "Madrid",
  //   id: "28112",
  //   label: "Pinilla del Valle (Madrid)",
  // },
  // {
  //   municipio: "Pozuelo de Alarcón",
  //   provincia: "Madrid",
  //   id: "28115",
  //   label: "Pozuelo de Alarcón (Madrid)",
  // },
  // {
  //   municipio: "Pozuelo del Rey",
  //   provincia: "Madrid",
  //   id: "28116",
  //   label: "Pozuelo del Rey (Madrid)",
  // },
  // {
  //   municipio: "Prádena del Rincón",
  //   provincia: "Madrid",
  //   id: "28117",
  //   label: "Prádena del Rincón (Madrid)",
  // },
  // {
  //   municipio: "Quijorna",
  //   provincia: "Madrid",
  //   id: "28119",
  //   label: "Quijorna (Madrid)",
  // },
  // {
  //   municipio: "Redueña",
  //   provincia: "Madrid",
  //   id: "28121",
  //   label: "Redueña (Madrid)",
  // },
  // {
  //   municipio: "Ribatejada",
  //   provincia: "Madrid",
  //   id: "28122",
  //   label: "Ribatejada (Madrid)",
  // },
  // {
  //   municipio: "Rivas-Vaciamadrid",
  //   provincia: "Madrid",
  //   id: "28123",
  //   label: "Rivas-Vaciamadrid (Madrid)",
  // },
  // {
  //   municipio: "Robregordo",
  //   provincia: "Madrid",
  //   id: "28126",
  //   label: "Robregordo (Madrid)",
  // },
  // {
  //   municipio: "Rozas de Puerto Real",
  //   provincia: "Madrid",
  //   id: "28128",
  //   label: "Rozas de Puerto Real (Madrid)",
  // },
  // {
  //   municipio: "Sevilla la Nueva",
  //   provincia: "Madrid",
  //   id: "28141",
  //   label: "Sevilla la Nueva (Madrid)",
  // },
  // {
  //   municipio: "Mazuecos de Valdeginate",
  //   provincia: "Palencia",
  //   id: "34103",
  //   label: "Mazuecos de Valdeginate (Palencia)",
  // },
  // {
  //   municipio: "Somosierra",
  //   provincia: "Madrid",
  //   id: "28143",
  //   label: "Somosierra (Madrid)",
  // },
  // {
  //   municipio: "Fuentes de Béjar",
  //   provincia: "Salamanca",
  //   id: "37139",
  //   label: "Fuentes de Béjar (Salamanca)",
  // },
  // {
  //   municipio: "Soto del Real",
  //   provincia: "Madrid",
  //   id: "28144",
  //   label: "Soto del Real (Madrid)",
  // },
  // {
  //   municipio: "Tielmes",
  //   provincia: "Madrid",
  //   id: "28146",
  //   label: "Tielmes (Madrid)",
  // },
  // {
  //   municipio: "Titulcia",
  //   provincia: "Madrid",
  //   id: "28147",
  //   label: "Titulcia (Madrid)",
  // },
  // {
  //   municipio: "Valdaracete",
  //   provincia: "Madrid",
  //   id: "28155",
  //   label: "Valdaracete (Madrid)",
  // },
  // {
  //   municipio: "Torrejón de Ardoz",
  //   provincia: "Madrid",
  //   id: "28148",
  //   label: "Torrejón de Ardoz (Madrid)",
  // },
  // {
  //   municipio: "Torrejón de la Calzada",
  //   provincia: "Madrid",
  //   id: "28149",
  //   label: "Torrejón de la Calzada (Madrid)",
  // },
  // {
  //   municipio: "Torrejón de Velasco",
  //   provincia: "Madrid",
  //   id: "28150",
  //   label: "Torrejón de Velasco (Madrid)",
  // },
  // {
  //   municipio: "Torrelaguna",
  //   provincia: "Madrid",
  //   id: "28151",
  //   label: "Torrelaguna (Madrid)",
  // },
  // {
  //   municipio: "Torrelodones",
  //   provincia: "Madrid",
  //   id: "28152",
  //   label: "Torrelodones (Madrid)",
  // },
  // {
  //   municipio: "Torres de la Alameda",
  //   provincia: "Madrid",
  //   id: "28154",
  //   label: "Torres de la Alameda (Madrid)",
  // },
  // {
  //   municipio: "Valdeavero",
  //   provincia: "Madrid",
  //   id: "28156",
  //   label: "Valdeavero (Madrid)",
  // },
  // {
  //   municipio: "Valdelaguna",
  //   provincia: "Madrid",
  //   id: "28157",
  //   label: "Valdelaguna (Madrid)",
  // },
  // {
  //   municipio: "Valdepiélagos",
  //   provincia: "Madrid",
  //   id: "28163",
  //   label: "Valdepiélagos (Madrid)",
  // },
  // {
  //   municipio: "Valdetorres de Jarama",
  //   provincia: "Madrid",
  //   id: "28164",
  //   label: "Valdetorres de Jarama (Madrid)",
  // },
  // {
  //   municipio: "Velilla de San Antonio",
  //   provincia: "Madrid",
  //   id: "28167",
  //   label: "Velilla de San Antonio (Madrid)",
  // },
  // {
  //   municipio: "Guaro",
  //   provincia: "Málaga",
  //   id: "29058",
  //   label: "Guaro (Málaga)",
  // },
  // {
  //   municipio: "Vellón, El",
  //   provincia: "Madrid",
  //   id: "28168",
  //   label: "Vellón, El (Madrid)",
  // },
  // {
  //   municipio: "Villaconejos",
  //   provincia: "Madrid",
  //   id: "28170",
  //   label: "Villaconejos (Madrid)",
  // },
  // {
  //   municipio: "Villa del Prado",
  //   provincia: "Madrid",
  //   id: "28171",
  //   label: "Villa del Prado (Madrid)",
  // },
  // {
  //   municipio: "Villamanrique de Tajo",
  //   provincia: "Madrid",
  //   id: "28173",
  //   label: "Villamanrique de Tajo (Madrid)",
  // },
  // {
  //   municipio: "Villamantilla",
  //   provincia: "Madrid",
  //   id: "28175",
  //   label: "Villamantilla (Madrid)",
  // },
  // {
  //   municipio: "Villar del Olmo",
  //   provincia: "Madrid",
  //   id: "28179",
  //   label: "Villar del Olmo (Madrid)",
  // },
  // {
  //   municipio: "Jimera de Líbar",
  //   provincia: "Málaga",
  //   id: "29063",
  //   label: "Jimera de Líbar (Málaga)",
  // },
  // {
  //   municipio: "Villarejo de Salvanés",
  //   provincia: "Madrid",
  //   id: "28180",
  //   label: "Villarejo de Salvanés (Madrid)",
  // },
  // {
  //   municipio: "Villavieja del Lozoya",
  //   provincia: "Madrid",
  //   id: "28182",
  //   label: "Villavieja del Lozoya (Madrid)",
  // },
  // {
  //   municipio: "Zarzalejo",
  //   provincia: "Madrid",
  //   id: "28183",
  //   label: "Zarzalejo (Madrid)",
  // },
  // {
  //   municipio: "Rebollo",
  //   provincia: "Segovia",
  //   id: "40165",
  //   label: "Rebollo (Segovia)",
  // },
  // {
  //   municipio: "Lozoyuela-Navas-Sieteiglesias",
  //   provincia: "Madrid",
  //   id: "28901",
  //   label: "Lozoyuela-Navas-Sieteiglesias (Madrid)",
  // },
  // {
  //   municipio: "Remondo",
  //   provincia: "Segovia",
  //   id: "40166",
  //   label: "Remondo (Segovia)",
  // },
  // {
  //   municipio: "Alameda",
  //   provincia: "Málaga",
  //   id: "29001",
  //   label: "Alameda (Málaga)",
  // },
  // {
  //   municipio: "Macharaviaya",
  //   provincia: "Málaga",
  //   id: "29066",
  //   label: "Macharaviaya (Málaga)",
  // },
  // {
  //   municipio: "Alcaucín",
  //   provincia: "Málaga",
  //   id: "29002",
  //   label: "Alcaucín (Málaga)",
  // },
  // {
  //   municipio: "Roda de Eresma",
  //   provincia: "Segovia",
  //   id: "40173",
  //   label: "Roda de Eresma (Segovia)",
  // },
  // {
  //   municipio: "Alfarnate",
  //   provincia: "Málaga",
  //   id: "29003",
  //   label: "Alfarnate (Málaga)",
  // },
  // {
  //   municipio: "Alfarnatejo",
  //   provincia: "Málaga",
  //   id: "29004",
  //   label: "Alfarnatejo (Málaga)",
  // },
  // {
  //   municipio: "Valdelagua del Cerro",
  //   provincia: "Soria",
  //   id: "42193",
  //   label: "Valdelagua del Cerro (Soria)",
  // },
  // {
  //   municipio: "Algarrobo",
  //   provincia: "Málaga",
  //   id: "29005",
  //   label: "Algarrobo (Málaga)",
  // },
  // {
  //   municipio: "Algatocín",
  //   provincia: "Málaga",
  //   id: "29006",
  //   label: "Algatocín (Málaga)",
  // },
  // {
  //   municipio: "Alhaurín de la Torre",
  //   provincia: "Málaga",
  //   id: "29007",
  //   label: "Alhaurín de la Torre (Málaga)",
  // },
  // {
  //   municipio: "Melgar de Yuso",
  //   provincia: "Palencia",
  //   id: "34104",
  //   label: "Melgar de Yuso (Palencia)",
  // },
  // {
  //   municipio: "Alhaurín el Grande",
  //   provincia: "Málaga",
  //   id: "29008",
  //   label: "Alhaurín el Grande (Málaga)",
  // },
  // {
  //   municipio: "Mudá",
  //   provincia: "Palencia",
  //   id: "34110",
  //   label: "Mudá (Palencia)",
  // },
  // {
  //   municipio: "Almáchar",
  //   provincia: "Málaga",
  //   id: "29009",
  //   label: "Almáchar (Málaga)",
  // },
  // {
  //   municipio: "Almargen",
  //   provincia: "Málaga",
  //   id: "29010",
  //   label: "Almargen (Málaga)",
  // },
  // {
  //   municipio: "Almogía",
  //   provincia: "Málaga",
  //   id: "29011",
  //   label: "Almogía (Málaga)",
  // },
  // {
  //   municipio: "Álora",
  //   provincia: "Málaga",
  //   id: "29012",
  //   label: "Álora (Málaga)",
  // },
  // {
  //   municipio: "Alozaina",
  //   provincia: "Málaga",
  //   id: "29013",
  //   label: "Alozaina (Málaga)",
  // },
  // {
  //   municipio: "Atajate",
  //   provincia: "Málaga",
  //   id: "29021",
  //   label: "Atajate (Málaga)",
  // },
  // {
  //   municipio: "Alpandeire",
  //   provincia: "Málaga",
  //   id: "29014",
  //   label: "Alpandeire (Málaga)",
  // },
  // {
  //   municipio: "Espinosa de Villagonzalo",
  //   provincia: "Palencia",
  //   id: "34071",
  //   label: "Espinosa de Villagonzalo (Palencia)",
  // },
  // {
  //   municipio: "Árchez",
  //   provincia: "Málaga",
  //   id: "29016",
  //   label: "Árchez (Málaga)",
  // },
  // {
  //   municipio: "Ardales",
  //   provincia: "Málaga",
  //   id: "29018",
  //   label: "Ardales (Málaga)",
  // },
  // {
  //   municipio: "Benaoján",
  //   provincia: "Málaga",
  //   id: "29028",
  //   label: "Benaoján (Málaga)",
  // },
  // {
  //   municipio: "Arenas",
  //   provincia: "Málaga",
  //   id: "29019",
  //   label: "Arenas (Málaga)",
  // },
  // {
  //   municipio: "Benadalid",
  //   provincia: "Málaga",
  //   id: "29022",
  //   label: "Benadalid (Málaga)",
  // },
  // {
  //   municipio: "Carratraca",
  //   provincia: "Málaga",
  //   id: "29036",
  //   label: "Carratraca (Málaga)",
  // },
  // {
  //   municipio: "Población de Arroyo",
  //   provincia: "Palencia",
  //   id: "34131",
  //   label: "Población de Arroyo (Palencia)",
  // },
  // {
  //   municipio: "Benahavís",
  //   provincia: "Málaga",
  //   id: "29023",
  //   label: "Benahavís (Málaga)",
  // },
  // {
  //   municipio: "Frechilla",
  //   provincia: "Palencia",
  //   id: "34072",
  //   label: "Frechilla (Palencia)",
  // },
  // {
  //   municipio: "Benalauría",
  //   provincia: "Málaga",
  //   id: "29024",
  //   label: "Benalauría (Málaga)",
  // },
  // {
  //   municipio: "Benalmádena",
  //   provincia: "Málaga",
  //   id: "29025",
  //   label: "Benalmádena (Málaga)",
  // },
  // {
  //   municipio: "Benamargosa",
  //   provincia: "Málaga",
  //   id: "29026",
  //   label: "Benamargosa (Málaga)",
  // },
  // {
  //   municipio: "Benamocarra",
  //   provincia: "Málaga",
  //   id: "29027",
  //   label: "Benamocarra (Málaga)",
  // },
  // {
  //   municipio: "Benarrabá",
  //   provincia: "Málaga",
  //   id: "29029",
  //   label: "Benarrabá (Málaga)",
  // },
  // {
  //   municipio: "Borge, El",
  //   provincia: "Málaga",
  //   id: "29030",
  //   label: "Borge, El (Málaga)",
  // },
  // {
  //   municipio: "Campillos",
  //   provincia: "Málaga",
  //   id: "29032",
  //   label: "Campillos (Málaga)",
  // },
  // {
  //   municipio: "Canillas de Aceituno",
  //   provincia: "Málaga",
  //   id: "29033",
  //   label: "Canillas de Aceituno (Málaga)",
  // },
  // {
  //   municipio: "Canillas de Albaida",
  //   provincia: "Málaga",
  //   id: "29034",
  //   label: "Canillas de Albaida (Málaga)",
  // },
  // {
  //   municipio: "Cartajima",
  //   provincia: "Málaga",
  //   id: "29037",
  //   label: "Cartajima (Málaga)",
  // },
  // {
  //   municipio: "Cártama",
  //   provincia: "Málaga",
  //   id: "29038",
  //   label: "Cártama (Málaga)",
  // },
  // {
  //   municipio: "Comares",
  //   provincia: "Málaga",
  //   id: "29044",
  //   label: "Comares (Málaga)",
  // },
  // {
  //   municipio: "Casabermeja",
  //   provincia: "Málaga",
  //   id: "29039",
  //   label: "Casabermeja (Málaga)",
  // },
  // {
  //   municipio: "Casarabonela",
  //   provincia: "Málaga",
  //   id: "29040",
  //   label: "Casarabonela (Málaga)",
  // },
  // {
  //   municipio: "Casares",
  //   provincia: "Málaga",
  //   id: "29041",
  //   label: "Casares (Málaga)",
  // },
  // {
  //   municipio: "Coín",
  //   provincia: "Málaga",
  //   id: "29042",
  //   label: "Coín (Málaga)",
  // },
  // {
  //   municipio: "Colmenar",
  //   provincia: "Málaga",
  //   id: "29043",
  //   label: "Colmenar (Málaga)",
  // },
  // {
  //   municipio: "Cuevas Bajas",
  //   provincia: "Málaga",
  //   id: "29047",
  //   label: "Cuevas Bajas (Málaga)",
  // },
  // {
  //   municipio: "Cuevas del Becerro",
  //   provincia: "Málaga",
  //   id: "29048",
  //   label: "Cuevas del Becerro (Málaga)",
  // },
  // {
  //   municipio: "Capillas",
  //   provincia: "Palencia",
  //   id: "34045",
  //   label: "Capillas (Palencia)",
  // },
  // {
  //   municipio: "Cuevas de San Marcos",
  //   provincia: "Málaga",
  //   id: "29049",
  //   label: "Cuevas de San Marcos (Málaga)",
  // },
  // {
  //   municipio: "Cardeñosa de Volpejera",
  //   provincia: "Palencia",
  //   id: "34046",
  //   label: "Cardeñosa de Volpejera (Palencia)",
  // },
  // {
  //   municipio: "Cútar",
  //   provincia: "Málaga",
  //   id: "29050",
  //   label: "Cútar (Málaga)",
  // },
  // {
  //   municipio: "Estepona",
  //   provincia: "Málaga",
  //   id: "29051",
  //   label: "Estepona (Málaga)",
  // },
  // {
  //   municipio: "Valderrodilla",
  //   provincia: "Soria",
  //   id: "42197",
  //   label: "Valderrodilla (Soria)",
  // },
  // {
  //   municipio: "Faraján",
  //   provincia: "Málaga",
  //   id: "29052",
  //   label: "Faraján (Málaga)",
  // },
  // {
  //   municipio: "Fuente de Piedra",
  //   provincia: "Málaga",
  //   id: "29055",
  //   label: "Fuente de Piedra (Málaga)",
  // },
  // {
  //   municipio: "Frigiliana",
  //   provincia: "Málaga",
  //   id: "29053",
  //   label: "Frigiliana (Málaga)",
  // },
  // {
  //   municipio: "Fuengirola",
  //   provincia: "Málaga",
  //   id: "29054",
  //   label: "Fuengirola (Málaga)",
  // },
  // {
  //   municipio: "Gaucín",
  //   provincia: "Málaga",
  //   id: "29056",
  //   label: "Gaucín (Málaga)",
  // },
  // {
  //   municipio: "Genalguacil",
  //   provincia: "Málaga",
  //   id: "29057",
  //   label: "Genalguacil (Málaga)",
  // },
  // {
  //   municipio: "Castil de Vela",
  //   provincia: "Palencia",
  //   id: "34048",
  //   label: "Castil de Vela (Palencia)",
  // },
  // {
  //   municipio: "Humilladero",
  //   provincia: "Málaga",
  //   id: "29059",
  //   label: "Humilladero (Málaga)",
  // },
  // {
  //   municipio: "Población de Campos",
  //   provincia: "Palencia",
  //   id: "34132",
  //   label: "Población de Campos (Palencia)",
  // },
  // {
  //   municipio: "Igualeja",
  //   provincia: "Málaga",
  //   id: "29060",
  //   label: "Igualeja (Málaga)",
  // },
  // {
  //   municipio: "Istán",
  //   provincia: "Málaga",
  //   id: "29061",
  //   label: "Istán (Málaga)",
  // },
  // {
  //   municipio: "Iznate",
  //   provincia: "Málaga",
  //   id: "29062",
  //   label: "Iznate (Málaga)",
  // },
  // {
  //   municipio: "Sanchonuño",
  //   provincia: "Segovia",
  //   id: "40179",
  //   label: "Sanchonuño (Segovia)",
  // },
  // {
  //   municipio: "Jubrique",
  //   provincia: "Málaga",
  //   id: "29064",
  //   label: "Jubrique (Málaga)",
  // },
  // {
  //   municipio: "Júzcar",
  //   provincia: "Málaga",
  //   id: "29065",
  //   label: "Júzcar (Málaga)",
  // },
  // {
  //   municipio: "Manilva",
  //   provincia: "Málaga",
  //   id: "29068",
  //   label: "Manilva (Málaga)",
  // },
  // {
  //   municipio: "Mijas",
  //   provincia: "Málaga",
  //   id: "29070",
  //   label: "Mijas (Málaga)",
  // },
  // {
  //   municipio: "Moclinejo",
  //   provincia: "Málaga",
  //   id: "29071",
  //   label: "Moclinejo (Málaga)",
  // },
  // {
  //   municipio: "Población de Cerrato",
  //   provincia: "Palencia",
  //   id: "34133",
  //   label: "Población de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Mollina",
  //   provincia: "Málaga",
  //   id: "29072",
  //   label: "Mollina (Málaga)",
  // },
  // {
  //   municipio: "Monda",
  //   provincia: "Málaga",
  //   id: "29073",
  //   label: "Monda (Málaga)",
  // },
  // {
  //   municipio: "Totalán",
  //   provincia: "Málaga",
  //   id: "29092",
  //   label: "Totalán (Málaga)",
  // },
  // {
  //   municipio: "Montejaque",
  //   provincia: "Málaga",
  //   id: "29074",
  //   label: "Montejaque (Málaga)",
  // },
  // {
  //   municipio: "Nerja",
  //   provincia: "Málaga",
  //   id: "29075",
  //   label: "Nerja (Málaga)",
  // },
  // {
  //   municipio: "Ojén",
  //   provincia: "Málaga",
  //   id: "29076",
  //   label: "Ojén (Málaga)",
  // },
  // {
  //   municipio: "Valle de Abdalajís",
  //   provincia: "Málaga",
  //   id: "29093",
  //   label: "Valle de Abdalajís (Málaga)",
  // },
  // {
  //   municipio: "Parauta",
  //   provincia: "Málaga",
  //   id: "29077",
  //   label: "Parauta (Málaga)",
  // },
  // {
  //   municipio: "Maside",
  //   provincia: "Orense",
  //   id: "32045",
  //   label: "Maside (Orense)",
  // },
  // {
  //   municipio: "Periana",
  //   provincia: "Málaga",
  //   id: "29079",
  //   label: "Periana (Málaga)",
  // },
  // {
  //   municipio: "Pizarra",
  //   provincia: "Málaga",
  //   id: "29080",
  //   label: "Pizarra (Málaga)",
  // },
  // {
  //   municipio: "Pujerra",
  //   provincia: "Málaga",
  //   id: "29081",
  //   label: "Pujerra (Málaga)",
  // },
  // {
  //   municipio: "Rincón de la Victoria",
  //   provincia: "Málaga",
  //   id: "29082",
  //   label: "Rincón de la Victoria (Málaga)",
  // },
  // {
  //   municipio: "Paderne de Allariz",
  //   provincia: "Orense",
  //   id: "32055",
  //   label: "Paderne de Allariz (Orense)",
  // },
  // {
  //   municipio: "Padrenda",
  //   provincia: "Orense",
  //   id: "32056",
  //   label: "Padrenda (Orense)",
  // },
  // {
  //   municipio: "Riogordo",
  //   provincia: "Málaga",
  //   id: "29083",
  //   label: "Riogordo (Málaga)",
  // },
  // {
  //   municipio: "Aldeanueva de la Serrezuela",
  //   provincia: "Segovia",
  //   id: "40009",
  //   label: "Aldeanueva de la Serrezuela (Segovia)",
  // },
  // {
  //   municipio: "Salares",
  //   provincia: "Málaga",
  //   id: "29085",
  //   label: "Salares (Málaga)",
  // },
  // {
  //   municipio: "Villanueva del Rosario",
  //   provincia: "Málaga",
  //   id: "29096",
  //   label: "Villanueva del Rosario (Málaga)",
  // },
  // {
  //   municipio: "Sayalonga",
  //   provincia: "Málaga",
  //   id: "29086",
  //   label: "Sayalonga (Málaga)",
  // },
  // {
  //   municipio: "Villanueva del Trabuco",
  //   provincia: "Málaga",
  //   id: "29097",
  //   label: "Villanueva del Trabuco (Málaga)",
  // },
  // {
  //   municipio: "Sedella",
  //   provincia: "Málaga",
  //   id: "29087",
  //   label: "Sedella (Málaga)",
  // },
  // {
  //   municipio: "Sierra de Yeguas",
  //   provincia: "Málaga",
  //   id: "29088",
  //   label: "Sierra de Yeguas (Málaga)",
  // },
  // {
  //   municipio: "Tolox",
  //   provincia: "Málaga",
  //   id: "29090",
  //   label: "Tolox (Málaga)",
  // },
  // {
  //   municipio: "Torrox",
  //   provincia: "Málaga",
  //   id: "29091",
  //   label: "Torrox (Málaga)",
  // },
  // {
  //   municipio: "Villanueva de Algaidas",
  //   provincia: "Málaga",
  //   id: "29095",
  //   label: "Villanueva de Algaidas (Málaga)",
  // },
  // {
  //   municipio: "Villanueva de Tapia",
  //   provincia: "Málaga",
  //   id: "29098",
  //   label: "Villanueva de Tapia (Málaga)",
  // },
  // {
  //   municipio: "Albudeite",
  //   provincia: "Murcia",
  //   id: "30004",
  //   label: "Albudeite (Murcia)",
  // },
  // {
  //   municipio: "Viñuela",
  //   provincia: "Málaga",
  //   id: "29099",
  //   label: "Viñuela (Málaga)",
  // },
  // {
  //   municipio: "Yunquera",
  //   provincia: "Málaga",
  //   id: "29100",
  //   label: "Yunquera (Málaga)",
  // },
  // {
  //   municipio: "Arnoia, A",
  //   provincia: "Orense",
  //   id: "32003",
  //   label: "Arnoia, A (Orense)",
  // },
  // {
  //   municipio: "Torremolinos",
  //   provincia: "Málaga",
  //   id: "29901",
  //   label: "Torremolinos (Málaga)",
  // },
  // {
  //   municipio: "Alcantarilla",
  //   provincia: "Murcia",
  //   id: "30005",
  //   label: "Alcantarilla (Murcia)",
  // },
  // {
  //   municipio: "Aledo",
  //   provincia: "Murcia",
  //   id: "30006",
  //   label: "Aledo (Murcia)",
  // },
  // {
  //   municipio: "Alguazas",
  //   provincia: "Murcia",
  //   id: "30007",
  //   label: "Alguazas (Murcia)",
  // },
  // {
  //   municipio: "Archena",
  //   provincia: "Murcia",
  //   id: "30009",
  //   label: "Archena (Murcia)",
  // },
  // {
  //   municipio: "Beniel",
  //   provincia: "Murcia",
  //   id: "30010",
  //   label: "Beniel (Murcia)",
  // },
  // {
  //   municipio: "Calasparra",
  //   provincia: "Murcia",
  //   id: "30013",
  //   label: "Calasparra (Murcia)",
  // },
  // {
  //   municipio: "Campos del Río",
  //   provincia: "Murcia",
  //   id: "30014",
  //   label: "Campos del Río (Murcia)",
  // },
  // {
  //   municipio: "Aldeanueva del Codonal",
  //   provincia: "Segovia",
  //   id: "40010",
  //   label: "Aldeanueva del Codonal (Segovia)",
  // },
  // {
  //   municipio: "Ceutí",
  //   provincia: "Murcia",
  //   id: "30018",
  //   label: "Ceutí (Murcia)",
  // },
  // {
  //   municipio: "Lorquí",
  //   provincia: "Murcia",
  //   id: "30025",
  //   label: "Lorquí (Murcia)",
  // },
  // {
  //   municipio: "Puerto Lumbreras",
  //   provincia: "Murcia",
  //   id: "30033",
  //   label: "Puerto Lumbreras (Murcia)",
  // },
  // {
  //   municipio: "Ricote",
  //   provincia: "Murcia",
  //   id: "30034",
  //   label: "Ricote (Murcia)",
  // },
  // {
  //   municipio: "Punxín",
  //   provincia: "Orense",
  //   id: "32065",
  //   label: "Punxín (Orense)",
  // },
  // {
  //   municipio: "Torres de Cotillas, Las",
  //   provincia: "Murcia",
  //   id: "30038",
  //   label: "Torres de Cotillas, Las (Murcia)",
  // },
  // {
  //   municipio: "Villanueva del Río Segura",
  //   provincia: "Murcia",
  //   id: "30042",
  //   label: "Villanueva del Río Segura (Murcia)",
  // },
  // {
  //   municipio: "Yecla",
  //   provincia: "Murcia",
  //   id: "30043",
  //   label: "Yecla (Murcia)",
  // },
  // {
  //   municipio: "Quintela de Leirado",
  //   provincia: "Orense",
  //   id: "32066",
  //   label: "Quintela de Leirado (Orense)",
  // },
  // {
  //   municipio: "Allariz",
  //   provincia: "Orense",
  //   id: "32001",
  //   label: "Allariz (Orense)",
  // },
  // {
  //   municipio: "Barbadás",
  //   provincia: "Orense",
  //   id: "32008",
  //   label: "Barbadás (Orense)",
  // },
  // {
  //   municipio: "Amoeiro",
  //   provincia: "Orense",
  //   id: "32002",
  //   label: "Amoeiro (Orense)",
  // },
  // {
  //   municipio: "Beade",
  //   provincia: "Orense",
  //   id: "32010",
  //   label: "Beade (Orense)",
  // },
  // {
  //   municipio: "Avión",
  //   provincia: "Orense",
  //   id: "32004",
  //   label: "Avión (Orense)",
  // },
  // {
  //   municipio: "Baltar",
  //   provincia: "Orense",
  //   id: "32005",
  //   label: "Baltar (Orense)",
  // },
  // {
  //   municipio: "Blancos, Os",
  //   provincia: "Orense",
  //   id: "32012",
  //   label: "Blancos, Os (Orense)",
  // },
  // {
  //   municipio: "Bande",
  //   provincia: "Orense",
  //   id: "32006",
  //   label: "Bande (Orense)",
  // },
  // {
  //   municipio: "Baños de Molgas",
  //   provincia: "Orense",
  //   id: "32007",
  //   label: "Baños de Molgas (Orense)",
  // },
  // {
  //   municipio: "Barco de Valdeorras, O",
  //   provincia: "Orense",
  //   id: "32009",
  //   label: "Barco de Valdeorras, O (Orense)",
  // },
  // {
  //   municipio: "Bea",
  //   provincia: "Teruel",
  //   id: "44036",
  //   label: "Bea (Teruel)",
  // },
  // {
  //   municipio: "Beariz",
  //   provincia: "Orense",
  //   id: "32011",
  //   label: "Beariz (Orense)",
  // },
  // {
  //   municipio: "Beceite",
  //   provincia: "Teruel",
  //   id: "44037",
  //   label: "Beceite (Teruel)",
  // },
  // {
  //   municipio: "Boborás",
  //   provincia: "Orense",
  //   id: "32013",
  //   label: "Boborás (Orense)",
  // },
  // {
  //   municipio: "Bolo, O",
  //   provincia: "Orense",
  //   id: "32015",
  //   label: "Bolo, O (Orense)",
  // },
  // {
  //   municipio: "Calvos de Randín",
  //   provincia: "Orense",
  //   id: "32016",
  //   label: "Calvos de Randín (Orense)",
  // },
  // {
  //   municipio: "Castrojimeno",
  //   provincia: "Segovia",
  //   id: "40048",
  //   label: "Castrojimeno (Segovia)",
  // },
  // {
  //   municipio: "Carballeda de Valdeorras",
  //   provincia: "Orense",
  //   id: "32017",
  //   label: "Carballeda de Valdeorras (Orense)",
  // },
  // {
  //   municipio: "Carballeda de Avia",
  //   provincia: "Orense",
  //   id: "32018",
  //   label: "Carballeda de Avia (Orense)",
  // },
  // {
  //   municipio: "Castrelo de Miño",
  //   provincia: "Orense",
  //   id: "32022",
  //   label: "Castrelo de Miño (Orense)",
  // },
  // {
  //   municipio: "Carballiño, O",
  //   provincia: "Orense",
  //   id: "32019",
  //   label: "Carballiño, O (Orense)",
  // },
  // {
  //   municipio: "Cartelle",
  //   provincia: "Orense",
  //   id: "32020",
  //   label: "Cartelle (Orense)",
  // },
  // {
  //   municipio: "Castrelo do Val",
  //   provincia: "Orense",
  //   id: "32021",
  //   label: "Castrelo do Val (Orense)",
  // },
  // {
  //   municipio: "Castro Caldelas",
  //   provincia: "Orense",
  //   id: "32023",
  //   label: "Castro Caldelas (Orense)",
  // },
  // {
  //   municipio: "Esgos",
  //   provincia: "Orense",
  //   id: "32031",
  //   label: "Esgos (Orense)",
  // },
  // {
  //   municipio: "Celanova",
  //   provincia: "Orense",
  //   id: "32024",
  //   label: "Celanova (Orense)",
  // },
  // {
  //   municipio: "Molinillo",
  //   provincia: "Salamanca",
  //   id: "37195",
  //   label: "Molinillo (Salamanca)",
  // },
  // {
  //   municipio: "Cenlle",
  //   provincia: "Orense",
  //   id: "32025",
  //   label: "Cenlle (Orense)",
  // },
  // {
  //   municipio: "Xunqueira de Ambía",
  //   provincia: "Orense",
  //   id: "32036",
  //   label: "Xunqueira de Ambía (Orense)",
  // },
  // {
  //   municipio: "Coles",
  //   provincia: "Orense",
  //   id: "32026",
  //   label: "Coles (Orense)",
  // },
  // {
  //   municipio: "Cortegada",
  //   provincia: "Orense",
  //   id: "32027",
  //   label: "Cortegada (Orense)",
  // },
  // {
  //   municipio: "Chandrexa de Queixa",
  //   provincia: "Orense",
  //   id: "32029",
  //   label: "Chandrexa de Queixa (Orense)",
  // },
  // {
  //   municipio: "Entrimo",
  //   provincia: "Orense",
  //   id: "32030",
  //   label: "Entrimo (Orense)",
  // },
  // {
  //   municipio: "Xinzo de Limia",
  //   provincia: "Orense",
  //   id: "32032",
  //   label: "Xinzo de Limia (Orense)",
  // },
  // {
  //   municipio: "Xunqueira de Espadanedo",
  //   provincia: "Orense",
  //   id: "32037",
  //   label: "Xunqueira de Espadanedo (Orense)",
  // },
  // {
  //   municipio: "Gomesende",
  //   provincia: "Orense",
  //   id: "32033",
  //   label: "Gomesende (Orense)",
  // },
  // {
  //   municipio: "Larouco",
  //   provincia: "Orense",
  //   id: "32038",
  //   label: "Larouco (Orense)",
  // },
  // {
  //   municipio: "Laza",
  //   provincia: "Orense",
  //   id: "32039",
  //   label: "Laza (Orense)",
  // },
  // {
  //   municipio: "Gudiña, A",
  //   provincia: "Orense",
  //   id: "32034",
  //   label: "Gudiña, A (Orense)",
  // },
  // {
  //   municipio: "Irixo, O",
  //   provincia: "Orense",
  //   id: "32035",
  //   label: "Irixo, O (Orense)",
  // },
  // {
  //   municipio: "Leiro",
  //   provincia: "Orense",
  //   id: "32040",
  //   label: "Leiro (Orense)",
  // },
  // {
  //   municipio: "Olea de Boedo",
  //   provincia: "Palencia",
  //   id: "34113",
  //   label: "Olea de Boedo (Palencia)",
  // },
  // {
  //   municipio: "Lobeira",
  //   provincia: "Orense",
  //   id: "32041",
  //   label: "Lobeira (Orense)",
  // },
  // {
  //   municipio: "Lobios",
  //   provincia: "Orense",
  //   id: "32042",
  //   label: "Lobios (Orense)",
  // },
  // {
  //   municipio: "Maceda",
  //   provincia: "Orense",
  //   id: "32043",
  //   label: "Maceda (Orense)",
  // },
  // {
  //   municipio: "Manzaneda",
  //   provincia: "Orense",
  //   id: "32044",
  //   label: "Manzaneda (Orense)",
  // },
  // {
  //   municipio: "Monforte de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37196",
  //   label: "Monforte de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Melón",
  //   provincia: "Orense",
  //   id: "32046",
  //   label: "Melón (Orense)",
  // },
  // {
  //   municipio: "Merca, A",
  //   provincia: "Orense",
  //   id: "32047",
  //   label: "Merca, A (Orense)",
  // },
  // {
  //   municipio: "Mezquita, A",
  //   provincia: "Orense",
  //   id: "32048",
  //   label: "Mezquita, A (Orense)",
  // },
  // {
  //   municipio: "Reinosa",
  //   provincia: "Cantabria",
  //   id: "39059",
  //   label: "Reinosa (Cantabria)",
  // },
  // {
  //   municipio: "Montederramo",
  //   provincia: "Orense",
  //   id: "32049",
  //   label: "Montederramo (Orense)",
  // },
  // {
  //   municipio: "Monterrei",
  //   provincia: "Orense",
  //   id: "32050",
  //   label: "Monterrei (Orense)",
  // },
  // {
  //   municipio: "Muíños",
  //   provincia: "Orense",
  //   id: "32051",
  //   label: "Muíños (Orense)",
  // },
  // {
  //   municipio: "Nogueira de Ramuín",
  //   provincia: "Orense",
  //   id: "32052",
  //   label: "Nogueira de Ramuín (Orense)",
  // },
  // {
  //   municipio: "Oímbra",
  //   provincia: "Orense",
  //   id: "32053",
  //   label: "Oímbra (Orense)",
  // },
  // {
  //   municipio: "Rairiz de Veiga",
  //   provincia: "Orense",
  //   id: "32067",
  //   label: "Rairiz de Veiga (Orense)",
  // },
  // {
  //   municipio: "Ourense",
  //   provincia: "Orense",
  //   id: "32054",
  //   label: "Ourense (Orense)",
  // },
  // {
  //   municipio: "Riós",
  //   provincia: "Orense",
  //   id: "32071",
  //   label: "Riós (Orense)",
  // },
  // {
  //   municipio: "Parada de Sil",
  //   provincia: "Orense",
  //   id: "32057",
  //   label: "Parada de Sil (Orense)",
  // },
  // {
  //   municipio: "Ayuela",
  //   provincia: "Palencia",
  //   id: "34020",
  //   label: "Ayuela (Palencia)",
  // },
  // {
  //   municipio: "Pereiro de Aguiar, O",
  //   provincia: "Orense",
  //   id: "32058",
  //   label: "Pereiro de Aguiar, O (Orense)",
  // },
  // {
  //   municipio: "San Cibrao das Viñas",
  //   provincia: "Orense",
  //   id: "32075",
  //   label: "San Cibrao das Viñas (Orense)",
  // },
  // {
  //   municipio: "Peroxa, A",
  //   provincia: "Orense",
  //   id: "32059",
  //   label: "Peroxa, A (Orense)",
  // },
  // {
  //   municipio: "Petín",
  //   provincia: "Orense",
  //   id: "32060",
  //   label: "Petín (Orense)",
  // },
  // {
  //   municipio: "Piñor",
  //   provincia: "Orense",
  //   id: "32061",
  //   label: "Piñor (Orense)",
  // },
  // {
  //   municipio: "San Cristovo de Cea",
  //   provincia: "Orense",
  //   id: "32076",
  //   label: "San Cristovo de Cea (Orense)",
  // },
  // {
  //   municipio: "Sandiás",
  //   provincia: "Orense",
  //   id: "32077",
  //   label: "Sandiás (Orense)",
  // },
  // {
  //   municipio: "Porqueira",
  //   provincia: "Orense",
  //   id: "32062",
  //   label: "Porqueira (Orense)",
  // },
  // {
  //   municipio: "Rúa, A",
  //   provincia: "Orense",
  //   id: "32072",
  //   label: "Rúa, A (Orense)",
  // },
  // {
  //   municipio: "Pobra de Trives, A",
  //   provincia: "Orense",
  //   id: "32063",
  //   label: "Pobra de Trives, A (Orense)",
  // },
  // {
  //   municipio: "Pontedeva",
  //   provincia: "Orense",
  //   id: "32064",
  //   label: "Pontedeva (Orense)",
  // },
  // {
  //   municipio: "Ramirás",
  //   provincia: "Orense",
  //   id: "32068",
  //   label: "Ramirás (Orense)",
  // },
  // {
  //   municipio: "San Amaro",
  //   provincia: "Orense",
  //   id: "32074",
  //   label: "San Amaro (Orense)",
  // },
  // {
  //   municipio: "Ribadavia",
  //   provincia: "Orense",
  //   id: "32069",
  //   label: "Ribadavia (Orense)",
  // },
  // {
  //   municipio: "San Xoán de Río",
  //   provincia: "Orense",
  //   id: "32070",
  //   label: "San Xoán de Río (Orense)",
  // },
  // {
  //   municipio: "Rubiá",
  //   provincia: "Orense",
  //   id: "32073",
  //   label: "Rubiá (Orense)",
  // },
  // {
  //   municipio: "Avilés",
  //   provincia: "Asturias (Principado de)",
  //   id: "33004",
  //   label: "Avilés (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Tala, La",
  //   provincia: "Salamanca",
  //   id: "37315",
  //   label: "Tala, La (Salamanca)",
  // },
  // {
  //   municipio: "Belmonte de Miranda",
  //   provincia: "Asturias (Principado de)",
  //   id: "33005",
  //   label: "Belmonte de Miranda (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Teixeira, A",
  //   provincia: "Orense",
  //   id: "32080",
  //   label: "Teixeira, A (Orense)",
  // },
  // {
  //   municipio: "Verea",
  //   provincia: "Orense",
  //   id: "32084",
  //   label: "Verea (Orense)",
  // },
  // {
  //   municipio: "Toén",
  //   provincia: "Orense",
  //   id: "32081",
  //   label: "Toén (Orense)",
  // },
  // {
  //   municipio: "Trasmiras",
  //   provincia: "Orense",
  //   id: "32082",
  //   label: "Trasmiras (Orense)",
  // },
  // {
  //   municipio: "Caso",
  //   provincia: "Asturias (Principado de)",
  //   id: "33015",
  //   label: "Caso (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Colunga",
  //   provincia: "Asturias (Principado de)",
  //   id: "33019",
  //   label: "Colunga (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Veiga, A",
  //   provincia: "Orense",
  //   id: "32083",
  //   label: "Veiga, A (Orense)",
  // },
  // {
  //   municipio: "Verín",
  //   provincia: "Orense",
  //   id: "32085",
  //   label: "Verín (Orense)",
  // },
  // {
  //   municipio: "Viana do Bolo",
  //   provincia: "Orense",
  //   id: "32086",
  //   label: "Viana do Bolo (Orense)",
  // },
  // {
  //   municipio: "Vilamarín",
  //   provincia: "Orense",
  //   id: "32087",
  //   label: "Vilamarín (Orense)",
  // },
  // {
  //   municipio: "Torresmenudas",
  //   provincia: "Salamanca",
  //   id: "37327",
  //   label: "Torresmenudas (Salamanca)",
  // },
  // {
  //   municipio: "Vilamartín de Valdeorras",
  //   provincia: "Orense",
  //   id: "32088",
  //   label: "Vilamartín de Valdeorras (Orense)",
  // },
  // {
  //   municipio: "Illas",
  //   provincia: "Asturias (Principado de)",
  //   id: "33030",
  //   label: "Illas (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Langreo",
  //   provincia: "Asturias (Principado de)",
  //   id: "33031",
  //   label: "Langreo (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Vilar de Barrio",
  //   provincia: "Orense",
  //   id: "32089",
  //   label: "Vilar de Barrio (Orense)",
  // },
  // {
  //   municipio: "Vilar de Santos",
  //   provincia: "Orense",
  //   id: "32090",
  //   label: "Vilar de Santos (Orense)",
  // },
  // {
  //   municipio: "Vilardevós",
  //   provincia: "Orense",
  //   id: "32091",
  //   label: "Vilardevós (Orense)",
  // },
  // {
  //   municipio: "Laviana",
  //   provincia: "Asturias (Principado de)",
  //   id: "33032",
  //   label: "Laviana (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Vilariño de Conso",
  //   provincia: "Orense",
  //   id: "32092",
  //   label: "Vilariño de Conso (Orense)",
  // },
  // {
  //   municipio: "Allande",
  //   provincia: "Asturias (Principado de)",
  //   id: "33001",
  //   label: "Allande (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Aller",
  //   provincia: "Asturias (Principado de)",
  //   id: "33002",
  //   label: "Aller (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Amieva",
  //   provincia: "Asturias (Principado de)",
  //   id: "33003",
  //   label: "Amieva (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Bimenes",
  //   provincia: "Asturias (Principado de)",
  //   id: "33006",
  //   label: "Bimenes (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Cabrales",
  //   provincia: "Asturias (Principado de)",
  //   id: "33008",
  //   label: "Cabrales (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Cabranes",
  //   provincia: "Asturias (Principado de)",
  //   id: "33009",
  //   label: "Cabranes (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Salas",
  //   provincia: "Asturias (Principado de)",
  //   id: "33059",
  //   label: "Salas (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Candamo",
  //   provincia: "Asturias (Principado de)",
  //   id: "33010",
  //   label: "Candamo (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Cangas del Narcea",
  //   provincia: "Asturias (Principado de)",
  //   id: "33011",
  //   label: "Cangas del Narcea (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Cangas de Onís",
  //   provincia: "Asturias (Principado de)",
  //   id: "33012",
  //   label: "Cangas de Onís (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Sariego",
  //   provincia: "Asturias (Principado de)",
  //   id: "33065",
  //   label: "Sariego (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Siero",
  //   provincia: "Asturias (Principado de)",
  //   id: "33066",
  //   label: "Siero (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Caravia",
  //   provincia: "Asturias (Principado de)",
  //   id: "33013",
  //   label: "Caravia (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Carreño",
  //   provincia: "Asturias (Principado de)",
  //   id: "33014",
  //   label: "Carreño (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Corvera de Asturias",
  //   provincia: "Asturias (Principado de)",
  //   id: "33020",
  //   label: "Corvera de Asturias (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Abarca de Campos",
  //   provincia: "Palencia",
  //   id: "34001",
  //   label: "Abarca de Campos (Palencia)",
  // },
  // {
  //   municipio: "Castroserna de Abajo",
  //   provincia: "Segovia",
  //   id: "40049",
  //   label: "Castroserna de Abajo (Segovia)",
  // },
  // {
  //   municipio: "Degaña",
  //   provincia: "Asturias (Principado de)",
  //   id: "33022",
  //   label: "Degaña (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Muros de Nalón",
  //   provincia: "Asturias (Principado de)",
  //   id: "33039",
  //   label: "Muros de Nalón (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Villalobón",
  //   provincia: "Palencia",
  //   id: "34217",
  //   label: "Villalobón (Palencia)",
  // },
  // {
  //   municipio: "Nava",
  //   provincia: "Asturias (Principado de)",
  //   id: "33040",
  //   label: "Nava (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Grado",
  //   provincia: "Asturias (Principado de)",
  //   id: "33026",
  //   label: "Grado (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Grandas de Salime",
  //   provincia: "Asturias (Principado de)",
  //   id: "33027",
  //   label: "Grandas de Salime (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Villamoronta",
  //   provincia: "Palencia",
  //   id: "34223",
  //   label: "Villamoronta (Palencia)",
  // },
  // {
  //   municipio: "Illano",
  //   provincia: "Asturias (Principado de)",
  //   id: "33029",
  //   label: "Illano (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Aldea Real",
  //   provincia: "Segovia",
  //   id: "40012",
  //   label: "Aldea Real (Segovia)",
  // },
  // {
  //   municipio: "Llanera",
  //   provincia: "Asturias (Principado de)",
  //   id: "33035",
  //   label: "Llanera (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Mieres",
  //   provincia: "Asturias (Principado de)",
  //   id: "33037",
  //   label: "Mieres (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Morcín",
  //   provincia: "Asturias (Principado de)",
  //   id: "33038",
  //   label: "Morcín (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Noreña",
  //   provincia: "Asturias (Principado de)",
  //   id: "33042",
  //   label: "Noreña (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Onís",
  //   provincia: "Asturias (Principado de)",
  //   id: "33043",
  //   label: "Onís (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Oviedo",
  //   provincia: "Asturias (Principado de)",
  //   id: "33044",
  //   label: "Oviedo (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Parres",
  //   provincia: "Asturias (Principado de)",
  //   id: "33045",
  //   label: "Parres (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Peñamellera Alta",
  //   provincia: "Asturias (Principado de)",
  //   id: "33046",
  //   label: "Peñamellera Alta (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Peñamellera Baja",
  //   provincia: "Asturias (Principado de)",
  //   id: "33047",
  //   label: "Peñamellera Baja (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Pesoz",
  //   provincia: "Asturias (Principado de)",
  //   id: "33048",
  //   label: "Pesoz (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Piloña",
  //   provincia: "Asturias (Principado de)",
  //   id: "33049",
  //   label: "Piloña (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Ponga",
  //   provincia: "Asturias (Principado de)",
  //   id: "33050",
  //   label: "Ponga (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Pravia",
  //   provincia: "Asturias (Principado de)",
  //   id: "33051",
  //   label: "Pravia (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Cabrerizos",
  //   provincia: "Salamanca",
  //   id: "37067",
  //   label: "Cabrerizos (Salamanca)",
  // },
  // {
  //   municipio: "Proaza",
  //   provincia: "Asturias (Principado de)",
  //   id: "33052",
  //   label: "Proaza (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Abia de las Torres",
  //   provincia: "Palencia",
  //   id: "34003",
  //   label: "Abia de las Torres (Palencia)",
  // },
  // {
  //   municipio: "Regueras, Las",
  //   provincia: "Asturias (Principado de)",
  //   id: "33054",
  //   label: "Regueras, Las (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Ribadedeva",
  //   provincia: "Asturias (Principado de)",
  //   id: "33055",
  //   label: "Ribadedeva (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Aguilar de Campoo",
  //   provincia: "Palencia",
  //   id: "34004",
  //   label: "Aguilar de Campoo (Palencia)",
  // },
  // {
  //   municipio: "Ribera de Arriba",
  //   provincia: "Asturias (Principado de)",
  //   id: "33057",
  //   label: "Ribera de Arriba (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Riosa",
  //   provincia: "Asturias (Principado de)",
  //   id: "33058",
  //   label: "Riosa (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Cordovilla la Real",
  //   provincia: "Palencia",
  //   id: "34063",
  //   label: "Cordovilla la Real (Palencia)",
  // },
  // {
  //   municipio: "San Martín del Rey Aurelio",
  //   provincia: "Asturias (Principado de)",
  //   id: "33060",
  //   label: "San Martín del Rey Aurelio (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Alba de Cerrato",
  //   provincia: "Palencia",
  //   id: "34006",
  //   label: "Alba de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "San Martín de Oscos",
  //   provincia: "Asturias (Principado de)",
  //   id: "33061",
  //   label: "San Martín de Oscos (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Amayuelas de Arriba",
  //   provincia: "Palencia",
  //   id: "34009",
  //   label: "Amayuelas de Arriba (Palencia)",
  // },
  // {
  //   municipio: "Santa Eulalia de Oscos",
  //   provincia: "Asturias (Principado de)",
  //   id: "33062",
  //   label: "Santa Eulalia de Oscos (Asturias (Principado de))",
  // },
  // {
  //   municipio: "San Tirso de Abres",
  //   provincia: "Asturias (Principado de)",
  //   id: "33063",
  //   label: "San Tirso de Abres (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Santo Adriano",
  //   provincia: "Asturias (Principado de)",
  //   id: "33064",
  //   label: "Santo Adriano (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Sobrescobio",
  //   provincia: "Asturias (Principado de)",
  //   id: "33067",
  //   label: "Sobrescobio (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Somiedo",
  //   provincia: "Asturias (Principado de)",
  //   id: "33068",
  //   label: "Somiedo (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Pedraza de Campos",
  //   provincia: "Palencia",
  //   id: "34125",
  //   label: "Pedraza de Campos (Palencia)",
  // },
  // {
  //   municipio: "Soto del Barco",
  //   provincia: "Asturias (Principado de)",
  //   id: "33069",
  //   label: "Soto del Barco (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Venta de Baños",
  //   provincia: "Palencia",
  //   id: "34023",
  //   label: "Venta de Baños (Palencia)",
  // },
  // {
  //   municipio: "Taramundi",
  //   provincia: "Asturias (Principado de)",
  //   id: "33071",
  //   label: "Taramundi (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Baquerín de Campos",
  //   provincia: "Palencia",
  //   id: "34024",
  //   label: "Baquerín de Campos (Palencia)",
  // },
  // {
  //   municipio: "Teverga",
  //   provincia: "Asturias (Principado de)",
  //   id: "33072",
  //   label: "Teverga (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Tineo",
  //   provincia: "Asturias (Principado de)",
  //   id: "33073",
  //   label: "Tineo (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Vegadeo",
  //   provincia: "Asturias (Principado de)",
  //   id: "33074",
  //   label: "Vegadeo (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Villanueva de Oscos",
  //   provincia: "Asturias (Principado de)",
  //   id: "33075",
  //   label: "Villanueva de Oscos (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Villayón",
  //   provincia: "Asturias (Principado de)",
  //   id: "33077",
  //   label: "Villayón (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Yernes y Tameza",
  //   provincia: "Asturias (Principado de)",
  //   id: "33078",
  //   label: "Yernes y Tameza (Asturias (Principado de))",
  // },
  // {
  //   municipio: "Alar del Rey",
  //   provincia: "Palencia",
  //   id: "34005",
  //   label: "Alar del Rey (Palencia)",
  // },
  // {
  //   municipio: "Bárcena de Campos",
  //   provincia: "Palencia",
  //   id: "34025",
  //   label: "Bárcena de Campos (Palencia)",
  // },
  // {
  //   municipio: "Ampudia",
  //   provincia: "Palencia",
  //   id: "34010",
  //   label: "Ampudia (Palencia)",
  // },
  // {
  //   municipio: "Antigüedad",
  //   provincia: "Palencia",
  //   id: "34012",
  //   label: "Antigüedad (Palencia)",
  // },
  // {
  //   municipio: "Amusco",
  //   provincia: "Palencia",
  //   id: "34011",
  //   label: "Amusco (Palencia)",
  // },
  // {
  //   municipio: "Cubillas de Cerrato",
  //   provincia: "Palencia",
  //   id: "34066",
  //   label: "Cubillas de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Dehesa de Montejo",
  //   provincia: "Palencia",
  //   id: "34067",
  //   label: "Dehesa de Montejo (Palencia)",
  // },
  // {
  //   municipio: "Arconada",
  //   provincia: "Palencia",
  //   id: "34015",
  //   label: "Arconada (Palencia)",
  // },
  // {
  //   municipio: "Autillo de Campos",
  //   provincia: "Palencia",
  //   id: "34019",
  //   label: "Autillo de Campos (Palencia)",
  // },
  // {
  //   municipio: "Astudillo",
  //   provincia: "Palencia",
  //   id: "34017",
  //   label: "Astudillo (Palencia)",
  // },
  // {
  //   municipio: "Autilla del Pino",
  //   provincia: "Palencia",
  //   id: "34018",
  //   label: "Autilla del Pino (Palencia)",
  // },
  // {
  //   municipio: "Baltanás",
  //   provincia: "Palencia",
  //   id: "34022",
  //   label: "Baltanás (Palencia)",
  // },
  // {
  //   municipio: "Berzosilla",
  //   provincia: "Palencia",
  //   id: "34032",
  //   label: "Berzosilla (Palencia)",
  // },
  // {
  //   municipio: "Barruelo de Santullán",
  //   provincia: "Palencia",
  //   id: "34027",
  //   label: "Barruelo de Santullán (Palencia)",
  // },
  // {
  //   municipio: "Báscones de Ojeda",
  //   provincia: "Palencia",
  //   id: "34028",
  //   label: "Báscones de Ojeda (Palencia)",
  // },
  // {
  //   municipio: "Becerril de Campos",
  //   provincia: "Palencia",
  //   id: "34029",
  //   label: "Becerril de Campos (Palencia)",
  // },
  // {
  //   municipio: "Belmonte de Campos",
  //   provincia: "Palencia",
  //   id: "34031",
  //   label: "Belmonte de Campos (Palencia)",
  // },
  // {
  //   municipio: "Dueñas",
  //   provincia: "Palencia",
  //   id: "34069",
  //   label: "Dueñas (Palencia)",
  // },
  // {
  //   municipio: "Aldeasoña",
  //   provincia: "Segovia",
  //   id: "40013",
  //   label: "Aldeasoña (Segovia)",
  // },
  // {
  //   municipio: "Espinosa de Cerrato",
  //   provincia: "Palencia",
  //   id: "34070",
  //   label: "Espinosa de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Boada de Campos",
  //   provincia: "Palencia",
  //   id: "34033",
  //   label: "Boada de Campos (Palencia)",
  // },
  // {
  //   municipio: "Boadilla del Camino",
  //   provincia: "Palencia",
  //   id: "34034",
  //   label: "Boadilla del Camino (Palencia)",
  // },
  // {
  //   municipio: "Bercimuel",
  //   provincia: "Segovia",
  //   id: "40029",
  //   label: "Bercimuel (Segovia)",
  // },
  // {
  //   municipio: "Brañosera",
  //   provincia: "Palencia",
  //   id: "34036",
  //   label: "Brañosera (Palencia)",
  // },
  // {
  //   municipio: "Pedrosa de la Vega",
  //   provincia: "Palencia",
  //   id: "34126",
  //   label: "Pedrosa de la Vega (Palencia)",
  // },
  // {
  //   municipio: "Buenavista de Valdavia",
  //   provincia: "Palencia",
  //   id: "34037",
  //   label: "Buenavista de Valdavia (Palencia)",
  // },
  // {
  //   municipio: "Bustillo de la Vega",
  //   provincia: "Palencia",
  //   id: "34038",
  //   label: "Bustillo de la Vega (Palencia)",
  // },
  // {
  //   municipio: "Bustillo del Páramo de Carrión",
  //   provincia: "Palencia",
  //   id: "34039",
  //   label: "Bustillo del Páramo de Carrión (Palencia)",
  // },
  // {
  //   municipio: "Carrión de los Condes",
  //   provincia: "Palencia",
  //   id: "34047",
  //   label: "Carrión de los Condes (Palencia)",
  // },
  // {
  //   municipio: "San Miguel del Robledo",
  //   provincia: "Salamanca",
  //   id: "37036",
  //   label: "San Miguel del Robledo (Salamanca)",
  // },
  // {
  //   municipio: "Castrejón de la Peña",
  //   provincia: "Palencia",
  //   id: "34049",
  //   label: "Castrejón de la Peña (Palencia)",
  // },
  // {
  //   municipio: "Castrillo de Villavega",
  //   provincia: "Palencia",
  //   id: "34052",
  //   label: "Castrillo de Villavega (Palencia)",
  // },
  // {
  //   municipio: "Cabezabellosa de la Calzada",
  //   provincia: "Salamanca",
  //   id: "37062",
  //   label: "Cabezabellosa de la Calzada (Salamanca)",
  // },
  // {
  //   municipio: "Castrillo de Don Juan",
  //   provincia: "Palencia",
  //   id: "34050",
  //   label: "Castrillo de Don Juan (Palencia)",
  // },
  // {
  //   municipio: "Castrillo de Onielo",
  //   provincia: "Palencia",
  //   id: "34051",
  //   label: "Castrillo de Onielo (Palencia)",
  // },
  // {
  //   municipio: "Castromocho",
  //   provincia: "Palencia",
  //   id: "34053",
  //   label: "Castromocho (Palencia)",
  // },
  // {
  //   municipio: "Cervatos de la Cueza",
  //   provincia: "Palencia",
  //   id: "34055",
  //   label: "Cervatos de la Cueza (Palencia)",
  // },
  // {
  //   municipio: "Cervera de Pisuerga",
  //   provincia: "Palencia",
  //   id: "34056",
  //   label: "Cervera de Pisuerga (Palencia)",
  // },
  // {
  //   municipio: "Barruecopardo",
  //   provincia: "Salamanca",
  //   id: "37044",
  //   label: "Barruecopardo (Salamanca)",
  // },
  // {
  //   municipio: "Cevico de la Torre",
  //   provincia: "Palencia",
  //   id: "34057",
  //   label: "Cevico de la Torre (Palencia)",
  // },
  // {
  //   municipio: "Cobos de Cerrato",
  //   provincia: "Palencia",
  //   id: "34060",
  //   label: "Cobos de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Cevico Navero",
  //   provincia: "Palencia",
  //   id: "34058",
  //   label: "Cevico Navero (Palencia)",
  // },
  // {
  //   municipio: "Cisneros",
  //   provincia: "Palencia",
  //   id: "34059",
  //   label: "Cisneros (Palencia)",
  // },
  // {
  //   municipio: "Collazos de Boedo",
  //   provincia: "Palencia",
  //   id: "34061",
  //   label: "Collazos de Boedo (Palencia)",
  // },
  // {
  //   municipio: "Frómista",
  //   provincia: "Palencia",
  //   id: "34074",
  //   label: "Frómista (Palencia)",
  // },
  // {
  //   municipio: "Congosto de Valdavia",
  //   provincia: "Palencia",
  //   id: "34062",
  //   label: "Congosto de Valdavia (Palencia)",
  // },
  // {
  //   municipio: "Fresno del Río",
  //   provincia: "Palencia",
  //   id: "34073",
  //   label: "Fresno del Río (Palencia)",
  // },
  // {
  //   municipio: "Valdevacas y Guijar",
  //   provincia: "Segovia",
  //   id: "40213",
  //   label: "Valdevacas y Guijar (Segovia)",
  // },
  // {
  //   municipio: "Fuentes de Nava",
  //   provincia: "Palencia",
  //   id: "34076",
  //   label: "Fuentes de Nava (Palencia)",
  // },
  // {
  //   municipio: "Fuentes de Valdepero",
  //   provincia: "Palencia",
  //   id: "34077",
  //   label: "Fuentes de Valdepero (Palencia)",
  // },
  // {
  //   municipio: "Grijota",
  //   provincia: "Palencia",
  //   id: "34079",
  //   label: "Grijota (Palencia)",
  // },
  // {
  //   municipio: "Hérmedes de Cerrato",
  //   provincia: "Palencia",
  //   id: "34082",
  //   label: "Hérmedes de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Herrera de Pisuerga",
  //   provincia: "Palencia",
  //   id: "34083",
  //   label: "Herrera de Pisuerga (Palencia)",
  // },
  // {
  //   municipio: "Ventosilla y Tejadilla",
  //   provincia: "Segovia",
  //   id: "40224",
  //   label: "Ventosilla y Tejadilla (Segovia)",
  // },
  // {
  //   municipio: "Guardo",
  //   provincia: "Palencia",
  //   id: "34080",
  //   label: "Guardo (Palencia)",
  // },
  // {
  //   municipio: "Guaza de Campos",
  //   provincia: "Palencia",
  //   id: "34081",
  //   label: "Guaza de Campos (Palencia)",
  // },
  // {
  //   municipio: "Herrera de Valdecañas",
  //   provincia: "Palencia",
  //   id: "34084",
  //   label: "Herrera de Valdecañas (Palencia)",
  // },
  // {
  //   municipio: "Hontoria de Cerrato",
  //   provincia: "Palencia",
  //   id: "34086",
  //   label: "Hontoria de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Hornillos de Cerrato",
  //   provincia: "Palencia",
  //   id: "34087",
  //   label: "Hornillos de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Husillos",
  //   provincia: "Palencia",
  //   id: "34088",
  //   label: "Husillos (Palencia)",
  // },
  // {
  //   municipio: "Llocnou de Sant Jeroni",
  //   provincia: "Valencia",
  //   id: "46153",
  //   label: "Llocnou de Sant Jeroni (Valencia)",
  // },
  // {
  //   municipio: "Valdeolmillos",
  //   provincia: "Palencia",
  //   id: "34189",
  //   label: "Valdeolmillos (Palencia)",
  // },
  // {
  //   municipio: "Valderrábano",
  //   provincia: "Palencia",
  //   id: "34190",
  //   label: "Valderrábano (Palencia)",
  // },
  // {
  //   municipio: "Valde-Ucieza",
  //   provincia: "Palencia",
  //   id: "34192",
  //   label: "Valde-Ucieza (Palencia)",
  // },
  // {
  //   municipio: "Lagartos",
  //   provincia: "Palencia",
  //   id: "34091",
  //   label: "Lagartos (Palencia)",
  // },
  // {
  //   municipio: "Lantadilla",
  //   provincia: "Palencia",
  //   id: "34092",
  //   label: "Lantadilla (Palencia)",
  // },
  // {
  //   municipio: "Pinedas",
  //   provincia: "Salamanca",
  //   id: "37252",
  //   label: "Pinedas (Salamanca)",
  // },
  // {
  //   municipio: "Vid de Ojeda, La",
  //   provincia: "Palencia",
  //   id: "34093",
  //   label: "Vid de Ojeda, La (Palencia)",
  // },
  // {
  //   municipio: "Ledigos",
  //   provincia: "Palencia",
  //   id: "34094",
  //   label: "Ledigos (Palencia)",
  // },
  // {
  //   municipio: "Lomas",
  //   provincia: "Palencia",
  //   id: "34096",
  //   label: "Lomas (Palencia)",
  // },
  // {
  //   municipio: "Magaz de Pisuerga",
  //   provincia: "Palencia",
  //   id: "34098",
  //   label: "Magaz de Pisuerga (Palencia)",
  // },
  // {
  //   municipio: "Manquillos",
  //   provincia: "Palencia",
  //   id: "34099",
  //   label: "Manquillos (Palencia)",
  // },
  // {
  //   municipio: "Mantinos",
  //   provincia: "Palencia",
  //   id: "34100",
  //   label: "Mantinos (Palencia)",
  // },
  // {
  //   municipio: "Meneses de Campos",
  //   provincia: "Palencia",
  //   id: "34106",
  //   label: "Meneses de Campos (Palencia)",
  // },
  // {
  //   municipio: "Micieces de Ojeda",
  //   provincia: "Palencia",
  //   id: "34107",
  //   label: "Micieces de Ojeda (Palencia)",
  // },
  // {
  //   municipio: "Poza de la Vega",
  //   provincia: "Palencia",
  //   id: "34136",
  //   label: "Poza de la Vega (Palencia)",
  // },
  // {
  //   municipio: "Monzón de Campos",
  //   provincia: "Palencia",
  //   id: "34108",
  //   label: "Monzón de Campos (Palencia)",
  // },
  // {
  //   municipio: "Moratinos",
  //   provincia: "Palencia",
  //   id: "34109",
  //   label: "Moratinos (Palencia)",
  // },
  // {
  //   municipio: "Valle de Cerrato",
  //   provincia: "Palencia",
  //   id: "34196",
  //   label: "Valle de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Nogal de las Huertas",
  //   provincia: "Palencia",
  //   id: "34112",
  //   label: "Nogal de las Huertas (Palencia)",
  // },
  // {
  //   municipio: "Pozo de Urama",
  //   provincia: "Palencia",
  //   id: "34137",
  //   label: "Pozo de Urama (Palencia)",
  // },
  // {
  //   municipio: "Olmos de Ojeda",
  //   provincia: "Palencia",
  //   id: "34114",
  //   label: "Olmos de Ojeda (Palencia)",
  // },
  // {
  //   municipio: "Osornillo",
  //   provincia: "Palencia",
  //   id: "34116",
  //   label: "Osornillo (Palencia)",
  // },
  // {
  //   municipio: "Palencia",
  //   provincia: "Palencia",
  //   id: "34120",
  //   label: "Palencia (Palencia)",
  // },
  // {
  //   municipio: "Palenzuela",
  //   provincia: "Palencia",
  //   id: "34121",
  //   label: "Palenzuela (Palencia)",
  // },
  // {
  //   municipio: "Páramo de Boedo",
  //   provincia: "Palencia",
  //   id: "34122",
  //   label: "Páramo de Boedo (Palencia)",
  // },
  // {
  //   municipio: "Perales",
  //   provincia: "Palencia",
  //   id: "34127",
  //   label: "Perales (Palencia)",
  // },
  // {
  //   municipio: "Paredes de Nava",
  //   provincia: "Palencia",
  //   id: "34123",
  //   label: "Paredes de Nava (Palencia)",
  // },
  // {
  //   municipio: "Payo de Ojeda",
  //   provincia: "Palencia",
  //   id: "34124",
  //   label: "Payo de Ojeda (Palencia)",
  // },
  // {
  //   municipio: "Pino del Río",
  //   provincia: "Palencia",
  //   id: "34129",
  //   label: "Pino del Río (Palencia)",
  // },
  // {
  //   municipio: "Polentinos",
  //   provincia: "Palencia",
  //   id: "34134",
  //   label: "Polentinos (Palencia)",
  // },
  // {
  //   municipio: "Quintana del Puente",
  //   provincia: "Palencia",
  //   id: "34141",
  //   label: "Quintana del Puente (Palencia)",
  // },
  // {
  //   municipio: "Pomar de Valdivia",
  //   provincia: "Palencia",
  //   id: "34135",
  //   label: "Pomar de Valdivia (Palencia)",
  // },
  // {
  //   municipio: "Prádanos de Ojeda",
  //   provincia: "Palencia",
  //   id: "34139",
  //   label: "Prádanos de Ojeda (Palencia)",
  // },
  // {
  //   municipio: "Puebla de Valdavia, La",
  //   provincia: "Palencia",
  //   id: "34140",
  //   label: "Puebla de Valdavia, La (Palencia)",
  // },
  // {
  //   municipio: "Renedo de la Vega",
  //   provincia: "Palencia",
  //   id: "34147",
  //   label: "Renedo de la Vega (Palencia)",
  // },
  // {
  //   municipio: "Quintanilla de Onsoña",
  //   provincia: "Palencia",
  //   id: "34143",
  //   label: "Quintanilla de Onsoña (Palencia)",
  // },
  // {
  //   municipio: "Reinoso de Cerrato",
  //   provincia: "Palencia",
  //   id: "34146",
  //   label: "Reinoso de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Requena de Campos",
  //   provincia: "Palencia",
  //   id: "34149",
  //   label: "Requena de Campos (Palencia)",
  // },
  // {
  //   municipio: "Respenda de la Peña",
  //   provincia: "Palencia",
  //   id: "34151",
  //   label: "Respenda de la Peña (Palencia)",
  // },
  // {
  //   municipio: "Revenga de Campos",
  //   provincia: "Palencia",
  //   id: "34152",
  //   label: "Revenga de Campos (Palencia)",
  // },
  // {
  //   municipio: "San Mamés de Campos",
  //   provincia: "Palencia",
  //   id: "34163",
  //   label: "San Mamés de Campos (Palencia)",
  // },
  // {
  //   municipio: "Revilla de Collazos",
  //   provincia: "Palencia",
  //   id: "34154",
  //   label: "Revilla de Collazos (Palencia)",
  // },
  // {
  //   municipio: "Ribas de Campos",
  //   provincia: "Palencia",
  //   id: "34155",
  //   label: "Ribas de Campos (Palencia)",
  // },
  // {
  //   municipio: "Riberos de la Cueza",
  //   provincia: "Palencia",
  //   id: "34156",
  //   label: "Riberos de la Cueza (Palencia)",
  // },
  // {
  //   municipio: "Saldaña",
  //   provincia: "Palencia",
  //   id: "34157",
  //   label: "Saldaña (Palencia)",
  // },
  // {
  //   municipio: "Tariego de Cerrato",
  //   provincia: "Palencia",
  //   id: "34181",
  //   label: "Tariego de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Salinas de Pisuerga",
  //   provincia: "Palencia",
  //   id: "34158",
  //   label: "Salinas de Pisuerga (Palencia)",
  // },
  // {
  //   municipio: "San Cebrián de Campos",
  //   provincia: "Palencia",
  //   id: "34159",
  //   label: "San Cebrián de Campos (Palencia)",
  // },
  // {
  //   municipio: "San Cebrián de Mudá",
  //   provincia: "Palencia",
  //   id: "34160",
  //   label: "San Cebrián de Mudá (Palencia)",
  // },
  // {
  //   municipio: "San Cristóbal de Boedo",
  //   provincia: "Palencia",
  //   id: "34161",
  //   label: "San Cristóbal de Boedo (Palencia)",
  // },
  // {
  //   municipio: "Torquemada",
  //   provincia: "Palencia",
  //   id: "34182",
  //   label: "Torquemada (Palencia)",
  // },
  // {
  //   municipio: "Torremormojón",
  //   provincia: "Palencia",
  //   id: "34184",
  //   label: "Torremormojón (Palencia)",
  // },
  // {
  //   municipio: "Triollo",
  //   provincia: "Palencia",
  //   id: "34185",
  //   label: "Triollo (Palencia)",
  // },
  // {
  //   municipio: "San Román de la Cuba",
  //   provincia: "Palencia",
  //   id: "34165",
  //   label: "San Román de la Cuba (Palencia)",
  // },
  // {
  //   municipio: "Santa Cruz de Boedo",
  //   provincia: "Palencia",
  //   id: "34168",
  //   label: "Santa Cruz de Boedo (Palencia)",
  // },
  // {
  //   municipio: "Santervás de la Vega",
  //   provincia: "Palencia",
  //   id: "34169",
  //   label: "Santervás de la Vega (Palencia)",
  // },
  // {
  //   municipio: "Santibáñez de la Peña",
  //   provincia: "Palencia",
  //   id: "34171",
  //   label: "Santibáñez de la Peña (Palencia)",
  // },
  // {
  //   municipio: "Santoyo",
  //   provincia: "Palencia",
  //   id: "34174",
  //   label: "Santoyo (Palencia)",
  // },
  // {
  //   municipio: "Sotobañado y Priorato",
  //   provincia: "Palencia",
  //   id: "34176",
  //   label: "Sotobañado y Priorato (Palencia)",
  // },
  // {
  //   municipio: "Soto de Cerrato",
  //   provincia: "Palencia",
  //   id: "34177",
  //   label: "Soto de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Tabanera de Cerrato",
  //   provincia: "Palencia",
  //   id: "34178",
  //   label: "Tabanera de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Tabanera de Valdavia",
  //   provincia: "Palencia",
  //   id: "34179",
  //   label: "Tabanera de Valdavia (Palencia)",
  // },
  // {
  //   municipio: "Velilla del Río Carrión",
  //   provincia: "Palencia",
  //   id: "34199",
  //   label: "Velilla del Río Carrión (Palencia)",
  // },
  // {
  //   municipio: "Vertavillo",
  //   provincia: "Palencia",
  //   id: "34201",
  //   label: "Vertavillo (Palencia)",
  // },
  // {
  //   municipio: "Villabasta de Valdavia",
  //   provincia: "Palencia",
  //   id: "34202",
  //   label: "Villabasta de Valdavia (Palencia)",
  // },
  // {
  //   municipio: "Villacidaler",
  //   provincia: "Palencia",
  //   id: "34204",
  //   label: "Villacidaler (Palencia)",
  // },
  // {
  //   municipio: "Villaconancio",
  //   provincia: "Palencia",
  //   id: "34205",
  //   label: "Villaconancio (Palencia)",
  // },
  // {
  //   municipio: "Villada",
  //   provincia: "Palencia",
  //   id: "34206",
  //   label: "Villada (Palencia)",
  // },
  // {
  //   municipio: "Villaeles de Valdavia",
  //   provincia: "Palencia",
  //   id: "34208",
  //   label: "Villaeles de Valdavia (Palencia)",
  // },
  // {
  //   municipio: "Villahán",
  //   provincia: "Palencia",
  //   id: "34210",
  //   label: "Villahán (Palencia)",
  // },
  // {
  //   municipio: "Villaherreros",
  //   provincia: "Palencia",
  //   id: "34211",
  //   label: "Villaherreros (Palencia)",
  // },
  // {
  //   municipio: "Villalba de Guardo",
  //   provincia: "Palencia",
  //   id: "34214",
  //   label: "Villalba de Guardo (Palencia)",
  // },
  // {
  //   municipio: "Villalcázar de Sirga",
  //   provincia: "Palencia",
  //   id: "34215",
  //   label: "Villalcázar de Sirga (Palencia)",
  // },
  // {
  //   municipio: "Villalcón",
  //   provincia: "Palencia",
  //   id: "34216",
  //   label: "Villalcón (Palencia)",
  // },
  // {
  //   municipio: "Villaluenga de la Vega",
  //   provincia: "Palencia",
  //   id: "34218",
  //   label: "Villaluenga de la Vega (Palencia)",
  // },
  // {
  //   municipio: "Villamartín de Campos",
  //   provincia: "Palencia",
  //   id: "34220",
  //   label: "Villamartín de Campos (Palencia)",
  // },
  // {
  //   municipio: "Arcones",
  //   provincia: "Segovia",
  //   id: "40020",
  //   label: "Arcones (Segovia)",
  // },
  // {
  //   municipio: "Villamediana",
  //   provincia: "Palencia",
  //   id: "34221",
  //   label: "Villamediana (Palencia)",
  // },
  // {
  //   municipio: "Villamuera de la Cueza",
  //   provincia: "Palencia",
  //   id: "34224",
  //   label: "Villamuera de la Cueza (Palencia)",
  // },
  // {
  //   municipio: "Villameriel",
  //   provincia: "Palencia",
  //   id: "34222",
  //   label: "Villameriel (Palencia)",
  // },
  // {
  //   municipio: "Arevalillo de Cega",
  //   provincia: "Segovia",
  //   id: "40021",
  //   label: "Arevalillo de Cega (Segovia)",
  // },
  // {
  //   municipio: "Villamuriel de Cerrato",
  //   provincia: "Palencia",
  //   id: "34225",
  //   label: "Villamuriel de Cerrato (Palencia)",
  // },
  // {
  //   municipio: "Villanuño de Valdavia",
  //   provincia: "Palencia",
  //   id: "34228",
  //   label: "Villanuño de Valdavia (Palencia)",
  // },
  // {
  //   municipio: "Villaprovedo",
  //   provincia: "Palencia",
  //   id: "34229",
  //   label: "Villaprovedo (Palencia)",
  // },
  // {
  //   municipio: "Villarmentero de Campos",
  //   provincia: "Palencia",
  //   id: "34230",
  //   label: "Villarmentero de Campos (Palencia)",
  // },
  // {
  //   municipio: "Villarrabé",
  //   provincia: "Palencia",
  //   id: "34231",
  //   label: "Villarrabé (Palencia)",
  // },
  // {
  //   municipio: "Villarramiel",
  //   provincia: "Palencia",
  //   id: "34232",
  //   label: "Villarramiel (Palencia)",
  // },
  // {
  //   municipio: "Villasarracino",
  //   provincia: "Palencia",
  //   id: "34233",
  //   label: "Villasarracino (Palencia)",
  // },
  // {
  //   municipio: "Cilleruelo de San Mamés",
  //   provincia: "Segovia",
  //   id: "40055",
  //   label: "Cilleruelo de San Mamés (Segovia)",
  // },
  // {
  //   municipio: "Villasila de Valdavia",
  //   provincia: "Palencia",
  //   id: "34234",
  //   label: "Villasila de Valdavia (Palencia)",
  // },
  // {
  //   municipio: "Villaturde",
  //   provincia: "Palencia",
  //   id: "34236",
  //   label: "Villaturde (Palencia)",
  // },
  // {
  //   municipio: "Guijo de Ávila",
  //   provincia: "Salamanca",
  //   id: "37155",
  //   label: "Guijo de Ávila (Salamanca)",
  // },
  // {
  //   municipio: "Villaumbrales",
  //   provincia: "Palencia",
  //   id: "34237",
  //   label: "Villaumbrales (Palencia)",
  // },
  // {
  //   municipio: "Villaviudas",
  //   provincia: "Palencia",
  //   id: "34238",
  //   label: "Villaviudas (Palencia)",
  // },
  // {
  //   municipio: "Cobos de Fuentidueña",
  //   provincia: "Segovia",
  //   id: "40056",
  //   label: "Cobos de Fuentidueña (Segovia)",
  // },
  // {
  //   municipio: "Villoldo",
  //   provincia: "Palencia",
  //   id: "34243",
  //   label: "Villoldo (Palencia)",
  // },
  // {
  //   municipio: "Aldearrubia",
  //   provincia: "Salamanca",
  //   id: "37020",
  //   label: "Aldearrubia (Salamanca)",
  // },
  // {
  //   municipio: "Villota del Páramo",
  //   provincia: "Palencia",
  //   id: "34245",
  //   label: "Villota del Páramo (Palencia)",
  // },
  // {
  //   municipio: "Villovieco",
  //   provincia: "Palencia",
  //   id: "34246",
  //   label: "Villovieco (Palencia)",
  // },
  // {
  //   municipio: "Montserrat",
  //   provincia: "Valencia",
  //   id: "46172",
  //   label: "Montserrat (Valencia)",
  // },
  // {
  //   municipio: "Osorno la Mayor",
  //   provincia: "Palencia",
  //   id: "34901",
  //   label: "Osorno la Mayor (Palencia)",
  // },
  // {
  //   municipio: "Valle del Retortillo",
  //   provincia: "Palencia",
  //   id: "34902",
  //   label: "Valle del Retortillo (Palencia)",
  // },
  // {
  //   municipio: "Loma de Ucieza",
  //   provincia: "Palencia",
  //   id: "34903",
  //   label: "Loma de Ucieza (Palencia)",
  // },
  // {
  //   municipio: "Pernía, La",
  //   provincia: "Palencia",
  //   id: "34904",
  //   label: "Pernía, La (Palencia)",
  // },
  // {
  //   municipio: "San Bartolomé",
  //   provincia: "Las Palmas",
  //   id: "35018",
  //   label: "San Bartolomé (Las Palmas)",
  // },
  // {
  //   municipio: "Tías",
  //   provincia: "Las Palmas",
  //   id: "35028",
  //   label: "Tías (Las Palmas)",
  // },
  // {
  //   municipio: "Arbo",
  //   provincia: "Pontevedra",
  //   id: "36001",
  //   label: "Arbo (Pontevedra)",
  // },
  // {
  //   municipio: "Barro",
  //   provincia: "Pontevedra",
  //   id: "36002",
  //   label: "Barro (Pontevedra)",
  // },
  // {
  //   municipio: "Meaño",
  //   provincia: "Pontevedra",
  //   id: "36027",
  //   label: "Meaño (Pontevedra)",
  // },
  // {
  //   municipio: "Caldas de Reis",
  //   provincia: "Pontevedra",
  //   id: "36005",
  //   label: "Caldas de Reis (Pontevedra)",
  // },
  // {
  //   municipio: "Aldealpozo",
  //   provincia: "Soria",
  //   id: "42011",
  //   label: "Aldealpozo (Soria)",
  // },
  // {
  //   municipio: "Campo Lameiro",
  //   provincia: "Pontevedra",
  //   id: "36007",
  //   label: "Campo Lameiro (Pontevedra)",
  // },
  // {
  //   municipio: "Cañiza, A",
  //   provincia: "Pontevedra",
  //   id: "36009",
  //   label: "Cañiza, A (Pontevedra)",
  // },
  // {
  //   municipio: "Aldealseñor",
  //   provincia: "Soria",
  //   id: "42012",
  //   label: "Aldealseñor (Soria)",
  // },
  // {
  //   municipio: "Catoira",
  //   provincia: "Pontevedra",
  //   id: "36010",
  //   label: "Catoira (Pontevedra)",
  // },
  // {
  //   municipio: "Cabañas de Polendos",
  //   provincia: "Segovia",
  //   id: "40035",
  //   label: "Cabañas de Polendos (Segovia)",
  // },
  // {
  //   municipio: "Covelo",
  //   provincia: "Pontevedra",
  //   id: "36013",
  //   label: "Covelo (Pontevedra)",
  // },
  // {
  //   municipio: "Aldeavieja de Tormes",
  //   provincia: "Salamanca",
  //   id: "37024",
  //   label: "Aldeavieja de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "Crecente",
  //   provincia: "Pontevedra",
  //   id: "36014",
  //   label: "Crecente (Pontevedra)",
  // },
  // {
  //   municipio: "Aldehuela de Periáñez",
  //   provincia: "Soria",
  //   id: "42013",
  //   label: "Aldehuela de Periáñez (Soria)",
  // },
  // {
  //   municipio: "Cuntis",
  //   provincia: "Pontevedra",
  //   id: "36015",
  //   label: "Cuntis (Pontevedra)",
  // },
  // {
  //   municipio: "Cantimpalos",
  //   provincia: "Segovia",
  //   id: "40041",
  //   label: "Cantimpalos (Segovia)",
  // },
  // {
  //   municipio: "Dozón",
  //   provincia: "Pontevedra",
  //   id: "36016",
  //   label: "Dozón (Pontevedra)",
  // },
  // {
  //   municipio: "Estrada, A",
  //   provincia: "Pontevedra",
  //   id: "36017",
  //   label: "Estrada, A (Pontevedra)",
  // },
  // {
  //   municipio: "Lumbrales",
  //   provincia: "Salamanca",
  //   id: "37173",
  //   label: "Lumbrales (Salamanca)",
  // },
  // {
  //   municipio: "Forcarei",
  //   provincia: "Pontevedra",
  //   id: "36018",
  //   label: "Forcarei (Pontevedra)",
  // },
  // {
  //   municipio: "Fornelos de Montes",
  //   provincia: "Pontevedra",
  //   id: "36019",
  //   label: "Fornelos de Montes (Pontevedra)",
  // },
  // {
  //   municipio: "Agolada",
  //   provincia: "Pontevedra",
  //   id: "36020",
  //   label: "Agolada (Pontevedra)",
  // },
  // {
  //   municipio: "Caracena",
  //   provincia: "Soria",
  //   id: "42052",
  //   label: "Caracena (Soria)",
  // },
  // {
  //   municipio: "Gondomar",
  //   provincia: "Pontevedra",
  //   id: "36021",
  //   label: "Gondomar (Pontevedra)",
  // },
  // {
  //   municipio: "Guarda, A",
  //   provincia: "Pontevedra",
  //   id: "36023",
  //   label: "Guarda, A (Pontevedra)",
  // },
  // {
  //   municipio: "Almenara de Tormes",
  //   provincia: "Salamanca",
  //   id: "37027",
  //   label: "Almenara de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "Lama, A",
  //   provincia: "Pontevedra",
  //   id: "36025",
  //   label: "Lama, A (Pontevedra)",
  // },
  // {
  //   municipio: "Marín",
  //   provincia: "Pontevedra",
  //   id: "36026",
  //   label: "Marín (Pontevedra)",
  // },
  // {
  //   municipio: "Meis",
  //   provincia: "Pontevedra",
  //   id: "36028",
  //   label: "Meis (Pontevedra)",
  // },
  // {
  //   municipio: "Moaña",
  //   provincia: "Pontevedra",
  //   id: "36029",
  //   label: "Moaña (Pontevedra)",
  // },
  // {
  //   municipio: "Mondariz",
  //   provincia: "Pontevedra",
  //   id: "36030",
  //   label: "Mondariz (Pontevedra)",
  // },
  // {
  //   municipio: "Mondariz-Balneario",
  //   provincia: "Pontevedra",
  //   id: "36031",
  //   label: "Mondariz-Balneario (Pontevedra)",
  // },
  // {
  //   municipio: "Moraña",
  //   provincia: "Pontevedra",
  //   id: "36032",
  //   label: "Moraña (Pontevedra)",
  // },
  // {
  //   municipio: "Rinconada de la Sierra, La",
  //   provincia: "Salamanca",
  //   id: "37268",
  //   label: "Rinconada de la Sierra, La (Salamanca)",
  // },
  // {
  //   municipio: "Mos",
  //   provincia: "Pontevedra",
  //   id: "36033",
  //   label: "Mos (Pontevedra)",
  // },
  // {
  //   municipio: "Pazos de Borbén",
  //   provincia: "Pontevedra",
  //   id: "36037",
  //   label: "Pazos de Borbén (Pontevedra)",
  // },
  // {
  //   municipio: "Pontevedra",
  //   provincia: "Pontevedra",
  //   id: "36038",
  //   label: "Pontevedra (Pontevedra)",
  // },
  // {
  //   municipio: "Neves, As",
  //   provincia: "Pontevedra",
  //   id: "36034",
  //   label: "Neves, As (Pontevedra)",
  // },
  // {
  //   municipio: "Pozalmuro",
  //   provincia: "Soria",
  //   id: "42142",
  //   label: "Pozalmuro (Soria)",
  // },
  // {
  //   municipio: "Porriño, O",
  //   provincia: "Pontevedra",
  //   id: "36039",
  //   label: "Porriño, O (Pontevedra)",
  // },
  // {
  //   municipio: "Pontecesures",
  //   provincia: "Pontevedra",
  //   id: "36044",
  //   label: "Pontecesures (Pontevedra)",
  // },
  // {
  //   municipio: "Redondela",
  //   provincia: "Pontevedra",
  //   id: "36045",
  //   label: "Redondela (Pontevedra)",
  // },
  // {
  //   municipio: "Portas",
  //   provincia: "Pontevedra",
  //   id: "36040",
  //   label: "Portas (Pontevedra)",
  // },
  // {
  //   municipio: "Ponteareas",
  //   provincia: "Pontevedra",
  //   id: "36042",
  //   label: "Ponteareas (Pontevedra)",
  // },
  // {
  //   municipio: "Collado Hermoso",
  //   provincia: "Segovia",
  //   id: "40059",
  //   label: "Collado Hermoso (Segovia)",
  // },
  // {
  //   municipio: "Ponte Caldelas",
  //   provincia: "Pontevedra",
  //   id: "36043",
  //   label: "Ponte Caldelas (Pontevedra)",
  // },
  // {
  //   municipio: "Condado de Castilnovo",
  //   provincia: "Segovia",
  //   id: "40060",
  //   label: "Condado de Castilnovo (Segovia)",
  // },
  // {
  //   municipio: "Ribadumia",
  //   provincia: "Pontevedra",
  //   id: "36046",
  //   label: "Ribadumia (Pontevedra)",
  // },
  // {
  //   municipio: "Rodeiro",
  //   provincia: "Pontevedra",
  //   id: "36047",
  //   label: "Rodeiro (Pontevedra)",
  // },
  // {
  //   municipio: "Rosal, O",
  //   provincia: "Pontevedra",
  //   id: "36048",
  //   label: "Rosal, O (Pontevedra)",
  // },
  // {
  //   municipio: "Salceda de Caselas",
  //   provincia: "Pontevedra",
  //   id: "36049",
  //   label: "Salceda de Caselas (Pontevedra)",
  // },
  // {
  //   municipio: "Salvaterra de Miño",
  //   provincia: "Pontevedra",
  //   id: "36050",
  //   label: "Salvaterra de Miño (Pontevedra)",
  // },
  // {
  //   municipio: "Silleda",
  //   provincia: "Pontevedra",
  //   id: "36052",
  //   label: "Silleda (Pontevedra)",
  // },
  // {
  //   municipio: "Soutomaior",
  //   provincia: "Pontevedra",
  //   id: "36053",
  //   label: "Soutomaior (Pontevedra)",
  // },
  // {
  //   municipio: "Abusejo",
  //   provincia: "Salamanca",
  //   id: "37001",
  //   label: "Abusejo (Salamanca)",
  // },
  // {
  //   municipio: "Tomiño",
  //   provincia: "Pontevedra",
  //   id: "36054",
  //   label: "Tomiño (Pontevedra)",
  // },
  // {
  //   municipio: "Alameda de Gardón, La",
  //   provincia: "Salamanca",
  //   id: "37005",
  //   label: "Alameda de Gardón, La (Salamanca)",
  // },
  // {
  //   municipio: "Tui",
  //   provincia: "Pontevedra",
  //   id: "36055",
  //   label: "Tui (Pontevedra)",
  // },
  // {
  //   municipio: "Valga",
  //   provincia: "Pontevedra",
  //   id: "36056",
  //   label: "Valga (Pontevedra)",
  // },
  // {
  //   municipio: "Agallas",
  //   provincia: "Salamanca",
  //   id: "37002",
  //   label: "Agallas (Salamanca)",
  // },
  // {
  //   municipio: "Monleón",
  //   provincia: "Salamanca",
  //   id: "37197",
  //   label: "Monleón (Salamanca)",
  // },
  // {
  //   municipio: "Ahigal de los Aceiteros",
  //   provincia: "Salamanca",
  //   id: "37003",
  //   label: "Ahigal de los Aceiteros (Salamanca)",
  // },
  // {
  //   municipio: "Ahigal de Villarino",
  //   provincia: "Salamanca",
  //   id: "37004",
  //   label: "Ahigal de Villarino (Salamanca)",
  // },
  // {
  //   municipio: "Moriscos",
  //   provincia: "Salamanca",
  //   id: "37207",
  //   label: "Moriscos (Salamanca)",
  // },
  // {
  //   municipio: "Alamedilla, La",
  //   provincia: "Salamanca",
  //   id: "37006",
  //   label: "Alamedilla, La (Salamanca)",
  // },
  // {
  //   municipio: "Alconada",
  //   provincia: "Salamanca",
  //   id: "37012",
  //   label: "Alconada (Salamanca)",
  // },
  // {
  //   municipio: "Alaraz",
  //   provincia: "Salamanca",
  //   id: "37007",
  //   label: "Alaraz (Salamanca)",
  // },
  // {
  //   municipio: "Alba de Tormes",
  //   provincia: "Salamanca",
  //   id: "37008",
  //   label: "Alba de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "Alba de Yeltes",
  //   provincia: "Salamanca",
  //   id: "37009",
  //   label: "Alba de Yeltes (Salamanca)",
  // },
  // {
  //   municipio: "Alberca, La",
  //   provincia: "Salamanca",
  //   id: "37010",
  //   label: "Alberca, La (Salamanca)",
  // },
  // {
  //   municipio: "Alberguería de Argañán, La",
  //   provincia: "Salamanca",
  //   id: "37011",
  //   label: "Alberguería de Argañán, La (Salamanca)",
  // },
  // {
  //   municipio: "Aldeacipreste",
  //   provincia: "Salamanca",
  //   id: "37013",
  //   label: "Aldeacipreste (Salamanca)",
  // },
  // {
  //   municipio: "Aldeadávila de la Ribera",
  //   provincia: "Salamanca",
  //   id: "37014",
  //   label: "Aldeadávila de la Ribera (Salamanca)",
  // },
  // {
  //   municipio: "Aldea del Obispo",
  //   provincia: "Salamanca",
  //   id: "37015",
  //   label: "Aldea del Obispo (Salamanca)",
  // },
  // {
  //   municipio: "Aldealengua",
  //   provincia: "Salamanca",
  //   id: "37016",
  //   label: "Aldealengua (Salamanca)",
  // },
  // {
  //   municipio: "Aldeanueva de Figueroa",
  //   provincia: "Salamanca",
  //   id: "37017",
  //   label: "Aldeanueva de Figueroa (Salamanca)",
  // },
  // {
  //   municipio: "Aldeanueva de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37018",
  //   label: "Aldeanueva de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Bastida, La",
  //   provincia: "Salamanca",
  //   id: "37045",
  //   label: "Bastida, La (Salamanca)",
  // },
  // {
  //   municipio: "Cabeza del Caballo",
  //   provincia: "Salamanca",
  //   id: "37065",
  //   label: "Cabeza del Caballo (Salamanca)",
  // },
  // {
  //   municipio: "Aldearrodrigo",
  //   provincia: "Salamanca",
  //   id: "37019",
  //   label: "Aldearrodrigo (Salamanca)",
  // },
  // {
  //   municipio: "Navacarros",
  //   provincia: "Salamanca",
  //   id: "37212",
  //   label: "Navacarros (Salamanca)",
  // },
  // {
  //   municipio: "Aldeaseca de Alba",
  //   provincia: "Salamanca",
  //   id: "37021",
  //   label: "Aldeaseca de Alba (Salamanca)",
  // },
  // {
  //   municipio: "Aldeaseca de la Frontera",
  //   provincia: "Salamanca",
  //   id: "37022",
  //   label: "Aldeaseca de la Frontera (Salamanca)",
  // },
  // {
  //   municipio: "Aldeatejada",
  //   provincia: "Salamanca",
  //   id: "37023",
  //   label: "Aldeatejada (Salamanca)",
  // },
  // {
  //   municipio: "Benafarces",
  //   provincia: "Valladolid",
  //   id: "47016",
  //   label: "Benafarces (Valladolid)",
  // },
  // {
  //   municipio: "Aldehuela de la Bóveda",
  //   provincia: "Salamanca",
  //   id: "37025",
  //   label: "Aldehuela de la Bóveda (Salamanca)",
  // },
  // {
  //   municipio: "Nava de Béjar",
  //   provincia: "Salamanca",
  //   id: "37213",
  //   label: "Nava de Béjar (Salamanca)",
  // },
  // {
  //   municipio: "Aldehuela de Yeltes",
  //   provincia: "Salamanca",
  //   id: "37026",
  //   label: "Aldehuela de Yeltes (Salamanca)",
  // },
  // {
  //   municipio: "Almendra",
  //   provincia: "Salamanca",
  //   id: "37028",
  //   label: "Almendra (Salamanca)",
  // },
  // {
  //   municipio: "Anaya de Alba",
  //   provincia: "Salamanca",
  //   id: "37029",
  //   label: "Anaya de Alba (Salamanca)",
  // },
  // {
  //   municipio: "Añover de Tormes",
  //   provincia: "Salamanca",
  //   id: "37030",
  //   label: "Añover de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "Arabayona de Mógica",
  //   provincia: "Salamanca",
  //   id: "37031",
  //   label: "Arabayona de Mógica (Salamanca)",
  // },
  // {
  //   municipio: "Arcediano",
  //   provincia: "Salamanca",
  //   id: "37033",
  //   label: "Arcediano (Salamanca)",
  // },
  // {
  //   municipio: "Arapiles",
  //   provincia: "Salamanca",
  //   id: "37032",
  //   label: "Arapiles (Salamanca)",
  // },
  // {
  //   municipio: "Cabrillas",
  //   provincia: "Salamanca",
  //   id: "37068",
  //   label: "Cabrillas (Salamanca)",
  // },
  // {
  //   municipio: "Arco, El",
  //   provincia: "Salamanca",
  //   id: "37034",
  //   label: "Arco, El (Salamanca)",
  // },
  // {
  //   municipio: "Armenteros",
  //   provincia: "Salamanca",
  //   id: "37035",
  //   label: "Armenteros (Salamanca)",
  // },
  // {
  //   municipio: "Atalaya, La",
  //   provincia: "Salamanca",
  //   id: "37037",
  //   label: "Atalaya, La (Salamanca)",
  // },
  // {
  //   municipio: "Babilafuente",
  //   provincia: "Salamanca",
  //   id: "37038",
  //   label: "Babilafuente (Salamanca)",
  // },
  // {
  //   municipio: "Bañobárez",
  //   provincia: "Salamanca",
  //   id: "37039",
  //   label: "Bañobárez (Salamanca)",
  // },
  // {
  //   municipio: "Barbadillo",
  //   provincia: "Salamanca",
  //   id: "37040",
  //   label: "Barbadillo (Salamanca)",
  // },
  // {
  //   municipio: "Barbalos",
  //   provincia: "Salamanca",
  //   id: "37041",
  //   label: "Barbalos (Salamanca)",
  // },
  // {
  //   municipio: "Barceo",
  //   provincia: "Salamanca",
  //   id: "37042",
  //   label: "Barceo (Salamanca)",
  // },
  // {
  //   municipio: "Béjar",
  //   provincia: "Salamanca",
  //   id: "37046",
  //   label: "Béjar (Salamanca)",
  // },
  // {
  //   municipio: "Bóveda del Río Almar",
  //   provincia: "Salamanca",
  //   id: "37057",
  //   label: "Bóveda del Río Almar (Salamanca)",
  // },
  // {
  //   municipio: "Beleña",
  //   provincia: "Salamanca",
  //   id: "37047",
  //   label: "Beleña (Salamanca)",
  // },
  // {
  //   municipio: "Bermellar",
  //   provincia: "Salamanca",
  //   id: "37049",
  //   label: "Bermellar (Salamanca)",
  // },
  // {
  //   municipio: "Berrocal de Huebra",
  //   provincia: "Salamanca",
  //   id: "37050",
  //   label: "Berrocal de Huebra (Salamanca)",
  // },
  // {
  //   municipio: "Berrocal de Salvatierra",
  //   provincia: "Salamanca",
  //   id: "37051",
  //   label: "Berrocal de Salvatierra (Salamanca)",
  // },
  // {
  //   municipio: "Boada",
  //   provincia: "Salamanca",
  //   id: "37052",
  //   label: "Boada (Salamanca)",
  // },
  // {
  //   municipio: "Bogajo",
  //   provincia: "Salamanca",
  //   id: "37055",
  //   label: "Bogajo (Salamanca)",
  // },
  // {
  //   municipio: "Bodón, El",
  //   provincia: "Salamanca",
  //   id: "37054",
  //   label: "Bodón, El (Salamanca)",
  // },
  // {
  //   municipio: "Brincones",
  //   provincia: "Salamanca",
  //   id: "37058",
  //   label: "Brincones (Salamanca)",
  // },
  // {
  //   municipio: "Bouza, La",
  //   provincia: "Salamanca",
  //   id: "37056",
  //   label: "Bouza, La (Salamanca)",
  // },
  // {
  //   municipio: "Buenamadre",
  //   provincia: "Salamanca",
  //   id: "37059",
  //   label: "Buenamadre (Salamanca)",
  // },
  // {
  //   municipio: "Buenavista",
  //   provincia: "Salamanca",
  //   id: "37060",
  //   label: "Buenavista (Salamanca)",
  // },
  // {
  //   municipio: "Campillo de Azaba",
  //   provincia: "Salamanca",
  //   id: "37074",
  //   label: "Campillo de Azaba (Salamanca)",
  // },
  // {
  //   municipio: "Cabaco, El",
  //   provincia: "Salamanca",
  //   id: "37061",
  //   label: "Cabaco, El (Salamanca)",
  // },
  // {
  //   municipio: "Cabeza de Béjar, La",
  //   provincia: "Salamanca",
  //   id: "37063",
  //   label: "Cabeza de Béjar, La (Salamanca)",
  // },
  // {
  //   municipio: "Calvarrasa de Abajo",
  //   provincia: "Salamanca",
  //   id: "37069",
  //   label: "Calvarrasa de Abajo (Salamanca)",
  // },
  // {
  //   municipio: "Campo de Peñaranda, El",
  //   provincia: "Salamanca",
  //   id: "37077",
  //   label: "Campo de Peñaranda, El (Salamanca)",
  // },
  // {
  //   municipio: "Calvarrasa de Arriba",
  //   provincia: "Salamanca",
  //   id: "37070",
  //   label: "Calvarrasa de Arriba (Salamanca)",
  // },
  // {
  //   municipio: "Calzada de Béjar, La",
  //   provincia: "Salamanca",
  //   id: "37071",
  //   label: "Calzada de Béjar, La (Salamanca)",
  // },
  // {
  //   municipio: "Calzada de Don Diego",
  //   provincia: "Salamanca",
  //   id: "37072",
  //   label: "Calzada de Don Diego (Salamanca)",
  // },
  // {
  //   municipio: "Calzada de Valdunciel",
  //   provincia: "Salamanca",
  //   id: "37073",
  //   label: "Calzada de Valdunciel (Salamanca)",
  // },
  // {
  //   municipio: "Candelario",
  //   provincia: "Salamanca",
  //   id: "37078",
  //   label: "Candelario (Salamanca)",
  // },
  // {
  //   municipio: "Canillas de Abajo",
  //   provincia: "Salamanca",
  //   id: "37079",
  //   label: "Canillas de Abajo (Salamanca)",
  // },
  // {
  //   municipio: "Cantalapiedra",
  //   provincia: "Salamanca",
  //   id: "37081",
  //   label: "Cantalapiedra (Salamanca)",
  // },
  // {
  //   municipio: "Colmenar de Montemayor",
  //   provincia: "Salamanca",
  //   id: "37109",
  //   label: "Colmenar de Montemayor (Salamanca)",
  // },
  // {
  //   municipio: "Cantalpino",
  //   provincia: "Salamanca",
  //   id: "37082",
  //   label: "Cantalpino (Salamanca)",
  // },
  // {
  //   municipio: "Cordovilla",
  //   provincia: "Salamanca",
  //   id: "37110",
  //   label: "Cordovilla (Salamanca)",
  // },
  // {
  //   municipio: "Cantaracillo",
  //   provincia: "Salamanca",
  //   id: "37083",
  //   label: "Cantaracillo (Salamanca)",
  // },
  // {
  //   municipio: "Casas del Conde, Las",
  //   provincia: "Salamanca",
  //   id: "37090",
  //   label: "Casas del Conde, Las (Salamanca)",
  // },
  // {
  //   municipio: "Nava de Francia",
  //   provincia: "Salamanca",
  //   id: "37214",
  //   label: "Nava de Francia (Salamanca)",
  // },
  // {
  //   municipio: "Carbajosa de la Sagrada",
  //   provincia: "Salamanca",
  //   id: "37085",
  //   label: "Carbajosa de la Sagrada (Salamanca)",
  // },
  // {
  //   municipio: "Cepeda",
  //   provincia: "Salamanca",
  //   id: "37098",
  //   label: "Cepeda (Salamanca)",
  // },
  // {
  //   municipio: "Carpio de Azaba",
  //   provincia: "Salamanca",
  //   id: "37086",
  //   label: "Carpio de Azaba (Salamanca)",
  // },
  // {
  //   municipio: "Carrascal del Obispo",
  //   provincia: "Salamanca",
  //   id: "37088",
  //   label: "Carrascal del Obispo (Salamanca)",
  // },
  // {
  //   municipio: "Casafranca",
  //   provincia: "Salamanca",
  //   id: "37089",
  //   label: "Casafranca (Salamanca)",
  // },
  // {
  //   municipio: "Casillas de Flores",
  //   provincia: "Salamanca",
  //   id: "37091",
  //   label: "Casillas de Flores (Salamanca)",
  // },
  // {
  //   municipio: "Cereceda de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37099",
  //   label: "Cereceda de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Castellanos de Moriscos",
  //   provincia: "Salamanca",
  //   id: "37092",
  //   label: "Castellanos de Moriscos (Salamanca)",
  // },
  // {
  //   municipio: "Castillejo de Martín Viejo",
  //   provincia: "Salamanca",
  //   id: "37096",
  //   label: "Castillejo de Martín Viejo (Salamanca)",
  // },
  // {
  //   municipio: "Castraz",
  //   provincia: "Salamanca",
  //   id: "37097",
  //   label: "Castraz (Salamanca)",
  // },
  // {
  //   municipio: "Cerralbo",
  //   provincia: "Salamanca",
  //   id: "37101",
  //   label: "Cerralbo (Salamanca)",
  // },
  // {
  //   municipio: "Cerro, El",
  //   provincia: "Salamanca",
  //   id: "37102",
  //   label: "Cerro, El (Salamanca)",
  // },
  // {
  //   municipio: "Navamorales",
  //   provincia: "Salamanca",
  //   id: "37218",
  //   label: "Navamorales (Salamanca)",
  // },
  // {
  //   municipio: "Cespedosa de Tormes",
  //   provincia: "Salamanca",
  //   id: "37103",
  //   label: "Cespedosa de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "Cilleros de la Bastida",
  //   provincia: "Salamanca",
  //   id: "37104",
  //   label: "Cilleros de la Bastida (Salamanca)",
  // },
  // {
  //   municipio: "Cipérez",
  //   provincia: "Salamanca",
  //   id: "37106",
  //   label: "Cipérez (Salamanca)",
  // },
  // {
  //   municipio: "Ciudad Rodrigo",
  //   provincia: "Salamanca",
  //   id: "37107",
  //   label: "Ciudad Rodrigo (Salamanca)",
  // },
  // {
  //   municipio: "Navarredonda de la Rinconada",
  //   provincia: "Salamanca",
  //   id: "37219",
  //   label: "Navarredonda de la Rinconada (Salamanca)",
  // },
  // {
  //   municipio: "Coca de Alba",
  //   provincia: "Salamanca",
  //   id: "37108",
  //   label: "Coca de Alba (Salamanca)",
  // },
  // {
  //   municipio: "Cristóbal",
  //   provincia: "Salamanca",
  //   id: "37112",
  //   label: "Cristóbal (Salamanca)",
  // },
  // {
  //   municipio: "Cubo de Don Sancho, El",
  //   provincia: "Salamanca",
  //   id: "37113",
  //   label: "Cubo de Don Sancho, El (Salamanca)",
  // },
  // {
  //   municipio: "Chagarcía Medianero",
  //   provincia: "Salamanca",
  //   id: "37114",
  //   label: "Chagarcía Medianero (Salamanca)",
  // },
  // {
  //   municipio: "Dios le Guarde",
  //   provincia: "Salamanca",
  //   id: "37115",
  //   label: "Dios le Guarde (Salamanca)",
  // },
  // {
  //   municipio: "Palencia de Negrilla",
  //   provincia: "Salamanca",
  //   id: "37229",
  //   label: "Palencia de Negrilla (Salamanca)",
  // },
  // {
  //   municipio: "Doñinos de Ledesma",
  //   provincia: "Salamanca",
  //   id: "37116",
  //   label: "Doñinos de Ledesma (Salamanca)",
  // },
  // {
  //   municipio: "Encina de San Silvestre",
  //   provincia: "Salamanca",
  //   id: "37120",
  //   label: "Encina de San Silvestre (Salamanca)",
  // },
  // {
  //   municipio: "Doñinos de Salamanca",
  //   provincia: "Salamanca",
  //   id: "37117",
  //   label: "Doñinos de Salamanca (Salamanca)",
  // },
  // {
  //   municipio: "Encinas de Abajo",
  //   provincia: "Salamanca",
  //   id: "37121",
  //   label: "Encinas de Abajo (Salamanca)",
  // },
  // {
  //   municipio: "Éjeme",
  //   provincia: "Salamanca",
  //   id: "37118",
  //   label: "Éjeme (Salamanca)",
  // },
  // {
  //   municipio: "Encinas de Arriba",
  //   provincia: "Salamanca",
  //   id: "37122",
  //   label: "Encinas de Arriba (Salamanca)",
  // },
  // {
  //   municipio: "Encina, La",
  //   provincia: "Salamanca",
  //   id: "37119",
  //   label: "Encina, La (Salamanca)",
  // },
  // {
  //   municipio: "Encinasola de los Comendadores",
  //   provincia: "Salamanca",
  //   id: "37123",
  //   label: "Encinasola de los Comendadores (Salamanca)",
  // },
  // {
  //   municipio: "Endrinal",
  //   provincia: "Salamanca",
  //   id: "37124",
  //   label: "Endrinal (Salamanca)",
  // },
  // {
  //   municipio: "Escurial de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37125",
  //   label: "Escurial de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Espadaña",
  //   provincia: "Salamanca",
  //   id: "37126",
  //   label: "Espadaña (Salamanca)",
  // },
  // {
  //   municipio: "Espino de la Orbada",
  //   provincia: "Salamanca",
  //   id: "37128",
  //   label: "Espino de la Orbada (Salamanca)",
  // },
  // {
  //   municipio: "Espeja",
  //   provincia: "Salamanca",
  //   id: "37127",
  //   label: "Espeja (Salamanca)",
  // },
  // {
  //   municipio: "Florida de Liébana",
  //   provincia: "Salamanca",
  //   id: "37129",
  //   label: "Florida de Liébana (Salamanca)",
  // },
  // {
  //   municipio: "Forfoleda",
  //   provincia: "Salamanca",
  //   id: "37130",
  //   label: "Forfoleda (Salamanca)",
  // },
  // {
  //   municipio: "Frades de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37131",
  //   label: "Frades de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Fresno Alhándiga",
  //   provincia: "Salamanca",
  //   id: "37134",
  //   label: "Fresno Alhándiga (Salamanca)",
  // },
  // {
  //   municipio: "Saldeana",
  //   provincia: "Salamanca",
  //   id: "37275",
  //   label: "Saldeana (Salamanca)",
  // },
  // {
  //   municipio: "Fregeneda, La",
  //   provincia: "Salamanca",
  //   id: "37132",
  //   label: "Fregeneda, La (Salamanca)",
  // },
  // {
  //   municipio: "Fresnedoso",
  //   provincia: "Salamanca",
  //   id: "37133",
  //   label: "Fresnedoso (Salamanca)",
  // },
  // {
  //   municipio: "Fuente de San Esteban, La",
  //   provincia: "Salamanca",
  //   id: "37135",
  //   label: "Fuente de San Esteban, La (Salamanca)",
  // },
  // {
  //   municipio: "Fuenteguinaldo",
  //   provincia: "Salamanca",
  //   id: "37136",
  //   label: "Fuenteguinaldo (Salamanca)",
  // },
  // {
  //   municipio: "Fuenteliante",
  //   provincia: "Salamanca",
  //   id: "37137",
  //   label: "Fuenteliante (Salamanca)",
  // },
  // {
  //   municipio: "Fuenterroble de Salvatierra",
  //   provincia: "Salamanca",
  //   id: "37138",
  //   label: "Fuenterroble de Salvatierra (Salamanca)",
  // },
  // {
  //   municipio: "Fuentes de Oñoro",
  //   provincia: "Salamanca",
  //   id: "37140",
  //   label: "Fuentes de Oñoro (Salamanca)",
  // },
  // {
  //   municipio: "Hoya, La",
  //   provincia: "Salamanca",
  //   id: "37163",
  //   label: "Hoya, La (Salamanca)",
  // },
  // {
  //   municipio: "Gajates",
  //   provincia: "Salamanca",
  //   id: "37141",
  //   label: "Gajates (Salamanca)",
  // },
  // {
  //   municipio: "Garcibuey",
  //   provincia: "Salamanca",
  //   id: "37147",
  //   label: "Garcibuey (Salamanca)",
  // },
  // {
  //   municipio: "Garcihernández",
  //   provincia: "Salamanca",
  //   id: "37148",
  //   label: "Garcihernández (Salamanca)",
  // },
  // {
  //   municipio: "Galindo y Perahuy",
  //   provincia: "Salamanca",
  //   id: "37142",
  //   label: "Galindo y Perahuy (Salamanca)",
  // },
  // {
  //   municipio: "Garcirrey",
  //   provincia: "Salamanca",
  //   id: "37149",
  //   label: "Garcirrey (Salamanca)",
  // },
  // {
  //   municipio: "Galinduste",
  //   provincia: "Salamanca",
  //   id: "37143",
  //   label: "Galinduste (Salamanca)",
  // },
  // {
  //   municipio: "Gomecello",
  //   provincia: "Salamanca",
  //   id: "37152",
  //   label: "Gomecello (Salamanca)",
  // },
  // {
  //   municipio: "Galisancho",
  //   provincia: "Salamanca",
  //   id: "37144",
  //   label: "Galisancho (Salamanca)",
  // },
  // {
  //   municipio: "Guadramiro",
  //   provincia: "Salamanca",
  //   id: "37154",
  //   label: "Guadramiro (Salamanca)",
  // },
  // {
  //   municipio: "Gallegos de Argañán",
  //   provincia: "Salamanca",
  //   id: "37145",
  //   label: "Gallegos de Argañán (Salamanca)",
  // },
  // {
  //   municipio: "Gallegos de Solmirón",
  //   provincia: "Salamanca",
  //   id: "37146",
  //   label: "Gallegos de Solmirón (Salamanca)",
  // },
  // {
  //   municipio: "Huerta",
  //   provincia: "Salamanca",
  //   id: "37164",
  //   label: "Huerta (Salamanca)",
  // },
  // {
  //   municipio: "Gejuelo del Barro",
  //   provincia: "Salamanca",
  //   id: "37150",
  //   label: "Gejuelo del Barro (Salamanca)",
  // },
  // {
  //   municipio: "Golpejas",
  //   provincia: "Salamanca",
  //   id: "37151",
  //   label: "Golpejas (Salamanca)",
  // },
  // {
  //   municipio: "Guijuelo",
  //   provincia: "Salamanca",
  //   id: "37156",
  //   label: "Guijuelo (Salamanca)",
  // },
  // {
  //   municipio: "Iruelos",
  //   provincia: "Salamanca",
  //   id: "37165",
  //   label: "Iruelos (Salamanca)",
  // },
  // {
  //   municipio: "Herguijuela de Ciudad Rodrigo",
  //   provincia: "Salamanca",
  //   id: "37157",
  //   label: "Herguijuela de Ciudad Rodrigo (Salamanca)",
  // },
  // {
  //   municipio: "Ledrada",
  //   provincia: "Salamanca",
  //   id: "37171",
  //   label: "Ledrada (Salamanca)",
  // },
  // {
  //   municipio: "Herguijuela de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37158",
  //   label: "Herguijuela de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Santiz",
  //   provincia: "Salamanca",
  //   id: "37299",
  //   label: "Santiz (Salamanca)",
  // },
  // {
  //   municipio: "Herguijuela del Campo",
  //   provincia: "Salamanca",
  //   id: "37159",
  //   label: "Herguijuela del Campo (Salamanca)",
  // },
  // {
  //   municipio: "Horcajo Medianero",
  //   provincia: "Salamanca",
  //   id: "37162",
  //   label: "Horcajo Medianero (Salamanca)",
  // },
  // {
  //   municipio: "Hinojosa de Duero",
  //   provincia: "Salamanca",
  //   id: "37160",
  //   label: "Hinojosa de Duero (Salamanca)",
  // },
  // {
  //   municipio: "Horcajo de Montemayor",
  //   provincia: "Salamanca",
  //   id: "37161",
  //   label: "Horcajo de Montemayor (Salamanca)",
  // },
  // {
  //   municipio: "Ituero de Azaba",
  //   provincia: "Salamanca",
  //   id: "37166",
  //   label: "Ituero de Azaba (Salamanca)",
  // },
  // {
  //   municipio: "Juzbado",
  //   provincia: "Salamanca",
  //   id: "37167",
  //   label: "Juzbado (Salamanca)",
  // },
  // {
  //   municipio: "Lagunilla",
  //   provincia: "Salamanca",
  //   id: "37168",
  //   label: "Lagunilla (Salamanca)",
  // },
  // {
  //   municipio: "Linares de Riofrío",
  //   provincia: "Salamanca",
  //   id: "37172",
  //   label: "Linares de Riofrío (Salamanca)",
  // },
  // {
  //   municipio: "Larrodrigo",
  //   provincia: "Salamanca",
  //   id: "37169",
  //   label: "Larrodrigo (Salamanca)",
  // },
  // {
  //   municipio: "Ledesma",
  //   provincia: "Salamanca",
  //   id: "37170",
  //   label: "Ledesma (Salamanca)",
  // },
  // {
  //   municipio: "Encinas",
  //   provincia: "Segovia",
  //   id: "40071",
  //   label: "Encinas (Segovia)",
  // },
  // {
  //   municipio: "Macotera",
  //   provincia: "Salamanca",
  //   id: "37174",
  //   label: "Macotera (Salamanca)",
  // },
  // {
  //   municipio: "Maíllo, El",
  //   provincia: "Salamanca",
  //   id: "37177",
  //   label: "Maíllo, El (Salamanca)",
  // },
  // {
  //   municipio: "Machacón",
  //   provincia: "Salamanca",
  //   id: "37175",
  //   label: "Machacón (Salamanca)",
  // },
  // {
  //   municipio: "Madroñal",
  //   provincia: "Salamanca",
  //   id: "37176",
  //   label: "Madroñal (Salamanca)",
  // },
  // {
  //   municipio: "Malpartida",
  //   provincia: "Salamanca",
  //   id: "37178",
  //   label: "Malpartida (Salamanca)",
  // },
  // {
  //   municipio: "Mancera de Abajo",
  //   provincia: "Salamanca",
  //   id: "37179",
  //   label: "Mancera de Abajo (Salamanca)",
  // },
  // {
  //   municipio: "Manzano, El",
  //   provincia: "Salamanca",
  //   id: "37180",
  //   label: "Manzano, El (Salamanca)",
  // },
  // {
  //   municipio: "Martiago",
  //   provincia: "Salamanca",
  //   id: "37181",
  //   label: "Martiago (Salamanca)",
  // },
  // {
  //   municipio: "Martinamor",
  //   provincia: "Salamanca",
  //   id: "37182",
  //   label: "Martinamor (Salamanca)",
  // },
  // {
  //   municipio: "Masueco",
  //   provincia: "Salamanca",
  //   id: "37184",
  //   label: "Masueco (Salamanca)",
  // },
  // {
  //   municipio: "Castellanos de Villiquera",
  //   provincia: "Salamanca",
  //   id: "37185",
  //   label: "Castellanos de Villiquera (Salamanca)",
  // },
  // {
  //   municipio: "Martín de Yeltes",
  //   provincia: "Salamanca",
  //   id: "37183",
  //   label: "Martín de Yeltes (Salamanca)",
  // },
  // {
  //   municipio: "Maya, La",
  //   provincia: "Salamanca",
  //   id: "37188",
  //   label: "Maya, La (Salamanca)",
  // },
  // {
  //   municipio: "Mata de Ledesma, La",
  //   provincia: "Salamanca",
  //   id: "37186",
  //   label: "Mata de Ledesma, La (Salamanca)",
  // },
  // {
  //   municipio: "Mieza",
  //   provincia: "Salamanca",
  //   id: "37190",
  //   label: "Mieza (Salamanca)",
  // },
  // {
  //   municipio: "Matilla de los Caños del Río",
  //   provincia: "Salamanca",
  //   id: "37187",
  //   label: "Matilla de los Caños del Río (Salamanca)",
  // },
  // {
  //   municipio: "Membribe de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37189",
  //   label: "Membribe de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Moríñigo",
  //   provincia: "Salamanca",
  //   id: "37206",
  //   label: "Moríñigo (Salamanca)",
  // },
  // {
  //   municipio: "Milano, El",
  //   provincia: "Salamanca",
  //   id: "37191",
  //   label: "Milano, El (Salamanca)",
  // },
  // {
  //   municipio: "Miranda de Azán",
  //   provincia: "Salamanca",
  //   id: "37192",
  //   label: "Miranda de Azán (Salamanca)",
  // },
  // {
  //   municipio: "Miranda del Castañar",
  //   provincia: "Salamanca",
  //   id: "37193",
  //   label: "Miranda del Castañar (Salamanca)",
  // },
  // {
  //   municipio: "Monleras",
  //   provincia: "Salamanca",
  //   id: "37198",
  //   label: "Monleras (Salamanca)",
  // },
  // {
  //   municipio: "Monsagro",
  //   provincia: "Salamanca",
  //   id: "37199",
  //   label: "Monsagro (Salamanca)",
  // },
  // {
  //   municipio: "Montejo",
  //   provincia: "Salamanca",
  //   id: "37200",
  //   label: "Montejo (Salamanca)",
  // },
  // {
  //   municipio: "Montemayor del Río",
  //   provincia: "Salamanca",
  //   id: "37201",
  //   label: "Montemayor del Río (Salamanca)",
  // },
  // {
  //   municipio: "Monterrubio de Armuña",
  //   provincia: "Salamanca",
  //   id: "37202",
  //   label: "Monterrubio de Armuña (Salamanca)",
  // },
  // {
  //   municipio: "Monterrubio de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37203",
  //   label: "Monterrubio de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Morasverdes",
  //   provincia: "Salamanca",
  //   id: "37204",
  //   label: "Morasverdes (Salamanca)",
  // },
  // {
  //   municipio: "Morille",
  //   provincia: "Salamanca",
  //   id: "37205",
  //   label: "Morille (Salamanca)",
  // },
  // {
  //   municipio: "Moronta",
  //   provincia: "Salamanca",
  //   id: "37208",
  //   label: "Moronta (Salamanca)",
  // },
  // {
  //   municipio: "Negrilla de Palencia",
  //   provincia: "Salamanca",
  //   id: "37222",
  //   label: "Negrilla de Palencia (Salamanca)",
  // },
  // {
  //   municipio: "Mozárbez",
  //   provincia: "Salamanca",
  //   id: "37209",
  //   label: "Mozárbez (Salamanca)",
  // },
  // {
  //   municipio: "Narros de Matalayegua",
  //   provincia: "Salamanca",
  //   id: "37211",
  //   label: "Narros de Matalayegua (Salamanca)",
  // },
  // {
  //   municipio: "Nava de Sotrobal",
  //   provincia: "Salamanca",
  //   id: "37215",
  //   label: "Nava de Sotrobal (Salamanca)",
  // },
  // {
  //   municipio: "Orbada, La",
  //   provincia: "Salamanca",
  //   id: "37224",
  //   label: "Orbada, La (Salamanca)",
  // },
  // {
  //   municipio: "Navales",
  //   provincia: "Salamanca",
  //   id: "37216",
  //   label: "Navales (Salamanca)",
  // },
  // {
  //   municipio: "Navalmoral de Béjar",
  //   provincia: "Salamanca",
  //   id: "37217",
  //   label: "Navalmoral de Béjar (Salamanca)",
  // },
  // {
  //   municipio: "Navasfrías",
  //   provincia: "Salamanca",
  //   id: "37221",
  //   label: "Navasfrías (Salamanca)",
  // },
  // {
  //   municipio: "Olmedo de Camaces",
  //   provincia: "Salamanca",
  //   id: "37223",
  //   label: "Olmedo de Camaces (Salamanca)",
  // },
  // {
  //   municipio: "Pajares de la Laguna",
  //   provincia: "Salamanca",
  //   id: "37225",
  //   label: "Pajares de la Laguna (Salamanca)",
  // },
  // {
  //   municipio: "Palacios del Arzobispo",
  //   provincia: "Salamanca",
  //   id: "37226",
  //   label: "Palacios del Arzobispo (Salamanca)",
  // },
  // {
  //   municipio: "Palaciosrubios",
  //   provincia: "Salamanca",
  //   id: "37228",
  //   label: "Palaciosrubios (Salamanca)",
  // },
  // {
  //   municipio: "Encinillas",
  //   provincia: "Segovia",
  //   id: "40072",
  //   label: "Encinillas (Segovia)",
  // },
  // {
  //   municipio: "Parada de Arriba",
  //   provincia: "Salamanca",
  //   id: "37230",
  //   label: "Parada de Arriba (Salamanca)",
  // },
  // {
  //   municipio: "Parada de Rubiales",
  //   provincia: "Salamanca",
  //   id: "37231",
  //   label: "Parada de Rubiales (Salamanca)",
  // },
  // {
  //   municipio: "Paradinas de San Juan",
  //   provincia: "Salamanca",
  //   id: "37232",
  //   label: "Paradinas de San Juan (Salamanca)",
  // },
  // {
  //   municipio: "Pastores",
  //   provincia: "Salamanca",
  //   id: "37233",
  //   label: "Pastores (Salamanca)",
  // },
  // {
  //   municipio: "Payo, El",
  //   provincia: "Salamanca",
  //   id: "37234",
  //   label: "Payo, El (Salamanca)",
  // },
  // {
  //   municipio: "Pedraza de Alba",
  //   provincia: "Salamanca",
  //   id: "37235",
  //   label: "Pedraza de Alba (Salamanca)",
  // },
  // {
  //   municipio: "Pedrosillo de Alba",
  //   provincia: "Salamanca",
  //   id: "37236",
  //   label: "Pedrosillo de Alba (Salamanca)",
  // },
  // {
  //   municipio: "San Esteban de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37284",
  //   label: "San Esteban de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Pedrosillo de los Aires",
  //   provincia: "Salamanca",
  //   id: "37237",
  //   label: "Pedrosillo de los Aires (Salamanca)",
  // },
  // {
  //   municipio: "Pedrosillo el Ralo",
  //   provincia: "Salamanca",
  //   id: "37238",
  //   label: "Pedrosillo el Ralo (Salamanca)",
  // },
  // {
  //   municipio: "Pedroso de la Armuña, El",
  //   provincia: "Salamanca",
  //   id: "37239",
  //   label: "Pedroso de la Armuña, El (Salamanca)",
  // },
  // {
  //   municipio: "Pelabravo",
  //   provincia: "Salamanca",
  //   id: "37240",
  //   label: "Pelabravo (Salamanca)",
  // },
  // {
  //   municipio: "Pelarrodríguez",
  //   provincia: "Salamanca",
  //   id: "37241",
  //   label: "Pelarrodríguez (Salamanca)",
  // },
  // {
  //   municipio: "Sanchón de la Sagrada",
  //   provincia: "Salamanca",
  //   id: "37281",
  //   label: "Sanchón de la Sagrada (Salamanca)",
  // },
  // {
  //   municipio: "Pelayos",
  //   provincia: "Salamanca",
  //   id: "37242",
  //   label: "Pelayos (Salamanca)",
  // },
  // {
  //   municipio: "Sanchotello",
  //   provincia: "Salamanca",
  //   id: "37282",
  //   label: "Sanchotello (Salamanca)",
  // },
  // {
  //   municipio: "Peña, La",
  //   provincia: "Salamanca",
  //   id: "37243",
  //   label: "Peña, La (Salamanca)",
  // },
  // {
  //   municipio: "Sanchón de la Ribera",
  //   provincia: "Salamanca",
  //   id: "37280",
  //   label: "Sanchón de la Ribera (Salamanca)",
  // },
  // {
  //   municipio: "Peñaparda",
  //   provincia: "Salamanca",
  //   id: "37245",
  //   label: "Peñaparda (Salamanca)",
  // },
  // {
  //   municipio: "Peñaranda de Bracamonte",
  //   provincia: "Salamanca",
  //   id: "37246",
  //   label: "Peñaranda de Bracamonte (Salamanca)",
  // },
  // {
  //   municipio: "Peñarandilla",
  //   provincia: "Salamanca",
  //   id: "37247",
  //   label: "Peñarandilla (Salamanca)",
  // },
  // {
  //   municipio: "Peralejos de Abajo",
  //   provincia: "Salamanca",
  //   id: "37248",
  //   label: "Peralejos de Abajo (Salamanca)",
  // },
  // {
  //   municipio: "Peralejos de Arriba",
  //   provincia: "Salamanca",
  //   id: "37249",
  //   label: "Peralejos de Arriba (Salamanca)",
  // },
  // {
  //   municipio: "Pino de Tormes, El",
  //   provincia: "Salamanca",
  //   id: "37253",
  //   label: "Pino de Tormes, El (Salamanca)",
  // },
  // {
  //   municipio: "Pitiegua",
  //   provincia: "Salamanca",
  //   id: "37254",
  //   label: "Pitiegua (Salamanca)",
  // },
  // {
  //   municipio: "Pereña de la Ribera",
  //   provincia: "Salamanca",
  //   id: "37250",
  //   label: "Pereña de la Ribera (Salamanca)",
  // },
  // {
  //   municipio: "Peromingo",
  //   provincia: "Salamanca",
  //   id: "37251",
  //   label: "Peromingo (Salamanca)",
  // },
  // {
  //   municipio: "Sando",
  //   provincia: "Salamanca",
  //   id: "37283",
  //   label: "Sando (Salamanca)",
  // },
  // {
  //   municipio: "Escalona del Prado",
  //   provincia: "Segovia",
  //   id: "40073",
  //   label: "Escalona del Prado (Segovia)",
  // },
  // {
  //   municipio: "San Felices de los Gallegos",
  //   provincia: "Salamanca",
  //   id: "37285",
  //   label: "San Felices de los Gallegos (Salamanca)",
  // },
  // {
  //   municipio: "Sepúlveda",
  //   provincia: "Segovia",
  //   id: "40195",
  //   label: "Sepúlveda (Segovia)",
  // },
  // {
  //   municipio: "Pizarral",
  //   provincia: "Salamanca",
  //   id: "37255",
  //   label: "Pizarral (Salamanca)",
  // },
  // {
  //   municipio: "Poveda de las Cintas",
  //   provincia: "Salamanca",
  //   id: "37256",
  //   label: "Poveda de las Cintas (Salamanca)",
  // },
  // {
  //   municipio: "Frumales",
  //   provincia: "Segovia",
  //   id: "40081",
  //   label: "Frumales (Segovia)",
  // },
  // {
  //   municipio: "Pozos de Hinojo",
  //   provincia: "Salamanca",
  //   id: "37257",
  //   label: "Pozos de Hinojo (Salamanca)",
  // },
  // {
  //   municipio: "Sequeros",
  //   provincia: "Salamanca",
  //   id: "37305",
  //   label: "Sequeros (Salamanca)",
  // },
  // {
  //   municipio: "Puebla de Azaba",
  //   provincia: "Salamanca",
  //   id: "37258",
  //   label: "Puebla de Azaba (Salamanca)",
  // },
  // {
  //   municipio: "Puebla de San Medel",
  //   provincia: "Salamanca",
  //   id: "37259",
  //   label: "Puebla de San Medel (Salamanca)",
  // },
  // {
  //   municipio: "Redonda, La",
  //   provincia: "Salamanca",
  //   id: "37266",
  //   label: "Redonda, La (Salamanca)",
  // },
  // {
  //   municipio: "Puebla de Yeltes",
  //   provincia: "Salamanca",
  //   id: "37260",
  //   label: "Puebla de Yeltes (Salamanca)",
  // },
  // {
  //   municipio: "Puente del Congosto",
  //   provincia: "Salamanca",
  //   id: "37261",
  //   label: "Puente del Congosto (Salamanca)",
  // },
  // {
  //   municipio: "Fuente de Santa Cruz",
  //   provincia: "Segovia",
  //   id: "40082",
  //   label: "Fuente de Santa Cruz (Segovia)",
  // },
  // {
  //   municipio: "Puertas",
  //   provincia: "Salamanca",
  //   id: "37262",
  //   label: "Puertas (Salamanca)",
  // },
  // {
  //   municipio: "Puerto de Béjar",
  //   provincia: "Salamanca",
  //   id: "37263",
  //   label: "Puerto de Béjar (Salamanca)",
  // },
  // {
  //   municipio: "Puerto Seguro",
  //   provincia: "Salamanca",
  //   id: "37264",
  //   label: "Puerto Seguro (Salamanca)",
  // },
  // {
  //   municipio: "Rágama",
  //   provincia: "Salamanca",
  //   id: "37265",
  //   label: "Rágama (Salamanca)",
  // },
  // {
  //   municipio: "Retortillo",
  //   provincia: "Salamanca",
  //   id: "37267",
  //   label: "Retortillo (Salamanca)",
  // },
  // {
  //   municipio: "Robleda",
  //   provincia: "Salamanca",
  //   id: "37269",
  //   label: "Robleda (Salamanca)",
  // },
  // {
  //   municipio: "Quintanas de Gormaz",
  //   provincia: "Soria",
  //   id: "42145",
  //   label: "Quintanas de Gormaz (Soria)",
  // },
  // {
  //   municipio: "Robliza de Cojos",
  //   provincia: "Salamanca",
  //   id: "37270",
  //   label: "Robliza de Cojos (Salamanca)",
  // },
  // {
  //   municipio: "Rollán",
  //   provincia: "Salamanca",
  //   id: "37271",
  //   label: "Rollán (Salamanca)",
  // },
  // {
  //   municipio: "Salmoral",
  //   provincia: "Salamanca",
  //   id: "37276",
  //   label: "Salmoral (Salamanca)",
  // },
  // {
  //   municipio: "Saelices el Chico",
  //   provincia: "Salamanca",
  //   id: "37272",
  //   label: "Saelices el Chico (Salamanca)",
  // },
  // {
  //   municipio: "Sagrada, La",
  //   provincia: "Salamanca",
  //   id: "37273",
  //   label: "Sagrada, La (Salamanca)",
  // },
  // {
  //   municipio: "Salamanca",
  //   provincia: "Salamanca",
  //   id: "37274",
  //   label: "Salamanca (Salamanca)",
  // },
  // {
  //   municipio: "Salvatierra de Tormes",
  //   provincia: "Salamanca",
  //   id: "37277",
  //   label: "Salvatierra de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "San Cristóbal de la Cuesta",
  //   provincia: "Salamanca",
  //   id: "37278",
  //   label: "San Cristóbal de la Cuesta (Salamanca)",
  // },
  // {
  //   municipio: "Sancti-Spíritus",
  //   provincia: "Salamanca",
  //   id: "37279",
  //   label: "Sancti-Spíritus (Salamanca)",
  // },
  // {
  //   municipio: "San Morales",
  //   provincia: "Salamanca",
  //   id: "37288",
  //   label: "San Morales (Salamanca)",
  // },
  // {
  //   municipio: "San Martín del Castañar",
  //   provincia: "Salamanca",
  //   id: "37286",
  //   label: "San Martín del Castañar (Salamanca)",
  // },
  // {
  //   municipio: "San Miguel de Valero",
  //   provincia: "Salamanca",
  //   id: "37287",
  //   label: "San Miguel de Valero (Salamanca)",
  // },
  // {
  //   municipio: "San Muñoz",
  //   provincia: "Salamanca",
  //   id: "37289",
  //   label: "San Muñoz (Salamanca)",
  // },
  // {
  //   municipio: "Santa María de Sando",
  //   provincia: "Salamanca",
  //   id: "37293",
  //   label: "Santa María de Sando (Salamanca)",
  // },
  // {
  //   municipio: "San Pedro del Valle",
  //   provincia: "Salamanca",
  //   id: "37290",
  //   label: "San Pedro del Valle (Salamanca)",
  // },
  // {
  //   municipio: "San Pedro de Rozados",
  //   provincia: "Salamanca",
  //   id: "37291",
  //   label: "San Pedro de Rozados (Salamanca)",
  // },
  // {
  //   municipio: "San Pelayo de Guareña",
  //   provincia: "Salamanca",
  //   id: "37292",
  //   label: "San Pelayo de Guareña (Salamanca)",
  // },
  // {
  //   municipio: "Santa Marta de Tormes",
  //   provincia: "Salamanca",
  //   id: "37294",
  //   label: "Santa Marta de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "Santibáñez de la Sierra",
  //   provincia: "Salamanca",
  //   id: "37298",
  //   label: "Santibáñez de la Sierra (Salamanca)",
  // },
  // {
  //   municipio: "Quiñonería",
  //   provincia: "Soria",
  //   id: "42148",
  //   label: "Quiñonería (Soria)",
  // },
  // {
  //   municipio: "Santiago de la Puebla",
  //   provincia: "Salamanca",
  //   id: "37296",
  //   label: "Santiago de la Puebla (Salamanca)",
  // },
  // {
  //   municipio: "Santibáñez de Béjar",
  //   provincia: "Salamanca",
  //   id: "37297",
  //   label: "Santibáñez de Béjar (Salamanca)",
  // },
  // {
  //   municipio: "Santos, Los",
  //   provincia: "Salamanca",
  //   id: "37300",
  //   label: "Santos, Los (Salamanca)",
  // },
  // {
  //   municipio: "Sardón de los Frailes",
  //   provincia: "Salamanca",
  //   id: "37301",
  //   label: "Sardón de los Frailes (Salamanca)",
  // },
  // {
  //   municipio: "Fuente el Olmo de Fuentidueña",
  //   provincia: "Segovia",
  //   id: "40083",
  //   label: "Fuente el Olmo de Fuentidueña (Segovia)",
  // },
  // {
  //   municipio: "Saucelle",
  //   provincia: "Salamanca",
  //   id: "37302",
  //   label: "Saucelle (Salamanca)",
  // },
  // {
  //   municipio: "Sepulcro-Hilario",
  //   provincia: "Salamanca",
  //   id: "37304",
  //   label: "Sepulcro-Hilario (Salamanca)",
  // },
  // {
  //   municipio: "Sahugo, El",
  //   provincia: "Salamanca",
  //   id: "37303",
  //   label: "Sahugo, El (Salamanca)",
  // },
  // {
  //   municipio: "Fuente el Olmo de Íscar",
  //   provincia: "Segovia",
  //   id: "40084",
  //   label: "Fuente el Olmo de Íscar (Segovia)",
  // },
  // {
  //   municipio: "Serradilla del Arroyo",
  //   provincia: "Salamanca",
  //   id: "37306",
  //   label: "Serradilla del Arroyo (Salamanca)",
  // },
  // {
  //   municipio: "Fuentepelayo",
  //   provincia: "Segovia",
  //   id: "40086",
  //   label: "Fuentepelayo (Segovia)",
  // },
  // {
  //   municipio: "Serradilla del Llano",
  //   provincia: "Salamanca",
  //   id: "37307",
  //   label: "Serradilla del Llano (Salamanca)",
  // },
  // {
  //   municipio: "Sierpe, La",
  //   provincia: "Salamanca",
  //   id: "37309",
  //   label: "Sierpe, La (Salamanca)",
  // },
  // {
  //   municipio: "Sieteiglesias de Tormes",
  //   provincia: "Salamanca",
  //   id: "37310",
  //   label: "Sieteiglesias de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "Sobradillo",
  //   provincia: "Salamanca",
  //   id: "37311",
  //   label: "Sobradillo (Salamanca)",
  // },
  // {
  //   municipio: "Sorihuela",
  //   provincia: "Salamanca",
  //   id: "37312",
  //   label: "Sorihuela (Salamanca)",
  // },
  // {
  //   municipio: "Tabera de Abajo",
  //   provincia: "Salamanca",
  //   id: "37314",
  //   label: "Tabera de Abajo (Salamanca)",
  // },
  // {
  //   municipio: "Sotoserrano",
  //   provincia: "Salamanca",
  //   id: "37313",
  //   label: "Sotoserrano (Salamanca)",
  // },
  // {
  //   municipio: "Tamames",
  //   provincia: "Salamanca",
  //   id: "37316",
  //   label: "Tamames (Salamanca)",
  // },
  // {
  //   municipio: "Tarazona de Guareña",
  //   provincia: "Salamanca",
  //   id: "37317",
  //   label: "Tarazona de Guareña (Salamanca)",
  // },
  // {
  //   municipio: "Tardáguila",
  //   provincia: "Salamanca",
  //   id: "37318",
  //   label: "Tardáguila (Salamanca)",
  // },
  // {
  //   municipio: "Tejado, El",
  //   provincia: "Salamanca",
  //   id: "37319",
  //   label: "Tejado, El (Salamanca)",
  // },
  // {
  //   municipio: "Zarza de Pumareda, La",
  //   provincia: "Salamanca",
  //   id: "37381",
  //   label: "Zarza de Pumareda, La (Salamanca)",
  // },
  // {
  //   municipio: "Tejeda y Segoyuela",
  //   provincia: "Salamanca",
  //   id: "37320",
  //   label: "Tejeda y Segoyuela (Salamanca)",
  // },
  // {
  //   municipio: "Anievas",
  //   provincia: "Cantabria",
  //   id: "39003",
  //   label: "Anievas (Cantabria)",
  // },
  // {
  //   municipio: "Arenas de Iguña",
  //   provincia: "Cantabria",
  //   id: "39004",
  //   label: "Arenas de Iguña (Cantabria)",
  // },
  // {
  //   municipio: "Tenebrón",
  //   provincia: "Salamanca",
  //   id: "37321",
  //   label: "Tenebrón (Salamanca)",
  // },
  // {
  //   municipio: "Terradillos",
  //   provincia: "Salamanca",
  //   id: "37322",
  //   label: "Terradillos (Salamanca)",
  // },
  // {
  //   municipio: "Argoños",
  //   provincia: "Cantabria",
  //   id: "39005",
  //   label: "Argoños (Cantabria)",
  // },
  // {
  //   municipio: "Arnuero",
  //   provincia: "Cantabria",
  //   id: "39006",
  //   label: "Arnuero (Cantabria)",
  // },
  // {
  //   municipio: "Topas",
  //   provincia: "Salamanca",
  //   id: "37323",
  //   label: "Topas (Salamanca)",
  // },
  // {
  //   municipio: "Trabanca",
  //   provincia: "Salamanca",
  //   id: "37328",
  //   label: "Trabanca (Salamanca)",
  // },
  // {
  //   municipio: "Tordillos",
  //   provincia: "Salamanca",
  //   id: "37324",
  //   label: "Tordillos (Salamanca)",
  // },
  // {
  //   municipio: "Tornadizo, El",
  //   provincia: "Salamanca",
  //   id: "37325",
  //   label: "Tornadizo, El (Salamanca)",
  // },
  // {
  //   municipio: "Valdehijaderos",
  //   provincia: "Salamanca",
  //   id: "37332",
  //   label: "Valdehijaderos (Salamanca)",
  // },
  // {
  //   municipio: "Tremedal de Tormes",
  //   provincia: "Salamanca",
  //   id: "37329",
  //   label: "Tremedal de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "Valdelacasa",
  //   provincia: "Salamanca",
  //   id: "37333",
  //   label: "Valdelacasa (Salamanca)",
  // },
  // {
  //   municipio: "Polanco",
  //   provincia: "Cantabria",
  //   id: "39054",
  //   label: "Polanco (Cantabria)",
  // },
  // {
  //   municipio: "Valdecarros",
  //   provincia: "Salamanca",
  //   id: "37330",
  //   label: "Valdecarros (Salamanca)",
  // },
  // {
  //   municipio: "Valdefuentes de Sangusín",
  //   provincia: "Salamanca",
  //   id: "37331",
  //   label: "Valdefuentes de Sangusín (Salamanca)",
  // },
  // {
  //   municipio: "Valdelageve",
  //   provincia: "Salamanca",
  //   id: "37334",
  //   label: "Valdelageve (Salamanca)",
  // },
  // {
  //   municipio: "Valdelosa",
  //   provincia: "Salamanca",
  //   id: "37335",
  //   label: "Valdelosa (Salamanca)",
  // },
  // {
  //   municipio: "Valdemierque",
  //   provincia: "Salamanca",
  //   id: "37336",
  //   label: "Valdemierque (Salamanca)",
  // },
  // {
  //   municipio: "Valderrodrigo",
  //   provincia: "Salamanca",
  //   id: "37337",
  //   label: "Valderrodrigo (Salamanca)",
  // },
  // {
  //   municipio: "Vídola, La",
  //   provincia: "Salamanca",
  //   id: "37349",
  //   label: "Vídola, La (Salamanca)",
  // },
  // {
  //   municipio: "Valdunciel",
  //   provincia: "Salamanca",
  //   id: "37338",
  //   label: "Valdunciel (Salamanca)",
  // },
  // {
  //   municipio: "Vilvestre",
  //   provincia: "Salamanca",
  //   id: "37350",
  //   label: "Vilvestre (Salamanca)",
  // },
  // {
  //   municipio: "Valero",
  //   provincia: "Salamanca",
  //   id: "37339",
  //   label: "Valero (Salamanca)",
  // },
  // {
  //   municipio: "Valsalabroso",
  //   provincia: "Salamanca",
  //   id: "37340",
  //   label: "Valsalabroso (Salamanca)",
  // },
  // {
  //   municipio: "Villalba de los Llanos",
  //   provincia: "Salamanca",
  //   id: "37353",
  //   label: "Villalba de los Llanos (Salamanca)",
  // },
  // {
  //   municipio: "Valverde de Valdelacasa",
  //   provincia: "Salamanca",
  //   id: "37341",
  //   label: "Valverde de Valdelacasa (Salamanca)",
  // },
  // {
  //   municipio: "Valverdón",
  //   provincia: "Salamanca",
  //   id: "37342",
  //   label: "Valverdón (Salamanca)",
  // },
  // {
  //   municipio: "Vallejera de Riofrío",
  //   provincia: "Salamanca",
  //   id: "37343",
  //   label: "Vallejera de Riofrío (Salamanca)",
  // },
  // {
  //   municipio: "Potes",
  //   provincia: "Cantabria",
  //   id: "39055",
  //   label: "Potes (Cantabria)",
  // },
  // {
  //   municipio: "Vecinos",
  //   provincia: "Salamanca",
  //   id: "37344",
  //   label: "Vecinos (Salamanca)",
  // },
  // {
  //   municipio: "Villasrubias",
  //   provincia: "Salamanca",
  //   id: "37371",
  //   label: "Villasrubias (Salamanca)",
  // },
  // {
  //   municipio: "Vega de Tirados",
  //   provincia: "Salamanca",
  //   id: "37345",
  //   label: "Vega de Tirados (Salamanca)",
  // },
  // {
  //   municipio: "Villanueva del Conde",
  //   provincia: "Salamanca",
  //   id: "37355",
  //   label: "Villanueva del Conde (Salamanca)",
  // },
  // {
  //   municipio: "Veguillas, Las",
  //   provincia: "Salamanca",
  //   id: "37346",
  //   label: "Veguillas, Las (Salamanca)",
  // },
  // {
  //   municipio: "Villaverde de Guareña",
  //   provincia: "Salamanca",
  //   id: "37372",
  //   label: "Villaverde de Guareña (Salamanca)",
  // },
  // {
  //   municipio: "Vellés, La",
  //   provincia: "Salamanca",
  //   id: "37347",
  //   label: "Vellés, La (Salamanca)",
  // },
  // {
  //   municipio: "Ventosa del Río Almar",
  //   provincia: "Salamanca",
  //   id: "37348",
  //   label: "Ventosa del Río Almar (Salamanca)",
  // },
  // {
  //   municipio: "Villaflores",
  //   provincia: "Salamanca",
  //   id: "37351",
  //   label: "Villaflores (Salamanca)",
  // },
  // {
  //   municipio: "Villar de Peralonso",
  //   provincia: "Salamanca",
  //   id: "37360",
  //   label: "Villar de Peralonso (Salamanca)",
  // },
  // {
  //   municipio: "Villagonzalo de Tormes",
  //   provincia: "Salamanca",
  //   id: "37352",
  //   label: "Villagonzalo de Tormes (Salamanca)",
  // },
  // {
  //   municipio: "Villamayor",
  //   provincia: "Salamanca",
  //   id: "37354",
  //   label: "Villamayor (Salamanca)",
  // },
  // {
  //   municipio: "Villar de Samaniego",
  //   provincia: "Salamanca",
  //   id: "37361",
  //   label: "Villar de Samaniego (Salamanca)",
  // },
  // {
  //   municipio: "Villar de Argañán",
  //   provincia: "Salamanca",
  //   id: "37356",
  //   label: "Villar de Argañán (Salamanca)",
  // },
  // {
  //   municipio: "Villasbuenas",
  //   provincia: "Salamanca",
  //   id: "37367",
  //   label: "Villasbuenas (Salamanca)",
  // },
  // {
  //   municipio: "Villoruela",
  //   provincia: "Salamanca",
  //   id: "37375",
  //   label: "Villoruela (Salamanca)",
  // },
  // {
  //   municipio: "Villar de Ciervo",
  //   provincia: "Salamanca",
  //   id: "37357",
  //   label: "Villar de Ciervo (Salamanca)",
  // },
  // {
  //   municipio: "Villar de Gallimazo",
  //   provincia: "Salamanca",
  //   id: "37358",
  //   label: "Villar de Gallimazo (Salamanca)",
  // },
  // {
  //   municipio: "Villar de la Yegua",
  //   provincia: "Salamanca",
  //   id: "37359",
  //   label: "Villar de la Yegua (Salamanca)",
  // },
  // {
  //   municipio: "Villares de la Reina",
  //   provincia: "Salamanca",
  //   id: "37362",
  //   label: "Villares de la Reina (Salamanca)",
  // },
  // {
  //   municipio: "Villasdardo",
  //   provincia: "Salamanca",
  //   id: "37368",
  //   label: "Villasdardo (Salamanca)",
  // },
  // {
  //   municipio: "Villares de Yeltes",
  //   provincia: "Salamanca",
  //   id: "37363",
  //   label: "Villares de Yeltes (Salamanca)",
  // },
  // {
  //   municipio: "Villaseco de los Gamitos",
  //   provincia: "Salamanca",
  //   id: "37369",
  //   label: "Villaseco de los Gamitos (Salamanca)",
  // },
  // {
  //   municipio: "Villarino de los Aires",
  //   provincia: "Salamanca",
  //   id: "37364",
  //   label: "Villarino de los Aires (Salamanca)",
  // },
  // {
  //   municipio: "Castroserracín",
  //   provincia: "Segovia",
  //   id: "40051",
  //   label: "Castroserracín (Segovia)",
  // },
  // {
  //   municipio: "Villarmayor",
  //   provincia: "Salamanca",
  //   id: "37365",
  //   label: "Villarmayor (Salamanca)",
  // },
  // {
  //   municipio: "Villarmuerto",
  //   provincia: "Salamanca",
  //   id: "37366",
  //   label: "Villarmuerto (Salamanca)",
  // },
  // {
  //   municipio: "Villaseco de los Reyes",
  //   provincia: "Salamanca",
  //   id: "37370",
  //   label: "Villaseco de los Reyes (Salamanca)",
  // },
  // {
  //   municipio: "Villavieja de Yeltes",
  //   provincia: "Salamanca",
  //   id: "37373",
  //   label: "Villavieja de Yeltes (Salamanca)",
  // },
  // {
  //   municipio: "Villoria",
  //   provincia: "Salamanca",
  //   id: "37374",
  //   label: "Villoria (Salamanca)",
  // },
  // {
  //   municipio: "Vitigudino",
  //   provincia: "Salamanca",
  //   id: "37376",
  //   label: "Vitigudino (Salamanca)",
  // },
  // {
  //   municipio: "Zamayón",
  //   provincia: "Salamanca",
  //   id: "37379",
  //   label: "Zamayón (Salamanca)",
  // },
  // {
  //   municipio: "Yecla de Yeltes",
  //   provincia: "Salamanca",
  //   id: "37377",
  //   label: "Yecla de Yeltes (Salamanca)",
  // },
  // {
  //   municipio: "Zarapicos",
  //   provincia: "Salamanca",
  //   id: "37380",
  //   label: "Zarapicos (Salamanca)",
  // },
  // {
  //   municipio: "Zamarra",
  //   provincia: "Salamanca",
  //   id: "37378",
  //   label: "Zamarra (Salamanca)",
  // },
  // {
  //   municipio: "Zorita de la Frontera",
  //   provincia: "Salamanca",
  //   id: "37382",
  //   label: "Zorita de la Frontera (Salamanca)",
  // },
  // {
  //   municipio: "Arafo",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38004",
  //   label: "Arafo (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Breña Alta",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38008",
  //   label: "Breña Alta (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Paso, El",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38027",
  //   label: "Paso, El (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "San Andrés y Sauces",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38033",
  //   label: "San Andrés y Sauces (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Santa Cruz de la Palma",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38037",
  //   label: "Santa Cruz de la Palma (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Tanque, El",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38044",
  //   label: "Tanque, El (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Tegueste",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38046",
  //   label: "Tegueste (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Victoria de Acentejo, La",
  //   provincia: "Santa Cruz de Tenerife",
  //   id: "38051",
  //   label: "Victoria de Acentejo, La (Santa Cruz de Tenerife)",
  // },
  // {
  //   municipio: "Alfoz de Lloredo",
  //   provincia: "Cantabria",
  //   id: "39001",
  //   label: "Alfoz de Lloredo (Cantabria)",
  // },
  // {
  //   municipio: "Ampuero",
  //   provincia: "Cantabria",
  //   id: "39002",
  //   label: "Ampuero (Cantabria)",
  // },
  // {
  //   municipio: "Arredondo",
  //   provincia: "Cantabria",
  //   id: "39007",
  //   label: "Arredondo (Cantabria)",
  // },
  // {
  //   municipio: "Astillero, El",
  //   provincia: "Cantabria",
  //   id: "39008",
  //   label: "Astillero, El (Cantabria)",
  // },
  // {
  //   municipio: "Cabezón de la Sal",
  //   provincia: "Cantabria",
  //   id: "39012",
  //   label: "Cabezón de la Sal (Cantabria)",
  // },
  // {
  //   municipio: "Bárcena de Cicero",
  //   provincia: "Cantabria",
  //   id: "39009",
  //   label: "Bárcena de Cicero (Cantabria)",
  // },
  // {
  //   municipio: "Bárcena de Pie de Concha",
  //   provincia: "Cantabria",
  //   id: "39010",
  //   label: "Bárcena de Pie de Concha (Cantabria)",
  // },
  // {
  //   municipio: "Bareyo",
  //   provincia: "Cantabria",
  //   id: "39011",
  //   label: "Bareyo (Cantabria)",
  // },
  // {
  //   municipio: "Cedillo de la Torre",
  //   provincia: "Segovia",
  //   id: "40052",
  //   label: "Cedillo de la Torre (Segovia)",
  // },
  // {
  //   municipio: "Cabezón de Liébana",
  //   provincia: "Cantabria",
  //   id: "39013",
  //   label: "Cabezón de Liébana (Cantabria)",
  // },
  // {
  //   municipio: "Cabuérniga",
  //   provincia: "Cantabria",
  //   id: "39014",
  //   label: "Cabuérniga (Cantabria)",
  // },
  // {
  //   municipio: "Camaleño",
  //   provincia: "Cantabria",
  //   id: "39015",
  //   label: "Camaleño (Cantabria)",
  // },
  // {
  //   municipio: "Camargo",
  //   provincia: "Cantabria",
  //   id: "39016",
  //   label: "Camargo (Cantabria)",
  // },
  // {
  //   municipio: "Campoo de Yuso",
  //   provincia: "Cantabria",
  //   id: "39017",
  //   label: "Campoo de Yuso (Cantabria)",
  // },
  // {
  //   municipio: "Cartes",
  //   provincia: "Cantabria",
  //   id: "39018",
  //   label: "Cartes (Cantabria)",
  // },
  // {
  //   municipio: "Castañeda",
  //   provincia: "Cantabria",
  //   id: "39019",
  //   label: "Castañeda (Cantabria)",
  // },
  // {
  //   municipio: "Cieza",
  //   provincia: "Cantabria",
  //   id: "39021",
  //   label: "Cieza (Cantabria)",
  // },
  // {
  //   municipio: "Cillorigo de Liébana",
  //   provincia: "Cantabria",
  //   id: "39022",
  //   label: "Cillorigo de Liébana (Cantabria)",
  // },
  // {
  //   municipio: "Ituero y Lama",
  //   provincia: "Segovia",
  //   id: "40104",
  //   label: "Ituero y Lama (Segovia)",
  // },
  // {
  //   municipio: "Liendo",
  //   provincia: "Cantabria",
  //   id: "39036",
  //   label: "Liendo (Cantabria)",
  // },
  // {
  //   municipio: "Colindres",
  //   provincia: "Cantabria",
  //   id: "39023",
  //   label: "Colindres (Cantabria)",
  // },
  // {
  //   municipio: "Comillas",
  //   provincia: "Cantabria",
  //   id: "39024",
  //   label: "Comillas (Cantabria)",
  // },
  // {
  //   municipio: "Corrales de Buelna, Los",
  //   provincia: "Cantabria",
  //   id: "39025",
  //   label: "Corrales de Buelna, Los (Cantabria)",
  // },
  // {
  //   municipio: "Aldealengua de Pedraza",
  //   provincia: "Segovia",
  //   id: "40007",
  //   label: "Aldealengua de Pedraza (Segovia)",
  // },
  // {
  //   municipio: "Corvera de Toranzo",
  //   provincia: "Cantabria",
  //   id: "39026",
  //   label: "Corvera de Toranzo (Cantabria)",
  // },
  // {
  //   municipio: "Escalante",
  //   provincia: "Cantabria",
  //   id: "39029",
  //   label: "Escalante (Cantabria)",
  // },
  // {
  //   municipio: "Campoo de Enmedio",
  //   provincia: "Cantabria",
  //   id: "39027",
  //   label: "Campoo de Enmedio (Cantabria)",
  // },
  // {
  //   municipio: "Pesaguero",
  //   provincia: "Cantabria",
  //   id: "39050",
  //   label: "Pesaguero (Cantabria)",
  // },
  // {
  //   municipio: "Entrambasaguas",
  //   provincia: "Cantabria",
  //   id: "39028",
  //   label: "Entrambasaguas (Cantabria)",
  // },
  // {
  //   municipio: "Pesquera",
  //   provincia: "Cantabria",
  //   id: "39051",
  //   label: "Pesquera (Cantabria)",
  // },
  // {
  //   municipio: "Piélagos",
  //   provincia: "Cantabria",
  //   id: "39052",
  //   label: "Piélagos (Cantabria)",
  // },
  // {
  //   municipio: "Guriezo",
  //   provincia: "Cantabria",
  //   id: "39030",
  //   label: "Guriezo (Cantabria)",
  // },
  // {
  //   municipio: "Hazas de Cesto",
  //   provincia: "Cantabria",
  //   id: "39031",
  //   label: "Hazas de Cesto (Cantabria)",
  // },
  // {
  //   municipio: "Hermandad de Campoo de Suso",
  //   provincia: "Cantabria",
  //   id: "39032",
  //   label: "Hermandad de Campoo de Suso (Cantabria)",
  // },
  // {
  //   municipio: "Herrerías",
  //   provincia: "Cantabria",
  //   id: "39033",
  //   label: "Herrerías (Cantabria)",
  // },
  // {
  //   municipio: "Medio Cudeyo",
  //   provincia: "Cantabria",
  //   id: "39042",
  //   label: "Medio Cudeyo (Cantabria)",
  // },
  // {
  //   municipio: "Lamasón",
  //   provincia: "Cantabria",
  //   id: "39034",
  //   label: "Lamasón (Cantabria)",
  // },
  // {
  //   municipio: "Laredo",
  //   provincia: "Cantabria",
  //   id: "39035",
  //   label: "Laredo (Cantabria)",
  // },
  // {
  //   municipio: "Liérganes",
  //   provincia: "Cantabria",
  //   id: "39037",
  //   label: "Liérganes (Cantabria)",
  // },
  // {
  //   municipio: "Limpias",
  //   provincia: "Cantabria",
  //   id: "39038",
  //   label: "Limpias (Cantabria)",
  // },
  // {
  //   municipio: "Luena",
  //   provincia: "Cantabria",
  //   id: "39039",
  //   label: "Luena (Cantabria)",
  // },
  // {
  //   municipio: "Mazcuerras",
  //   provincia: "Cantabria",
  //   id: "39041",
  //   label: "Mazcuerras (Cantabria)",
  // },
  // {
  //   municipio: "Molledo",
  //   provincia: "Cantabria",
  //   id: "39046",
  //   label: "Molledo (Cantabria)",
  // },
  // {
  //   municipio: "Noja",
  //   provincia: "Cantabria",
  //   id: "39047",
  //   label: "Noja (Cantabria)",
  // },
  // {
  //   municipio: "Polaciones",
  //   provincia: "Cantabria",
  //   id: "39053",
  //   label: "Polaciones (Cantabria)",
  // },
  // {
  //   municipio: "Marazuela",
  //   provincia: "Segovia",
  //   id: "40118",
  //   label: "Marazuela (Segovia)",
  // },
  // {
  //   municipio: "Puente Viesgo",
  //   provincia: "Cantabria",
  //   id: "39056",
  //   label: "Puente Viesgo (Cantabria)",
  // },
  // {
  //   municipio: "Reocín",
  //   provincia: "Cantabria",
  //   id: "39060",
  //   label: "Reocín (Cantabria)",
  // },
  // {
  //   municipio: "Ramales de la Victoria",
  //   provincia: "Cantabria",
  //   id: "39057",
  //   label: "Ramales de la Victoria (Cantabria)",
  // },
  // {
  //   municipio: "Bernardos",
  //   provincia: "Segovia",
  //   id: "40030",
  //   label: "Bernardos (Segovia)",
  // },
  // {
  //   municipio: "Rasines",
  //   provincia: "Cantabria",
  //   id: "39058",
  //   label: "Rasines (Cantabria)",
  // },
  // {
  //   municipio: "Ribamontán al Monte",
  //   provincia: "Cantabria",
  //   id: "39062",
  //   label: "Ribamontán al Monte (Cantabria)",
  // },
  // {
  //   municipio: "Rionansa",
  //   provincia: "Cantabria",
  //   id: "39063",
  //   label: "Rionansa (Cantabria)",
  // },
  // {
  //   municipio: "San Felices de Buelna",
  //   provincia: "Cantabria",
  //   id: "39069",
  //   label: "San Felices de Buelna (Cantabria)",
  // },
  // {
  //   municipio: "Riotuerto",
  //   provincia: "Cantabria",
  //   id: "39064",
  //   label: "Riotuerto (Cantabria)",
  // },
  // {
  //   municipio: "San Miguel de Aguayo",
  //   provincia: "Cantabria",
  //   id: "39070",
  //   label: "San Miguel de Aguayo (Cantabria)",
  // },
  // {
  //   municipio: "Rozas de Valdearroyo, Las",
  //   provincia: "Cantabria",
  //   id: "39065",
  //   label: "Rozas de Valdearroyo, Las (Cantabria)",
  // },
  // {
  //   municipio: "Ruente",
  //   provincia: "Cantabria",
  //   id: "39066",
  //   label: "Ruente (Cantabria)",
  // },
  // {
  //   municipio: "Ruesga",
  //   provincia: "Cantabria",
  //   id: "39067",
  //   label: "Ruesga (Cantabria)",
  // },
  // {
  //   municipio: "Santiurde de Reinosa",
  //   provincia: "Cantabria",
  //   id: "39077",
  //   label: "Santiurde de Reinosa (Cantabria)",
  // },
  // {
  //   municipio: "Ruiloba",
  //   provincia: "Cantabria",
  //   id: "39068",
  //   label: "Ruiloba (Cantabria)",
  // },
  // {
  //   municipio: "San Pedro del Romeral",
  //   provincia: "Cantabria",
  //   id: "39071",
  //   label: "San Pedro del Romeral (Cantabria)",
  // },
  // {
  //   municipio: "San Roque de Riomiera",
  //   provincia: "Cantabria",
  //   id: "39072",
  //   label: "San Roque de Riomiera (Cantabria)",
  // },
  // {
  //   municipio: "Santa Cruz de Bezana",
  //   provincia: "Cantabria",
  //   id: "39073",
  //   label: "Santa Cruz de Bezana (Cantabria)",
  // },
  // {
  //   municipio: "Santa María de Cayón",
  //   provincia: "Cantabria",
  //   id: "39074",
  //   label: "Santa María de Cayón (Cantabria)",
  // },
  // {
  //   municipio: "Santiurde de Toranzo",
  //   provincia: "Cantabria",
  //   id: "39078",
  //   label: "Santiurde de Toranzo (Cantabria)",
  // },
  // {
  //   municipio: "Santoña",
  //   provincia: "Cantabria",
  //   id: "39079",
  //   label: "Santoña (Cantabria)",
  // },
  // {
  //   municipio: "Santillana del Mar",
  //   provincia: "Cantabria",
  //   id: "39076",
  //   label: "Santillana del Mar (Cantabria)",
  // },
  // {
  //   municipio: "Solórzano",
  //   provincia: "Cantabria",
  //   id: "39084",
  //   label: "Solórzano (Cantabria)",
  // },
  // {
  //   municipio: "Suances",
  //   provincia: "Cantabria",
  //   id: "39085",
  //   label: "Suances (Cantabria)",
  // },
  // {
  //   municipio: "Saro",
  //   provincia: "Cantabria",
  //   id: "39081",
  //   label: "Saro (Cantabria)",
  // },
  // {
  //   municipio: "Selaya",
  //   provincia: "Cantabria",
  //   id: "39082",
  //   label: "Selaya (Cantabria)",
  // },
  // {
  //   municipio: "Tojos, Los",
  //   provincia: "Cantabria",
  //   id: "39086",
  //   label: "Tojos, Los (Cantabria)",
  // },
  // {
  //   municipio: "Torrelavega",
  //   provincia: "Cantabria",
  //   id: "39087",
  //   label: "Torrelavega (Cantabria)",
  // },
  // {
  //   municipio: "Valderredible",
  //   provincia: "Cantabria",
  //   id: "39094",
  //   label: "Valderredible (Cantabria)",
  // },
  // {
  //   municipio: "Vega de Liébana",
  //   provincia: "Cantabria",
  //   id: "39096",
  //   label: "Vega de Liébana (Cantabria)",
  // },
  // {
  //   municipio: "Tresviso",
  //   provincia: "Cantabria",
  //   id: "39088",
  //   label: "Tresviso (Cantabria)",
  // },
  // {
  //   municipio: "Vega de Pas",
  //   provincia: "Cantabria",
  //   id: "39097",
  //   label: "Vega de Pas (Cantabria)",
  // },
  // {
  //   municipio: "Tudanca",
  //   provincia: "Cantabria",
  //   id: "39089",
  //   label: "Tudanca (Cantabria)",
  // },
  // {
  //   municipio: "Udías",
  //   provincia: "Cantabria",
  //   id: "39090",
  //   label: "Udías (Cantabria)",
  // },
  // {
  //   municipio: "Valdáliga",
  //   provincia: "Cantabria",
  //   id: "39091",
  //   label: "Valdáliga (Cantabria)",
  // },
  // {
  //   municipio: "Valdeolea",
  //   provincia: "Cantabria",
  //   id: "39092",
  //   label: "Valdeolea (Cantabria)",
  // },
  // {
  //   municipio: "Villafufre",
  //   provincia: "Cantabria",
  //   id: "39100",
  //   label: "Villafufre (Cantabria)",
  // },
  // {
  //   municipio: "Barbolla",
  //   provincia: "Segovia",
  //   id: "40025",
  //   label: "Barbolla (Segovia)",
  // },
  // {
  //   municipio: "Valdeprado del Río",
  //   provincia: "Cantabria",
  //   id: "39093",
  //   label: "Valdeprado del Río (Cantabria)",
  // },
  // {
  //   municipio: "Villacarriedo",
  //   provincia: "Cantabria",
  //   id: "39098",
  //   label: "Villacarriedo (Cantabria)",
  // },
  // {
  //   municipio: "Villaescusa",
  //   provincia: "Cantabria",
  //   id: "39099",
  //   label: "Villaescusa (Cantabria)",
  // },
  // {
  //   municipio: "Valle de Villaverde",
  //   provincia: "Cantabria",
  //   id: "39101",
  //   label: "Valle de Villaverde (Cantabria)",
  // },
  // {
  //   municipio: "Voto",
  //   provincia: "Cantabria",
  //   id: "39102",
  //   label: "Voto (Cantabria)",
  // },
  // {
  //   municipio: "Adrados",
  //   provincia: "Segovia",
  //   id: "40003",
  //   label: "Adrados (Segovia)",
  // },
  // {
  //   municipio: "Abades",
  //   provincia: "Segovia",
  //   id: "40001",
  //   label: "Abades (Segovia)",
  // },
  // {
  //   municipio: "Adrada de Pirón",
  //   provincia: "Segovia",
  //   id: "40002",
  //   label: "Adrada de Pirón (Segovia)",
  // },
  // {
  //   municipio: "Aldealcorvo",
  //   provincia: "Segovia",
  //   id: "40006",
  //   label: "Aldealcorvo (Segovia)",
  // },
  // {
  //   municipio: "Aguilafuente",
  //   provincia: "Segovia",
  //   id: "40004",
  //   label: "Aguilafuente (Segovia)",
  // },
  // {
  //   municipio: "Alconada de Maderuelo",
  //   provincia: "Segovia",
  //   id: "40005",
  //   label: "Alconada de Maderuelo (Segovia)",
  // },
  // {
  //   municipio: "Martín Miguel",
  //   provincia: "Segovia",
  //   id: "40119",
  //   label: "Martín Miguel (Segovia)",
  // },
  // {
  //   municipio: "Ayllón",
  //   provincia: "Segovia",
  //   id: "40024",
  //   label: "Ayllón (Segovia)",
  // },
  // {
  //   municipio: "Basardilla",
  //   provincia: "Segovia",
  //   id: "40026",
  //   label: "Basardilla (Segovia)",
  // },
  // {
  //   municipio: "Bercial",
  //   provincia: "Segovia",
  //   id: "40028",
  //   label: "Bercial (Segovia)",
  // },
  // {
  //   municipio: "Aldealengua de Santa María",
  //   provincia: "Segovia",
  //   id: "40008",
  //   label: "Aldealengua de Santa María (Segovia)",
  // },
  // {
  //   municipio: "Aldehorno",
  //   provincia: "Segovia",
  //   id: "40014",
  //   label: "Aldehorno (Segovia)",
  // },
  // {
  //   municipio: "Aldehuela del Codonal",
  //   provincia: "Segovia",
  //   id: "40015",
  //   label: "Aldehuela del Codonal (Segovia)",
  // },
  // {
  //   municipio: "Aldeonte",
  //   provincia: "Segovia",
  //   id: "40016",
  //   label: "Aldeonte (Segovia)",
  // },
  // {
  //   municipio: "Armuña",
  //   provincia: "Segovia",
  //   id: "40022",
  //   label: "Armuña (Segovia)",
  // },
  // {
  //   municipio: "Bernuy de Porreros",
  //   provincia: "Segovia",
  //   id: "40031",
  //   label: "Bernuy de Porreros (Segovia)",
  // },
  // {
  //   municipio: "Cerezo de Abajo",
  //   provincia: "Segovia",
  //   id: "40053",
  //   label: "Cerezo de Abajo (Segovia)",
  // },
  // {
  //   municipio: "Boceguillas",
  //   provincia: "Segovia",
  //   id: "40032",
  //   label: "Boceguillas (Segovia)",
  // },
  // {
  //   municipio: "Brieva",
  //   provincia: "Segovia",
  //   id: "40033",
  //   label: "Brieva (Segovia)",
  // },
  // {
  //   municipio: "Caballar",
  //   provincia: "Segovia",
  //   id: "40034",
  //   label: "Caballar (Segovia)",
  // },
  // {
  //   municipio: "Cabezuela",
  //   provincia: "Segovia",
  //   id: "40036",
  //   label: "Cabezuela (Segovia)",
  // },
  // {
  //   municipio: "Calabazas de Fuentidueña",
  //   provincia: "Segovia",
  //   id: "40037",
  //   label: "Calabazas de Fuentidueña (Segovia)",
  // },
  // {
  //   municipio: "Campo de San Pedro",
  //   provincia: "Segovia",
  //   id: "40039",
  //   label: "Campo de San Pedro (Segovia)",
  // },
  // {
  //   municipio: "Casla",
  //   provincia: "Segovia",
  //   id: "40045",
  //   label: "Casla (Segovia)",
  // },
  // {
  //   municipio: "Cantalejo",
  //   provincia: "Segovia",
  //   id: "40040",
  //   label: "Cantalejo (Segovia)",
  // },
  // {
  //   municipio: "Carbonero el Mayor",
  //   provincia: "Segovia",
  //   id: "40043",
  //   label: "Carbonero el Mayor (Segovia)",
  // },
  // {
  //   municipio: "Carrascal del Río",
  //   provincia: "Segovia",
  //   id: "40044",
  //   label: "Carrascal del Río (Segovia)",
  // },
  // {
  //   municipio: "Cerezo de Arriba",
  //   provincia: "Segovia",
  //   id: "40054",
  //   label: "Cerezo de Arriba (Segovia)",
  // },
  // {
  //   municipio: "Coca",
  //   provincia: "Segovia",
  //   id: "40057",
  //   label: "Coca (Segovia)",
  // },
  // {
  //   municipio: "Codorniz",
  //   provincia: "Segovia",
  //   id: "40058",
  //   label: "Codorniz (Segovia)",
  // },
  // {
  //   municipio: "Corral de Ayllón",
  //   provincia: "Segovia",
  //   id: "40061",
  //   label: "Corral de Ayllón (Segovia)",
  // },
  // {
  //   municipio: "Cubillo",
  //   provincia: "Segovia",
  //   id: "40062",
  //   label: "Cubillo (Segovia)",
  // },
  // {
  //   municipio: "Chañe",
  //   provincia: "Segovia",
  //   id: "40065",
  //   label: "Chañe (Segovia)",
  // },
  // {
  //   municipio: "Domingo García",
  //   provincia: "Segovia",
  //   id: "40068",
  //   label: "Domingo García (Segovia)",
  // },
  // {
  //   municipio: "Donhierro",
  //   provincia: "Segovia",
  //   id: "40069",
  //   label: "Donhierro (Segovia)",
  // },
  // {
  //   municipio: "Duruelo",
  //   provincia: "Segovia",
  //   id: "40070",
  //   label: "Duruelo (Segovia)",
  // },
  // {
  //   municipio: "Lastras del Pozo",
  //   provincia: "Segovia",
  //   id: "40111",
  //   label: "Lastras del Pozo (Segovia)",
  // },
  // {
  //   municipio: "Lastrilla, La",
  //   provincia: "Segovia",
  //   id: "40112",
  //   label: "Lastrilla, La (Segovia)",
  // },
  // {
  //   municipio: "Losa, La",
  //   provincia: "Segovia",
  //   id: "40113",
  //   label: "Losa, La (Segovia)",
  // },
  // {
  //   municipio: "Escarabajosa de Cabezas",
  //   provincia: "Segovia",
  //   id: "40074",
  //   label: "Escarabajosa de Cabezas (Segovia)",
  // },
  // {
  //   municipio: "Escobar de Polendos",
  //   provincia: "Segovia",
  //   id: "40075",
  //   label: "Escobar de Polendos (Segovia)",
  // },
  // {
  //   municipio: "Espinar, El",
  //   provincia: "Segovia",
  //   id: "40076",
  //   label: "Espinar, El (Segovia)",
  // },
  // {
  //   municipio: "Espirdo",
  //   provincia: "Segovia",
  //   id: "40077",
  //   label: "Espirdo (Segovia)",
  // },
  // {
  //   municipio: "Fresneda de Cuéllar",
  //   provincia: "Segovia",
  //   id: "40078",
  //   label: "Fresneda de Cuéllar (Segovia)",
  // },
  // {
  //   municipio: "Monterrubio",
  //   provincia: "Segovia",
  //   id: "40131",
  //   label: "Monterrubio (Segovia)",
  // },
  // {
  //   municipio: "Fresno de Cantespino",
  //   provincia: "Segovia",
  //   id: "40079",
  //   label: "Fresno de Cantespino (Segovia)",
  // },
  // {
  //   municipio: "Fresno de la Fuente",
  //   provincia: "Segovia",
  //   id: "40080",
  //   label: "Fresno de la Fuente (Segovia)",
  // },
  // {
  //   municipio: "Gomezserracín",
  //   provincia: "Segovia",
  //   id: "40095",
  //   label: "Gomezserracín (Segovia)",
  // },
  // {
  //   municipio: "Maderuelo",
  //   provincia: "Segovia",
  //   id: "40115",
  //   label: "Maderuelo (Segovia)",
  // },
  // {
  //   municipio: "Otero de Herreros",
  //   provincia: "Segovia",
  //   id: "40152",
  //   label: "Otero de Herreros (Segovia)",
  // },
  // {
  //   municipio: "Moral de Hornuez",
  //   provincia: "Segovia",
  //   id: "40132",
  //   label: "Moral de Hornuez (Segovia)",
  // },
  // {
  //   municipio: "Mozoncillo",
  //   provincia: "Segovia",
  //   id: "40134",
  //   label: "Mozoncillo (Segovia)",
  // },
  // {
  //   municipio: "Fuentepiñel",
  //   provincia: "Segovia",
  //   id: "40087",
  //   label: "Fuentepiñel (Segovia)",
  // },
  // {
  //   municipio: "Fuenterrebollo",
  //   provincia: "Segovia",
  //   id: "40088",
  //   label: "Fuenterrebollo (Segovia)",
  // },
  // {
  //   municipio: "Fuentesaúco de Fuentidueña",
  //   provincia: "Segovia",
  //   id: "40089",
  //   label: "Fuentesaúco de Fuentidueña (Segovia)",
  // },
  // {
  //   municipio: "Fuentesoto",
  //   provincia: "Segovia",
  //   id: "40091",
  //   label: "Fuentesoto (Segovia)",
  // },
  // {
  //   municipio: "Fuentidueña",
  //   provincia: "Segovia",
  //   id: "40092",
  //   label: "Fuentidueña (Segovia)",
  // },
  // {
  //   municipio: "Gallegos",
  //   provincia: "Segovia",
  //   id: "40093",
  //   label: "Gallegos (Segovia)",
  // },
  // {
  //   municipio: "Garcillán",
  //   provincia: "Segovia",
  //   id: "40094",
  //   label: "Garcillán (Segovia)",
  // },
  // {
  //   municipio: "Grajera",
  //   provincia: "Segovia",
  //   id: "40097",
  //   label: "Grajera (Segovia)",
  // },
  // {
  //   municipio: "Muñopedro",
  //   provincia: "Segovia",
  //   id: "40135",
  //   label: "Muñopedro (Segovia)",
  // },
  // {
  //   municipio: "Huertos, Los",
  //   provincia: "Segovia",
  //   id: "40103",
  //   label: "Huertos, Los (Segovia)",
  // },
  // {
  //   municipio: "Muñoveros",
  //   provincia: "Segovia",
  //   id: "40136",
  //   label: "Muñoveros (Segovia)",
  // },
  // {
  //   municipio: "Nava de la Asunción",
  //   provincia: "Segovia",
  //   id: "40138",
  //   label: "Nava de la Asunción (Segovia)",
  // },
  // {
  //   municipio: "Honrubia de la Cuesta",
  //   provincia: "Segovia",
  //   id: "40099",
  //   label: "Honrubia de la Cuesta (Segovia)",
  // },
  // {
  //   municipio: "Hontalbilla",
  //   provincia: "Segovia",
  //   id: "40100",
  //   label: "Hontalbilla (Segovia)",
  // },
  // {
  //   municipio: "Hontanares de Eresma",
  //   provincia: "Segovia",
  //   id: "40101",
  //   label: "Hontanares de Eresma (Segovia)",
  // },
  // {
  //   municipio: "Juarros de Voltoya",
  //   provincia: "Segovia",
  //   id: "40106",
  //   label: "Juarros de Voltoya (Segovia)",
  // },
  // {
  //   municipio: "Labajos",
  //   provincia: "Segovia",
  //   id: "40107",
  //   label: "Labajos (Segovia)",
  // },
  // {
  //   municipio: "Laguna de Contreras",
  //   provincia: "Segovia",
  //   id: "40108",
  //   label: "Laguna de Contreras (Segovia)",
  // },
  // {
  //   municipio: "Languilla",
  //   provincia: "Segovia",
  //   id: "40109",
  //   label: "Languilla (Segovia)",
  // },
  // {
  //   municipio: "Martín Muñoz de la Dehesa",
  //   provincia: "Segovia",
  //   id: "40120",
  //   label: "Martín Muñoz de la Dehesa (Segovia)",
  // },
  // {
  //   municipio: "Martín Muñoz de las Posadas",
  //   provincia: "Segovia",
  //   id: "40121",
  //   label: "Martín Muñoz de las Posadas (Segovia)",
  // },
  // {
  //   municipio: "Lastras de Cuéllar",
  //   provincia: "Segovia",
  //   id: "40110",
  //   label: "Lastras de Cuéllar (Segovia)",
  // },
  // {
  //   municipio: "Marugán",
  //   provincia: "Segovia",
  //   id: "40122",
  //   label: "Marugán (Segovia)",
  // },
  // {
  //   municipio: "Matabuena",
  //   provincia: "Segovia",
  //   id: "40123",
  //   label: "Matabuena (Segovia)",
  // },
  // {
  //   municipio: "Navalilla",
  //   provincia: "Segovia",
  //   id: "40140",
  //   label: "Navalilla (Segovia)",
  // },
  // {
  //   municipio: "Santiuste de Pedraza",
  //   provincia: "Segovia",
  //   id: "40188",
  //   label: "Santiuste de Pedraza (Segovia)",
  // },
  // {
  //   municipio: "Migueláñez",
  //   provincia: "Segovia",
  //   id: "40128",
  //   label: "Migueláñez (Segovia)",
  // },
  // {
  //   municipio: "Montejo de la Vega de la Serrezuela",
  //   provincia: "Segovia",
  //   id: "40130",
  //   label: "Montejo de la Vega de la Serrezuela (Segovia)",
  // },
  // {
  //   municipio: "Santiuste de San Juan Bautista",
  //   provincia: "Segovia",
  //   id: "40189",
  //   label: "Santiuste de San Juan Bautista (Segovia)",
  // },
  // {
  //   municipio: "Mata de Cuéllar",
  //   provincia: "Segovia",
  //   id: "40124",
  //   label: "Mata de Cuéllar (Segovia)",
  // },
  // {
  //   municipio: "Matilla, La",
  //   provincia: "Segovia",
  //   id: "40125",
  //   label: "Matilla, La (Segovia)",
  // },
  // {
  //   municipio: "Navalmanzano",
  //   provincia: "Segovia",
  //   id: "40141",
  //   label: "Navalmanzano (Segovia)",
  // },
  // {
  //   municipio: "Nieva",
  //   provincia: "Segovia",
  //   id: "40148",
  //   label: "Nieva (Segovia)",
  // },
  // {
  //   municipio: "Navares de Enmedio",
  //   provincia: "Segovia",
  //   id: "40143",
  //   label: "Navares de Enmedio (Segovia)",
  // },
  // {
  //   municipio: "Navares de las Cuevas",
  //   provincia: "Segovia",
  //   id: "40144",
  //   label: "Navares de las Cuevas (Segovia)",
  // },
  // {
  //   municipio: "Olombrada",
  //   provincia: "Segovia",
  //   id: "40149",
  //   label: "Olombrada (Segovia)",
  // },
  // {
  //   municipio: "Navas de Oro",
  //   provincia: "Segovia",
  //   id: "40145",
  //   label: "Navas de Oro (Segovia)",
  // },
  // {
  //   municipio: "Perosillo",
  //   provincia: "Segovia",
  //   id: "40158",
  //   label: "Perosillo (Segovia)",
  // },
  // {
  //   municipio: "Navas de San Antonio",
  //   provincia: "Segovia",
  //   id: "40146",
  //   label: "Navas de San Antonio (Segovia)",
  // },
  // {
  //   municipio: "Pelayos del Arroyo",
  //   provincia: "Segovia",
  //   id: "40157",
  //   label: "Pelayos del Arroyo (Segovia)",
  // },
  // {
  //   municipio: "Palazuelos de Eresma",
  //   provincia: "Segovia",
  //   id: "40155",
  //   label: "Palazuelos de Eresma (Segovia)",
  // },
  // {
  //   municipio: "Pedraza",
  //   provincia: "Segovia",
  //   id: "40156",
  //   label: "Pedraza (Segovia)",
  // },
  // {
  //   municipio: "Pinarnegrillo",
  //   provincia: "Segovia",
  //   id: "40160",
  //   label: "Pinarnegrillo (Segovia)",
  // },
  // {
  //   municipio: "Carabias",
  //   provincia: "Segovia",
  //   id: "40161",
  //   label: "Carabias (Segovia)",
  // },
  // {
  //   municipio: "Rapariegos",
  //   provincia: "Segovia",
  //   id: "40164",
  //   label: "Rapariegos (Segovia)",
  // },
  // {
  //   municipio: "Prádena",
  //   provincia: "Segovia",
  //   id: "40162",
  //   label: "Prádena (Segovia)",
  // },
  // {
  //   municipio: "Puebla de Pedraza",
  //   provincia: "Segovia",
  //   id: "40163",
  //   label: "Puebla de Pedraza (Segovia)",
  // },
  // {
  //   municipio: "Santo Domingo de Pirón",
  //   provincia: "Segovia",
  //   id: "40190",
  //   label: "Santo Domingo de Pirón (Segovia)",
  // },
  // {
  //   municipio: "Santo Tomé del Puerto",
  //   provincia: "Segovia",
  //   id: "40191",
  //   label: "Santo Tomé del Puerto (Segovia)",
  // },
  // {
  //   municipio: "Riaguas de San Bartolomé",
  //   provincia: "Segovia",
  //   id: "40168",
  //   label: "Riaguas de San Bartolomé (Segovia)",
  // },
  // {
  //   municipio: "Riaza",
  //   provincia: "Segovia",
  //   id: "40170",
  //   label: "Riaza (Segovia)",
  // },
  // {
  //   municipio: "Ribota",
  //   provincia: "Segovia",
  //   id: "40171",
  //   label: "Ribota (Segovia)",
  // },
  // {
  //   municipio: "Sangarcía",
  //   provincia: "Segovia",
  //   id: "40180",
  //   label: "Sangarcía (Segovia)",
  // },
  // {
  //   municipio: "Riofrío de Riaza",
  //   provincia: "Segovia",
  //   id: "40172",
  //   label: "Riofrío de Riaza (Segovia)",
  // },
  // {
  //   municipio: "Sacramenia",
  //   provincia: "Segovia",
  //   id: "40174",
  //   label: "Sacramenia (Segovia)",
  // },
  // {
  //   municipio: "Samboal",
  //   provincia: "Segovia",
  //   id: "40176",
  //   label: "Samboal (Segovia)",
  // },
  // {
  //   municipio: "Sebúlcor",
  //   provincia: "Segovia",
  //   id: "40193",
  //   label: "Sebúlcor (Segovia)",
  // },
  // {
  //   municipio: "Segovia",
  //   provincia: "Segovia",
  //   id: "40194",
  //   label: "Segovia (Segovia)",
  // },
  // {
  //   municipio: "San Cristóbal de Cuéllar",
  //   provincia: "Segovia",
  //   id: "40177",
  //   label: "San Cristóbal de Cuéllar (Segovia)",
  // },
  // {
  //   municipio: "San Cristóbal de la Vega",
  //   provincia: "Segovia",
  //   id: "40178",
  //   label: "San Cristóbal de la Vega (Segovia)",
  // },
  // {
  //   municipio: "Real Sitio de San Ildefonso",
  //   provincia: "Segovia",
  //   id: "40181",
  //   label: "Real Sitio de San Ildefonso (Segovia)",
  // },
  // {
  //   municipio: "Torrecilla del Pinar",
  //   provincia: "Segovia",
  //   id: "40204",
  //   label: "Torrecilla del Pinar (Segovia)",
  // },
  // {
  //   municipio: "San Martín y Mudrián",
  //   provincia: "Segovia",
  //   id: "40182",
  //   label: "San Martín y Mudrián (Segovia)",
  // },
  // {
  //   municipio: "San Miguel de Bernuy",
  //   provincia: "Segovia",
  //   id: "40183",
  //   label: "San Miguel de Bernuy (Segovia)",
  // },
  // {
  //   municipio: "San Pedro de Gaíllos",
  //   provincia: "Segovia",
  //   id: "40184",
  //   label: "San Pedro de Gaíllos (Segovia)",
  // },
  // {
  //   municipio: "Santa María la Real de Nieva",
  //   provincia: "Segovia",
  //   id: "40185",
  //   label: "Santa María la Real de Nieva (Segovia)",
  // },
  // {
  //   municipio: "Santa Marta del Cerro",
  //   provincia: "Segovia",
  //   id: "40186",
  //   label: "Santa Marta del Cerro (Segovia)",
  // },
  // {
  //   municipio: "Sequera de Fresno",
  //   provincia: "Segovia",
  //   id: "40196",
  //   label: "Sequera de Fresno (Segovia)",
  // },
  // {
  //   municipio: "Sotillo",
  //   provincia: "Segovia",
  //   id: "40198",
  //   label: "Sotillo (Segovia)",
  // },
  // {
  //   municipio: "Sotosalbos",
  //   provincia: "Segovia",
  //   id: "40199",
  //   label: "Sotosalbos (Segovia)",
  // },
  // {
  //   municipio: "Tabanera la Luenga",
  //   provincia: "Segovia",
  //   id: "40200",
  //   label: "Tabanera la Luenga (Segovia)",
  // },
  // {
  //   municipio: "Tolocirio",
  //   provincia: "Segovia",
  //   id: "40201",
  //   label: "Tolocirio (Segovia)",
  // },
  // {
  //   municipio: "Torreadrada",
  //   provincia: "Segovia",
  //   id: "40202",
  //   label: "Torreadrada (Segovia)",
  // },
  // {
  //   municipio: "Torrecaballeros",
  //   provincia: "Segovia",
  //   id: "40203",
  //   label: "Torrecaballeros (Segovia)",
  // },
  // {
  //   municipio: "Torreiglesias",
  //   provincia: "Segovia",
  //   id: "40205",
  //   label: "Torreiglesias (Segovia)",
  // },
  // {
  //   municipio: "Torre Val de San Pedro",
  //   provincia: "Segovia",
  //   id: "40206",
  //   label: "Torre Val de San Pedro (Segovia)",
  // },
  // {
  //   municipio: "Trescasas",
  //   provincia: "Segovia",
  //   id: "40207",
  //   label: "Trescasas (Segovia)",
  // },
  // {
  //   municipio: "Urueñas",
  //   provincia: "Segovia",
  //   id: "40210",
  //   label: "Urueñas (Segovia)",
  // },
  // {
  //   municipio: "Valdeprados",
  //   provincia: "Segovia",
  //   id: "40211",
  //   label: "Valdeprados (Segovia)",
  // },
  // {
  //   municipio: "Turégano",
  //   provincia: "Segovia",
  //   id: "40208",
  //   label: "Turégano (Segovia)",
  // },
  // {
  //   municipio: "Valseca",
  //   provincia: "Segovia",
  //   id: "40214",
  //   label: "Valseca (Segovia)",
  // },
  // {
  //   municipio: "Valtiendas",
  //   provincia: "Segovia",
  //   id: "40215",
  //   label: "Valtiendas (Segovia)",
  // },
  // {
  //   municipio: "Valverde del Majano",
  //   provincia: "Segovia",
  //   id: "40216",
  //   label: "Valverde del Majano (Segovia)",
  // },
  // {
  //   municipio: "Valle de Tabladillo",
  //   provincia: "Segovia",
  //   id: "40218",
  //   label: "Valle de Tabladillo (Segovia)",
  // },
  // {
  //   municipio: "Vallelado",
  //   provincia: "Segovia",
  //   id: "40219",
  //   label: "Vallelado (Segovia)",
  // },
  // {
  //   municipio: "Marazoleja",
  //   provincia: "Segovia",
  //   id: "40903",
  //   label: "Marazoleja (Segovia)",
  // },
  // {
  //   municipio: "Navas de Riofrío",
  //   provincia: "Segovia",
  //   id: "40904",
  //   label: "Navas de Riofrío (Segovia)",
  // },
  // {
  //   municipio: "Villaverde de Montejo",
  //   provincia: "Segovia",
  //   id: "40229",
  //   label: "Villaverde de Montejo (Segovia)",
  // },
  // {
  //   municipio: "Cuevas de Provanco",
  //   provincia: "Segovia",
  //   id: "40905",
  //   label: "Cuevas de Provanco (Segovia)",
  // },
  // {
  //   municipio: "Valleruela de Pedraza",
  //   provincia: "Segovia",
  //   id: "40220",
  //   label: "Valleruela de Pedraza (Segovia)",
  // },
  // {
  //   municipio: "Valleruela de Sepúlveda",
  //   provincia: "Segovia",
  //   id: "40221",
  //   label: "Valleruela de Sepúlveda (Segovia)",
  // },
  // {
  //   municipio: "Veganzones",
  //   provincia: "Segovia",
  //   id: "40222",
  //   label: "Veganzones (Segovia)",
  // },
  // {
  //   municipio: "Vegas de Matute",
  //   provincia: "Segovia",
  //   id: "40223",
  //   label: "Vegas de Matute (Segovia)",
  // },
  // {
  //   municipio: "Villacastín",
  //   provincia: "Segovia",
  //   id: "40225",
  //   label: "Villacastín (Segovia)",
  // },
  // {
  //   municipio: "Villaverde de Íscar",
  //   provincia: "Segovia",
  //   id: "40228",
  //   label: "Villaverde de Íscar (Segovia)",
  // },
  // {
  //   municipio: "San Cristóbal de Segovia",
  //   provincia: "Segovia",
  //   id: "40906",
  //   label: "San Cristóbal de Segovia (Segovia)",
  // },
  // {
  //   municipio: "Aguadulce",
  //   provincia: "Sevilla",
  //   id: "41001",
  //   label: "Aguadulce (Sevilla)",
  // },
  // {
  //   municipio: "Alanís",
  //   provincia: "Sevilla",
  //   id: "41002",
  //   label: "Alanís (Sevilla)",
  // },
  // {
  //   municipio: "Cozuelos de Fuentidueña",
  //   provincia: "Segovia",
  //   id: "40902",
  //   label: "Cozuelos de Fuentidueña (Segovia)",
  // },
  // {
  //   municipio: "Alcalá de Guadaíra",
  //   provincia: "Sevilla",
  //   id: "41004",
  //   label: "Alcalá de Guadaíra (Sevilla)",
  // },
  // {
  //   municipio: "Albaida del Aljarafe",
  //   provincia: "Sevilla",
  //   id: "41003",
  //   label: "Albaida del Aljarafe (Sevilla)",
  // },
  // {
  //   municipio: "Villeguillo",
  //   provincia: "Segovia",
  //   id: "40230",
  //   label: "Villeguillo (Segovia)",
  // },
  // {
  //   municipio: "Yanguas de Eresma",
  //   provincia: "Segovia",
  //   id: "40231",
  //   label: "Yanguas de Eresma (Segovia)",
  // },
  // {
  //   municipio: "Alcalá del Río",
  //   provincia: "Sevilla",
  //   id: "41005",
  //   label: "Alcalá del Río (Sevilla)",
  // },
  // {
  //   municipio: "Zarzuela del Monte",
  //   provincia: "Segovia",
  //   id: "40233",
  //   label: "Zarzuela del Monte (Segovia)",
  // },
  // {
  //   municipio: "Zarzuela del Pinar",
  //   provincia: "Segovia",
  //   id: "40234",
  //   label: "Zarzuela del Pinar (Segovia)",
  // },
  // {
  //   municipio: "Ortigosa del Monte",
  //   provincia: "Segovia",
  //   id: "40901",
  //   label: "Ortigosa del Monte (Segovia)",
  // },
  // {
  //   municipio: "Alcolea del Río",
  //   provincia: "Sevilla",
  //   id: "41006",
  //   label: "Alcolea del Río (Sevilla)",
  // },
  // {
  //   municipio: "Algaba, La",
  //   provincia: "Sevilla",
  //   id: "41007",
  //   label: "Algaba, La (Sevilla)",
  // },
  // {
  //   municipio: "Algámitas",
  //   provincia: "Sevilla",
  //   id: "41008",
  //   label: "Algámitas (Sevilla)",
  // },
  // {
  //   municipio: "Almadén de la Plata",
  //   provincia: "Sevilla",
  //   id: "41009",
  //   label: "Almadén de la Plata (Sevilla)",
  // },
  // {
  //   municipio: "Burguillos",
  //   provincia: "Sevilla",
  //   id: "41019",
  //   label: "Burguillos (Sevilla)",
  // },
  // {
  //   municipio: "Almensilla",
  //   provincia: "Sevilla",
  //   id: "41010",
  //   label: "Almensilla (Sevilla)",
  // },
  // {
  //   municipio: "Arahal",
  //   provincia: "Sevilla",
  //   id: "41011",
  //   label: "Arahal (Sevilla)",
  // },
  // {
  //   municipio: "Aznalcázar",
  //   provincia: "Sevilla",
  //   id: "41012",
  //   label: "Aznalcázar (Sevilla)",
  // },
  // {
  //   municipio: "Dos Hermanas",
  //   provincia: "Sevilla",
  //   id: "41038",
  //   label: "Dos Hermanas (Sevilla)",
  // },
  // {
  //   municipio: "Estepa",
  //   provincia: "Sevilla",
  //   id: "41041",
  //   label: "Estepa (Sevilla)",
  // },
  // {
  //   municipio: "Fuentes de Andalucía",
  //   provincia: "Sevilla",
  //   id: "41042",
  //   label: "Fuentes de Andalucía (Sevilla)",
  // },
  // {
  //   municipio: "Badolatosa",
  //   provincia: "Sevilla",
  //   id: "41014",
  //   label: "Badolatosa (Sevilla)",
  // },
  // {
  //   municipio: "Benacazón",
  //   provincia: "Sevilla",
  //   id: "41015",
  //   label: "Benacazón (Sevilla)",
  // },
  // {
  //   municipio: "Bormujos",
  //   provincia: "Sevilla",
  //   id: "41017",
  //   label: "Bormujos (Sevilla)",
  // },
  // {
  //   municipio: "Brenes",
  //   provincia: "Sevilla",
  //   id: "41018",
  //   label: "Brenes (Sevilla)",
  // },
  // {
  //   municipio: "Cabezas de San Juan, Las",
  //   provincia: "Sevilla",
  //   id: "41020",
  //   label: "Cabezas de San Juan, Las (Sevilla)",
  // },
  // {
  //   municipio: "Camas",
  //   provincia: "Sevilla",
  //   id: "41021",
  //   label: "Camas (Sevilla)",
  // },
  // {
  //   municipio: "Serón de Nágima",
  //   provincia: "Soria",
  //   id: "42171",
  //   label: "Serón de Nágima (Soria)",
  // },
  // {
  //   municipio: "Campana, La",
  //   provincia: "Sevilla",
  //   id: "41022",
  //   label: "Campana, La (Sevilla)",
  // },
  // {
  //   municipio: "Lantejuela",
  //   provincia: "Sevilla",
  //   id: "41052",
  //   label: "Lantejuela (Sevilla)",
  // },
  // {
  //   municipio: "Lebrija",
  //   provincia: "Sevilla",
  //   id: "41053",
  //   label: "Lebrija (Sevilla)",
  // },
  // {
  //   municipio: "Cantillana",
  //   provincia: "Sevilla",
  //   id: "41023",
  //   label: "Cantillana (Sevilla)",
  // },
  // {
  //   municipio: "Lora de Estepa",
  //   provincia: "Sevilla",
  //   id: "41054",
  //   label: "Lora de Estepa (Sevilla)",
  // },
  // {
  //   municipio: "Carrión de los Céspedes",
  //   provincia: "Sevilla",
  //   id: "41025",
  //   label: "Carrión de los Céspedes (Sevilla)",
  // },
  // {
  //   municipio: "Arenillas",
  //   provincia: "Soria",
  //   id: "42026",
  //   label: "Arenillas (Soria)",
  // },
  // {
  //   municipio: "Casariche",
  //   provincia: "Sevilla",
  //   id: "41026",
  //   label: "Casariche (Sevilla)",
  // },
  // {
  //   municipio: "Castilblanco de los Arroyos",
  //   provincia: "Sevilla",
  //   id: "41027",
  //   label: "Castilblanco de los Arroyos (Sevilla)",
  // },
  // {
  //   municipio: "Castilleja de Guzmán",
  //   provincia: "Sevilla",
  //   id: "41028",
  //   label: "Castilleja de Guzmán (Sevilla)",
  // },
  // {
  //   municipio: "Castilleja de la Cuesta",
  //   provincia: "Sevilla",
  //   id: "41029",
  //   label: "Castilleja de la Cuesta (Sevilla)",
  // },
  // {
  //   municipio: "Castilleja del Campo",
  //   provincia: "Sevilla",
  //   id: "41030",
  //   label: "Castilleja del Campo (Sevilla)",
  // },
  // {
  //   municipio: "Castillo de las Guardas, El",
  //   provincia: "Sevilla",
  //   id: "41031",
  //   label: "Castillo de las Guardas, El (Sevilla)",
  // },
  // {
  //   municipio: "Cazalla de la Sierra",
  //   provincia: "Sevilla",
  //   id: "41032",
  //   label: "Cazalla de la Sierra (Sevilla)",
  // },
  // {
  //   municipio: "Constantina",
  //   provincia: "Sevilla",
  //   id: "41033",
  //   label: "Constantina (Sevilla)",
  // },
  // {
  //   municipio: "Marinaleda",
  //   provincia: "Sevilla",
  //   id: "41061",
  //   label: "Marinaleda (Sevilla)",
  // },
  // {
  //   municipio: "Coria del Río",
  //   provincia: "Sevilla",
  //   id: "41034",
  //   label: "Coria del Río (Sevilla)",
  // },
  // {
  //   municipio: "Rubio, El",
  //   provincia: "Sevilla",
  //   id: "41084",
  //   label: "Rubio, El (Sevilla)",
  // },
  // {
  //   municipio: "Coripe",
  //   provincia: "Sevilla",
  //   id: "41035",
  //   label: "Coripe (Sevilla)",
  // },
  // {
  //   municipio: "Coronil, El",
  //   provincia: "Sevilla",
  //   id: "41036",
  //   label: "Coronil, El (Sevilla)",
  // },
  // {
  //   municipio: "Corrales, Los",
  //   provincia: "Sevilla",
  //   id: "41037",
  //   label: "Corrales, Los (Sevilla)",
  // },
  // {
  //   municipio: "Garrobo, El",
  //   provincia: "Sevilla",
  //   id: "41043",
  //   label: "Garrobo, El (Sevilla)",
  // },
  // {
  //   municipio: "Gelves",
  //   provincia: "Sevilla",
  //   id: "41044",
  //   label: "Gelves (Sevilla)",
  // },
  // {
  //   municipio: "Gerena",
  //   provincia: "Sevilla",
  //   id: "41045",
  //   label: "Gerena (Sevilla)",
  // },
  // {
  //   municipio: "San Juan de Aznalfarache",
  //   provincia: "Sevilla",
  //   id: "41086",
  //   label: "San Juan de Aznalfarache (Sevilla)",
  // },
  // {
  //   municipio: "Gilena",
  //   provincia: "Sevilla",
  //   id: "41046",
  //   label: "Gilena (Sevilla)",
  // },
  // {
  //   municipio: "Tocina",
  //   provincia: "Sevilla",
  //   id: "41092",
  //   label: "Tocina (Sevilla)",
  // },
  // {
  //   municipio: "Gines",
  //   provincia: "Sevilla",
  //   id: "41047",
  //   label: "Gines (Sevilla)",
  // },
  // {
  //   municipio: "Guadalcanal",
  //   provincia: "Sevilla",
  //   id: "41048",
  //   label: "Guadalcanal (Sevilla)",
  // },
  // {
  //   municipio: "Adradas",
  //   provincia: "Soria",
  //   id: "42003",
  //   label: "Adradas (Soria)",
  // },
  // {
  //   municipio: "Herrera",
  //   provincia: "Sevilla",
  //   id: "41050",
  //   label: "Herrera (Sevilla)",
  // },
  // {
  //   municipio: "Huévar del Aljarafe",
  //   provincia: "Sevilla",
  //   id: "41051",
  //   label: "Huévar del Aljarafe (Sevilla)",
  // },
  // {
  //   municipio: "Arévalo de la Sierra",
  //   provincia: "Soria",
  //   id: "42027",
  //   label: "Arévalo de la Sierra (Soria)",
  // },
  // {
  //   municipio: "Luisiana, La",
  //   provincia: "Sevilla",
  //   id: "41056",
  //   label: "Luisiana, La (Sevilla)",
  // },
  // {
  //   municipio: "Ausejo de la Sierra",
  //   provincia: "Soria",
  //   id: "42028",
  //   label: "Ausejo de la Sierra (Soria)",
  // },
  // {
  //   municipio: "Mairena del Alcor",
  //   provincia: "Sevilla",
  //   id: "41058",
  //   label: "Mairena del Alcor (Sevilla)",
  // },
  // {
  //   municipio: "Bliecos",
  //   provincia: "Soria",
  //   id: "42037",
  //   label: "Bliecos (Soria)",
  // },
  // {
  //   municipio: "Mairena del Aljarafe",
  //   provincia: "Sevilla",
  //   id: "41059",
  //   label: "Mairena del Aljarafe (Sevilla)",
  // },
  // {
  //   municipio: "Marchena",
  //   provincia: "Sevilla",
  //   id: "41060",
  //   label: "Marchena (Sevilla)",
  // },
  // {
  //   municipio: "Martín de la Jara",
  //   provincia: "Sevilla",
  //   id: "41062",
  //   label: "Martín de la Jara (Sevilla)",
  // },
  // {
  //   municipio: "Aldehuelas, Las",
  //   provincia: "Soria",
  //   id: "42014",
  //   label: "Aldehuelas, Las (Soria)",
  // },
  // {
  //   municipio: "Molares, Los",
  //   provincia: "Sevilla",
  //   id: "41063",
  //   label: "Molares, Los (Sevilla)",
  // },
  // {
  //   municipio: "San Nicolás del Puerto",
  //   provincia: "Sevilla",
  //   id: "41088",
  //   label: "San Nicolás del Puerto (Sevilla)",
  // },
  // {
  //   municipio: "Montellano",
  //   provincia: "Sevilla",
  //   id: "41064",
  //   label: "Montellano (Sevilla)",
  // },
  // {
  //   municipio: "Morón de la Frontera",
  //   provincia: "Sevilla",
  //   id: "41065",
  //   label: "Morón de la Frontera (Sevilla)",
  // },
  // {
  //   municipio: "Navas de la Concepción, Las",
  //   provincia: "Sevilla",
  //   id: "41066",
  //   label: "Navas de la Concepción, Las (Sevilla)",
  // },
  // {
  //   municipio: "Olivares",
  //   provincia: "Sevilla",
  //   id: "41067",
  //   label: "Olivares (Sevilla)",
  // },
  // {
  //   municipio: "Palacios y Villafranca, Los",
  //   provincia: "Sevilla",
  //   id: "41069",
  //   label: "Palacios y Villafranca, Los (Sevilla)",
  // },
  // {
  //   municipio: "Palomares del Río",
  //   provincia: "Sevilla",
  //   id: "41070",
  //   label: "Palomares del Río (Sevilla)",
  // },
  // {
  //   municipio: "Paradas",
  //   provincia: "Sevilla",
  //   id: "41071",
  //   label: "Paradas (Sevilla)",
  // },
  // {
  //   municipio: "Cañizo",
  //   provincia: "Zamora",
  //   id: "49035",
  //   label: "Cañizo (Zamora)",
  // },
  // {
  //   municipio: "Pedrera",
  //   provincia: "Sevilla",
  //   id: "41072",
  //   label: "Pedrera (Sevilla)",
  // },
  // {
  //   municipio: "Pedroso, El",
  //   provincia: "Sevilla",
  //   id: "41073",
  //   label: "Pedroso, El (Sevilla)",
  // },
  // {
  //   municipio: "Peñaflor",
  //   provincia: "Sevilla",
  //   id: "41074",
  //   label: "Peñaflor (Sevilla)",
  // },
  // {
  //   municipio: "Espeja de San Marcelino",
  //   provincia: "Soria",
  //   id: "42080",
  //   label: "Espeja de San Marcelino (Soria)",
  // },
  // {
  //   municipio: "Pilas",
  //   provincia: "Sevilla",
  //   id: "41075",
  //   label: "Pilas (Sevilla)",
  // },
  // {
  //   municipio: "Pruna",
  //   provincia: "Sevilla",
  //   id: "41076",
  //   label: "Pruna (Sevilla)",
  // },
  // {
  //   municipio: "Puebla de Cazalla, La",
  //   provincia: "Sevilla",
  //   id: "41077",
  //   label: "Puebla de Cazalla, La (Sevilla)",
  // },
  // {
  //   municipio: "Puebla de los Infantes, La",
  //   provincia: "Sevilla",
  //   id: "41078",
  //   label: "Puebla de los Infantes, La (Sevilla)",
  // },
  // {
  //   municipio: "Puebla del Río, La",
  //   provincia: "Sevilla",
  //   id: "41079",
  //   label: "Puebla del Río, La (Sevilla)",
  // },
  // {
  //   municipio: "Real de la Jara, El",
  //   provincia: "Sevilla",
  //   id: "41080",
  //   label: "Real de la Jara, El (Sevilla)",
  // },
  // {
  //   municipio: "Rinconada, La",
  //   provincia: "Sevilla",
  //   id: "41081",
  //   label: "Rinconada, La (Sevilla)",
  // },
  // {
  //   municipio: "Roda de Andalucía, La",
  //   provincia: "Sevilla",
  //   id: "41082",
  //   label: "Roda de Andalucía, La (Sevilla)",
  // },
  // {
  //   municipio: "Ronquillo, El",
  //   provincia: "Sevilla",
  //   id: "41083",
  //   label: "Ronquillo, El (Sevilla)",
  // },
  // {
  //   municipio: "Santiponce",
  //   provincia: "Sevilla",
  //   id: "41089",
  //   label: "Santiponce (Sevilla)",
  // },
  // {
  //   municipio: "Saucejo, El",
  //   provincia: "Sevilla",
  //   id: "41090",
  //   label: "Saucejo, El (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091",
  //   label: "Sevilla (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_1_SanPabloSantaJusta",
  //   label: "Sevilla - San Pablo - Santa Justa (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_2_CascoAntiguo",
  //   label: "Sevilla - Casco Antiguo (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_3_Nervion",
  //   label: "Sevilla - Nervión (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_4_Macarena",
  //   label: "Sevilla - Macarena (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_5_LosRemedios",
  //   label: "Sevilla - Los Remedios (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_6_BellavistaLaPalmera",
  //   label: "Sevilla - Bellavista - La Palmera (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_7_Sur",
  //   label: "Sevilla - Sur (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_8_CerroAmate",
  //   label: "Sevilla - Cerro - Amate (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_9_EsteAlcosaTorreblanca",
  //   label: "Sevilla - Este - Alcosa - Torreblanca (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_10_Triana",
  //   label: "Sevilla - Triana (Sevilla)",
  // },
  // {
  //   municipio: "Sevilla",
  //   provincia: "Sevilla",
  //   id: "41091_11_Norte",
  //   label: "Sevilla - Norte (Sevilla)",
  // },
  // {
  //   municipio: "Fuentecantos",
  //   provincia: "Soria",
  //   id: "42087",
  //   label: "Fuentecantos (Soria)",
  // },
  // {
  //   municipio: "Tomares",
  //   provincia: "Sevilla",
  //   id: "41093",
  //   label: "Tomares (Sevilla)",
  // },
  // {
  //   municipio: "Umbrete",
  //   provincia: "Sevilla",
  //   id: "41094",
  //   label: "Umbrete (Sevilla)",
  // },
  // {
  //   municipio: "Valencina de la Concepción",
  //   provincia: "Sevilla",
  //   id: "41096",
  //   label: "Valencina de la Concepción (Sevilla)",
  // },
  // {
  //   municipio: "Alconaba",
  //   provincia: "Soria",
  //   id: "42006",
  //   label: "Alconaba (Soria)",
  // },
  // {
  //   municipio: "Villamanrique de la Condesa",
  //   provincia: "Sevilla",
  //   id: "41097",
  //   label: "Villamanrique de la Condesa (Sevilla)",
  // },
  // {
  //   municipio: "Villaverde del Río",
  //   provincia: "Sevilla",
  //   id: "41101",
  //   label: "Villaverde del Río (Sevilla)",
  // },
  // {
  //   municipio: "Villanueva del Ariscal",
  //   provincia: "Sevilla",
  //   id: "41098",
  //   label: "Villanueva del Ariscal (Sevilla)",
  // },
  // {
  //   municipio: "Villanueva del Río y Minas",
  //   provincia: "Sevilla",
  //   id: "41099",
  //   label: "Villanueva del Río y Minas (Sevilla)",
  // },
  // {
  //   municipio: "Villanueva de San Juan",
  //   provincia: "Sevilla",
  //   id: "41100",
  //   label: "Villanueva de San Juan (Sevilla)",
  // },
  // {
  //   municipio: "Viso del Alcor, El",
  //   provincia: "Sevilla",
  //   id: "41102",
  //   label: "Viso del Alcor, El (Sevilla)",
  // },
  // {
  //   municipio: "Cañada Rosal",
  //   provincia: "Sevilla",
  //   id: "41901",
  //   label: "Cañada Rosal (Sevilla)",
  // },
  // {
  //   municipio: "Isla Mayor",
  //   provincia: "Sevilla",
  //   id: "41902",
  //   label: "Isla Mayor (Sevilla)",
  // },
  // {
  //   municipio: "Aldealafuente",
  //   provincia: "Soria",
  //   id: "42009",
  //   label: "Aldealafuente (Soria)",
  // },
  // {
  //   municipio: "Cuervo de Sevilla, El",
  //   provincia: "Sevilla",
  //   id: "41903",
  //   label: "Cuervo de Sevilla, El (Sevilla)",
  // },
  // {
  //   municipio: "Abejar",
  //   provincia: "Soria",
  //   id: "42001",
  //   label: "Abejar (Soria)",
  // },
  // {
  //   municipio: "Aldealices",
  //   provincia: "Soria",
  //   id: "42010",
  //   label: "Aldealices (Soria)",
  // },
  // {
  //   municipio: "Fresno de Caracena",
  //   provincia: "Soria",
  //   id: "42084",
  //   label: "Fresno de Caracena (Soria)",
  // },
  // {
  //   municipio: "Ágreda",
  //   provincia: "Soria",
  //   id: "42004",
  //   label: "Ágreda (Soria)",
  // },
  // {
  //   municipio: "Fuentes de Magaña",
  //   provincia: "Soria",
  //   id: "42092",
  //   label: "Fuentes de Magaña (Soria)",
  // },
  // {
  //   municipio: "Alcubilla de Avellaneda",
  //   provincia: "Soria",
  //   id: "42007",
  //   label: "Alcubilla de Avellaneda (Soria)",
  // },
  // {
  //   municipio: "Alcubilla de las Peñas",
  //   provincia: "Soria",
  //   id: "42008",
  //   label: "Alcubilla de las Peñas (Soria)",
  // },
  // {
  //   municipio: "Fuentecambrón",
  //   provincia: "Soria",
  //   id: "42086",
  //   label: "Fuentecambrón (Soria)",
  // },
  // {
  //   municipio: "Santa María de las Hoyas",
  //   provincia: "Soria",
  //   id: "42168",
  //   label: "Santa María de las Hoyas (Soria)",
  // },
  // {
  //   municipio: "Alentisque",
  //   provincia: "Soria",
  //   id: "42015",
  //   label: "Alentisque (Soria)",
  // },
  // {
  //   municipio: "Aliud",
  //   provincia: "Soria",
  //   id: "42016",
  //   label: "Aliud (Soria)",
  // },
  // {
  //   municipio: "Almajano",
  //   provincia: "Soria",
  //   id: "42017",
  //   label: "Almajano (Soria)",
  // },
  // {
  //   municipio: "Borjabad",
  //   provincia: "Soria",
  //   id: "42038",
  //   label: "Borjabad (Soria)",
  // },
  // {
  //   municipio: "Almaluez",
  //   provincia: "Soria",
  //   id: "42018",
  //   label: "Almaluez (Soria)",
  // },
  // {
  //   municipio: "Borobia",
  //   provincia: "Soria",
  //   id: "42039",
  //   label: "Borobia (Soria)",
  // },
  // {
  //   municipio: "Almarza",
  //   provincia: "Soria",
  //   id: "42019",
  //   label: "Almarza (Soria)",
  // },
  // {
  //   municipio: "Almazul",
  //   provincia: "Soria",
  //   id: "42021",
  //   label: "Almazul (Soria)",
  // },
  // {
  //   municipio: "Almazán",
  //   provincia: "Soria",
  //   id: "42020",
  //   label: "Almazán (Soria)",
  // },
  // {
  //   municipio: "Arancón",
  //   provincia: "Soria",
  //   id: "42024",
  //   label: "Arancón (Soria)",
  // },
  // {
  //   municipio: "Almenar de Soria",
  //   provincia: "Soria",
  //   id: "42022",
  //   label: "Almenar de Soria (Soria)",
  // },
  // {
  //   municipio: "Alpanseque",
  //   provincia: "Soria",
  //   id: "42023",
  //   label: "Alpanseque (Soria)",
  // },
  // {
  //   municipio: "Arcos de Jalón",
  //   provincia: "Soria",
  //   id: "42025",
  //   label: "Arcos de Jalón (Soria)",
  // },
  // {
  //   municipio: "Fuentestrún",
  //   provincia: "Soria",
  //   id: "42093",
  //   label: "Fuentestrún (Soria)",
  // },
  // {
  //   municipio: "Baraona",
  //   provincia: "Soria",
  //   id: "42029",
  //   label: "Baraona (Soria)",
  // },
  // {
  //   municipio: "Barca",
  //   provincia: "Soria",
  //   id: "42030",
  //   label: "Barca (Soria)",
  // },
  // {
  //   municipio: "Buberos",
  //   provincia: "Soria",
  //   id: "42041",
  //   label: "Buberos (Soria)",
  // },
  // {
  //   municipio: "Barcones",
  //   provincia: "Soria",
  //   id: "42031",
  //   label: "Barcones (Soria)",
  // },
  // {
  //   municipio: "Bayubas de Abajo",
  //   provincia: "Soria",
  //   id: "42032",
  //   label: "Bayubas de Abajo (Soria)",
  // },
  // {
  //   municipio: "Bayubas de Arriba",
  //   provincia: "Soria",
  //   id: "42033",
  //   label: "Bayubas de Arriba (Soria)",
  // },
  // {
  //   municipio: "Beratón",
  //   provincia: "Soria",
  //   id: "42034",
  //   label: "Beratón (Soria)",
  // },
  // {
  //   municipio: "Buitrago",
  //   provincia: "Soria",
  //   id: "42042",
  //   label: "Buitrago (Soria)",
  // },
  // {
  //   municipio: "Burgo de Osma-Ciudad de Osma",
  //   provincia: "Soria",
  //   id: "42043",
  //   label: "Burgo de Osma-Ciudad de Osma (Soria)",
  // },
  // {
  //   municipio: "Cabrejas del Campo",
  //   provincia: "Soria",
  //   id: "42044",
  //   label: "Cabrejas del Campo (Soria)",
  // },
  // {
  //   municipio: "Cabrejas del Pinar",
  //   provincia: "Soria",
  //   id: "42045",
  //   label: "Cabrejas del Pinar (Soria)",
  // },
  // {
  //   municipio: "Berlanga de Duero",
  //   provincia: "Soria",
  //   id: "42035",
  //   label: "Berlanga de Duero (Soria)",
  // },
  // {
  //   municipio: "Blacos",
  //   provincia: "Soria",
  //   id: "42036",
  //   label: "Blacos (Soria)",
  // },
  // {
  //   municipio: "Calatañazor",
  //   provincia: "Soria",
  //   id: "42046",
  //   label: "Calatañazor (Soria)",
  // },
  // {
  //   municipio: "Soliedra",
  //   provincia: "Soria",
  //   id: "42172",
  //   label: "Soliedra (Soria)",
  // },
  // {
  //   municipio: "Caltojar",
  //   provincia: "Soria",
  //   id: "42048",
  //   label: "Caltojar (Soria)",
  // },
  // {
  //   municipio: "Casarejos",
  //   provincia: "Soria",
  //   id: "42055",
  //   label: "Casarejos (Soria)",
  // },
  // {
  //   municipio: "Castilfrío de la Sierra",
  //   provincia: "Soria",
  //   id: "42056",
  //   label: "Castilfrío de la Sierra (Soria)",
  // },
  // {
  //   municipio: "Castilruiz",
  //   provincia: "Soria",
  //   id: "42057",
  //   label: "Castilruiz (Soria)",
  // },
  // {
  //   municipio: "Castillejo de Robledo",
  //   provincia: "Soria",
  //   id: "42058",
  //   label: "Castillejo de Robledo (Soria)",
  // },
  // {
  //   municipio: "Centenera de Andaluz",
  //   provincia: "Soria",
  //   id: "42059",
  //   label: "Centenera de Andaluz (Soria)",
  // },
  // {
  //   municipio: "Fuentelmonge",
  //   provincia: "Soria",
  //   id: "42088",
  //   label: "Fuentelmonge (Soria)",
  // },
  // {
  //   municipio: "Cerbón",
  //   provincia: "Soria",
  //   id: "42060",
  //   label: "Cerbón (Soria)",
  // },
  // {
  //   municipio: "Cidones",
  //   provincia: "Soria",
  //   id: "42061",
  //   label: "Cidones (Soria)",
  // },
  // {
  //   municipio: "Cigudosa",
  //   provincia: "Soria",
  //   id: "42062",
  //   label: "Cigudosa (Soria)",
  // },
  // {
  //   municipio: "Cihuela",
  //   provincia: "Soria",
  //   id: "42063",
  //   label: "Cihuela (Soria)",
  // },
  // {
  //   municipio: "Dévanos",
  //   provincia: "Soria",
  //   id: "42075",
  //   label: "Dévanos (Soria)",
  // },
  // {
  //   municipio: "Ciria",
  //   provincia: "Soria",
  //   id: "42064",
  //   label: "Ciria (Soria)",
  // },
  // {
  //   municipio: "Cirujales del Río",
  //   provincia: "Soria",
  //   id: "42065",
  //   label: "Cirujales del Río (Soria)",
  // },
  // {
  //   municipio: "Coscurita",
  //   provincia: "Soria",
  //   id: "42068",
  //   label: "Coscurita (Soria)",
  // },
  // {
  //   municipio: "Covaleda",
  //   provincia: "Soria",
  //   id: "42069",
  //   label: "Covaleda (Soria)",
  // },
  // {
  //   municipio: "Cubilla",
  //   provincia: "Soria",
  //   id: "42070",
  //   label: "Cubilla (Soria)",
  // },
  // {
  //   municipio: "Cubo de la Solana",
  //   provincia: "Soria",
  //   id: "42071",
  //   label: "Cubo de la Solana (Soria)",
  // },
  // {
  //   municipio: "Muriel Viejo",
  //   provincia: "Soria",
  //   id: "42125",
  //   label: "Muriel Viejo (Soria)",
  // },
  // {
  //   municipio: "Nafría de Ucero",
  //   provincia: "Soria",
  //   id: "42127",
  //   label: "Nafría de Ucero (Soria)",
  // },
  // {
  //   municipio: "Sotillo del Rincón",
  //   provincia: "Soria",
  //   id: "42174",
  //   label: "Sotillo del Rincón (Soria)",
  // },
  // {
  //   municipio: "Amposta",
  //   provincia: "Tarragona",
  //   id: "43014",
  //   label: "Amposta (Tarragona)",
  // },
  // {
  //   municipio: "Deza",
  //   provincia: "Soria",
  //   id: "42076",
  //   label: "Deza (Soria)",
  // },
  // {
  //   municipio: "Duruelo de la Sierra",
  //   provincia: "Soria",
  //   id: "42078",
  //   label: "Duruelo de la Sierra (Soria)",
  // },
  // {
  //   municipio: "Espejón",
  //   provincia: "Soria",
  //   id: "42081",
  //   label: "Espejón (Soria)",
  // },
  // {
  //   municipio: "Estepa de San Juan",
  //   provincia: "Soria",
  //   id: "42082",
  //   label: "Estepa de San Juan (Soria)",
  // },
  // {
  //   municipio: "Cuba, La",
  //   provincia: "Teruel",
  //   id: "44088",
  //   label: "Cuba, La (Teruel)",
  // },
  // {
  //   municipio: "Frechilla de Almazán",
  //   provincia: "Soria",
  //   id: "42083",
  //   label: "Frechilla de Almazán (Soria)",
  // },
  // {
  //   municipio: "Fuentearmegil",
  //   provincia: "Soria",
  //   id: "42085",
  //   label: "Fuentearmegil (Soria)",
  // },
  // {
  //   municipio: "Fuentelsaz de Soria",
  //   provincia: "Soria",
  //   id: "42089",
  //   label: "Fuentelsaz de Soria (Soria)",
  // },
  // {
  //   municipio: "Fuentepinilla",
  //   provincia: "Soria",
  //   id: "42090",
  //   label: "Fuentepinilla (Soria)",
  // },
  // {
  //   municipio: "Navaleno",
  //   provincia: "Soria",
  //   id: "42129",
  //   label: "Navaleno (Soria)",
  // },
  // {
  //   municipio: "Alcover",
  //   provincia: "Tarragona",
  //   id: "43005",
  //   label: "Alcover (Tarragona)",
  // },
  // {
  //   municipio: "Garray",
  //   provincia: "Soria",
  //   id: "42094",
  //   label: "Garray (Soria)",
  // },
  // {
  //   municipio: "Golmayo",
  //   provincia: "Soria",
  //   id: "42095",
  //   label: "Golmayo (Soria)",
  // },
  // {
  //   municipio: "Gómara",
  //   provincia: "Soria",
  //   id: "42096",
  //   label: "Gómara (Soria)",
  // },
  // {
  //   municipio: "Herrera de Soria",
  //   provincia: "Soria",
  //   id: "42098",
  //   label: "Herrera de Soria (Soria)",
  // },
  // {
  //   municipio: "Santa María de Huerta",
  //   provincia: "Soria",
  //   id: "42167",
  //   label: "Santa María de Huerta (Soria)",
  // },
  // {
  //   municipio: "Magaña",
  //   provincia: "Soria",
  //   id: "42107",
  //   label: "Magaña (Soria)",
  // },
  // {
  //   municipio: "Maján",
  //   provincia: "Soria",
  //   id: "42108",
  //   label: "Maján (Soria)",
  // },
  // {
  //   municipio: "Matalebreras",
  //   provincia: "Soria",
  //   id: "42110",
  //   label: "Matalebreras (Soria)",
  // },
  // {
  //   municipio: "Langa de Duero",
  //   provincia: "Soria",
  //   id: "42103",
  //   label: "Langa de Duero (Soria)",
  // },
  // {
  //   municipio: "Matamala de Almazán",
  //   provincia: "Soria",
  //   id: "42111",
  //   label: "Matamala de Almazán (Soria)",
  // },
  // {
  //   municipio: "Recuerda",
  //   provincia: "Soria",
  //   id: "42152",
  //   label: "Recuerda (Soria)",
  // },
  // {
  //   municipio: "Rioseco de Soria",
  //   provincia: "Soria",
  //   id: "42158",
  //   label: "Rioseco de Soria (Soria)",
  // },
  // {
  //   municipio: "Miño de Medinaceli",
  //   provincia: "Soria",
  //   id: "42115",
  //   label: "Miño de Medinaceli (Soria)",
  // },
  // {
  //   municipio: "Miño de San Esteban",
  //   provincia: "Soria",
  //   id: "42116",
  //   label: "Miño de San Esteban (Soria)",
  // },
  // {
  //   municipio: "Rello",
  //   provincia: "Soria",
  //   id: "42153",
  //   label: "Rello (Soria)",
  // },
  // {
  //   municipio: "Medinaceli",
  //   provincia: "Soria",
  //   id: "42113",
  //   label: "Medinaceli (Soria)",
  // },
  // {
  //   municipio: "Nolay",
  //   provincia: "Soria",
  //   id: "42131",
  //   label: "Nolay (Soria)",
  // },
  // {
  //   municipio: "Vallclara",
  //   provincia: "Tarragona",
  //   id: "43158",
  //   label: "Vallclara (Tarragona)",
  // },
  // {
  //   municipio: "Molinos de Duero",
  //   provincia: "Soria",
  //   id: "42117",
  //   label: "Molinos de Duero (Soria)",
  // },
  // {
  //   municipio: "Momblona",
  //   provincia: "Soria",
  //   id: "42118",
  //   label: "Momblona (Soria)",
  // },
  // {
  //   municipio: "Póveda de Soria, La",
  //   provincia: "Soria",
  //   id: "42141",
  //   label: "Póveda de Soria, La (Soria)",
  // },
  // {
  //   municipio: "Monteagudo de las Vicarías",
  //   provincia: "Soria",
  //   id: "42119",
  //   label: "Monteagudo de las Vicarías (Soria)",
  // },
  // {
  //   municipio: "Renieblas",
  //   provincia: "Soria",
  //   id: "42154",
  //   label: "Renieblas (Soria)",
  // },
  // {
  //   municipio: "Montejo de Tiermes",
  //   provincia: "Soria",
  //   id: "42120",
  //   label: "Montejo de Tiermes (Soria)",
  // },
  // {
  //   municipio: "Montenegro de Cameros",
  //   provincia: "Soria",
  //   id: "42121",
  //   label: "Montenegro de Cameros (Soria)",
  // },
  // {
  //   municipio: "Morón de Almazán",
  //   provincia: "Soria",
  //   id: "42123",
  //   label: "Morón de Almazán (Soria)",
  // },
  // {
  //   municipio: "Muriel de la Fuente",
  //   provincia: "Soria",
  //   id: "42124",
  //   label: "Muriel de la Fuente (Soria)",
  // },
  // {
  //   municipio: "Nepas",
  //   provincia: "Soria",
  //   id: "42130",
  //   label: "Nepas (Soria)",
  // },
  // {
  //   municipio: "Noviercas",
  //   provincia: "Soria",
  //   id: "42132",
  //   label: "Noviercas (Soria)",
  // },
  // {
  //   municipio: "Retortillo de Soria",
  //   provincia: "Soria",
  //   id: "42155",
  //   label: "Retortillo de Soria (Soria)",
  // },
  // {
  //   municipio: "Ólvega",
  //   provincia: "Soria",
  //   id: "42134",
  //   label: "Ólvega (Soria)",
  // },
  // {
  //   municipio: "Oncala",
  //   provincia: "Soria",
  //   id: "42135",
  //   label: "Oncala (Soria)",
  // },
  // {
  //   municipio: "Pinilla del Campo",
  //   provincia: "Soria",
  //   id: "42139",
  //   label: "Pinilla del Campo (Soria)",
  // },
  // {
  //   municipio: "Aldover",
  //   provincia: "Tarragona",
  //   id: "43006",
  //   label: "Aldover (Tarragona)",
  // },
  // {
  //   municipio: "Quintana Redonda",
  //   provincia: "Soria",
  //   id: "42144",
  //   label: "Quintana Redonda (Soria)",
  // },
  // {
  //   municipio: "Rábanos, Los",
  //   provincia: "Soria",
  //   id: "42149",
  //   label: "Rábanos, Los (Soria)",
  // },
  // {
  //   municipio: "Rebollar",
  //   provincia: "Soria",
  //   id: "42151",
  //   label: "Rebollar (Soria)",
  // },
  // {
  //   municipio: "San Felices",
  //   provincia: "Soria",
  //   id: "42163",
  //   label: "San Felices (Soria)",
  // },
  // {
  //   municipio: "Royo, El",
  //   provincia: "Soria",
  //   id: "42160",
  //   label: "Royo, El (Soria)",
  // },
  // {
  //   municipio: "San Leonardo de Yagüe",
  //   provincia: "Soria",
  //   id: "42164",
  //   label: "San Leonardo de Yagüe (Soria)",
  // },
  // {
  //   municipio: "Santa Cruz de Yanguas",
  //   provincia: "Soria",
  //   id: "42166",
  //   label: "Santa Cruz de Yanguas (Soria)",
  // },
  // {
  //   municipio: "San Pedro Manrique",
  //   provincia: "Soria",
  //   id: "42165",
  //   label: "San Pedro Manrique (Soria)",
  // },
  // {
  //   municipio: "Valdeavellano de Tera",
  //   provincia: "Soria",
  //   id: "42191",
  //   label: "Valdeavellano de Tera (Soria)",
  // },
  // {
  //   municipio: "Talveila",
  //   provincia: "Soria",
  //   id: "42178",
  //   label: "Talveila (Soria)",
  // },
  // {
  //   municipio: "Tardelcuende",
  //   provincia: "Soria",
  //   id: "42181",
  //   label: "Tardelcuende (Soria)",
  // },
  // {
  //   municipio: "Taroda",
  //   provincia: "Soria",
  //   id: "42182",
  //   label: "Taroda (Soria)",
  // },
  // {
  //   municipio: "Tejado",
  //   provincia: "Soria",
  //   id: "42183",
  //   label: "Tejado (Soria)",
  // },
  // {
  //   municipio: "Torlengua",
  //   provincia: "Soria",
  //   id: "42184",
  //   label: "Torlengua (Soria)",
  // },
  // {
  //   municipio: "Torreblacos",
  //   provincia: "Soria",
  //   id: "42185",
  //   label: "Torreblacos (Soria)",
  // },
  // {
  //   municipio: "Torrubia de Soria",
  //   provincia: "Soria",
  //   id: "42187",
  //   label: "Torrubia de Soria (Soria)",
  // },
  // {
  //   municipio: "Trévago",
  //   provincia: "Soria",
  //   id: "42188",
  //   label: "Trévago (Soria)",
  // },
  // {
  //   municipio: "Ucero",
  //   provincia: "Soria",
  //   id: "42189",
  //   label: "Ucero (Soria)",
  // },
  // {
  //   municipio: "Vadillo",
  //   provincia: "Soria",
  //   id: "42190",
  //   label: "Vadillo (Soria)",
  // },
  // {
  //   municipio: "Valdemaluque",
  //   provincia: "Soria",
  //   id: "42194",
  //   label: "Valdemaluque (Soria)",
  // },
  // {
  //   municipio: "Valdenebro",
  //   provincia: "Soria",
  //   id: "42195",
  //   label: "Valdenebro (Soria)",
  // },
  // {
  //   municipio: "Villaseca de Arciel",
  //   provincia: "Soria",
  //   id: "42213",
  //   label: "Villaseca de Arciel (Soria)",
  // },
  // {
  //   municipio: "Valdeprado",
  //   provincia: "Soria",
  //   id: "42196",
  //   label: "Valdeprado (Soria)",
  // },
  // {
  //   municipio: "Valtajeros",
  //   provincia: "Soria",
  //   id: "42198",
  //   label: "Valtajeros (Soria)",
  // },
  // {
  //   municipio: "Aleixar, L'",
  //   provincia: "Tarragona",
  //   id: "43007",
  //   label: "Aleixar, L' (Tarragona)",
  // },
  // {
  //   municipio: "Velamazán",
  //   provincia: "Soria",
  //   id: "42200",
  //   label: "Velamazán (Soria)",
  // },
  // {
  //   municipio: "Velilla de la Sierra",
  //   provincia: "Soria",
  //   id: "42201",
  //   label: "Velilla de la Sierra (Soria)",
  // },
  // {
  //   municipio: "Velilla de los Ajos",
  //   provincia: "Soria",
  //   id: "42202",
  //   label: "Velilla de los Ajos (Soria)",
  // },
  // {
  //   municipio: "Villanueva de Gormaz",
  //   provincia: "Soria",
  //   id: "42206",
  //   label: "Villanueva de Gormaz (Soria)",
  // },
  // {
  //   municipio: "Viana de Duero",
  //   provincia: "Soria",
  //   id: "42204",
  //   label: "Viana de Duero (Soria)",
  // },
  // {
  //   municipio: "Villaciervos",
  //   provincia: "Soria",
  //   id: "42205",
  //   label: "Villaciervos (Soria)",
  // },
  // {
  //   municipio: "Alfara de Carles",
  //   provincia: "Tarragona",
  //   id: "43008",
  //   label: "Alfara de Carles (Tarragona)",
  // },
  // {
  //   municipio: "Alforja",
  //   provincia: "Tarragona",
  //   id: "43009",
  //   label: "Alforja (Tarragona)",
  // },
  // {
  //   municipio: "Villar del Ala",
  //   provincia: "Soria",
  //   id: "42207",
  //   label: "Villar del Ala (Soria)",
  // },
  // {
  //   municipio: "Villar del Campo",
  //   provincia: "Soria",
  //   id: "42208",
  //   label: "Villar del Campo (Soria)",
  // },
  // {
  //   municipio: "Alió",
  //   provincia: "Tarragona",
  //   id: "43010",
  //   label: "Alió (Tarragona)",
  // },
  // {
  //   municipio: "Villar del Río",
  //   provincia: "Soria",
  //   id: "42209",
  //   label: "Villar del Río (Soria)",
  // },
  // {
  //   municipio: "Villares de Soria, Los",
  //   provincia: "Soria",
  //   id: "42211",
  //   label: "Villares de Soria, Los (Soria)",
  // },
  // {
  //   municipio: "Altafulla",
  //   provincia: "Tarragona",
  //   id: "43012",
  //   label: "Altafulla (Tarragona)",
  // },
  // {
  //   municipio: "Vinuesa",
  //   provincia: "Soria",
  //   id: "42215",
  //   label: "Vinuesa (Soria)",
  // },
  // {
  //   municipio: "Vizmanos",
  //   provincia: "Soria",
  //   id: "42216",
  //   label: "Vizmanos (Soria)",
  // },
  // {
  //   municipio: "Vozmediano",
  //   provincia: "Soria",
  //   id: "42217",
  //   label: "Vozmediano (Soria)",
  // },
  // {
  //   municipio: "Yanguas",
  //   provincia: "Soria",
  //   id: "42218",
  //   label: "Yanguas (Soria)",
  // },
  // {
  //   municipio: "Yelo",
  //   provincia: "Soria",
  //   id: "42219",
  //   label: "Yelo (Soria)",
  // },
  // {
  //   municipio: "Albinyana",
  //   provincia: "Tarragona",
  //   id: "43002",
  //   label: "Albinyana (Tarragona)",
  // },
  // {
  //   municipio: "Arboç, L'",
  //   provincia: "Tarragona",
  //   id: "43016",
  //   label: "Arboç, L' (Tarragona)",
  // },
  // {
  //   municipio: "Albiol, L'",
  //   provincia: "Tarragona",
  //   id: "43003",
  //   label: "Albiol, L' (Tarragona)",
  // },
  // {
  //   municipio: "Alcanar",
  //   provincia: "Tarragona",
  //   id: "43004",
  //   label: "Alcanar (Tarragona)",
  // },
  // {
  //   municipio: "Arbolí",
  //   provincia: "Tarragona",
  //   id: "43015",
  //   label: "Arbolí (Tarragona)",
  // },
  // {
  //   municipio: "Barberà de la Conca",
  //   provincia: "Tarragona",
  //   id: "43021",
  //   label: "Barberà de la Conca (Tarragona)",
  // },
  // {
  //   municipio: "Batea",
  //   provincia: "Tarragona",
  //   id: "43022",
  //   label: "Batea (Tarragona)",
  // },
  // {
  //   municipio: "Argentera, L'",
  //   provincia: "Tarragona",
  //   id: "43017",
  //   label: "Argentera, L' (Tarragona)",
  // },
  // {
  //   municipio: "Arnes",
  //   provincia: "Tarragona",
  //   id: "43018",
  //   label: "Arnes (Tarragona)",
  // },
  // {
  //   municipio: "Bellvei",
  //   provincia: "Tarragona",
  //   id: "43024",
  //   label: "Bellvei (Tarragona)",
  // },
  // {
  //   municipio: "Benissanet",
  //   provincia: "Tarragona",
  //   id: "43026",
  //   label: "Benissanet (Tarragona)",
  // },
  // {
  //   municipio: "Solivella",
  //   provincia: "Tarragona",
  //   id: "43147",
  //   label: "Solivella (Tarragona)",
  // },
  // {
  //   municipio: "Banyeres del Penedès",
  //   provincia: "Tarragona",
  //   id: "43020",
  //   label: "Banyeres del Penedès (Tarragona)",
  // },
  // {
  //   municipio: "Tivenys",
  //   provincia: "Tarragona",
  //   id: "43149",
  //   label: "Tivenys (Tarragona)",
  // },
  // {
  //   municipio: "Torre de Fontaubella, La",
  //   provincia: "Tarragona",
  //   id: "43151",
  //   label: "Torre de Fontaubella, La (Tarragona)",
  // },
  // {
  //   municipio: "Torre de l'Espanyol, La",
  //   provincia: "Tarragona",
  //   id: "43152",
  //   label: "Torre de l'Espanyol, La (Tarragona)",
  // },
  // {
  //   municipio: "Bellmunt del Priorat",
  //   provincia: "Tarragona",
  //   id: "43023",
  //   label: "Bellmunt del Priorat (Tarragona)",
  // },
  // {
  //   municipio: "Bisbal de Falset, La",
  //   provincia: "Tarragona",
  //   id: "43027",
  //   label: "Bisbal de Falset, La (Tarragona)",
  // },
  // {
  //   municipio: "Bonastre",
  //   provincia: "Tarragona",
  //   id: "43030",
  //   label: "Bonastre (Tarragona)",
  // },
  // {
  //   municipio: "Borges del Camp, Les",
  //   provincia: "Tarragona",
  //   id: "43031",
  //   label: "Borges del Camp, Les (Tarragona)",
  // },
  // {
  //   municipio: "Bisbal del Penedès, La",
  //   provincia: "Tarragona",
  //   id: "43028",
  //   label: "Bisbal del Penedès, La (Tarragona)",
  // },
  // {
  //   municipio: "Blancafort",
  //   provincia: "Tarragona",
  //   id: "43029",
  //   label: "Blancafort (Tarragona)",
  // },
  // {
  //   municipio: "Bot",
  //   provincia: "Tarragona",
  //   id: "43032",
  //   label: "Bot (Tarragona)",
  // },
  // {
  //   municipio: "Botarell",
  //   provincia: "Tarragona",
  //   id: "43033",
  //   label: "Botarell (Tarragona)",
  // },
  // {
  //   municipio: "Bràfim",
  //   provincia: "Tarragona",
  //   id: "43034",
  //   label: "Bràfim (Tarragona)",
  // },
  // {
  //   municipio: "Cabacés",
  //   provincia: "Tarragona",
  //   id: "43035",
  //   label: "Cabacés (Tarragona)",
  // },
  // {
  //   municipio: "Vinyols i els Arcs",
  //   provincia: "Tarragona",
  //   id: "43178",
  //   label: "Vinyols i els Arcs (Tarragona)",
  // },
  // {
  //   municipio: "Cabra del Camp",
  //   provincia: "Tarragona",
  //   id: "43036",
  //   label: "Cabra del Camp (Tarragona)",
  // },
  // {
  //   municipio: "Cambrils",
  //   provincia: "Tarragona",
  //   id: "43038",
  //   label: "Cambrils (Tarragona)",
  // },
  // {
  //   municipio: "Creixell",
  //   provincia: "Tarragona",
  //   id: "43050",
  //   label: "Creixell (Tarragona)",
  // },
  // {
  //   municipio: "Xerta",
  //   provincia: "Tarragona",
  //   id: "43052",
  //   label: "Xerta (Tarragona)",
  // },
  // {
  //   municipio: "Capafonts",
  //   provincia: "Tarragona",
  //   id: "43039",
  //   label: "Capafonts (Tarragona)",
  // },
  // {
  //   municipio: "Caseres",
  //   provincia: "Tarragona",
  //   id: "43041",
  //   label: "Caseres (Tarragona)",
  // },
  // {
  //   municipio: "Catllar, El",
  //   provincia: "Tarragona",
  //   id: "43043",
  //   label: "Catllar, El (Tarragona)",
  // },
  // {
  //   municipio: "Conesa",
  //   provincia: "Tarragona",
  //   id: "43046",
  //   label: "Conesa (Tarragona)",
  // },
  // {
  //   municipio: "Constantí",
  //   provincia: "Tarragona",
  //   id: "43047",
  //   label: "Constantí (Tarragona)",
  // },
  // {
  //   municipio: "Duesaigües",
  //   provincia: "Tarragona",
  //   id: "43053",
  //   label: "Duesaigües (Tarragona)",
  // },
  // {
  //   municipio: "Forès",
  //   provincia: "Tarragona",
  //   id: "43061",
  //   label: "Forès (Tarragona)",
  // },
  // {
  //   municipio: "Espluga de Francolí, L'",
  //   provincia: "Tarragona",
  //   id: "43054",
  //   label: "Espluga de Francolí, L' (Tarragona)",
  // },
  // {
  //   municipio: "Falset",
  //   provincia: "Tarragona",
  //   id: "43055",
  //   label: "Falset (Tarragona)",
  // },
  // {
  //   municipio: "Febró, La",
  //   provincia: "Tarragona",
  //   id: "43057",
  //   label: "Febró, La (Tarragona)",
  // },
  // {
  //   municipio: "Figuera, La",
  //   provincia: "Tarragona",
  //   id: "43058",
  //   label: "Figuera, La (Tarragona)",
  // },
  // {
  //   municipio: "Figuerola del Camp",
  //   provincia: "Tarragona",
  //   id: "43059",
  //   label: "Figuerola del Camp (Tarragona)",
  // },
  // {
  //   municipio: "Freginals",
  //   provincia: "Tarragona",
  //   id: "43062",
  //   label: "Freginals (Tarragona)",
  // },
  // {
  //   municipio: "Galera, La",
  //   provincia: "Tarragona",
  //   id: "43063",
  //   label: "Galera, La (Tarragona)",
  // },
  // {
  //   municipio: "Gandesa",
  //   provincia: "Tarragona",
  //   id: "43064",
  //   label: "Gandesa (Tarragona)",
  // },
  // {
  //   municipio: "Garidells, Els",
  //   provincia: "Tarragona",
  //   id: "43066",
  //   label: "Garidells, Els (Tarragona)",
  // },
  // {
  //   municipio: "Ginestar",
  //   provincia: "Tarragona",
  //   id: "43067",
  //   label: "Ginestar (Tarragona)",
  // },
  // {
  //   municipio: "Godall",
  //   provincia: "Tarragona",
  //   id: "43068",
  //   label: "Godall (Tarragona)",
  // },
  // {
  //   municipio: "Gratallops",
  //   provincia: "Tarragona",
  //   id: "43069",
  //   label: "Gratallops (Tarragona)",
  // },
  // {
  //   municipio: "Guiamets, Els",
  //   provincia: "Tarragona",
  //   id: "43070",
  //   label: "Guiamets, Els (Tarragona)",
  // },
  // {
  //   municipio: "Horta de Sant Joan",
  //   provincia: "Tarragona",
  //   id: "43071",
  //   label: "Horta de Sant Joan (Tarragona)",
  // },
  // {
  //   municipio: "Lloar, El",
  //   provincia: "Tarragona",
  //   id: "43072",
  //   label: "Lloar, El (Tarragona)",
  // },
  // {
  //   municipio: "Llorac",
  //   provincia: "Tarragona",
  //   id: "43073",
  //   label: "Llorac (Tarragona)",
  // },
  // {
  //   municipio: "Montbrió del Camp",
  //   provincia: "Tarragona",
  //   id: "43088",
  //   label: "Montbrió del Camp (Tarragona)",
  // },
  // {
  //   municipio: "Llorenç del Penedès",
  //   provincia: "Tarragona",
  //   id: "43074",
  //   label: "Llorenç del Penedès (Tarragona)",
  // },
  // {
  //   municipio: "Mas de Barberans",
  //   provincia: "Tarragona",
  //   id: "43077",
  //   label: "Mas de Barberans (Tarragona)",
  // },
  // {
  //   municipio: "Masdenverge",
  //   provincia: "Tarragona",
  //   id: "43078",
  //   label: "Masdenverge (Tarragona)",
  // },
  // {
  //   municipio: "Masllorenç",
  //   provincia: "Tarragona",
  //   id: "43079",
  //   label: "Masllorenç (Tarragona)",
  // },
  // {
  //   municipio: "Masó, La",
  //   provincia: "Tarragona",
  //   id: "43080",
  //   label: "Masó, La (Tarragona)",
  // },
  // {
  //   municipio: "Móra la Nova",
  //   provincia: "Tarragona",
  //   id: "43094",
  //   label: "Móra la Nova (Tarragona)",
  // },
  // {
  //   municipio: "Maspujols",
  //   provincia: "Tarragona",
  //   id: "43081",
  //   label: "Maspujols (Tarragona)",
  // },
  // {
  //   municipio: "Masroig, El",
  //   provincia: "Tarragona",
  //   id: "43082",
  //   label: "Masroig, El (Tarragona)",
  // },
  // {
  //   municipio: "Milà, El",
  //   provincia: "Tarragona",
  //   id: "43083",
  //   label: "Milà, El (Tarragona)",
  // },
  // {
  //   municipio: "Nou de Gaià, La",
  //   provincia: "Tarragona",
  //   id: "43097",
  //   label: "Nou de Gaià, La (Tarragona)",
  // },
  // {
  //   municipio: "Molar, El",
  //   provincia: "Tarragona",
  //   id: "43085",
  //   label: "Molar, El (Tarragona)",
  // },
  // {
  //   municipio: "Montblanc",
  //   provincia: "Tarragona",
  //   id: "43086",
  //   label: "Montblanc (Tarragona)",
  // },
  // {
  //   municipio: "Montferri",
  //   provincia: "Tarragona",
  //   id: "43089",
  //   label: "Montferri (Tarragona)",
  // },
  // {
  //   municipio: "Montmell, El",
  //   provincia: "Tarragona",
  //   id: "43090",
  //   label: "Montmell, El (Tarragona)",
  // },
  // {
  //   municipio: "Mont-ral",
  //   provincia: "Tarragona",
  //   id: "43091",
  //   label: "Mont-ral (Tarragona)",
  // },
  // {
  //   municipio: "Mont-roig del Camp",
  //   provincia: "Tarragona",
  //   id: "43092",
  //   label: "Mont-roig del Camp (Tarragona)",
  // },
  // {
  //   municipio: "Móra d'Ebre",
  //   provincia: "Tarragona",
  //   id: "43093",
  //   label: "Móra d'Ebre (Tarragona)",
  // },
  // {
  //   municipio: "Morell, El",
  //   provincia: "Tarragona",
  //   id: "43095",
  //   label: "Morell, El (Tarragona)",
  // },
  // {
  //   municipio: "Pira",
  //   provincia: "Tarragona",
  //   id: "43107",
  //   label: "Pira (Tarragona)",
  // },
  // {
  //   municipio: "Nulles",
  //   provincia: "Tarragona",
  //   id: "43098",
  //   label: "Nulles (Tarragona)",
  // },
  // {
  //   municipio: "Palma d'Ebre, La",
  //   provincia: "Tarragona",
  //   id: "43099",
  //   label: "Palma d'Ebre, La (Tarragona)",
  // },
  // {
  //   municipio: "Pallaresos, Els",
  //   provincia: "Tarragona",
  //   id: "43100",
  //   label: "Pallaresos, Els (Tarragona)",
  // },
  // {
  //   municipio: "Passanant i Belltall",
  //   provincia: "Tarragona",
  //   id: "43101",
  //   label: "Passanant i Belltall (Tarragona)",
  // },
  // {
  //   municipio: "Paüls",
  //   provincia: "Tarragona",
  //   id: "43102",
  //   label: "Paüls (Tarragona)",
  // },
  // {
  //   municipio: "Codo",
  //   provincia: "Zaragoza",
  //   id: "50085",
  //   label: "Codo (Zaragoza)",
  // },
  // {
  //   municipio: "Perafort",
  //   provincia: "Tarragona",
  //   id: "43103",
  //   label: "Perafort (Tarragona)",
  // },
  // {
  //   municipio: "Pobla de Mafumet, La",
  //   provincia: "Tarragona",
  //   id: "43109",
  //   label: "Pobla de Mafumet, La (Tarragona)",
  // },
  // {
  //   municipio: "Pobla de Massaluca, La",
  //   provincia: "Tarragona",
  //   id: "43110",
  //   label: "Pobla de Massaluca, La (Tarragona)",
  // },
  // {
  //   municipio: "Poboleda",
  //   provincia: "Tarragona",
  //   id: "43112",
  //   label: "Poboleda (Tarragona)",
  // },
  // {
  //   municipio: "Pobla de Montornès, La",
  //   provincia: "Tarragona",
  //   id: "43111",
  //   label: "Pobla de Montornès, La (Tarragona)",
  // },
  // {
  //   municipio: "Prades",
  //   provincia: "Tarragona",
  //   id: "43116",
  //   label: "Prades (Tarragona)",
  // },
  // {
  //   municipio: "Prat de Comte",
  //   provincia: "Tarragona",
  //   id: "43117",
  //   label: "Prat de Comte (Tarragona)",
  // },
  // {
  //   municipio: "Pratdip",
  //   provincia: "Tarragona",
  //   id: "43118",
  //   label: "Pratdip (Tarragona)",
  // },
  // {
  //   municipio: "San Martín del Río",
  //   provincia: "Teruel",
  //   id: "44207",
  //   label: "San Martín del Río (Teruel)",
  // },
  // {
  //   municipio: "Pont d'Armentera, El",
  //   provincia: "Tarragona",
  //   id: "43113",
  //   label: "Pont d'Armentera, El (Tarragona)",
  // },
  // {
  //   municipio: "Porrera",
  //   provincia: "Tarragona",
  //   id: "43114",
  //   label: "Porrera (Tarragona)",
  // },
  // {
  //   municipio: "Pradell de la Teixeta",
  //   provincia: "Tarragona",
  //   id: "43115",
  //   label: "Pradell de la Teixeta (Tarragona)",
  // },
  // {
  //   municipio: "Puigpelat",
  //   provincia: "Tarragona",
  //   id: "43119",
  //   label: "Puigpelat (Tarragona)",
  // },
  // {
  //   municipio: "Rasquera",
  //   provincia: "Tarragona",
  //   id: "43121",
  //   label: "Rasquera (Tarragona)",
  // },
  // {
  //   municipio: "Renau",
  //   provincia: "Tarragona",
  //   id: "43122",
  //   label: "Renau (Tarragona)",
  // },
  // {
  //   municipio: "Roquetes",
  //   provincia: "Tarragona",
  //   id: "43133",
  //   label: "Roquetes (Tarragona)",
  // },
  // {
  //   municipio: "Riba, La",
  //   provincia: "Tarragona",
  //   id: "43124",
  //   label: "Riba, La (Tarragona)",
  // },
  // {
  //   municipio: "Canonja, La",
  //   provincia: "Tarragona",
  //   id: "43907",
  //   label: "Canonja, La (Tarragona)",
  // },
  // {
  //   municipio: "Riera de Gaià, La",
  //   provincia: "Tarragona",
  //   id: "43126",
  //   label: "Riera de Gaià, La (Tarragona)",
  // },
  // {
  //   municipio: "Rourell, El",
  //   provincia: "Tarragona",
  //   id: "43134",
  //   label: "Rourell, El (Tarragona)",
  // },
  // {
  //   municipio: "Riudecanyes",
  //   provincia: "Tarragona",
  //   id: "43127",
  //   label: "Riudecanyes (Tarragona)",
  // },
  // {
  //   municipio: "Pontils",
  //   provincia: "Tarragona",
  //   id: "43141",
  //   label: "Pontils (Tarragona)",
  // },
  // {
  //   municipio: "Sarral",
  //   provincia: "Tarragona",
  //   id: "43142",
  //   label: "Sarral (Tarragona)",
  // },
  // {
  //   municipio: "Riudecols",
  //   provincia: "Tarragona",
  //   id: "43128",
  //   label: "Riudecols (Tarragona)",
  // },
  // {
  //   municipio: "Riudoms",
  //   provincia: "Tarragona",
  //   id: "43129",
  //   label: "Riudoms (Tarragona)",
  // },
  // {
  //   municipio: "Rocafort de Queralt",
  //   provincia: "Tarragona",
  //   id: "43130",
  //   label: "Rocafort de Queralt (Tarragona)",
  // },
  // {
  //   municipio: "Senan",
  //   provincia: "Tarragona",
  //   id: "43146",
  //   label: "Senan (Tarragona)",
  // },
  // {
  //   municipio: "Roda de Berà",
  //   provincia: "Tarragona",
  //   id: "43131",
  //   label: "Roda de Berà (Tarragona)",
  // },
  // {
  //   municipio: "Rodonyà",
  //   provincia: "Tarragona",
  //   id: "43132",
  //   label: "Rodonyà (Tarragona)",
  // },
  // {
  //   municipio: "Salomó",
  //   provincia: "Tarragona",
  //   id: "43135",
  //   label: "Salomó (Tarragona)",
  // },
  // {
  //   municipio: "Sant Jaume dels Domenys",
  //   provincia: "Tarragona",
  //   id: "43137",
  //   label: "Sant Jaume dels Domenys (Tarragona)",
  // },
  // {
  //   municipio: "Santa Bàrbara",
  //   provincia: "Tarragona",
  //   id: "43138",
  //   label: "Santa Bàrbara (Tarragona)",
  // },
  // {
  //   municipio: "Santa Coloma de Queralt",
  //   provincia: "Tarragona",
  //   id: "43139",
  //   label: "Santa Coloma de Queralt (Tarragona)",
  // },
  // {
  //   municipio: "Santa Oliva",
  //   provincia: "Tarragona",
  //   id: "43140",
  //   label: "Santa Oliva (Tarragona)",
  // },
  // {
  //   municipio: "Savallà del Comtat",
  //   provincia: "Tarragona",
  //   id: "43143",
  //   label: "Savallà del Comtat (Tarragona)",
  // },
  // {
  //   municipio: "Secuita, La",
  //   provincia: "Tarragona",
  //   id: "43144",
  //   label: "Secuita, La (Tarragona)",
  // },
  // {
  //   municipio: "Torredembarra",
  //   provincia: "Tarragona",
  //   id: "43153",
  //   label: "Torredembarra (Tarragona)",
  // },
  // {
  //   municipio: "Torroja del Priorat",
  //   provincia: "Tarragona",
  //   id: "43154",
  //   label: "Torroja del Priorat (Tarragona)",
  // },
  // {
  //   municipio: "Ulldecona",
  //   provincia: "Tarragona",
  //   id: "43156",
  //   label: "Ulldecona (Tarragona)",
  // },
  // {
  //   municipio: "Vallfogona de Riucorb",
  //   provincia: "Tarragona",
  //   id: "43159",
  //   label: "Vallfogona de Riucorb (Tarragona)",
  // },
  // {
  //   municipio: "Vallmoll",
  //   provincia: "Tarragona",
  //   id: "43160",
  //   label: "Vallmoll (Tarragona)",
  // },
  // {
  //   municipio: "Vendrell, El",
  //   provincia: "Tarragona",
  //   id: "43163",
  //   label: "Vendrell, El (Tarragona)",
  // },
  // {
  //   municipio: "Vespella de Gaià",
  //   provincia: "Tarragona",
  //   id: "43164",
  //   label: "Vespella de Gaià (Tarragona)",
  // },
  // {
  //   municipio: "Vilabella",
  //   provincia: "Tarragona",
  //   id: "43165",
  //   label: "Vilabella (Tarragona)",
  // },
  // {
  //   municipio: "Vilallonga del Camp",
  //   provincia: "Tarragona",
  //   id: "43166",
  //   label: "Vilallonga del Camp (Tarragona)",
  // },
  // {
  //   municipio: "Cañada Vellida",
  //   provincia: "Teruel",
  //   id: "44062",
  //   label: "Cañada Vellida (Teruel)",
  // },
  // {
  //   municipio: "Vilanova d'Escornalbou",
  //   provincia: "Tarragona",
  //   id: "43167",
  //   label: "Vilanova d'Escornalbou (Tarragona)",
  // },
  // {
  //   municipio: "Vilaplana",
  //   provincia: "Tarragona",
  //   id: "43169",
  //   label: "Vilaplana (Tarragona)",
  // },
  // {
  //   municipio: "Vila-rodona",
  //   provincia: "Tarragona",
  //   id: "43170",
  //   label: "Vila-rodona (Tarragona)",
  // },
  // {
  //   municipio: "Vila-seca",
  //   provincia: "Tarragona",
  //   id: "43171",
  //   label: "Vila-seca (Tarragona)",
  // },
  // {
  //   municipio: "Castejón de Tornos",
  //   provincia: "Teruel",
  //   id: "44065",
  //   label: "Castejón de Tornos (Teruel)",
  // },
  // {
  //   municipio: "Vilaverd",
  //   provincia: "Tarragona",
  //   id: "43172",
  //   label: "Vilaverd (Tarragona)",
  // },
  // {
  //   municipio: "Vilella Alta, La",
  //   provincia: "Tarragona",
  //   id: "43173",
  //   label: "Vilella Alta, La (Tarragona)",
  // },
  // {
  //   municipio: "Santa Cruz de Nogueras",
  //   provincia: "Teruel",
  //   id: "44208",
  //   label: "Santa Cruz de Nogueras (Teruel)",
  // },
  // {
  //   municipio: "Vilella Baixa, La",
  //   provincia: "Tarragona",
  //   id: "43174",
  //   label: "Vilella Baixa, La (Tarragona)",
  // },
  // {
  //   municipio: "Vilalba dels Arcs",
  //   provincia: "Tarragona",
  //   id: "43175",
  //   label: "Vilalba dels Arcs (Tarragona)",
  // },
  // {
  //   municipio: "Vimbodí i Poblet",
  //   provincia: "Tarragona",
  //   id: "43176",
  //   label: "Vimbodí i Poblet (Tarragona)",
  // },
  // {
  //   municipio: "Sant Jaume d'Enveja",
  //   provincia: "Tarragona",
  //   id: "43902",
  //   label: "Sant Jaume d'Enveja (Tarragona)",
  // },
  // {
  //   municipio: "Blancas",
  //   provincia: "Teruel",
  //   id: "44042",
  //   label: "Blancas (Teruel)",
  // },
  // {
  //   municipio: "Camarles",
  //   provincia: "Tarragona",
  //   id: "43903",
  //   label: "Camarles (Tarragona)",
  // },
  // {
  //   municipio: "Ababuj",
  //   provincia: "Teruel",
  //   id: "44001",
  //   label: "Ababuj (Teruel)",
  // },
  // {
  //   municipio: "Aldea, L'",
  //   provincia: "Tarragona",
  //   id: "43904",
  //   label: "Aldea, L' (Tarragona)",
  // },
  // {
  //   municipio: "Alacón",
  //   provincia: "Teruel",
  //   id: "44006",
  //   label: "Alacón (Teruel)",
  // },
  // {
  //   municipio: "Abejuela",
  //   provincia: "Teruel",
  //   id: "44002",
  //   label: "Abejuela (Teruel)",
  // },
  // {
  //   municipio: "Aguatón",
  //   provincia: "Teruel",
  //   id: "44003",
  //   label: "Aguatón (Teruel)",
  // },
  // {
  //   municipio: "Aguaviva",
  //   provincia: "Teruel",
  //   id: "44004",
  //   label: "Aguaviva (Teruel)",
  // },
  // {
  //   municipio: "Aguilar del Alfambra",
  //   provincia: "Teruel",
  //   id: "44005",
  //   label: "Aguilar del Alfambra (Teruel)",
  // },
  // {
  //   municipio: "Alba",
  //   provincia: "Teruel",
  //   id: "44007",
  //   label: "Alba (Teruel)",
  // },
  // {
  //   municipio: "Albalate del Arzobispo",
  //   provincia: "Teruel",
  //   id: "44008",
  //   label: "Albalate del Arzobispo (Teruel)",
  // },
  // {
  //   municipio: "Albentosa",
  //   provincia: "Teruel",
  //   id: "44010",
  //   label: "Albentosa (Teruel)",
  // },
  // {
  //   municipio: "Gátova",
  //   provincia: "Valencia",
  //   id: "46902",
  //   label: "Gátova (Valencia)",
  // },
  // {
  //   municipio: "Alcaine",
  //   provincia: "Teruel",
  //   id: "44011",
  //   label: "Alcaine (Teruel)",
  // },
  // {
  //   municipio: "Alobras",
  //   provincia: "Teruel",
  //   id: "44019",
  //   label: "Alobras (Teruel)",
  // },
  // {
  //   municipio: "Alcalá de la Selva",
  //   provincia: "Teruel",
  //   id: "44012",
  //   label: "Alcalá de la Selva (Teruel)",
  // },
  // {
  //   municipio: "Cuervo, El",
  //   provincia: "Teruel",
  //   id: "44092",
  //   label: "Cuervo, El (Teruel)",
  // },
  // {
  //   municipio: "Alcañiz",
  //   provincia: "Teruel",
  //   id: "44013",
  //   label: "Alcañiz (Teruel)",
  // },
  // {
  //   municipio: "Alcorisa",
  //   provincia: "Teruel",
  //   id: "44014",
  //   label: "Alcorisa (Teruel)",
  // },
  // {
  //   municipio: "Alpeñés",
  //   provincia: "Teruel",
  //   id: "44020",
  //   label: "Alpeñés (Teruel)",
  // },
  // {
  //   municipio: "Alfambra",
  //   provincia: "Teruel",
  //   id: "44016",
  //   label: "Alfambra (Teruel)",
  // },
  // {
  //   municipio: "Bocos de Duero",
  //   provincia: "Valladolid",
  //   id: "47022",
  //   label: "Bocos de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Aliaga",
  //   provincia: "Teruel",
  //   id: "44017",
  //   label: "Aliaga (Teruel)",
  // },
  // {
  //   municipio: "Allepuz",
  //   provincia: "Teruel",
  //   id: "44021",
  //   label: "Allepuz (Teruel)",
  // },
  // {
  //   municipio: "Alloza",
  //   provincia: "Teruel",
  //   id: "44022",
  //   label: "Alloza (Teruel)",
  // },
  // {
  //   municipio: "Allueva",
  //   provincia: "Teruel",
  //   id: "44023",
  //   label: "Allueva (Teruel)",
  // },
  // {
  //   municipio: "Anadón",
  //   provincia: "Teruel",
  //   id: "44024",
  //   label: "Anadón (Teruel)",
  // },
  // {
  //   municipio: "Andorra",
  //   provincia: "Teruel",
  //   id: "44025",
  //   label: "Andorra (Teruel)",
  // },
  // {
  //   municipio: "Arcos de las Salinas",
  //   provincia: "Teruel",
  //   id: "44026",
  //   label: "Arcos de las Salinas (Teruel)",
  // },
  // {
  //   municipio: "Arens de Lledó",
  //   provincia: "Teruel",
  //   id: "44027",
  //   label: "Arens de Lledó (Teruel)",
  // },
  // {
  //   municipio: "Azaila",
  //   provincia: "Teruel",
  //   id: "44031",
  //   label: "Azaila (Teruel)",
  // },
  // {
  //   municipio: "Argente",
  //   provincia: "Teruel",
  //   id: "44028",
  //   label: "Argente (Teruel)",
  // },
  // {
  //   municipio: "Ariño",
  //   provincia: "Teruel",
  //   id: "44029",
  //   label: "Ariño (Teruel)",
  // },
  // {
  //   municipio: "Burbáguena",
  //   provincia: "Teruel",
  //   id: "44047",
  //   label: "Burbáguena (Teruel)",
  // },
  // {
  //   municipio: "Cabra de Mora",
  //   provincia: "Teruel",
  //   id: "44048",
  //   label: "Cabra de Mora (Teruel)",
  // },
  // {
  //   municipio: "Bádenas",
  //   provincia: "Teruel",
  //   id: "44032",
  //   label: "Bádenas (Teruel)",
  // },
  // {
  //   municipio: "Calaceite",
  //   provincia: "Teruel",
  //   id: "44049",
  //   label: "Calaceite (Teruel)",
  // },
  // {
  //   municipio: "Báguena",
  //   provincia: "Teruel",
  //   id: "44033",
  //   label: "Báguena (Teruel)",
  // },
  // {
  //   municipio: "Bañón",
  //   provincia: "Teruel",
  //   id: "44034",
  //   label: "Bañón (Teruel)",
  // },
  // {
  //   municipio: "Belmonte de San José",
  //   provincia: "Teruel",
  //   id: "44038",
  //   label: "Belmonte de San José (Teruel)",
  // },
  // {
  //   municipio: "Bello",
  //   provincia: "Teruel",
  //   id: "44039",
  //   label: "Bello (Teruel)",
  // },
  // {
  //   municipio: "Berge",
  //   provincia: "Teruel",
  //   id: "44040",
  //   label: "Berge (Teruel)",
  // },
  // {
  //   municipio: "Bezas",
  //   provincia: "Teruel",
  //   id: "44041",
  //   label: "Bezas (Teruel)",
  // },
  // {
  //   municipio: "Blesa",
  //   provincia: "Teruel",
  //   id: "44043",
  //   label: "Blesa (Teruel)",
  // },
  // {
  //   municipio: "Bronchales",
  //   provincia: "Teruel",
  //   id: "44045",
  //   label: "Bronchales (Teruel)",
  // },
  // {
  //   municipio: "Bordón",
  //   provincia: "Teruel",
  //   id: "44044",
  //   label: "Bordón (Teruel)",
  // },
  // {
  //   municipio: "Bueña",
  //   provincia: "Teruel",
  //   id: "44046",
  //   label: "Bueña (Teruel)",
  // },
  // {
  //   municipio: "Calamocha",
  //   provincia: "Teruel",
  //   id: "44050",
  //   label: "Calamocha (Teruel)",
  // },
  // {
  //   municipio: "Calanda",
  //   provincia: "Teruel",
  //   id: "44051",
  //   label: "Calanda (Teruel)",
  // },
  // {
  //   municipio: "Calomarde",
  //   provincia: "Teruel",
  //   id: "44052",
  //   label: "Calomarde (Teruel)",
  // },
  // {
  //   municipio: "Camañas",
  //   provincia: "Teruel",
  //   id: "44053",
  //   label: "Camañas (Teruel)",
  // },
  // {
  //   municipio: "Cañada de Verich, La",
  //   provincia: "Teruel",
  //   id: "44061",
  //   label: "Cañada de Verich, La (Teruel)",
  // },
  // {
  //   municipio: "Camarena de la Sierra",
  //   provincia: "Teruel",
  //   id: "44054",
  //   label: "Camarena de la Sierra (Teruel)",
  // },
  // {
  //   municipio: "Camarillas",
  //   provincia: "Teruel",
  //   id: "44055",
  //   label: "Camarillas (Teruel)",
  // },
  // {
  //   municipio: "Caminreal",
  //   provincia: "Teruel",
  //   id: "44056",
  //   label: "Caminreal (Teruel)",
  // },
  // {
  //   municipio: "Cañada de Benatanduz",
  //   provincia: "Teruel",
  //   id: "44060",
  //   label: "Cañada de Benatanduz (Teruel)",
  // },
  // {
  //   municipio: "Cañizar del Olivar",
  //   provincia: "Teruel",
  //   id: "44063",
  //   label: "Cañizar del Olivar (Teruel)",
  // },
  // {
  //   municipio: "Cascante del Río",
  //   provincia: "Teruel",
  //   id: "44064",
  //   label: "Cascante del Río (Teruel)",
  // },
  // {
  //   municipio: "Castel de Cabra",
  //   provincia: "Teruel",
  //   id: "44066",
  //   label: "Castel de Cabra (Teruel)",
  // },
  // {
  //   municipio: "Castelserás",
  //   provincia: "Teruel",
  //   id: "44068",
  //   label: "Castelserás (Teruel)",
  // },
  // {
  //   municipio: "Castelnou",
  //   provincia: "Teruel",
  //   id: "44067",
  //   label: "Castelnou (Teruel)",
  // },
  // {
  //   municipio: "Torre los Negros",
  //   provincia: "Teruel",
  //   id: "44227",
  //   label: "Torre los Negros (Teruel)",
  // },
  // {
  //   municipio: "Castellar, El",
  //   provincia: "Teruel",
  //   id: "44070",
  //   label: "Castellar, El (Teruel)",
  // },
  // {
  //   municipio: "Castellote",
  //   provincia: "Teruel",
  //   id: "44071",
  //   label: "Castellote (Teruel)",
  // },
  // {
  //   municipio: "Cedrillas",
  //   provincia: "Teruel",
  //   id: "44074",
  //   label: "Cedrillas (Teruel)",
  // },
  // {
  //   municipio: "Celadas",
  //   provincia: "Teruel",
  //   id: "44075",
  //   label: "Celadas (Teruel)",
  // },
  // {
  //   municipio: "Maicas",
  //   provincia: "Teruel",
  //   id: "44142",
  //   label: "Maicas (Teruel)",
  // },
  // {
  //   municipio: "Cerollera, La",
  //   provincia: "Teruel",
  //   id: "44077",
  //   label: "Cerollera, La (Teruel)",
  // },
  // {
  //   municipio: "Codoñera, La",
  //   provincia: "Teruel",
  //   id: "44080",
  //   label: "Codoñera, La (Teruel)",
  // },
  // {
  //   municipio: "Mata de los Olmos, La",
  //   provincia: "Teruel",
  //   id: "44146",
  //   label: "Mata de los Olmos, La (Teruel)",
  // },
  // {
  //   municipio: "Corbalán",
  //   provincia: "Teruel",
  //   id: "44082",
  //   label: "Corbalán (Teruel)",
  // },
  // {
  //   municipio: "Cortes de Aragón",
  //   provincia: "Teruel",
  //   id: "44084",
  //   label: "Cortes de Aragón (Teruel)",
  // },
  // {
  //   municipio: "Cosa",
  //   provincia: "Teruel",
  //   id: "44085",
  //   label: "Cosa (Teruel)",
  // },
  // {
  //   municipio: "Crivillén",
  //   provincia: "Teruel",
  //   id: "44087",
  //   label: "Crivillén (Teruel)",
  // },
  // {
  //   municipio: "Cretas",
  //   provincia: "Teruel",
  //   id: "44086",
  //   label: "Cretas (Teruel)",
  // },
  // {
  //   municipio: "Cubla",
  //   provincia: "Teruel",
  //   id: "44089",
  //   label: "Cubla (Teruel)",
  // },
  // {
  //   municipio: "Cucalón",
  //   provincia: "Teruel",
  //   id: "44090",
  //   label: "Cucalón (Teruel)",
  // },
  // {
  //   municipio: "Cuevas de Almudén",
  //   provincia: "Teruel",
  //   id: "44093",
  //   label: "Cuevas de Almudén (Teruel)",
  // },
  // {
  //   municipio: "Ejulve",
  //   provincia: "Teruel",
  //   id: "44096",
  //   label: "Ejulve (Teruel)",
  // },
  // {
  //   municipio: "Cuevas Labradas",
  //   provincia: "Teruel",
  //   id: "44094",
  //   label: "Cuevas Labradas (Teruel)",
  // },
  // {
  //   municipio: "Fresneda, La",
  //   provincia: "Teruel",
  //   id: "44108",
  //   label: "Fresneda, La (Teruel)",
  // },
  // {
  //   municipio: "Escorihuela",
  //   provincia: "Teruel",
  //   id: "44097",
  //   label: "Escorihuela (Teruel)",
  // },
  // {
  //   municipio: "Escucha",
  //   provincia: "Teruel",
  //   id: "44099",
  //   label: "Escucha (Teruel)",
  // },
  // {
  //   municipio: "Estercuel",
  //   provincia: "Teruel",
  //   id: "44100",
  //   label: "Estercuel (Teruel)",
  // },
  // {
  //   municipio: "Ferreruela de Huerva",
  //   provincia: "Teruel",
  //   id: "44101",
  //   label: "Ferreruela de Huerva (Teruel)",
  // },
  // {
  //   municipio: "Fonfría",
  //   provincia: "Teruel",
  //   id: "44102",
  //   label: "Fonfría (Teruel)",
  // },
  // {
  //   municipio: "Formiche Alto",
  //   provincia: "Teruel",
  //   id: "44103",
  //   label: "Formiche Alto (Teruel)",
  // },
  // {
  //   municipio: "Fórnoles",
  //   provincia: "Teruel",
  //   id: "44105",
  //   label: "Fórnoles (Teruel)",
  // },
  // {
  //   municipio: "Torremocha de Jiloca",
  //   provincia: "Teruel",
  //   id: "44228",
  //   label: "Torremocha de Jiloca (Teruel)",
  // },
  // {
  //   municipio: "Fortanete",
  //   provincia: "Teruel",
  //   id: "44106",
  //   label: "Fortanete (Teruel)",
  // },
  // {
  //   municipio: "Foz-Calanda",
  //   provincia: "Teruel",
  //   id: "44107",
  //   label: "Foz-Calanda (Teruel)",
  // },
  // {
  //   municipio: "Frías de Albarracín",
  //   provincia: "Teruel",
  //   id: "44109",
  //   label: "Frías de Albarracín (Teruel)",
  // },
  // {
  //   municipio: "Fuenferrada",
  //   provincia: "Teruel",
  //   id: "44110",
  //   label: "Fuenferrada (Teruel)",
  // },
  // {
  //   municipio: "Fuentes Calientes",
  //   provincia: "Teruel",
  //   id: "44111",
  //   label: "Fuentes Calientes (Teruel)",
  // },
  // {
  //   municipio: "Fuentes Claras",
  //   provincia: "Teruel",
  //   id: "44112",
  //   label: "Fuentes Claras (Teruel)",
  // },
  // {
  //   municipio: "Gargallo",
  //   provincia: "Teruel",
  //   id: "44116",
  //   label: "Gargallo (Teruel)",
  // },
  // {
  //   municipio: "Fuentes de Rubielos",
  //   provincia: "Teruel",
  //   id: "44113",
  //   label: "Fuentes de Rubielos (Teruel)",
  // },
  // {
  //   municipio: "Hinojosa de Jarque",
  //   provincia: "Teruel",
  //   id: "44123",
  //   label: "Hinojosa de Jarque (Teruel)",
  // },
  // {
  //   municipio: "Fuentespalda",
  //   provincia: "Teruel",
  //   id: "44114",
  //   label: "Fuentespalda (Teruel)",
  // },
  // {
  //   municipio: "Galve",
  //   provincia: "Teruel",
  //   id: "44115",
  //   label: "Galve (Teruel)",
  // },
  // {
  //   municipio: "Gea de Albarracín",
  //   provincia: "Teruel",
  //   id: "44117",
  //   label: "Gea de Albarracín (Teruel)",
  // },
  // {
  //   municipio: "Hoz de la Vieja, La",
  //   provincia: "Teruel",
  //   id: "44124",
  //   label: "Hoz de la Vieja, La (Teruel)",
  // },
  // {
  //   municipio: "Ginebrosa, La",
  //   provincia: "Teruel",
  //   id: "44118",
  //   label: "Ginebrosa, La (Teruel)",
  // },
  // {
  //   municipio: "Bahabón",
  //   provincia: "Valladolid",
  //   id: "47012",
  //   label: "Bahabón (Valladolid)",
  // },
  // {
  //   municipio: "Griegos",
  //   provincia: "Teruel",
  //   id: "44119",
  //   label: "Griegos (Teruel)",
  // },
  // {
  //   municipio: "Jabaloyas",
  //   provincia: "Teruel",
  //   id: "44127",
  //   label: "Jabaloyas (Teruel)",
  // },
  // {
  //   municipio: "Guadalaviar",
  //   provincia: "Teruel",
  //   id: "44120",
  //   label: "Guadalaviar (Teruel)",
  // },
  // {
  //   municipio: "Jarque de la Val",
  //   provincia: "Teruel",
  //   id: "44128",
  //   label: "Jarque de la Val (Teruel)",
  // },
  // {
  //   municipio: "Gúdar",
  //   provincia: "Teruel",
  //   id: "44121",
  //   label: "Gúdar (Teruel)",
  // },
  // {
  //   municipio: "Jatiel",
  //   provincia: "Teruel",
  //   id: "44129",
  //   label: "Jatiel (Teruel)",
  // },
  // {
  //   municipio: "Híjar",
  //   provincia: "Teruel",
  //   id: "44122",
  //   label: "Híjar (Teruel)",
  // },
  // {
  //   municipio: "Huesa del Común",
  //   provincia: "Teruel",
  //   id: "44125",
  //   label: "Huesa del Común (Teruel)",
  // },
  // {
  //   municipio: "Iglesuela del Cid, La",
  //   provincia: "Teruel",
  //   id: "44126",
  //   label: "Iglesuela del Cid, La (Teruel)",
  // },
  // {
  //   municipio: "Jorcas",
  //   provincia: "Teruel",
  //   id: "44130",
  //   label: "Jorcas (Teruel)",
  // },
  // {
  //   municipio: "Josa",
  //   provincia: "Teruel",
  //   id: "44131",
  //   label: "Josa (Teruel)",
  // },
  // {
  //   municipio: "Lagueruela",
  //   provincia: "Teruel",
  //   id: "44132",
  //   label: "Lagueruela (Teruel)",
  // },
  // {
  //   municipio: "Monroyo",
  //   provincia: "Teruel",
  //   id: "44154",
  //   label: "Monroyo (Teruel)",
  // },
  // {
  //   municipio: "Lanzuela",
  //   provincia: "Teruel",
  //   id: "44133",
  //   label: "Lanzuela (Teruel)",
  // },
  // {
  //   municipio: "Libros",
  //   provincia: "Teruel",
  //   id: "44135",
  //   label: "Libros (Teruel)",
  // },
  // {
  //   municipio: "Torres de Albarracín",
  //   provincia: "Teruel",
  //   id: "44229",
  //   label: "Torres de Albarracín (Teruel)",
  // },
  // {
  //   municipio: "Lidón",
  //   provincia: "Teruel",
  //   id: "44136",
  //   label: "Lidón (Teruel)",
  // },
  // {
  //   municipio: "Linares de Mora",
  //   provincia: "Teruel",
  //   id: "44137",
  //   label: "Linares de Mora (Teruel)",
  // },
  // {
  //   municipio: "Loscos",
  //   provincia: "Teruel",
  //   id: "44138",
  //   label: "Loscos (Teruel)",
  // },
  // {
  //   municipio: "Lledó",
  //   provincia: "Teruel",
  //   id: "44141",
  //   label: "Lledó (Teruel)",
  // },
  // {
  //   municipio: "Manzanera",
  //   provincia: "Teruel",
  //   id: "44143",
  //   label: "Manzanera (Teruel)",
  // },
  // {
  //   municipio: "Mazaleón",
  //   provincia: "Teruel",
  //   id: "44147",
  //   label: "Mazaleón (Teruel)",
  // },
  // {
  //   municipio: "Martín del Río",
  //   provincia: "Teruel",
  //   id: "44144",
  //   label: "Martín del Río (Teruel)",
  // },
  // {
  //   municipio: "Mas de las Matas",
  //   provincia: "Teruel",
  //   id: "44145",
  //   label: "Mas de las Matas (Teruel)",
  // },
  // {
  //   municipio: "Mezquita de Jarque",
  //   provincia: "Teruel",
  //   id: "44148",
  //   label: "Mezquita de Jarque (Teruel)",
  // },
  // {
  //   municipio: "Mirambel",
  //   provincia: "Teruel",
  //   id: "44149",
  //   label: "Mirambel (Teruel)",
  // },
  // {
  //   municipio: "Miravete de la Sierra",
  //   provincia: "Teruel",
  //   id: "44150",
  //   label: "Miravete de la Sierra (Teruel)",
  // },
  // {
  //   municipio: "Molinos",
  //   provincia: "Teruel",
  //   id: "44151",
  //   label: "Molinos (Teruel)",
  // },
  // {
  //   municipio: "Orihuela del Tremedal",
  //   provincia: "Teruel",
  //   id: "44174",
  //   label: "Orihuela del Tremedal (Teruel)",
  // },
  // {
  //   municipio: "Monforte de Moyuela",
  //   provincia: "Teruel",
  //   id: "44152",
  //   label: "Monforte de Moyuela (Teruel)",
  // },
  // {
  //   municipio: "Monreal del Campo",
  //   provincia: "Teruel",
  //   id: "44153",
  //   label: "Monreal del Campo (Teruel)",
  // },
  // {
  //   municipio: "Montalbán",
  //   provincia: "Teruel",
  //   id: "44155",
  //   label: "Montalbán (Teruel)",
  // },
  // {
  //   municipio: "Monteagudo del Castillo",
  //   provincia: "Teruel",
  //   id: "44156",
  //   label: "Monteagudo del Castillo (Teruel)",
  // },
  // {
  //   municipio: "Monterde de Albarracín",
  //   provincia: "Teruel",
  //   id: "44157",
  //   label: "Monterde de Albarracín (Teruel)",
  // },
  // {
  //   municipio: "Moscardón",
  //   provincia: "Teruel",
  //   id: "44159",
  //   label: "Moscardón (Teruel)",
  // },
  // {
  //   municipio: "Mora de Rubielos",
  //   provincia: "Teruel",
  //   id: "44158",
  //   label: "Mora de Rubielos (Teruel)",
  // },
  // {
  //   municipio: "Muniesa",
  //   provincia: "Teruel",
  //   id: "44161",
  //   label: "Muniesa (Teruel)",
  // },
  // {
  //   municipio: "Noguera de Albarracín",
  //   provincia: "Teruel",
  //   id: "44163",
  //   label: "Noguera de Albarracín (Teruel)",
  // },
  // {
  //   municipio: "Nogueras",
  //   provincia: "Teruel",
  //   id: "44164",
  //   label: "Nogueras (Teruel)",
  // },
  // {
  //   municipio: "Odón",
  //   provincia: "Teruel",
  //   id: "44168",
  //   label: "Odón (Teruel)",
  // },
  // {
  //   municipio: "Nogueruelas",
  //   provincia: "Teruel",
  //   id: "44165",
  //   label: "Nogueruelas (Teruel)",
  // },
  // {
  //   municipio: "Obón",
  //   provincia: "Teruel",
  //   id: "44167",
  //   label: "Obón (Teruel)",
  // },
  // {
  //   municipio: "Carrícola",
  //   provincia: "Valencia",
  //   id: "46086",
  //   label: "Carrícola (Valencia)",
  // },
  // {
  //   municipio: "Ojos Negros",
  //   provincia: "Teruel",
  //   id: "44169",
  //   label: "Ojos Negros (Teruel)",
  // },
  // {
  //   municipio: "Olba",
  //   provincia: "Teruel",
  //   id: "44171",
  //   label: "Olba (Teruel)",
  // },
  // {
  //   municipio: "Olmos, Los",
  //   provincia: "Teruel",
  //   id: "44173",
  //   label: "Olmos, Los (Teruel)",
  // },
  // {
  //   municipio: "Oliete",
  //   provincia: "Teruel",
  //   id: "44172",
  //   label: "Oliete (Teruel)",
  // },
  // {
  //   municipio: "Catadau",
  //   provincia: "Valencia",
  //   id: "46093",
  //   label: "Catadau (Valencia)",
  // },
  // {
  //   municipio: "Orrios",
  //   provincia: "Teruel",
  //   id: "44175",
  //   label: "Orrios (Teruel)",
  // },
  // {
  //   municipio: "Palomar de Arroyos",
  //   provincia: "Teruel",
  //   id: "44176",
  //   label: "Palomar de Arroyos (Teruel)",
  // },
  // {
  //   municipio: "Pancrudo",
  //   provincia: "Teruel",
  //   id: "44177",
  //   label: "Pancrudo (Teruel)",
  // },
  // {
  //   municipio: "Chelva",
  //   provincia: "Valencia",
  //   id: "46106",
  //   label: "Chelva (Valencia)",
  // },
  // {
  //   municipio: "Parras de Castellote, Las",
  //   provincia: "Teruel",
  //   id: "44178",
  //   label: "Parras de Castellote, Las (Teruel)",
  // },
  // {
  //   municipio: "Peracense",
  //   provincia: "Teruel",
  //   id: "44180",
  //   label: "Peracense (Teruel)",
  // },
  // {
  //   municipio: "Chella",
  //   provincia: "Valencia",
  //   id: "46107",
  //   label: "Chella (Valencia)",
  // },
  // {
  //   municipio: "Peñarroya de Tastavins",
  //   provincia: "Teruel",
  //   id: "44179",
  //   label: "Peñarroya de Tastavins (Teruel)",
  // },
  // {
  //   municipio: "Plou",
  //   provincia: "Teruel",
  //   id: "44184",
  //   label: "Plou (Teruel)",
  // },
  // {
  //   municipio: "Peralejos",
  //   provincia: "Teruel",
  //   id: "44181",
  //   label: "Peralejos (Teruel)",
  // },
  // {
  //   municipio: "Perales del Alfambra",
  //   provincia: "Teruel",
  //   id: "44182",
  //   label: "Perales del Alfambra (Teruel)",
  // },
  // {
  //   municipio: "Pitarque",
  //   provincia: "Teruel",
  //   id: "44183",
  //   label: "Pitarque (Teruel)",
  // },
  // {
  //   municipio: "Pobo, El",
  //   provincia: "Teruel",
  //   id: "44185",
  //   label: "Pobo, El (Teruel)",
  // },
  // {
  //   municipio: "Portellada, La",
  //   provincia: "Teruel",
  //   id: "44187",
  //   label: "Portellada, La (Teruel)",
  // },
  // {
  //   municipio: "Saldón",
  //   provincia: "Teruel",
  //   id: "44204",
  //   label: "Saldón (Teruel)",
  // },
  // {
  //   municipio: "Pozondón",
  //   provincia: "Teruel",
  //   id: "44189",
  //   label: "Pozondón (Teruel)",
  // },
  // {
  //   municipio: "Pozuel del Campo",
  //   provincia: "Teruel",
  //   id: "44190",
  //   label: "Pozuel del Campo (Teruel)",
  // },
  // {
  //   municipio: "Cuelgamures",
  //   provincia: "Zamora",
  //   id: "49059",
  //   label: "Cuelgamures (Zamora)",
  // },
  // {
  //   municipio: "Puebla de Híjar, La",
  //   provincia: "Teruel",
  //   id: "44191",
  //   label: "Puebla de Híjar, La (Teruel)",
  // },
  // {
  //   municipio: "Puebla de Valverde, La",
  //   provincia: "Teruel",
  //   id: "44192",
  //   label: "Puebla de Valverde, La (Teruel)",
  // },
  // {
  //   municipio: "Puertomingalvo",
  //   provincia: "Teruel",
  //   id: "44193",
  //   label: "Puertomingalvo (Teruel)",
  // },
  // {
  //   municipio: "Torralba de los Sisones",
  //   provincia: "Teruel",
  //   id: "44220",
  //   label: "Torralba de los Sisones (Teruel)",
  // },
  // {
  //   municipio: "Ráfales",
  //   provincia: "Teruel",
  //   id: "44194",
  //   label: "Ráfales (Teruel)",
  // },
  // {
  //   municipio: "Rillo",
  //   provincia: "Teruel",
  //   id: "44195",
  //   label: "Rillo (Teruel)",
  // },
  // {
  //   municipio: "Riodeva",
  //   provincia: "Teruel",
  //   id: "44196",
  //   label: "Riodeva (Teruel)",
  // },
  // {
  //   municipio: "Ródenas",
  //   provincia: "Teruel",
  //   id: "44197",
  //   label: "Ródenas (Teruel)",
  // },
  // {
  //   municipio: "Royuela",
  //   provincia: "Teruel",
  //   id: "44198",
  //   label: "Royuela (Teruel)",
  // },
  // {
  //   municipio: "Rubiales",
  //   provincia: "Teruel",
  //   id: "44199",
  //   label: "Rubiales (Teruel)",
  // },
  // {
  //   municipio: "Rubielos de la Cérida",
  //   provincia: "Teruel",
  //   id: "44200",
  //   label: "Rubielos de la Cérida (Teruel)",
  // },
  // {
  //   municipio: "Samper de Calanda",
  //   provincia: "Teruel",
  //   id: "44205",
  //   label: "Samper de Calanda (Teruel)",
  // },
  // {
  //   municipio: "Rubielos de Mora",
  //   provincia: "Teruel",
  //   id: "44201",
  //   label: "Rubielos de Mora (Teruel)",
  // },
  // {
  //   municipio: "Salcedillo",
  //   provincia: "Teruel",
  //   id: "44203",
  //   label: "Salcedillo (Teruel)",
  // },
  // {
  //   municipio: "San Agustín",
  //   provincia: "Teruel",
  //   id: "44206",
  //   label: "San Agustín (Teruel)",
  // },
  // {
  //   municipio: "Santa Eulalia",
  //   provincia: "Teruel",
  //   id: "44209",
  //   label: "Santa Eulalia (Teruel)",
  // },
  // {
  //   municipio: "Torrecilla de Alcañiz",
  //   provincia: "Teruel",
  //   id: "44221",
  //   label: "Torrecilla de Alcañiz (Teruel)",
  // },
  // {
  //   municipio: "Sarrión",
  //   provincia: "Teruel",
  //   id: "44210",
  //   label: "Sarrión (Teruel)",
  // },
  // {
  //   municipio: "Tormón",
  //   provincia: "Teruel",
  //   id: "44218",
  //   label: "Tormón (Teruel)",
  // },
  // {
  //   municipio: "Segura de los Baños",
  //   provincia: "Teruel",
  //   id: "44211",
  //   label: "Segura de los Baños (Teruel)",
  // },
  // {
  //   municipio: "Seno",
  //   provincia: "Teruel",
  //   id: "44212",
  //   label: "Seno (Teruel)",
  // },
  // {
  //   municipio: "Singra",
  //   provincia: "Teruel",
  //   id: "44213",
  //   label: "Singra (Teruel)",
  // },
  // {
  //   municipio: "Terriente",
  //   provincia: "Teruel",
  //   id: "44215",
  //   label: "Terriente (Teruel)",
  // },
  // {
  //   municipio: "Toril y Masegoso",
  //   provincia: "Teruel",
  //   id: "44217",
  //   label: "Toril y Masegoso (Teruel)",
  // },
  // {
  //   municipio: "Tornos",
  //   provincia: "Teruel",
  //   id: "44219",
  //   label: "Tornos (Teruel)",
  // },
  // {
  //   municipio: "Torrecilla del Rebollar",
  //   provincia: "Teruel",
  //   id: "44222",
  //   label: "Torrecilla del Rebollar (Teruel)",
  // },
  // {
  //   municipio: "Torre de Arcas",
  //   provincia: "Teruel",
  //   id: "44223",
  //   label: "Torre de Arcas (Teruel)",
  // },
  // {
  //   municipio: "Entrala",
  //   provincia: "Zamora",
  //   id: "49061",
  //   label: "Entrala (Zamora)",
  // },
  // {
  //   municipio: "Torre de las Arcas",
  //   provincia: "Teruel",
  //   id: "44224",
  //   label: "Torre de las Arcas (Teruel)",
  // },
  // {
  //   municipio: "Torre del Compte",
  //   provincia: "Teruel",
  //   id: "44225",
  //   label: "Torre del Compte (Teruel)",
  // },
  // {
  //   municipio: "Torrelacárcel",
  //   provincia: "Teruel",
  //   id: "44226",
  //   label: "Torrelacárcel (Teruel)",
  // },
  // {
  //   municipio: "Torrevelilla",
  //   provincia: "Teruel",
  //   id: "44230",
  //   label: "Torrevelilla (Teruel)",
  // },
  // {
  //   municipio: "Tronchón",
  //   provincia: "Teruel",
  //   id: "44236",
  //   label: "Tronchón (Teruel)",
  // },
  // {
  //   municipio: "Torrijas",
  //   provincia: "Teruel",
  //   id: "44231",
  //   label: "Torrijas (Teruel)",
  // },
  // {
  //   municipio: "Vallecillo, El",
  //   provincia: "Teruel",
  //   id: "44249",
  //   label: "Vallecillo, El (Teruel)",
  // },
  // {
  //   municipio: "Torrijo del Campo",
  //   provincia: "Teruel",
  //   id: "44232",
  //   label: "Torrijo del Campo (Teruel)",
  // },
  // {
  //   municipio: "Urrea de Gaén",
  //   provincia: "Teruel",
  //   id: "44237",
  //   label: "Urrea de Gaén (Teruel)",
  // },
  // {
  //   municipio: "Campaspero",
  //   provincia: "Valladolid",
  //   id: "47030",
  //   label: "Campaspero (Valladolid)",
  // },
  // {
  //   municipio: "Tramacastiel",
  //   provincia: "Teruel",
  //   id: "44234",
  //   label: "Tramacastiel (Teruel)",
  // },
  // {
  //   municipio: "Tramacastilla",
  //   provincia: "Teruel",
  //   id: "44235",
  //   label: "Tramacastilla (Teruel)",
  // },
  // {
  //   municipio: "Utrillas",
  //   provincia: "Teruel",
  //   id: "44238",
  //   label: "Utrillas (Teruel)",
  // },
  // {
  //   municipio: "Valacloche",
  //   provincia: "Teruel",
  //   id: "44239",
  //   label: "Valacloche (Teruel)",
  // },
  // {
  //   municipio: "Valbona",
  //   provincia: "Teruel",
  //   id: "44240",
  //   label: "Valbona (Teruel)",
  // },
  // {
  //   municipio: "Valdealgorfa",
  //   provincia: "Teruel",
  //   id: "44241",
  //   label: "Valdealgorfa (Teruel)",
  // },
  // {
  //   municipio: "Valdeltormo",
  //   provincia: "Teruel",
  //   id: "44245",
  //   label: "Valdeltormo (Teruel)",
  // },
  // {
  //   municipio: "Valdecuenca",
  //   provincia: "Teruel",
  //   id: "44243",
  //   label: "Valdecuenca (Teruel)",
  // },
  // {
  //   municipio: "Valderrobres",
  //   provincia: "Teruel",
  //   id: "44246",
  //   label: "Valderrobres (Teruel)",
  // },
  // {
  //   municipio: "Valjunquera",
  //   provincia: "Teruel",
  //   id: "44247",
  //   label: "Valjunquera (Teruel)",
  // },
  // {
  //   municipio: "Veguillas de la Sierra",
  //   provincia: "Teruel",
  //   id: "44250",
  //   label: "Veguillas de la Sierra (Teruel)",
  // },
  // {
  //   municipio: "Valdelinares",
  //   provincia: "Teruel",
  //   id: "44244",
  //   label: "Valdelinares (Teruel)",
  // },
  // {
  //   municipio: "Villafranca del Campo",
  //   provincia: "Teruel",
  //   id: "44251",
  //   label: "Villafranca del Campo (Teruel)",
  // },
  // {
  //   municipio: "Villahermosa del Campo",
  //   provincia: "Teruel",
  //   id: "44252",
  //   label: "Villahermosa del Campo (Teruel)",
  // },
  // {
  //   municipio: "Villanueva del Rebollar de la Sierra",
  //   provincia: "Teruel",
  //   id: "44256",
  //   label: "Villanueva del Rebollar de la Sierra (Teruel)",
  // },
  // {
  //   municipio: "Villar del Cobo",
  //   provincia: "Teruel",
  //   id: "44257",
  //   label: "Villar del Cobo (Teruel)",
  // },
  // {
  //   municipio: "Villarquemado",
  //   provincia: "Teruel",
  //   id: "44261",
  //   label: "Villarquemado (Teruel)",
  // },
  // {
  //   municipio: "Villar del Salz",
  //   provincia: "Teruel",
  //   id: "44258",
  //   label: "Villar del Salz (Teruel)",
  // },
  // {
  //   municipio: "Villarroya de los Pinares",
  //   provincia: "Teruel",
  //   id: "44262",
  //   label: "Villarroya de los Pinares (Teruel)",
  // },
  // {
  //   municipio: "Villarluengo",
  //   provincia: "Teruel",
  //   id: "44260",
  //   label: "Villarluengo (Teruel)",
  // },
  // {
  //   municipio: "Arcicóllar",
  //   provincia: "Toledo",
  //   id: "45015",
  //   label: "Arcicóllar (Toledo)",
  // },
  // {
  //   municipio: "Villastar",
  //   provincia: "Teruel",
  //   id: "44263",
  //   label: "Villastar (Teruel)",
  // },
  // {
  //   municipio: "Argés",
  //   provincia: "Toledo",
  //   id: "45016",
  //   label: "Argés (Toledo)",
  // },
  // {
  //   municipio: "Villel",
  //   provincia: "Teruel",
  //   id: "44264",
  //   label: "Villel (Teruel)",
  // },
  // {
  //   municipio: "Urueña",
  //   provincia: "Valladolid",
  //   id: "47178",
  //   label: "Urueña (Valladolid)",
  // },
  // {
  //   municipio: "Azután",
  //   provincia: "Toledo",
  //   id: "45017",
  //   label: "Azután (Toledo)",
  // },
  // {
  //   municipio: "Vinaceite",
  //   provincia: "Teruel",
  //   id: "44265",
  //   label: "Vinaceite (Teruel)",
  // },
  // {
  //   municipio: "Visiedo",
  //   provincia: "Teruel",
  //   id: "44266",
  //   label: "Visiedo (Teruel)",
  // },
  // {
  //   municipio: "Vivel del Río Martín",
  //   provincia: "Teruel",
  //   id: "44267",
  //   label: "Vivel del Río Martín (Teruel)",
  // },
  // {
  //   municipio: "Zoma, La",
  //   provincia: "Teruel",
  //   id: "44268",
  //   label: "Zoma, La (Teruel)",
  // },
  // {
  //   municipio: "Cabañas de Yepes",
  //   provincia: "Toledo",
  //   id: "45026",
  //   label: "Cabañas de Yepes (Toledo)",
  // },
  // {
  //   municipio: "Ajofrín",
  //   provincia: "Toledo",
  //   id: "45001",
  //   label: "Ajofrín (Toledo)",
  // },
  // {
  //   municipio: "Alcabón",
  //   provincia: "Toledo",
  //   id: "45004",
  //   label: "Alcabón (Toledo)",
  // },
  // {
  //   municipio: "Alameda de la Sagra",
  //   provincia: "Toledo",
  //   id: "45002",
  //   label: "Alameda de la Sagra (Toledo)",
  // },
  // {
  //   municipio: "Alcañizo",
  //   provincia: "Toledo",
  //   id: "45005",
  //   label: "Alcañizo (Toledo)",
  // },
  // {
  //   municipio: "Albarreal de Tajo",
  //   provincia: "Toledo",
  //   id: "45003",
  //   label: "Albarreal de Tajo (Toledo)",
  // },
  // {
  //   municipio: "Villamuriel de Campos",
  //   provincia: "Valladolid",
  //   id: "47215",
  //   label: "Villamuriel de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Alcaudete de la Jara",
  //   provincia: "Toledo",
  //   id: "45006",
  //   label: "Alcaudete de la Jara (Toledo)",
  // },
  // {
  //   municipio: "Alcolea de Tajo",
  //   provincia: "Toledo",
  //   id: "45007",
  //   label: "Alcolea de Tajo (Toledo)",
  // },
  // {
  //   municipio: "Chueca",
  //   provincia: "Toledo",
  //   id: "45057",
  //   label: "Chueca (Toledo)",
  // },
  // {
  //   municipio: "Aldea en Cabo",
  //   provincia: "Toledo",
  //   id: "45008",
  //   label: "Aldea en Cabo (Toledo)",
  // },
  // {
  //   municipio: "Aldeanueva de Barbarroya",
  //   provincia: "Toledo",
  //   id: "45009",
  //   label: "Aldeanueva de Barbarroya (Toledo)",
  // },
  // {
  //   municipio: "Cabezamesada",
  //   provincia: "Toledo",
  //   id: "45027",
  //   label: "Cabezamesada (Toledo)",
  // },
  // {
  //   municipio: "Aldeanueva de San Bartolomé",
  //   provincia: "Toledo",
  //   id: "45010",
  //   label: "Aldeanueva de San Bartolomé (Toledo)",
  // },
  // {
  //   municipio: "Barcience",
  //   provincia: "Toledo",
  //   id: "45018",
  //   label: "Barcience (Toledo)",
  // },
  // {
  //   municipio: "Calera y Chozas",
  //   provincia: "Toledo",
  //   id: "45028",
  //   label: "Calera y Chozas (Toledo)",
  // },
  // {
  //   municipio: "Almendral de la Cañada",
  //   provincia: "Toledo",
  //   id: "45011",
  //   label: "Almendral de la Cañada (Toledo)",
  // },
  // {
  //   municipio: "Almonacid de Toledo",
  //   provincia: "Toledo",
  //   id: "45012",
  //   label: "Almonacid de Toledo (Toledo)",
  // },
  // {
  //   municipio: "Almorox",
  //   provincia: "Toledo",
  //   id: "45013",
  //   label: "Almorox (Toledo)",
  // },
  // {
  //   municipio: "Añover de Tajo",
  //   provincia: "Toledo",
  //   id: "45014",
  //   label: "Añover de Tajo (Toledo)",
  // },
  // {
  //   municipio: "Bargas",
  //   provincia: "Toledo",
  //   id: "45019",
  //   label: "Bargas (Toledo)",
  // },
  // {
  //   municipio: "Caleruela",
  //   provincia: "Toledo",
  //   id: "45029",
  //   label: "Caleruela (Toledo)",
  // },
  // {
  //   municipio: "Calzada de Oropesa",
  //   provincia: "Toledo",
  //   id: "45030",
  //   label: "Calzada de Oropesa (Toledo)",
  // },
  // {
  //   municipio: "Belvís de la Jara",
  //   provincia: "Toledo",
  //   id: "45020",
  //   label: "Belvís de la Jara (Toledo)",
  // },
  // {
  //   municipio: "Borox",
  //   provincia: "Toledo",
  //   id: "45021",
  //   label: "Borox (Toledo)",
  // },
  // {
  //   municipio: "Carranque",
  //   provincia: "Toledo",
  //   id: "45038",
  //   label: "Carranque (Toledo)",
  // },
  // {
  //   municipio: "Buenaventura",
  //   provincia: "Toledo",
  //   id: "45022",
  //   label: "Buenaventura (Toledo)",
  // },
  // {
  //   municipio: "Burguillos de Toledo",
  //   provincia: "Toledo",
  //   id: "45023",
  //   label: "Burguillos de Toledo (Toledo)",
  // },
  // {
  //   municipio: "Burujón",
  //   provincia: "Toledo",
  //   id: "45024",
  //   label: "Burujón (Toledo)",
  // },
  // {
  //   municipio: "Cabañas de la Sagra",
  //   provincia: "Toledo",
  //   id: "45025",
  //   label: "Cabañas de la Sagra (Toledo)",
  // },
  // {
  //   municipio: "Camarena",
  //   provincia: "Toledo",
  //   id: "45031",
  //   label: "Camarena (Toledo)",
  // },
  // {
  //   municipio: "Camarenilla",
  //   provincia: "Toledo",
  //   id: "45032",
  //   label: "Camarenilla (Toledo)",
  // },
  // {
  //   municipio: "Carriches",
  //   provincia: "Toledo",
  //   id: "45039",
  //   label: "Carriches (Toledo)",
  // },
  // {
  //   municipio: "Campillo de la Jara, El",
  //   provincia: "Toledo",
  //   id: "45033",
  //   label: "Campillo de la Jara, El (Toledo)",
  // },
  // {
  //   municipio: "Cazalegas",
  //   provincia: "Toledo",
  //   id: "45045",
  //   label: "Cazalegas (Toledo)",
  // },
  // {
  //   municipio: "Camuñas",
  //   provincia: "Toledo",
  //   id: "45034",
  //   label: "Camuñas (Toledo)",
  // },
  // {
  //   municipio: "Cardiel de los Montes",
  //   provincia: "Toledo",
  //   id: "45035",
  //   label: "Cardiel de los Montes (Toledo)",
  // },
  // {
  //   municipio: "Carmena",
  //   provincia: "Toledo",
  //   id: "45036",
  //   label: "Carmena (Toledo)",
  // },
  // {
  //   municipio: "Carpio de Tajo, El",
  //   provincia: "Toledo",
  //   id: "45037",
  //   label: "Carpio de Tajo, El (Toledo)",
  // },
  // {
  //   municipio: "Casar de Escalona, El",
  //   provincia: "Toledo",
  //   id: "45040",
  //   label: "Casar de Escalona, El (Toledo)",
  // },
  // {
  //   municipio: "Casarrubios del Monte",
  //   provincia: "Toledo",
  //   id: "45041",
  //   label: "Casarrubios del Monte (Toledo)",
  // },
  // {
  //   municipio: "Casasbuenas",
  //   provincia: "Toledo",
  //   id: "45042",
  //   label: "Casasbuenas (Toledo)",
  // },
  // {
  //   municipio: "Castillo de Bayuela",
  //   provincia: "Toledo",
  //   id: "45043",
  //   label: "Castillo de Bayuela (Toledo)",
  // },
  // {
  //   municipio: "Villán de Tordesillas",
  //   provincia: "Valladolid",
  //   id: "47216",
  //   label: "Villán de Tordesillas (Valladolid)",
  // },
  // {
  //   municipio: "Cebolla",
  //   provincia: "Toledo",
  //   id: "45046",
  //   label: "Cebolla (Toledo)",
  // },
  // {
  //   municipio: "Cobeja",
  //   provincia: "Toledo",
  //   id: "45051",
  //   label: "Cobeja (Toledo)",
  // },
  // {
  //   municipio: "Cedillo del Condado",
  //   provincia: "Toledo",
  //   id: "45047",
  //   label: "Cedillo del Condado (Toledo)",
  // },
  // {
  //   municipio: "Cuerva",
  //   provincia: "Toledo",
  //   id: "45055",
  //   label: "Cuerva (Toledo)",
  // },
  // {
  //   municipio: "Cerralbos, Los",
  //   provincia: "Toledo",
  //   id: "45048",
  //   label: "Cerralbos, Los (Toledo)",
  // },
  // {
  //   municipio: "Aldaia",
  //   provincia: "Valencia",
  //   id: "46021",
  //   label: "Aldaia (Valencia)",
  // },
  // {
  //   municipio: "Cervera de los Montes",
  //   provincia: "Toledo",
  //   id: "45049",
  //   label: "Cervera de los Montes (Toledo)",
  // },
  // {
  //   municipio: "Ciruelos",
  //   provincia: "Toledo",
  //   id: "45050",
  //   label: "Ciruelos (Toledo)",
  // },
  // {
  //   municipio: "Cobisa",
  //   provincia: "Toledo",
  //   id: "45052",
  //   label: "Cobisa (Toledo)",
  // },
  // {
  //   municipio: "Chozas de Canales",
  //   provincia: "Toledo",
  //   id: "45056",
  //   label: "Chozas de Canales (Toledo)",
  // },
  // {
  //   municipio: "Consuegra",
  //   provincia: "Toledo",
  //   id: "45053",
  //   label: "Consuegra (Toledo)",
  // },
  // {
  //   municipio: "Corral de Almaguer",
  //   provincia: "Toledo",
  //   id: "45054",
  //   label: "Corral de Almaguer (Toledo)",
  // },
  // {
  //   municipio: "Domingo Pérez",
  //   provincia: "Toledo",
  //   id: "45058",
  //   label: "Domingo Pérez (Toledo)",
  // },
  // {
  //   municipio: "Erustes",
  //   provincia: "Toledo",
  //   id: "45060",
  //   label: "Erustes (Toledo)",
  // },
  // {
  //   municipio: "Magán",
  //   provincia: "Toledo",
  //   id: "45088",
  //   label: "Magán (Toledo)",
  // },
  // {
  //   municipio: "Dosbarrios",
  //   provincia: "Toledo",
  //   id: "45059",
  //   label: "Dosbarrios (Toledo)",
  // },
  // {
  //   municipio: "Guardia, La",
  //   provincia: "Toledo",
  //   id: "45071",
  //   label: "Guardia, La (Toledo)",
  // },
  // {
  //   municipio: "Herencias, Las",
  //   provincia: "Toledo",
  //   id: "45072",
  //   label: "Herencias, Las (Toledo)",
  // },
  // {
  //   municipio: "Escalona",
  //   provincia: "Toledo",
  //   id: "45061",
  //   label: "Escalona (Toledo)",
  // },
  // {
  //   municipio: "Escalonilla",
  //   provincia: "Toledo",
  //   id: "45062",
  //   label: "Escalonilla (Toledo)",
  // },
  // {
  //   municipio: "Espinoso del Rey",
  //   provincia: "Toledo",
  //   id: "45063",
  //   label: "Espinoso del Rey (Toledo)",
  // },
  // {
  //   municipio: "Esquivias",
  //   provincia: "Toledo",
  //   id: "45064",
  //   label: "Esquivias (Toledo)",
  // },
  // {
  //   municipio: "Estrella, La",
  //   provincia: "Toledo",
  //   id: "45065",
  //   label: "Estrella, La (Toledo)",
  // },
  // {
  //   municipio: "Herreruela de Oropesa",
  //   provincia: "Toledo",
  //   id: "45073",
  //   label: "Herreruela de Oropesa (Toledo)",
  // },
  // {
  //   municipio: "Fuensalida",
  //   provincia: "Toledo",
  //   id: "45066",
  //   label: "Fuensalida (Toledo)",
  // },
  // {
  //   municipio: "Illescas",
  //   provincia: "Toledo",
  //   id: "45081",
  //   label: "Illescas (Toledo)",
  // },
  // {
  //   municipio: "Alfauir",
  //   provincia: "Valencia",
  //   id: "46023",
  //   label: "Alfauir (Valencia)",
  // },
  // {
  //   municipio: "Gálvez",
  //   provincia: "Toledo",
  //   id: "45067",
  //   label: "Gálvez (Toledo)",
  // },
  // {
  //   municipio: "Garciotum",
  //   provincia: "Toledo",
  //   id: "45068",
  //   label: "Garciotum (Toledo)",
  // },
  // {
  //   municipio: "Gerindote",
  //   provincia: "Toledo",
  //   id: "45069",
  //   label: "Gerindote (Toledo)",
  // },
  // {
  //   municipio: "Miguel Esteban",
  //   provincia: "Toledo",
  //   id: "45101",
  //   label: "Miguel Esteban (Toledo)",
  // },
  // {
  //   municipio: "Guadamur",
  //   provincia: "Toledo",
  //   id: "45070",
  //   label: "Guadamur (Toledo)",
  // },
  // {
  //   municipio: "Hinojosa de San Vicente",
  //   provincia: "Toledo",
  //   id: "45074",
  //   label: "Hinojosa de San Vicente (Toledo)",
  // },
  // {
  //   municipio: "Hontanar",
  //   provincia: "Toledo",
  //   id: "45075",
  //   label: "Hontanar (Toledo)",
  // },
  // {
  //   municipio: "Hormigos",
  //   provincia: "Toledo",
  //   id: "45076",
  //   label: "Hormigos (Toledo)",
  // },
  // {
  //   municipio: "Huecas",
  //   provincia: "Toledo",
  //   id: "45077",
  //   label: "Huecas (Toledo)",
  // },
  // {
  //   municipio: "Layos",
  //   provincia: "Toledo",
  //   id: "45083",
  //   label: "Layos (Toledo)",
  // },
  // {
  //   municipio: "Huerta de Valdecarábanos",
  //   provincia: "Toledo",
  //   id: "45078",
  //   label: "Huerta de Valdecarábanos (Toledo)",
  // },
  // {
  //   municipio: "Iglesuela del Tiétar, La",
  //   provincia: "Toledo",
  //   id: "45079",
  //   label: "Iglesuela del Tiétar, La (Toledo)",
  // },
  // {
  //   municipio: "Illán de Vacas",
  //   provincia: "Toledo",
  //   id: "45080",
  //   label: "Illán de Vacas (Toledo)",
  // },
  // {
  //   municipio: "Lagartera",
  //   provincia: "Toledo",
  //   id: "45082",
  //   label: "Lagartera (Toledo)",
  // },
  // {
  //   municipio: "Lillo",
  //   provincia: "Toledo",
  //   id: "45084",
  //   label: "Lillo (Toledo)",
  // },
  // {
  //   municipio: "Montearagón",
  //   provincia: "Toledo",
  //   id: "45104",
  //   label: "Montearagón (Toledo)",
  // },
  // {
  //   municipio: "Lominchar",
  //   provincia: "Toledo",
  //   id: "45085",
  //   label: "Lominchar (Toledo)",
  // },
  // {
  //   municipio: "Madridejos",
  //   provincia: "Toledo",
  //   id: "45087",
  //   label: "Madridejos (Toledo)",
  // },
  // {
  //   municipio: "Lucillos",
  //   provincia: "Toledo",
  //   id: "45086",
  //   label: "Lucillos (Toledo)",
  // },
  // {
  //   municipio: "Malpica de Tajo",
  //   provincia: "Toledo",
  //   id: "45089",
  //   label: "Malpica de Tajo (Toledo)",
  // },
  // {
  //   municipio: "Manzaneque",
  //   provincia: "Toledo",
  //   id: "45090",
  //   label: "Manzaneque (Toledo)",
  // },
  // {
  //   municipio: "Maqueda",
  //   provincia: "Toledo",
  //   id: "45091",
  //   label: "Maqueda (Toledo)",
  // },
  // {
  //   municipio: "Marjaliza",
  //   provincia: "Toledo",
  //   id: "45092",
  //   label: "Marjaliza (Toledo)",
  // },
  // {
  //   municipio: "Alfara del Patriarca",
  //   provincia: "Valencia",
  //   id: "46025",
  //   label: "Alfara del Patriarca (Valencia)",
  // },
  // {
  //   municipio: "Marrupe",
  //   provincia: "Toledo",
  //   id: "45093",
  //   label: "Marrupe (Toledo)",
  // },
  // {
  //   municipio: "Mata, La",
  //   provincia: "Toledo",
  //   id: "45095",
  //   label: "Mata, La (Toledo)",
  // },
  // {
  //   municipio: "Mascaraque",
  //   provincia: "Toledo",
  //   id: "45094",
  //   label: "Mascaraque (Toledo)",
  // },
  // {
  //   municipio: "Mazarambroz",
  //   provincia: "Toledo",
  //   id: "45096",
  //   label: "Mazarambroz (Toledo)",
  // },
  // {
  //   municipio: "Mejorada",
  //   provincia: "Toledo",
  //   id: "45097",
  //   label: "Mejorada (Toledo)",
  // },
  // {
  //   municipio: "Méntrida",
  //   provincia: "Toledo",
  //   id: "45099",
  //   label: "Méntrida (Toledo)",
  // },
  // {
  //   municipio: "Mesegar de Tajo",
  //   provincia: "Toledo",
  //   id: "45100",
  //   label: "Mesegar de Tajo (Toledo)",
  // },
  // {
  //   municipio: "Menasalbas",
  //   provincia: "Toledo",
  //   id: "45098",
  //   label: "Menasalbas (Toledo)",
  // },
  // {
  //   municipio: "Mocejón",
  //   provincia: "Toledo",
  //   id: "45102",
  //   label: "Mocejón (Toledo)",
  // },
  // {
  //   municipio: "Mohedas de la Jara",
  //   provincia: "Toledo",
  //   id: "45103",
  //   label: "Mohedas de la Jara (Toledo)",
  // },
  // {
  //   municipio: "Montesclaros",
  //   provincia: "Toledo",
  //   id: "45105",
  //   label: "Montesclaros (Toledo)",
  // },
  // {
  //   municipio: "Mora",
  //   provincia: "Toledo",
  //   id: "45106",
  //   label: "Mora (Toledo)",
  // },
  // {
  //   municipio: "Nava de Ricomalillo, La",
  //   provincia: "Toledo",
  //   id: "45108",
  //   label: "Nava de Ricomalillo, La (Toledo)",
  // },
  // {
  //   municipio: "Nambroca",
  //   provincia: "Toledo",
  //   id: "45107",
  //   label: "Nambroca (Toledo)",
  // },
  // {
  //   municipio: "Noblejas",
  //   provincia: "Toledo",
  //   id: "45115",
  //   label: "Noblejas (Toledo)",
  // },
  // {
  //   municipio: "Navahermosa",
  //   provincia: "Toledo",
  //   id: "45109",
  //   label: "Navahermosa (Toledo)",
  // },
  // {
  //   municipio: "Navalcán",
  //   provincia: "Toledo",
  //   id: "45110",
  //   label: "Navalcán (Toledo)",
  // },
  // {
  //   municipio: "Navalmoralejo",
  //   provincia: "Toledo",
  //   id: "45111",
  //   label: "Navalmoralejo (Toledo)",
  // },
  // {
  //   municipio: "Noez",
  //   provincia: "Toledo",
  //   id: "45116",
  //   label: "Noez (Toledo)",
  // },
  // {
  //   municipio: "Nombela",
  //   provincia: "Toledo",
  //   id: "45117",
  //   label: "Nombela (Toledo)",
  // },
  // {
  //   municipio: "Navalmorales, Los",
  //   provincia: "Toledo",
  //   id: "45112",
  //   label: "Navalmorales, Los (Toledo)",
  // },
  // {
  //   municipio: "Navalucillos, Los",
  //   provincia: "Toledo",
  //   id: "45113",
  //   label: "Navalucillos, Los (Toledo)",
  // },
  // {
  //   municipio: "Orgaz",
  //   provincia: "Toledo",
  //   id: "45124",
  //   label: "Orgaz (Toledo)",
  // },
  // {
  //   municipio: "Algar de Palancia",
  //   provincia: "Valencia",
  //   id: "46028",
  //   label: "Algar de Palancia (Valencia)",
  // },
  // {
  //   municipio: "Navamorcuende",
  //   provincia: "Toledo",
  //   id: "45114",
  //   label: "Navamorcuende (Toledo)",
  // },
  // {
  //   municipio: "Novés",
  //   provincia: "Toledo",
  //   id: "45118",
  //   label: "Novés (Toledo)",
  // },
  // {
  //   municipio: "Bugarra",
  //   provincia: "Valencia",
  //   id: "46076",
  //   label: "Bugarra (Valencia)",
  // },
  // {
  //   municipio: "Numancia de la Sagra",
  //   provincia: "Toledo",
  //   id: "45119",
  //   label: "Numancia de la Sagra (Toledo)",
  // },
  // {
  //   municipio: "Nuño Gómez",
  //   provincia: "Toledo",
  //   id: "45120",
  //   label: "Nuño Gómez (Toledo)",
  // },
  // {
  //   municipio: "Ocaña",
  //   provincia: "Toledo",
  //   id: "45121",
  //   label: "Ocaña (Toledo)",
  // },
  // {
  //   municipio: "Otero",
  //   provincia: "Toledo",
  //   id: "45126",
  //   label: "Otero (Toledo)",
  // },
  // {
  //   municipio: "Olías del Rey",
  //   provincia: "Toledo",
  //   id: "45122",
  //   label: "Olías del Rey (Toledo)",
  // },
  // {
  //   municipio: "Palomeque",
  //   provincia: "Toledo",
  //   id: "45127",
  //   label: "Palomeque (Toledo)",
  // },
  // {
  //   municipio: "Ontígola",
  //   provincia: "Toledo",
  //   id: "45123",
  //   label: "Ontígola (Toledo)",
  // },
  // {
  //   municipio: "Pantoja",
  //   provincia: "Toledo",
  //   id: "45128",
  //   label: "Pantoja (Toledo)",
  // },
  // {
  //   municipio: "Paredes de Escalona",
  //   provincia: "Toledo",
  //   id: "45129",
  //   label: "Paredes de Escalona (Toledo)",
  // },
  // {
  //   municipio: "Puente del Arzobispo, El",
  //   provincia: "Toledo",
  //   id: "45138",
  //   label: "Puente del Arzobispo, El (Toledo)",
  // },
  // {
  //   municipio: "Parrillas",
  //   provincia: "Toledo",
  //   id: "45130",
  //   label: "Parrillas (Toledo)",
  // },
  // {
  //   municipio: "Almàssera",
  //   provincia: "Valencia",
  //   id: "46032",
  //   label: "Almàssera (Valencia)",
  // },
  // {
  //   municipio: "Pelahustán",
  //   provincia: "Toledo",
  //   id: "45131",
  //   label: "Pelahustán (Toledo)",
  // },
  // {
  //   municipio: "Pepino",
  //   provincia: "Toledo",
  //   id: "45132",
  //   label: "Pepino (Toledo)",
  // },
  // {
  //   municipio: "Polán",
  //   provincia: "Toledo",
  //   id: "45133",
  //   label: "Polán (Toledo)",
  // },
  // {
  //   municipio: "Puerto de San Vicente",
  //   provincia: "Toledo",
  //   id: "45139",
  //   label: "Puerto de San Vicente (Toledo)",
  // },
  // {
  //   municipio: "Portillo de Toledo",
  //   provincia: "Toledo",
  //   id: "45134",
  //   label: "Portillo de Toledo (Toledo)",
  // },
  // {
  //   municipio: "Puebla de Almoradiel, La",
  //   provincia: "Toledo",
  //   id: "45135",
  //   label: "Puebla de Almoradiel, La (Toledo)",
  // },
  // {
  //   municipio: "Puebla de Montalbán, La",
  //   provincia: "Toledo",
  //   id: "45136",
  //   label: "Puebla de Montalbán, La (Toledo)",
  // },
  // {
  //   municipio: "Pueblanueva, La",
  //   provincia: "Toledo",
  //   id: "45137",
  //   label: "Pueblanueva, La (Toledo)",
  // },
  // {
  //   municipio: "Pulgar",
  //   provincia: "Toledo",
  //   id: "45140",
  //   label: "Pulgar (Toledo)",
  // },
  // {
  //   municipio: "Quero",
  //   provincia: "Toledo",
  //   id: "45141",
  //   label: "Quero (Toledo)",
  // },
  // {
  //   municipio: "Estubeny",
  //   provincia: "Valencia",
  //   id: "46121",
  //   label: "Estubeny (Valencia)",
  // },
  // {
  //   municipio: "Quintanar de la Orden",
  //   provincia: "Toledo",
  //   id: "45142",
  //   label: "Quintanar de la Orden (Toledo)",
  // },
  // {
  //   municipio: "Recas",
  //   provincia: "Toledo",
  //   id: "45145",
  //   label: "Recas (Toledo)",
  // },
  // {
  //   municipio: "Quismondo",
  //   provincia: "Toledo",
  //   id: "45143",
  //   label: "Quismondo (Toledo)",
  // },
  // {
  //   municipio: "Sartajada",
  //   provincia: "Toledo",
  //   id: "45159",
  //   label: "Sartajada (Toledo)",
  // },
  // {
  //   municipio: "Retamoso de la Jara",
  //   provincia: "Toledo",
  //   id: "45146",
  //   label: "Retamoso de la Jara (Toledo)",
  // },
  // {
  //   municipio: "Rielves",
  //   provincia: "Toledo",
  //   id: "45147",
  //   label: "Rielves (Toledo)",
  // },
  // {
  //   municipio: "Robledo del Mazo",
  //   provincia: "Toledo",
  //   id: "45148",
  //   label: "Robledo del Mazo (Toledo)",
  // },
  // {
  //   municipio: "Segurilla",
  //   provincia: "Toledo",
  //   id: "45160",
  //   label: "Segurilla (Toledo)",
  // },
  // {
  //   municipio: "Romeral, El",
  //   provincia: "Toledo",
  //   id: "45149",
  //   label: "Romeral, El (Toledo)",
  // },
  // {
  //   municipio: "Almiserà",
  //   provincia: "Valencia",
  //   id: "46033",
  //   label: "Almiserà (Valencia)",
  // },
  // {
  //   municipio: "San Bartolomé de las Abiertas",
  //   provincia: "Toledo",
  //   id: "45150",
  //   label: "San Bartolomé de las Abiertas (Toledo)",
  // },
  // {
  //   municipio: "Toboso, El",
  //   provincia: "Toledo",
  //   id: "45167",
  //   label: "Toboso, El (Toledo)",
  // },
  // {
  //   municipio: "San Martín de Montalbán",
  //   provincia: "Toledo",
  //   id: "45151",
  //   label: "San Martín de Montalbán (Toledo)",
  // },
  // {
  //   municipio: "San Martín de Pusa",
  //   provincia: "Toledo",
  //   id: "45152",
  //   label: "San Martín de Pusa (Toledo)",
  // },
  // {
  //   municipio: "Totanés",
  //   provincia: "Toledo",
  //   id: "45174",
  //   label: "Totanés (Toledo)",
  // },
  // {
  //   municipio: "San Pablo de los Montes",
  //   provincia: "Toledo",
  //   id: "45153",
  //   label: "San Pablo de los Montes (Toledo)",
  // },
  // {
  //   municipio: "Almoines",
  //   provincia: "Valencia",
  //   id: "46034",
  //   label: "Almoines (Valencia)",
  // },
  // {
  //   municipio: "San Román de los Montes",
  //   provincia: "Toledo",
  //   id: "45154",
  //   label: "San Román de los Montes (Toledo)",
  // },
  // {
  //   municipio: "Santa Ana de Pusa",
  //   provincia: "Toledo",
  //   id: "45155",
  //   label: "Santa Ana de Pusa (Toledo)",
  // },
  // {
  //   municipio: "Sotillo de las Palomas",
  //   provincia: "Toledo",
  //   id: "45164",
  //   label: "Sotillo de las Palomas (Toledo)",
  // },
  // {
  //   municipio: "Santa Olalla",
  //   provincia: "Toledo",
  //   id: "45158",
  //   label: "Santa Olalla (Toledo)",
  // },
  // {
  //   municipio: "Sevilleja de la Jara",
  //   provincia: "Toledo",
  //   id: "45162",
  //   label: "Sevilleja de la Jara (Toledo)",
  // },
  // {
  //   municipio: "Sonseca",
  //   provincia: "Toledo",
  //   id: "45163",
  //   label: "Sonseca (Toledo)",
  // },
  // {
  //   municipio: "Bóveda de Toro, La",
  //   provincia: "Zamora",
  //   id: "49024",
  //   label: "Bóveda de Toro, La (Zamora)",
  // },
  // {
  //   municipio: "Tembleque",
  //   provincia: "Toledo",
  //   id: "45166",
  //   label: "Tembleque (Toledo)",
  // },
  // {
  //   municipio: "Almussafes",
  //   provincia: "Valencia",
  //   id: "46035",
  //   label: "Almussafes (Valencia)",
  // },
  // {
  //   municipio: "Torralba de Oropesa",
  //   provincia: "Toledo",
  //   id: "45169",
  //   label: "Torralba de Oropesa (Toledo)",
  // },
  // {
  //   municipio: "Turleque",
  //   provincia: "Toledo",
  //   id: "45175",
  //   label: "Turleque (Toledo)",
  // },
  // {
  //   municipio: "Torrecilla de la Jara",
  //   provincia: "Toledo",
  //   id: "45170",
  //   label: "Torrecilla de la Jara (Toledo)",
  // },
  // {
  //   municipio: "Ugena",
  //   provincia: "Toledo",
  //   id: "45176",
  //   label: "Ugena (Toledo)",
  // },
  // {
  //   municipio: "Torre de Esteban Hambrán, La",
  //   provincia: "Toledo",
  //   id: "45171",
  //   label: "Torre de Esteban Hambrán, La (Toledo)",
  // },
  // {
  //   municipio: "Alpuente",
  //   provincia: "Valencia",
  //   id: "46036",
  //   label: "Alpuente (Valencia)",
  // },
  // {
  //   municipio: "Torrico",
  //   provincia: "Toledo",
  //   id: "45172",
  //   label: "Torrico (Toledo)",
  // },
  // {
  //   municipio: "Torrijos",
  //   provincia: "Toledo",
  //   id: "45173",
  //   label: "Torrijos (Toledo)",
  // },
  // {
  //   municipio: "Urda",
  //   provincia: "Toledo",
  //   id: "45177",
  //   label: "Urda (Toledo)",
  // },
  // {
  //   municipio: "Albal",
  //   provincia: "Valencia",
  //   id: "46007",
  //   label: "Albal (Valencia)",
  // },
  // {
  //   municipio: "Valdeverdeja",
  //   provincia: "Toledo",
  //   id: "45179",
  //   label: "Valdeverdeja (Toledo)",
  // },
  // {
  //   municipio: "Albalat de la Ribera",
  //   provincia: "Valencia",
  //   id: "46008",
  //   label: "Albalat de la Ribera (Valencia)",
  // },
  // {
  //   municipio: "Valmojado",
  //   provincia: "Toledo",
  //   id: "45180",
  //   label: "Valmojado (Toledo)",
  // },
  // {
  //   municipio: "Anna",
  //   provincia: "Valencia",
  //   id: "46039",
  //   label: "Anna (Valencia)",
  // },
  // {
  //   municipio: "Ventas con Peña Aguilera, Las",
  //   provincia: "Toledo",
  //   id: "45182",
  //   label: "Ventas con Peña Aguilera, Las (Toledo)",
  // },
  // {
  //   municipio: "Alborache",
  //   provincia: "Valencia",
  //   id: "46012",
  //   label: "Alborache (Valencia)",
  // },
  // {
  //   municipio: "Ventas de Retamosa, Las",
  //   provincia: "Toledo",
  //   id: "45183",
  //   label: "Ventas de Retamosa, Las (Toledo)",
  // },
  // {
  //   municipio: "Ventas de San Julián, Las",
  //   provincia: "Toledo",
  //   id: "45184",
  //   label: "Ventas de San Julián, Las (Toledo)",
  // },
  // {
  //   municipio: "Alqueria de la Comtessa, l'",
  //   provincia: "Valencia",
  //   id: "46037",
  //   label: "Alqueria de la Comtessa, l' (Valencia)",
  // },
  // {
  //   municipio: "Villacañas",
  //   provincia: "Toledo",
  //   id: "45185",
  //   label: "Villacañas (Toledo)",
  // },
  // {
  //   municipio: "Villa de Don Fadrique, La",
  //   provincia: "Toledo",
  //   id: "45186",
  //   label: "Villa de Don Fadrique, La (Toledo)",
  // },
  // {
  //   municipio: "Villaluenga de la Sagra",
  //   provincia: "Toledo",
  //   id: "45188",
  //   label: "Villaluenga de la Sagra (Toledo)",
  // },
  // {
  //   municipio: "Villamiel de Toledo",
  //   provincia: "Toledo",
  //   id: "45189",
  //   label: "Villamiel de Toledo (Toledo)",
  // },
  // {
  //   municipio: "Favara",
  //   provincia: "Valencia",
  //   id: "46123",
  //   label: "Favara (Valencia)",
  // },
  // {
  //   municipio: "Villaminaya",
  //   provincia: "Toledo",
  //   id: "45190",
  //   label: "Villaminaya (Toledo)",
  // },
  // {
  //   municipio: "Villanueva de Alcardete",
  //   provincia: "Toledo",
  //   id: "45192",
  //   label: "Villanueva de Alcardete (Toledo)",
  // },
  // {
  //   municipio: "Villamuelas",
  //   provincia: "Toledo",
  //   id: "45191",
  //   label: "Villamuelas (Toledo)",
  // },
  // {
  //   municipio: "Aielo de Rugat",
  //   provincia: "Valencia",
  //   id: "46043",
  //   label: "Aielo de Rugat (Valencia)",
  // },
  // {
  //   municipio: "Villanueva de Bogas",
  //   provincia: "Toledo",
  //   id: "45193",
  //   label: "Villanueva de Bogas (Toledo)",
  // },
  // {
  //   municipio: "Villaseca de la Sagra",
  //   provincia: "Toledo",
  //   id: "45196",
  //   label: "Villaseca de la Sagra (Toledo)",
  // },
  // {
  //   municipio: "Villarejo de Montalbán",
  //   provincia: "Toledo",
  //   id: "45194",
  //   label: "Villarejo de Montalbán (Toledo)",
  // },
  // {
  //   municipio: "Benavites",
  //   provincia: "Valencia",
  //   id: "46052",
  //   label: "Benavites (Valencia)",
  // },
  // {
  //   municipio: "Villarrubia de Santiago",
  //   provincia: "Toledo",
  //   id: "45195",
  //   label: "Villarrubia de Santiago (Toledo)",
  // },
  // {
  //   municipio: "Villasequilla",
  //   provincia: "Toledo",
  //   id: "45197",
  //   label: "Villasequilla (Toledo)",
  // },
  // {
  //   municipio: "Yepes",
  //   provincia: "Toledo",
  //   id: "45202",
  //   label: "Yepes (Toledo)",
  // },
  // {
  //   municipio: "Villatobas",
  //   provincia: "Toledo",
  //   id: "45198",
  //   label: "Villatobas (Toledo)",
  // },
  // {
  //   municipio: "Beniflá",
  //   provincia: "Valencia",
  //   id: "46061",
  //   label: "Beniflá (Valencia)",
  // },
  // {
  //   municipio: "Viso de San Juan, El",
  //   provincia: "Toledo",
  //   id: "45199",
  //   label: "Viso de San Juan, El (Toledo)",
  // },
  // {
  //   municipio: "Yébenes, Los",
  //   provincia: "Toledo",
  //   id: "45200",
  //   label: "Yébenes, Los (Toledo)",
  // },
  // {
  //   municipio: "Atzeneta d'Albaida",
  //   provincia: "Valencia",
  //   id: "46003",
  //   label: "Atzeneta d'Albaida (Valencia)",
  // },
  // {
  //   municipio: "Yeles",
  //   provincia: "Toledo",
  //   id: "45201",
  //   label: "Yeles (Toledo)",
  // },
  // {
  //   municipio: "Agullent",
  //   provincia: "Valencia",
  //   id: "46004",
  //   label: "Agullent (Valencia)",
  // },
  // {
  //   municipio: "Yuncler",
  //   provincia: "Toledo",
  //   id: "45203",
  //   label: "Yuncler (Toledo)",
  // },
  // {
  //   municipio: "Yunclillos",
  //   provincia: "Toledo",
  //   id: "45204",
  //   label: "Yunclillos (Toledo)",
  // },
  // {
  //   municipio: "Yuncos",
  //   provincia: "Toledo",
  //   id: "45205",
  //   label: "Yuncos (Toledo)",
  // },
  // {
  //   municipio: "Alaquàs",
  //   provincia: "Valencia",
  //   id: "46005",
  //   label: "Alaquàs (Valencia)",
  // },
  // {
  //   municipio: "Santo Domingo-Caudilla",
  //   provincia: "Toledo",
  //   id: "45901",
  //   label: "Santo Domingo-Caudilla (Toledo)",
  // },
  // {
  //   municipio: "Ademuz",
  //   provincia: "Valencia",
  //   id: "46001",
  //   label: "Ademuz (Valencia)",
  // },
  // {
  //   municipio: "Ador",
  //   provincia: "Valencia",
  //   id: "46002",
  //   label: "Ador (Valencia)",
  // },
  // {
  //   municipio: "Albaida",
  //   provincia: "Valencia",
  //   id: "46006",
  //   label: "Albaida (Valencia)",
  // },
  // {
  //   municipio: "Benimuslem",
  //   provincia: "Valencia",
  //   id: "46064",
  //   label: "Benimuslem (Valencia)",
  // },
  // {
  //   municipio: "Albalat dels Sorells",
  //   provincia: "Valencia",
  //   id: "46009",
  //   label: "Albalat dels Sorells (Valencia)",
  // },
  // {
  //   municipio: "Albalat dels Tarongers",
  //   provincia: "Valencia",
  //   id: "46010",
  //   label: "Albalat dels Tarongers (Valencia)",
  // },
  // {
  //   municipio: "Beniparrell",
  //   provincia: "Valencia",
  //   id: "46065",
  //   label: "Beniparrell (Valencia)",
  // },
  // {
  //   municipio: "Alberic",
  //   provincia: "Valencia",
  //   id: "46011",
  //   label: "Alberic (Valencia)",
  // },
  // {
  //   municipio: "Alcàntera de Xúquer",
  //   provincia: "Valencia",
  //   id: "46016",
  //   label: "Alcàntera de Xúquer (Valencia)",
  // },
  // {
  //   municipio: "Andilla",
  //   provincia: "Valencia",
  //   id: "46038",
  //   label: "Andilla (Valencia)",
  // },
  // {
  //   municipio: "Alzira",
  //   provincia: "Valencia",
  //   id: "46017",
  //   label: "Alzira (Valencia)",
  // },
  // {
  //   municipio: "Albuixech",
  //   provincia: "Valencia",
  //   id: "46014",
  //   label: "Albuixech (Valencia)",
  // },
  // {
  //   municipio: "Alcàsser",
  //   provincia: "Valencia",
  //   id: "46015",
  //   label: "Alcàsser (Valencia)",
  // },
  // {
  //   municipio: "Alcúdia, l'",
  //   provincia: "Valencia",
  //   id: "46019",
  //   label: "Alcúdia, l' (Valencia)",
  // },
  // {
  //   municipio: "Alcúdia de Crespins, l'",
  //   provincia: "Valencia",
  //   id: "46020",
  //   label: "Alcúdia de Crespins, l' (Valencia)",
  // },
  // {
  //   municipio: "Benirredrà",
  //   provincia: "Valencia",
  //   id: "46066",
  //   label: "Benirredrà (Valencia)",
  // },
  // {
  //   municipio: "Alfafar",
  //   provincia: "Valencia",
  //   id: "46022",
  //   label: "Alfafar (Valencia)",
  // },
  // {
  //   municipio: "Alfara de la Baronia",
  //   provincia: "Valencia",
  //   id: "46024",
  //   label: "Alfara de la Baronia (Valencia)",
  // },
  // {
  //   municipio: "Antella",
  //   provincia: "Valencia",
  //   id: "46040",
  //   label: "Antella (Valencia)",
  // },
  // {
  //   municipio: "Aras de los Olmos",
  //   provincia: "Valencia",
  //   id: "46041",
  //   label: "Aras de los Olmos (Valencia)",
  // },
  // {
  //   municipio: "Aielo de Malferit",
  //   provincia: "Valencia",
  //   id: "46042",
  //   label: "Aielo de Malferit (Valencia)",
  // },
  // {
  //   municipio: "Alfarp",
  //   provincia: "Valencia",
  //   id: "46026",
  //   label: "Alfarp (Valencia)",
  // },
  // {
  //   municipio: "Alfarrasí",
  //   provincia: "Valencia",
  //   id: "46027",
  //   label: "Alfarrasí (Valencia)",
  // },
  // {
  //   municipio: "Algímia d'Alfara",
  //   provincia: "Valencia",
  //   id: "46030",
  //   label: "Algímia d'Alfara (Valencia)",
  // },
  // {
  //   municipio: "Alginet",
  //   provincia: "Valencia",
  //   id: "46031",
  //   label: "Alginet (Valencia)",
  // },
  // {
  //   municipio: "Barxeta",
  //   provincia: "Valencia",
  //   id: "46045",
  //   label: "Barxeta (Valencia)",
  // },
  // {
  //   municipio: "Barx",
  //   provincia: "Valencia",
  //   id: "46046",
  //   label: "Barx (Valencia)",
  // },
  // {
  //   municipio: "Bèlgida",
  //   provincia: "Valencia",
  //   id: "46047",
  //   label: "Bèlgida (Valencia)",
  // },
  // {
  //   municipio: "Bellreguard",
  //   provincia: "Valencia",
  //   id: "46048",
  //   label: "Bellreguard (Valencia)",
  // },
  // {
  //   municipio: "Villamor de los Escuderos",
  //   provincia: "Zamora",
  //   id: "49255",
  //   label: "Villamor de los Escuderos (Zamora)",
  // },
  // {
  //   municipio: "Bellús",
  //   provincia: "Valencia",
  //   id: "46049",
  //   label: "Bellús (Valencia)",
  // },
  // {
  //   municipio: "Benagéber",
  //   provincia: "Valencia",
  //   id: "46050",
  //   label: "Benagéber (Valencia)",
  // },
  // {
  //   municipio: "Benaguasil",
  //   provincia: "Valencia",
  //   id: "46051",
  //   label: "Benaguasil (Valencia)",
  // },
  // {
  //   municipio: "Beneixida",
  //   provincia: "Valencia",
  //   id: "46053",
  //   label: "Beneixida (Valencia)",
  // },
  // {
  //   municipio: "Benetússer",
  //   provincia: "Valencia",
  //   id: "46054",
  //   label: "Benetússer (Valencia)",
  // },
  // {
  //   municipio: "Beniarjó",
  //   provincia: "Valencia",
  //   id: "46055",
  //   label: "Beniarjó (Valencia)",
  // },
  // {
  //   municipio: "Losa del Obispo",
  //   provincia: "Valencia",
  //   id: "46149",
  //   label: "Losa del Obispo (Valencia)",
  // },
  // {
  //   municipio: "Beniatjar",
  //   provincia: "Valencia",
  //   id: "46056",
  //   label: "Beniatjar (Valencia)",
  // },
  // {
  //   municipio: "Benicolet",
  //   provincia: "Valencia",
  //   id: "46057",
  //   label: "Benicolet (Valencia)",
  // },
  // {
  //   municipio: "Brime de Urz",
  //   provincia: "Zamora",
  //   id: "49028",
  //   label: "Brime de Urz (Zamora)",
  // },
  // {
  //   municipio: "Benifairó de les Valls",
  //   provincia: "Valencia",
  //   id: "46058",
  //   label: "Benifairó de les Valls (Valencia)",
  // },
  // {
  //   municipio: "Benifairó de la Valldigna",
  //   provincia: "Valencia",
  //   id: "46059",
  //   label: "Benifairó de la Valldigna (Valencia)",
  // },
  // {
  //   municipio: "Benifaió",
  //   provincia: "Valencia",
  //   id: "46060",
  //   label: "Benifaió (Valencia)",
  // },
  // {
  //   municipio: "Benigànim",
  //   provincia: "Valencia",
  //   id: "46062",
  //   label: "Benigànim (Valencia)",
  // },
  // {
  //   municipio: "Benimodo",
  //   provincia: "Valencia",
  //   id: "46063",
  //   label: "Benimodo (Valencia)",
  // },
  // {
  //   municipio: "Benissanó",
  //   provincia: "Valencia",
  //   id: "46067",
  //   label: "Benissanó (Valencia)",
  // },
  // {
  //   municipio: "Benissoda",
  //   provincia: "Valencia",
  //   id: "46068",
  //   label: "Benissoda (Valencia)",
  // },
  // {
  //   municipio: "Benissuera",
  //   provincia: "Valencia",
  //   id: "46069",
  //   label: "Benissuera (Valencia)",
  // },
  // {
  //   municipio: "Bétera",
  //   provincia: "Valencia",
  //   id: "46070",
  //   label: "Bétera (Valencia)",
  // },
  // {
  //   municipio: "Bolbaite",
  //   provincia: "Valencia",
  //   id: "46073",
  //   label: "Bolbaite (Valencia)",
  // },
  // {
  //   municipio: "Bonrepòs i Mirambell",
  //   provincia: "Valencia",
  //   id: "46074",
  //   label: "Bonrepòs i Mirambell (Valencia)",
  // },
  // {
  //   municipio: "Bicorp",
  //   provincia: "Valencia",
  //   id: "46071",
  //   label: "Bicorp (Valencia)",
  // },
  // {
  //   municipio: "Bocairent",
  //   provincia: "Valencia",
  //   id: "46072",
  //   label: "Bocairent (Valencia)",
  // },
  // {
  //   municipio: "Cerdà",
  //   provincia: "Valencia",
  //   id: "46096",
  //   label: "Cerdà (Valencia)",
  // },
  // {
  //   municipio: "Bufali",
  //   provincia: "Valencia",
  //   id: "46075",
  //   label: "Bufali (Valencia)",
  // },
  // {
  //   municipio: "Cofrentes",
  //   provincia: "Valencia",
  //   id: "46097",
  //   label: "Cofrentes (Valencia)",
  // },
  // {
  //   municipio: "Corbera",
  //   provincia: "Valencia",
  //   id: "46098",
  //   label: "Corbera (Valencia)",
  // },
  // {
  //   municipio: "Cortes de Pallás",
  //   provincia: "Valencia",
  //   id: "46099",
  //   label: "Cortes de Pallás (Valencia)",
  // },
  // {
  //   municipio: "Burganes de Valverde",
  //   provincia: "Zamora",
  //   id: "49029",
  //   label: "Burganes de Valverde (Zamora)",
  // },
  // {
  //   municipio: "Buñol",
  //   provincia: "Valencia",
  //   id: "46077",
  //   label: "Buñol (Valencia)",
  // },
  // {
  //   municipio: "Burjassot",
  //   provincia: "Valencia",
  //   id: "46078",
  //   label: "Burjassot (Valencia)",
  // },
  // {
  //   municipio: "Camporrobles",
  //   provincia: "Valencia",
  //   id: "46080",
  //   label: "Camporrobles (Valencia)",
  // },
  // {
  //   municipio: "Canet d'En Berenguer",
  //   provincia: "Valencia",
  //   id: "46082",
  //   label: "Canet d'En Berenguer (Valencia)",
  // },
  // {
  //   municipio: "Castrillo de la Guareña",
  //   provincia: "Zamora",
  //   id: "49040",
  //   label: "Castrillo de la Guareña (Zamora)",
  // },
  // {
  //   municipio: "Canals",
  //   provincia: "Valencia",
  //   id: "46081",
  //   label: "Canals (Valencia)",
  // },
  // {
  //   municipio: "Carcaixent",
  //   provincia: "Valencia",
  //   id: "46083",
  //   label: "Carcaixent (Valencia)",
  // },
  // {
  //   municipio: "Càrcer",
  //   provincia: "Valencia",
  //   id: "46084",
  //   label: "Càrcer (Valencia)",
  // },
  // {
  //   municipio: "Carlet",
  //   provincia: "Valencia",
  //   id: "46085",
  //   label: "Carlet (Valencia)",
  // },
  // {
  //   municipio: "Casas Altas",
  //   provincia: "Valencia",
  //   id: "46087",
  //   label: "Casas Altas (Valencia)",
  // },
  // {
  //   municipio: "Cotes",
  //   provincia: "Valencia",
  //   id: "46100",
  //   label: "Cotes (Valencia)",
  // },
  // {
  //   municipio: "Quart de les Valls",
  //   provincia: "Valencia",
  //   id: "46101",
  //   label: "Quart de les Valls (Valencia)",
  // },
  // {
  //   municipio: "Quart de Poblet",
  //   provincia: "Valencia",
  //   id: "46102",
  //   label: "Quart de Poblet (Valencia)",
  // },
  // {
  //   municipio: "Casas Bajas",
  //   provincia: "Valencia",
  //   id: "46088",
  //   label: "Casas Bajas (Valencia)",
  // },
  // {
  //   municipio: "Casinos",
  //   provincia: "Valencia",
  //   id: "46089",
  //   label: "Casinos (Valencia)",
  // },
  // {
  //   municipio: "Castelló de Rugat",
  //   provincia: "Valencia",
  //   id: "46090",
  //   label: "Castelló de Rugat (Valencia)",
  // },
  // {
  //   municipio: "Castellonet de la Conquesta",
  //   provincia: "Valencia",
  //   id: "46091",
  //   label: "Castellonet de la Conquesta (Valencia)",
  // },
  // {
  //   municipio: "Castielfabib",
  //   provincia: "Valencia",
  //   id: "46092",
  //   label: "Castielfabib (Valencia)",
  // },
  // {
  //   municipio: "Catarroja",
  //   provincia: "Valencia",
  //   id: "46094",
  //   label: "Catarroja (Valencia)",
  // },
  // {
  //   municipio: "Caudete de las Fuentes",
  //   provincia: "Valencia",
  //   id: "46095",
  //   label: "Caudete de las Fuentes (Valencia)",
  // },
  // {
  //   municipio: "Faura",
  //   provincia: "Valencia",
  //   id: "46122",
  //   label: "Faura (Valencia)",
  // },
  // {
  //   municipio: "Quartell",
  //   provincia: "Valencia",
  //   id: "46103",
  //   label: "Quartell (Valencia)",
  // },
  // {
  //   municipio: "Quatretonda",
  //   provincia: "Valencia",
  //   id: "46104",
  //   label: "Quatretonda (Valencia)",
  // },
  // {
  //   municipio: "Chera",
  //   provincia: "Valencia",
  //   id: "46108",
  //   label: "Chera (Valencia)",
  // },
  // {
  //   municipio: "Chulilla",
  //   provincia: "Valencia",
  //   id: "46112",
  //   label: "Chulilla (Valencia)",
  // },
  // {
  //   municipio: "Cheste",
  //   provincia: "Valencia",
  //   id: "46109",
  //   label: "Cheste (Valencia)",
  // },
  // {
  //   municipio: "Xirivella",
  //   provincia: "Valencia",
  //   id: "46110",
  //   label: "Xirivella (Valencia)",
  // },
  // {
  //   municipio: "Chiva",
  //   provincia: "Valencia",
  //   id: "46111",
  //   label: "Chiva (Valencia)",
  // },
  // {
  //   municipio: "Daimús",
  //   provincia: "Valencia",
  //   id: "46113",
  //   label: "Daimús (Valencia)",
  // },
  // {
  //   municipio: "Domeño",
  //   provincia: "Valencia",
  //   id: "46114",
  //   label: "Domeño (Valencia)",
  // },
  // {
  //   municipio: "Eliana, l'",
  //   provincia: "Valencia",
  //   id: "46116",
  //   label: "Eliana, l' (Valencia)",
  // },
  // {
  //   municipio: "Emperador",
  //   provincia: "Valencia",
  //   id: "46117",
  //   label: "Emperador (Valencia)",
  // },
  // {
  //   municipio: "Dos Aguas",
  //   provincia: "Valencia",
  //   id: "46115",
  //   label: "Dos Aguas (Valencia)",
  // },
  // {
  //   municipio: "Enguera",
  //   provincia: "Valencia",
  //   id: "46118",
  //   label: "Enguera (Valencia)",
  // },
  // {
  //   municipio: "Énova, l'",
  //   provincia: "Valencia",
  //   id: "46119",
  //   label: "Énova, l' (Valencia)",
  // },
  // {
  //   municipio: "Estivella",
  //   provincia: "Valencia",
  //   id: "46120",
  //   label: "Estivella (Valencia)",
  // },
  // {
  //   municipio: "Fontanars dels Alforins",
  //   provincia: "Valencia",
  //   id: "46124",
  //   label: "Fontanars dels Alforins (Valencia)",
  // },
  // {
  //   municipio: "Fortaleny",
  //   provincia: "Valencia",
  //   id: "46125",
  //   label: "Fortaleny (Valencia)",
  // },
  // {
  //   municipio: "Foios",
  //   provincia: "Valencia",
  //   id: "46126",
  //   label: "Foios (Valencia)",
  // },
  // {
  //   municipio: "Font d'en Carròs, la",
  //   provincia: "Valencia",
  //   id: "46127",
  //   label: "Font d'en Carròs, la (Valencia)",
  // },
  // {
  //   municipio: "Font de la Figuera, la",
  //   provincia: "Valencia",
  //   id: "46128",
  //   label: "Font de la Figuera, la (Valencia)",
  // },
  // {
  //   municipio: "Fuenterrobles",
  //   provincia: "Valencia",
  //   id: "46129",
  //   label: "Fuenterrobles (Valencia)",
  // },
  // {
  //   municipio: "Gavarda",
  //   provincia: "Valencia",
  //   id: "46130",
  //   label: "Gavarda (Valencia)",
  // },
  // {
  //   municipio: "Gandia",
  //   provincia: "Valencia",
  //   id: "46131",
  //   label: "Gandia (Valencia)",
  // },
  // {
  //   municipio: "Barcial de la Loma",
  //   provincia: "Valladolid",
  //   id: "47013",
  //   label: "Barcial de la Loma (Valladolid)",
  // },
  // {
  //   municipio: "Genovés, el",
  //   provincia: "Valencia",
  //   id: "46132",
  //   label: "Genovés, el (Valencia)",
  // },
  // {
  //   municipio: "Gestalgar",
  //   provincia: "Valencia",
  //   id: "46133",
  //   label: "Gestalgar (Valencia)",
  // },
  // {
  //   municipio: "Gilet",
  //   provincia: "Valencia",
  //   id: "46134",
  //   label: "Gilet (Valencia)",
  // },
  // {
  //   municipio: "Godella",
  //   provincia: "Valencia",
  //   id: "46135",
  //   label: "Godella (Valencia)",
  // },
  // {
  //   municipio: "Massanassa",
  //   provincia: "Valencia",
  //   id: "46165",
  //   label: "Massanassa (Valencia)",
  // },
  // {
  //   municipio: "Godelleta",
  //   provincia: "Valencia",
  //   id: "46136",
  //   label: "Godelleta (Valencia)",
  // },
  // {
  //   municipio: "Granja de la Costera, la",
  //   provincia: "Valencia",
  //   id: "46137",
  //   label: "Granja de la Costera, la (Valencia)",
  // },
  // {
  //   municipio: "Guadasséquies",
  //   provincia: "Valencia",
  //   id: "46138",
  //   label: "Guadasséquies (Valencia)",
  // },
  // {
  //   municipio: "Guadassuar",
  //   provincia: "Valencia",
  //   id: "46139",
  //   label: "Guadassuar (Valencia)",
  // },
  // {
  //   municipio: "Guardamar de la Safor",
  //   provincia: "Valencia",
  //   id: "46140",
  //   label: "Guardamar de la Safor (Valencia)",
  // },
  // {
  //   municipio: "Real de Gandia, el",
  //   provincia: "Valencia",
  //   id: "46211",
  //   label: "Real de Gandia, el (Valencia)",
  // },
  // {
  //   municipio: "Higueruelas",
  //   provincia: "Valencia",
  //   id: "46141",
  //   label: "Higueruelas (Valencia)",
  // },
  // {
  //   municipio: "Jalance",
  //   provincia: "Valencia",
  //   id: "46142",
  //   label: "Jalance (Valencia)",
  // },
  // {
  //   municipio: "Real",
  //   provincia: "Valencia",
  //   id: "46212",
  //   label: "Real (Valencia)",
  // },
  // {
  //   municipio: "Xeraco",
  //   provincia: "Valencia",
  //   id: "46143",
  //   label: "Xeraco (Valencia)",
  // },
  // {
  //   municipio: "Meliana",
  //   provincia: "Valencia",
  //   id: "46166",
  //   label: "Meliana (Valencia)",
  // },
  // {
  //   municipio: "Jarafuel",
  //   provincia: "Valencia",
  //   id: "46144",
  //   label: "Jarafuel (Valencia)",
  // },
  // {
  //   municipio: "Xeresa",
  //   provincia: "Valencia",
  //   id: "46146",
  //   label: "Xeresa (Valencia)",
  // },
  // {
  //   municipio: "Llíria",
  //   provincia: "Valencia",
  //   id: "46147",
  //   label: "Llíria (Valencia)",
  // },
  // {
  //   municipio: "Loriguilla",
  //   provincia: "Valencia",
  //   id: "46148",
  //   label: "Loriguilla (Valencia)",
  // },
  // {
  //   municipio: "Llutxent",
  //   provincia: "Valencia",
  //   id: "46150",
  //   label: "Llutxent (Valencia)",
  // },
  // {
  //   municipio: "Llocnou d'En Fenollet",
  //   provincia: "Valencia",
  //   id: "46151",
  //   label: "Llocnou d'En Fenollet (Valencia)",
  // },
  // {
  //   municipio: "Llanera de Ranes",
  //   provincia: "Valencia",
  //   id: "46154",
  //   label: "Llanera de Ranes (Valencia)",
  // },
  // {
  //   municipio: "Llaurí",
  //   provincia: "Valencia",
  //   id: "46155",
  //   label: "Llaurí (Valencia)",
  // },
  // {
  //   municipio: "Llombai",
  //   provincia: "Valencia",
  //   id: "46156",
  //   label: "Llombai (Valencia)",
  // },
  // {
  //   municipio: "Llosa de Ranes, la",
  //   provincia: "Valencia",
  //   id: "46157",
  //   label: "Llosa de Ranes, la (Valencia)",
  // },
  // {
  //   municipio: "Macastre",
  //   provincia: "Valencia",
  //   id: "46158",
  //   label: "Macastre (Valencia)",
  // },
  // {
  //   municipio: "Sant Joanet",
  //   provincia: "Valencia",
  //   id: "46222",
  //   label: "Sant Joanet (Valencia)",
  // },
  // {
  //   municipio: "Manises",
  //   provincia: "Valencia",
  //   id: "46159",
  //   label: "Manises (Valencia)",
  // },
  // {
  //   municipio: "Manuel",
  //   provincia: "Valencia",
  //   id: "46160",
  //   label: "Manuel (Valencia)",
  // },
  // {
  //   municipio: "Marines",
  //   provincia: "Valencia",
  //   id: "46161",
  //   label: "Marines (Valencia)",
  // },
  // {
  //   municipio: "Mislata",
  //   provincia: "Valencia",
  //   id: "46169",
  //   label: "Mislata (Valencia)",
  // },
  // {
  //   municipio: "Massalavés",
  //   provincia: "Valencia",
  //   id: "46162",
  //   label: "Massalavés (Valencia)",
  // },
  // {
  //   municipio: "Massalfassar",
  //   provincia: "Valencia",
  //   id: "46163",
  //   label: "Massalfassar (Valencia)",
  // },
  // {
  //   municipio: "Montaverner",
  //   provincia: "Valencia",
  //   id: "46173",
  //   label: "Montaverner (Valencia)",
  // },
  // {
  //   municipio: "Massamagrell",
  //   provincia: "Valencia",
  //   id: "46164",
  //   label: "Massamagrell (Valencia)",
  // },
  // {
  //   municipio: "Moncada",
  //   provincia: "Valencia",
  //   id: "46171",
  //   label: "Moncada (Valencia)",
  // },
  // {
  //   municipio: "Millares",
  //   provincia: "Valencia",
  //   id: "46167",
  //   label: "Millares (Valencia)",
  // },
  // {
  //   municipio: "Miramar",
  //   provincia: "Valencia",
  //   id: "46168",
  //   label: "Miramar (Valencia)",
  // },
  // {
  //   municipio: "Montesa",
  //   provincia: "Valencia",
  //   id: "46174",
  //   label: "Montesa (Valencia)",
  // },
  // {
  //   municipio: "Montitxelvo/Montichelvo",
  //   provincia: "Valencia",
  //   id: "46175",
  //   label: "Montitxelvo/Montichelvo (Valencia)",
  // },
  // {
  //   municipio: "Montroi/Montroy",
  //   provincia: "Valencia",
  //   id: "46176",
  //   label: "Montroi/Montroy (Valencia)",
  // },
  // {
  //   municipio: "Museros",
  //   provincia: "Valencia",
  //   id: "46177",
  //   label: "Museros (Valencia)",
  // },
  // {
  //   municipio: "Nàquera/Náquera",
  //   provincia: "Valencia",
  //   id: "46178",
  //   label: "Nàquera/Náquera (Valencia)",
  // },
  // {
  //   municipio: "Navarrés",
  //   provincia: "Valencia",
  //   id: "46179",
  //   label: "Navarrés (Valencia)",
  // },
  // {
  //   municipio: "Olocau",
  //   provincia: "Valencia",
  //   id: "46182",
  //   label: "Olocau (Valencia)",
  // },
  // {
  //   municipio: "Olleria, l'",
  //   provincia: "Valencia",
  //   id: "46183",
  //   label: "Olleria, l' (Valencia)",
  // },
  // {
  //   municipio: "Palomar, el",
  //   provincia: "Valencia",
  //   id: "46189",
  //   label: "Palomar, el (Valencia)",
  // },
  // {
  //   municipio: "Ontinyent",
  //   provincia: "Valencia",
  //   id: "46184",
  //   label: "Ontinyent (Valencia)",
  // },
  // {
  //   municipio: "Pedralba",
  //   provincia: "Valencia",
  //   id: "46191",
  //   label: "Pedralba (Valencia)",
  // },
  // {
  //   municipio: "Petrés",
  //   provincia: "Valencia",
  //   id: "46192",
  //   label: "Petrés (Valencia)",
  // },
  // {
  //   municipio: "Otos",
  //   provincia: "Valencia",
  //   id: "46185",
  //   label: "Otos (Valencia)",
  // },
  // {
  //   municipio: "Paiporta",
  //   provincia: "Valencia",
  //   id: "46186",
  //   label: "Paiporta (Valencia)",
  // },
  // {
  //   municipio: "Palma de Gandía",
  //   provincia: "Valencia",
  //   id: "46187",
  //   label: "Palma de Gandía (Valencia)",
  // },
  // {
  //   municipio: "Palmera",
  //   provincia: "Valencia",
  //   id: "46188",
  //   label: "Palmera (Valencia)",
  // },
  // {
  //   municipio: "Picanya",
  //   provincia: "Valencia",
  //   id: "46193",
  //   label: "Picanya (Valencia)",
  // },
  // {
  //   municipio: "Paterna",
  //   provincia: "Valencia",
  //   id: "46190",
  //   label: "Paterna (Valencia)",
  // },
  // {
  //   municipio: "Picassent",
  //   provincia: "Valencia",
  //   id: "46194",
  //   label: "Picassent (Valencia)",
  // },
  // {
  //   municipio: "Piles",
  //   provincia: "Valencia",
  //   id: "46195",
  //   label: "Piles (Valencia)",
  // },
  // {
  //   municipio: "Pinet",
  //   provincia: "Valencia",
  //   id: "46196",
  //   label: "Pinet (Valencia)",
  // },
  // {
  //   municipio: "Polinyà de Xúquer",
  //   provincia: "Valencia",
  //   id: "46197",
  //   label: "Polinyà de Xúquer (Valencia)",
  // },
  // {
  //   municipio: "Potries",
  //   provincia: "Valencia",
  //   id: "46198",
  //   label: "Potries (Valencia)",
  // },
  // {
  //   municipio: "Pobla de Farnals, la",
  //   provincia: "Valencia",
  //   id: "46199",
  //   label: "Pobla de Farnals, la (Valencia)",
  // },
  // {
  //   municipio: "Pobla Llarga, la",
  //   provincia: "Valencia",
  //   id: "46203",
  //   label: "Pobla Llarga, la (Valencia)",
  // },
  // {
  //   municipio: "Pobla del Duc, la",
  //   provincia: "Valencia",
  //   id: "46200",
  //   label: "Pobla del Duc, la (Valencia)",
  // },
  // {
  //   municipio: "Puçol",
  //   provincia: "Valencia",
  //   id: "46205",
  //   label: "Puçol (Valencia)",
  // },
  // {
  //   municipio: "Quesa",
  //   provincia: "Valencia",
  //   id: "46206",
  //   label: "Quesa (Valencia)",
  // },
  // {
  //   municipio: "Puebla de San Miguel",
  //   provincia: "Valencia",
  //   id: "46201",
  //   label: "Puebla de San Miguel (Valencia)",
  // },
  // {
  //   municipio: "Puig de Santa Maria, el",
  //   provincia: "Valencia",
  //   id: "46204",
  //   label: "Puig de Santa Maria, el (Valencia)",
  // },
  // {
  //   municipio: "Rafelbunyol",
  //   provincia: "Valencia",
  //   id: "46207",
  //   label: "Rafelbunyol (Valencia)",
  // },
  // {
  //   municipio: "Rafelguaraf",
  //   provincia: "Valencia",
  //   id: "46209",
  //   label: "Rafelguaraf (Valencia)",
  // },
  // {
  //   municipio: "Ráfol de Salem",
  //   provincia: "Valencia",
  //   id: "46210",
  //   label: "Ráfol de Salem (Valencia)",
  // },
  // {
  //   municipio: "Riba-roja de Túria",
  //   provincia: "Valencia",
  //   id: "46214",
  //   label: "Riba-roja de Túria (Valencia)",
  // },
  // {
  //   municipio: "Riola",
  //   provincia: "Valencia",
  //   id: "46215",
  //   label: "Riola (Valencia)",
  // },
  // {
  //   municipio: "Rocafort",
  //   provincia: "Valencia",
  //   id: "46216",
  //   label: "Rocafort (Valencia)",
  // },
  // {
  //   municipio: "Cerecinos del Carrizal",
  //   provincia: "Zamora",
  //   id: "49047",
  //   label: "Cerecinos del Carrizal (Zamora)",
  // },
  // {
  //   municipio: "Rotglà i Corberà",
  //   provincia: "Valencia",
  //   id: "46217",
  //   label: "Rotglà i Corberà (Valencia)",
  // },
  // {
  //   municipio: "Barruelo del Valle",
  //   provincia: "Valladolid",
  //   id: "47014",
  //   label: "Barruelo del Valle (Valladolid)",
  // },
  // {
  //   municipio: "Ròtova",
  //   provincia: "Valencia",
  //   id: "46218",
  //   label: "Ròtova (Valencia)",
  // },
  // {
  //   municipio: "Rugat",
  //   provincia: "Valencia",
  //   id: "46219",
  //   label: "Rugat (Valencia)",
  // },
  // {
  //   municipio: "Salem",
  //   provincia: "Valencia",
  //   id: "46221",
  //   label: "Salem (Valencia)",
  // },
  // {
  //   municipio: "Sedaví",
  //   provincia: "Valencia",
  //   id: "46223",
  //   label: "Sedaví (Valencia)",
  // },
  // {
  //   municipio: "Segart",
  //   provincia: "Valencia",
  //   id: "46224",
  //   label: "Segart (Valencia)",
  // },
  // {
  //   municipio: "Serra",
  //   provincia: "Valencia",
  //   id: "46228",
  //   label: "Serra (Valencia)",
  // },
  // {
  //   municipio: "Sellent",
  //   provincia: "Valencia",
  //   id: "46225",
  //   label: "Sellent (Valencia)",
  // },
  // {
  //   municipio: "Sempere",
  //   provincia: "Valencia",
  //   id: "46226",
  //   label: "Sempere (Valencia)",
  // },
  // {
  //   municipio: "Senyera",
  //   provincia: "Valencia",
  //   id: "46227",
  //   label: "Senyera (Valencia)",
  // },
  // {
  //   municipio: "Siete Aguas",
  //   provincia: "Valencia",
  //   id: "46229",
  //   label: "Siete Aguas (Valencia)",
  // },
  // {
  //   municipio: "Silla",
  //   provincia: "Valencia",
  //   id: "46230",
  //   label: "Silla (Valencia)",
  // },
  // {
  //   municipio: "Campillo, El",
  //   provincia: "Valladolid",
  //   id: "47031",
  //   label: "Campillo, El (Valladolid)",
  // },
  // {
  //   municipio: "Simat de la Valldigna",
  //   provincia: "Valencia",
  //   id: "46231",
  //   label: "Simat de la Valldigna (Valencia)",
  // },
  // {
  //   municipio: "Terrateig",
  //   provincia: "Valencia",
  //   id: "46240",
  //   label: "Terrateig (Valencia)",
  // },
  // {
  //   municipio: "Sinarcas",
  //   provincia: "Valencia",
  //   id: "46232",
  //   label: "Sinarcas (Valencia)",
  // },
  // {
  //   municipio: "Sot de Chera",
  //   provincia: "Valencia",
  //   id: "46234",
  //   label: "Sot de Chera (Valencia)",
  // },
  // {
  //   municipio: "Sumacàrcer",
  //   provincia: "Valencia",
  //   id: "46236",
  //   label: "Sumacàrcer (Valencia)",
  // },
  // {
  //   municipio: "Tavernes Blanques",
  //   provincia: "Valencia",
  //   id: "46237",
  //   label: "Tavernes Blanques (Valencia)",
  // },
  // {
  //   municipio: "Titaguas",
  //   provincia: "Valencia",
  //   id: "46241",
  //   label: "Titaguas (Valencia)",
  // },
  // {
  //   municipio: "Tavernes de la Valldigna",
  //   provincia: "Valencia",
  //   id: "46238",
  //   label: "Tavernes de la Valldigna (Valencia)",
  // },
  // {
  //   municipio: "Teresa de Cofrentes",
  //   provincia: "Valencia",
  //   id: "46239",
  //   label: "Teresa de Cofrentes (Valencia)",
  // },
  // {
  //   municipio: "Camporredondo",
  //   provincia: "Valladolid",
  //   id: "47032",
  //   label: "Camporredondo (Valladolid)",
  // },
  // {
  //   municipio: "Torrebaja",
  //   provincia: "Valencia",
  //   id: "46242",
  //   label: "Torrebaja (Valencia)",
  // },
  // {
  //   municipio: "Torrella",
  //   provincia: "Valencia",
  //   id: "46243",
  //   label: "Torrella (Valencia)",
  // },
  // {
  //   municipio: "Torrent",
  //   provincia: "Valencia",
  //   id: "46244",
  //   label: "Torrent (Valencia)",
  // },
  // {
  //   municipio: "Torres Torres",
  //   provincia: "Valencia",
  //   id: "46245",
  //   label: "Torres Torres (Valencia)",
  // },
  // {
  //   municipio: "Tous",
  //   provincia: "Valencia",
  //   id: "46246",
  //   label: "Tous (Valencia)",
  // },
  // {
  //   municipio: "Cotanes del Monte",
  //   provincia: "Zamora",
  //   id: "49055",
  //   label: "Cotanes del Monte (Zamora)",
  // },
  // {
  //   municipio: "Tuéjar",
  //   provincia: "Valencia",
  //   id: "46247",
  //   label: "Tuéjar (Valencia)",
  // },
  // {
  //   municipio: "Castrillo de Duero",
  //   provincia: "Valladolid",
  //   id: "47038",
  //   label: "Castrillo de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Castrillo-Tejeriego",
  //   provincia: "Valladolid",
  //   id: "47039",
  //   label: "Castrillo-Tejeriego (Valladolid)",
  // },
  // {
  //   municipio: "Turís",
  //   provincia: "Valencia",
  //   id: "46248",
  //   label: "Turís (Valencia)",
  // },
  // {
  //   municipio: "Yátova",
  //   provincia: "Valencia",
  //   id: "46261",
  //   label: "Yátova (Valencia)",
  // },
  // {
  //   municipio: "Utiel",
  //   provincia: "Valencia",
  //   id: "46249",
  //   label: "Utiel (Valencia)",
  // },
  // {
  //   municipio: "Vallada",
  //   provincia: "Valencia",
  //   id: "46251",
  //   label: "Vallada (Valencia)",
  // },
  // {
  //   municipio: "Vallanca",
  //   provincia: "Valencia",
  //   id: "46252",
  //   label: "Vallanca (Valencia)",
  // },
  // {
  //   municipio: "Vallés",
  //   provincia: "Valencia",
  //   id: "46253",
  //   label: "Vallés (Valencia)",
  // },
  // {
  //   municipio: "Villargordo del Cabriel",
  //   provincia: "Valencia",
  //   id: "46259",
  //   label: "Villargordo del Cabriel (Valencia)",
  // },
  // {
  //   municipio: "Vilallonga/Villalonga",
  //   provincia: "Valencia",
  //   id: "46255",
  //   label: "Vilallonga/Villalonga (Valencia)",
  // },
  // {
  //   municipio: "Vinalesa",
  //   provincia: "Valencia",
  //   id: "46260",
  //   label: "Vinalesa (Valencia)",
  // },
  // {
  //   municipio: "Castelló",
  //   provincia: "Valencia",
  //   id: "46257",
  //   label: "Castelló (Valencia)",
  // },
  // {
  //   municipio: "Villar del Arzobispo",
  //   provincia: "Valencia",
  //   id: "46258",
  //   label: "Villar del Arzobispo (Valencia)",
  // },
  // {
  //   municipio: "Yesa, La",
  //   provincia: "Valencia",
  //   id: "46262",
  //   label: "Yesa, La (Valencia)",
  // },
  // {
  //   municipio: "Zarra",
  //   provincia: "Valencia",
  //   id: "46263",
  //   label: "Zarra (Valencia)",
  // },
  // {
  //   municipio: "San Antonio de Benagéber",
  //   provincia: "Valencia",
  //   id: "46903",
  //   label: "San Antonio de Benagéber (Valencia)",
  // },
  // {
  //   municipio: "Benicull de Xúquer",
  //   provincia: "Valencia",
  //   id: "46904",
  //   label: "Benicull de Xúquer (Valencia)",
  // },
  // {
  //   municipio: "Adalia",
  //   provincia: "Valladolid",
  //   id: "47001",
  //   label: "Adalia (Valladolid)",
  // },
  // {
  //   municipio: "Aguasal",
  //   provincia: "Valladolid",
  //   id: "47002",
  //   label: "Aguasal (Valladolid)",
  // },
  // {
  //   municipio: "Aguilar de Campos",
  //   provincia: "Valladolid",
  //   id: "47003",
  //   label: "Aguilar de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Alaejos",
  //   provincia: "Valladolid",
  //   id: "47004",
  //   label: "Alaejos (Valladolid)",
  // },
  // {
  //   municipio: "Castrodeza",
  //   provincia: "Valladolid",
  //   id: "47041",
  //   label: "Castrodeza (Valladolid)",
  // },
  // {
  //   municipio: "Alcazarén",
  //   provincia: "Valladolid",
  //   id: "47005",
  //   label: "Alcazarén (Valladolid)",
  // },
  // {
  //   municipio: "Aldea de San Miguel",
  //   provincia: "Valladolid",
  //   id: "47006",
  //   label: "Aldea de San Miguel (Valladolid)",
  // },
  // {
  //   municipio: "Castromembibre",
  //   provincia: "Valladolid",
  //   id: "47042",
  //   label: "Castromembibre (Valladolid)",
  // },
  // {
  //   municipio: "Aldeamayor de San Martín",
  //   provincia: "Valladolid",
  //   id: "47007",
  //   label: "Aldeamayor de San Martín (Valladolid)",
  // },
  // {
  //   municipio: "Ceinos de Campos",
  //   provincia: "Valladolid",
  //   id: "47048",
  //   label: "Ceinos de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Becilla de Valderaduey",
  //   provincia: "Valladolid",
  //   id: "47015",
  //   label: "Becilla de Valderaduey (Valladolid)",
  // },
  // {
  //   municipio: "Cigales",
  //   provincia: "Valladolid",
  //   id: "47050",
  //   label: "Cigales (Valladolid)",
  // },
  // {
  //   municipio: "Almenara de Adaja",
  //   provincia: "Valladolid",
  //   id: "47008",
  //   label: "Almenara de Adaja (Valladolid)",
  // },
  // {
  //   municipio: "Amusquillo",
  //   provincia: "Valladolid",
  //   id: "47009",
  //   label: "Amusquillo (Valladolid)",
  // },
  // {
  //   municipio: "Arroyo de la Encomienda",
  //   provincia: "Valladolid",
  //   id: "47010",
  //   label: "Arroyo de la Encomienda (Valladolid)",
  // },
  // {
  //   municipio: "Ataquines",
  //   provincia: "Valladolid",
  //   id: "47011",
  //   label: "Ataquines (Valladolid)",
  // },
  // {
  //   municipio: "Bercero",
  //   provincia: "Valladolid",
  //   id: "47017",
  //   label: "Bercero (Valladolid)",
  // },
  // {
  //   municipio: "Ciguñuela",
  //   provincia: "Valladolid",
  //   id: "47051",
  //   label: "Ciguñuela (Valladolid)",
  // },
  // {
  //   municipio: "Berceruelo",
  //   provincia: "Valladolid",
  //   id: "47018",
  //   label: "Berceruelo (Valladolid)",
  // },
  // {
  //   municipio: "Berrueces",
  //   provincia: "Valladolid",
  //   id: "47019",
  //   label: "Berrueces (Valladolid)",
  // },
  // {
  //   municipio: "Bobadilla del Campo",
  //   provincia: "Valladolid",
  //   id: "47020",
  //   label: "Bobadilla del Campo (Valladolid)",
  // },
  // {
  //   municipio: "Bocigas",
  //   provincia: "Valladolid",
  //   id: "47021",
  //   label: "Bocigas (Valladolid)",
  // },
  // {
  //   municipio: "Boecillo",
  //   provincia: "Valladolid",
  //   id: "47023",
  //   label: "Boecillo (Valladolid)",
  // },
  // {
  //   municipio: "Bolaños de Campos",
  //   provincia: "Valladolid",
  //   id: "47024",
  //   label: "Bolaños de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Canalejas de Peñafiel",
  //   provincia: "Valladolid",
  //   id: "47033",
  //   label: "Canalejas de Peñafiel (Valladolid)",
  // },
  // {
  //   municipio: "Cabezón de Pisuerga",
  //   provincia: "Valladolid",
  //   id: "47027",
  //   label: "Cabezón de Pisuerga (Valladolid)",
  // },
  // {
  //   municipio: "Cervillego de la Cruz",
  //   provincia: "Valladolid",
  //   id: "47049",
  //   label: "Cervillego de la Cruz (Valladolid)",
  // },
  // {
  //   municipio: "Cabezón de Valderaduey",
  //   provincia: "Valladolid",
  //   id: "47028",
  //   label: "Cabezón de Valderaduey (Valladolid)",
  // },
  // {
  //   municipio: "Cabreros del Monte",
  //   provincia: "Valladolid",
  //   id: "47029",
  //   label: "Cabreros del Monte (Valladolid)",
  // },
  // {
  //   municipio: "Canillas de Esgueva",
  //   provincia: "Valladolid",
  //   id: "47034",
  //   label: "Canillas de Esgueva (Valladolid)",
  // },
  // {
  //   municipio: "Carpio",
  //   provincia: "Valladolid",
  //   id: "47035",
  //   label: "Carpio (Valladolid)",
  // },
  // {
  //   municipio: "Cubillas de Santa Marta",
  //   provincia: "Valladolid",
  //   id: "47057",
  //   label: "Cubillas de Santa Marta (Valladolid)",
  // },
  // {
  //   municipio: "Casasola de Arión",
  //   provincia: "Valladolid",
  //   id: "47036",
  //   label: "Casasola de Arión (Valladolid)",
  // },
  // {
  //   municipio: "Castrejón de Trabancos",
  //   provincia: "Valladolid",
  //   id: "47037",
  //   label: "Castrejón de Trabancos (Valladolid)",
  // },
  // {
  //   municipio: "Castrobol",
  //   provincia: "Valladolid",
  //   id: "47040",
  //   label: "Castrobol (Valladolid)",
  // },
  // {
  //   municipio: "Castromonte",
  //   provincia: "Valladolid",
  //   id: "47043",
  //   label: "Castromonte (Valladolid)",
  // },
  // {
  //   municipio: "Castronuevo de Esgueva",
  //   provincia: "Valladolid",
  //   id: "47044",
  //   label: "Castronuevo de Esgueva (Valladolid)",
  // },
  // {
  //   municipio: "Cuenca de Campos",
  //   provincia: "Valladolid",
  //   id: "47058",
  //   label: "Cuenca de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Castronuño",
  //   provincia: "Valladolid",
  //   id: "47045",
  //   label: "Castronuño (Valladolid)",
  // },
  // {
  //   municipio: "Castroponce",
  //   provincia: "Valladolid",
  //   id: "47046",
  //   label: "Castroponce (Valladolid)",
  // },
  // {
  //   municipio: "Castroverde de Cerrato",
  //   provincia: "Valladolid",
  //   id: "47047",
  //   label: "Castroverde de Cerrato (Valladolid)",
  // },
  // {
  //   municipio: "Corcos",
  //   provincia: "Valladolid",
  //   id: "47055",
  //   label: "Corcos (Valladolid)",
  // },
  // {
  //   municipio: "Cistérniga",
  //   provincia: "Valladolid",
  //   id: "47052",
  //   label: "Cistérniga (Valladolid)",
  // },
  // {
  //   municipio: "Cogeces de Íscar",
  //   provincia: "Valladolid",
  //   id: "47053",
  //   label: "Cogeces de Íscar (Valladolid)",
  // },
  // {
  //   municipio: "Cogeces del Monte",
  //   provincia: "Valladolid",
  //   id: "47054",
  //   label: "Cogeces del Monte (Valladolid)",
  // },
  // {
  //   municipio: "Corrales de Duero",
  //   provincia: "Valladolid",
  //   id: "47056",
  //   label: "Corrales de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Curiel de Duero",
  //   provincia: "Valladolid",
  //   id: "47059",
  //   label: "Curiel de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Encinas de Esgueva",
  //   provincia: "Valladolid",
  //   id: "47060",
  //   label: "Encinas de Esgueva (Valladolid)",
  // },
  // {
  //   municipio: "Esguevillas de Esgueva",
  //   provincia: "Valladolid",
  //   id: "47061",
  //   label: "Esguevillas de Esgueva (Valladolid)",
  // },
  // {
  //   municipio: "Melgar de Arriba",
  //   provincia: "Valladolid",
  //   id: "47089",
  //   label: "Melgar de Arriba (Valladolid)",
  // },
  // {
  //   municipio: "Monasterio de Vega",
  //   provincia: "Valladolid",
  //   id: "47091",
  //   label: "Monasterio de Vega (Valladolid)",
  // },
  // {
  //   municipio: "Fombellida",
  //   provincia: "Valladolid",
  //   id: "47062",
  //   label: "Fombellida (Valladolid)",
  // },
  // {
  //   municipio: "Fompedraza",
  //   provincia: "Valladolid",
  //   id: "47063",
  //   label: "Fompedraza (Valladolid)",
  // },
  // {
  //   municipio: "Fuente-Olmedo",
  //   provincia: "Valladolid",
  //   id: "47068",
  //   label: "Fuente-Olmedo (Valladolid)",
  // },
  // {
  //   municipio: "Mojados",
  //   provincia: "Valladolid",
  //   id: "47090",
  //   label: "Mojados (Valladolid)",
  // },
  // {
  //   municipio: "Fontihoyuelo",
  //   provincia: "Valladolid",
  //   id: "47064",
  //   label: "Fontihoyuelo (Valladolid)",
  // },
  // {
  //   municipio: "Gallegos de Hornija",
  //   provincia: "Valladolid",
  //   id: "47069",
  //   label: "Gallegos de Hornija (Valladolid)",
  // },
  // {
  //   municipio: "Fresno el Viejo",
  //   provincia: "Valladolid",
  //   id: "47065",
  //   label: "Fresno el Viejo (Valladolid)",
  // },
  // {
  //   municipio: "Fuensaldaña",
  //   provincia: "Valladolid",
  //   id: "47066",
  //   label: "Fuensaldaña (Valladolid)",
  // },
  // {
  //   municipio: "Fuente el Sol",
  //   provincia: "Valladolid",
  //   id: "47067",
  //   label: "Fuente el Sol (Valladolid)",
  // },
  // {
  //   municipio: "Megeces",
  //   provincia: "Valladolid",
  //   id: "47087",
  //   label: "Megeces (Valladolid)",
  // },
  // {
  //   municipio: "Hornillos de Eresma",
  //   provincia: "Valladolid",
  //   id: "47074",
  //   label: "Hornillos de Eresma (Valladolid)",
  // },
  // {
  //   municipio: "Langayo",
  //   provincia: "Valladolid",
  //   id: "47077",
  //   label: "Langayo (Valladolid)",
  // },
  // {
  //   municipio: "Íscar",
  //   provincia: "Valladolid",
  //   id: "47075",
  //   label: "Íscar (Valladolid)",
  // },
  // {
  //   municipio: "Melgar de Abajo",
  //   provincia: "Valladolid",
  //   id: "47088",
  //   label: "Melgar de Abajo (Valladolid)",
  // },
  // {
  //   municipio: "Lomoviejo",
  //   provincia: "Valladolid",
  //   id: "47078",
  //   label: "Lomoviejo (Valladolid)",
  // },
  // {
  //   municipio: "Laguna de Duero",
  //   provincia: "Valladolid",
  //   id: "47076",
  //   label: "Laguna de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Mudarra, La",
  //   provincia: "Valladolid",
  //   id: "47099",
  //   label: "Mudarra, La (Valladolid)",
  // },
  // {
  //   municipio: "Montealegre de Campos",
  //   provincia: "Valladolid",
  //   id: "47092",
  //   label: "Montealegre de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Montemayor de Pililla",
  //   provincia: "Valladolid",
  //   id: "47093",
  //   label: "Montemayor de Pililla (Valladolid)",
  // },
  // {
  //   municipio: "Llano de Olmedo",
  //   provincia: "Valladolid",
  //   id: "47079",
  //   label: "Llano de Olmedo (Valladolid)",
  // },
  // {
  //   municipio: "Marzales",
  //   provincia: "Valladolid",
  //   id: "47081",
  //   label: "Marzales (Valladolid)",
  // },
  // {
  //   municipio: "Matapozuelos",
  //   provincia: "Valladolid",
  //   id: "47082",
  //   label: "Matapozuelos (Valladolid)",
  // },
  // {
  //   municipio: "Matilla de los Caños",
  //   provincia: "Valladolid",
  //   id: "47083",
  //   label: "Matilla de los Caños (Valladolid)",
  // },
  // {
  //   municipio: "Mayorga",
  //   provincia: "Valladolid",
  //   id: "47084",
  //   label: "Mayorga (Valladolid)",
  // },
  {
    municipio: "Medina del Campo",
    provincia: "Valladolid",
    id: "47085",
    label: "Medina del Campo (Valladolid)",
  },
  // {
  //   municipio: "Olmos de Esgueva",
  //   provincia: "Valladolid",
  //   id: "47105",
  //   label: "Olmos de Esgueva (Valladolid)",
  // },
  // {
  //   municipio: "Medina de Rioseco",
  //   provincia: "Valladolid",
  //   id: "47086",
  //   label: "Medina de Rioseco (Valladolid)",
  // },
  // {
  //   municipio: "Moral de la Reina",
  //   provincia: "Valladolid",
  //   id: "47094",
  //   label: "Moral de la Reina (Valladolid)",
  // },
  // {
  //   municipio: "Moraleja de las Panaderas",
  //   provincia: "Valladolid",
  //   id: "47095",
  //   label: "Moraleja de las Panaderas (Valladolid)",
  // },
  // {
  //   municipio: "Morales de Campos",
  //   provincia: "Valladolid",
  //   id: "47096",
  //   label: "Morales de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Valdenebro de los Valles",
  //   provincia: "Valladolid",
  //   id: "47181",
  //   label: "Valdenebro de los Valles (Valladolid)",
  // },
  // {
  //   municipio: "Mota del Marqués",
  //   provincia: "Valladolid",
  //   id: "47097",
  //   label: "Mota del Marqués (Valladolid)",
  // },
  // {
  //   municipio: "Mucientes",
  //   provincia: "Valladolid",
  //   id: "47098",
  //   label: "Mucientes (Valladolid)",
  // },
  // {
  //   municipio: "Nava del Rey",
  //   provincia: "Valladolid",
  //   id: "47101",
  //   label: "Nava del Rey (Valladolid)",
  // },
  // {
  //   municipio: "Nueva Villa de las Torres",
  //   provincia: "Valladolid",
  //   id: "47102",
  //   label: "Nueva Villa de las Torres (Valladolid)",
  // },
  // {
  //   municipio: "Olivares de Duero",
  //   provincia: "Valladolid",
  //   id: "47103",
  //   label: "Olivares de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Olmos de Peñafiel",
  //   provincia: "Valladolid",
  //   id: "47106",
  //   label: "Olmos de Peñafiel (Valladolid)",
  // },
  // {
  //   municipio: "Torrecilla de la Torre",
  //   provincia: "Valladolid",
  //   id: "47168",
  //   label: "Torrecilla de la Torre (Valladolid)",
  // },
  // {
  //   municipio: "Olmedo",
  //   provincia: "Valladolid",
  //   id: "47104",
  //   label: "Olmedo (Valladolid)",
  // },
  // {
  //   municipio: "Palazuelo de Vedija",
  //   provincia: "Valladolid",
  //   id: "47109",
  //   label: "Palazuelo de Vedija (Valladolid)",
  // },
  // {
  //   municipio: "Parrilla, La",
  //   provincia: "Valladolid",
  //   id: "47110",
  //   label: "Parrilla, La (Valladolid)",
  // },
  // {
  //   municipio: "San Pedro de Latarce",
  //   provincia: "Valladolid",
  //   id: "47148",
  //   label: "San Pedro de Latarce (Valladolid)",
  // },
  // {
  //   municipio: "Pedraja de Portillo, La",
  //   provincia: "Valladolid",
  //   id: "47111",
  //   label: "Pedraja de Portillo, La (Valladolid)",
  // },
  // {
  //   municipio: "Pedrajas de San Esteban",
  //   provincia: "Valladolid",
  //   id: "47112",
  //   label: "Pedrajas de San Esteban (Valladolid)",
  // },
  // {
  //   municipio: "Rábano",
  //   provincia: "Valladolid",
  //   id: "47131",
  //   label: "Rábano (Valladolid)",
  // },
  // {
  //   municipio: "Pedrosa del Rey",
  //   provincia: "Valladolid",
  //   id: "47113",
  //   label: "Pedrosa del Rey (Valladolid)",
  // },
  // {
  //   municipio: "Peñaflor de Hornija",
  //   provincia: "Valladolid",
  //   id: "47115",
  //   label: "Peñaflor de Hornija (Valladolid)",
  // },
  // {
  //   municipio: "Peñafiel",
  //   provincia: "Valladolid",
  //   id: "47114",
  //   label: "Peñafiel (Valladolid)",
  // },
  // {
  //   municipio: "Quintanilla de Arriba",
  //   provincia: "Valladolid",
  //   id: "47127",
  //   label: "Quintanilla de Arriba (Valladolid)",
  // },
  // {
  //   municipio: "Pesquera de Duero",
  //   provincia: "Valladolid",
  //   id: "47116",
  //   label: "Pesquera de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Piña de Esgueva",
  //   provincia: "Valladolid",
  //   id: "47117",
  //   label: "Piña de Esgueva (Valladolid)",
  // },
  // {
  //   municipio: "Quintanilla del Molar",
  //   provincia: "Valladolid",
  //   id: "47128",
  //   label: "Quintanilla del Molar (Valladolid)",
  // },
  // {
  //   municipio: "Piñel de Abajo",
  //   provincia: "Valladolid",
  //   id: "47118",
  //   label: "Piñel de Abajo (Valladolid)",
  // },
  // {
  //   municipio: "Piñel de Arriba",
  //   provincia: "Valladolid",
  //   id: "47119",
  //   label: "Piñel de Arriba (Valladolid)",
  // },
  // {
  //   municipio: "Ramiro",
  //   provincia: "Valladolid",
  //   id: "47132",
  //   label: "Ramiro (Valladolid)",
  // },
  // {
  //   municipio: "Pollos",
  //   provincia: "Valladolid",
  //   id: "47121",
  //   label: "Pollos (Valladolid)",
  // },
  // {
  //   municipio: "Pozaldez",
  //   provincia: "Valladolid",
  //   id: "47124",
  //   label: "Pozaldez (Valladolid)",
  // },
  // {
  //   municipio: "Portillo",
  //   provincia: "Valladolid",
  //   id: "47122",
  //   label: "Portillo (Valladolid)",
  // },
  // {
  //   municipio: "Pozal de Gallinas",
  //   provincia: "Valladolid",
  //   id: "47123",
  //   label: "Pozal de Gallinas (Valladolid)",
  // },
  // {
  //   municipio: "San Román de Hornija",
  //   provincia: "Valladolid",
  //   id: "47150",
  //   label: "San Román de Hornija (Valladolid)",
  // },
  // {
  //   municipio: "San Salvador",
  //   provincia: "Valladolid",
  //   id: "47151",
  //   label: "San Salvador (Valladolid)",
  // },
  // {
  //   municipio: "Valdestillas",
  //   provincia: "Valladolid",
  //   id: "47182",
  //   label: "Valdestillas (Valladolid)",
  // },
  // {
  //   municipio: "Quintanilla de Onésimo",
  //   provincia: "Valladolid",
  //   id: "47129",
  //   label: "Quintanilla de Onésimo (Valladolid)",
  // },
  // {
  //   municipio: "Quintanilla de Trigueros",
  //   provincia: "Valladolid",
  //   id: "47130",
  //   label: "Quintanilla de Trigueros (Valladolid)",
  // },
  // {
  //   municipio: "Renedo de Esgueva",
  //   provincia: "Valladolid",
  //   id: "47133",
  //   label: "Renedo de Esgueva (Valladolid)",
  // },
  // {
  //   municipio: "Roales de Campos",
  //   provincia: "Valladolid",
  //   id: "47134",
  //   label: "Roales de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Robladillo",
  //   provincia: "Valladolid",
  //   id: "47135",
  //   label: "Robladillo (Valladolid)",
  // },
  // {
  //   municipio: "Roturas",
  //   provincia: "Valladolid",
  //   id: "47137",
  //   label: "Roturas (Valladolid)",
  // },
  // {
  //   municipio: "Rubí de Bracamonte",
  //   provincia: "Valladolid",
  //   id: "47138",
  //   label: "Rubí de Bracamonte (Valladolid)",
  // },
  // {
  //   municipio: "Rueda",
  //   provincia: "Valladolid",
  //   id: "47139",
  //   label: "Rueda (Valladolid)",
  // },
  // {
  //   municipio: "Salvador de Zapardiel",
  //   provincia: "Valladolid",
  //   id: "47141",
  //   label: "Salvador de Zapardiel (Valladolid)",
  // },
  // {
  //   municipio: "San Cebrián de Mazote",
  //   provincia: "Valladolid",
  //   id: "47142",
  //   label: "San Cebrián de Mazote (Valladolid)",
  // },
  // {
  //   municipio: "San Pelayo",
  //   provincia: "Valladolid",
  //   id: "47149",
  //   label: "San Pelayo (Valladolid)",
  // },
  // {
  //   municipio: "San Llorente",
  //   provincia: "Valladolid",
  //   id: "47143",
  //   label: "San Llorente (Valladolid)",
  // },
  // {
  //   municipio: "San Martín de Valvení",
  //   provincia: "Valladolid",
  //   id: "47144",
  //   label: "San Martín de Valvení (Valladolid)",
  // },
  // {
  //   municipio: "San Miguel del Arroyo",
  //   provincia: "Valladolid",
  //   id: "47145",
  //   label: "San Miguel del Arroyo (Valladolid)",
  // },
  // {
  //   municipio: "San Miguel del Pino",
  //   provincia: "Valladolid",
  //   id: "47146",
  //   label: "San Miguel del Pino (Valladolid)",
  // },
  // {
  //   municipio: "San Pablo de la Moraleja",
  //   provincia: "Valladolid",
  //   id: "47147",
  //   label: "San Pablo de la Moraleja (Valladolid)",
  // },
  // {
  //   municipio: "Santervás de Campos",
  //   provincia: "Valladolid",
  //   id: "47153",
  //   label: "Santervás de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Santibáñez de Valcorba",
  //   provincia: "Valladolid",
  //   id: "47154",
  //   label: "Santibáñez de Valcorba (Valladolid)",
  // },
  // {
  //   municipio: "Santovenia de Pisuerga",
  //   provincia: "Valladolid",
  //   id: "47155",
  //   label: "Santovenia de Pisuerga (Valladolid)",
  // },
  // {
  //   municipio: "San Vicente del Palacio",
  //   provincia: "Valladolid",
  //   id: "47156",
  //   label: "San Vicente del Palacio (Valladolid)",
  // },
  // {
  //   municipio: "Sardón de Duero",
  //   provincia: "Valladolid",
  //   id: "47157",
  //   label: "Sardón de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Unión de Campos, La",
  //   provincia: "Valladolid",
  //   id: "47176",
  //   label: "Unión de Campos, La (Valladolid)",
  // },
  // {
  //   municipio: "Seca, La",
  //   provincia: "Valladolid",
  //   id: "47158",
  //   label: "Seca, La (Valladolid)",
  // },
  // {
  //   municipio: "Serrada",
  //   provincia: "Valladolid",
  //   id: "47159",
  //   label: "Serrada (Valladolid)",
  // },
  // {
  //   municipio: "Urones de Castroponce",
  //   provincia: "Valladolid",
  //   id: "47177",
  //   label: "Urones de Castroponce (Valladolid)",
  // },
  // {
  //   municipio: "Siete Iglesias de Trabancos",
  //   provincia: "Valladolid",
  //   id: "47160",
  //   label: "Siete Iglesias de Trabancos (Valladolid)",
  // },
  // {
  //   municipio: "Tamariz de Campos",
  //   provincia: "Valladolid",
  //   id: "47162",
  //   label: "Tamariz de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Simancas",
  //   provincia: "Valladolid",
  //   id: "47161",
  //   label: "Simancas (Valladolid)",
  // },
  // {
  //   municipio: "Jambrina",
  //   provincia: "Zamora",
  //   id: "49096",
  //   label: "Jambrina (Zamora)",
  // },
  // {
  //   municipio: "Tiedra",
  //   provincia: "Valladolid",
  //   id: "47163",
  //   label: "Tiedra (Valladolid)",
  // },
  // {
  //   municipio: "Tordehumos",
  //   provincia: "Valladolid",
  //   id: "47164",
  //   label: "Tordehumos (Valladolid)",
  // },
  // {
  //   municipio: "Tordesillas",
  //   provincia: "Valladolid",
  //   id: "47165",
  //   label: "Tordesillas (Valladolid)",
  // },
  // {
  //   municipio: "Torrecilla de la Abadesa",
  //   provincia: "Valladolid",
  //   id: "47166",
  //   label: "Torrecilla de la Abadesa (Valladolid)",
  // },
  // {
  //   municipio: "Torrecilla de la Orden",
  //   provincia: "Valladolid",
  //   id: "47167",
  //   label: "Torrecilla de la Orden (Valladolid)",
  // },
  // {
  //   municipio: "Torre de Esgueva",
  //   provincia: "Valladolid",
  //   id: "47169",
  //   label: "Torre de Esgueva (Valladolid)",
  // },
  // {
  //   municipio: "Torre de Peñafiel",
  //   provincia: "Valladolid",
  //   id: "47170",
  //   label: "Torre de Peñafiel (Valladolid)",
  // },
  // {
  //   municipio: "Torrelobatón",
  //   provincia: "Valladolid",
  //   id: "47171",
  //   label: "Torrelobatón (Valladolid)",
  // },
  // {
  //   municipio: "Trigueros del Valle",
  //   provincia: "Valladolid",
  //   id: "47174",
  //   label: "Trigueros del Valle (Valladolid)",
  // },
  // {
  //   municipio: "Torrescárcela",
  //   provincia: "Valladolid",
  //   id: "47172",
  //   label: "Torrescárcela (Valladolid)",
  // },
  // {
  //   municipio: "Traspinedo",
  //   provincia: "Valladolid",
  //   id: "47173",
  //   label: "Traspinedo (Valladolid)",
  // },
  // {
  //   municipio: "Justel",
  //   provincia: "Zamora",
  //   id: "49097",
  //   label: "Justel (Zamora)",
  // },
  // {
  //   municipio: "Tudela de Duero",
  //   provincia: "Valladolid",
  //   id: "47175",
  //   label: "Tudela de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Valbuena de Duero",
  //   provincia: "Valladolid",
  //   id: "47179",
  //   label: "Valbuena de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Valdearcos de la Vega",
  //   provincia: "Valladolid",
  //   id: "47180",
  //   label: "Valdearcos de la Vega (Valladolid)",
  // },
  // {
  //   municipio: "Valdunquillo",
  //   provincia: "Valladolid",
  //   id: "47183",
  //   label: "Valdunquillo (Valladolid)",
  // },
  // {
  //   municipio: "Vega de Valdetronco",
  //   provincia: "Valladolid",
  //   id: "47188",
  //   label: "Vega de Valdetronco (Valladolid)",
  // },
  // {
  //   municipio: "Valoria la Buena",
  //   provincia: "Valladolid",
  //   id: "47184",
  //   label: "Valoria la Buena (Valladolid)",
  // },
  // {
  //   municipio: "Valverde de Campos",
  //   provincia: "Valladolid",
  //   id: "47185",
  //   label: "Valverde de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Vega de Ruiponce",
  //   provincia: "Valladolid",
  //   id: "47187",
  //   label: "Vega de Ruiponce (Valladolid)",
  // },
  // {
  //   municipio: "Velilla",
  //   provincia: "Valladolid",
  //   id: "47190",
  //   label: "Velilla (Valladolid)",
  // },
  // {
  //   municipio: "Velliza",
  //   provincia: "Valladolid",
  //   id: "47191",
  //   label: "Velliza (Valladolid)",
  // },
  // {
  //   municipio: "Ventosa de la Cuesta",
  //   provincia: "Valladolid",
  //   id: "47192",
  //   label: "Ventosa de la Cuesta (Valladolid)",
  // },
  // {
  //   municipio: "Viana de Cega",
  //   provincia: "Valladolid",
  //   id: "47193",
  //   label: "Viana de Cega (Valladolid)",
  // },
  // {
  //   municipio: "Viloria",
  //   provincia: "Valladolid",
  //   id: "47194",
  //   label: "Viloria (Valladolid)",
  // },
  // {
  //   municipio: "Villanueva de los Caballeros",
  //   provincia: "Valladolid",
  //   id: "47220",
  //   label: "Villanueva de los Caballeros (Valladolid)",
  // },
  // {
  //   municipio: "Villabáñez",
  //   provincia: "Valladolid",
  //   id: "47195",
  //   label: "Villabáñez (Valladolid)",
  // },
  // {
  //   municipio: "Villafrechós",
  //   provincia: "Valladolid",
  //   id: "47205",
  //   label: "Villafrechós (Valladolid)",
  // },
  // {
  //   municipio: "Villafuerte",
  //   provincia: "Valladolid",
  //   id: "47206",
  //   label: "Villafuerte (Valladolid)",
  // },
  // {
  //   municipio: "Villabaruz de Campos",
  //   provincia: "Valladolid",
  //   id: "47196",
  //   label: "Villabaruz de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Villabrágima",
  //   provincia: "Valladolid",
  //   id: "47197",
  //   label: "Villabrágima (Valladolid)",
  // },
  // {
  //   municipio: "Villaco",
  //   provincia: "Valladolid",
  //   id: "47200",
  //   label: "Villaco (Valladolid)",
  // },
  // {
  //   municipio: "Villafrades de Campos",
  //   provincia: "Valladolid",
  //   id: "47203",
  //   label: "Villafrades de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Villafranca de Duero",
  //   provincia: "Valladolid",
  //   id: "47204",
  //   label: "Villafranca de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Villanueva de los Infantes",
  //   provincia: "Valladolid",
  //   id: "47221",
  //   label: "Villanueva de los Infantes (Valladolid)",
  // },
  // {
  //   municipio: "Villagarcía de Campos",
  //   provincia: "Valladolid",
  //   id: "47207",
  //   label: "Villagarcía de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Villagómez la Nueva",
  //   provincia: "Valladolid",
  //   id: "47208",
  //   label: "Villagómez la Nueva (Valladolid)",
  // },
  // {
  //   municipio: "Villalán de Campos",
  //   provincia: "Valladolid",
  //   id: "47209",
  //   label: "Villalán de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Villanueva de San Mancio",
  //   provincia: "Valladolid",
  //   id: "47222",
  //   label: "Villanueva de San Mancio (Valladolid)",
  // },
  // {
  //   municipio: "Villalar de los Comuneros",
  //   provincia: "Valladolid",
  //   id: "47210",
  //   label: "Villalar de los Comuneros (Valladolid)",
  // },
  // {
  //   municipio: "Villalba de la Loma",
  //   provincia: "Valladolid",
  //   id: "47211",
  //   label: "Villalba de la Loma (Valladolid)",
  // },
  // {
  //   municipio: "Argujillo",
  //   provincia: "Zamora",
  //   id: "49013",
  //   label: "Argujillo (Zamora)",
  // },
  // {
  //   municipio: "Villalba de los Alcores",
  //   provincia: "Valladolid",
  //   id: "47212",
  //   label: "Villalba de los Alcores (Valladolid)",
  // },
  // {
  //   municipio: "Villanubla",
  //   provincia: "Valladolid",
  //   id: "47217",
  //   label: "Villanubla (Valladolid)",
  // },
  // {
  //   municipio: "Villalbarba",
  //   provincia: "Valladolid",
  //   id: "47213",
  //   label: "Villalbarba (Valladolid)",
  // },
  // {
  //   municipio: "Villalón de Campos",
  //   provincia: "Valladolid",
  //   id: "47214",
  //   label: "Villalón de Campos (Valladolid)",
  // },
  // {
  //   municipio: "Villanueva de Duero",
  //   provincia: "Valladolid",
  //   id: "47218",
  //   label: "Villanueva de Duero (Valladolid)",
  // },
  // {
  //   municipio: "Villavaquerín",
  //   provincia: "Valladolid",
  //   id: "47226",
  //   label: "Villavaquerín (Valladolid)",
  // },
  // {
  //   municipio: "Villardefrades",
  //   provincia: "Valladolid",
  //   id: "47223",
  //   label: "Villardefrades (Valladolid)",
  // },
  // {
  //   municipio: "Villarmentero de Esgueva",
  //   provincia: "Valladolid",
  //   id: "47224",
  //   label: "Villarmentero de Esgueva (Valladolid)",
  // },
  // {
  //   municipio: "Villasexmir",
  //   provincia: "Valladolid",
  //   id: "47225",
  //   label: "Villasexmir (Valladolid)",
  // },
  // {
  //   municipio: "Villavellid",
  //   provincia: "Valladolid",
  //   id: "47227",
  //   label: "Villavellid (Valladolid)",
  // },
  // {
  //   municipio: "Villaverde de Medina",
  //   provincia: "Valladolid",
  //   id: "47228",
  //   label: "Villaverde de Medina (Valladolid)",
  // },
  // {
  //   municipio: "Villavicencio de los Caballeros",
  //   provincia: "Valladolid",
  //   id: "47229",
  //   label: "Villavicencio de los Caballeros (Valladolid)",
  // },
  // {
  //   municipio: "Wamba",
  //   provincia: "Valladolid",
  //   id: "47230",
  //   label: "Wamba (Valladolid)",
  // },
  // {
  //   municipio: "Zaratán",
  //   provincia: "Valladolid",
  //   id: "47231",
  //   label: "Zaratán (Valladolid)",
  // },
  // {
  //   municipio: "Zarza, La",
  //   provincia: "Valladolid",
  //   id: "47232",
  //   label: "Zarza, La (Valladolid)",
  // },
  // {
  //   municipio: "Abezames",
  //   provincia: "Zamora",
  //   id: "49002",
  //   label: "Abezames (Zamora)",
  // },
  // {
  //   municipio: "Alcañices",
  //   provincia: "Zamora",
  //   id: "49003",
  //   label: "Alcañices (Zamora)",
  // },
  // {
  //   municipio: "Alcubilla de Nogales",
  //   provincia: "Zamora",
  //   id: "49004",
  //   label: "Alcubilla de Nogales (Zamora)",
  // },
  // {
  //   municipio: "Alfaraz de Sayago",
  //   provincia: "Zamora",
  //   id: "49005",
  //   label: "Alfaraz de Sayago (Zamora)",
  // },
  // {
  //   municipio: "Algodre",
  //   provincia: "Zamora",
  //   id: "49006",
  //   label: "Algodre (Zamora)",
  // },
  // {
  //   municipio: "Almaraz de Duero",
  //   provincia: "Zamora",
  //   id: "49007",
  //   label: "Almaraz de Duero (Zamora)",
  // },
  // {
  //   municipio: "Almeida de Sayago",
  //   provincia: "Zamora",
  //   id: "49008",
  //   label: "Almeida de Sayago (Zamora)",
  // },
  // {
  //   municipio: "Andavías",
  //   provincia: "Zamora",
  //   id: "49009",
  //   label: "Andavías (Zamora)",
  // },
  // {
  //   municipio: "Arcenillas",
  //   provincia: "Zamora",
  //   id: "49010",
  //   label: "Arcenillas (Zamora)",
  // },
  // {
  //   municipio: "Arcos de la Polvorosa",
  //   provincia: "Zamora",
  //   id: "49011",
  //   label: "Arcos de la Polvorosa (Zamora)",
  // },
  // {
  //   municipio: "Argañín",
  //   provincia: "Zamora",
  //   id: "49012",
  //   label: "Argañín (Zamora)",
  // },
  // {
  //   municipio: "Aspariegos",
  //   provincia: "Zamora",
  //   id: "49016",
  //   label: "Aspariegos (Zamora)",
  // },
  // {
  //   municipio: "Asturianos",
  //   provincia: "Zamora",
  //   id: "49017",
  //   label: "Asturianos (Zamora)",
  // },
  // {
  //   municipio: "Ayoó de Vidriales",
  //   provincia: "Zamora",
  //   id: "49018",
  //   label: "Ayoó de Vidriales (Zamora)",
  // },
  // {
  //   municipio: "Barcial del Barco",
  //   provincia: "Zamora",
  //   id: "49019",
  //   label: "Barcial del Barco (Zamora)",
  // },
  // {
  //   municipio: "Belver de los Montes",
  //   provincia: "Zamora",
  //   id: "49020",
  //   label: "Belver de los Montes (Zamora)",
  // },
  // {
  //   municipio: "Benavente",
  //   provincia: "Zamora",
  //   id: "49021",
  //   label: "Benavente (Zamora)",
  // },
  // {
  //   municipio: "Benegiles",
  //   provincia: "Zamora",
  //   id: "49022",
  //   label: "Benegiles (Zamora)",
  // },
  // {
  //   municipio: "Bermillo de Sayago",
  //   provincia: "Zamora",
  //   id: "49023",
  //   label: "Bermillo de Sayago (Zamora)",
  // },
  // {
  //   municipio: "Bretó",
  //   provincia: "Zamora",
  //   id: "49025",
  //   label: "Bretó (Zamora)",
  // },
  // {
  //   municipio: "Bretocino",
  //   provincia: "Zamora",
  //   id: "49026",
  //   label: "Bretocino (Zamora)",
  // },
  // {
  //   municipio: "Brime de Sog",
  //   provincia: "Zamora",
  //   id: "49027",
  //   label: "Brime de Sog (Zamora)",
  // },
  // {
  //   municipio: "Bustillo del Oro",
  //   provincia: "Zamora",
  //   id: "49030",
  //   label: "Bustillo del Oro (Zamora)",
  // },
  // {
  //   municipio: "Cabañas de Sayago",
  //   provincia: "Zamora",
  //   id: "49031",
  //   label: "Cabañas de Sayago (Zamora)",
  // },
  // {
  //   municipio: "Calzadilla de Tera",
  //   provincia: "Zamora",
  //   id: "49032",
  //   label: "Calzadilla de Tera (Zamora)",
  // },
  // {
  //   municipio: "Camarzana de Tera",
  //   provincia: "Zamora",
  //   id: "49033",
  //   label: "Camarzana de Tera (Zamora)",
  // },
  // {
  //   municipio: "Cañizal",
  //   provincia: "Zamora",
  //   id: "49034",
  //   label: "Cañizal (Zamora)",
  // },
  // {
  //   municipio: "Carbajales de Alba",
  //   provincia: "Zamora",
  //   id: "49036",
  //   label: "Carbajales de Alba (Zamora)",
  // },
  // {
  //   municipio: "Carbellino",
  //   provincia: "Zamora",
  //   id: "49037",
  //   label: "Carbellino (Zamora)",
  // },
  // {
  //   municipio: "Casaseca de Campeán",
  //   provincia: "Zamora",
  //   id: "49038",
  //   label: "Casaseca de Campeán (Zamora)",
  // },
  // {
  //   municipio: "Casaseca de las Chanas",
  //   provincia: "Zamora",
  //   id: "49039",
  //   label: "Casaseca de las Chanas (Zamora)",
  // },
  // {
  //   municipio: "Castrogonzalo",
  //   provincia: "Zamora",
  //   id: "49041",
  //   label: "Castrogonzalo (Zamora)",
  // },
  // {
  //   municipio: "Castronuevo",
  //   provincia: "Zamora",
  //   id: "49042",
  //   label: "Castronuevo (Zamora)",
  // },
  // {
  //   municipio: "Cubillos",
  //   provincia: "Zamora",
  //   id: "49056",
  //   label: "Cubillos (Zamora)",
  // },
  // {
  //   municipio: "Cubo de Tierra del Vino, El",
  //   provincia: "Zamora",
  //   id: "49058",
  //   label: "Cubo de Tierra del Vino, El (Zamora)",
  // },
  // {
  //   municipio: "Castroverde de Campos",
  //   provincia: "Zamora",
  //   id: "49043",
  //   label: "Castroverde de Campos (Zamora)",
  // },
  // {
  //   municipio: "Cazurra",
  //   provincia: "Zamora",
  //   id: "49044",
  //   label: "Cazurra (Zamora)",
  // },
  // {
  //   municipio: "Cerecinos de Campos",
  //   provincia: "Zamora",
  //   id: "49046",
  //   label: "Cerecinos de Campos (Zamora)",
  // },
  // {
  //   municipio: "Cernadilla",
  //   provincia: "Zamora",
  //   id: "49048",
  //   label: "Cernadilla (Zamora)",
  // },
  // {
  //   municipio: "Cobreros",
  //   provincia: "Zamora",
  //   id: "49050",
  //   label: "Cobreros (Zamora)",
  // },
  // {
  //   municipio: "Coomonte",
  //   provincia: "Zamora",
  //   id: "49052",
  //   label: "Coomonte (Zamora)",
  // },
  // {
  //   municipio: "Coreses",
  //   provincia: "Zamora",
  //   id: "49053",
  //   label: "Coreses (Zamora)",
  // },
  // {
  //   municipio: "Cubo de Benavente",
  //   provincia: "Zamora",
  //   id: "49057",
  //   label: "Cubo de Benavente (Zamora)",
  // },
  // {
  //   municipio: "Corrales del Vino",
  //   provincia: "Zamora",
  //   id: "49054",
  //   label: "Corrales del Vino (Zamora)",
  // },
  // {
  //   municipio: "Ferreras de Abajo",
  //   provincia: "Zamora",
  //   id: "49066",
  //   label: "Ferreras de Abajo (Zamora)",
  // },
  // {
  //   municipio: "Manganeses de la Polvorosa",
  //   provincia: "Zamora",
  //   id: "49109",
  //   label: "Manganeses de la Polvorosa (Zamora)",
  // },
  // {
  //   municipio: "Espadañedo",
  //   provincia: "Zamora",
  //   id: "49062",
  //   label: "Espadañedo (Zamora)",
  // },
  // {
  //   municipio: "Fresno de la Ribera",
  //   provincia: "Zamora",
  //   id: "49076",
  //   label: "Fresno de la Ribera (Zamora)",
  // },
  // {
  //   municipio: "Faramontanos de Tábara",
  //   provincia: "Zamora",
  //   id: "49063",
  //   label: "Faramontanos de Tábara (Zamora)",
  // },
  // {
  //   municipio: "Micereces de Tera",
  //   provincia: "Zamora",
  //   id: "49117",
  //   label: "Micereces de Tera (Zamora)",
  // },
  // {
  //   municipio: "Hiniesta, La",
  //   provincia: "Zamora",
  //   id: "49095",
  //   label: "Hiniesta, La (Zamora)",
  // },
  // {
  //   municipio: "Fariza",
  //   provincia: "Zamora",
  //   id: "49064",
  //   label: "Fariza (Zamora)",
  // },
  // {
  //   municipio: "Ferreruela",
  //   provincia: "Zamora",
  //   id: "49068",
  //   label: "Ferreruela (Zamora)",
  // },
  // {
  //   municipio: "Figueruela de Arriba",
  //   provincia: "Zamora",
  //   id: "49069",
  //   label: "Figueruela de Arriba (Zamora)",
  // },
  // {
  //   municipio: "Fermoselle",
  //   provincia: "Zamora",
  //   id: "49065",
  //   label: "Fermoselle (Zamora)",
  // },
  // {
  //   municipio: "Ferreras de Arriba",
  //   provincia: "Zamora",
  //   id: "49067",
  //   label: "Ferreras de Arriba (Zamora)",
  // },
  // {
  //   municipio: "Fonfría",
  //   provincia: "Zamora",
  //   id: "49071",
  //   label: "Fonfría (Zamora)",
  // },
  // {
  //   municipio: "Fresno de la Polvorosa",
  //   provincia: "Zamora",
  //   id: "49075",
  //   label: "Fresno de la Polvorosa (Zamora)",
  // },
  // {
  //   municipio: "Fresno de Sayago",
  //   provincia: "Zamora",
  //   id: "49077",
  //   label: "Fresno de Sayago (Zamora)",
  // },
  // {
  //   municipio: "Friera de Valverde",
  //   provincia: "Zamora",
  //   id: "49078",
  //   label: "Friera de Valverde (Zamora)",
  // },
  // {
  //   municipio: "Fuentelapeña",
  //   provincia: "Zamora",
  //   id: "49080",
  //   label: "Fuentelapeña (Zamora)",
  // },
  // {
  //   municipio: "Hermisende",
  //   provincia: "Zamora",
  //   id: "49094",
  //   label: "Hermisende (Zamora)",
  // },
  // {
  //   municipio: "Requejo",
  //   provincia: "Zamora",
  //   id: "49174",
  //   label: "Requejo (Zamora)",
  // },
  // {
  //   municipio: "Fuentes de Ropel",
  //   provincia: "Zamora",
  //   id: "49082",
  //   label: "Fuentes de Ropel (Zamora)",
  // },
  // {
  //   municipio: "Fuentesecas",
  //   provincia: "Zamora",
  //   id: "49083",
  //   label: "Fuentesecas (Zamora)",
  // },
  // {
  //   municipio: "Revellinos",
  //   provincia: "Zamora",
  //   id: "49175",
  //   label: "Revellinos (Zamora)",
  // },
  // {
  //   municipio: "Fuentespreadas",
  //   provincia: "Zamora",
  //   id: "49084",
  //   label: "Fuentespreadas (Zamora)",
  // },
  // {
  //   municipio: "Galende",
  //   provincia: "Zamora",
  //   id: "49085",
  //   label: "Galende (Zamora)",
  // },
  // {
  //   municipio: "Gallegos del Pan",
  //   provincia: "Zamora",
  //   id: "49086",
  //   label: "Gallegos del Pan (Zamora)",
  // },
  // {
  //   municipio: "Gallegos del Río",
  //   provincia: "Zamora",
  //   id: "49087",
  //   label: "Gallegos del Río (Zamora)",
  // },
  // {
  //   municipio: "Gamones",
  //   provincia: "Zamora",
  //   id: "49088",
  //   label: "Gamones (Zamora)",
  // },
  // {
  //   municipio: "Gema",
  //   provincia: "Zamora",
  //   id: "49090",
  //   label: "Gema (Zamora)",
  // },
  // {
  //   municipio: "Valcabado",
  //   provincia: "Zamora",
  //   id: "49227",
  //   label: "Valcabado (Zamora)",
  // },
  // {
  //   municipio: "Granja de Moreruela",
  //   provincia: "Zamora",
  //   id: "49091",
  //   label: "Granja de Moreruela (Zamora)",
  // },
  // {
  //   municipio: "Granucillo",
  //   provincia: "Zamora",
  //   id: "49092",
  //   label: "Granucillo (Zamora)",
  // },
  // {
  //   municipio: "Guarrate",
  //   provincia: "Zamora",
  //   id: "49093",
  //   label: "Guarrate (Zamora)",
  // },
  // {
  //   municipio: "Losacino",
  //   provincia: "Zamora",
  //   id: "49098",
  //   label: "Losacino (Zamora)",
  // },
  // {
  //   municipio: "Losacio",
  //   provincia: "Zamora",
  //   id: "49099",
  //   label: "Losacio (Zamora)",
  // },
  // {
  //   municipio: "Lubián",
  //   provincia: "Zamora",
  //   id: "49100",
  //   label: "Lubián (Zamora)",
  // },
  // {
  //   municipio: "Santibáñez de Tera",
  //   provincia: "Zamora",
  //   id: "49205",
  //   label: "Santibáñez de Tera (Zamora)",
  // },
  // {
  //   municipio: "Luelmo",
  //   provincia: "Zamora",
  //   id: "49101",
  //   label: "Luelmo (Zamora)",
  // },
  // {
  //   municipio: "Maderal, El",
  //   provincia: "Zamora",
  //   id: "49102",
  //   label: "Maderal, El (Zamora)",
  // },
  // {
  //   municipio: "Madridanos",
  //   provincia: "Zamora",
  //   id: "49103",
  //   label: "Madridanos (Zamora)",
  // },
  // {
  //   municipio: "Mahide",
  //   provincia: "Zamora",
  //   id: "49104",
  //   label: "Mahide (Zamora)",
  // },
  // {
  //   municipio: "Maire de Castroponce",
  //   provincia: "Zamora",
  //   id: "49105",
  //   label: "Maire de Castroponce (Zamora)",
  // },
  // {
  //   municipio: "Valdefinjas",
  //   provincia: "Zamora",
  //   id: "49228",
  //   label: "Valdefinjas (Zamora)",
  // },
  // {
  //   municipio: "Malva",
  //   provincia: "Zamora",
  //   id: "49107",
  //   label: "Malva (Zamora)",
  // },
  // {
  //   municipio: "Manganeses de la Lampreana",
  //   provincia: "Zamora",
  //   id: "49108",
  //   label: "Manganeses de la Lampreana (Zamora)",
  // },
  // {
  //   municipio: "Manzanal de Arriba",
  //   provincia: "Zamora",
  //   id: "49110",
  //   label: "Manzanal de Arriba (Zamora)",
  // },
  // {
  //   municipio: "Tapioles",
  //   provincia: "Zamora",
  //   id: "49216",
  //   label: "Tapioles (Zamora)",
  // },
  // {
  //   municipio: "Manzanal del Barco",
  //   provincia: "Zamora",
  //   id: "49111",
  //   label: "Manzanal del Barco (Zamora)",
  // },
  // {
  //   municipio: "Manzanal de los Infantes",
  //   provincia: "Zamora",
  //   id: "49112",
  //   label: "Manzanal de los Infantes (Zamora)",
  // },
  // {
  //   municipio: "Matilla de Arzón",
  //   provincia: "Zamora",
  //   id: "49113",
  //   label: "Matilla de Arzón (Zamora)",
  // },
  // {
  //   municipio: "Toro",
  //   provincia: "Zamora",
  //   id: "49219",
  //   label: "Toro (Zamora)",
  // },
  // {
  //   municipio: "Matilla la Seca",
  //   provincia: "Zamora",
  //   id: "49114",
  //   label: "Matilla la Seca (Zamora)",
  // },
  // {
  //   municipio: "Mayalde",
  //   provincia: "Zamora",
  //   id: "49115",
  //   label: "Mayalde (Zamora)",
  // },
  // {
  //   municipio: "Melgar de Tera",
  //   provincia: "Zamora",
  //   id: "49116",
  //   label: "Melgar de Tera (Zamora)",
  // },
  // {
  //   municipio: "Milles de la Polvorosa",
  //   provincia: "Zamora",
  //   id: "49118",
  //   label: "Milles de la Polvorosa (Zamora)",
  // },
  // {
  //   municipio: "Molacillos",
  //   provincia: "Zamora",
  //   id: "49119",
  //   label: "Molacillos (Zamora)",
  // },
  // {
  //   municipio: "Molezuelas de la Carballeda",
  //   provincia: "Zamora",
  //   id: "49120",
  //   label: "Molezuelas de la Carballeda (Zamora)",
  // },
  // {
  //   municipio: "Mombuey",
  //   provincia: "Zamora",
  //   id: "49121",
  //   label: "Mombuey (Zamora)",
  // },
  // {
  //   municipio: "Monfarracinos",
  //   provincia: "Zamora",
  //   id: "49122",
  //   label: "Monfarracinos (Zamora)",
  // },
  // {
  //   municipio: "Torre del Valle, La",
  //   provincia: "Zamora",
  //   id: "49220",
  //   label: "Torre del Valle, La (Zamora)",
  // },
  // {
  //   municipio: "Montamarta",
  //   provincia: "Zamora",
  //   id: "49123",
  //   label: "Montamarta (Zamora)",
  // },
  // {
  //   municipio: "Moraleja de Sayago",
  //   provincia: "Zamora",
  //   id: "49126",
  //   label: "Moraleja de Sayago (Zamora)",
  // },
  // {
  //   municipio: "Moral de Sayago",
  //   provincia: "Zamora",
  //   id: "49124",
  //   label: "Moral de Sayago (Zamora)",
  // },
  // {
  //   municipio: "Moraleja del Vino",
  //   provincia: "Zamora",
  //   id: "49125",
  //   label: "Moraleja del Vino (Zamora)",
  // },
  // {
  //   municipio: "Morales del Vino",
  //   provincia: "Zamora",
  //   id: "49127",
  //   label: "Morales del Vino (Zamora)",
  // },
  // {
  //   municipio: "Morales de Rey",
  //   provincia: "Zamora",
  //   id: "49128",
  //   label: "Morales de Rey (Zamora)",
  // },
  // {
  //   municipio: "Moreruela de los Infanzones",
  //   provincia: "Zamora",
  //   id: "49132",
  //   label: "Moreruela de los Infanzones (Zamora)",
  // },
  // {
  //   municipio: "Morales de Toro",
  //   provincia: "Zamora",
  //   id: "49129",
  //   label: "Morales de Toro (Zamora)",
  // },
  // {
  //   municipio: "Morales de Valverde",
  //   provincia: "Zamora",
  //   id: "49130",
  //   label: "Morales de Valverde (Zamora)",
  // },
  // {
  //   municipio: "Moralina",
  //   provincia: "Zamora",
  //   id: "49131",
  //   label: "Moralina (Zamora)",
  // },
  // {
  //   municipio: "Moreruela de Tábara",
  //   provincia: "Zamora",
  //   id: "49133",
  //   label: "Moreruela de Tábara (Zamora)",
  // },
  // {
  //   municipio: "Muelas de los Caballeros",
  //   provincia: "Zamora",
  //   id: "49134",
  //   label: "Muelas de los Caballeros (Zamora)",
  // },
  // {
  //   municipio: "Muelas del Pan",
  //   provincia: "Zamora",
  //   id: "49135",
  //   label: "Muelas del Pan (Zamora)",
  // },
  // {
  //   municipio: "Muga de Sayago",
  //   provincia: "Zamora",
  //   id: "49136",
  //   label: "Muga de Sayago (Zamora)",
  // },
  // {
  //   municipio: "Piedrahita de Castro",
  //   provincia: "Zamora",
  //   id: "49155",
  //   label: "Piedrahita de Castro (Zamora)",
  // },
  // {
  //   municipio: "Pinilla de Toro",
  //   provincia: "Zamora",
  //   id: "49156",
  //   label: "Pinilla de Toro (Zamora)",
  // },
  // {
  //   municipio: "Navianos de Valverde",
  //   provincia: "Zamora",
  //   id: "49137",
  //   label: "Navianos de Valverde (Zamora)",
  // },
  // {
  //   municipio: "Pajares de la Lampreana",
  //   provincia: "Zamora",
  //   id: "49141",
  //   label: "Pajares de la Lampreana (Zamora)",
  // },
  // {
  //   municipio: "Olmillos de Castro",
  //   provincia: "Zamora",
  //   id: "49138",
  //   label: "Olmillos de Castro (Zamora)",
  // },
  // {
  //   municipio: "Otero de Bodas",
  //   provincia: "Zamora",
  //   id: "49139",
  //   label: "Otero de Bodas (Zamora)",
  // },
  // {
  //   municipio: "Palacios del Pan",
  //   provincia: "Zamora",
  //   id: "49142",
  //   label: "Palacios del Pan (Zamora)",
  // },
  // {
  //   municipio: "Peleagonzalo",
  //   provincia: "Zamora",
  //   id: "49147",
  //   label: "Peleagonzalo (Zamora)",
  // },
  // {
  //   municipio: "Samir de los Caños",
  //   provincia: "Zamora",
  //   id: "49184",
  //   label: "Samir de los Caños (Zamora)",
  // },
  // {
  //   municipio: "Palacios de Sanabria",
  //   provincia: "Zamora",
  //   id: "49143",
  //   label: "Palacios de Sanabria (Zamora)",
  // },
  // {
  //   municipio: "Peleas de Abajo",
  //   provincia: "Zamora",
  //   id: "49148",
  //   label: "Peleas de Abajo (Zamora)",
  // },
  // {
  //   municipio: "Quiruelas de Vidriales",
  //   provincia: "Zamora",
  //   id: "49171",
  //   label: "Quiruelas de Vidriales (Zamora)",
  // },
  // {
  //   municipio: "Pedralba de la Pradería",
  //   provincia: "Zamora",
  //   id: "49145",
  //   label: "Pedralba de la Pradería (Zamora)",
  // },
  // {
  //   municipio: "Pego, El",
  //   provincia: "Zamora",
  //   id: "49146",
  //   label: "Pego, El (Zamora)",
  // },
  // {
  //   municipio: "Peñausende",
  //   provincia: "Zamora",
  //   id: "49149",
  //   label: "Peñausende (Zamora)",
  // },
  // {
  //   municipio: "Peque",
  //   provincia: "Zamora",
  //   id: "49150",
  //   label: "Peque (Zamora)",
  // },
  // {
  //   municipio: "Pobladura de Valderaduey",
  //   provincia: "Zamora",
  //   id: "49160",
  //   label: "Pobladura de Valderaduey (Zamora)",
  // },
  // {
  //   municipio: "Perdigón, El",
  //   provincia: "Zamora",
  //   id: "49151",
  //   label: "Perdigón, El (Zamora)",
  // },
  // {
  //   municipio: "Pereruela",
  //   provincia: "Zamora",
  //   id: "49152",
  //   label: "Pereruela (Zamora)",
  // },
  // {
  //   municipio: "Roales",
  //   provincia: "Zamora",
  //   id: "49178",
  //   label: "Roales (Zamora)",
  // },
  // {
  //   municipio: "Perilla de Castro",
  //   provincia: "Zamora",
  //   id: "49153",
  //   label: "Perilla de Castro (Zamora)",
  // },
  // {
  //   municipio: "Pías",
  //   provincia: "Zamora",
  //   id: "49154",
  //   label: "Pías (Zamora)",
  // },
  // {
  //   municipio: "Pino del Oro",
  //   provincia: "Zamora",
  //   id: "49157",
  //   label: "Pino del Oro (Zamora)",
  // },
  // {
  //   municipio: "Piñero, El",
  //   provincia: "Zamora",
  //   id: "49158",
  //   label: "Piñero, El (Zamora)",
  // },
  // {
  //   municipio: "Pobladura del Valle",
  //   provincia: "Zamora",
  //   id: "49159",
  //   label: "Pobladura del Valle (Zamora)",
  // },
  // {
  //   municipio: "Porto",
  //   provincia: "Zamora",
  //   id: "49162",
  //   label: "Porto (Zamora)",
  // },
  // {
  //   municipio: "Quintanilla del Monte",
  //   provincia: "Zamora",
  //   id: "49168",
  //   label: "Quintanilla del Monte (Zamora)",
  // },
  // {
  //   municipio: "San Agustín del Pozo",
  //   provincia: "Zamora",
  //   id: "49185",
  //   label: "San Agustín del Pozo (Zamora)",
  // },
  // {
  //   municipio: "Pozoantiguo",
  //   provincia: "Zamora",
  //   id: "49163",
  //   label: "Pozoantiguo (Zamora)",
  // },
  // {
  //   municipio: "Pozuelo de Tábara",
  //   provincia: "Zamora",
  //   id: "49164",
  //   label: "Pozuelo de Tábara (Zamora)",
  // },
  // {
  //   municipio: "Prado",
  //   provincia: "Zamora",
  //   id: "49165",
  //   label: "Prado (Zamora)",
  // },
  // {
  //   municipio: "Puebla de Sanabria",
  //   provincia: "Zamora",
  //   id: "49166",
  //   label: "Puebla de Sanabria (Zamora)",
  // },
  // {
  //   municipio: "Pueblica de Valverde",
  //   provincia: "Zamora",
  //   id: "49167",
  //   label: "Pueblica de Valverde (Zamora)",
  // },
  // {
  //   municipio: "Quintanilla del Olmo",
  //   provincia: "Zamora",
  //   id: "49169",
  //   label: "Quintanilla del Olmo (Zamora)",
  // },
  // {
  //   municipio: "Rabanales",
  //   provincia: "Zamora",
  //   id: "49172",
  //   label: "Rabanales (Zamora)",
  // },
  // {
  //   municipio: "Rábano de Aliste",
  //   provincia: "Zamora",
  //   id: "49173",
  //   label: "Rábano de Aliste (Zamora)",
  // },
  // {
  //   municipio: "Vega de Villalobos",
  //   provincia: "Zamora",
  //   id: "49232",
  //   label: "Vega de Villalobos (Zamora)",
  // },
  // {
  //   municipio: "Riofrío de Aliste",
  //   provincia: "Zamora",
  //   id: "49176",
  //   label: "Riofrío de Aliste (Zamora)",
  // },
  // {
  //   municipio: "Rionegro del Puente",
  //   provincia: "Zamora",
  //   id: "49177",
  //   label: "Rionegro del Puente (Zamora)",
  // },
  // {
  //   municipio: "Robleda-Cervantes",
  //   provincia: "Zamora",
  //   id: "49179",
  //   label: "Robleda-Cervantes (Zamora)",
  // },
  // {
  //   municipio: "Roelos de Sayago",
  //   provincia: "Zamora",
  //   id: "49180",
  //   label: "Roelos de Sayago (Zamora)",
  // },
  // {
  //   municipio: "Vegalatrave",
  //   provincia: "Zamora",
  //   id: "49233",
  //   label: "Vegalatrave (Zamora)",
  // },
  // {
  //   municipio: "Rosinos de la Requejada",
  //   provincia: "Zamora",
  //   id: "49181",
  //   label: "Rosinos de la Requejada (Zamora)",
  // },
  // {
  //   municipio: "Salce",
  //   provincia: "Zamora",
  //   id: "49183",
  //   label: "Salce (Zamora)",
  // },
  // {
  //   municipio: "San Cristóbal de Entreviñas",
  //   provincia: "Zamora",
  //   id: "49187",
  //   label: "San Cristóbal de Entreviñas (Zamora)",
  // },
  // {
  //   municipio: "San Justo",
  //   provincia: "Zamora",
  //   id: "49189",
  //   label: "San Justo (Zamora)",
  // },
  // {
  //   municipio: "San Cebrián de Castro",
  //   provincia: "Zamora",
  //   id: "49186",
  //   label: "San Cebrián de Castro (Zamora)",
  // },
  // {
  //   municipio: "San Esteban del Molar",
  //   provincia: "Zamora",
  //   id: "49188",
  //   label: "San Esteban del Molar (Zamora)",
  // },
  // {
  //   municipio: "San Miguel del Valle",
  //   provincia: "Zamora",
  //   id: "49192",
  //   label: "San Miguel del Valle (Zamora)",
  // },
  // {
  //   municipio: "Santa Cristina de la Polvorosa",
  //   provincia: "Zamora",
  //   id: "49200",
  //   label: "Santa Cristina de la Polvorosa (Zamora)",
  // },
  // {
  //   municipio: "Vidayanes",
  //   provincia: "Zamora",
  //   id: "49236",
  //   label: "Vidayanes (Zamora)",
  // },
  // {
  //   municipio: "San Miguel de la Ribera",
  //   provincia: "Zamora",
  //   id: "49191",
  //   label: "San Miguel de la Ribera (Zamora)",
  // },
  // {
  //   municipio: "San Pedro de Ceque",
  //   provincia: "Zamora",
  //   id: "49193",
  //   label: "San Pedro de Ceque (Zamora)",
  // },
  // {
  //   municipio: "San Pedro de la Nave-Almendra",
  //   provincia: "Zamora",
  //   id: "49194",
  //   label: "San Pedro de la Nave-Almendra (Zamora)",
  // },
  // {
  //   municipio: "Santa Clara de Avedillo",
  //   provincia: "Zamora",
  //   id: "49197",
  //   label: "Santa Clara de Avedillo (Zamora)",
  // },
  // {
  //   municipio: "Santa Colomba de las Monjas",
  //   provincia: "Zamora",
  //   id: "49199",
  //   label: "Santa Colomba de las Monjas (Zamora)",
  // },
  // {
  //   municipio: "Santa Eufemia del Barco",
  //   provincia: "Zamora",
  //   id: "49202",
  //   label: "Santa Eufemia del Barco (Zamora)",
  // },
  // {
  //   municipio: "Santa María de la Vega",
  //   provincia: "Zamora",
  //   id: "49203",
  //   label: "Santa María de la Vega (Zamora)",
  // },
  // {
  //   municipio: "Santa María de Valverde",
  //   provincia: "Zamora",
  //   id: "49204",
  //   label: "Santa María de Valverde (Zamora)",
  // },
  // {
  //   municipio: "Sanzoles",
  //   provincia: "Zamora",
  //   id: "49210",
  //   label: "Sanzoles (Zamora)",
  // },
  // {
  //   municipio: "Santibáñez de Vidriales",
  //   provincia: "Zamora",
  //   id: "49206",
  //   label: "Santibáñez de Vidriales (Zamora)",
  // },
  // {
  //   municipio: "Santovenia",
  //   provincia: "Zamora",
  //   id: "49207",
  //   label: "Santovenia (Zamora)",
  // },
  // {
  //   municipio: "San Vicente de la Cabeza",
  //   provincia: "Zamora",
  //   id: "49208",
  //   label: "San Vicente de la Cabeza (Zamora)",
  // },
  // {
  //   municipio: "San Vitero",
  //   provincia: "Zamora",
  //   id: "49209",
  //   label: "San Vitero (Zamora)",
  // },
  // {
  //   municipio: "Cinco Olivas",
  //   provincia: "Zaragoza",
  //   id: "50083",
  //   label: "Cinco Olivas (Zaragoza)",
  // },
  // {
  //   municipio: "Tábara",
  //   provincia: "Zamora",
  //   id: "49214",
  //   label: "Tábara (Zamora)",
  // },
  // {
  //   municipio: "Torregamones",
  //   provincia: "Zamora",
  //   id: "49221",
  //   label: "Torregamones (Zamora)",
  // },
  // {
  //   municipio: "Torres del Carrizal",
  //   provincia: "Zamora",
  //   id: "49222",
  //   label: "Torres del Carrizal (Zamora)",
  // },
  // {
  //   municipio: "Trabazos",
  //   provincia: "Zamora",
  //   id: "49223",
  //   label: "Trabazos (Zamora)",
  // },
  // {
  //   municipio: "Trefacio",
  //   provincia: "Zamora",
  //   id: "49224",
  //   label: "Trefacio (Zamora)",
  // },
  // {
  //   municipio: "Uña de Quintana",
  //   provincia: "Zamora",
  //   id: "49225",
  //   label: "Uña de Quintana (Zamora)",
  // },
  // {
  //   municipio: "Venialbo",
  //   provincia: "Zamora",
  //   id: "49234",
  //   label: "Venialbo (Zamora)",
  // },
  // {
  //   municipio: "Vezdemarbán",
  //   provincia: "Zamora",
  //   id: "49235",
  //   label: "Vezdemarbán (Zamora)",
  // },
  // {
  //   municipio: "Vadillo de la Guareña",
  //   provincia: "Zamora",
  //   id: "49226",
  //   label: "Vadillo de la Guareña (Zamora)",
  // },
  // {
  //   municipio: "Valdescorriel",
  //   provincia: "Zamora",
  //   id: "49229",
  //   label: "Valdescorriel (Zamora)",
  // },
  // {
  //   municipio: "Vallesa de la Guareña",
  //   provincia: "Zamora",
  //   id: "49230",
  //   label: "Vallesa de la Guareña (Zamora)",
  // },
  // {
  //   municipio: "Vega de Tera",
  //   provincia: "Zamora",
  //   id: "49231",
  //   label: "Vega de Tera (Zamora)",
  // },
  // {
  //   municipio: "Videmala",
  //   provincia: "Zamora",
  //   id: "49237",
  //   label: "Videmala (Zamora)",
  // },
  // {
  //   municipio: "Villabrázaro",
  //   provincia: "Zamora",
  //   id: "49238",
  //   label: "Villabrázaro (Zamora)",
  // },
  // {
  //   municipio: "Villabuena del Puente",
  //   provincia: "Zamora",
  //   id: "49239",
  //   label: "Villabuena del Puente (Zamora)",
  // },
  // {
  //   municipio: "Villadepera",
  //   provincia: "Zamora",
  //   id: "49240",
  //   label: "Villadepera (Zamora)",
  // },
  // {
  //   municipio: "Villaescusa",
  //   provincia: "Zamora",
  //   id: "49241",
  //   label: "Villaescusa (Zamora)",
  // },
  // {
  //   municipio: "Villafáfila",
  //   provincia: "Zamora",
  //   id: "49242",
  //   label: "Villafáfila (Zamora)",
  // },
  // {
  //   municipio: "Villaferrueña",
  //   provincia: "Zamora",
  //   id: "49243",
  //   label: "Villaferrueña (Zamora)",
  // },
  // {
  //   municipio: "Villageriz",
  //   provincia: "Zamora",
  //   id: "49244",
  //   label: "Villageriz (Zamora)",
  // },
  // {
  //   municipio: "Villalazán",
  //   provincia: "Zamora",
  //   id: "49245",
  //   label: "Villalazán (Zamora)",
  // },
  // {
  //   municipio: "Villalba de la Lampreana",
  //   provincia: "Zamora",
  //   id: "49246",
  //   label: "Villalba de la Lampreana (Zamora)",
  // },
  // {
  //   municipio: "Villardiegua de la Ribera",
  //   provincia: "Zamora",
  //   id: "49265",
  //   label: "Villardiegua de la Ribera (Zamora)",
  // },
  // {
  //   municipio: "Villárdiga",
  //   provincia: "Zamora",
  //   id: "49266",
  //   label: "Villárdiga (Zamora)",
  // },
  // {
  //   municipio: "Villalcampo",
  //   provincia: "Zamora",
  //   id: "49247",
  //   label: "Villalcampo (Zamora)",
  // },
  // {
  //   municipio: "Villalobos",
  //   provincia: "Zamora",
  //   id: "49248",
  //   label: "Villalobos (Zamora)",
  // },
  // {
  //   municipio: "Fombuena",
  //   provincia: "Zaragoza",
  //   id: "50108",
  //   label: "Fombuena (Zaragoza)",
  // },
  // {
  //   municipio: "Villalube",
  //   provincia: "Zamora",
  //   id: "49251",
  //   label: "Villalube (Zamora)",
  // },
  // {
  //   municipio: "Villamayor de Campos",
  //   provincia: "Zamora",
  //   id: "49252",
  //   label: "Villamayor de Campos (Zamora)",
  // },
  // {
  //   municipio: "Villalpando",
  //   provincia: "Zamora",
  //   id: "49250",
  //   label: "Villalpando (Zamora)",
  // },
  // {
  //   municipio: "Villanueva de Azoague",
  //   provincia: "Zamora",
  //   id: "49257",
  //   label: "Villanueva de Azoague (Zamora)",
  // },
  // {
  //   municipio: "Villanázar",
  //   provincia: "Zamora",
  //   id: "49256",
  //   label: "Villanázar (Zamora)",
  // },
  // {
  //   municipio: "Villanueva de Campeán",
  //   provincia: "Zamora",
  //   id: "49258",
  //   label: "Villanueva de Campeán (Zamora)",
  // },
  // {
  //   municipio: "Villanueva de las Peras",
  //   provincia: "Zamora",
  //   id: "49259",
  //   label: "Villanueva de las Peras (Zamora)",
  // },
  // {
  //   municipio: "Sobradiel",
  //   provincia: "Zaragoza",
  //   id: "50247",
  //   label: "Sobradiel (Zaragoza)",
  // },
  // {
  //   municipio: "Villanueva del Campo",
  //   provincia: "Zamora",
  //   id: "49260",
  //   label: "Villanueva del Campo (Zamora)",
  // },
  // {
  //   municipio: "Villaralbo",
  //   provincia: "Zamora",
  //   id: "49261",
  //   label: "Villaralbo (Zamora)",
  // },
  // {
  //   municipio: "Lagata",
  //   provincia: "Zaragoza",
  //   id: "50133",
  //   label: "Lagata (Zaragoza)",
  // },
  // {
  //   municipio: "Villardeciervos",
  //   provincia: "Zamora",
  //   id: "49262",
  //   label: "Villardeciervos (Zamora)",
  // },
  // {
  //   municipio: "Villar de Fallaves",
  //   provincia: "Zamora",
  //   id: "49263",
  //   label: "Villar de Fallaves (Zamora)",
  // },
  // {
  //   municipio: "Sos del Rey Católico",
  //   provincia: "Zaragoza",
  //   id: "50248",
  //   label: "Sos del Rey Católico (Zaragoza)",
  // },
  // {
  //   municipio: "Villardondiego",
  //   provincia: "Zamora",
  //   id: "49267",
  //   label: "Villardondiego (Zamora)",
  // },
  // {
  //   municipio: "Bagüés",
  //   provincia: "Zaragoza",
  //   id: "50041",
  //   label: "Bagüés (Zaragoza)",
  // },
  // {
  //   municipio: "Letux",
  //   provincia: "Zaragoza",
  //   id: "50139",
  //   label: "Letux (Zaragoza)",
  // },
  // {
  //   municipio: "Villar del Buey",
  //   provincia: "Zamora",
  //   id: "49264",
  //   label: "Villar del Buey (Zamora)",
  // },
  // {
  //   municipio: "Villarrín de Campos",
  //   provincia: "Zamora",
  //   id: "49268",
  //   label: "Villarrín de Campos (Zamora)",
  // },
  // {
  //   municipio: "Villaseco del Pan",
  //   provincia: "Zamora",
  //   id: "49269",
  //   label: "Villaseco del Pan (Zamora)",
  // },
  // {
  //   municipio: "Villavendimio",
  //   provincia: "Zamora",
  //   id: "49270",
  //   label: "Villavendimio (Zamora)",
  // },
  // {
  //   municipio: "Villaveza del Agua",
  //   provincia: "Zamora",
  //   id: "49271",
  //   label: "Villaveza del Agua (Zamora)",
  // },
  // {
  //   municipio: "Villaveza de Valverde",
  //   provincia: "Zamora",
  //   id: "49272",
  //   label: "Villaveza de Valverde (Zamora)",
  // },
  // {
  //   municipio: "Viñas",
  //   provincia: "Zamora",
  //   id: "49273",
  //   label: "Viñas (Zamora)",
  // },
  // {
  //   municipio: "Zamora",
  //   provincia: "Zamora",
  //   id: "49275",
  //   label: "Zamora (Zamora)",
  // },
  // {
  //   municipio: "Balconchán",
  //   provincia: "Zaragoza",
  //   id: "50042",
  //   label: "Balconchán (Zaragoza)",
  // },
  // {
  //   municipio: "Abanto",
  //   provincia: "Zaragoza",
  //   id: "50001",
  //   label: "Abanto (Zaragoza)",
  // },
  // {
  //   municipio: "Acered",
  //   provincia: "Zaragoza",
  //   id: "50002",
  //   label: "Acered (Zaragoza)",
  // },
  // {
  //   municipio: "Berrueco",
  //   provincia: "Zaragoza",
  //   id: "50048",
  //   label: "Berrueco (Zaragoza)",
  // },
  // {
  //   municipio: "Agón",
  //   provincia: "Zaragoza",
  //   id: "50003",
  //   label: "Agón (Zaragoza)",
  // },
  // {
  //   municipio: "Alberite de San Juan",
  //   provincia: "Zaragoza",
  //   id: "50010",
  //   label: "Alberite de San Juan (Zaragoza)",
  // },
  // {
  //   municipio: "Aguarón",
  //   provincia: "Zaragoza",
  //   id: "50004",
  //   label: "Aguarón (Zaragoza)",
  // },
  // {
  //   municipio: "Aguilón",
  //   provincia: "Zaragoza",
  //   id: "50005",
  //   label: "Aguilón (Zaragoza)",
  // },
  // {
  //   municipio: "Alcalá de Moncayo",
  //   provincia: "Zaragoza",
  //   id: "50014",
  //   label: "Alcalá de Moncayo (Zaragoza)",
  // },
  // {
  //   municipio: "Ainzón",
  //   provincia: "Zaragoza",
  //   id: "50006",
  //   label: "Ainzón (Zaragoza)",
  // },
  // {
  //   municipio: "Aladrén",
  //   provincia: "Zaragoza",
  //   id: "50007",
  //   label: "Aladrén (Zaragoza)",
  // },
  // {
  //   municipio: "Alconchel de Ariza",
  //   provincia: "Zaragoza",
  //   id: "50015",
  //   label: "Alconchel de Ariza (Zaragoza)",
  // },
  // {
  //   municipio: "Alagón",
  //   provincia: "Zaragoza",
  //   id: "50008",
  //   label: "Alagón (Zaragoza)",
  // },
  // {
  //   municipio: "Alarba",
  //   provincia: "Zaragoza",
  //   id: "50009",
  //   label: "Alarba (Zaragoza)",
  // },
  // {
  //   municipio: "Albeta",
  //   provincia: "Zaragoza",
  //   id: "50011",
  //   label: "Albeta (Zaragoza)",
  // },
  // {
  //   municipio: "Alborge",
  //   provincia: "Zaragoza",
  //   id: "50012",
  //   label: "Alborge (Zaragoza)",
  // },
  // {
  //   municipio: "Alcalá de Ebro",
  //   provincia: "Zaragoza",
  //   id: "50013",
  //   label: "Alcalá de Ebro (Zaragoza)",
  // },
  // {
  //   municipio: "Aldehuela de Liestos",
  //   provincia: "Zaragoza",
  //   id: "50016",
  //   label: "Aldehuela de Liestos (Zaragoza)",
  // },
  // {
  //   municipio: "Alforque",
  //   provincia: "Zaragoza",
  //   id: "50019",
  //   label: "Alforque (Zaragoza)",
  // },
  // {
  //   municipio: "Bisimbre",
  //   provincia: "Zaragoza",
  //   id: "50052",
  //   label: "Bisimbre (Zaragoza)",
  // },
  // {
  //   municipio: "Alfajarín",
  //   provincia: "Zaragoza",
  //   id: "50017",
  //   label: "Alfajarín (Zaragoza)",
  // },
  // {
  //   municipio: "Alfamén",
  //   provincia: "Zaragoza",
  //   id: "50018",
  //   label: "Alfamén (Zaragoza)",
  // },
  // {
  //   municipio: "Alhama de Aragón",
  //   provincia: "Zaragoza",
  //   id: "50020",
  //   label: "Alhama de Aragón (Zaragoza)",
  // },
  // {
  //   municipio: "Almochuel",
  //   provincia: "Zaragoza",
  //   id: "50021",
  //   label: "Almochuel (Zaragoza)",
  // },
  // {
  //   municipio: "Anento",
  //   provincia: "Zaragoza",
  //   id: "50028",
  //   label: "Anento (Zaragoza)",
  // },
  // {
  //   municipio: "Almolda, La",
  //   provincia: "Zaragoza",
  //   id: "50022",
  //   label: "Almolda, La (Zaragoza)",
  // },
  // {
  //   municipio: "Almonacid de la Cuba",
  //   provincia: "Zaragoza",
  //   id: "50023",
  //   label: "Almonacid de la Cuba (Zaragoza)",
  // },
  // {
  //   municipio: "Almonacid de la Sierra",
  //   provincia: "Zaragoza",
  //   id: "50024",
  //   label: "Almonacid de la Sierra (Zaragoza)",
  // },
  // {
  //   municipio: "Ambel",
  //   provincia: "Zaragoza",
  //   id: "50027",
  //   label: "Ambel (Zaragoza)",
  // },
  // {
  //   municipio: "Litago",
  //   provincia: "Zaragoza",
  //   id: "50140",
  //   label: "Litago (Zaragoza)",
  // },
  // {
  //   municipio: "Aniñón",
  //   provincia: "Zaragoza",
  //   id: "50029",
  //   label: "Aniñón (Zaragoza)",
  // },
  // {
  //   municipio: "Belmonte de Gracián",
  //   provincia: "Zaragoza",
  //   id: "50046",
  //   label: "Belmonte de Gracián (Zaragoza)",
  // },
  // {
  //   municipio: "Añón de Moncayo",
  //   provincia: "Zaragoza",
  //   id: "50030",
  //   label: "Añón de Moncayo (Zaragoza)",
  // },
  // {
  //   municipio: "Berdejo",
  //   provincia: "Zaragoza",
  //   id: "50047",
  //   label: "Berdejo (Zaragoza)",
  // },
  // {
  //   municipio: "Aranda de Moncayo",
  //   provincia: "Zaragoza",
  //   id: "50031",
  //   label: "Aranda de Moncayo (Zaragoza)",
  // },
  // {
  //   municipio: "Bureta",
  //   provincia: "Zaragoza",
  //   id: "50061",
  //   label: "Bureta (Zaragoza)",
  // },
  // {
  //   municipio: "Arándiga",
  //   provincia: "Zaragoza",
  //   id: "50032",
  //   label: "Arándiga (Zaragoza)",
  // },
  // {
  //   municipio: "Artieda",
  //   provincia: "Zaragoza",
  //   id: "50035",
  //   label: "Artieda (Zaragoza)",
  // },
  // {
  //   municipio: "Lituénigo",
  //   provincia: "Zaragoza",
  //   id: "50141",
  //   label: "Lituénigo (Zaragoza)",
  // },
  // {
  //   municipio: "Ardisa",
  //   provincia: "Zaragoza",
  //   id: "50033",
  //   label: "Ardisa (Zaragoza)",
  // },
  // {
  //   municipio: "Ariza",
  //   provincia: "Zaragoza",
  //   id: "50034",
  //   label: "Ariza (Zaragoza)",
  // },
  // {
  //   municipio: "Asín",
  //   provincia: "Zaragoza",
  //   id: "50036",
  //   label: "Asín (Zaragoza)",
  // },
  // {
  //   municipio: "Atea",
  //   provincia: "Zaragoza",
  //   id: "50037",
  //   label: "Atea (Zaragoza)",
  // },
  // {
  //   municipio: "Ateca",
  //   provincia: "Zaragoza",
  //   id: "50038",
  //   label: "Ateca (Zaragoza)",
  // },
  // {
  //   municipio: "Azuara",
  //   provincia: "Zaragoza",
  //   id: "50039",
  //   label: "Azuara (Zaragoza)",
  // },
  // {
  //   municipio: "Badules",
  //   provincia: "Zaragoza",
  //   id: "50040",
  //   label: "Badules (Zaragoza)",
  // },
  // {
  //   municipio: "Bárboles",
  //   provincia: "Zaragoza",
  //   id: "50043",
  //   label: "Bárboles (Zaragoza)",
  // },
  // {
  //   municipio: "Bardallur",
  //   provincia: "Zaragoza",
  //   id: "50044",
  //   label: "Bardallur (Zaragoza)",
  // },
  // {
  //   municipio: "Belchite",
  //   provincia: "Zaragoza",
  //   id: "50045",
  //   label: "Belchite (Zaragoza)",
  // },
  // {
  //   municipio: "Bijuesca",
  //   provincia: "Zaragoza",
  //   id: "50050",
  //   label: "Bijuesca (Zaragoza)",
  // },
  // {
  //   municipio: "Boquiñeni",
  //   provincia: "Zaragoza",
  //   id: "50053",
  //   label: "Boquiñeni (Zaragoza)",
  // },
  // {
  //   municipio: "Biota",
  //   provincia: "Zaragoza",
  //   id: "50051",
  //   label: "Biota (Zaragoza)",
  // },
  // {
  //   municipio: "Botorrita",
  //   provincia: "Zaragoza",
  //   id: "50056",
  //   label: "Botorrita (Zaragoza)",
  // },
  // {
  //   municipio: "Brea de Aragón",
  //   provincia: "Zaragoza",
  //   id: "50057",
  //   label: "Brea de Aragón (Zaragoza)",
  // },
  // {
  //   municipio: "Bubierca",
  //   provincia: "Zaragoza",
  //   id: "50058",
  //   label: "Bubierca (Zaragoza)",
  // },
  // {
  //   municipio: "Bujaraloz",
  //   provincia: "Zaragoza",
  //   id: "50059",
  //   label: "Bujaraloz (Zaragoza)",
  // },
  // {
  //   municipio: "Bulbuente",
  //   provincia: "Zaragoza",
  //   id: "50060",
  //   label: "Bulbuente (Zaragoza)",
  // },
  // {
  //   municipio: "Fréscano",
  //   provincia: "Zaragoza",
  //   id: "50111",
  //   label: "Fréscano (Zaragoza)",
  // },
  // {
  //   municipio: "Burgo de Ebro, El",
  //   provincia: "Zaragoza",
  //   id: "50062",
  //   label: "Burgo de Ebro, El (Zaragoza)",
  // },
  // {
  //   municipio: "Buste, El",
  //   provincia: "Zaragoza",
  //   id: "50063",
  //   label: "Buste, El (Zaragoza)",
  // },
  // {
  //   municipio: "Lucena de Jalón",
  //   provincia: "Zaragoza",
  //   id: "50146",
  //   label: "Lucena de Jalón (Zaragoza)",
  // },
  // {
  //   municipio: "Cabañas de Ebro",
  //   provincia: "Zaragoza",
  //   id: "50064",
  //   label: "Cabañas de Ebro (Zaragoza)",
  // },
  // {
  //   municipio: "Cabolafuente",
  //   provincia: "Zaragoza",
  //   id: "50065",
  //   label: "Cabolafuente (Zaragoza)",
  // },
  // {
  //   municipio: "Cadrete",
  //   provincia: "Zaragoza",
  //   id: "50066",
  //   label: "Cadrete (Zaragoza)",
  // },
  // {
  //   municipio: "Calatayud",
  //   provincia: "Zaragoza",
  //   id: "50067",
  //   label: "Calatayud (Zaragoza)",
  // },
  // {
  //   municipio: "Calatorao",
  //   provincia: "Zaragoza",
  //   id: "50068",
  //   label: "Calatorao (Zaragoza)",
  // },
  // {
  //   municipio: "Calcena",
  //   provincia: "Zaragoza",
  //   id: "50069",
  //   label: "Calcena (Zaragoza)",
  // },
  // {
  //   municipio: "Calmarza",
  //   provincia: "Zaragoza",
  //   id: "50070",
  //   label: "Calmarza (Zaragoza)",
  // },
  // {
  //   municipio: "Campillo de Aragón",
  //   provincia: "Zaragoza",
  //   id: "50071",
  //   label: "Campillo de Aragón (Zaragoza)",
  // },
  // {
  //   municipio: "Carenas",
  //   provincia: "Zaragoza",
  //   id: "50072",
  //   label: "Carenas (Zaragoza)",
  // },
  // {
  //   municipio: "Cariñena",
  //   provincia: "Zaragoza",
  //   id: "50073",
  //   label: "Cariñena (Zaragoza)",
  // },
  // {
  //   municipio: "Caspe",
  //   provincia: "Zaragoza",
  //   id: "50074",
  //   label: "Caspe (Zaragoza)",
  // },
  // {
  //   municipio: "Castejón de Alarba",
  //   provincia: "Zaragoza",
  //   id: "50075",
  //   label: "Castejón de Alarba (Zaragoza)",
  // },
  // {
  //   municipio: "Castejón de las Armas",
  //   provincia: "Zaragoza",
  //   id: "50076",
  //   label: "Castejón de las Armas (Zaragoza)",
  // },
  // {
  //   municipio: "Clarés de Ribota",
  //   provincia: "Zaragoza",
  //   id: "50084",
  //   label: "Clarés de Ribota (Zaragoza)",
  // },
  // {
  //   municipio: "Castejón de Valdejasa",
  //   provincia: "Zaragoza",
  //   id: "50077",
  //   label: "Castejón de Valdejasa (Zaragoza)",
  // },
  // {
  //   municipio: "Castiliscar",
  //   provincia: "Zaragoza",
  //   id: "50078",
  //   label: "Castiliscar (Zaragoza)",
  // },
  // {
  //   municipio: "Cervera de la Cañada",
  //   provincia: "Zaragoza",
  //   id: "50079",
  //   label: "Cervera de la Cañada (Zaragoza)",
  // },
  // {
  //   municipio: "Cerveruela",
  //   provincia: "Zaragoza",
  //   id: "50080",
  //   label: "Cerveruela (Zaragoza)",
  // },
  // {
  //   municipio: "Cetina",
  //   provincia: "Zaragoza",
  //   id: "50081",
  //   label: "Cetina (Zaragoza)",
  // },
  // {
  //   municipio: "Cimballa",
  //   provincia: "Zaragoza",
  //   id: "50082",
  //   label: "Cimballa (Zaragoza)",
  // },
  // {
  //   municipio: "Codos",
  //   provincia: "Zaragoza",
  //   id: "50086",
  //   label: "Codos (Zaragoza)",
  // },
  // {
  //   municipio: "Cubel",
  //   provincia: "Zaragoza",
  //   id: "50090",
  //   label: "Cubel (Zaragoza)",
  // },
  // {
  //   municipio: "Contamina",
  //   provincia: "Zaragoza",
  //   id: "50087",
  //   label: "Contamina (Zaragoza)",
  // },
  // {
  //   municipio: "Cosuenda",
  //   provincia: "Zaragoza",
  //   id: "50088",
  //   label: "Cosuenda (Zaragoza)",
  // },
  // {
  //   municipio: "Cuarte de Huerva",
  //   provincia: "Zaragoza",
  //   id: "50089",
  //   label: "Cuarte de Huerva (Zaragoza)",
  // },
  // {
  //   municipio: "Chiprana",
  //   provincia: "Zaragoza",
  //   id: "50092",
  //   label: "Chiprana (Zaragoza)",
  // },
  // {
  //   municipio: "Embid de Ariza",
  //   provincia: "Zaragoza",
  //   id: "50096",
  //   label: "Embid de Ariza (Zaragoza)",
  // },
  // {
  //   municipio: "Gallocanta",
  //   provincia: "Zaragoza",
  //   id: "50117",
  //   label: "Gallocanta (Zaragoza)",
  // },
  // {
  //   municipio: "Chodes",
  //   provincia: "Zaragoza",
  //   id: "50093",
  //   label: "Chodes (Zaragoza)",
  // },
  // {
  //   municipio: "Ejea de los Caballeros",
  //   provincia: "Zaragoza",
  //   id: "50095",
  //   label: "Ejea de los Caballeros (Zaragoza)",
  // },
  // {
  //   municipio: "Isuerre",
  //   provincia: "Zaragoza",
  //   id: "50128",
  //   label: "Isuerre (Zaragoza)",
  // },
  // {
  //   municipio: "Encinacorba",
  //   provincia: "Zaragoza",
  //   id: "50098",
  //   label: "Encinacorba (Zaragoza)",
  // },
  // {
  //   municipio: "Épila",
  //   provincia: "Zaragoza",
  //   id: "50099",
  //   label: "Épila (Zaragoza)",
  // },
  // {
  //   municipio: "Erla",
  //   provincia: "Zaragoza",
  //   id: "50100",
  //   label: "Erla (Zaragoza)",
  // },
  // {
  //   municipio: "Escatrón",
  //   provincia: "Zaragoza",
  //   id: "50101",
  //   label: "Escatrón (Zaragoza)",
  // },
  // {
  //   municipio: "Fabara",
  //   provincia: "Zaragoza",
  //   id: "50102",
  //   label: "Fabara (Zaragoza)",
  // },
  // {
  //   municipio: "Farlete",
  //   provincia: "Zaragoza",
  //   id: "50104",
  //   label: "Farlete (Zaragoza)",
  // },
  // {
  //   municipio: "Fayos, Los",
  //   provincia: "Zaragoza",
  //   id: "50106",
  //   label: "Fayos, Los (Zaragoza)",
  // },
  // {
  //   municipio: "Fayón",
  //   provincia: "Zaragoza",
  //   id: "50105",
  //   label: "Fayón (Zaragoza)",
  // },
  // {
  //   municipio: "Figueruelas",
  //   provincia: "Zaragoza",
  //   id: "50107",
  //   label: "Figueruelas (Zaragoza)",
  // },
  // {
  //   municipio: "Frago, El",
  //   provincia: "Zaragoza",
  //   id: "50109",
  //   label: "Frago, El (Zaragoza)",
  // },
  // {
  //   municipio: "Frasno, El",
  //   provincia: "Zaragoza",
  //   id: "50110",
  //   label: "Frasno, El (Zaragoza)",
  // },
  // {
  //   municipio: "Fuendejalón",
  //   provincia: "Zaragoza",
  //   id: "50113",
  //   label: "Fuendejalón (Zaragoza)",
  // },
  // {
  //   municipio: "Fuendetodos",
  //   provincia: "Zaragoza",
  //   id: "50114",
  //   label: "Fuendetodos (Zaragoza)",
  // },
  // {
  //   municipio: "Fuentes de Ebro",
  //   provincia: "Zaragoza",
  //   id: "50115",
  //   label: "Fuentes de Ebro (Zaragoza)",
  // },
  // {
  //   municipio: "Joyosa, La",
  //   provincia: "Zaragoza",
  //   id: "50132",
  //   label: "Joyosa, La (Zaragoza)",
  // },
  // {
  //   municipio: "Fuentes de Jiloca",
  //   provincia: "Zaragoza",
  //   id: "50116",
  //   label: "Fuentes de Jiloca (Zaragoza)",
  // },
  // {
  //   municipio: "Longás",
  //   provincia: "Zaragoza",
  //   id: "50144",
  //   label: "Longás (Zaragoza)",
  // },
  // {
  //   municipio: "Gallur",
  //   provincia: "Zaragoza",
  //   id: "50118",
  //   label: "Gallur (Zaragoza)",
  // },
  // {
  //   municipio: "Gelsa",
  //   provincia: "Zaragoza",
  //   id: "50119",
  //   label: "Gelsa (Zaragoza)",
  // },
  // {
  //   municipio: "Godojos",
  //   provincia: "Zaragoza",
  //   id: "50120",
  //   label: "Godojos (Zaragoza)",
  // },
  // {
  //   municipio: "Gotor",
  //   provincia: "Zaragoza",
  //   id: "50121",
  //   label: "Gotor (Zaragoza)",
  // },
  // {
  //   municipio: "Grisel",
  //   provincia: "Zaragoza",
  //   id: "50122",
  //   label: "Grisel (Zaragoza)",
  // },
  // {
  //   municipio: "Grisén",
  //   provincia: "Zaragoza",
  //   id: "50123",
  //   label: "Grisén (Zaragoza)",
  // },
  // {
  //   municipio: "Herrera de los Navarros",
  //   provincia: "Zaragoza",
  //   id: "50124",
  //   label: "Herrera de los Navarros (Zaragoza)",
  // },
  // {
  //   municipio: "Jaraba",
  //   provincia: "Zaragoza",
  //   id: "50129",
  //   label: "Jaraba (Zaragoza)",
  // },
  // {
  //   municipio: "Ibdes",
  //   provincia: "Zaragoza",
  //   id: "50125",
  //   label: "Ibdes (Zaragoza)",
  // },
  // {
  //   municipio: "Illueca",
  //   provincia: "Zaragoza",
  //   id: "50126",
  //   label: "Illueca (Zaragoza)",
  // },
  // {
  //   municipio: "Jarque de Moncayo",
  //   provincia: "Zaragoza",
  //   id: "50130",
  //   label: "Jarque de Moncayo (Zaragoza)",
  // },
  // {
  //   municipio: "Jaulín",
  //   provincia: "Zaragoza",
  //   id: "50131",
  //   label: "Jaulín (Zaragoza)",
  // },
  // {
  //   municipio: "Langa del Castillo",
  //   provincia: "Zaragoza",
  //   id: "50134",
  //   label: "Langa del Castillo (Zaragoza)",
  // },
  // {
  //   municipio: "Layana",
  //   provincia: "Zaragoza",
  //   id: "50135",
  //   label: "Layana (Zaragoza)",
  // },
  // {
  //   municipio: "Mara",
  //   provincia: "Zaragoza",
  //   id: "50162",
  //   label: "Mara (Zaragoza)",
  // },
  // {
  //   municipio: "María de Huerva",
  //   provincia: "Zaragoza",
  //   id: "50163",
  //   label: "María de Huerva (Zaragoza)",
  // },
  // {
  //   municipio: "Lécera",
  //   provincia: "Zaragoza",
  //   id: "50136",
  //   label: "Lécera (Zaragoza)",
  // },
  // {
  //   municipio: "Leciñena",
  //   provincia: "Zaragoza",
  //   id: "50137",
  //   label: "Leciñena (Zaragoza)",
  // },
  // {
  //   municipio: "Lechón",
  //   provincia: "Zaragoza",
  //   id: "50138",
  //   label: "Lechón (Zaragoza)",
  // },
  // {
  //   municipio: "Talamantes",
  //   provincia: "Zaragoza",
  //   id: "50250",
  //   label: "Talamantes (Zaragoza)",
  // },
  // {
  //   municipio: "Lobera de Onsella",
  //   provincia: "Zaragoza",
  //   id: "50142",
  //   label: "Lobera de Onsella (Zaragoza)",
  // },
  // {
  //   municipio: "Longares",
  //   provincia: "Zaragoza",
  //   id: "50143",
  //   label: "Longares (Zaragoza)",
  // },
  // {
  //   municipio: "Luceni",
  //   provincia: "Zaragoza",
  //   id: "50147",
  //   label: "Luceni (Zaragoza)",
  // },
  // {
  //   municipio: "Luesia",
  //   provincia: "Zaragoza",
  //   id: "50148",
  //   label: "Luesia (Zaragoza)",
  // },
  // {
  //   municipio: "Luesma",
  //   provincia: "Zaragoza",
  //   id: "50149",
  //   label: "Luesma (Zaragoza)",
  // },
  // {
  //   municipio: "Moyuela",
  //   provincia: "Zaragoza",
  //   id: "50179",
  //   label: "Moyuela (Zaragoza)",
  // },
  // {
  //   municipio: "Lumpiaque",
  //   provincia: "Zaragoza",
  //   id: "50150",
  //   label: "Lumpiaque (Zaragoza)",
  // },
  // {
  //   municipio: "Luna",
  //   provincia: "Zaragoza",
  //   id: "50151",
  //   label: "Luna (Zaragoza)",
  // },
  // {
  //   municipio: "Mainar",
  //   provincia: "Zaragoza",
  //   id: "50154",
  //   label: "Mainar (Zaragoza)",
  // },
  // {
  //   municipio: "Maella",
  //   provincia: "Zaragoza",
  //   id: "50152",
  //   label: "Maella (Zaragoza)",
  // },
  // {
  //   municipio: "Magallón",
  //   provincia: "Zaragoza",
  //   id: "50153",
  //   label: "Magallón (Zaragoza)",
  // },
  // {
  //   municipio: "Manchones",
  //   provincia: "Zaragoza",
  //   id: "50161",
  //   label: "Manchones (Zaragoza)",
  // },
  // {
  //   municipio: "Malanquilla",
  //   provincia: "Zaragoza",
  //   id: "50155",
  //   label: "Malanquilla (Zaragoza)",
  // },
  // {
  //   municipio: "Maleján",
  //   provincia: "Zaragoza",
  //   id: "50156",
  //   label: "Maleján (Zaragoza)",
  // },
  // {
  //   municipio: "Malón",
  //   provincia: "Zaragoza",
  //   id: "50157",
  //   label: "Malón (Zaragoza)",
  // },
  // {
  //   municipio: "Maluenda",
  //   provincia: "Zaragoza",
  //   id: "50159",
  //   label: "Maluenda (Zaragoza)",
  // },
  // {
  //   municipio: "Mallén",
  //   provincia: "Zaragoza",
  //   id: "50160",
  //   label: "Mallén (Zaragoza)",
  // },
  // {
  //   municipio: "Mediana de Aragón",
  //   provincia: "Zaragoza",
  //   id: "50164",
  //   label: "Mediana de Aragón (Zaragoza)",
  // },
  // {
  //   municipio: "Mequinenza",
  //   provincia: "Zaragoza",
  //   id: "50165",
  //   label: "Mequinenza (Zaragoza)",
  // },
  // {
  //   municipio: "Mesones de Isuela",
  //   provincia: "Zaragoza",
  //   id: "50166",
  //   label: "Mesones de Isuela (Zaragoza)",
  // },
  // {
  //   municipio: "Mezalocha",
  //   provincia: "Zaragoza",
  //   id: "50167",
  //   label: "Mezalocha (Zaragoza)",
  // },
  // {
  //   municipio: "Mianos",
  //   provincia: "Zaragoza",
  //   id: "50168",
  //   label: "Mianos (Zaragoza)",
  // },
  // {
  //   municipio: "Miedes de Aragón",
  //   provincia: "Zaragoza",
  //   id: "50169",
  //   label: "Miedes de Aragón (Zaragoza)",
  // },
  // {
  //   municipio: "Monreal de Ariza",
  //   provincia: "Zaragoza",
  //   id: "50172",
  //   label: "Monreal de Ariza (Zaragoza)",
  // },
  // {
  //   municipio: "Monegrillo",
  //   provincia: "Zaragoza",
  //   id: "50170",
  //   label: "Monegrillo (Zaragoza)",
  // },
  // {
  //   municipio: "Morata de Jiloca",
  //   provincia: "Zaragoza",
  //   id: "50176",
  //   label: "Morata de Jiloca (Zaragoza)",
  // },
  // {
  //   municipio: "Moneva",
  //   provincia: "Zaragoza",
  //   id: "50171",
  //   label: "Moneva (Zaragoza)",
  // },
  // {
  //   municipio: "Oseja",
  //   provincia: "Zaragoza",
  //   id: "50198",
  //   label: "Oseja (Zaragoza)",
  // },
  // {
  //   municipio: "Monterde",
  //   provincia: "Zaragoza",
  //   id: "50173",
  //   label: "Monterde (Zaragoza)",
  // },
  // {
  //   municipio: "Montón",
  //   provincia: "Zaragoza",
  //   id: "50174",
  //   label: "Montón (Zaragoza)",
  // },
  // {
  //   municipio: "Morata de Jalón",
  //   provincia: "Zaragoza",
  //   id: "50175",
  //   label: "Morata de Jalón (Zaragoza)",
  // },
  // {
  //   municipio: "Morés",
  //   provincia: "Zaragoza",
  //   id: "50177",
  //   label: "Morés (Zaragoza)",
  // },
  // {
  //   municipio: "Moros",
  //   provincia: "Zaragoza",
  //   id: "50178",
  //   label: "Moros (Zaragoza)",
  // },
  // {
  //   municipio: "Mozota",
  //   provincia: "Zaragoza",
  //   id: "50180",
  //   label: "Mozota (Zaragoza)",
  // },
  // {
  //   municipio: "Munébrega",
  //   provincia: "Zaragoza",
  //   id: "50183",
  //   label: "Munébrega (Zaragoza)",
  // },
  // {
  //   municipio: "Muel",
  //   provincia: "Zaragoza",
  //   id: "50181",
  //   label: "Muel (Zaragoza)",
  // },
  // {
  //   municipio: "Muela, La",
  //   provincia: "Zaragoza",
  //   id: "50182",
  //   label: "Muela, La (Zaragoza)",
  // },
  // {
  //   municipio: "Murero",
  //   provincia: "Zaragoza",
  //   id: "50184",
  //   label: "Murero (Zaragoza)",
  // },
  // {
  //   municipio: "Murillo de Gállego",
  //   provincia: "Zaragoza",
  //   id: "50185",
  //   label: "Murillo de Gállego (Zaragoza)",
  // },
  // {
  //   municipio: "Nigüella",
  //   provincia: "Zaragoza",
  //   id: "50187",
  //   label: "Nigüella (Zaragoza)",
  // },
  // {
  //   municipio: "Nombrevilla",
  //   provincia: "Zaragoza",
  //   id: "50188",
  //   label: "Nombrevilla (Zaragoza)",
  // },
  // {
  //   municipio: "Nonaspe",
  //   provincia: "Zaragoza",
  //   id: "50189",
  //   label: "Nonaspe (Zaragoza)",
  // },
  // {
  //   municipio: "Navardún",
  //   provincia: "Zaragoza",
  //   id: "50186",
  //   label: "Navardún (Zaragoza)",
  // },
  // {
  //   municipio: "Pina de Ebro",
  //   provincia: "Zaragoza",
  //   id: "50208",
  //   label: "Pina de Ebro (Zaragoza)",
  // },
  // {
  //   municipio: "Santa Cruz de Moncayo",
  //   provincia: "Zaragoza",
  //   id: "50237",
  //   label: "Santa Cruz de Moncayo (Zaragoza)",
  // },
  // {
  //   municipio: "Novallas",
  //   provincia: "Zaragoza",
  //   id: "50190",
  //   label: "Novallas (Zaragoza)",
  // },
  // {
  //   municipio: "Novillas",
  //   provincia: "Zaragoza",
  //   id: "50191",
  //   label: "Novillas (Zaragoza)",
  // },
  // {
  //   municipio: "Remolinos",
  //   provincia: "Zaragoza",
  //   id: "50223",
  //   label: "Remolinos (Zaragoza)",
  // },
  // {
  //   municipio: "Nuévalos",
  //   provincia: "Zaragoza",
  //   id: "50192",
  //   label: "Nuévalos (Zaragoza)",
  // },
  // {
  //   municipio: "Nuez de Ebro",
  //   provincia: "Zaragoza",
  //   id: "50193",
  //   label: "Nuez de Ebro (Zaragoza)",
  // },
  // {
  //   municipio: "Pomer",
  //   provincia: "Zaragoza",
  //   id: "50214",
  //   label: "Pomer (Zaragoza)",
  // },
  // {
  //   municipio: "Perdiguera",
  //   provincia: "Zaragoza",
  //   id: "50206",
  //   label: "Perdiguera (Zaragoza)",
  // },
  // {
  //   municipio: "Piedratajada",
  //   provincia: "Zaragoza",
  //   id: "50207",
  //   label: "Piedratajada (Zaragoza)",
  // },
  // {
  //   municipio: "Olvés",
  //   provincia: "Zaragoza",
  //   id: "50194",
  //   label: "Olvés (Zaragoza)",
  // },
  // {
  //   municipio: "Orcajo",
  //   provincia: "Zaragoza",
  //   id: "50195",
  //   label: "Orcajo (Zaragoza)",
  // },
  // {
  //   municipio: "Orera",
  //   provincia: "Zaragoza",
  //   id: "50196",
  //   label: "Orera (Zaragoza)",
  // },
  // {
  //   municipio: "Orés",
  //   provincia: "Zaragoza",
  //   id: "50197",
  //   label: "Orés (Zaragoza)",
  // },
  // {
  //   municipio: "Osera de Ebro",
  //   provincia: "Zaragoza",
  //   id: "50199",
  //   label: "Osera de Ebro (Zaragoza)",
  // },
  // {
  //   municipio: "Paniza",
  //   provincia: "Zaragoza",
  //   id: "50200",
  //   label: "Paniza (Zaragoza)",
  // },
  // {
  //   municipio: "Paracuellos de Jiloca",
  //   provincia: "Zaragoza",
  //   id: "50201",
  //   label: "Paracuellos de Jiloca (Zaragoza)",
  // },
  // {
  //   municipio: "Paracuellos de la Ribera",
  //   provincia: "Zaragoza",
  //   id: "50202",
  //   label: "Paracuellos de la Ribera (Zaragoza)",
  // },
  // {
  //   municipio: "Pastriz",
  //   provincia: "Zaragoza",
  //   id: "50203",
  //   label: "Pastriz (Zaragoza)",
  // },
  // {
  //   municipio: "Pedrola",
  //   provincia: "Zaragoza",
  //   id: "50204",
  //   label: "Pedrola (Zaragoza)",
  // },
  // {
  //   municipio: "Pedrosas, Las",
  //   provincia: "Zaragoza",
  //   id: "50205",
  //   label: "Pedrosas, Las (Zaragoza)",
  // },
  // {
  //   municipio: "Pinseque",
  //   provincia: "Zaragoza",
  //   id: "50209",
  //   label: "Pinseque (Zaragoza)",
  // },
  // {
  //   municipio: "Pintanos, Los",
  //   provincia: "Zaragoza",
  //   id: "50210",
  //   label: "Pintanos, Los (Zaragoza)",
  // },
  // {
  //   municipio: "Pozuel de Ariza",
  //   provincia: "Zaragoza",
  //   id: "50215",
  //   label: "Pozuel de Ariza (Zaragoza)",
  // },
  // {
  //   municipio: "Plasencia de Jalón",
  //   provincia: "Zaragoza",
  //   id: "50211",
  //   label: "Plasencia de Jalón (Zaragoza)",
  // },
  // {
  //   municipio: "Pleitas",
  //   provincia: "Zaragoza",
  //   id: "50212",
  //   label: "Pleitas (Zaragoza)",
  // },
  // {
  //   municipio: "Plenas",
  //   provincia: "Zaragoza",
  //   id: "50213",
  //   label: "Plenas (Zaragoza)",
  // },
  // {
  //   municipio: "Pozuelo de Aragón",
  //   provincia: "Zaragoza",
  //   id: "50216",
  //   label: "Pozuelo de Aragón (Zaragoza)",
  // },
  // {
  //   municipio: "Pradilla de Ebro",
  //   provincia: "Zaragoza",
  //   id: "50217",
  //   label: "Pradilla de Ebro (Zaragoza)",
  // },
  // {
  //   municipio: "Puendeluna",
  //   provincia: "Zaragoza",
  //   id: "50220",
  //   label: "Puendeluna (Zaragoza)",
  // },
  // {
  //   municipio: "Puebla de Albortón",
  //   provincia: "Zaragoza",
  //   id: "50218",
  //   label: "Puebla de Albortón (Zaragoza)",
  // },
  // {
  //   municipio: "Puebla de Alfindén, La",
  //   provincia: "Zaragoza",
  //   id: "50219",
  //   label: "Puebla de Alfindén, La (Zaragoza)",
  // },
  // {
  //   municipio: "Purujosa",
  //   provincia: "Zaragoza",
  //   id: "50221",
  //   label: "Purujosa (Zaragoza)",
  // },
  // {
  //   municipio: "Retascón",
  //   provincia: "Zaragoza",
  //   id: "50224",
  //   label: "Retascón (Zaragoza)",
  // },
  // {
  //   municipio: "Ruesca",
  //   provincia: "Zaragoza",
  //   id: "50229",
  //   label: "Ruesca (Zaragoza)",
  // },
  // {
  //   municipio: "Quinto",
  //   provincia: "Zaragoza",
  //   id: "50222",
  //   label: "Quinto (Zaragoza)",
  // },
  // {
  //   municipio: "Romanos",
  //   provincia: "Zaragoza",
  //   id: "50227",
  //   label: "Romanos (Zaragoza)",
  // },
  // {
  //   municipio: "Ricla",
  //   provincia: "Zaragoza",
  //   id: "50225",
  //   label: "Ricla (Zaragoza)",
  // },
  // {
  //   municipio: "Rueda de Jalón",
  //   provincia: "Zaragoza",
  //   id: "50228",
  //   label: "Rueda de Jalón (Zaragoza)",
  // },
  // {
  //   municipio: "Sádaba",
  //   provincia: "Zaragoza",
  //   id: "50230",
  //   label: "Sádaba (Zaragoza)",
  // },
  // {
  //   municipio: "Salillas de Jalón",
  //   provincia: "Zaragoza",
  //   id: "50231",
  //   label: "Salillas de Jalón (Zaragoza)",
  // },
  // {
  //   municipio: "Santa Cruz de Grío",
  //   provincia: "Zaragoza",
  //   id: "50236",
  //   label: "Santa Cruz de Grío (Zaragoza)",
  // },
  // {
  //   municipio: "Salvatierra de Esca",
  //   provincia: "Zaragoza",
  //   id: "50232",
  //   label: "Salvatierra de Esca (Zaragoza)",
  // },
  // {
  //   municipio: "Samper del Salz",
  //   provincia: "Zaragoza",
  //   id: "50233",
  //   label: "Samper del Salz (Zaragoza)",
  // },
  // {
  //   municipio: "San Martín de la Virgen de Moncayo",
  //   provincia: "Zaragoza",
  //   id: "50234",
  //   label: "San Martín de la Virgen de Moncayo (Zaragoza)",
  // },
  // {
  //   municipio: "San Mateo de Gállego",
  //   provincia: "Zaragoza",
  //   id: "50235",
  //   label: "San Mateo de Gállego (Zaragoza)",
  // },
  // {
  //   municipio: "Santa Eulalia de Gállego",
  //   provincia: "Zaragoza",
  //   id: "50238",
  //   label: "Santa Eulalia de Gállego (Zaragoza)",
  // },
  // {
  //   municipio: "Santed",
  //   provincia: "Zaragoza",
  //   id: "50239",
  //   label: "Santed (Zaragoza)",
  // },
  // {
  //   municipio: "Sisamón",
  //   provincia: "Zaragoza",
  //   id: "50246",
  //   label: "Sisamón (Zaragoza)",
  // },
  // {
  //   municipio: "Sabiñán",
  //   provincia: "Zaragoza",
  //   id: "50241",
  //   label: "Sabiñán (Zaragoza)",
  // },
  // {
  //   municipio: "Sediles",
  //   provincia: "Zaragoza",
  //   id: "50242",
  //   label: "Sediles (Zaragoza)",
  // },
  // {
  //   municipio: "Sestrica",
  //   provincia: "Zaragoza",
  //   id: "50243",
  //   label: "Sestrica (Zaragoza)",
  // },
  // {
  //   municipio: "Sierra de Luna",
  //   provincia: "Zaragoza",
  //   id: "50244",
  //   label: "Sierra de Luna (Zaragoza)",
  // },
  // {
  //   municipio: "Sigüés",
  //   provincia: "Zaragoza",
  //   id: "50245",
  //   label: "Sigüés (Zaragoza)",
  // },
  // {
  //   municipio: "Tabuenca",
  //   provincia: "Zaragoza",
  //   id: "50249",
  //   label: "Tabuenca (Zaragoza)",
  // },
  // {
  //   municipio: "Torralba de los Frailes",
  //   provincia: "Zaragoza",
  //   id: "50256",
  //   label: "Torralba de los Frailes (Zaragoza)",
  // },
  // {
  //   municipio: "Terrer",
  //   provincia: "Zaragoza",
  //   id: "50253",
  //   label: "Terrer (Zaragoza)",
  // },
  // {
  //   municipio: "Tierga",
  //   provincia: "Zaragoza",
  //   id: "50254",
  //   label: "Tierga (Zaragoza)",
  // },
  // {
  //   municipio: "Tauste",
  //   provincia: "Zaragoza",
  //   id: "50252",
  //   label: "Tauste (Zaragoza)",
  // },
  // {
  //   municipio: "Tobed",
  //   provincia: "Zaragoza",
  //   id: "50255",
  //   label: "Tobed (Zaragoza)",
  // },
  // {
  //   municipio: "Torrellas",
  //   provincia: "Zaragoza",
  //   id: "50261",
  //   label: "Torrellas (Zaragoza)",
  // },
  // {
  //   municipio: "Trasmoz",
  //   provincia: "Zaragoza",
  //   id: "50265",
  //   label: "Trasmoz (Zaragoza)",
  // },
  // {
  //   municipio: "Torralba de Ribota",
  //   provincia: "Zaragoza",
  //   id: "50257",
  //   label: "Torralba de Ribota (Zaragoza)",
  // },
  // {
  //   municipio: "Torralbilla",
  //   provincia: "Zaragoza",
  //   id: "50258",
  //   label: "Torralbilla (Zaragoza)",
  // },
  // {
  //   municipio: "Torrehermosa",
  //   provincia: "Zaragoza",
  //   id: "50259",
  //   label: "Torrehermosa (Zaragoza)",
  // },
  // {
  //   municipio: "Torrelapaja",
  //   provincia: "Zaragoza",
  //   id: "50260",
  //   label: "Torrelapaja (Zaragoza)",
  // },
  // {
  //   municipio: "Trasobares",
  //   provincia: "Zaragoza",
  //   id: "50266",
  //   label: "Trasobares (Zaragoza)",
  // },
  // {
  //   municipio: "Uncastillo",
  //   provincia: "Zaragoza",
  //   id: "50267",
  //   label: "Uncastillo (Zaragoza)",
  // },
  // {
  //   municipio: "Torres de Berrellén",
  //   provincia: "Zaragoza",
  //   id: "50262",
  //   label: "Torres de Berrellén (Zaragoza)",
  // },
  // {
  //   municipio: "Valdehorna",
  //   provincia: "Zaragoza",
  //   id: "50273",
  //   label: "Valdehorna (Zaragoza)",
  // },
  // {
  //   municipio: "Torrijo de la Cañada",
  //   provincia: "Zaragoza",
  //   id: "50263",
  //   label: "Torrijo de la Cañada (Zaragoza)",
  // },
  // {
  //   municipio: "Tosos",
  //   provincia: "Zaragoza",
  //   id: "50264",
  //   label: "Tosos (Zaragoza)",
  // },
  // {
  //   municipio: "Undués de Lerda",
  //   provincia: "Zaragoza",
  //   id: "50268",
  //   label: "Undués de Lerda (Zaragoza)",
  // },
  // {
  //   municipio: "Urrea de Jalón",
  //   provincia: "Zaragoza",
  //   id: "50269",
  //   label: "Urrea de Jalón (Zaragoza)",
  // },
  // {
  //   municipio: "Valtorres",
  //   provincia: "Zaragoza",
  //   id: "50277",
  //   label: "Valtorres (Zaragoza)",
  // },
  // {
  //   municipio: "Used",
  //   provincia: "Zaragoza",
  //   id: "50271",
  //   label: "Used (Zaragoza)",
  // },
  // {
  //   municipio: "Utebo",
  //   provincia: "Zaragoza",
  //   id: "50272",
  //   label: "Utebo (Zaragoza)",
  // },
  // {
  //   municipio: "Villadoz",
  //   provincia: "Zaragoza",
  //   id: "50283",
  //   label: "Villadoz (Zaragoza)",
  // },
  // {
  //   municipio: "Urriés",
  //   provincia: "Zaragoza",
  //   id: "50270",
  //   label: "Urriés (Zaragoza)",
  // },
  // {
  //   municipio: "Vilueña, La",
  //   provincia: "Zaragoza",
  //   id: "50282",
  //   label: "Vilueña, La (Zaragoza)",
  // },
  // {
  //   municipio: "Vera de Moncayo",
  //   provincia: "Zaragoza",
  //   id: "50280",
  //   label: "Vera de Moncayo (Zaragoza)",
  // },
  // {
  //   municipio: "Valmadrid",
  //   provincia: "Zaragoza",
  //   id: "50275",
  //   label: "Valmadrid (Zaragoza)",
  // },
  // {
  //   municipio: "Valpalmas",
  //   provincia: "Zaragoza",
  //   id: "50276",
  //   label: "Valpalmas (Zaragoza)",
  // },
  // {
  //   municipio: "Velilla de Ebro",
  //   provincia: "Zaragoza",
  //   id: "50278",
  //   label: "Velilla de Ebro (Zaragoza)",
  // },
  // {
  //   municipio: "Velilla de Jiloca",
  //   provincia: "Zaragoza",
  //   id: "50279",
  //   label: "Velilla de Jiloca (Zaragoza)",
  // },
  // {
  //   municipio: "Marracos",
  //   provincia: "Zaragoza",
  //   id: "50902",
  //   label: "Marracos (Zaragoza)",
  // },
  // {
  //   municipio: "Villamayor de Gállego",
  //   provincia: "Zaragoza",
  //   id: "50903",
  //   label: "Villamayor de Gállego (Zaragoza)",
  // },
  // {
  //   municipio: "Melilla",
  //   provincia: "Melilla",
  //   id: "52001",
  //   label: "Melilla (Melilla)",
  // },
  // {
  //   municipio: "Villar de los Navarros",
  //   provincia: "Zaragoza",
  //   id: "50291",
  //   label: "Villar de los Navarros (Zaragoza)",
  // },
  // {
  //   municipio: "Vierlas",
  //   provincia: "Zaragoza",
  //   id: "50281",
  //   label: "Vierlas (Zaragoza)",
  // },
  // {
  //   municipio: "Villafeliche",
  //   provincia: "Zaragoza",
  //   id: "50284",
  //   label: "Villafeliche (Zaragoza)",
  // },
  // {
  //   municipio: "Villafranca de Ebro",
  //   provincia: "Zaragoza",
  //   id: "50285",
  //   label: "Villafranca de Ebro (Zaragoza)",
  // },
  // {
  //   municipio: "Villanueva de Gállego",
  //   provincia: "Zaragoza",
  //   id: "50288",
  //   label: "Villanueva de Gállego (Zaragoza)",
  // },
  // {
  //   municipio: "Zaida, La",
  //   provincia: "Zaragoza",
  //   id: "50296",
  //   label: "Zaida, La (Zaragoza)",
  // },
  // {
  //   municipio: "Villalba de Perejil",
  //   provincia: "Zaragoza",
  //   id: "50286",
  //   label: "Villalba de Perejil (Zaragoza)",
  // },
  // {
  //   municipio: "Vistabella",
  //   provincia: "Zaragoza",
  //   id: "50295",
  //   label: "Vistabella (Zaragoza)",
  // },
  // {
  //   municipio: "Villalengua",
  //   provincia: "Zaragoza",
  //   id: "50287",
  //   label: "Villalengua (Zaragoza)",
  // },
  // {
  //   municipio: "Villanueva de Jiloca",
  //   provincia: "Zaragoza",
  //   id: "50289",
  //   label: "Villanueva de Jiloca (Zaragoza)",
  // },
  // {
  //   municipio: "Villanueva de Huerva",
  //   provincia: "Zaragoza",
  //   id: "50290",
  //   label: "Villanueva de Huerva (Zaragoza)",
  // },
  // {
  //   municipio: "Villarreal de Huerva",
  //   provincia: "Zaragoza",
  //   id: "50292",
  //   label: "Villarreal de Huerva (Zaragoza)",
  // },
  // {
  //   municipio: "Villarroya de la Sierra",
  //   provincia: "Zaragoza",
  //   id: "50293",
  //   label: "Villarroya de la Sierra (Zaragoza)",
  // },
  // {
  //   municipio: "Villarroya del Campo",
  //   provincia: "Zaragoza",
  //   id: "50294",
  //   label: "Villarroya del Campo (Zaragoza)",
  // },
  // {
  //   municipio: "Biel",
  //   provincia: "Zaragoza",
  //   id: "50901",
  //   label: "Biel (Zaragoza)",
  // },
  // {
  //   municipio: "Orendain",
  //   provincia: "Guipúzcoa",
  //   id: "20905",
  //   label: "Orendain (Guipúzcoa)",
  // },
  // {
  //   municipio: "Altzaga",
  //   provincia: "Guipúzcoa",
  //   id: "20906",
  //   label: "Altzaga (Guipúzcoa)",
  // },
  // {
  //   municipio: "Monreal/Elo",
  //   provincia: "Navarra",
  //   id: "31172",
  //   label: "Monreal/Elo (Navarra)",
  // },
  // {
  //   municipio: "Abaltzisketa",
  //   provincia: "Guipúzcoa",
  //   id: "20001",
  //   label: "Abaltzisketa (Guipúzcoa)",
  // },
  // {
  //   municipio: "Monteagudo",
  //   provincia: "Navarra",
  //   id: "31173",
  //   label: "Monteagudo (Navarra)",
  // },
  // {
  //   municipio: "Aduna",
  //   provincia: "Guipúzcoa",
  //   id: "20002",
  //   label: "Aduna (Guipúzcoa)",
  // },
  // {
  //   municipio: "Alkiza",
  //   provincia: "Guipúzcoa",
  //   id: "20006",
  //   label: "Alkiza (Guipúzcoa)",
  // },
  // {
  //   municipio: "Bidania-Goiatz",
  //   provincia: "Guipúzcoa",
  //   id: "20024",
  //   label: "Bidania-Goiatz (Guipúzcoa)",
  // },
  // {
  //   municipio: "Idiazabal",
  //   provincia: "Guipúzcoa",
  //   id: "20043",
  //   label: "Idiazabal (Guipúzcoa)",
  // },
  // {
  //   municipio: "Ikaztegieta",
  //   provincia: "Guipúzcoa",
  //   id: "20044",
  //   label: "Ikaztegieta (Guipúzcoa)",
  // },
  // {
  //   municipio: "Berango",
  //   provincia: "Vizcaya",
  //   id: "48016",
  //   label: "Berango (Vizcaya)",
  // },
  // {
  //   municipio: "Leaburu",
  //   provincia: "Guipúzcoa",
  //   id: "20050",
  //   label: "Leaburu (Guipúzcoa)",
  // },
  // {
  //   municipio: "Berriatua",
  //   provincia: "Vizcaya",
  //   id: "48018",
  //   label: "Berriatua (Vizcaya)",
  // },
  // {
  //   municipio: "Bilbao",
  //   provincia: "Vizcaya",
  //   id: "48020",
  //   label: "Bilbao (Vizcaya)",
  // },
  // {
  //   municipio: "Busturia",
  //   provincia: "Vizcaya",
  //   id: "48021",
  //   label: "Busturia (Vizcaya)",
  // },
  // {
  //   municipio: "Karrantza Harana/Valle de Carranza",
  //   provincia: "Vizcaya",
  //   id: "48022",
  //   label: "Karrantza Harana/Valle de Carranza (Vizcaya)",
  // },
  // {
  //   municipio: "Hernialde",
  //   provincia: "Guipúzcoa",
  //   id: "20041",
  //   label: "Hernialde (Guipúzcoa)",
  // },
  // {
  //   municipio: "Segura",
  //   provincia: "Guipúzcoa",
  //   id: "20070",
  //   label: "Segura (Guipúzcoa)",
  // },
  // {
  //   municipio: "Ibarra",
  //   provincia: "Guipúzcoa",
  //   id: "20042",
  //   label: "Ibarra (Guipúzcoa)",
  // },
  // {
  //   municipio: "Berrobi",
  //   provincia: "Guipúzcoa",
  //   id: "20023",
  //   label: "Berrobi (Guipúzcoa)",
  // },
  // {
  //   municipio: "Donostia/San Sebastián",
  //   provincia: "Guipúzcoa",
  //   id: "20069",
  //   label: "Donostia/San Sebastián (Guipúzcoa)",
  // },
  // {
  //   municipio: "Larraul",
  //   provincia: "Guipúzcoa",
  //   id: "20048",
  //   label: "Larraul (Guipúzcoa)",
  // },
  // {
  //   municipio: "Gatika",
  //   provincia: "Vizcaya",
  //   id: "48040",
  //   label: "Gatika (Vizcaya)",
  // },
  // {
  //   municipio: "Güeñes",
  //   provincia: "Vizcaya",
  //   id: "48045",
  //   label: "Güeñes (Vizcaya)",
  // },
  // {
  //   municipio: "Lemoiz",
  //   provincia: "Vizcaya",
  //   id: "48056",
  //   label: "Lemoiz (Vizcaya)",
  // },
  // {
  //   municipio: "Gernika-Lumo",
  //   provincia: "Vizcaya",
  //   id: "48046",
  //   label: "Gernika-Lumo (Vizcaya)",
  // },
  // {
  //   municipio: "Markina-Xemein",
  //   provincia: "Vizcaya",
  //   id: "48060",
  //   label: "Markina-Xemein (Vizcaya)",
  // },
  // {
  //   municipio: "Muxika",
  //   provincia: "Vizcaya",
  //   id: "48067",
  //   label: "Muxika (Vizcaya)",
  // },
  // {
  //   municipio: "Lazkao",
  //   provincia: "Guipúzcoa",
  //   id: "20049",
  //   label: "Lazkao (Guipúzcoa)",
  // },
  // {
  //   municipio: "Mungia",
  //   provincia: "Vizcaya",
  //   id: "48069",
  //   label: "Mungia (Vizcaya)",
  // },
  // {
  //   municipio: "Errigoiti",
  //   provincia: "Vizcaya",
  //   id: "48079",
  //   label: "Errigoiti (Vizcaya)",
  // },
  // {
  //   municipio: "Bedia",
  //   provincia: "Vizcaya",
  //   id: "48092",
  //   label: "Bedia (Vizcaya)",
  // },
  // {
  //   municipio: "Igorre",
  //   provincia: "Vizcaya",
  //   id: "48094",
  //   label: "Igorre (Vizcaya)",
  // },
  // {
  //   municipio: "Zaldibar",
  //   provincia: "Vizcaya",
  //   id: "48095",
  //   label: "Zaldibar (Vizcaya)",
  // },
  // {
  //   municipio: "Ajangiz",
  //   provincia: "Vizcaya",
  //   id: "48911",
  //   label: "Ajangiz (Vizcaya)",
  // },
  // {
  //   municipio: "Morentin",
  //   provincia: "Navarra",
  //   id: "31174",
  //   label: "Morentin (Navarra)",
  // },
  // {
  //   municipio: "Legazpi",
  //   provincia: "Guipúzcoa",
  //   id: "20051",
  //   label: "Legazpi (Guipúzcoa)",
  // },
  // {
  //   municipio: "Olaberria",
  //   provincia: "Guipúzcoa",
  //   id: "20058",
  //   label: "Olaberria (Guipúzcoa)",
  // },
  // {
  //   municipio: "Desojo",
  //   provincia: "Navarra",
  //   id: "31079",
  //   label: "Desojo (Navarra)",
  // },
  // {
  //   municipio: "Vitoria-Gasteiz",
  //   provincia: "Álava",
  //   id: "01059",
  //   label: "Vitoria-Gasteiz (Álava)",
  // },
  // {
  //   municipio: "Albiztur",
  //   provincia: "Guipúzcoa",
  //   id: "20004",
  //   label: "Albiztur (Guipúzcoa)",
  // },
  // {
  //   municipio: "Urnieta",
  //   provincia: "Guipúzcoa",
  //   id: "20072",
  //   label: "Urnieta (Guipúzcoa)",
  // },
  // {
  //   municipio: "Tolosa",
  //   provincia: "Guipúzcoa",
  //   id: "20071",
  //   label: "Tolosa (Guipúzcoa)",
  // },
  // {
  //   municipio: "Zarautz",
  //   provincia: "Guipúzcoa",
  //   id: "20079",
  //   label: "Zarautz (Guipúzcoa)",
  // },
  // {
  //   municipio: "Zumaia",
  //   provincia: "Guipúzcoa",
  //   id: "20081",
  //   label: "Zumaia (Guipúzcoa)",
  // },
  // {
  //   municipio: "Bakio",
  //   provincia: "Vizcaya",
  //   id: "48012",
  //   label: "Bakio (Vizcaya)",
  // },
  // {
  //   municipio: "Bermeo",
  //   provincia: "Vizcaya",
  //   id: "48017",
  //   label: "Bermeo (Vizcaya)",
  // },
  // {
  //   municipio: "Berriz",
  //   provincia: "Vizcaya",
  //   id: "48019",
  //   label: "Berriz (Vizcaya)",
  // },
  // {
  //   municipio: "Zeanuri",
  //   provincia: "Vizcaya",
  //   id: "48024",
  //   label: "Zeanuri (Vizcaya)",
  // },
  // {
  //   municipio: "Dima",
  //   provincia: "Vizcaya",
  //   id: "48026",
  //   label: "Dima (Vizcaya)",
  // },
  // {
  //   municipio: "Ea",
  //   provincia: "Vizcaya",
  //   id: "48028",
  //   label: "Ea (Vizcaya)",
  // },
  // {
  //   municipio: "Etxebarria",
  //   provincia: "Vizcaya",
  //   id: "48030",
  //   label: "Etxebarria (Vizcaya)",
  // },
  // {
  //   municipio: "Galdakao",
  //   provincia: "Vizcaya",
  //   id: "48036",
  //   label: "Galdakao (Vizcaya)",
  // },
  // {
  //   municipio: "Galdames",
  //   provincia: "Vizcaya",
  //   id: "48037",
  //   label: "Galdames (Vizcaya)",
  // },
  // {
  //   municipio: "Iruraiz-Gauna",
  //   provincia: "Álava",
  //   id: "01027",
  //   label: "Iruraiz-Gauna (Álava)",
  // },
  // {
  //   municipio: "Usurbil",
  //   provincia: "Guipúzcoa",
  //   id: "20073",
  //   label: "Usurbil (Guipúzcoa)",
  // },
  // {
  //   municipio: "Bergara",
  //   provincia: "Guipúzcoa",
  //   id: "20074",
  //   label: "Bergara (Guipúzcoa)",
  // },
  // {
  //   municipio: "Villabona",
  //   provincia: "Guipúzcoa",
  //   id: "20075",
  //   label: "Villabona (Guipúzcoa)",
  // },
  // {
  //   municipio: "Ordizia",
  //   provincia: "Guipúzcoa",
  //   id: "20076",
  //   label: "Ordizia (Guipúzcoa)",
  // },
  // {
  //   municipio: "Urretxu",
  //   provincia: "Guipúzcoa",
  //   id: "20077",
  //   label: "Urretxu (Guipúzcoa)",
  // },
  // {
  //   municipio: "Zaldibia",
  //   provincia: "Guipúzcoa",
  //   id: "20078",
  //   label: "Zaldibia (Guipúzcoa)",
  // },
  // {
  //   municipio: "Zumarraga",
  //   provincia: "Guipúzcoa",
  //   id: "20080",
  //   label: "Zumarraga (Guipúzcoa)",
  // },
  // {
  //   municipio: "Elantxobe",
  //   provincia: "Vizcaya",
  //   id: "48031",
  //   label: "Elantxobe (Vizcaya)",
  // },
  // {
  //   municipio: "Mendaro",
  //   provincia: "Guipúzcoa",
  //   id: "20901",
  //   label: "Mendaro (Guipúzcoa)",
  // },
  // {
  //   municipio: "Lasarte-Oria",
  //   provincia: "Guipúzcoa",
  //   id: "20902",
  //   label: "Lasarte-Oria (Guipúzcoa)",
  // },
  // {
  //   municipio: "Elorrio",
  //   provincia: "Vizcaya",
  //   id: "48032",
  //   label: "Elorrio (Vizcaya)",
  // },
  // {
  //   municipio: "Ereño",
  //   provincia: "Vizcaya",
  //   id: "48033",
  //   label: "Ereño (Vizcaya)",
  // },
  // {
  //   municipio: "Ermua",
  //   provincia: "Vizcaya",
  //   id: "48034",
  //   label: "Ermua (Vizcaya)",
  // },
  // {
  //   municipio: "Astigarraga",
  //   provincia: "Guipúzcoa",
  //   id: "20903",
  //   label: "Astigarraga (Guipúzcoa)",
  // },
  // {
  //   municipio: "Baliarrain",
  //   provincia: "Guipúzcoa",
  //   id: "20904",
  //   label: "Baliarrain (Guipúzcoa)",
  // },
  // {
  //   municipio: "Fruiz",
  //   provincia: "Vizcaya",
  //   id: "48035",
  //   label: "Fruiz (Vizcaya)",
  // },
  // {
  //   municipio: "Gamiz-Fika",
  //   provincia: "Vizcaya",
  //   id: "48038",
  //   label: "Gamiz-Fika (Vizcaya)",
  // },
  // {
  //   municipio: "Gaztelu",
  //   provincia: "Guipúzcoa",
  //   id: "20907",
  //   label: "Gaztelu (Guipúzcoa)",
  // },
  // {
  //   municipio: "Abadiño",
  //   provincia: "Vizcaya",
  //   id: "48001",
  //   label: "Abadiño (Vizcaya)",
  // },
  // {
  //   municipio: "Arakaldo",
  //   provincia: "Vizcaya",
  //   id: "48005",
  //   label: "Arakaldo (Vizcaya)",
  // },
  // {
  //   municipio: "Arantzazu",
  //   provincia: "Vizcaya",
  //   id: "48006",
  //   label: "Arantzazu (Vizcaya)",
  // },
  // {
  //   municipio: "Abanto y Ciérvana-Abanto Zierbena",
  //   provincia: "Vizcaya",
  //   id: "48002",
  //   label: "Abanto y Ciérvana-Abanto Zierbena (Vizcaya)",
  // },
  // {
  //   municipio: "Amorebieta-Etxano",
  //   provincia: "Vizcaya",
  //   id: "48003",
  //   label: "Amorebieta-Etxano (Vizcaya)",
  // },
  // {
  //   municipio: "Lanestosa",
  //   provincia: "Vizcaya",
  //   id: "48051",
  //   label: "Lanestosa (Vizcaya)",
  // },
  // {
  //   municipio: "Amoroto",
  //   provincia: "Vizcaya",
  //   id: "48004",
  //   label: "Amoroto (Vizcaya)",
  // },
  // {
  //   municipio: "Larrabetzu",
  //   provincia: "Vizcaya",
  //   id: "48052",
  //   label: "Larrabetzu (Vizcaya)",
  // },
  // {
  //   municipio: "Laukiz",
  //   provincia: "Vizcaya",
  //   id: "48053",
  //   label: "Laukiz (Vizcaya)",
  // },
  // {
  //   municipio: "Basauri",
  //   provincia: "Vizcaya",
  //   id: "48015",
  //   label: "Basauri (Vizcaya)",
  // },
  // {
  //   municipio: "Aguilar de Codés",
  //   provincia: "Navarra",
  //   id: "31008",
  //   label: "Aguilar de Codés (Navarra)",
  // },
  // {
  //   municipio: "Baztan",
  //   provincia: "Navarra",
  //   id: "31050",
  //   label: "Baztan (Navarra)",
  // },
  // {
  //   municipio: "Goizueta",
  //   provincia: "Navarra",
  //   id: "31117",
  //   label: "Goizueta (Navarra)",
  // },
  // {
  //   municipio: "Isaba/Izaba",
  //   provincia: "Navarra",
  //   id: "31128",
  //   label: "Isaba/Izaba (Navarra)",
  // },
  // {
  //   municipio: "Larraun",
  //   provincia: "Navarra",
  //   id: "31144",
  //   label: "Larraun (Navarra)",
  // },
  // {
  //   municipio: "Peralta/Azkoien",
  //   provincia: "Navarra",
  //   id: "31202",
  //   label: "Peralta/Azkoien (Navarra)",
  // },
  // {
  //   municipio: "Munitibar-Arbatzegi Gerrikaitz",
  //   provincia: "Vizcaya",
  //   id: "48007",
  //   label: "Munitibar-Arbatzegi Gerrikaitz (Vizcaya)",
  // },
  // {
  //   municipio: "Murueta",
  //   provincia: "Vizcaya",
  //   id: "48908",
  //   label: "Murueta (Vizcaya)",
  // },
  // {
  //   municipio: "Artzentales",
  //   provincia: "Vizcaya",
  //   id: "48008",
  //   label: "Artzentales (Vizcaya)",
  // },
  // {
  //   municipio: "Arrigorriaga",
  //   provincia: "Vizcaya",
  //   id: "48011",
  //   label: "Arrigorriaga (Vizcaya)",
  // },
  // {
  //   municipio: "Barakaldo",
  //   provincia: "Vizcaya",
  //   id: "48013",
  //   label: "Barakaldo (Vizcaya)",
  // },
  // {
  //   municipio: "Barrika",
  //   provincia: "Vizcaya",
  //   id: "48014",
  //   label: "Barrika (Vizcaya)",
  // },
  // {
  //   municipio: "Arrankudiaga-Zollo",
  //   provincia: "Vizcaya",
  //   id: "48009",
  //   label: "Arrankudiaga-Zollo (Vizcaya)",
  // },
  // {
  //   municipio: "Arrieta",
  //   provincia: "Vizcaya",
  //   id: "48010",
  //   label: "Arrieta (Vizcaya)",
  // },
  // {
  //   municipio: "Artea",
  //   provincia: "Vizcaya",
  //   id: "48023",
  //   label: "Artea (Vizcaya)",
  // },
  // {
  //   municipio: "Zeberio",
  //   provincia: "Vizcaya",
  //   id: "48025",
  //   label: "Zeberio (Vizcaya)",
  // },
  // {
  //   municipio: "Durango",
  //   provincia: "Vizcaya",
  //   id: "48027",
  //   label: "Durango (Vizcaya)",
  // },
  // {
  //   municipio: "Gautegiz Arteaga",
  //   provincia: "Vizcaya",
  //   id: "48041",
  //   label: "Gautegiz Arteaga (Vizcaya)",
  // },
  // {
  //   municipio: "Etxebarri",
  //   provincia: "Vizcaya",
  //   id: "48029",
  //   label: "Etxebarri (Vizcaya)",
  // },
  // {
  //   municipio: "Gordexola",
  //   provincia: "Vizcaya",
  //   id: "48042",
  //   label: "Gordexola (Vizcaya)",
  // },
  // {
  //   municipio: "Gorliz",
  //   provincia: "Vizcaya",
  //   id: "48043",
  //   label: "Gorliz (Vizcaya)",
  // },
  // {
  //   municipio: "Getxo",
  //   provincia: "Vizcaya",
  //   id: "48044",
  //   label: "Getxo (Vizcaya)",
  // },
  // {
  //   municipio: "Garai",
  //   provincia: "Vizcaya",
  //   id: "48039",
  //   label: "Garai (Vizcaya)",
  // },
  // {
  //   municipio: "Izurtza",
  //   provincia: "Vizcaya",
  //   id: "48050",
  //   label: "Izurtza (Vizcaya)",
  // },
  // {
  //   municipio: "Gizaburuaga",
  //   provincia: "Vizcaya",
  //   id: "48047",
  //   label: "Gizaburuaga (Vizcaya)",
  // },
  // {
  //   municipio: "Ibarrangelu",
  //   provincia: "Vizcaya",
  //   id: "48048",
  //   label: "Ibarrangelu (Vizcaya)",
  // },
  // {
  //   municipio: "Ispaster",
  //   provincia: "Vizcaya",
  //   id: "48049",
  //   label: "Ispaster (Vizcaya)",
  // },
  // {
  //   municipio: "Añana",
  //   provincia: "Álava",
  //   id: "01049",
  //   label: "Añana (Álava)",
  // },
  // {
  //   municipio: "Leioa",
  //   provincia: "Vizcaya",
  //   id: "48054",
  //   label: "Leioa (Vizcaya)",
  // },
  // {
  //   municipio: "Lemoa",
  //   provincia: "Vizcaya",
  //   id: "48055",
  //   label: "Lemoa (Vizcaya)",
  // },
  // {
  //   municipio: "Villabuena de Álava/Eskuernaga",
  //   provincia: "Álava",
  //   id: "01057",
  //   label: "Villabuena de Álava/Eskuernaga (Álava)",
  // },
  // {
  //   municipio: "Lekeitio",
  //   provincia: "Vizcaya",
  //   id: "48057",
  //   label: "Lekeitio (Vizcaya)",
  // },
  // {
  //   municipio: "Elvillar/Bilar",
  //   provincia: "Álava",
  //   id: "01023",
  //   label: "Elvillar/Bilar (Álava)",
  // },
  // {
  //   municipio: "Mallabia",
  //   provincia: "Vizcaya",
  //   id: "48058",
  //   label: "Mallabia (Vizcaya)",
  // },
  // {
  //   municipio: "Mañaria",
  //   provincia: "Vizcaya",
  //   id: "48059",
  //   label: "Mañaria (Vizcaya)",
  // },
  // {
  //   municipio: "Maruri-Jatabe",
  //   provincia: "Vizcaya",
  //   id: "48061",
  //   label: "Maruri-Jatabe (Vizcaya)",
  // },
  // {
  //   municipio: "Mendata",
  //   provincia: "Vizcaya",
  //   id: "48062",
  //   label: "Mendata (Vizcaya)",
  // },
  // {
  //   municipio: "Portugalete",
  //   provincia: "Vizcaya",
  //   id: "48078",
  //   label: "Portugalete (Vizcaya)",
  // },
  // {
  //   municipio: "Mendexa",
  //   provincia: "Vizcaya",
  //   id: "48063",
  //   label: "Mendexa (Vizcaya)",
  // },
  // {
  //   municipio: "Meñaka",
  //   provincia: "Vizcaya",
  //   id: "48064",
  //   label: "Meñaka (Vizcaya)",
  // },
  // {
  //   municipio: "Ugao-Miraballes",
  //   provincia: "Vizcaya",
  //   id: "48065",
  //   label: "Ugao-Miraballes (Vizcaya)",
  // },
  // {
  //   municipio: "Morga",
  //   provincia: "Vizcaya",
  //   id: "48066",
  //   label: "Morga (Vizcaya)",
  // },
  // {
  //   municipio: "Mundaka",
  //   provincia: "Vizcaya",
  //   id: "48068",
  //   label: "Mundaka (Vizcaya)",
  // },
  // {
  //   municipio: "Aulesti",
  //   provincia: "Vizcaya",
  //   id: "48070",
  //   label: "Aulesti (Vizcaya)",
  // },
  // {
  //   municipio: "Muskiz",
  //   provincia: "Vizcaya",
  //   id: "48071",
  //   label: "Muskiz (Vizcaya)",
  // },
  // {
  //   municipio: "Otxandio",
  //   provincia: "Vizcaya",
  //   id: "48072",
  //   label: "Otxandio (Vizcaya)",
  // },
  // {
  //   municipio: "Ondarroa",
  //   provincia: "Vizcaya",
  //   id: "48073",
  //   label: "Ondarroa (Vizcaya)",
  // },
  // {
  //   municipio: "Urduña/Orduña",
  //   provincia: "Vizcaya",
  //   id: "48074",
  //   label: "Urduña/Orduña (Vizcaya)",
  // },
  // {
  //   municipio: "Orozko",
  //   provincia: "Vizcaya",
  //   id: "48075",
  //   label: "Orozko (Vizcaya)",
  // },
  // {
  //   municipio: "Sukarrieta",
  //   provincia: "Vizcaya",
  //   id: "48076",
  //   label: "Sukarrieta (Vizcaya)",
  // },
  // {
  //   municipio: "Plentzia",
  //   provincia: "Vizcaya",
  //   id: "48077",
  //   label: "Plentzia (Vizcaya)",
  // },
  // {
  //   municipio: "Valle de Trápaga-Trapagaran",
  //   provincia: "Vizcaya",
  //   id: "48080",
  //   label: "Valle de Trápaga-Trapagaran (Vizcaya)",
  // },
  // {
  //   municipio: "Santurtzi",
  //   provincia: "Vizcaya",
  //   id: "48082",
  //   label: "Santurtzi (Vizcaya)",
  // },
  // {
  //   municipio: "Lezama",
  //   provincia: "Vizcaya",
  //   id: "48081",
  //   label: "Lezama (Vizcaya)",
  // },
  // {
  //   municipio: "Sestao",
  //   provincia: "Vizcaya",
  //   id: "48084",
  //   label: "Sestao (Vizcaya)",
  // },
  // {
  //   municipio: "Ortuella",
  //   provincia: "Vizcaya",
  //   id: "48083",
  //   label: "Ortuella (Vizcaya)",
  // },
  // {
  //   municipio: "Aibar/Oibar",
  //   provincia: "Navarra",
  //   id: "31009",
  //   label: "Aibar/Oibar (Navarra)",
  // },
  // {
  //   municipio: "Sopela",
  //   provincia: "Vizcaya",
  //   id: "48085",
  //   label: "Sopela (Vizcaya)",
  // },
  // {
  //   municipio: "Sopuerta",
  //   provincia: "Vizcaya",
  //   id: "48086",
  //   label: "Sopuerta (Vizcaya)",
  // },
  // {
  //   municipio: "Trucios-Turtzioz",
  //   provincia: "Vizcaya",
  //   id: "48087",
  //   label: "Trucios-Turtzioz (Vizcaya)",
  // },
  // {
  //   municipio: "Zaratamo",
  //   provincia: "Vizcaya",
  //   id: "48097",
  //   label: "Zaratamo (Vizcaya)",
  // },
  // {
  //   municipio: "Ubide",
  //   provincia: "Vizcaya",
  //   id: "48088",
  //   label: "Ubide (Vizcaya)",
  // },
  // {
  //   municipio: "Urduliz",
  //   provincia: "Vizcaya",
  //   id: "48089",
  //   label: "Urduliz (Vizcaya)",
  // },
  // {
  //   municipio: "Balmaseda",
  //   provincia: "Vizcaya",
  //   id: "48090",
  //   label: "Balmaseda (Vizcaya)",
  // },
  // {
  //   municipio: "Derio",
  //   provincia: "Vizcaya",
  //   id: "48901",
  //   label: "Derio (Vizcaya)",
  // },
  // {
  //   municipio: "Erandio",
  //   provincia: "Vizcaya",
  //   id: "48902",
  //   label: "Erandio (Vizcaya)",
  // },
  // {
  //   municipio: "Loiu",
  //   provincia: "Vizcaya",
  //   id: "48903",
  //   label: "Loiu (Vizcaya)",
  // },
  // {
  //   municipio: "Atxondo",
  //   provincia: "Vizcaya",
  //   id: "48091",
  //   label: "Atxondo (Vizcaya)",
  // },
  // {
  //   municipio: "Areatza",
  //   provincia: "Vizcaya",
  //   id: "48093",
  //   label: "Areatza (Vizcaya)",
  // },
  // {
  //   municipio: "Zalla",
  //   provincia: "Vizcaya",
  //   id: "48096",
  //   label: "Zalla (Vizcaya)",
  // },
  // {
  //   municipio: "Aribe",
  //   provincia: "Navarra",
  //   id: "31034",
  //   label: "Aribe (Navarra)",
  // },
  // {
  //   municipio: "Sondika",
  //   provincia: "Vizcaya",
  //   id: "48904",
  //   label: "Sondika (Vizcaya)",
  // },
  // {
  //   municipio: "Zamudio",
  //   provincia: "Vizcaya",
  //   id: "48905",
  //   label: "Zamudio (Vizcaya)",
  // },
  // {
  //   municipio: "Forua",
  //   provincia: "Vizcaya",
  //   id: "48906",
  //   label: "Forua (Vizcaya)",
  // },
  // {
  //   municipio: "Kortezubi",
  //   provincia: "Vizcaya",
  //   id: "48907",
  //   label: "Kortezubi (Vizcaya)",
  // },
  // {
  //   municipio: "Nabarniz",
  //   provincia: "Vizcaya",
  //   id: "48909",
  //   label: "Nabarniz (Vizcaya)",
  // },
  // {
  //   municipio: "Iurreta",
  //   provincia: "Vizcaya",
  //   id: "48910",
  //   label: "Iurreta (Vizcaya)",
  // },
  // {
  //   municipio: "Alonsotegi",
  //   provincia: "Vizcaya",
  //   id: "48912",
  //   label: "Alonsotegi (Vizcaya)",
  // },
  // {
  //   municipio: "Zierbena",
  //   provincia: "Vizcaya",
  //   id: "48913",
  //   label: "Zierbena (Vizcaya)",
  // },
  // {
  //   municipio: "Arratzu",
  //   provincia: "Vizcaya",
  //   id: "48914",
  //   label: "Arratzu (Vizcaya)",
  // },
  // {
  //   municipio: "Ziortza-Bolibar",
  //   provincia: "Vizcaya",
  //   id: "48915",
  //   label: "Ziortza-Bolibar (Vizcaya)",
  // },
  // {
  //   municipio: "Aberin",
  //   provincia: "Navarra",
  //   id: "31005",
  //   label: "Aberin (Navarra)",
  // },
  // {
  //   municipio: "Ablitas",
  //   provincia: "Navarra",
  //   id: "31006",
  //   label: "Ablitas (Navarra)",
  // },
  // {
  //   municipio: "Abárzuza/Abartzuza",
  //   provincia: "Navarra",
  //   id: "31002",
  //   label: "Abárzuza/Abartzuza (Navarra)",
  // },
  // {
  //   municipio: "Abaurregaina/Abaurrea Alta",
  //   provincia: "Navarra",
  //   id: "31003",
  //   label: "Abaurregaina/Abaurrea Alta (Navarra)",
  // },
  // {
  //   municipio: "Adiós",
  //   provincia: "Navarra",
  //   id: "31007",
  //   label: "Adiós (Navarra)",
  // },
  // {
  //   municipio: "Ansoáin/Antsoain",
  //   provincia: "Navarra",
  //   id: "31016",
  //   label: "Ansoáin/Antsoain (Navarra)",
  // },
  // {
  //   municipio: "Altsasu/Alsasua",
  //   provincia: "Navarra",
  //   id: "31010",
  //   label: "Altsasu/Alsasua (Navarra)",
  // },
  // {
  //   municipio: "Allín/Allin",
  //   provincia: "Navarra",
  //   id: "31011",
  //   label: "Allín/Allin (Navarra)",
  // },
  // {
  //   municipio: "Allo",
  //   provincia: "Navarra",
  //   id: "31012",
  //   label: "Allo (Navarra)",
  // },
  // {
  //   municipio: "Alegría-Dulantzi",
  //   provincia: "Álava",
  //   id: "01001",
  //   label: "Alegría-Dulantzi (Álava)",
  // },
  // {
  //   municipio: "Amurrio",
  //   provincia: "Álava",
  //   id: "01002",
  //   label: "Amurrio (Álava)",
  // },
  // {
  //   municipio: "Améscoa Baja",
  //   provincia: "Navarra",
  //   id: "31013",
  //   label: "Améscoa Baja (Navarra)",
  // },
  // {
  //   municipio: "Ancín/Antzin",
  //   provincia: "Navarra",
  //   id: "31014",
  //   label: "Ancín/Antzin (Navarra)",
  // },
  // {
  //   municipio: "Andosilla",
  //   provincia: "Navarra",
  //   id: "31015",
  //   label: "Andosilla (Navarra)",
  // },
  // {
  //   municipio: "Leache/Leatxe",
  //   provincia: "Navarra",
  //   id: "31146",
  //   label: "Leache/Leatxe (Navarra)",
  // },
  // {
  //   municipio: "Aramaio",
  //   provincia: "Álava",
  //   id: "01003",
  //   label: "Aramaio (Álava)",
  // },
  // {
  //   municipio: "Artziniega",
  //   provincia: "Álava",
  //   id: "01004",
  //   label: "Artziniega (Álava)",
  // },
  // {
  //   municipio: "Zegama",
  //   provincia: "Guipúzcoa",
  //   id: "20025",
  //   label: "Zegama (Guipúzcoa)",
  // },
  // {
  //   municipio: "Armiñón",
  //   provincia: "Álava",
  //   id: "01006",
  //   label: "Armiñón (Álava)",
  // },
  // {
  //   municipio: "Arratzua-Ubarrundia",
  //   provincia: "Álava",
  //   id: "01008",
  //   label: "Arratzua-Ubarrundia (Álava)",
  // },
  // {
  //   municipio: "Baños de Ebro/Mañueta",
  //   provincia: "Álava",
  //   id: "01011",
  //   label: "Baños de Ebro/Mañueta (Álava)",
  // },
  // {
  //   municipio: "Zerain",
  //   provincia: "Guipúzcoa",
  //   id: "20026",
  //   label: "Zerain (Guipúzcoa)",
  // },
  // {
  //   municipio: "Asparrena",
  //   provincia: "Álava",
  //   id: "01009",
  //   label: "Asparrena (Álava)",
  // },
  // {
  //   municipio: "Ayala/Aiara",
  //   provincia: "Álava",
  //   id: "01010",
  //   label: "Ayala/Aiara (Álava)",
  // },
  // {
  //   municipio: "Barrundia",
  //   provincia: "Álava",
  //   id: "01013",
  //   label: "Barrundia (Álava)",
  // },
  // {
  //   municipio: "Berantevilla",
  //   provincia: "Álava",
  //   id: "01014",
  //   label: "Berantevilla (Álava)",
  // },
  // {
  //   municipio: "Elciego",
  //   provincia: "Álava",
  //   id: "01022",
  //   label: "Elciego (Álava)",
  // },
  // {
  //   municipio: "Bernedo",
  //   provincia: "Álava",
  //   id: "01016",
  //   label: "Bernedo (Álava)",
  // },
  // {
  //   municipio: "Campezo/Kanpezu",
  //   provincia: "Álava",
  //   id: "01017",
  //   label: "Campezo/Kanpezu (Álava)",
  // },
  // {
  //   municipio: "Zigoitia",
  //   provincia: "Álava",
  //   id: "01018",
  //   label: "Zigoitia (Álava)",
  // },
  // {
  //   municipio: "Kripan",
  //   provincia: "Álava",
  //   id: "01019",
  //   label: "Kripan (Álava)",
  // },
  // {
  //   municipio: "Kuartango",
  //   provincia: "Álava",
  //   id: "01020",
  //   label: "Kuartango (Álava)",
  // },
  // {
  //   municipio: "Areso",
  //   provincia: "Navarra",
  //   id: "31031",
  //   label: "Areso (Navarra)",
  // },
  // {
  //   municipio: "Elburgo/Burgelu",
  //   provincia: "Álava",
  //   id: "01021",
  //   label: "Elburgo/Burgelu (Álava)",
  // },
  // {
  //   municipio: "Legarda",
  //   provincia: "Navarra",
  //   id: "31147",
  //   label: "Legarda (Navarra)",
  // },
  // {
  //   municipio: "Labastida/Bastida",
  //   provincia: "Álava",
  //   id: "01028",
  //   label: "Labastida/Bastida (Álava)",
  // },
  // {
  //   municipio: "Lagrán",
  //   provincia: "Álava",
  //   id: "01030",
  //   label: "Lagrán (Álava)",
  // },
  // {
  //   municipio: "Laguardia",
  //   provincia: "Álava",
  //   id: "01031",
  //   label: "Laguardia (Álava)",
  // },
  // {
  //   municipio: "Lanciego/Lantziego",
  //   provincia: "Álava",
  //   id: "01032",
  //   label: "Lanciego/Lantziego (Álava)",
  // },
  // {
  //   municipio: "Lapuebla de Labarca",
  //   provincia: "Álava",
  //   id: "01033",
  //   label: "Lapuebla de Labarca (Álava)",
  // },
  // {
  //   municipio: "Leza",
  //   provincia: "Álava",
  //   id: "01034",
  //   label: "Leza (Álava)",
  // },
  // {
  //   municipio: "Olaibar",
  //   provincia: "Navarra",
  //   id: "31188",
  //   label: "Olaibar (Navarra)",
  // },
  // {
  //   municipio: "Laudio/Llodio",
  //   provincia: "Álava",
  //   id: "01036",
  //   label: "Laudio/Llodio (Álava)",
  // },
  // {
  //   municipio: "Mues",
  //   provincia: "Navarra",
  //   id: "31175",
  //   label: "Mues (Navarra)",
  // },
  // {
  //   municipio: "Arraia-Maeztu",
  //   provincia: "Álava",
  //   id: "01037",
  //   label: "Arraia-Maeztu (Álava)",
  // },
  // {
  //   municipio: "Moreda de Álava/Moreda Araba",
  //   provincia: "Álava",
  //   id: "01039",
  //   label: "Moreda de Álava/Moreda Araba (Álava)",
  // },
  // {
  //   municipio: "Anue",
  //   provincia: "Navarra",
  //   id: "31017",
  //   label: "Anue (Navarra)",
  // },
  // {
  //   municipio: "Gabiria",
  //   provincia: "Guipúzcoa",
  //   id: "20038",
  //   label: "Gabiria (Guipúzcoa)",
  // },
  // {
  //   municipio: "Añorbe",
  //   provincia: "Navarra",
  //   id: "31018",
  //   label: "Añorbe (Navarra)",
  // },
  // {
  //   municipio: "Navaridas",
  //   provincia: "Álava",
  //   id: "01041",
  //   label: "Navaridas (Álava)",
  // },
  // {
  //   municipio: "Iruña Oka/Iruña de Oca",
  //   provincia: "Álava",
  //   id: "01901",
  //   label: "Iruña Oka/Iruña de Oca (Álava)",
  // },
  // {
  //   municipio: "Okondo",
  //   provincia: "Álava",
  //   id: "01042",
  //   label: "Okondo (Álava)",
  // },
  // {
  //   municipio: "Lantarón",
  //   provincia: "Álava",
  //   id: "01902",
  //   label: "Lantarón (Álava)",
  // },
  // {
  //   municipio: "Oyón-Oion",
  //   provincia: "Álava",
  //   id: "01043",
  //   label: "Oyón-Oion (Álava)",
  // },
  // {
  //   municipio: "Erriberabeitia",
  //   provincia: "Álava",
  //   id: "01047",
  //   label: "Erriberabeitia (Álava)",
  // },
  // {
  //   municipio: "Arrasate/Mondragón",
  //   provincia: "Guipúzcoa",
  //   id: "20055",
  //   label: "Arrasate/Mondragón (Guipúzcoa)",
  // },
  // {
  //   municipio: "Mutriku",
  //   provincia: "Guipúzcoa",
  //   id: "20056",
  //   label: "Mutriku (Guipúzcoa)",
  // },
  // {
  //   municipio: "Peñacerrada-Urizaharra",
  //   provincia: "Álava",
  //   id: "01044",
  //   label: "Peñacerrada-Urizaharra (Álava)",
  // },
  // {
  //   municipio: "Erriberagoitia/Ribera Alta",
  //   provincia: "Álava",
  //   id: "01046",
  //   label: "Erriberagoitia/Ribera Alta (Álava)",
  // },
  // {
  //   municipio: "Mutiloa",
  //   provincia: "Guipúzcoa",
  //   id: "20057",
  //   label: "Mutiloa (Guipúzcoa)",
  // },
  // {
  //   municipio: "Agurain/Salvatierra",
  //   provincia: "Álava",
  //   id: "01051",
  //   label: "Agurain/Salvatierra (Álava)",
  // },
  // {
  //   municipio: "Samaniego",
  //   provincia: "Álava",
  //   id: "01052",
  //   label: "Samaniego (Álava)",
  // },
  // {
  //   municipio: "San Millán/Donemiliaga",
  //   provincia: "Álava",
  //   id: "01053",
  //   label: "San Millán/Donemiliaga (Álava)",
  // },
  // {
  //   municipio: "Ormaiztegi",
  //   provincia: "Guipúzcoa",
  //   id: "20062",
  //   label: "Ormaiztegi (Guipúzcoa)",
  // },
  // {
  //   municipio: "Urkabustaiz",
  //   provincia: "Álava",
  //   id: "01054",
  //   label: "Urkabustaiz (Álava)",
  // },
  // {
  //   municipio: "Valdegovía/Gaubea",
  //   provincia: "Álava",
  //   id: "01055",
  //   label: "Valdegovía/Gaubea (Álava)",
  // },
  // {
  //   municipio: "Oiartzun",
  //   provincia: "Guipúzcoa",
  //   id: "20063",
  //   label: "Oiartzun (Guipúzcoa)",
  // },
  // {
  //   municipio: "Pasaia",
  //   provincia: "Guipúzcoa",
  //   id: "20064",
  //   label: "Pasaia (Guipúzcoa)",
  // },
  // {
  //   municipio: "Harana/Valle de Arana",
  //   provincia: "Álava",
  //   id: "01056",
  //   label: "Harana/Valle de Arana (Álava)",
  // },
  // {
  //   municipio: "Aoiz/Agoitz",
  //   provincia: "Navarra",
  //   id: "31019",
  //   label: "Aoiz/Agoitz (Navarra)",
  // },
  // {
  //   municipio: "Legutio",
  //   provincia: "Álava",
  //   id: "01058",
  //   label: "Legutio (Álava)",
  // },
  // {
  //   municipio: "Yécora/Iekora",
  //   provincia: "Álava",
  //   id: "01060",
  //   label: "Yécora/Iekora (Álava)",
  // },
  // {
  //   municipio: "Zalduondo",
  //   provincia: "Álava",
  //   id: "01061",
  //   label: "Zalduondo (Álava)",
  // },
  // {
  //   municipio: "Araitz",
  //   provincia: "Navarra",
  //   id: "31020",
  //   label: "Araitz (Navarra)",
  // },
  // {
  //   municipio: "Zambrana",
  //   provincia: "Álava",
  //   id: "01062",
  //   label: "Zambrana (Álava)",
  // },
  // {
  //   municipio: "Aranarache/Aranaratxe",
  //   provincia: "Navarra",
  //   id: "31021",
  //   label: "Aranarache/Aranaratxe (Navarra)",
  // },
  // {
  //   municipio: "Zuia",
  //   provincia: "Álava",
  //   id: "01063",
  //   label: "Zuia (Álava)",
  // },
  // {
  //   municipio: "Arantza",
  //   provincia: "Navarra",
  //   id: "31022",
  //   label: "Arantza (Navarra)",
  // },
  // {
  //   municipio: "Aranguren",
  //   provincia: "Navarra",
  //   id: "31023",
  //   label: "Aranguren (Navarra)",
  // },
  // {
  //   municipio: "Alegia",
  //   provincia: "Guipúzcoa",
  //   id: "20005",
  //   label: "Alegia (Guipúzcoa)",
  // },
  // {
  //   municipio: "Arano",
  //   provincia: "Navarra",
  //   id: "31024",
  //   label: "Arano (Navarra)",
  // },
  // {
  //   municipio: "Aizarnazabal",
  //   provincia: "Guipúzcoa",
  //   id: "20003",
  //   label: "Aizarnazabal (Guipúzcoa)",
  // },
  // {
  //   municipio: "Altzo",
  //   provincia: "Guipúzcoa",
  //   id: "20007",
  //   label: "Altzo (Guipúzcoa)",
  // },
  // {
  //   municipio: "Amezketa",
  //   provincia: "Guipúzcoa",
  //   id: "20008",
  //   label: "Amezketa (Guipúzcoa)",
  // },
  // {
  //   municipio: "Muruzábal",
  //   provincia: "Navarra",
  //   id: "31180",
  //   label: "Muruzábal (Navarra)",
  // },
  // {
  //   municipio: "Andoain",
  //   provincia: "Guipúzcoa",
  //   id: "20009",
  //   label: "Andoain (Guipúzcoa)",
  // },
  // {
  //   municipio: "Anoeta",
  //   provincia: "Guipúzcoa",
  //   id: "20010",
  //   label: "Anoeta (Guipúzcoa)",
  // },
  // {
  //   municipio: "Antzuola",
  //   provincia: "Guipúzcoa",
  //   id: "20011",
  //   label: "Antzuola (Guipúzcoa)",
  // },
  // {
  //   municipio: "Abáigar",
  //   provincia: "Navarra",
  //   id: "31001",
  //   label: "Abáigar (Navarra)",
  // },
  // {
  //   municipio: "Arama",
  //   provincia: "Guipúzcoa",
  //   id: "20012",
  //   label: "Arama (Guipúzcoa)",
  // },
  // {
  //   municipio: "Aretxabaleta",
  //   provincia: "Guipúzcoa",
  //   id: "20013",
  //   label: "Aretxabaleta (Guipúzcoa)",
  // },
  // {
  //   municipio: "Asteasu",
  //   provincia: "Guipúzcoa",
  //   id: "20014",
  //   label: "Asteasu (Guipúzcoa)",
  // },
  // {
  //   municipio: "Burlada/Burlata",
  //   provincia: "Navarra",
  //   id: "31060",
  //   label: "Burlada/Burlata (Navarra)",
  // },
  // {
  //   municipio: "Ataun",
  //   provincia: "Guipúzcoa",
  //   id: "20015",
  //   label: "Ataun (Guipúzcoa)",
  // },
  // {
  //   municipio: "Azkoitia",
  //   provincia: "Guipúzcoa",
  //   id: "20017",
  //   label: "Azkoitia (Guipúzcoa)",
  // },
  // {
  //   municipio: "Soraluze-Placencia de las Armas",
  //   provincia: "Guipúzcoa",
  //   id: "20065",
  //   label: "Soraluze-Placencia de las Armas (Guipúzcoa)",
  // },
  // {
  //   municipio: "Azpeitia",
  //   provincia: "Guipúzcoa",
  //   id: "20018",
  //   label: "Azpeitia (Guipúzcoa)",
  // },
  // {
  //   municipio: "Beasain",
  //   provincia: "Guipúzcoa",
  //   id: "20019",
  //   label: "Beasain (Guipúzcoa)",
  // },
  // {
  //   municipio: "Aras",
  //   provincia: "Navarra",
  //   id: "31026",
  //   label: "Aras (Navarra)",
  // },
  // {
  //   municipio: "Busto, El",
  //   provincia: "Navarra",
  //   id: "31061",
  //   label: "Busto, El (Navarra)",
  // },
  // {
  //   municipio: "Beizama",
  //   provincia: "Guipúzcoa",
  //   id: "20020",
  //   label: "Beizama (Guipúzcoa)",
  // },
  // {
  //   municipio: "Belauntza",
  //   provincia: "Guipúzcoa",
  //   id: "20021",
  //   label: "Belauntza (Guipúzcoa)",
  // },
  // {
  //   municipio: "Eibar",
  //   provincia: "Guipúzcoa",
  //   id: "20030",
  //   label: "Eibar (Guipúzcoa)",
  // },
  // {
  //   municipio: "Berastegi",
  //   provincia: "Guipúzcoa",
  //   id: "20022",
  //   label: "Berastegi (Guipúzcoa)",
  // },
  // {
  //   municipio: "Zestoa",
  //   provincia: "Guipúzcoa",
  //   id: "20027",
  //   label: "Zestoa (Guipúzcoa)",
  // },
  // {
  //   municipio: "Zizurkil",
  //   provincia: "Guipúzcoa",
  //   id: "20028",
  //   label: "Zizurkil (Guipúzcoa)",
  // },
  // {
  //   municipio: "Elduain",
  //   provincia: "Guipúzcoa",
  //   id: "20031",
  //   label: "Elduain (Guipúzcoa)",
  // },
  // {
  //   municipio: "Gaintza",
  //   provincia: "Guipúzcoa",
  //   id: "20037",
  //   label: "Gaintza (Guipúzcoa)",
  // },
  // {
  //   municipio: "Elgoibar",
  //   provincia: "Guipúzcoa",
  //   id: "20032",
  //   label: "Elgoibar (Guipúzcoa)",
  // },
  // {
  //   municipio: "Elgeta",
  //   provincia: "Guipúzcoa",
  //   id: "20033",
  //   label: "Elgeta (Guipúzcoa)",
  // },
  // {
  //   municipio: "Eskoriatza",
  //   provincia: "Guipúzcoa",
  //   id: "20034",
  //   label: "Eskoriatza (Guipúzcoa)",
  // },
  // {
  //   municipio: "Ezkio-Itsaso",
  //   provincia: "Guipúzcoa",
  //   id: "20035",
  //   label: "Ezkio-Itsaso (Guipúzcoa)",
  // },
  // {
  //   municipio: "Irun",
  //   provincia: "Guipúzcoa",
  //   id: "20045",
  //   label: "Irun (Guipúzcoa)",
  // },
  // {
  //   municipio: "Irura",
  //   provincia: "Guipúzcoa",
  //   id: "20046",
  //   label: "Irura (Guipúzcoa)",
  // },
  // {
  //   municipio: "Orbara",
  //   provincia: "Navarra",
  //   id: "31196",
  //   label: "Orbara (Navarra)",
  // },
  // {
  //   municipio: "Itsasondo",
  //   provincia: "Guipúzcoa",
  //   id: "20047",
  //   label: "Itsasondo (Guipúzcoa)",
  // },
  // {
  //   municipio: "Legorreta",
  //   provincia: "Guipúzcoa",
  //   id: "20052",
  //   label: "Legorreta (Guipúzcoa)",
  // },
  // {
  //   municipio: "Orísoain",
  //   provincia: "Navarra",
  //   id: "31197",
  //   label: "Orísoain (Navarra)",
  // },
  // {
  //   municipio: "Lezo",
  //   provincia: "Guipúzcoa",
  //   id: "20053",
  //   label: "Lezo (Guipúzcoa)",
  // },
  // {
  //   municipio: "Lizartza",
  //   provincia: "Guipúzcoa",
  //   id: "20054",
  //   label: "Lizartza (Guipúzcoa)",
  // },
  // {
  //   municipio: "Oñati",
  //   provincia: "Guipúzcoa",
  //   id: "20059",
  //   label: "Oñati (Guipúzcoa)",
  // },
  // {
  //   municipio: "Orexa",
  //   provincia: "Guipúzcoa",
  //   id: "20060",
  //   label: "Orexa (Guipúzcoa)",
  // },
  // {
  //   municipio: "Orio",
  //   provincia: "Guipúzcoa",
  //   id: "20061",
  //   label: "Orio (Guipúzcoa)",
  // },
  // {
  //   municipio: "Errezil",
  //   provincia: "Guipúzcoa",
  //   id: "20066",
  //   label: "Errezil (Guipúzcoa)",
  // },
  // {
  //   municipio: "Errenteria",
  //   provincia: "Guipúzcoa",
  //   id: "20067",
  //   label: "Errenteria (Guipúzcoa)",
  // },
  // {
  //   municipio: "Leintz-Gatzaga",
  //   provincia: "Guipúzcoa",
  //   id: "20068",
  //   label: "Leintz-Gatzaga (Guipúzcoa)",
  // },
  // {
  //   municipio: "Abaurrepea/Abaurrea Baja",
  //   provincia: "Navarra",
  //   id: "31004",
  //   label: "Abaurrepea/Abaurrea Baja (Navarra)",
  // },
  // {
  //   municipio: "Arbizu",
  //   provincia: "Navarra",
  //   id: "31027",
  //   label: "Arbizu (Navarra)",
  // },
  // {
  //   municipio: "Arce/Artzi",
  //   provincia: "Navarra",
  //   id: "31028",
  //   label: "Arce/Artzi (Navarra)",
  // },
  // {
  //   municipio: "Arakil",
  //   provincia: "Navarra",
  //   id: "31025",
  //   label: "Arakil (Navarra)",
  // },
  // {
  //   municipio: "Arcos, Los",
  //   provincia: "Navarra",
  //   id: "31029",
  //   label: "Arcos, Los (Navarra)",
  // },
  // {
  //   municipio: "Arellano",
  //   provincia: "Navarra",
  //   id: "31030",
  //   label: "Arellano (Navarra)",
  // },
  // {
  //   municipio: "Arguedas",
  //   provincia: "Navarra",
  //   id: "31032",
  //   label: "Arguedas (Navarra)",
  // },
  // {
  //   municipio: "Aria",
  //   provincia: "Navarra",
  //   id: "31033",
  //   label: "Aria (Navarra)",
  // },
  // {
  //   municipio: "Armañanzas",
  //   provincia: "Navarra",
  //   id: "31035",
  //   label: "Armañanzas (Navarra)",
  // },
  // {
  //   municipio: "Arróniz",
  //   provincia: "Navarra",
  //   id: "31036",
  //   label: "Arróniz (Navarra)",
  // },
  // {
  //   municipio: "Arruazu",
  //   provincia: "Navarra",
  //   id: "31037",
  //   label: "Arruazu (Navarra)",
  // },
  // {
  //   municipio: "Artajona",
  //   provincia: "Navarra",
  //   id: "31038",
  //   label: "Artajona (Navarra)",
  // },
  // {
  //   municipio: "Artazu",
  //   provincia: "Navarra",
  //   id: "31039",
  //   label: "Artazu (Navarra)",
  // },
  // {
  //   municipio: "Atez/Atetz",
  //   provincia: "Navarra",
  //   id: "31040",
  //   label: "Atez/Atetz (Navarra)",
  // },
  // {
  //   municipio: "Beire",
  //   provincia: "Navarra",
  //   id: "31051",
  //   label: "Beire (Navarra)",
  // },
  // {
  //   municipio: "Ayegui/Aiegi",
  //   provincia: "Navarra",
  //   id: "31041",
  //   label: "Ayegui/Aiegi (Navarra)",
  // },
  // {
  //   municipio: "Bakaiku",
  //   provincia: "Navarra",
  //   id: "31044",
  //   label: "Bakaiku (Navarra)",
  // },
  // {
  //   municipio: "Azagra",
  //   provincia: "Navarra",
  //   id: "31042",
  //   label: "Azagra (Navarra)",
  // },
  // {
  //   municipio: "Azuelo",
  //   provincia: "Navarra",
  //   id: "31043",
  //   label: "Azuelo (Navarra)",
  // },
  // {
  //   municipio: "Belascoáin",
  //   provincia: "Navarra",
  //   id: "31052",
  //   label: "Belascoáin (Navarra)",
  // },
  // {
  //   municipio: "Barásoain",
  //   provincia: "Navarra",
  //   id: "31045",
  //   label: "Barásoain (Navarra)",
  // },
  // {
  //   municipio: "Barbarin",
  //   provincia: "Navarra",
  //   id: "31046",
  //   label: "Barbarin (Navarra)",
  // },
  // {
  //   municipio: "Berbinzana",
  //   provincia: "Navarra",
  //   id: "31053",
  //   label: "Berbinzana (Navarra)",
  // },
  // {
  //   municipio: "Bargota",
  //   provincia: "Navarra",
  //   id: "31047",
  //   label: "Bargota (Navarra)",
  // },
  // {
  //   municipio: "Barillas",
  //   provincia: "Navarra",
  //   id: "31048",
  //   label: "Barillas (Navarra)",
  // },
  // {
  //   municipio: "Biurrun-Olcoz",
  //   provincia: "Navarra",
  //   id: "31056",
  //   label: "Biurrun-Olcoz (Navarra)",
  // },
  // {
  //   municipio: "Basaburua",
  //   provincia: "Navarra",
  //   id: "31049",
  //   label: "Basaburua (Navarra)",
  // },
  // {
  //   municipio: "Buñuel",
  //   provincia: "Navarra",
  //   id: "31057",
  //   label: "Buñuel (Navarra)",
  // },
  // {
  //   municipio: "Cizur",
  //   provincia: "Navarra",
  //   id: "31076",
  //   label: "Cizur (Navarra)",
  // },
  // {
  //   municipio: "Corella",
  //   provincia: "Navarra",
  //   id: "31077",
  //   label: "Corella (Navarra)",
  // },
  // {
  //   municipio: "Cortes",
  //   provincia: "Navarra",
  //   id: "31078",
  //   label: "Cortes (Navarra)",
  // },
  // {
  //   municipio: "Bertizarana",
  //   provincia: "Navarra",
  //   id: "31054",
  //   label: "Bertizarana (Navarra)",
  // },
  // {
  //   municipio: "Betelu",
  //   provincia: "Navarra",
  //   id: "31055",
  //   label: "Betelu (Navarra)",
  // },
  // {
  //   municipio: "Auritz/Burguete",
  //   provincia: "Navarra",
  //   id: "31058",
  //   label: "Auritz/Burguete (Navarra)",
  // },
  // {
  //   municipio: "Burgui/Burgi",
  //   provincia: "Navarra",
  //   id: "31059",
  //   label: "Burgui/Burgi (Navarra)",
  // },
  // {
  //   municipio: "Cabanillas",
  //   provincia: "Navarra",
  //   id: "31062",
  //   label: "Cabanillas (Navarra)",
  // },
  // {
  //   municipio: "Garralda",
  //   provincia: "Navarra",
  //   id: "31115",
  //   label: "Garralda (Navarra)",
  // },
  // {
  //   municipio: "Cabredo",
  //   provincia: "Navarra",
  //   id: "31063",
  //   label: "Cabredo (Navarra)",
  // },
  // {
  //   municipio: "Cáseda",
  //   provincia: "Navarra",
  //   id: "31069",
  //   label: "Cáseda (Navarra)",
  // },
  // {
  //   municipio: "Cadreita",
  //   provincia: "Navarra",
  //   id: "31064",
  //   label: "Cadreita (Navarra)",
  // },
  // {
  //   municipio: "Carcastillo",
  //   provincia: "Navarra",
  //   id: "31067",
  //   label: "Carcastillo (Navarra)",
  // },
  // {
  //   municipio: "Caparroso",
  //   provincia: "Navarra",
  //   id: "31065",
  //   label: "Caparroso (Navarra)",
  // },
  // {
  //   municipio: "Cárcar",
  //   provincia: "Navarra",
  //   id: "31066",
  //   label: "Cárcar (Navarra)",
  // },
  // {
  //   municipio: "Cascante",
  //   provincia: "Navarra",
  //   id: "31068",
  //   label: "Cascante (Navarra)",
  // },
  // {
  //   municipio: "Ciriza/Ziritza",
  //   provincia: "Navarra",
  //   id: "31075",
  //   label: "Ciriza/Ziritza (Navarra)",
  // },
  // {
  //   municipio: "Castejón",
  //   provincia: "Navarra",
  //   id: "31070",
  //   label: "Castejón (Navarra)",
  // },
  // {
  //   municipio: "Ziordia",
  //   provincia: "Navarra",
  //   id: "31073",
  //   label: "Ziordia (Navarra)",
  // },
  // {
  //   municipio: "Cirauqui/Zirauki",
  //   provincia: "Navarra",
  //   id: "31074",
  //   label: "Cirauqui/Zirauki (Navarra)",
  // },
  // {
  //   municipio: "Dicastillo",
  //   provincia: "Navarra",
  //   id: "31080",
  //   label: "Dicastillo (Navarra)",
  // },
  // {
  //   municipio: "Donamaria",
  //   provincia: "Navarra",
  //   id: "31081",
  //   label: "Donamaria (Navarra)",
  // },
  // {
  //   municipio: "Etxalar",
  //   provincia: "Navarra",
  //   id: "31082",
  //   label: "Etxalar (Navarra)",
  // },
  // {
  //   municipio: "Echarri/Etxarri",
  //   provincia: "Navarra",
  //   id: "31083",
  //   label: "Echarri/Etxarri (Navarra)",
  // },
  // {
  //   municipio: "Etxarri Aranatz",
  //   provincia: "Navarra",
  //   id: "31084",
  //   label: "Etxarri Aranatz (Navarra)",
  // },
  // {
  //   municipio: "Etxauri",
  //   provincia: "Navarra",
  //   id: "31085",
  //   label: "Etxauri (Navarra)",
  // },
  // {
  //   municipio: "Valle de Egüés/Eguesibar",
  //   provincia: "Navarra",
  //   id: "31086",
  //   label: "Valle de Egüés/Eguesibar (Navarra)",
  // },
  // {
  //   municipio: "Elgorriaga",
  //   provincia: "Navarra",
  //   id: "31087",
  //   label: "Elgorriaga (Navarra)",
  // },
  // {
  //   municipio: "Noáin (Valle de Elorz)/Noain (Elortzibar)",
  //   provincia: "Navarra",
  //   id: "31088",
  //   label: "Noáin (Valle de Elorz)/Noain (Elortzibar) (Navarra)",
  // },
  // {
  //   municipio: "Enériz/Eneritz",
  //   provincia: "Navarra",
  //   id: "31089",
  //   label: "Enériz/Eneritz (Navarra)",
  // },
  // {
  //   municipio: "Eratsun",
  //   provincia: "Navarra",
  //   id: "31090",
  //   label: "Eratsun (Navarra)",
  // },
  // {
  //   municipio: "Ezcároz/Ezkaroze",
  //   provincia: "Navarra",
  //   id: "31093",
  //   label: "Ezcároz/Ezkaroze (Navarra)",
  // },
  // {
  //   municipio: "Ergoiena",
  //   provincia: "Navarra",
  //   id: "31091",
  //   label: "Ergoiena (Navarra)",
  // },
  // {
  //   municipio: "Eslava",
  //   provincia: "Navarra",
  //   id: "31094",
  //   label: "Eslava (Navarra)",
  // },
  // {
  //   municipio: "Erro",
  //   provincia: "Navarra",
  //   id: "31092",
  //   label: "Erro (Navarra)",
  // },
  // {
  //   municipio: "Castillonuevo",
  //   provincia: "Navarra",
  //   id: "31071",
  //   label: "Castillonuevo (Navarra)",
  // },
  // {
  //   municipio: "Esparza de Salazar/Espartza Zaraitzu",
  //   provincia: "Navarra",
  //   id: "31095",
  //   label: "Esparza de Salazar/Espartza Zaraitzu (Navarra)",
  // },
  // {
  //   municipio: "Espronceda",
  //   provincia: "Navarra",
  //   id: "31096",
  //   label: "Espronceda (Navarra)",
  // },
  // {
  //   municipio: "Estella-Lizarra",
  //   provincia: "Navarra",
  //   id: "31097",
  //   label: "Estella-Lizarra (Navarra)",
  // },
  // {
  //   municipio: "Cintruénigo",
  //   provincia: "Navarra",
  //   id: "31072",
  //   label: "Cintruénigo (Navarra)",
  // },
  // {
  //   municipio: "Esteribar",
  //   provincia: "Navarra",
  //   id: "31098",
  //   label: "Esteribar (Navarra)",
  // },
  // {
  //   municipio: "Jaurrieta",
  //   provincia: "Navarra",
  //   id: "31134",
  //   label: "Jaurrieta (Navarra)",
  // },
  // {
  //   municipio: "Etayo",
  //   provincia: "Navarra",
  //   id: "31099",
  //   label: "Etayo (Navarra)",
  // },
  // {
  //   municipio: "Javier",
  //   provincia: "Navarra",
  //   id: "31135",
  //   label: "Javier (Navarra)",
  // },
  // {
  //   municipio: "Juslapeña/Txulapain",
  //   provincia: "Navarra",
  //   id: "31136",
  //   label: "Juslapeña/Txulapain (Navarra)",
  // },
  // {
  //   municipio: "Eulate",
  //   provincia: "Navarra",
  //   id: "31100",
  //   label: "Eulate (Navarra)",
  // },
  // {
  //   municipio: "Ezcabarte",
  //   provincia: "Navarra",
  //   id: "31101",
  //   label: "Ezcabarte (Navarra)",
  // },
  // {
  //   municipio: "Garaioa",
  //   provincia: "Navarra",
  //   id: "31112",
  //   label: "Garaioa (Navarra)",
  // },
  // {
  //   municipio: "Ezkurra",
  //   provincia: "Navarra",
  //   id: "31102",
  //   label: "Ezkurra (Navarra)",
  // },
  // {
  //   municipio: "Ezprogui",
  //   provincia: "Navarra",
  //   id: "31103",
  //   label: "Ezprogui (Navarra)",
  // },
  // {
  //   municipio: "Falces",
  //   provincia: "Navarra",
  //   id: "31104",
  //   label: "Falces (Navarra)",
  // },
  // {
  //   municipio: "Fitero",
  //   provincia: "Navarra",
  //   id: "31105",
  //   label: "Fitero (Navarra)",
  // },
  // {
  //   municipio: "Fontellas",
  //   provincia: "Navarra",
  //   id: "31106",
  //   label: "Fontellas (Navarra)",
  // },
  // {
  //   municipio: "Funes",
  //   provincia: "Navarra",
  //   id: "31107",
  //   label: "Funes (Navarra)",
  // },
  // {
  //   municipio: "Pitillas",
  //   provincia: "Navarra",
  //   id: "31205",
  //   label: "Pitillas (Navarra)",
  // },
  // {
  //   municipio: "Fustiñana",
  //   provincia: "Navarra",
  //   id: "31108",
  //   label: "Fustiñana (Navarra)",
  // },
  // {
  //   municipio: "Galar",
  //   provincia: "Navarra",
  //   id: "31109",
  //   label: "Galar (Navarra)",
  // },
  // {
  //   municipio: "Gallipienzo/Galipentzu",
  //   provincia: "Navarra",
  //   id: "31110",
  //   label: "Gallipienzo/Galipentzu (Navarra)",
  // },
  // {
  //   municipio: "Gallués/Galoze",
  //   provincia: "Navarra",
  //   id: "31111",
  //   label: "Gallués/Galoze (Navarra)",
  // },
  // {
  //   municipio: "Garde",
  //   provincia: "Navarra",
  //   id: "31113",
  //   label: "Garde (Navarra)",
  // },
  // {
  //   municipio: "Garínoain",
  //   provincia: "Navarra",
  //   id: "31114",
  //   label: "Garínoain (Navarra)",
  // },
  // {
  //   municipio: "Genevilla",
  //   provincia: "Navarra",
  //   id: "31116",
  //   label: "Genevilla (Navarra)",
  // },
  // {
  //   municipio: "Piedramillera",
  //   provincia: "Navarra",
  //   id: "31204",
  //   label: "Piedramillera (Navarra)",
  // },
  // {
  //   municipio: "Goñi",
  //   provincia: "Navarra",
  //   id: "31118",
  //   label: "Goñi (Navarra)",
  // },
  // {
  //   municipio: "Güesa/Gorza",
  //   provincia: "Navarra",
  //   id: "31119",
  //   label: "Güesa/Gorza (Navarra)",
  // },
  // {
  //   municipio: "Ituren",
  //   provincia: "Navarra",
  //   id: "31129",
  //   label: "Ituren (Navarra)",
  // },
  // {
  //   municipio: "Guesálaz/Gesalatz",
  //   provincia: "Navarra",
  //   id: "31120",
  //   label: "Guesálaz/Gesalatz (Navarra)",
  // },
  // {
  //   municipio: "Guirguillano",
  //   provincia: "Navarra",
  //   id: "31121",
  //   label: "Guirguillano (Navarra)",
  // },
  // {
  //   municipio: "Huarte/Uharte",
  //   provincia: "Navarra",
  //   id: "31122",
  //   label: "Huarte/Uharte (Navarra)",
  // },
  // {
  //   municipio: "Irañeta",
  //   provincia: "Navarra",
  //   id: "31127",
  //   label: "Irañeta (Navarra)",
  // },
  // {
  //   municipio: "Uharte Arakil",
  //   provincia: "Navarra",
  //   id: "31123",
  //   label: "Uharte Arakil (Navarra)",
  // },
  // {
  //   municipio: "Ibargoiti",
  //   provincia: "Navarra",
  //   id: "31124",
  //   label: "Ibargoiti (Navarra)",
  // },
  // {
  //   municipio: "Igúzquiza",
  //   provincia: "Navarra",
  //   id: "31125",
  //   label: "Igúzquiza (Navarra)",
  // },
  // {
  //   municipio: "Imotz",
  //   provincia: "Navarra",
  //   id: "31126",
  //   label: "Imotz (Navarra)",
  // },
  // {
  //   municipio: "Iturmendi",
  //   provincia: "Navarra",
  //   id: "31130",
  //   label: "Iturmendi (Navarra)",
  // },
  // {
  //   municipio: "Iza/Itza",
  //   provincia: "Navarra",
  //   id: "31131",
  //   label: "Iza/Itza (Navarra)",
  // },
  // {
  //   municipio: "Izagaondoa",
  //   provincia: "Navarra",
  //   id: "31132",
  //   label: "Izagaondoa (Navarra)",
  // },
  // {
  //   municipio: "Izalzu/Itzaltzu",
  //   provincia: "Navarra",
  //   id: "31133",
  //   label: "Izalzu/Itzaltzu (Navarra)",
  // },
  // {
  //   municipio: "Lantz",
  //   provincia: "Navarra",
  //   id: "31140",
  //   label: "Lantz (Navarra)",
  // },
  // {
  //   municipio: "Beintza-Labaien",
  //   provincia: "Navarra",
  //   id: "31137",
  //   label: "Beintza-Labaien (Navarra)",
  // },
  // {
  //   municipio: "Lapoblación",
  //   provincia: "Navarra",
  //   id: "31141",
  //   label: "Lapoblación (Navarra)",
  // },
  // {
  //   municipio: "Lakuntza",
  //   provincia: "Navarra",
  //   id: "31138",
  //   label: "Lakuntza (Navarra)",
  // },
  // {
  //   municipio: "Lana",
  //   provincia: "Navarra",
  //   id: "31139",
  //   label: "Lana (Navarra)",
  // },
  // {
  //   municipio: "Larraga",
  //   provincia: "Navarra",
  //   id: "31142",
  //   label: "Larraga (Navarra)",
  // },
  // {
  //   municipio: "Larraona",
  //   provincia: "Navarra",
  //   id: "31143",
  //   label: "Larraona (Navarra)",
  // },
  // {
  //   municipio: "Murchante",
  //   provincia: "Navarra",
  //   id: "31176",
  //   label: "Murchante (Navarra)",
  // },
  // {
  //   municipio: "Legaria",
  //   provincia: "Navarra",
  //   id: "31148",
  //   label: "Legaria (Navarra)",
  // },
  // {
  //   municipio: "Lerga",
  //   provincia: "Navarra",
  //   id: "31151",
  //   label: "Lerga (Navarra)",
  // },
  // {
  //   municipio: "Leitza",
  //   provincia: "Navarra",
  //   id: "31149",
  //   label: "Leitza (Navarra)",
  // },
  // {
  //   municipio: "Leoz/Leotz",
  //   provincia: "Navarra",
  //   id: "31150",
  //   label: "Leoz/Leotz (Navarra)",
  // },
  // {
  //   municipio: "Liédena",
  //   provincia: "Navarra",
  //   id: "31155",
  //   label: "Liédena (Navarra)",
  // },
  // {
  //   municipio: "Lerín",
  //   provincia: "Navarra",
  //   id: "31152",
  //   label: "Lerín (Navarra)",
  // },
  // {
  //   municipio: "Luquin",
  //   provincia: "Navarra",
  //   id: "31160",
  //   label: "Luquin (Navarra)",
  // },
  // {
  //   municipio: "Lesaka",
  //   provincia: "Navarra",
  //   id: "31153",
  //   label: "Lesaka (Navarra)",
  // },
  // {
  //   municipio: "Lezaun",
  //   provincia: "Navarra",
  //   id: "31154",
  //   label: "Lezaun (Navarra)",
  // },
  // {
  //   municipio: "Lizoain-Arriasgoiti/Lizoainibar-Arriasgoiti",
  //   provincia: "Navarra",
  //   id: "31156",
  //   label: "Lizoain-Arriasgoiti/Lizoainibar-Arriasgoiti (Navarra)",
  // },
  // {
  //   municipio: "Lodosa",
  //   provincia: "Navarra",
  //   id: "31157",
  //   label: "Lodosa (Navarra)",
  // },
  // {
  //   municipio: "Mañeru",
  //   provincia: "Navarra",
  //   id: "31161",
  //   label: "Mañeru (Navarra)",
  // },
  // {
  //   municipio: "Lónguida/Longida",
  //   provincia: "Navarra",
  //   id: "31158",
  //   label: "Lónguida/Longida (Navarra)",
  // },
  // {
  //   municipio: "Lumbier",
  //   provincia: "Navarra",
  //   id: "31159",
  //   label: "Lumbier (Navarra)",
  // },
  // {
  //   municipio: "Murieta",
  //   provincia: "Navarra",
  //   id: "31177",
  //   label: "Murieta (Navarra)",
  // },
  // {
  //   municipio: "Murillo el Cuende",
  //   provincia: "Navarra",
  //   id: "31178",
  //   label: "Murillo el Cuende (Navarra)",
  // },
  // {
  //   municipio: "Murillo el Fruto",
  //   provincia: "Navarra",
  //   id: "31179",
  //   label: "Murillo el Fruto (Navarra)",
  // },
  // {
  //   municipio: "Marañón",
  //   provincia: "Navarra",
  //   id: "31162",
  //   label: "Marañón (Navarra)",
  // },
  // {
  //   municipio: "Marcilla",
  //   provincia: "Navarra",
  //   id: "31163",
  //   label: "Marcilla (Navarra)",
  // },
  // {
  //   municipio: "Mélida",
  //   provincia: "Navarra",
  //   id: "31164",
  //   label: "Mélida (Navarra)",
  // },
  // {
  //   municipio: "Mendaza",
  //   provincia: "Navarra",
  //   id: "31166",
  //   label: "Mendaza (Navarra)",
  // },
  // {
  //   municipio: "Mendavia",
  //   provincia: "Navarra",
  //   id: "31165",
  //   label: "Mendavia (Navarra)",
  // },
  // {
  //   municipio: "Miranda de Arga",
  //   provincia: "Navarra",
  //   id: "31171",
  //   label: "Miranda de Arga (Navarra)",
  // },
  // {
  //   municipio: "Mendigorria",
  //   provincia: "Navarra",
  //   id: "31167",
  //   label: "Mendigorria (Navarra)",
  // },
  // {
  //   municipio: "Metauten",
  //   provincia: "Navarra",
  //   id: "31168",
  //   label: "Metauten (Navarra)",
  // },
  // {
  //   municipio: "Milagro",
  //   provincia: "Navarra",
  //   id: "31169",
  //   label: "Milagro (Navarra)",
  // },
  // {
  //   municipio: "Mirafuentes",
  //   provincia: "Navarra",
  //   id: "31170",
  //   label: "Mirafuentes (Navarra)",
  // },
  // {
  //   municipio: "Navascués/Nabaskoze",
  //   provincia: "Navarra",
  //   id: "31181",
  //   label: "Navascués/Nabaskoze (Navarra)",
  // },
  // {
  //   municipio: "Nazar",
  //   provincia: "Navarra",
  //   id: "31182",
  //   label: "Nazar (Navarra)",
  // },
  // {
  //   municipio: "Oiz",
  //   provincia: "Navarra",
  //   id: "31187",
  //   label: "Oiz (Navarra)",
  // },
  // {
  //   municipio: "Obanos",
  //   provincia: "Navarra",
  //   id: "31183",
  //   label: "Obanos (Navarra)",
  // },
  // {
  //   municipio: "Oco",
  //   provincia: "Navarra",
  //   id: "31184",
  //   label: "Oco (Navarra)",
  // },
  // {
  //   municipio: "Ochagavía/Otsagabia",
  //   provincia: "Navarra",
  //   id: "31185",
  //   label: "Ochagavía/Otsagabia (Navarra)",
  // },
  // {
  //   municipio: "Odieta",
  //   provincia: "Navarra",
  //   id: "31186",
  //   label: "Odieta (Navarra)",
  // },
  // {
  //   municipio: "Olazti/Olazagutía",
  //   provincia: "Navarra",
  //   id: "31189",
  //   label: "Olazti/Olazagutía (Navarra)",
  // },
  // {
  //   municipio: "Olejua",
  //   provincia: "Navarra",
  //   id: "31190",
  //   label: "Olejua (Navarra)",
  // },
  // {
  //   municipio: "Olite/Erriberri",
  //   provincia: "Navarra",
  //   id: "31191",
  //   label: "Olite/Erriberri (Navarra)",
  // },
  // {
  //   municipio: "Olóriz/Oloritz",
  //   provincia: "Navarra",
  //   id: "31192",
  //   label: "Olóriz/Oloritz (Navarra)",
  // },
  // {
  //   municipio: "Cendea de Olza/Oltza Zendea",
  //   provincia: "Navarra",
  //   id: "31193",
  //   label: "Cendea de Olza/Oltza Zendea (Navarra)",
  // },
  // {
  //   municipio: "Valle de Ollo/Ollaran",
  //   provincia: "Navarra",
  //   id: "31194",
  //   label: "Valle de Ollo/Ollaran (Navarra)",
  // },
  // {
  //   municipio: "Orbaizeta",
  //   provincia: "Navarra",
  //   id: "31195",
  //   label: "Orbaizeta (Navarra)",
  // },
  // {
  //   municipio: "Oronz/Orontze",
  //   provincia: "Navarra",
  //   id: "31198",
  //   label: "Oronz/Orontze (Navarra)",
  // },
  // {
  //   municipio: "Oroz-Betelu/Orotz-Betelu",
  //   provincia: "Navarra",
  //   id: "31199",
  //   label: "Oroz-Betelu/Orotz-Betelu (Navarra)",
  // },
  // {
  //   municipio: "Oteiza",
  //   provincia: "Navarra",
  //   id: "31200",
  //   label: "Oteiza (Navarra)",
  // },
  // {
  //   municipio: "Pamplona/Iruña",
  //   provincia: "Navarra",
  //   id: "31201",
  //   label: "Pamplona/Iruña (Navarra)",
  // },
  // {
  //   municipio: "Petilla de Aragón",
  //   provincia: "Navarra",
  //   id: "31203",
  //   label: "Petilla de Aragón (Navarra)",
  // },
  // {
  //   municipio: "Puente la Reina/Gares",
  //   provincia: "Navarra",
  //   id: "31206",
  //   label: "Puente la Reina/Gares (Navarra)",
  // },
  // {
  //   municipio: "Doneztebe/Santesteban",
  //   provincia: "Navarra",
  //   id: "31221",
  //   label: "Doneztebe/Santesteban (Navarra)",
  // },
  // {
  //   municipio: "Pueyo/Puiu",
  //   provincia: "Navarra",
  //   id: "31207",
  //   label: "Pueyo/Puiu (Navarra)",
  // },
  // {
  //   municipio: "Ribaforada",
  //   provincia: "Navarra",
  //   id: "31208",
  //   label: "Ribaforada (Navarra)",
  // },
  // {
  //   municipio: "Romanzado/Erromantzatua",
  //   provincia: "Navarra",
  //   id: "31209",
  //   label: "Romanzado/Erromantzatua (Navarra)",
  // },
  // {
  //   municipio: "Sunbilla",
  //   provincia: "Navarra",
  //   id: "31226",
  //   label: "Sunbilla (Navarra)",
  // },
  // {
  //   municipio: "Roncal/Erronkari",
  //   provincia: "Navarra",
  //   id: "31210",
  //   label: "Roncal/Erronkari (Navarra)",
  // },
  // {
  //   municipio: "Sartaguda",
  //   provincia: "Navarra",
  //   id: "31223",
  //   label: "Sartaguda (Navarra)",
  // },
  // {
  //   municipio: "Tirapu",
  //   provincia: "Navarra",
  //   id: "31229",
  //   label: "Tirapu (Navarra)",
  // },
  // {
  //   municipio: "Sesma",
  //   provincia: "Navarra",
  //   id: "31224",
  //   label: "Sesma (Navarra)",
  // },
  // {
  //   municipio: "Sada",
  //   provincia: "Navarra",
  //   id: "31212",
  //   label: "Sada (Navarra)",
  // },
  // {
  //   municipio: "Ujué/Uxue",
  //   provincia: "Navarra",
  //   id: "31235",
  //   label: "Ujué/Uxue (Navarra)",
  // },
  // {
  //   municipio: "Saldías",
  //   provincia: "Navarra",
  //   id: "31213",
  //   label: "Saldías (Navarra)",
  // },
  // {
  //   municipio: "Ultzama",
  //   provincia: "Navarra",
  //   id: "31236",
  //   label: "Ultzama (Navarra)",
  // },
  // {
  //   municipio: "Sorlada",
  //   provincia: "Navarra",
  //   id: "31225",
  //   label: "Sorlada (Navarra)",
  // },
  // {
  //   municipio: "Salinas de Oro/Jaitz",
  //   provincia: "Navarra",
  //   id: "31214",
  //   label: "Salinas de Oro/Jaitz (Navarra)",
  // },
  // {
  //   municipio: "Tafalla",
  //   provincia: "Navarra",
  //   id: "31227",
  //   label: "Tafalla (Navarra)",
  // },
  // {
  //   municipio: "Tiebas-Muruarte de Reta",
  //   provincia: "Navarra",
  //   id: "31228",
  //   label: "Tiebas-Muruarte de Reta (Navarra)",
  // },
  // {
  //   municipio: "Orreaga/Roncesvalles",
  //   provincia: "Navarra",
  //   id: "31211",
  //   label: "Orreaga/Roncesvalles (Navarra)",
  // },
  // {
  //   municipio: "San Adrián",
  //   provincia: "Navarra",
  //   id: "31215",
  //   label: "San Adrián (Navarra)",
  // },
  // {
  //   municipio: "Santacara",
  //   provincia: "Navarra",
  //   id: "31220",
  //   label: "Santacara (Navarra)",
  // },
  // {
  //   municipio: "Sangüesa/Zangoza",
  //   provincia: "Navarra",
  //   id: "31216",
  //   label: "Sangüesa/Zangoza (Navarra)",
  // },
  // {
  //   municipio: "Urdazubi/Urdax",
  //   provincia: "Navarra",
  //   id: "31239",
  //   label: "Urdazubi/Urdax (Navarra)",
  // },
  // {
  //   municipio: "San Martín de Unx",
  //   provincia: "Navarra",
  //   id: "31217",
  //   label: "San Martín de Unx (Navarra)",
  // },
  // {
  //   municipio: "Sansol",
  //   provincia: "Navarra",
  //   id: "31219",
  //   label: "Sansol (Navarra)",
  // },
  // {
  //   municipio: "Bidaurreta",
  //   provincia: "Navarra",
  //   id: "31253",
  //   label: "Bidaurreta (Navarra)",
  // },
  // {
  //   municipio: "Sarriés/Sartze",
  //   provincia: "Navarra",
  //   id: "31222",
  //   label: "Sarriés/Sartze (Navarra)",
  // },
  // {
  //   municipio: "Torralba del Río",
  //   provincia: "Navarra",
  //   id: "31230",
  //   label: "Torralba del Río (Navarra)",
  // },
  // {
  //   municipio: "Ucar",
  //   provincia: "Navarra",
  //   id: "31234",
  //   label: "Ucar (Navarra)",
  // },
  // {
  //   municipio: "Torres del Río",
  //   provincia: "Navarra",
  //   id: "31231",
  //   label: "Torres del Río (Navarra)",
  // },
  // {
  //   municipio: "Tudela",
  //   provincia: "Navarra",
  //   id: "31232",
  //   label: "Tudela (Navarra)",
  // },
  // {
  //   municipio: "Tulebras",
  //   provincia: "Navarra",
  //   id: "31233",
  //   label: "Tulebras (Navarra)",
  // },
  // {
  //   municipio: "Villafranca",
  //   provincia: "Navarra",
  //   id: "31254",
  //   label: "Villafranca (Navarra)",
  // },
  // {
  //   municipio: "Unciti",
  //   provincia: "Navarra",
  //   id: "31237",
  //   label: "Unciti (Navarra)",
  // },
  // {
  //   municipio: "Unzué/Untzue",
  //   provincia: "Navarra",
  //   id: "31238",
  //   label: "Unzué/Untzue (Navarra)",
  // },
  // {
  //   municipio: "Urdiain",
  //   provincia: "Navarra",
  //   id: "31240",
  //   label: "Urdiain (Navarra)",
  // },
  // {
  //   municipio: "Urraul Alto",
  //   provincia: "Navarra",
  //   id: "31241",
  //   label: "Urraul Alto (Navarra)",
  // },
  // {
  //   municipio: "Urraul Bajo",
  //   provincia: "Navarra",
  //   id: "31242",
  //   label: "Urraul Bajo (Navarra)",
  // },
  // {
  //   municipio: "Urroz-Villa",
  //   provincia: "Navarra",
  //   id: "31243",
  //   label: "Urroz-Villa (Navarra)",
  // },
  // {
  //   municipio: "Urroz",
  //   provincia: "Navarra",
  //   id: "31244",
  //   label: "Urroz (Navarra)",
  // },
  // {
  //   municipio: "Zabalza/Zabaltza",
  //   provincia: "Navarra",
  //   id: "31262",
  //   label: "Zabalza/Zabaltza (Navarra)",
  // },
  // {
  //   municipio: "Urzainqui/Urzainki",
  //   provincia: "Navarra",
  //   id: "31245",
  //   label: "Urzainqui/Urzainki (Navarra)",
  // },
  // {
  //   municipio: "Uterga",
  //   provincia: "Navarra",
  //   id: "31246",
  //   label: "Uterga (Navarra)",
  // },
  // {
  //   municipio: "Zubieta",
  //   provincia: "Navarra",
  //   id: "31263",
  //   label: "Zubieta (Navarra)",
  // },
  // {
  //   municipio: "Uztárroz/Uztarroze",
  //   provincia: "Navarra",
  //   id: "31247",
  //   label: "Uztárroz/Uztarroze (Navarra)",
  // },
  // {
  //   municipio: "Villamayor de Monjardín",
  //   provincia: "Navarra",
  //   id: "31255",
  //   label: "Villamayor de Monjardín (Navarra)",
  // },
  // {
  //   municipio: "Luzaide/Valcarlos",
  //   provincia: "Navarra",
  //   id: "31248",
  //   label: "Luzaide/Valcarlos (Navarra)",
  // },
  // {
  //   municipio: "Valtierra",
  //   provincia: "Navarra",
  //   id: "31249",
  //   label: "Valtierra (Navarra)",
  // },
  // {
  //   municipio: "Hiriberri/Villanueva de Aezkoa",
  //   provincia: "Navarra",
  //   id: "31256",
  //   label: "Hiriberri/Villanueva de Aezkoa (Navarra)",
  // },
  // {
  //   municipio: "Bera",
  //   provincia: "Navarra",
  //   id: "31250",
  //   label: "Bera (Navarra)",
  // },
  // {
  //   municipio: "Yesa",
  //   provincia: "Navarra",
  //   id: "31261",
  //   label: "Yesa (Navarra)",
  // },
  // {
  //   municipio: "Viana",
  //   provincia: "Navarra",
  //   id: "31251",
  //   label: "Viana (Navarra)",
  // },
  // {
  //   municipio: "Vidángoz/Bidankoze",
  //   provincia: "Navarra",
  //   id: "31252",
  //   label: "Vidángoz/Bidankoze (Navarra)",
  // },
  // {
  //   municipio: "Villatuerta",
  //   provincia: "Navarra",
  //   id: "31257",
  //   label: "Villatuerta (Navarra)",
  // },
  // {
  //   municipio: "Villava/Atarrabia",
  //   provincia: "Navarra",
  //   id: "31258",
  //   label: "Villava/Atarrabia (Navarra)",
  // },
  // {
  //   municipio: "Igantzi",
  //   provincia: "Navarra",
  //   id: "31259",
  //   label: "Igantzi (Navarra)",
  // },
  // {
  //   municipio: "Valle de Yerri/Deierri",
  //   provincia: "Navarra",
  //   id: "31260",
  //   label: "Valle de Yerri/Deierri (Navarra)",
  // },
  // {
  //   municipio: "Zúñiga",
  //   provincia: "Navarra",
  //   id: "31265",
  //   label: "Zúñiga (Navarra)",
  // },
  // {
  //   municipio: "Barañáin/Barañain",
  //   provincia: "Navarra",
  //   id: "31901",
  //   label: "Barañáin/Barañain (Navarra)",
  // },
  // {
  //   municipio: "Orkoien",
  //   provincia: "Navarra",
  //   id: "31906",
  //   label: "Orkoien (Navarra)",
  // },
  // {
  //   municipio: "Berrioplano/Berriobeiti",
  //   provincia: "Navarra",
  //   id: "31902",
  //   label: "Berrioplano/Berriobeiti (Navarra)",
  // },
  // {
  //   municipio: "Berriozar",
  //   provincia: "Navarra",
  //   id: "31903",
  //   label: "Berriozar (Navarra)",
  // },
  // {
  //   municipio: "Irurtzun",
  //   provincia: "Navarra",
  //   id: "31904",
  //   label: "Irurtzun (Navarra)",
  // },
  // {
  //   municipio: "Beriáin",
  //   provincia: "Navarra",
  //   id: "31905",
  //   label: "Beriáin (Navarra)",
  // },
  // {
  //   municipio: "Zizur Mayor/Zizur Nagusia",
  //   provincia: "Navarra",
  //   id: "31907",
  //   label: "Zizur Mayor/Zizur Nagusia (Navarra)",
  // },
  // {
  //   municipio: "Lekunberri",
  //   provincia: "Navarra",
  //   id: "31908",
  //   label: "Lekunberri (Navarra)",
  // },
  // {
  //   municipio: "Tiétar",
  //   id: "10904",
  //   provincia: "Cáceres",
  //   label: "Tiétar (Cáceres)",
  // },
  // {
  //   municipio: "San Martín del Tesorillo",
  //   id: "11903",
  //   provincia: "Cádiz",
  //   label: "San Martín del Tesorillo (Cádiz)",
  // },
  // {
  //   municipio: "Sagunt/Sagunto",
  //   id: "46220",
  //   provincia: "Valencia",
  //   label: "Sagunt/Sagunto (Valencia)",
  // },
  // {
  //   municipio: "Guadiana",
  //   id: "06903",
  //   provincia: "Badajoz",
  //   label: "Guadiana (Badajoz)",
  // },
  // {
  //   municipio: "Valderrubio",
  //   id: "18914",
  //   provincia: "Granada",
  //   label: "Valderrubio (Granada)",
  // },
  // {
  //   municipio: "Pobla de Vallbona, la",
  //   id: "46202",
  //   provincia: "Valencia",
  //   label: "Pobla de Vallbona, la (Valencia)",
  // },
  // {
  //   municipio: "Almassora",
  //   id: "12009",
  //   provincia: "Castellón",
  //   label: "Almassora (Castellón)",
  // },
  // {
  //   municipio: "Torrenueva Costa",
  //   id: "18916",
  //   provincia: "Granada",
  //   label: "Torrenueva Costa (Granada)",
  // },
  // {
  //   municipio: "Alfàs del Pi, l'",
  //   id: "03011",
  //   provincia: "Alicante",
  //   label: "Alfàs del Pi, l' (Alicante)",
  // },
  // {
  //   municipio: "Torre d'en Besora, la",
  //   id: "12119",
  //   provincia: "Castellón",
  //   label: "Torre d'en Besora, la (Castellón)",
  // },
  // {
  //   municipio: "Zarza-Perrunal, La",
  //   id: "21902",
  //   provincia: "Huelva",
  //   label: "Zarza-Perrunal, La (Huelva)",
  // },
  // {
  //   municipio: "Vila Joiosa, la/Villajoyosa",
  //   id: "03139",
  //   provincia: "Alicante",
  //   label: "Vila Joiosa, la/Villajoyosa (Alicante)",
  // },
  // {
  //   municipio: "Guijarrosa, La",
  //   id: "14902",
  //   provincia: "Córdoba",
  //   label: "Guijarrosa, La (Córdoba)",
  // },
  // {
  //   municipio: "Montecorto",
  //   id: "29903",
  //   provincia: "Málaga",
  //   label: "Montecorto (Málaga)",
  // },
  // {
  //   municipio: "Balanegra",
  //   id: "04904",
  //   provincia: "Almería",
  //   label: "Balanegra (Almería)",
  // },
  // {
  //   municipio: "Sant Vicent del Raspeig/San Vincente del Raspeig",
  //   id: "03122",
  //   provincia: "Alicante",
  //   label: "Sant Vicent del Raspeig/San Vincente del Raspeig (Alicante)",
  // },
  // {
  //   municipio: "Alcoi/Alcoy",
  //   id: "03009",
  //   provincia: "Alicante",
  //   label: "Alcoi/Alcoy (Alicante)",
  // },
  // {
  //   municipio: "Dehesas Viejas",
  //   id: "18065",
  //   provincia: "Granada",
  //   label: "Dehesas Viejas (Granada)",
  // },
  // {
  //   municipio: "Oza-Cesuras",
  //   id: "15902",
  //   provincia: "La Coruña",
  //   label: "Oza-Cesuras (La Coruña)",
  // },
  // {
  //   municipio: "Xàbia/Jávea",
  //   id: "03082",
  //   provincia: "Alicante",
  //   label: "Xàbia/Jávea (Alicante)",
  // },
  // {
  //   municipio: "Domingo Pérez de Granada",
  //   id: "18915",
  //   provincia: "Granada",
  //   label: "Domingo Pérez de Granada (Granada)",
  // },
  // {
  //   municipio: "Fuente Carreteros",
  //   id: "14901",
  //   provincia: "Córdoba",
  //   label: "Fuente Carreteros (Córdoba)",
  // },
  // {
  //   municipio: "Hospitalet de Llobregat, L'",
  //   id: "08101",
  //   provincia: "Barcelona",
  //   label: "Hospitalet de Llobregat, L' (Barcelona)",
  // },
  // {
  //   municipio: "Vall d'Uixó, la",
  //   id: "12126",
  //   provincia: "Castellón",
  //   label: "Vall d'Uixó, la (Castellón)",
  // },
  // {
  //   municipio: "Serrato",
  //   id: "29904",
  //   provincia: "Málaga",
  //   label: "Serrato (Málaga)",
  // },
  // {
  //   municipio: "Campello, el",
  //   id: "03050",
  //   provincia: "Alicante",
  //   label: "Campello, el (Alicante)",
  // },
  // {
  //   municipio: "Alboraia/Alboraya",
  //   id: "46013",
  //   provincia: "Valencia",
  //   label: "Alboraia/Alboraya (Valencia)",
  // },
  // {
  //   municipio: "Játar",
  //   id: "18106",
  //   provincia: "Granada",
  //   label: "Játar (Granada)",
  // },
  // {
  //   municipio: "Castelló de la Plana",
  //   id: "12040",
  //   provincia: "Castellón",
  //   label: "Castelló de la Plana (Castellón)",
  // },
  // {
  //   municipio: "Pueblonuevo de Miramontes",
  //   id: "10905",
  //   provincia: "Cáceres",
  //   label: "Pueblonuevo de Miramontes (Cáceres)",
  // },
  // {
  //   municipio: "Fornes",
  //   id: "18077",
  //   provincia: "Granada",
  //   label: "Fornes (Granada)",
  // },
  // {
  //   municipio: "Palmar de Troya, El",
  //   id: "41904",
  //   provincia: "Sevilla",
  //   label: "Palmar de Troya, El (Sevilla)",
  // },
  // {
  //   municipio: "Cerdedo-Cotobade",
  //   id: "36902",
  //   provincia: "Pontevedra",
  //   label: "Cerdedo-Cotobade (Pontevedra)",
  // },
  // {
  //   municipio: "Sant Joan d'Alacant",
  //   id: "03119",
  //   provincia: "Alicante",
  //   label: "Sant Joan d'Alacant (Alicante)",
  // },

  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_1_Oia",
  //   label: "Vigo - Oia (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_2_Navia",
  //   label: "Vigo - Navia (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_3_Valladares",
  //   label: "Vigo - Valladares (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_4_Alcabre",
  //   label: "Vigo - Alcabre (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_5_Centro",
  //   label: "Vigo - Centro (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_6_Beade",
  //   label: "Vigo - Beade (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_7_Sardoma",
  //   label: "Vigo - Sárdoma (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_8_Bembrive",
  //   label: "Vigo - Bembrive (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_9_Teis",
  //   label: "Vigo - Teis (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_10_Cabral",
  //   label: "Vigo - Cabral (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_11_Comesaña",
  //   label: "Vigo - Comesaña (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_12_Matama",
  //   label: "Vigo - Matamá (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_13_Castrelos",
  //   label: "Vigo - Castrelos (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_14_Zamans",
  //   label: "Vigo - Zamáns (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_15_Saians",
  //   label: "Vigo - Saiáns (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_16_Candean",
  //   label: "Vigo - Candeán (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_17_Coruxo",
  //   label: "Vigo - Coruxo (Vigo)",
  // },
  // {
  //   municipio: "Vigo",
  //   provincia: "Vigo",
  //   id: "36057_18_Lavadores",
  //   label: "Vigo - Lavadores (Vigo)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_1_CiutatVella",
  //   label: "Barcelona - Ciutat Vella (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_2_Eixample",
  //   label: "Barcelona - Eixample (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_3_SantsMontjuic",
  //   label: "Barcelona - Sants-Montjuïc (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_4_LesCorts",
  //   label: "Barcelona - Les Corts (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_5_SarriaSantGervasi",
  //   label: "Barcelona - Sarrià-Sant Gervasi (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_6_Gracia",
  //   label: "Barcelona - Gràcia (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_7_HortaGuinardo",
  //   label: "Barcelona - Horta-Guinardó (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_8_NouBarris",
  //   label: "Barcelona - Nou Barris (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_9_SantAndreu",
  //   label: "Barcelona - Sant Andreu (Barcelona)",
  // },
  // {
  //   municipio: "Barcelona",
  //   provincia: "Barcelona",
  //   id: "08019_10_SantMarti",
  //   label: "Barcelona - Sant Martí (Barcelona)",
  // },
  // {
  //   id: "28079_1_Centro",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Centro (Madrid)",
  // },
  // {
  //   id: "28079_2_Arganzuela",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Arganzuela (Madrid)",
  // },
  // {
  //   id: "28079_3_Retiro",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Retiro (Madrid)",
  // },
  // {
  //   id: "28079_4_Salamanca",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Salamanca (Madrid)",
  // },
  // {
  //   id: "28079_5_Chamartin",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Chamartín (Madrid)",
  // },
  // {
  //   id: "28079_6_Tetuan",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Tetuán (Madrid)",
  // },
  // {
  //   id: "28079_7_Chamberi",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Chamberí (Madrid)",
  // },
  // {
  //   id: "28079_8_FuencarralElPardo",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Fuencarral  - El Pardo(Madrid)",
  // },
  // {
  //   id: "28079_9_MoncloaAravaca",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Moncloa - Aravaca (Madrid)",
  // },
  // {
  //   id: "28079_10_Latina",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Latina (Madrid)",
  // },
  // {
  //   id: "28079_11_Carabanchel",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Carabanchel (Madrid)",
  // },
  // {
  //   id: "28079_12_Usera",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Usera (Madrid)",
  // },
  // {
  //   id: "28079_13_PuentedeVallecas",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Puente de Vallecas (Madrid)",
  // },
  // {
  //   id: "28079_14_Moratalaz",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Moratalaz (Madrid)",
  // },
  // {
  //   id: "28079_15_CiudadLineal",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid -   Ciudad Lineal (Madrid)",
  // },
  // {
  //   id: "28079_16_Hortaleza",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Hortaleza (Madrid)",
  // },
  // {
  //   id: "28079_17_Villaverde",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Villaverde (Madrid)",
  // },
  // {
  //   id: "28079_18_VilladeVallecas",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Villa de Vallecas (Madrid)",
  // },
  // {
  //   id: "28079_19_Vicalvaro",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Vicálvaro (Madrid)",
  // },
  // {
  //   id: "28079_20_San Blas - Canillejas",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - San Blas - Canillejas (Madrid)",
  // },
  // {
  //   id: "28079_21_Barajas",
  //   municipio: "Madrid",
  //   provincia: "Madrid",
  //   label: "Madrid - Barajas (Madrid)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_8_Churriana",
  //   label: "Málaga - Churriana (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_7_CarreteradeCadiz",
  //   label: "Málaga - Carretera de Cádiz (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_11_TeatinosUniversidad",
  //   label: "Málaga - Teatinos - Universidad (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_6_CruzdeHumilladero",
  //   label: "Málaga - Cruz de Humilladero (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_4_BailenMiraflores",
  //   label: "Málaga - Bailen - Miraflores (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_5_PalmaPalmilla",
  //   label: "Málaga - Palma - Palmilla (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_3_CiudadJardin",
  //   label: "Málaga - Ciudad Jardin (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_2_Este",
  //   label: "Málaga - Este (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_1_Centro",
  //   label: "Málaga - Centro (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_10_PuertodelaTorre",
  //   label: "Málaga - Puerto de la Torre (Málaga)",
  // },
  // {
  //   municipio: "Málaga",
  //   provincia: "Málaga",
  //   id: "29067_9_Campanillas",
  //   label: "Málaga - Campanillas (Málaga)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021_1_Centro",
  //   label: "Córdoba - Centro (Córdoba)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021_2_Levante",
  //   label: "Córdoba - Levante (Córdoba)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021_3_NorteCentro",
  //   label: "Córdoba - Norte Centro (Córdoba)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021_4_NorteSierra",
  //   label: "Córdoba - Norte Sierra (Córdoba)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021_5_PonienteNorte",
  //   label: "Córdoba - Poniente Norte (Córdoba)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021_6_PonienteSur",
  //   label: "Córdoba - Poniente Sur (Córdoba)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021_7_Sur",
  //   label: "Córdoba - Sur (Córdoba)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021_8_Sureste",
  //   label: "Córdoba - Sureste (Córdoba)",
  // },
  // {
  //   municipio: "Córdoba",
  //   provincia: "Córdoba",
  //   id: "14021_9_CordobaPeriurbano",
  //   label: "Córdoba - Córdoba Periurbano (Córdoba)",
  // },
  // {
  //   municipio: "Murcia",
  //   provincia: "Murcia",
  //   id: "30030_1_Distrito1",
  //   label: "Murcia - Distrito 1 (Murcia)",
  // },
  // {
  //   municipio: "Murcia",
  //   provincia: "Murcia",
  //   id: "30030_2_Distrito2",
  //   label: "Murcia - Distrito 2 (Murcia)",
  // },
  // {
  //   municipio: "Murcia",
  //   provincia: "Murcia",
  //   id: "30030_3_Distrito3",
  //   label: "Murcia - Distrito 3 (Murcia)",
  // },
  // {
  //   municipio: "Murcia",
  //   provincia: "Murcia",
  //   id: "30030_4_Distrito4",
  //   label: "Murcia - Distrito 4 (Murcia)",
  // },
  // {
  //   municipio: "Murcia",
  //   provincia: "Murcia",
  //   id: "30030_5_Distrito5",
  //   label: "Murcia - Distrito 5 (Murcia)",
  // },
  // {
  //   municipio: "Murcia",
  //   provincia: "Murcia",
  //   id: "30030_6_Distrito6",
  //   label: "Murcia - Distrito 6 (Murcia)",
  // },
  // {
  //   municipio: "Murcia",
  //   provincia: "Murcia",
  //   id: "30030_7_Distrito7",
  //   label: "Murcia - Distrito 7 (Murcia)",
  // },
  // {
  //   municipio: "Murcia",
  //   provincia: "Murcia",
  //   id: "30030_8_Distrito8",
  //   label: "Murcia - Distrito 8 (Murcia)",
  // },
  // {
  //   municipio: "Palmas de Gran Canaria, Las",
  //   provincia: "Palmas de Gran Canaria, Las",
  //   id: "35016_1_Vegueta,ConoSuryTafira",
  //   label:
  //     "Palmas de Gran Canaria, Las - Vegueta, Cono Sur y Tafira (Palmas de Gran Canaria, Las)",
  // },
  // {
  //   municipio: "Palmas de Gran Canaria, Las",
  //   provincia: "Palmas de Gran Canaria, Las",
  //   id: "35016_2_Centro",
  //   label: "Palmas de Gran Canaria, Las - Centro (Palmas de Gran Canaria, Las)",
  // },
  // {
  //   municipio: "Palmas de Gran Canaria, Las",
  //   provincia: "Palmas de Gran Canaria, Las",
  //   id: "35016_3_IsletaPuertoGuanarteme",
  //   label:
  //     "Palmas de Gran Canaria, Las - Isleta-Puerto-Guanarteme (Palmas de Gran Canaria, Las)",
  // },
  // {
  //   municipio: "Palmas de Gran Canaria, Las",
  //   provincia: "Palmas de Gran Canaria, Las",
  //   id: "35016_4_CiudadAlta",
  //   label:
  //     "Palmas de Gran Canaria, Las - Ciudad Alta (Palmas de Gran Canaria, Las)",
  // },
  // {
  //   municipio: "Palmas de Gran Canaria, Las",
  //   provincia: "Palmas de Gran Canaria, Las",
  //   id: "35016_5_TamaraceiteSanLorenzoTenoya",
  //   label:
  //     "Palmas de Gran Canaria, Las - Tamaraceite-San Lorenzo-Tenoya (Palmas de Gran Canaria, Las)",
  // },
];

// for (let index in municipe_list) {
//   let element = municipe_list[index];
//   //// console.log(
//   //   element,
//   //   element["municipio"] + " (" + element["provincia"] + ")"
//   // );
//   municipe_list[index]["label"] =
//     element["municipio"] + " (" + element["provincia"] + ")";
// }
const result = municipe_list.sort((a, b) => {
  return a.label.localeCompare(b.label);
});

export default result;
