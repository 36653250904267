
import LayerClass, { legendGradient, interpolateColors, hexToRgb } from "./LayerCommons";
import LayerPublicAmianticTextInput from "./LayerPublicAmianticTextInput";
import { renderToString } from 'react-dom/server'
import SolarInfoTabComponent from "./SolarInfoTabComponent";

const legend = () =>legendGradient("+2000m²", "0m²", ["#15cfca", "#002a4a"], "1000m²");
const getBackground = (feature) => {
   const colors = interpolateColors("8, 81, 79", "0, 16, 29", 2000 - 0);
   const defaultColor = "#002a4a";
   if (feature && "values_" in feature &&
      "features" in feature.values_ &&
      feature.values_.features.length >= 1 &&
      "a_total" in feature.values_.features[0].values_
    ) {
      const a_total = Math.round(feature.values_.features[0].values_["a_total"]);
      if(a_total >=2000){
        return colors[0];
      }else if(a_total <=0){
        return colors[colors.length -1];
      }else{
        return colors[colors.length - a_total-1];
      }
    }
    return defaultColor;
  };


  const getTooltip = (feature)=>{
    if (
      "features" in feature.values_ &&
      feature.values_.features.length >= 1 &&
      "a_total" in feature.values_.features[0].values_
    ) {
      const a_total = new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,

      }).format(feature.values_.features[0].values_["a_total"]).toString();
      return "Superficie : "+a_total + "m²";
    }
  };



  var info_field = (
    label,
    _data,
    fixed_decimals = false,
    is_date=false,
    backgroundColor = false,
    suffix = "",
  ) => {
      
   // console.log(_data);
    if (fixed_decimals && _data !== undefined && _data !== null) {
      //_data = _data.toFixed(fixed_decimals).toString();
      _data = new Intl.NumberFormat("es-ES", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,

      }).format(_data).toString();
    }
    if(is_date  && _data !== undefined && _data !== null){
      _data = new Date(_data).toISOString().slice(0, 10);
    }
    return (

      <LayerPublicAmianticTextInput
        style={{
          width: "90%",
          paddingLeft:"5%",
         // marginTop: "0.8rem",
          backgroundColor: backgroundColor,
        }}
       // sx={{style:{width:"100%"}}}
       // size="small"
        label={label}
        value={"" + _data + suffix}
        //readonly
      />
    );
  };
  


const solarModal = (feature)=>{
    console.log("amianticPublicModal feature", feature,  "values_" in feature,  "horizontal_irradiation_mean" in feature["values_"] )
    if (
      feature &&
      "values_" in feature &&
      "horizontal_irradiation_mean" in feature["values_"] 
    ){
          
      const data = feature.values_;
      let data_processed = JSON.parse(JSON.stringify(data));
      const replacements = {
        "Provincia":"Provincia",
        "Municipio":"Municipio",
        "reference":"Ref. Catastral",
        "currentUse":"Uso actual del edificio",
        "beginning":"Fecha de construcción",
        //"age":"Antigüedad",
        "a_total": "Área",
        "Link_catastro":"Catastro online",
        //"Link_image":"Imagen del inmueble",
        //"area_amianto":"Superficie de amianto",
        //"amianto_percentage":"Porcentaje de ocupación de amianto",
        //"priority":"Prioridad de retirada",
        //"date_withdrawal":"Calendario de retirada",
        //"withdrawal_cost":"Coste de retirada",
        //"labour_cost":"Coste de la mano de obra",
        //"new_cover_cost":"Coste de nueva cubierta",
        //"building_license_cost":"Coste de licencia de obra",
        //"new_basic_cover_cost":"Coste de nueva cubierta básica",
        //"basic_building_license_cost":"Coste de licencia de obra básica",
        //"made_by":"Análisis realizado por",

       //"Superficie de amianto":
      }
      const replacements_sun = {
        //"horizontal_irradiation_year"
        "horizontal_irradiation_mean":"Radiación anual média - Horizontal",
        //"temperature_year"
        "temperature_mean" : "Temperatura media",
        //"direct_normal_irradiation_year"
        "direct_normal_irradiation_mean" : "Radiación anual média - Directa normal",
        //"optimal_angle_irradiation_year"
        "optimal_angle_irradiation_mean": "Radiación anual média - Ángulo óptimo",
        "superficie_cubierta":"Superficie disponible considerada para el cálculo",
        "superficie_fv":"Porcentaje de superficie con fotovoltaicas",
        "Potencia_instalada_kWp":"Potencia total calculada",
        "ratio_kWp_m2":"Ratio kWp/m²",
        "Coste €/kWp":"Coste",
        "n_modulos": "Número de módulos",
        "potencia_modulos":"Potencia de cada módulo",
        "Lucernario":"¿Tiene licernarias?"
      }
      const links = [
        "Link_catastro",
        "Link_image"
      ]
      const units = {
        "horizontal_irradiation_mean":" KWh/m²",
        "temperature_mean":" ºC",
        "Potencia de cada módulo":" KWp",
        "Coste €/kWp":" €/kWp",
        "direct_normal_irradiation_mean":" KWh/m²",
        "optimal_angle_irradiation_mean":" KWh/m²",
        "superficie_fv": " %",
        "a_total": " m²",
        "superficie_cubierta": " m²",
        "area_amianto":" m²",
        "age":" años",
        "amianto_percentage":" %",
        "withdrawal_cost": " €",
        "labour_cost": " €",
        "new_cover_cost": " €",
        "building_license_cost": " €",
        "new_basic_cover_cost": " €",
        "basic_building_license_cost": " €",
      }
      const is_float = [
        "horizontal_irradiation_mean",
        "temperature_mean",
        "direct_normal_irradiation_mean",
        "optimal_angle_irradiation_mean",
        "a_total", 
        "amianto_percentage",
        "area_amianto",
        "withdrawal_cost",
        "labour_cost",
        "new_cover_cost",
        "building_license_cost",
        "new_basic_cover_cost",
        "basic_building_license_cost"]
      const is_date = []
      for(const key of Object.keys(data)){
        if(!Object.keys(replacements).includes(key) && !Object.keys(replacements_sun).includes(key)){
          delete data_processed[key];
        }
      }
      // if(location.getN_REF_CATASTRAL() != null && location.getN_REF_CATASTRAL().length() >= 14) {
       const uri = "https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?rc1=";
      let catastroUrl = "";
      let catastroImageUrl = "";
      if(Object.keys(data).includes("Link_catastro")){
        catastroUrl= data["Link_catastro"]
      }else if(Object.keys(data).includes("reference")){
        const rc1 = data["reference"].substring(0, 7);
        const rc2 = data["reference"].substring(7, 14);
        catastroUrl = uri + rc1 + "&rc2=" + rc2;

        data_processed["Link_catastro"] = catastroUrl;

      }
      if(Object.keys(data).includes("reference")){
        catastroImageUrl= "https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral="+data_processed["reference"];
      }
      //   properties.put("urlIframe", catastroUrl);
      // }
     
      console.log("data_processed", data_processed)
      const a =  <div style={{width:"100%", display:"flex", flexWrap:"wrap", gap:"1.5rem", paddingRight:"1rem"}}>
        {feature ?<SolarInfoTabComponent feature={feature}/> :<></>}
          
        {catastroImageUrl != "" && <div  style={{display:"flex", flex:"3 1 320px",  maxHeight:"70vh"}}>
         <a href={catastroImageUrl} target='blank'> <img style={{width: "100%", borderRadius: "10px",height:"100%",   objectFit:"cover"}}  src={catastroImageUrl}/></a>
        </div>}
      </div>
      return a; 
      //return renderToString(<TableKeyValue dataObject={data} replacements={replacements} />)
    }
    return false;
  }







export const LAYER_SOLAR = new LayerClass(
    "Superficie",
    legend,
    getBackground,
    getTooltip,
     solarModal
  );

export {solarModal};