import LayerClass, { legendBoxes, hexToRgb } from "./LayerCommons";

const legend = () =>
  legendBoxes([
    { color: "#66c2a5", text: "Agrícola" },
    { color: "#fc8d62", text: "Industrial" },
    { color: "#8da0cb", text: "Otros" },
    { color: "#e78ac3", text: "Residencial" },
    { color: "#a6d854", text: "Servicios Públicos" },
    { color: "#ffd92f", text: "Retail" },
    { color: "#e5c494", text: "Oficinas" },
  ]);
const getBackground = (feature) => {
  const colors = {
    Agrícola: hexToRgb("#66c2a5"),
    Industrial: hexToRgb("#fc8d62"),
    Otros: hexToRgb("#8da0cb"),
    Residencial: hexToRgb("#e78ac3"),
    "Servicios Públicos": hexToRgb("#a6d854"),
    Retail: hexToRgb("#ffd92f"),
    Oficinas: hexToRgb("#e5c494"),
  };
  //colors: ['#fdae61','#1a9641','#a6d96a','#d7191c' ], //['#d7191c', '#fdae61', '#a6d96a','#1a9641' ], // Colores para las categorías
  //           alta      BAJA      media  muy alta

  let defaultColor = hexToRgb("#002a4a");
  // console.log("feature color ", feature)
  if (
    feature &&
    "values_" in feature &&
    "features" in feature.values_ &&
    feature.values_.features.length >= 1 &&
    "currentUse" in feature.values_.features[0].values_
  ) {
    const priority = feature.values_.features[0].values_["currentUse"];
    //console.log("feature color values_", priority)

    if (Object.keys(colors).includes(priority)) {
      //console.log("feature color EXITO", colors[priority])
      return colors[priority];
    } else {
      // console.log("feature color NO EXITO",priority,  colors[priority], feature.values_.features[0].values_)
      return defaultColor;
    }
  }
  // console.log("feature color NO EXITO VALUES", feature)
  return defaultColor;
};

const getTooltip = (feature) => {
  if (
    "features" in feature.values_ &&
    feature.values_.features.length >= 1 &&
    "currentUse" in feature.values_.features[0].values_
  ) {
    return "Uso actual : " + feature.values_.features[0].values_["currentUse"];
  }
};

export const LAYER_CURRENT_USE = new LayerClass(
  "Tipo de uso",
  legend,
  getBackground,
  getTooltip
);
